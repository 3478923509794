import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';

import OnOffByScroll from '../Animation/onOffByScroll';
import { AuthContext } from '../auth';
import various from '../General/various';
import { updateInstance } from '../Apis/apiHandlers';
import { memberDetail, memberIndex } from '../Apis/apiOfManaged';
import { emailRegValidator } from '../CommonFunction/validators';
import Footer from '../Footer/footer';
// import { localStorageArrayHandler } from '../CommonFunction/managePermission';
import BreadCrumbs from '../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  titles: {},
  inputs: {},
  helptext: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  container: {
    marginTop: '168px',
    width: '100%',
    '& > form': {
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '& > img': {
            maxWidth: '300px',
            maxHeight: '300px',
            objectFit: 'contain',
          },
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > label': {
            width: '100%',
            fontSize: '1rem',
            textAlign: 'end',
            '@media screen and (max-width:750px)': {
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
          '& > span': {
            color: 'red',
          },
        },

        '& $inputs': {
          width: '70%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
          '& > input[type="checkbox"]': {
            boxShadow: 'none',
          },
          '& $helptext': {
            fontSize: '0.8rem',
            color: 'grey',
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    marginTop: '88px !important',
    justifyContent: 'end',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const MyPageUpdate = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {},
  });

  const { isAuth } = useContext(AuthContext);
  // const permissions = localStorageArrayHandler('parse', various.permissionKey);

  const [member, setMember] = useState();
  const [members, setMembers] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const CRUMBS = [
    { name: 'MyPage', path: '/mypage' },
    { name: '登録情報更新', path: '/mypage/update' },
  ];

  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  useEffect(() => {
    if (location) {
      memberDetail(setMember, location.state.uuid);
    }
  }, [location]);

  useEffect(() => {
    memberIndex(setMembers);
  }, []);

  useEffect(() => {
    if (member) {
      const rejectList = [
        'discount_rate',
        'belongs',
        'member_of_honor',
        'roushi_is',
        'num_of_seinar_participation',
        'num_of_club_participation',
        'num_of_training_participation',
        'num_of_application_forms_sent',
        'image',
        'created_at',
        'user',
        'seminar',
        'participated_seminars',
        'permission_level',
      ];
      const keys = Object.keys(member);
      const resetValues = {};
      keys.forEach((k) => {
        if (!rejectList.includes(k)) {
          resetValues[k] = member[k];
        }
      });
      reset(resetValues);
    }
  }, [member, reset]);

  const onSubmit = handleSubmit(async (data) => {
    const imageFileKey = 'image';
    data[imageFileKey] = '';
    try {
      await updateInstance('MyPage', data, imageFileKey);
      console.log('update finish!');
      navigate(location.state.previous);
    } catch (error) {
      console.log(error);
    }
  });

  const memberExistenceCheck = (v) => {
    if (members.length > 0) {
      const emails = members.map((m) => {
        return m.email;
      });
      if (v === member.email) return true;
      else return !emails.includes(v);
    }
  };
  const form = () => {
    if (member) {
      return (
        <>
          <div className={classes.container}>
            <form
              method="POST"
              encType="multipart/form-data"
              onSubmit={onSubmit}
            >
              <div>
                <div className={classes.titles}>
                  <label htmlFor="氏名（姓）">{'氏名（姓）'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="last_name"
                    type="text"
                    {...register('last_name', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.last_name?.message && (
                    <div className={classes.error}>
                      {errors.last_name.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="氏名（カナ姓）">{'氏名（カナ姓）'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="last_name_kana"
                    type="text"
                    {...register('last_name_kana', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.last_name_kana?.message && (
                    <div className={classes.error}>
                      {errors.last_name_kana.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="氏名（名）">{'氏名（名）'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="first_name"
                    type="text"
                    {...register('first_name', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.first_name?.message && (
                    <div className={classes.error}>
                      {errors.first_name.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="氏名（カナ名）">{'氏名（カナ名）'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="first_name_kana"
                    type="text"
                    {...register('first_name_kana', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.first_name_kana?.message && (
                    <div className={classes.error}>
                      {errors.first_name_kana.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="Eメール">{'Eメール'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="email"
                    type="email"
                    disabled={true}
                    {...register('email', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          emailRegValidator(v)
                            ? memberExistenceCheck(v)
                              ? null
                              : `すでに登録されています。`
                            : `※正しいEメールの形式で入力してください`,
                      },
                    })}
                  />
                  <div className={classes.helptext}>
                    ここでEメールアドレスを変更することはできません。前のページ（MyPage）から変更してください。
                  </div>
                  {errors.email?.message && (
                    <div className={classes.error}>{errors.email.message}</div>
                  )}
                </div>
              </div>
              {/* {!permissions.includes('member_of_group') ? ( */}
              <>
                <div>
                  <div className={classes.titles}>
                    <label htmlFor="電話番号">{'電話番号'}</label>
                    <span> *</span>
                  </div>
                  <div className={classes.inputs}>
                    <input
                      id="phone_number"
                      type="text"
                      {...register('phone_number', {
                        required: {
                          value: true,
                          message: '入力必須の項目です',
                        },
                      })}
                    />
                    {errors.phone_number?.message && (
                      <div className={classes.error}>
                        {errors.phone_number.message}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div className={classes.titles}>
                    <label htmlFor="都道府県">{'都道府県'}</label>
                    <span> *</span>
                  </div>
                  <div className={classes.inputs}>
                    <input
                      id="address1"
                      type="text"
                      {...register('address1', {
                        required: {
                          value: true,
                          message: '入力必須の項目です',
                        },
                      })}
                    />
                    {errors.address1?.message && (
                      <div className={classes.error}>
                        {errors.address1.message}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div className={classes.titles}>
                    <label htmlFor="市区町村">{'市区町村'}</label>
                    <span> *</span>
                  </div>
                  <div className={classes.inputs}>
                    <input
                      id="address2"
                      type="text"
                      {...register('address2', {
                        required: {
                          value: true,
                          message: '入力必須の項目です',
                        },
                      })}
                    />
                    {errors.address2?.message && (
                      <div className={classes.error}>
                        {errors.address2.message}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div className={classes.titles}>
                    <label htmlFor="番地">{'番地'}</label>
                    <span> *</span>
                  </div>
                  <div className={classes.inputs}>
                    <input
                      id="address3"
                      type="text"
                      {...register('address3', {
                        required: {
                          value: true,
                          message: '入力必須の項目です',
                        },
                      })}
                    />
                    {errors.address3?.message && (
                      <div className={classes.error}>
                        {errors.address3.message}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div className={classes.titles}>
                    <label htmlFor="建物名">{'建物名'}</label>
                  </div>
                  <div className={classes.inputs}>
                    <input
                      id="address4"
                      type="text"
                      {...register('address4', {
                        required: {
                          value: false,
                          message: '入力必須の項目です',
                        },
                      })}
                    />
                    {errors.address4?.message && (
                      <div className={classes.error}>
                        {errors.address4.message}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div className={classes.titles}>
                    <label htmlFor="Facebookアカウント">
                      {'Facebookアカウント'}
                    </label>
                  </div>
                  <div className={classes.inputs}>
                    <input
                      id="facebook_account"
                      type="url"
                      {...register('facebook_account', {
                        required: {
                          value: false,
                          message: '入力必須の項目です',
                        },
                      })}
                    />
                    <div className={classes.helptext}>
                      Facebookアカウントをお持ちであれば、アカウントURL（例：https://www.facebook.com/yamada.taro）を教えてください
                    </div>
                    {errors.facebook_account?.message && (
                      <div className={classes.error}>
                        {errors.facebook_account.message}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div className={classes.titles}>
                    <label htmlFor="職業/勤務先">{'職業/勤務先'}</label>
                    <span> *</span>
                  </div>
                  <div className={classes.inputs}>
                    <input
                      id="work_place"
                      type="text"
                      {...register('work_place', {
                        required: {
                          value: true,
                          message: '入力必須の項目です',
                        },
                        maxLength: {
                          value: 50,
                          message: '50字以内でご入力ください',
                        },
                      })}
                    />
                    {errors.work_place?.message && (
                      <div className={classes.error}>
                        {errors.work_place.message}
                      </div>
                    )}
                  </div>
                </div>
              </>
              {/* ) : undefined} */}
              <div>
                <div className={classes.titles}>
                  <label htmlFor="セミナー情報配信の可否">
                    {'セミナー情報配信の可否'}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="notification_of_seminar"
                    type="checkbox"
                    {...register('notification_of_seminar', {
                      required: {
                        value: false,
                        message: '入力必須の項目です',
                      },
                    })}
                  />
                  <div className={classes.helptext}>
                    今後、セミナー情報の配信をご希望される場合は、チェックを入れてください。
                  </div>
                  {errors.notification_of_seminar?.message && (
                    <div className={classes.error}>
                      {errors.notification_of_seminar.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="ニュース情報配信の可否">
                    {'ニュース情報配信の可否'}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="notification_of_news"
                    type="checkbox"
                    {...register('notification_of_news', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    今後、ニュース配信をご希望される場合は、チェックを入れてください。
                  </div>
                  {errors.notification_of_news?.message && (
                    <div className={classes.error}>
                      {errors.notification_of_news.message}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.button}>
                <button type="submit">更新</button>
              </div>
            </form>
          </div>
          <Footer />
        </>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>
        登録情報更新
        <BreadCrumbs crumbs={CRUMBS} />
      </div>
      <hr />
      {isAuth ? (
        form()
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default MyPageUpdate;
