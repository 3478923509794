import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../auth';
import { trainingPlaceIndex, trainingHallIndex } from '../Apis/apiOfManaged';
import various from '../General/various';
import imageIndex from '../img/imageIndex';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../Animation/onOffByScroll';
import Footer from '../Footer/footer';
import BreadCrumbs from '../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
    '& > span': {
      color: 'grey',
      fontSize: '0.8rem',
    },
  },
  img: {},
  container: {
    marginTop: '80px',
    fontSize: '0.8em',
    '& > table': {
      borderCollapse: 'collapse',
      '& > thead, & > tbody': {
        '& > tr': {
          borderBottom: '1px solid grey',
          '& > td': {
            minWidth: '160px',
            height: '96px',
            textAlign: 'start',
            paddingRight: '32px',
            '& > a': {
              color: 'black',
              textDecoration: 'underline',
            },
          },
          '& $img': {
            textAlign: 'center',
          },
        },
      },
    },
  },
}));
const TrainingPlaceInfo = () => {
  const { isAuth } = useContext(AuthContext);
  const [place, setPlace] = useState([]);
  const [hall, setHall] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const CRUMBS = [
    { name: 'MyPage', path: '/mypage' },
    { name: '稽古場', path: '/mypage/training_place_information' },
  ];

  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  useEffect(() => {
    trainingPlaceIndex(setPlace);
  }, []);

  useEffect(() => {
    trainingHallIndex(setHall);
  }, []);

  const contents = () => {
    if (place && hall) {
      const result = [];
      place.forEach((p) => {
        const halls = [];
        hall.forEach((h) => {
          if (p.uuid === h.training_place) {
            halls.push(h.name);
          }
        });
        result.push({
          name: p.facility_name,
          halls: halls,
          address: p.address,
          phone: p.phone_number,
          url: p.url,
          gmap: p.gmap,
        });
      });
      return result;
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>
        主な稽古場のご案内
        <BreadCrumbs crumbs={CRUMBS} />
        <br />
        <span>※稽古場会場への直接の電話連絡等はお控えください。</span>
      </div>
      <hr />
      {isAuth ? (
        <>
          <div className={classes.container}>
            <table>
              <thead>
                <tr>
                  <td className={classes.img}>GMap</td>
                  <td>名称</td>
                  <td>稽古場</td>
                  <td>住所</td>
                  {/* <td>電話番号</td> */}
                </tr>
              </thead>
              <tbody>
                {contents().map((c, i) => {
                  return (
                    <tr key={i}>
                      <td className={classes.img}>
                        <a href={c.gmap}>
                          <img src={imageIndex.MapPing} alt="map_ping" />
                        </a>
                      </td>
                      <td>
                        {/* <a href={c.url}>{c.name}</a> */}
                        {c.name}
                      </td>
                      <td>
                        <ul>
                          {c.halls.map((h, index) => {
                            return <li key={`hall_${index}`}>{h}</li>;
                          })}
                        </ul>
                      </td>
                      <td>{c.address}</td>
                      {/* <td>{c.phone}</td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Footer />
        </>
      ) : (
        <>
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleAuth}
            >
              ログイン
            </span>
            してください。
          </div>
          <Footer />
        </>
      )}
    </>
  );
};
export default TrainingPlaceInfo;
