import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import OnOffByScroll from '../../../Animation/onOffByScroll';

import {
  memberDetail,
  memberIndex,
  memberPatchUpdateOfHimself,
} from '../../../Apis/apiOfManaged';
import {
  birthdayValidator,
  emailRegValidator,
  phoneNumberValidator,
  selectDefaultCheck,
} from '../../../CommonFunction/validators';
import Footer from '../../../Footer/footer';
import { AuthContext } from '../../../auth';
import various from '../../../General/various';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  titles: {},
  inputs: {},
  helptext: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  container: {
    marginTop: '168px',
    width: '100%',
    '& > form': {
      '& > p': {
        fontSize: '0.7em',
        textAlign: 'center',
      },
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          '& > label': {
            width: '100%',
            fontSize: '1rem',
            textAlign: 'end',
            '@media screen and (max-width:750px)': {
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
          '& > span': {
            color: 'red',
          },
        },

        '& $inputs': {
          width: '70%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input, & > textarea, & > select': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
          '& #image': {
            boxShadow: 'none',
            border: 'none',
          },
          '& > input[type="checkbox"]': {
            boxShadow: 'none',
          },
          '& > textarea': {
            height: '5em',
          },
          '& > img': {
            width: '100%',
            objectFit: 'contain',
            maxHeight: '300px',
          },
          '& $helptext': {
            fontSize: '0.8rem',
            color: 'grey',
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    marginTop: '88px !important',
    justifyContent: 'end',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));
const MemberCreateByHimself = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {},
  });

  const { isAuth } = useContext(AuthContext);

  const [member, setMember] = useState();
  const [members, setMembers] = useState([]);

  const [token, setToken] = useState('');
  const [submitData, setSubmitData] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const handleAuth = () => {
    navigate(various.general.login, {
      state: {
        previous: `${location.pathname}?id=${query.get('id')}&flg=${query.get(
          'flg'
        )}`,
      },
    });
  };
  console.log('flg:', query.get('flg'));

  useEffect(() => {
    if (members.length > 0) {
      const uuids = members.map((m) => {
        return m.uuid;
      });
      if (member) {
        if (uuids.includes(member.uuid)) {
          const rejectList = [
            'discount_rate',
            'belongs',
            'member_of_honor',
            'roushi_is',
            'num_of_seinar_participation',
            'num_of_club_participation',
            'num_of_training_participation',
            'num_of_application_forms_sent',
            'image',
            'created_at',
            'user',
            'seminar',
            'participated_seminars',
            'permission_level',
          ];
          const birthdayKeys = [
            'year_of_birth',
            'year_of_month',
            'year_of_day',
          ];
          let birthdayList = [];
          const keys = Object.keys(member);
          const resetValues = {};
          keys.forEach((k) => {
            if (!rejectList.includes(k)) {
              if (birthdayKeys.includes(k)) {
                if (member[k]) {
                  birthdayList.push(member[k]);
                }
              } else {
                if (k !== 'sex') {
                  resetValues[k] = member[k];
                } else {
                  resetValues[k] = 'choice';
                }
              }
            }
          });
          const dateData = new Date(
            birthdayList[0],
            birthdayList[1] - 1,
            birthdayList[2]
          )
            .toLocaleString()
            .split(' ')[0]
            .replace(/\//g, '-');
          resetValues['birthday'] = dateData;
          reset(resetValues);
        }
      }
    }
  }, [members, member, reset, query]);

  useEffect(() => {
    if (member) {
      if (member.is_member || member.is_training_group_member) {
        alert('本ページは非会員の方向けの登録フォームです。');
        navigate('/');
      }
    }
  }, [member, navigate]);

  useEffect(() => {
    const id = query.get('id');
    memberDetail(setMember, id);
  }, [query]);

  useEffect(() => {
    memberIndex(setMembers);
  }, []);

  const onSubmit = async (data) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    } else {
      const reCaptchaToken = await executeRecaptcha('contact');
      setToken(reCaptchaToken);
      setSubmitData(data);
    }
  };

  useEffect(() => {
    if (submitData && token) {
      const data = { ...submitData };
      data['g_recaptcha_response'] = token;
      apiHandler(data);
    }
    // eslint-disable-next-line
  }, [submitData, token]);

  const apiHandler = (data) => {
    const imageFileKey = 'image';
    data[imageFileKey] = '';

    let birthday = data.birthday + 'T00:00:00+09:00';
    const date = new Date(Date.parse(birthday));
    data['year_of_birth'] = date.getFullYear();
    data['year_of_month'] = date.getMonth() + 1;
    data['year_of_day'] = date.getDate();

    const protocol = window.location.protocol;
    const host = window.location.host;
    const domain = document.domain;
    const path = 'mypage';
    const dataset = {
      email: data.email,
      protocol: protocol,
      host: host,
      path: path,
      domain: domain,
    };
    data['urlData'] = dataset;
    data['flag'] = query.get('flg');

    memberPatchUpdateOfHimself(data);
    setTimeout(() => {
      console.log('create finish!');
      navigate('/');
    }, 2000);
  };

  const memberExistanceCheck = (v) => {
    if (members.length > 0) {
      const emails = members.map((m) => {
        return m.email;
      });
      if (v === member.email) return true;
      else return !emails.includes(v);
    }
  };

  const form = () => {
    return (
      <div className={classes.container}>
        <form
          method="POST"
          encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <div className={classes.titles}>
              <label htmlFor="氏名（姓）">{'氏名（姓）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="last_name"
                type="text"
                {...register('last_name', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.last_name?.message && (
                <div className={classes.error}>{errors.last_name.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="氏名（カナ姓）">{'氏名（カナ姓）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="last_name_kana"
                type="text"
                {...register('last_name_kana', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.last_name_kana?.message && (
                <div className={classes.error}>
                  {errors.last_name_kana.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="氏名（名）">{'氏名（名）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="first_name"
                type="text"
                {...register('first_name', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.first_name?.message && (
                <div className={classes.error}>{errors.first_name.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="氏名（カナ名）">{'氏名（カナ名）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="first_name_kana"
                type="text"
                {...register('first_name_kana', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.first_name_kana?.message && (
                <div className={classes.error}>
                  {errors.first_name_kana.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="性別">{'性別'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <select
                id="sex"
                defaultValue="choice"
                {...register('sex', {
                  required: { value: true, message: '入力必須の項目です' },
                  validate: {
                    message: (v) =>
                      selectDefaultCheck(v)
                        ? null
                        : `男性または女性のいずれかを選択してください。`,
                  },
                })}
              >
                <option value="choice">選択してください</option>
                <option value="male">男性</option>
                <option value="female">女性</option>
              </select>
              {errors.sex?.message && (
                <div className={classes.error}>{errors.sex.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="学生区分">{'学生区分'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="is_student"
                type="checkbox"
                {...register('is_student', {
                  required: { value: false, message: '入力必須の項目です' },
                })}
              />
              <div className={classes.helptext}>
                学生の方であればチェックを入れてください
              </div>
              {errors.is_student?.message && (
                <div className={classes.error}>{errors.is_student.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="社会人区分">{'社会人区分'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="is_worker"
                type="checkbox"
                {...register('is_worker', {
                  required: { value: false, message: '入力必須の項目です' },
                })}
              />
              <div className={classes.helptext}>
                社会人の方であればチェックを入れてください
              </div>
              {errors.is_worker?.message && (
                <div className={classes.error}>{errors.is_worker.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="Eメール">{'Eメール'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="email"
                type="email"
                disabled={member ? true : false}
                {...register('email', {
                  required: { value: true, message: '入力必須の項目です' },
                  validate: {
                    message: (v) =>
                      emailRegValidator(v)
                        ? memberExistanceCheck(v)
                          ? null
                          : `ご入力されたEメールでは送信できません。`
                        : `※正しいEメールの形式で入力してください`,
                  },
                })}
              />
              {errors.email?.message && (
                <div className={classes.error}>{errors.email.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="生年月日">{'生年月日'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="birthday"
                type="date"
                {...register('birthday', {
                  required: { value: true, message: '入力必須の項目です' },
                  validate: {
                    message: (v) =>
                      birthdayValidator(v)
                        ? null
                        : `ご入力いただいたご年齢ではお申し込みを承ることができません`,
                  },
                })}
              />
              {errors.birthday?.message && (
                <div className={classes.error}>{errors.birthday.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="電話番号">{'電話番号'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="phone_number"
                type="text"
                {...register('phone_number', {
                  required: { value: true, message: '入力必須の項目です' },
                  minLength: {
                    value: 10,
                    message: '短すぎます。市外局番からご入力ください',
                  },
                  maxLength: { value: 11, message: '電話番号が長すぎます' },
                  validate: {
                    message: (v) =>
                      phoneNumberValidator(v)
                        ? null
                        : `0から始まるハイフン無しの半角数字でご記入ください`,
                  },
                })}
              />
              {errors.phone_number?.message && (
                <div className={classes.error}>
                  {errors.phone_number.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="都道府県">{'都道府県'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="address1"
                type="text"
                {...register('address1', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.address1?.message && (
                <div className={classes.error}>{errors.address1.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="市区町村">{'市区町村'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="address2"
                type="text"
                {...register('address2', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.address2?.message && (
                <div className={classes.error}>{errors.address2.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="番地">{'番地'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="address3"
                type="text"
                {...register('address3', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.address3?.message && (
                <div className={classes.error}>{errors.address3.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="建物名等">{'建物名等'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="address4"
                type="text"
                {...register('address4', {
                  required: { value: false, message: '入力必須の項目です' },
                })}
              />
              {errors.address4?.message && (
                <div className={classes.error}>{errors.address4.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="Facebookアカウント">{'Facebookアカウント'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="facebook_account"
                type="url"
                {...register('facebook_account', {
                  required: { value: false, message: '入力必須の項目です' },
                })}
              />
              <div className={classes.helptext}>
                Facebookアカウントをお持ちであれば、アカウントURL（例：https://www.facebook.com/yamada.taro）を教えてください
              </div>
              {errors.facebook_account?.message && (
                <div className={classes.error}>
                  {errors.facebook_account.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="職業/勤務先">{'職業/勤務先'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="work_place"
                type="text"
                {...register('work_place', {
                  required: { value: true, message: '入力必須の項目です' },
                  maxLength: { value: 50, message: '50字以内でご入力ください' },
                })}
              />
              {errors.work_place?.message && (
                <div className={classes.error}>{errors.work_place.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="所属団体歴">{'所属団体歴'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="affiliation_history"
                type="text"
                {...register('affiliation_history', {
                  required: { value: false, message: '入力必須の項目です' },
                  maxLength: { value: 50, message: '50字以内でご入力ください' },
                })}
              />
              {errors.affiliation_history?.message && (
                <div className={classes.error}>
                  {errors.affiliation_history.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="運動歴">{'運動歴'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="exercise_career"
                type="text"
                {...register('exercise_career', {
                  required: { value: false, message: '入力必須の項目です' },
                  maxLength: { value: 50, message: '50字以内でご入力ください' },
                })}
              />
              {errors.exercise_career?.message && (
                <div className={classes.error}>
                  {errors.exercise_career.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="既往症">{'既往症'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="anamnesis"
                type="text"
                {...register('anamnesis', {
                  required: { value: false, message: '入力必須の項目です' },
                  maxLength: { value: 50, message: '50字以内でご入力ください' },
                })}
              />
              {errors.anamnesis?.message && (
                <div className={classes.error}>{errors.anamnesis.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="紹介者">{'紹介者'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="introducer"
                type="text"
                {...register('introducer', {
                  required: { value: false, message: '入力必須の項目です' },
                  maxLength: { value: 50, message: '50字以内でご入力ください' },
                })}
              />
              <div className={classes.helptext}>
                刀禅を紹介された方の氏名をご記入ください
              </div>
              {errors.introducer?.message && (
                <div className={classes.error}>{errors.introducer.message}</div>
              )}
            </div>
          </div>
          {/* <div>
            <div className={classes.titles}>
              <label htmlFor="入会金支払予定日">{'入会金支払予定日'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="scheduled_date"
                type="date"
                {...register('scheduled_date', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.scheduled_date?.message && (
                <div className={classes.error}>
                  {errors.scheduled_date.message}
                </div>
              )}
            </div>
          </div> */}
          <div>
            <div className={classes.titles}>
              <label htmlFor="セミナー通知可否">{'セミナー通知可否'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="notification_of_seminar"
                type="checkbox"
                {...register('notification_of_seminar', {
                  required: { value: false, message: '入力必須の項目です' },
                })}
              />
              <div className={classes.helptext}>
                今後、セミナー情報の配信をご希望される場合は、チェックを入れてください。
              </div>
              {errors.notification_of_seminar?.message && (
                <div className={classes.error}>
                  {errors.notification_of_seminar.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="ニュース通知可否">{'ニュース通知可否'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="notification_of_news"
                type="checkbox"
                {...register('notification_of_news', {
                  required: { value: false, message: '入力必須の項目です' },
                })}
              />
              <div className={classes.helptext}>
                今後、ニュース配信をご希望される場合は、チェックを入れてください。
              </div>
              {errors.notification_of_news?.message && (
                <div className={classes.error}>
                  {errors.notification_of_news.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="参加の理由・動機">{'参加の理由・動機'}</label>
            </div>
            <div className={classes.inputs}>
              <textarea
                id="reason_and_motivation"
                type="text"
                {...register('reason_and_motivation', {
                  required: { value: false, message: '入力必須の項目です' },
                  maxLength: {
                    value: 300,
                    message: '300字以内でご記入ください',
                  },
                })}
              />
              {errors.reason_and_motivation?.message && (
                <div className={classes.error}>
                  {errors.reason_and_motivation.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="質問・要望">{'質問・要望'}</label>
            </div>
            <div className={classes.inputs}>
              <textarea
                id="question_and_request"
                type="text"
                {...register('question_and_request', {
                  required: { value: false, message: '入力必須の項目です' },
                  maxLength: {
                    value: 300,
                    message: '300字以内でご記入ください',
                  },
                })}
              />
              {errors.question_and_request?.message && (
                <div className={classes.error}>
                  {errors.question_and_request.message}
                </div>
              )}
            </div>
          </div>
          <div className={classes.button}>
            <button type="submit">登録</button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>刀禅会員入会申込フォーム</div>
      {isAuth ? (
        <>
          {form()}
          <Footer />
        </>
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default MemberCreateByHimself;
