import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import {
  seminarAssistanceIndex,
  seminarDetailForAdmin,
} from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import Footer from '../../../Footer/footer';
import various from '../../../General/various';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
  },
  container: {
    marginTop: '64px',
    '& > table': {
      width: '100%',
      margin: '16px 0px 32px 0px',
      fontSize: '1rem',
      borderCollapse: 'collapse',
      '& > thead, & > tbody': {
        '& > tr': {
          '& > td': {
            lineHeight: '20px',
            padding: '8px',
            border: 'solid 0.2px grey',
          },
          '& #name': {
            minWidth: '128px',
          },
          '& #text': {
            textAlign: 'start',
          },
        },
      },
    },
    '& > div': {
      textAlign: 'start',
      marginBottom: '64px',
    },
  },
}));

const SeminarApplyFreeWright = () => {
  const { isAuth } = useContext(AuthContext);
  const [seminar, setSeminar] = useState();
  const [data, setData] = useState([]);
  const [date, setDate] = useState();

  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: 'セミナー一覧', path: '/seminar_index' },
    {
      name: '申込状況一覧',
      path: '/seminar_apply_situation',
      data: { uuid: location.state?.uuid },
    },
    { name: '自由記入欄', path: '/seminar_apply_free_wright' },
  ];

  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  useEffect(() => {
    seminarDetailForAdmin(setSeminar, location.state.uuid);
    seminarAssistanceIndex(setData, location.state.uuid);
  }, [location]);

  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  const contents = () => {
    if (data) {
      return (
        <table>
          <thead>
            <tr>
              <td>氏名</td>
              <td>自由記入欄</td>
            </tr>
          </thead>
          <tbody>
            {data.map((obj, index) => {
              const result = [];
              if (obj.text) {
                result.push(
                  <tr key={index}>
                    <td id="name">
                      {obj.member.last_name + obj.member.first_name}
                    </td>
                    <td id="text">{obj.text}</td>
                  </tr>
                );
              }
              return result;
            })}
          </tbody>
        </table>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {isAuth && seminar ? (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">
                {seminar.title +
                  (seminar.serial_number
                    ? ` #${seminar.serial_number}`
                    : undefined)}{' '}
                参加者自由記入欄一覧
              </span>
              <span>{date}</span>
            </div>
          </div>
          <BreadCrumbs crumbs={CRUMBS} />
          <hr />
          <div className={classes.container}>{contents()}</div>
        </>
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
      <Footer />
    </>
  );
};
export default SeminarApplyFreeWright;
