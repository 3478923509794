import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import ReactPaginato from 'react-paginate';
import { useLocation, useNavigate } from 'react-router-dom';

import various from './various';

// reference: https://www.npmjs.com/package/react-paginate

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '80px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  pagination: {
    display: 'flex',
    gap: '0px 20px',
    padding: 0,
    '& > li': {
      display: 'block',
    },
  },
  pageItem: {},
  pageLink: {
    cursor: 'pointer',
  },
  active: {
    color: theme.palette.primary.main,
  },
  previousLink: {
    cursor: 'pointer',
  },
  nextLink: {
    cursor: 'pointer',
  },
  noneLink: {
    color: '#c0c0c0',
    opacity: '0.5',
    pointerEvents: 'none',
  },
  prevnextArrowIcon: {},
}));

const LAST_DISPLYA_SIZE = 1;
const AROUND_DISPLAY_PAGES = 1;

const Pagination = (props) => {
  const {
    contentsSize,
    perPage,
    setCurrentPageNumber,
    setForcePageNumber,
    forcePage,
    ids,
  } = props;

  const [currentPage, setCurrentPage] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const handlePaginate = (selectedItem) => {
    if (setForcePageNumber) setForcePageNumber(selectedItem.selected);
    setCurrentPageNumber(selectedItem.selected);
    setCurrentPage(selectedItem.selected);
    if (location.pathname.indexOf('news') !== -1) {
      navigate(`${various.general.news}/${ids.news[selectedItem.selected]}`);
    } else if (location.pathname.indexOf('seminar') !== -1) {
      navigate(
        `${various.general.seminar}/${ids.seminar[selectedItem.selected]}`
      );
    }
  };

  const arrowIcon = (iconName) => {
    return <i className={classes.prevnextArrowIcon}>{iconName}</i>;
  };

  const calculatePageCount = () => {
    return Math.ceil(contentsSize / perPage);
  };

  useEffect(() => {
    setCurrentPageNumber(currentPage);
    if (forcePage) setCurrentPage(forcePage);
  }, [currentPage, setCurrentPageNumber, forcePage, setCurrentPage]);

  return (
    <div className={classes.container}>
      <ReactPaginato
        pageCount={calculatePageCount()}
        marginPagesDisplayed={LAST_DISPLYA_SIZE}
        pageRangeDisplayed={AROUND_DISPLAY_PAGES}
        onPageChange={handlePaginate}
        forcePage={currentPage}
        containerClassName={classes.pagination}
        pageClassName={classes.pageItem}
        pageLinkClassName={classes.pageLink}
        activeClassName={classes.active}
        activeLinkClassName={classes.active}
        previousLinkClassName={
          currentPage === 0 ? classes.noneLink : classes.previousLink
        }
        nextLinkClassName={
          currentPage + 1 === calculatePageCount()
            ? classes.noneLink
            : classes.nextLink
        }
        previousLabel={arrowIcon('< prev')}
        nextLabel={arrowIcon('next >')}
        breakLabel={'...'}
        disabledClassName="disabled-button"
      />
    </div>
  );
};
export default Pagination;
