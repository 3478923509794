import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import { updateInstance } from '../../../Apis/apiHandlers';
import { seminarDetailForAdmin } from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import Footer from '../../../Footer/footer';
import ArrowButton from '../../../General/arrowButton';
import Clock from '../../../General/clock';
import ContentTitle from '../../../General/contentTitle';
import various from '../../../General/various';
import imageIndex from '../../../img/imageIndex';
import SitemapGenerator from '../../../sitemapGenerator';

const useStyles = makeStyles((theme) => ({
  published: {
    marginTop: '168px',
    marginBottom: '84px',
    textAlign: 'start',
    '& > span': {
      display: 'block',
      fontSize: '1rem',
      padding: '16px',
    },
    '& > button': {
      width: '100%',
      margin: '40px 0px 80px',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '8px 16px',
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
    '& > div': {
      width: '100%',
      fontSize: '0.9rem',
      color: 'grey',
      textAlign: 'start',
      marginBottom: '32px',
    },
    borderBottom: '2px dotted #8c8b8b',
  },

  badge: {},
  badgeEnd: {},
  memberBadge: {},
  secretBadge: {},
  badgeContainer: {
    color: '#FFF',
    textAlign: 'start',
    width: '100%',
    marginTop: '168px',
    marginBottom: '4px',
    '& > span': {
      display: 'inline-block',
      width: '80px',
      height: '20px',
      textAlign: 'center',
      verticalAlign: 'middle',
      border: 'none',
      borderRadius: '20px',
      fontSize: '0.7rem',
      marginRight: '4px',
    },
    '& $badge': {
      backgroundColor: theme.palette.primary.main,
    },
    '& $badgeEnd': {
      backgroundColor: 'grey',
    },
    '& $memberBadge': {
      backgroundColor: 'darkslategray',
    },
    '& $secretBadge': {
      backgroundColor: theme.palette.background.default,
      color: '#000',
      border: 'solid 1px #000',
    },
  },
  contentTitle: {
    display: 'flex',
    justifyContent: 'start',
    '& > div': {
      marginBottom: '64px',
      '& > div': {
        fontSize: '2.5rem',
        '& > span': {
          '& > span': {
            '& > span': {
              lineHeight: '40px',
              textAlign: 'justify',
            },
          },
        },
        '@media screen and (max-width:550px)': {
          fontSize: '2rem',
          '& > span': {
            '& > span': {
              '& > span': {
                lineHeight: '36px !important',
              },
            },
          },
        },
      },
    },
  },
  hashTags: {
    textAlign: 'start',
    fontWeight: 'bold',
    lineHeight: '24px',
    '& > span': {
      display: 'inline-block',
      marginRight: '16px',
      fontSize: '1rem',
      fontStyle: 'italic',
    },
  },
  decoTop: {
    marginTop: '112px',
    marginBottom: '56px',
    '& > img': {
      width: '100%',
    },
  },
  container: {},
  descriptionWrapper: {
    width: '100%',
    minHeight: `calc(70vw * 0.45 + 32px)`,
    borderBottom: 'solid 1px #000',
    marginBottom: '64px',
    '& > p': {
      width: '100%',
      textAlign: 'justify',
      marginTop: 0,
      marginBottom: '48px ',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      lineHeight: '28px',
      fontSize: '1rem',
      '& > span': {
        '& > a': {
          display: 'inline-block',
          '&:link': {
            color: '#000',
          },
          '&:visited': {
            color: '#000',
          },
          '&:hover': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
  },
  seminarImage: {
    width: 'calc(70vw * 0.45)',
    margin: '0px 32px 0px 0px',
    float: 'left',
    '& > img': {
      display: 'inline-block',
      width: 'calc(70vw * 0.45)',
      height: 'calc(70vw * 0.45)',
      objectFit: 'cover',
      // filter:
      //   'contrast(100%) grayscale(100%) saturate(0%) drop-shadow(2px 2px 2px black)',
      filter: 'drop-shadow(2px 2px 2px black)',
    },
  },
  shown: {
    color: 'grey',
    fontWeight: 'bold',
    cursor: 'pointer',
  },

  readWrapper: {
    display: 'flex',
    width: '100%',
    gap: '0px 5vw',
    borderBottom: 'solid 1px #000',
    '@media screen and (max-width:1000px)': {
      flexFlow: 'column',
      '& $instructorImageWrapper': {
        width: '100%',
        '& $instructorImage': {
          float: 'left',
        },
      },
      '& $readElm': {
        width: '100%',
      },
    },
  },
  instructorImageWrapper: {
    width: 'calc(70vw * 0.45)',
    paddingBottom: '64px',
  },
  instructorImage: {
    width: 'calc(70vw * 0.45 * 0.7)',
    height: 'calc(70vw * 0.45 * 0.7)',
    margin: '0px 16px 8px 0px',
    '& > img': {
      width: 'calc(70vw * 0.45 * 0.7)',
      height: 'calc(70vw * 0.45 * 0.7)',
      filter:
        'contrast(100%) grayscale(100%) saturate(0%) drop-shadow(2px 2px 0px black)',
      objectFit: 'cover',
    },
  },
  instructorBiography: {
    textAlign: 'justify',
    fontSize: '1rem',
    '& > p': {
      marginTop: 0,
      fontWeight: 'bold',
    },
    '& > span': {
      whiteSpace: 'pre-wrap',
      letterSpacing: '0.02rem',
      wordBreak: 'break-word',
      lineHeight: '28px',
    },
  },
  readElm: {
    width: '55%',
    marginBottom: '56px',
    textAlign: 'start',
    fontWeight: 'bold',
    lineHeight: '2rem',
    fontSize: '1rem',
    '@media screen and (max-width:550px)': {
      '& > div': {
        marginBottom: '16px',
        '& > div': {
          width: '100px !important',
        },
        '& $place': {
          width: '70% !important',
        },
      },
    },

    '& > div': {
      display: 'flex',
      marginBottom: '40px',
      '& > div': {
        width: '90px',
        textAlignLast: 'justify',
        marginRight: '16px',
      },
    },
  },
  place: {
    width: '70% !important',
    display: 'flex',
    flexFlow: 'column',
    textAlignLast: 'start !important',
  },
  placeNote: {
    width: '100%',
    fontSize: '0.8rem',
    fontWeight: 'normal',
    opacity: '0.5',
    verticalAlign: 'bottom',
    textAlignLast: 'start !important',
    lineHeight: '1rem !important',
  },
  docsDownload: {
    borderBottom: 'solid 1px #000',
    '& > div': {
      width: '100%',
      marginTop: '64px',
      marginBottom: '64px',
      textAlign: 'start',
      '& > p': {
        fontSize: '0.8rem',
        margin: 0,
        opacity: '0.5',
      },
      '& > a': {
        '& > button': {
          width: '40%',
          fontFamily: theme.typography.fontFamily,
          display: 'inline-block',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          color: '#FFF',
          fontWeight: 'bold',
          backgroundColor: theme.palette.primary.main,
          textAlign: 'center',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease',
          '&:hover': {
            backgroundColor: '#4f455c',
          },
        },
      },
    },
  },
  attention: {
    marginTop: '64px',
    marginBottom: '56px',
    textAlign: 'start',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    fontSize: '1rem',
    '& > div': {
      display: 'flex',
      opacity: '0.5',
      '& > div': {
        verticalAlign: 'baseline',
      },
    },
    '& > p': {
      '& > a': {
        display: 'inline-block',
        '&:link': {
          color: '#000',
        },
        '&:visited': {
          color: '#000',
        },
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  mark: {
    paddingTop: '4px',
    marginRight: '8px',
  },

  decoBottom: {
    marginTop: '56px',
    '& > img': {
      width: '100%',
    },
  },
  arrow: {
    backgroundColor: theme.palette.primary.main,
    marginTop: '64px',
    marginRight: 0,
    marginLeft: 'auto',
    width: '120px',
  },
  arrowEnd: {
    backgroundColor: 'grey',
    marginTop: '64px',
    marginRight: 0,
    marginLeft: 'auto',
    width: '120px',
    cursor: 'not-allowed',
  },
  memberOnly: {
    display: 'block',
    width: '100%',
    marginTop: '16px',
    color: 'grey',
    fontSize: '0.8rem',
    textAlign: 'end',
    '& > span': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  },
}));

const SeminarDetailConfirm = () => {
  const { isAuth } = useContext(AuthContext);
  const [detailData, setDetailData] = useState();
  const [shown, setShown] = useState(false);
  const [komaNum, setKomaNum] = useState();
  const [unitPrice, setUnitPrice] = useState();
  const [push, setPush] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const TIMEZONE = various.timezone;

  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handlePublished = async () => {
    const data = {};
    const imageFileKey = 'image';
    data[imageFileKey] = '';
    data['uuid'] = location.state.uuid;
    data['release_is'] = true;
    try {
      await updateInstance('Seminar', data, imageFileKey);
      setPush(true);
      console.log('update finish!');
    } catch (error) {
      console.log(error);
    }
  };

  const handleShown = () => {
    setShown(true);
  };

  useEffect(() => {
    if (komaNum && detailData) {
      const oneDayPrice = detailData.membership_fee;
      setUnitPrice(oneDayPrice / komaNum);
    }
  }, [komaNum, detailData]);

  useEffect(() => {
    if (detailData) {
      let komanum = 1;
      const seminarTime = {
        part2: Clock({
          timezone: TIMEZONE,
          time: null,
          string: detailData.part_two_end_time,
        }).hour,
        part3: detailData.part_three_end_time
          ? Clock({
              timezone: TIMEZONE,
              time: null,
              string: detailData.part_three_end_time,
            }).hour
          : null,
        part4: detailData.part_four_end_time
          ? Clock({
              timezone: TIMEZONE,
              time: null,
              string: detailData.part_four_end_time,
            }).hour
          : null,
      };
      if (seminarTime.part2 !== 23) komanum += 1;
      if (seminarTime.part3 !== null) komanum += 1;
      // 現状、4コマ目は肝稽古用として使用しているため、コマ数にはカウントせず
      // if (seminarTime.part4 !== null) komanum += 1;
      setKomaNum(komanum);
    }
  }, [detailData, TIMEZONE]);

  useEffect(() => {
    if (location.state.uuid) {
      seminarDetailForAdmin(setDetailData, location.state.uuid);
    }
  }, [location]);

  const seminarDetailMapping = () => {
    const linkedContent = (text) => {
      // 参考：https://omkz.net/text-url-link/
      const regExp = /(https?:\/\/[\w/:%#$&?()~.=+-]+)/g;
      return text.replace(regExp, '<a href="$1">$1</a>');
    };

    if (detailData && komaNum && unitPrice) {
      return (
        <>
          <div className={classes.badgeContainer}>
            <span className={classes.badge}>応募受付中</span>
            {detailData.subject === '会員' ? (
              <span className={classes.memberBadge}>会員のみ</span>
            ) : null}
            {detailData.is_secret ? (
              <span className={classes.secretBadge}>秘伝</span>
            ) : null}
          </div>
          <div className={classes.contentTitle}>
            <div>
              <ContentTitle
                title={
                  detailData.serial_number
                    ? {
                        titleList: [
                          detailData.title + ' #' + detailData.serial_number,
                        ],
                        sub: null,
                      }
                    : {
                        titleList: [detailData.title],
                        sub: null,
                      }
                }
              />
            </div>
          </div>
          <div className={classes.hashTags}>
            {detailData.tag.map((h, i) => {
              return <span key={i}>{` #${h.name}`}</span>;
            })}
          </div>
          <div className={classes.decoTop}>
            <img
              src={imageIndex.semianrPage.detail.decoLineTop}
              alt="decoTop"
            />
          </div>
          <div className={classes.container}>
            <div className={classes.descriptionWrapper}>
              <div className={classes.seminarImage}>
                <img src={detailData.image} alt="smeinarImage" />
              </div>
              <p>
                {shown ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: linkedContent(detailData.guidance),
                    }}
                  />
                ) : detailData.guidance.length < 401 ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: linkedContent(detailData.guidance),
                    }}
                  />
                ) : (
                  <>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: linkedContent(
                          detailData.guidance.slice(0, 401)
                        ),
                      }}
                    />
                    <span className={classes.shown} onClick={handleShown}>
                      ...もっと見る
                    </span>
                  </>
                )}
              </p>
            </div>
            <div className={classes.readWrapper}>
              {detailData.show_the_instructor ? (
                <div className={classes.instructorImageWrapper}>
                  <div className={classes.instructorImage}>
                    <img src={detailData.instructor.image} alt="instructor" />
                  </div>
                  <div className={classes.instructorBiography}>
                    <p>講師:&nbsp;{detailData.instructor.name}</p>
                    <span>{detailData.instructor.biography}</span>
                  </div>
                </div>
              ) : undefined}
              <div className={classes.readElm}>
                <div>
                  <div>対象者:</div>
                  <span>{detailData.subject}</span>
                </div>
                <div>
                  <div>定員:</div>
                  <span>{detailData.capacity}名&nbsp;/&nbsp;部</span>
                </div>
                <div>
                  <div>参加費:</div>
                  {!unitPrice ? (
                    <span>{unitPrice.toLocaleString()}円</span>
                  ) : komaNum === 1 ? (
                    <span>1,000円〜{unitPrice.toLocaleString()}円 / 通し</span>
                  ) : (
                    <span>1,000円〜{unitPrice.toLocaleString()}円 / 1コマ</span>
                  )}
                  {/* {detailData.subject === '会員' ? (
                    detailData.membership_fee ? (
                      <span>
                        会員&nbsp;:&nbsp;
                        {detailData.membership_fee.toLocaleString()}
                        円&nbsp;/&nbsp;日
                      </span>
                    ) : (
                      <span>会員&nbsp;:&nbsp;無料</span>
                    )
                  ) : (
                    <>
                      <span>
                        会員&nbsp;:&nbsp;
                        {detailData.membership_fee.toLocaleString()}
                        円&nbsp;/&nbsp;日
                      </span>
                      &nbsp;&nbsp;&nbsp;
                      <span>
                        一般&nbsp;:&nbsp;
                        {detailData.entry_fee.toLocaleString()}
                        円&nbsp;/&nbsp;日
                      </span>
                    </>
                  )} */}
                </div>
                <div>
                  <div>開催日:</div>
                  <span>
                    {
                      Clock({
                        timezone: TIMEZONE,
                        time: null,
                        string: detailData.event_date_start_time,
                      }).today
                    }
                    &nbsp;
                    {
                      Clock({
                        timezone: TIMEZONE,
                        time: null,
                        string: detailData.part_one_start_time,
                      }).hourMinutes
                    }
                    &nbsp;~
                  </span>
                </div>
                {Clock({
                  timezone: TIMEZONE,
                  time: null,
                  string: detailData.section2Start,
                }).hour === 23 &&
                Clock({
                  timezone: TIMEZONE,
                  time: null,
                  string: detailData.section2Start,
                }).minutes === 59 ? (
                  <>
                    {/* 1コマの場合 */}
                    <div>
                      <div>第一部:</div>
                      <span>
                        {
                          Clock({
                            timezone: TIMEZONE,
                            time: null,
                            string: detailData.part_one_start_time,
                          }).hourMinutes
                        }
                        &nbsp;-&nbsp;
                        {
                          Clock({
                            timezone: TIMEZONE,
                            time: null,
                            string: detailData.part_one_end_time,
                          }).hourMinutes
                        }
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    {/* 2コマ以上の場合 */}
                    <div>
                      <div>第一部:</div>
                      <span>
                        {
                          Clock({
                            timezone: TIMEZONE,
                            time: null,
                            string: detailData.part_one_start_time,
                          }).hourMinutes
                        }
                        &nbsp;-&nbsp;
                        {
                          Clock({
                            timezone: TIMEZONE,
                            time: null,
                            string: detailData.part_one_end_time,
                          }).hourMinutes
                        }
                      </span>
                    </div>
                    <div>
                      <div>第二部:</div>
                      <span>
                        {
                          Clock({
                            timezone: TIMEZONE,
                            time: null,
                            string: detailData.part_two_start_time,
                          }).hourMinutes
                        }
                        &nbsp;-&nbsp;
                        {
                          Clock({
                            timezone: TIMEZONE,
                            time: null,
                            string: detailData.part_two_end_time,
                          }).hourMinutes
                        }
                      </span>
                    </div>
                  </>
                )}
                {detailData.part_three_start_time ? (
                  <div>
                    <div>第三部:</div>
                    <span>
                      {
                        Clock({
                          timezone: TIMEZONE,
                          time: null,
                          string: detailData.part_three_start_time,
                        }).hourMinutes
                      }
                      &nbsp;-&nbsp;
                      {
                        Clock({
                          timezone: TIMEZONE,
                          time: null,
                          string: detailData.part_three_end_time,
                        }).hourMinutes
                      }
                    </span>
                  </div>
                ) : null}
                {detailData.part_four_start_time ? (
                  <div>
                    <div>懇親会:</div>
                    <span>
                      {
                        Clock({
                          timezone: TIMEZONE,
                          time: null,
                          string: detailData.part_four_start_time,
                        }).hourMinutes
                      }
                      &nbsp;-&nbsp;
                      {
                        Clock({
                          timezone: TIMEZONE,
                          time: null,
                          string: detailData.part_four_end_time,
                        }).hourMinutes
                      }
                    </span>
                  </div>
                ) : null}
                <div>
                  <div>場所:</div>
                  <div className={classes.place}>
                    <span>{detailData.display_name_of_the_place}</span>
                    <span className={classes.placeNote}>
                      ※詳細はお申し込みされた方のみにお知らせいたします。
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {detailData.documents.document && detailData.documents.is_inuse ? (
              <div className={classes.docsDownload}>
                <div>
                  <p>▶︎以下のボタンから配布資料をダウンロードしてください。</p>
                  <a href={detailData.documents.document} download>
                    <button>配布資料</button>
                  </a>
                </div>
              </div>
            ) : null}
            <div className={classes.attention}>
              <div>
                <div className={classes.mark}>
                  <imageIndex.semianrPage.detail.warning fontSize="small" />
                </div>
                <div>注意事項</div>
              </div>
              <p>{detailData.notes}</p>
            </div>
          </div>
          <div className={classes.decoBottom}>
            <img
              src={imageIndex.semianrPage.detail.decoLineBottom}
              alt="decoBottom"
            />
          </div>
          <div className={classes.arrow}>
            <ArrowButton color="#FFF" buttonName="APPLY" disabled={false} />
          </div>
          {/* <Footer /> */}
        </>
      );
    }
  };

  const object = {
    previous: location.state.previous,
    state: { uuid: location.state.uuid },
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {isAuth ? (
        <>
          <div className={classes.published}>
            <p>
              以下の内容で公開してよろしければ、「公開する」ボタンを押下してください。
            </p>
            <span>
              ご注意：
              <br />
              押下後は、「公開予定日時」を過ぎていれば即刻公開され、過ぎていなければ「公開予定」として、セミナー一覧にリストされます。公開予定日時を過ぎれば自動的に公開されます。また、併せて、”配信”を「有り」に設定していれば、公開と同時にニュース配信も行われます。
            </span>
            <button onClick={handlePublished}>公開する</button>
            <div>※以下、セミナー公開イメージです。</div>
          </div>
          {seminarDetailMapping()}
          {push ? <SitemapGenerator push={push} navigate={object} /> : ''}
          <Footer />
        </>
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default SeminarDetailConfirm;
