import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';

import OnOffByScroll from '../../../Animation/onOffByScroll';
import { AuthContext } from '../../../auth';
import various from '../../../General/various';
import { updateInstance } from '../../../Apis/apiHandlers';
import { productMasterDetail } from '../../../Apis/apiOfManaged';
import {
  managementTagsValidator3Segments,
  managementTagsValidator4Segments,
  selectDefaultCheck,
  validateLatinCharactersAndLength,
} from '../../../CommonFunction/validators';
import Footer from '../../../Footer/footer';
import { fileSizeObserver } from '../../../CommonFunction/imageManagement';
import { localStorageArrayHandler } from '../../../CommonFunction/managePermission';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
  },
  titles: {},
  inputs: {},
  helptext: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  container: {
    marginTop: '64px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media screen and (max-width:800px)': {
      '& > div': {
        width: '100%',
      },
    },
    '& > form': {
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '& > img': {
            maxWidth: '300px',
            maxHeight: '300px',
            objectFit: 'contain',
          },
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > label': {
            width: '100%',
            textAlign: 'end',
            fontSize: '1rem',
            '@media screen and (max-width:750px)': {
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
          '& > span': {
            color: 'red',
          },
        },

        '& $inputs': {
          width: '70%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input, & > textarea, & > select': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
          '& > textarea': {
            minHeight: '160px',
          },
          '& > input[type="checkbox"]': {
            boxShadow: 'none',
          },
          '& $helptext': {
            fontSize: '0.8rem',
            color: 'grey',
            '& > a': {
              textDecoration: 'underline',
              '&:link': {
                color: theme.palette.primary.main,
              },
            },
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    marginTop: '88px !important',
    justifyContent: 'end',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const ProductMasterUpdate = () => {
  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {},
  });

  const { isAuth } = useContext(AuthContext);
  const permissions = localStorageArrayHandler('parse', various.permissionKey);
  const LIMITSIZE = 2;

  const [date, setDate] = useState();
  const [product, setProduct] = useState([]);
  const [isSubscription, setIsSubscription] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: 'サービスマスター一覧', path: '/manage/product_master_index' },
    { name: 'サービスマスター更新', path: '/manage/product_master_update' },
  ];

  //   Init
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };
  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  useEffect(() => {
    const uuid = location.state.uuid;
    if (uuid) {
      productMasterDetail(setProduct, uuid);
    }
  }, [location]);

  useEffect(() => {
    if (product) {
      reset({
        name: product.name,
        description: product.description,
        statement_descriptor: product.statement_descriptor,
        brand: product.brand,
        features: product.features,
        price: product.price,
        currency: product.currency,
        is_active: product.is_active,
        is_half_model: product.is_half_model,
        is_deduction_model: product.is_deduction_model,
        product_type: product.product_type,
        billing_cycle_type: product.billing_cycle_type,
        billing_cycle_value: product.billing_cycle_value,
        billing_cycle_start_day: product.billing_cycle_start_day,
        product_category: product.product_category,
        metadata: product.metadata ? JSON.stringify(product.metadata) : null,
        create_stripe_product: product.create_stripe_product,
        management_tags: product.management_tags,
      });
    }
  }, [product, reset]);

  const onSubmit = handleSubmit(async (data) => {
    if (data.image.length > 0) {
      const environment = process.env.REACT_APP_ENVIRONMENT;
      if (environment === 'development') {
        data.title = data.name + '_dev';
      } else if (environment === 'staging') {
        data.title = data.name + '_staging';
      } else if (environment === 'production') {
        data.title = data.name;
      }
    }

    if (data.product_type === 'payment') {
      data.billing_cycle_type = null;
      data.billing_cycle_value = 1;
      data.billing_cycle_start_day = null;
    }

    data.uuid = location.state.uuid;
    const imageFileKey = 'image';

    try {
      // console.log('data: ', data);
      await updateInstance('ProductMaster', data, imageFileKey);
      console.log('update finish!');
      navigate(location.state.previous, {
        state: { previous: location.pathname, uuid: location.state.uuid },
      });
    } catch (error) {
      console.error('Error in updateInstance:', error);
    }
  });

  //  Subscriptionの監視
  const watchSubscription = watch('product_type');
  // マネジメントタグ用の監視
  const isHalfModel = watch('is_half_model');
  const isDeductionModel = watch('is_deduction_model');

  useEffect(() => {
    setIsSubscription(watchSubscription === 'subscription' ? true : false);
  }, [watchSubscription]);

  const form = () => {
    if (product) {
      return (
        <>
          <form method="POST" encType="multipart/form-data" onSubmit={onSubmit}>
            <div>
              <div className={classes.titles}>
                <label htmlFor="サービス名称">サービス名称</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="name"
                  type="text"
                  {...register('name', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                <div className={classes.helptext}>
                  Stripeの決済画面に表示される名称です。
                </div>
                {errors.name?.message && (
                  <div className={classes.error}>{errors.name.message}</div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="サービス説明">サービス説明</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <textarea
                  id="description"
                  type="textarea"
                  {...register('description', {
                    required: { value: true, message: '入力必須の項目です' },
                    maxLength: {
                      value: 400,
                      message: '400字以内でお願いします',
                    },
                  })}
                />
                <div className={classes.helptext}>
                  Stripeの決済画面に表示される説明書きです。なるべき「・*****」というように「・」を使用した箇条書きにしてください。
                </div>
                {errors.description?.message && (
                  <div className={classes.error}>
                    {errors.description.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="明細書表記">明細書表記</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="statement_descriptor"
                  type="text"
                  {...register('statement_descriptor', {
                    required: { value: true, message: '入力必須の項目です' },
                    validate: {
                      message: (v) =>
                        validateLatinCharactersAndLength(v) === true
                          ? null
                          : validateLatinCharactersAndLength(v),
                    },
                  })}
                />
                <div className={classes.helptext}>
                  クレジットカード明細書に記載される表記です。ラテン文字と数字のみ許容されます。なお文字数制限は22文字です。
                </div>
                {errors.statement_descriptor?.message && (
                  <div className={classes.error}>
                    {errors.statement_descriptor.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="管理用タグ">管理用タグ</label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="management_tags"
                  type="text"
                  {...register('management_tags', {
                    required: { value: true, message: '入力必須の項目です' },
                    validate: {
                      message: (v) => {
                        const validator =
                          isHalfModel || isDeductionModel
                            ? managementTagsValidator4Segments
                            : managementTagsValidator3Segments;
                        const validationResult = validator(v);
                        return validationResult === true
                          ? null
                          : validationResult;
                      },
                    },
                  })}
                />
                <div className={classes.helptext}>
                  名称の代わりになる管理用のタグです。基本的には"サービスカテゴリー"_"seminar等の区分"_"連番"というフォーマットで運用します。バリデーションは以下の通りです。
                  <br />
                  <ul>
                    <li>3～4文節構成</li>
                    <li>各文節はアルファベット表記</li>
                    <li>
                      本モデルがハーフモデルである場合は4文節となる。その場合の3文節目は"half"
                    </li>
                    <li>末尾文節は3桁〜5桁数字であり単価（¥）を表す</li>
                    <li>例:"event_seminar_500", "basic_membership_1000"</li>
                  </ul>
                </div>
                {errors.management_tags?.message && (
                  <div className={classes.error}>
                    {errors.management_tags.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="ブランド区分">ブランド区分</label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="brand"
                  type="text"
                  {...register('brand', {
                    required: { value: false, message: '入力必須の項目です' },
                  })}
                />
                <div className={classes.helptext}>
                  管理用の区分です。現在未使用です。
                </div>
                {errors.brand?.message && (
                  <div className={classes.error}>{errors.brand.message}</div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="機能リスト">機能リスト</label>
              </div>
              <div className={classes.inputs}>
                <textarea
                  id="features"
                  type="textarea"
                  {...register('features', {
                    required: { value: false, message: '入力必須の項目です' },
                    maxLength: {
                      value: 400,
                      message: '400字以内でお願いします',
                    },
                  })}
                />
                <div className={classes.helptext}>
                  Stripeのどこかに表記できる機能リストです。現在未使用です。
                </div>
                {errors.features?.message && (
                  <div className={classes.error}>{errors.features.message}</div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="金額">金額</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="price"
                  type="text"
                  {...register('price', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                <div className={classes.helptext}>提供する定価価格です。</div>
                {errors.price?.message && (
                  <div className={classes.error}>{errors.price.message}</div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="通貨コード">通貨コード</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="currency"
                  type="text"
                  {...register('currency', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                  disabled
                />
                <div className={classes.helptext}>
                  世界が見えたら選択式にします。
                </div>
                {errors.currency?.message && (
                  <div className={classes.error}>{errors.currency.message}</div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="提供中">提供中</label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="is_active"
                  type="checkbox"
                  {...register('is_active', {
                    required: { value: false, message: '入力必須の項目です' },
                  })}
                />
                <div className={classes.helptext}>
                  提供中であればチェックを入れてください。提供を休止される場合はチェックを外してください。
                </div>
                {errors.is_active?.message && (
                  <div className={classes.error}>
                    {errors.is_active.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="半額モデル区分">半額モデル区分</label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="is_half_model"
                  type="checkbox"
                  {...register('is_half_model', {
                    required: { value: false, message: '入力必須の項目です' },
                  })}
                />
                <div className={classes.helptext}>
                  基本的には想定していませんが、仮に半額モデルとしての扱いから外したい場合はチェックを外してください。なおその場合は名称や案内書などの修正が必要になることが想定されます。
                </div>
                {errors.is_half_model?.message && (
                  <div className={classes.error}>
                    {errors.is_half_model.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="入会金控除モデル区分">
                  入会金控除モデル区分
                </label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="is_deduction_model"
                  type="checkbox"
                  {...register('is_deduction_model', {
                    required: { value: false, message: '入力必須の項目です' },
                  })}
                  disabled
                  style={{ cursor: 'not-allowed' }}
                />
                <div className={classes.helptext}>
                  基本的には想定していませんが、仮に入会金控除モデルとしての扱いから外したい場合はチェックを外してください。なおその場合は名称や案内書などの修正が必要になることが想定されます。
                  <br />
                  ※現状、入会金変換モデルの運用は停止中です。
                </div>
                {errors.is_deduction_model?.message && (
                  <div className={classes.error}>
                    {errors.is_deduction_model.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="サービスタイプ">{'サービスタイプ'}</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <select
                  id="product_type"
                  defaultValue="choice"
                  {...register('product_type', {
                    required: { value: true, message: '入力必須の項目です' },
                    validate: {
                      message: (v) =>
                        selectDefaultCheck(v)
                          ? null
                          : `いずれかを選択してください。`,
                    },
                  })}
                >
                  <option value="choice">選択してください</option>
                  <option value="subscription">subscription</option>
                  <option value="payment">payment</option>
                </select>
                <div className={classes.helptext}>
                  定例会員 = 'subscription', 回数会員またはセミナー = 'payment'
                </div>
                {errors.product_type?.message && (
                  <div className={classes.error}>
                    {errors.product_type.message}
                  </div>
                )}
              </div>
            </div>
            {isSubscription && (
              <>
                <div>
                  <div className={classes.titles}>
                    <label htmlFor="請求周期の単位">{'請求周期の単位'}</label>
                    <span> *</span>
                  </div>
                  <div className={classes.inputs}>
                    <select
                      id="billing_cycle_type"
                      defaultValue="choice"
                      {...register('billing_cycle_type', {
                        required: {
                          value: true,
                          message: '入力必須の項目です',
                        },
                        validate: {
                          message: (v) =>
                            selectDefaultCheck(v)
                              ? null
                              : `いずれかを選択してください。`,
                        },
                      })}
                    >
                      <option value="choice">選択してください</option>
                      <option value="daily">日毎</option>
                      <option value="weekly">週毎</option>
                      <option value="monthly">月毎</option>
                      <option value="yearly">年毎</option>
                    </select>
                    {errors.billing_cycle_type?.message && (
                      <div className={classes.error}>
                        {errors.billing_cycle_type.message}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div className={classes.titles}>
                    <label htmlFor="請求周期の値">{'請求周期の値'}</label>
                    <span> *</span>
                  </div>
                  <div className={classes.inputs}>
                    <input
                      id="billing_cycle_value"
                      type="number"
                      {...register('billing_cycle_value', {
                        required: {
                          value: true,
                          message: '入力必須の項目です',
                        },
                      })}
                    />
                    <div className={classes.helptext}>
                      例：請求周期の単位が「月毎」で請求周期の値が「1」であれば「毎月1度請求する」ということになります。
                    </div>
                    {errors.billing_cycle_value?.message && (
                      <div className={classes.error}>
                        {errors.billing_cycle_value.message}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div className={classes.titles}>
                    <label htmlFor="請求周期の開始日">
                      {'請求周期の開始日'}
                    </label>
                    <span> *</span>
                  </div>
                  <div className={classes.inputs}>
                    <input
                      id="billing_cycle_start_day"
                      type="number"
                      {...register('billing_cycle_start_day', {
                        required: {
                          value: true,
                          message: '入力必須の項目です',
                        },
                      })}
                    />
                    <div className={classes.helptext}>
                      例：請求周期の単位が「月毎」で、請求周期の値が「1」で、請求周期の開始日が「1」であれば「毎月1日に請求する」ということになります。
                    </div>
                    {errors.billing_cycle_start_day?.message && (
                      <div className={classes.error}>
                        {errors.billing_cycle_start_day.message}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            <div>
              <div className={classes.titles}>
                <label htmlFor="サービスカテゴリー">
                  {'サービスカテゴリー'}
                </label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <select
                  id="product_category"
                  defaultValue="choice"
                  {...register('product_category', {
                    required: { value: true, message: '入力必須の項目です' },
                    validate: {
                      message: (v) =>
                        selectDefaultCheck(v)
                          ? null
                          : `いずれかを選択してください。`,
                    },
                  })}
                >
                  <option value="choice">選択してください</option>
                  <option value="basic">basic</option>
                  <option value="event">event</option>
                  <option value="material">material</option>
                </select>
                <div className={classes.helptext}>
                  会費 = 'basic', セミナー等 = 'event', その他物販 = 'material'
                </div>
                {errors.product_category?.message && (
                  <div className={classes.error}>
                    {errors.product_category.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="MetaData">MetaData</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <textarea
                  id="metadata"
                  type="textarea"
                  {...register('metadata', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                <div className={classes.helptext}>
                  関連付けるデータがあればJSON形式で入力してください。
                </div>
                {errors.metadata?.message && (
                  <div className={classes.error}>{errors.metadata.message}</div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="image">{'image'}</label>
              </div>
              <div className={classes.inputs}>
                <p style={{ fontSize: '0.8rem' }}>登録されているイメージ:</p>
                {product.image ? (
                  <img
                    src={product.image}
                    alt="product_image"
                    style={{ marginBottom: '8px' }}
                  />
                ) : (
                  '未登録'
                )}
                <input
                  id="image"
                  type="file"
                  accept="image/*,.pdf"
                  {...register('image', {
                    required: { value: false, message: '入力必須の項目です' },
                    validate: {
                      message: (v) =>
                        fileSizeObserver(v, LIMITSIZE)
                          ? null
                          : `イメージファイルは${LIMITSIZE}MB以下にしてください`,
                    },
                  })}
                />
                <div className={classes.helptext}>
                  Stripeの決済画面に表示されるサムネイル画像です。なくても構いません。
                  <br />
                  残念ながら、現時点でDBへの画像登録は問題ないがStripeへ登録ができていない状況です。CORSの問題と思われます。とりあえず関連画像はDBへの登録までです。したがってユーザーには表示されません。
                </div>
                {errors.image?.message && (
                  <div className={classes.error}>{errors.image.message}</div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="Stripe登録商品の更新">
                  Stripe登録商品の更新
                </label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="create_stripe_product"
                  type="checkbox"
                  {...register('create_stripe_product', {
                    required: { value: false, message: '入力必須の項目です' },
                  })}
                />
                <div className={classes.helptext}>
                  チェックが入っていれば関連づいている既存のStripeの商品マスターを更新します。
                </div>
                {errors.create_stripe_product?.message && (
                  <div className={classes.error}>
                    {errors.create_stripe_product.message}
                  </div>
                )}
              </div>
            </div>
            <div className={classes.button}>
              <button type="submit">更新</button>
            </div>
          </form>
        </>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {!isAuth ? (
        <>
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleAuth}
            >
              ログイン
            </span>
            してください。
          </div>
        </>
      ) : permissions.includes('admin') ? (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">サービスマスターの更新</span>
              <span>{date}</span>
            </div>
          </div>
          <hr />
          <BreadCrumbs crumbs={CRUMBS} />
          <div className={classes.container}>{form()}</div>
        </>
      ) : permissions === 'null' ? (
        setTimeout(() => {
          window.location.reload(true);
        }, 500)
      ) : (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">サービスマスターの更新</span>
              <span>{date}</span>
            </div>
          </div>
          <hr />
          <div className={classes.container}>権限がありません</div>
        </>
      )}
      <Footer />
    </>
  );
};
export default ProductMasterUpdate;
