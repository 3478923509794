import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../auth';
import various from '../../../General/various';
import { emailRegValidator } from '../../../CommonFunction/validators';
import { memberIndex, memberDetail } from '../../../Apis/apiOfManaged';
import { createInstance } from '../../../Apis/apiHandlers';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import Footer from '../../../Footer/footer';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  para: {
    width: '70%',
    margin: '32px auto',
    fontSize: '0.9rem',
    textAlign: 'start',
  },
  titles: {},
  inputs: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  container: {
    marginTop: '168px',
    width: '100%',
    '& > form': {
      '& > p': {
        fontSize: '0.7em',
        textAlign: 'center',
      },
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          '& > label': {
            width: '100%',
            textAlign: 'end',
            '@media screen and (max-width:750px)': {
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
          '& > span': {
            color: 'red',
          },
        },

        '& $inputs': {
          width: '70%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input, & > textarea, & > select': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    marginTop: '88px !important',
    justifyContent: 'end',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const MemberCreateSendForm = () => {
  const { isAuth } = useContext(AuthContext);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {},
  });

  const [uuid, setUuid] = useState();
  const [member, setMember] = useState();
  const [members, setMembers] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const flag = location.state.memberClass;

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '会員一覧', path: '/member_index' },
    { name: '会員登録送信フォーム', path: '/member_create_send_form' },
  ];

  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  useEffect(() => {
    if (location.state.uuid) {
      setUuid(location.state.uuid);
    }
  }, [location]);

  useEffect(() => {
    if (member) {
      let last_name = undefined;
      let first_name = undefined;
      if (member.last_name) {
        last_name = member.last_name;
        first_name = member.first_name;
      }
      const resetValues = {
        email: member.email,
        last_name: last_name,
        first_name: first_name,
      };
      reset(resetValues);
    }
  }, [member, reset]);

  useEffect(() => {
    if (uuid) {
      memberDetail(setMember, uuid);
    }
  }, [uuid]);

  useEffect(() => {
    memberIndex(setMembers);
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    if (members.length > 0) {
      const emails = members.map((m) => {
        return m.email;
      });
      if (emails.includes(data.email)) {
        data['existance'] = true;
      } else {
        data['existance'] = false;
      }
      const imageFileKey = 'image';
      data[imageFileKey] = '';
      data['uuid'] = uuid;

      const protocol = window.location.protocol;
      const host = window.location.host;
      const domain = document.domain;
      const path = 'member_create_by_himself';
      const pathForPass = 'password_setting';
      const dataset = {
        email: data.email,
        protocol: protocol,
        host: host,
        path: path,
        pathForPass: pathForPass,
        domain: domain,
      };
      data['urlData'] = dataset;
      data['flag'] = flag;

      try {
        await createInstance('MemberCreateSendForm', data, imageFileKey);
        console.log('create finish!');
        navigate(location.state.previous);
      } catch (error) {
        console.error('Error in MemberCreateSendForm:', error);
      }
    }
  });

  const form = () => {
    return (
      <div className={classes.container}>
        <form method="POST" encType="multipart/form-data" onSubmit={onSubmit}>
          <div>
            <div className={classes.titles}>
              <label htmlFor="宛先 お名前（姓）">{'宛先 お名前（姓）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="last_name"
                type="text"
                {...register('last_name', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.last_name?.message && (
                <div className={classes.error}>{errors.last_name.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="宛先 お名前（名）">{'宛先 お名前（名）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="first_name"
                type="text"
                {...register('first_name', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.first_name?.message && (
                <div className={classes.error}>{errors.first_name.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="宛先 Eメール">{'宛先 Eメール'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="email"
                type="email"
                {...register('email', {
                  required: { value: true, message: '入力必須の項目です' },
                  validate: {
                    message: (v) =>
                      emailRegValidator(v)
                        ? null
                        : `※正しいEメールの形式で入力してください`,
                  },
                })}
              />
              {errors.email?.message && (
                <div className={classes.error}>{errors.email.message}</div>
              )}
            </div>
          </div>
          <div className={classes.button}>
            <button type="submit">送信</button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {isAuth ? (
        <>
          <div className={classes.pageTitle}>
            {flag === 'himself'
              ? '入会希望者への登録フォームの送信'
              : flag === 'member_of_group_himself'
              ? '稽古会会員として入会希望者への登録フォームの送信'
              : undefined}
          </div>
          <hr />
          <BreadCrumbs crumbs={CRUMBS} />
          <p className={classes.para}>
            入会を希望される方のお名前とEメールアドレスをご入力ください。ニュースの配信を受け取っている方ならばEメールアドレスが、
            体験稽古やセミナーに過去に参加された方であれば、既に受け取っている情報が記入済みの申込フォームが送られます。
          </p>
          {form()}
        </>
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
      <Footer />
    </>
  );
};
export default MemberCreateSendForm;
