import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';

import OnOffByScroll from '../../../Animation/onOffByScroll';
import { AuthContext } from '../../../auth';
import various from '../../../General/various';
import { updateInstance } from '../../../Apis/apiHandlers';
import { branchDetailForAdmin, memberIndex } from '../../../Apis/apiOfManaged';
import { selectDefaultCheck } from '../../../CommonFunction/validators';
import Footer from '../../../Footer/footer';
import { fileSizeObserver } from '../../../CommonFunction/imageManagement';
import { localStorageArrayHandler } from '../../../CommonFunction/managePermission';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
  },
  titles: {},
  inputs: {},
  helptext: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  container: {
    marginTop: '64px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media screen and (max-width:800px)': {
      '& > div': {
        width: '100%',
      },
    },
    '& > form': {
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '& > img': {
            maxWidth: '300px',
            maxHeight: '300px',
            objectFit: 'contain',
          },
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > label': {
            width: '100%',
            textAlign: 'end',
            fontSize: '1rem',
            '@media screen and (max-width:750px)': {
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
          '& > span': {
            color: 'red',
          },
        },

        '& $inputs': {
          width: '70%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input, & > textarea, & > select': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
          '& > textarea': {
            minHeight: '160px',
          },
          '& > input[type="checkbox"]': {
            boxShadow: 'none',
          },
          '& $helptext': {
            fontSize: '0.8rem',
            color: 'grey',
            '& > a': {
              textDecoration: 'underline',
              '&:link': {
                color: theme.palette.primary.main,
              },
            },
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    marginTop: '88px !important',
    justifyContent: 'end',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const BranchUpdate = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {},
  });

  const { isAuth } = useContext(AuthContext);
  const permissions = localStorageArrayHandler('parse', various.permissionKey);
  const LIMITSIZE = 5;

  const [date, setDate] = useState();
  const [members, setMembers] = useState();
  const [branch, setBranch] = useState();
  const [targets, setTargets] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '同好会・特別稽古会一覧', path: '/manage/branch_index' },
    { name: '同好会・特別稽古会更新', path: '/manage/branch_update' },
  ];

  // Init
  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  useEffect(() => {
    const uuid = location.state.uuid;
    if (uuid) {
      branchDetailForAdmin(setBranch, uuid);
      memberIndex(setMembers);
    }
  }, [location]);

  useEffect(() => {
    if (members) {
      setTargets(
        // eslint-disable-next-line
        members.map((member, index) => {
          if (member.member_of_club_manager_is) {
            return (
              <option key={`member_${index}`} value={member.email}>
                {member.last_name + member.first_name}
              </option>
            );
          }
        })
      );
    }
  }, [members]);

  useEffect(() => {
    if (branch && members) {
      const member = members.filter((m) => {
        return m.email === branch.user.email;
      })[0];
      reset({
        branch_name: branch.branch_name,
        branch_name_kana: branch.branch_name_kana,
        club_categories: branch.branch_is
          ? 'branch_is'
          : branch.club_is
          ? 'club_is'
          : branch.special_class_is
          ? 'special_class_is'
          : '',
        area_categories: branch.area_categories,
        activity_area: branch.activity_area,
        representative: branch.representative,
        representative_kana: branch.representative_kana,
        specialized_field: branch.specialized_field,
        email: branch.email,
        main_training_days: branch.main_training_days,
        information: branch.information,
        branch_url: branch.branch_url,
        instagram_url: branch.instagram_url,
        twitter_url: branch.twitter_url,
        facebook_url: branch.facebook_url,
        blog_url: branch.blog_url,
        youtube_url: branch.youtube_url,
        calendar_url: branch.calendar_url,
        display_order: branch.display_order,
        is_useful: branch.is_useful,
        user: member ? member.email : '',
      });
    }
  }, [branch, members, reset]);

  // Util
  const nameChecker = (branchName) => {
    if (branchName.slice(0, 1) !== '刀禅') {
      branchName = '刀禅' + branchName;
    }
    if (branchName.slice(-1, -3) !== '同好会') {
      branchName = branchName + '同好会';
    }
    return branchName;
  };

  // Submit
  const onSubmit = handleSubmit(async (data) => {
    if (data.image.length > 0) {
      const environment = process.env.REACT_APP_ENVIRONMENT;
      if (environment === 'development') {
        data.title = data.branch_name + '_dev';
      } else if (environment === 'staging') {
        data.title = data.branch_name + '_staging';
      } else if (environment === 'production') {
        data.title = data.branch_name;
      }
    }
    if (data.club_categories === 'club_is') {
      data.branch_name = nameChecker(data.branch_name);
    }
    data.uuid = location.state.uuid;
    const imageFileKey = 'image';

    try {
      await updateInstance('Branch', data, imageFileKey);
      console.log('update finish!');
      navigate(location.state.previous, {
        state: { previous: location.pathname, uuid: location.state.uuid },
      });
    } catch (error) {
      console.error('Error in updateInstance:', error);
    }
  });

  // Hnadler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  // Content
  const form = () => {
    if (branch && members) {
      return (
        <>
          <form method="POST" encType="multipart/form-data" onSubmit={onSubmit}>
            <div>
              <div className={classes.titles}>
                <label htmlFor="稽古会名称">稽古会名称</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="branch_name"
                  type="text"
                  {...register('branch_name', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                <div className={classes.helptext}>
                  同好会の場合、名称の頭に「刀禅」をつけ、名称の最後に「同好会」をつけてください。ついていなかった場合、自動で付されます。
                </div>
                {errors.branch_name?.message && (
                  <div className={classes.error}>
                    {errors.branch_name.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="稽古会名称（カナ）">稽古会名称（カナ）</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="branch_name_kana"
                  type="text"
                  {...register('branch_name_kana', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                {errors.branch_name_kana?.message && (
                  <div className={classes.error}>
                    {errors.branch_name_kana.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="稽古会区分">稽古会区分</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <select
                  id="club_categories"
                  defaultValue="choice"
                  {...register('club_categories', {
                    required: { value: true, message: '入力必須の項目です' },
                    validate: {
                      message: (v) =>
                        selectDefaultCheck(v)
                          ? null
                          : `いずれかを選択してください。`,
                    },
                  })}
                >
                  <option value="choice">選択してください</option>
                  <option value="branch_is">支部</option>
                  <option value="club_is">同好会</option>
                  <option value="special_class_is">特別稽古会</option>
                  <option value="others">その他</option>
                </select>
                {errors.club_categories?.message && (
                  <div className={classes.error}>
                    {errors.club_categories.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="エリア">エリア</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="area_categories"
                  type="text"
                  {...register('area_categories', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                <div className={classes.helptext}>
                  同好会の場合は、「都道府県名」を記載してください。特別稽古会の団体の場合「特別稽古会」と記載してください。
                </div>
                {errors.area_categories?.message && (
                  <div className={classes.error}>
                    {errors.area_categories.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="エリア">エリア</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="activity_area"
                  type="text"
                  {...register('activity_area', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                <div className={classes.helptext}>
                  "埼玉県蕨市"等、市区町村いずれかのレベルまで記載してください。
                </div>
                {errors.activity_area?.message && (
                  <div className={classes.error}>
                    {errors.activity_area.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="主宰者">主宰者</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="representative"
                  type="text"
                  {...register('representative', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                <div className={classes.helptext}>
                  主宰される会員の名称（公称）をフルネームで記載してください。
                </div>
                {errors.representative?.message && (
                  <div className={classes.error}>
                    {errors.representative.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="主宰者（カナ）">主宰者（カナ）</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="representative_kana"
                  type="text"
                  {...register('representative_kana', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                {errors.representative_kana?.message && (
                  <div className={classes.error}>
                    {errors.representative_kana.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="専門領域">専門領域</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="specialized_field"
                  type="text"
                  {...register('specialized_field', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                <div className={classes.helptext}>
                  主宰者または主に指導に従事される方の専門分野（例えば”空手”等、武道・武術流派名に限らない）をご記載ください。
                </div>
                {errors.specialized_field?.message && (
                  <div className={classes.error}>
                    {errors.specialized_field.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="団体Email">団体Email</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="email"
                  type="email"
                  {...register('email', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                <div className={classes.helptext}>
                  問い合わせ等の自動メールが転送されるアドレスです。
                </div>
                {errors.email?.message && (
                  <div className={classes.error}>{errors.email.message}</div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="主な稽古日等">主な稽古日時</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="main_training_days"
                  type="text"
                  {...register('main_training_days', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                <div className={classes.helptext}>
                  ”毎週日曜日9:00~12:00”のように可能であれば時間帯までご記載ください。また、掲出画面にて改行を入れたい場合は、「半角スペース」を入力してください。逆の場合、半角スペースを入力すると改行してしまうので、カンマ等で表現してください。
                </div>
                {errors.main_training_days?.message && (
                  <div className={classes.error}>
                    {errors.main_training_days.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="紹介文">紹介文</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <textarea
                  id="information"
                  type="textarea"
                  {...register('information', {
                    required: { value: true, message: '入力必須の項目です' },
                    maxLength: {
                      value: 400,
                      message: '400字以内でお願いします',
                    },
                  })}
                />
                <div className={classes.helptext}>
                  400文字以内で記述してください。
                </div>
                {errors.information?.message && (
                  <div className={classes.error}>
                    {errors.information.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="Webサイト">WebサイトURL</label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="branch_url"
                  type="url"
                  {...register('branch_url', {
                    required: { value: false, message: '入力必須の項目です' },
                  })}
                />
                {errors.branch_url?.message && (
                  <div className={classes.error}>
                    {errors.branch_url.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="Instagram">InstagramURL</label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="instagram_url"
                  type="url"
                  {...register('instagram_url', {
                    required: { value: false, message: '入力必須の項目です' },
                  })}
                />
                {errors.instagram_url?.message && (
                  <div className={classes.error}>
                    {errors.instagram_url.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="Twitter">TwitterURL</label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="twitter_url"
                  type="url"
                  {...register('twitter_url', {
                    required: { value: false, message: '入力必須の項目です' },
                  })}
                />
                {errors.twitter_url?.message && (
                  <div className={classes.error}>
                    {errors.twitter_url.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="Facebook">FacebookURL</label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="facebook_url"
                  type="url"
                  {...register('facebook_url', {
                    required: { value: false, message: '入力必須の項目です' },
                  })}
                />
                {errors.facebook_url?.message && (
                  <div className={classes.error}>
                    {errors.facebook_url.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="Blog">BlogURL</label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="blog_url"
                  type="url"
                  {...register('blog_url', {
                    required: { value: false, message: '入力必須の項目です' },
                  })}
                />
                {errors.blog_url?.message && (
                  <div className={classes.error}>{errors.blog_url.message}</div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="YouTubu">YouTubuURL</label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="youtube_url"
                  type="url"
                  {...register('youtube_url', {
                    required: { value: false, message: '入力必須の項目です' },
                  })}
                />
                {errors.youtube_url?.message && (
                  <div className={classes.error}>
                    {errors.youtube_url.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="カレンダー">カレンダーURL</label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="calendar_url"
                  type="url"
                  {...register('calendar_url', {
                    required: { value: false, message: '入力必須の項目です' },
                  })}
                />
                {errors.calendar_url?.message && (
                  <div className={classes.error}>
                    {errors.calendar_url.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="活動区分">活動区分</label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="is_useful"
                  type="checkbox"
                  {...register('is_useful', {
                    required: { value: false, message: '入力必須の項目です' },
                  })}
                />
                <div className={classes.helptext}>
                  活動中であればチェックを入れてください。活動を休止される場合はチェックを外してください。ここにチェックが入っていない限り、一般公開はされません。
                </div>
                {errors.is_useful?.message && (
                  <div className={classes.error}>
                    {errors.is_useful.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="表示順位">表示順位</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="display_order"
                  type="number"
                  {...register('display_order', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                {errors.display_order?.message && (
                  <div className={classes.error}>
                    {errors.display_order.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="アカウント">{'アカウント'}</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <select
                  id="user"
                  defaultValue="choice"
                  {...register('user', {
                    required: { value: true, message: '入力必須の項目です' },
                    validate: {
                      message: (v) =>
                        selectDefaultCheck(v)
                          ? null
                          : `主宰者となられる会員を選択してください。`,
                    },
                  })}
                >
                  <option value="choice">選択してください</option>
                  {targets}
                </select>
                <div className={classes.helptext}>
                  選択肢に該当の方がいない場合は、
                  <a href={various.general.memberIndex}>会員一覧</a>
                  より会員更新画面にいき、「同好会主宰者区分」にチェックを入れてください。
                </div>
                {errors.user?.message && (
                  <div className={classes.error}>{errors.user.message}</div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="image">{'差し替え画像アップロード'}</label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="image"
                  type="file"
                  accept="image/*,.pdf"
                  {...register('image', {
                    required: { value: false, message: '入力必須の項目です' },
                    validate: {
                      message: (v) =>
                        fileSizeObserver(v, LIMITSIZE)
                          ? null
                          : `イメージファイルは${LIMITSIZE}MB以下にしてください`,
                    },
                  })}
                />
                {errors.image?.message && (
                  <div className={classes.error}>{errors.image.message}</div>
                )}
              </div>
            </div>
            <div className={classes.button}>
              <button type="submit">更新</button>
            </div>
          </form>
        </>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {!isAuth ? (
        <>
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleAuth}
            >
              ログイン
            </span>
            してください。
          </div>
        </>
      ) : permissions.includes('admin') ? (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">稽古会情報の更新</span>
              <span>{date}</span>
            </div>
          </div>
          <hr />
          <BreadCrumbs crumbs={CRUMBS} />
          <div className={classes.container}>{form()}</div>
        </>
      ) : permissions === 'null' ? (
        setTimeout(() => {
          window.location.reload(true);
        }, 500)
      ) : (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">稽古会情報の更新</span>
              <span>{date}</span>
            </div>
          </div>
          <hr />
          <div className={classes.container}>権限がありません</div>
        </>
      )}
      <Footer />
    </>
  );
};
export default BranchUpdate;
