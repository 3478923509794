import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';

import OnOffByScroll from '../../../Animation/onOffByScroll';
import { AuthContext } from '../../../auth';
import various from '../../../General/various';
import {
  trainingHallIndex,
  trainingPlaceDetailForAdmin,
  trainingPlacePatchUpdate,
} from '../../../Apis/apiOfManaged';
import Footer from '../../../Footer/footer';
import { localStorageArrayHandler } from '../../../CommonFunction/managePermission';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
  },
  titles: {},
  inputs: {},
  helptext: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  container: {
    marginTop: '64px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media screen and (max-width:800px)': {
      '& > div': {
        width: '100%',
      },
    },
    '& > form': {
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '& > img': {
            maxWidth: '300px',
            maxHeight: '300px',
            objectFit: 'contain',
          },
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > label': {
            width: '100%',
            textAlign: 'end',
            fontSize: '1rem',
            '@media screen and (max-width:750px)': {
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
          '& > span': {
            color: 'red',
          },
        },

        '& $inputs': {
          width: '70%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input, & > textarea, & > select': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
          '& > textarea': {
            minHeight: '160px',
          },
          '& > input[type="checkbox"]': {
            boxShadow: 'none',
          },
          '& $helptext': {
            fontSize: '0.8rem',
            color: 'grey',
            '& > a': {
              textDecoration: 'underline',
              '&:link': {
                color: theme.palette.primary.main,
              },
            },
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    marginTop: '88px !important',
    justifyContent: 'end',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
  addBtn: {
    width: '100%',
    textAlign: 'center',
    marginTop: '24px',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    appearance: 'button',
  },
}));

const PlaceAndHallUpdate = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {},
  });

  const { isAuth } = useContext(AuthContext);
  const permissions = localStorageArrayHandler('parse', various.permissionKey);

  const [date, setDate] = useState();
  const [place, setPlace] = useState();
  const [halls, setHalls] = useState();
  const [targets, setTargets] = useState();
  const [inputs, setInputs] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '稽古場一覧', path: '/manage/place_hall_index' },
    { name: '稽古場更新', path: '/manage/place_hall_update' },
  ];

  // Init
  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  useEffect(() => {
    const uuid = location.state.uuid;
    if (uuid) {
      trainingPlaceDetailForAdmin(setPlace, uuid);
      trainingHallIndex(setHalls);
    }
  }, [location]);

  useEffect(() => {
    if (place && halls) {
      const defaultValues = {
        facility_name: place.facility_name,
        facility_name_short: place.facility_name_short,
        in_use_is: place.in_use_is,
        phone_number: place.phone_number,
        address: place.address,
        url: place.url,
        gmap: place.gmap,
        remarks: place.remarks,
        display_order: place.display_order,
      };
      const targetValues = {};

      const inputsResult = halls
        .filter((hall) => {
          return place.uuid === hall.training_place;
        })
        .map((hall, index) => {
          let key = `training_place${index + 1}`;
          defaultValues[key] = hall.name;
          targetValues[key] = { uuid: hall.uuid };
          return '';
        });

      reset(defaultValues);
      setTargets(targetValues);
      setInputs(inputsResult);
    }
  }, [place, halls, reset]);

  // Submit
  const onSubmit = handleSubmit((data) => {
    data['uuid'] = location.state.uuid;
    data['halls'] = targets;
    trainingPlacePatchUpdate(data);
    setTimeout(() => {
      console.log('update finish!');
      navigate(location.state.previous, {
        state: { previous: location.pathname, uuid: location.state.uuid },
      });
      setTimeout(() => {
        window.location.reload(true);
      }, 300);
    }, 1000);
  });

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleAddInput = () => {
    setInputs([...inputs, '']);
  };

  // Contents
  const form = () => {
    if (place) {
      return (
        <>
          <form method="POST" encType="multipart/form-data" onSubmit={onSubmit}>
            <div>
              <div className={classes.titles}>
                <label htmlFor="施設名称">施設名称</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="facility_name"
                  type="text"
                  {...register('facility_name', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                {errors.facility_name?.message && (
                  <div className={classes.error}>
                    {errors.facility_name.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="施設名略称">施設名略称</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="facility_name_short"
                  type="text"
                  {...register('facility_name_short', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                {errors.facility_name_short?.message && (
                  <div className={classes.error}>
                    {errors.facility_name_short.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="使用区分">使用区分</label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="in_use_is"
                  type="checkbox"
                  {...register('in_use_is', {
                    required: { value: false, message: '入力必須の項目です' },
                  })}
                />
                <div className={classes.helptext}>
                  使用中の施設であればチェックを入れてください。使用する見込みがなくなったらチェックを外してください。チェックを外れた施設はセミナー会場として選択することができなくなります。また、MyPage内で稽古場としてリストされません。
                </div>
                {errors.in_use_is?.message && (
                  <div className={classes.error}>
                    {errors.in_use_is.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="電話番号">電話番号</label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="phone_number"
                  type="text"
                  {...register('phone_number', {
                    required: { value: false, message: '入力必須の項目です' },
                  })}
                />
                {errors.phone_number?.message && (
                  <div className={classes.error}>
                    {errors.phone_number.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="住所">住所</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="address"
                  type="text"
                  {...register('address', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                {errors.address?.message && (
                  <div className={classes.error}>{errors.address.message}</div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="URL">URL</label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="url"
                  type="url"
                  {...register('url', {
                    required: { value: false, message: '入力必須の項目です' },
                  })}
                />
                {errors.url?.message && (
                  <div className={classes.error}>{errors.url.message}</div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="GoogleMap">GoogleMap</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="gmap"
                  type="url"
                  {...register('gmap', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                {errors.gmap?.message && (
                  <div className={classes.error}>{errors.gmap.message}</div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="備考">備考</label>
              </div>
              <div className={classes.inputs}>
                <textarea
                  id="remarks"
                  type="textarea"
                  {...register('remarks', {
                    required: { value: false, message: '入力必須の項目です' },
                    maxLength: {
                      value: 200,
                      message: '200字以内でお願いします',
                    },
                  })}
                />
                <div className={classes.helptext}>
                  200文字以内で記述してください。
                </div>
                {errors.remarks?.message && (
                  <div className={classes.error}>{errors.remarks.message}</div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="表示順位">表示順位</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="display_order"
                  type="number"
                  {...register('display_order', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                <div className={classes.helptext}>
                  touzen.jp/branch/での表示させる順番です。現状では、同好会→特別稽古会という順番で2大カテゴリーが並び、かつ、それぞれの順番は「緯度」を基準に並べています。
                </div>
                {errors.display_order?.message && (
                  <div className={classes.error}>
                    {errors.display_order.message}
                  </div>
                )}
              </div>
            </div>
            {inputs.map((input, index) => (
              <div key={index}>
                <div className={classes.titles}>
                  <label htmlFor={`training_place${index + 1}`}>{`稽古場${
                    index + 1
                  }`}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id={`training_place${index + 1}`}
                    type="text"
                    {...register(`training_place${index + 1}`, {
                      required: {
                        value: true,
                        message: '入力必須の項目です',
                      },
                    })}
                  />
                  {index === inputs.length - 1 ? (
                    <div className={classes.helptext}>
                      施設内で実際に利用される部屋名称等をご記入ください。
                      <br />
                      例）施設が「蕨市市民体育館」であっても、”1F格技場”なのか”3Fアリーナ”なのかの違いを明確にするために、それぞれをご記入ください。
                      <br />
                      ※なお、稽古場が複数ある場合は、以下の「稽古場を追加する」ボタンを押し、入力フォームを生成してください。最大4つまで登録できます。
                    </div>
                  ) : (
                    ''
                  )}
                  {inputs.length < 4 && index === inputs.length - 1 ? (
                    <div
                      className={classes.addBtn}
                      onClick={() => handleAddInput()}
                    >
                      稽古場を追加する
                    </div>
                  ) : (
                    ''
                  )}
                  {errors[`training_place${index + 1}`]?.message && (
                    <div className={classes.error}>
                      {errors[`training_place${index + 1}`].message}
                    </div>
                  )}
                </div>
              </div>
            ))}

            <div className={classes.button}>
              <button type="submit">更新</button>
            </div>
          </form>
        </>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {!isAuth ? (
        <>
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleAuth}
            >
              ログイン
            </span>
            してください。
          </div>
        </>
      ) : permissions.includes('admin') ? (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">稽古場情報の更新</span>
              <span>{date}</span>
            </div>
          </div>
          <hr />
          <BreadCrumbs crumbs={CRUMBS} />
          <div className={classes.container}>{form()}</div>
        </>
      ) : permissions === 'null' ? (
        setTimeout(() => {
          window.location.reload(true);
        }, 500)
      ) : (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">稽古場情報の更新</span>
              <span>{date}</span>
            </div>
          </div>
          <hr />
          <div className={classes.container}>権限がありません</div>
        </>
      )}
      <Footer />
    </>
  );
};
export default PlaceAndHallUpdate;
