import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { makeStyles } from '@material-ui/core';

import { images as imageApi, resetPasswordConfirm2 } from '../Apis/apis';
import { passwordRegValidator } from '../CommonFunction/validators';
import OnOffByScroll from '../Animation/onOffByScroll';
import StandardDialog from '../Dialog/standardDialog';
import various from '../General/various';
import Footer from '../Footer/footer';
import { ogpGenerator } from '../ogp';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '500px',
  },
  formWrapper: {
    marginTop: '168px',
    '& > p': {
      textAlign: 'start',
    },
  },

  titles: {},
  inputs: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  formcontainer: {
    marginTop: '32px',
    width: '100%',
    '& > form': {
      '& > div': {
        width: '100%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '& > img': {
            maxWidth: '300px',
            maxHeight: '300px',
            objectFit: 'contain',
          },
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '5%',
          marginRight: '4px',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > span': {
            color: 'red',
          },
        },

        '& $inputs': {
          width: '80%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    width: '85% !important',
    margin: '32px 0px 0px 0px !important',
    justifyContent: 'end',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  });

  const [image, setImage] = useState();
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  // OGP設定
  const ogp = useCallback((props) => {
    ogpGenerator(props);
  }, []);

  useLayoutEffect(() => {
    if (image) {
      const ogpProps = {
        page: 'forgot_confirm',
        pageTitle: '刀禪OfficialWebSiteパスワード再設定完了ページ',
        path: location.pathname,
        imageUrl: image.OfficialImage_TouzenLogoBlack.url,
      };
      ogp(ogpProps);
    }
  }, [image, location, ogp]);
  // OGP設定ここまで

  useEffect(() => {
    imageApi(setImage);
  }, []);

  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const onSubmit = handleSubmit((data) => {
    const token = query.get('token');
    resetPasswordConfirm2(setOpen, token, data.password);
  });

  const handleClose = () => {
    setOpen(false);
  };
  const toNavigate = () => {
    return navigate(various.general.home);
  };

  return (
    <>
      <div className={classes.container}>
        <div>
          <OnOffByScroll place="General" />
        </div>
        <div className={classes.formWrapper}>
          <p>設定するパスワードを送信してください。</p>
          <div className={classes.formcontainer}>
            <form
              method="POST"
              encType="multipart/form-data"
              onSubmit={onSubmit}
            >
              <div>
                <div className={classes.titles}>
                  <label htmlFor="">{''}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="password"
                    type="password"
                    {...register('password', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          passwordRegValidator(v)
                            ? null
                            : `パスワードは8文字以上の半角英数字で設定してください。`,
                      },
                    })}
                  />
                  {errors.password?.message && (
                    <div className={classes.error}>
                      {errors.password.message}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.button}>
                <button type="submit">送信</button>
              </div>
            </form>
          </div>
        </div>
        <StandardDialog
          open={open}
          handleClose={handleClose}
          text={{
            title: 'パスワードを設定しました。',
            content:
              '返信メール等はありません。パスワードは大切に保管してください。',
            agree: '了解',
            disagree: null,
          }}
          func={{ agreeFunc: toNavigate, disagreeFunc: null }}
        />
      </div>
      <Footer />
    </>
  );
};
export default ForgotPassword;
