export function formatDate(dt, flag) {
  let y = dt.getFullYear();
  let m = ('00' + (dt.getMonth() + 1)).slice(-2);
  let d = ('00' + dt.getDate()).slice(-2);
  let h = ('00' + dt.getHours()).slice(-2);
  let mm = ('00' + dt.getMinutes()).slice(-2);
  if (flag === 'date') return y + '-' + m + '-' + d;
  else if (flag === 'datetime')
    return y + '-' + m + '-' + d + 'T' + h + ':' + mm;
}

const Clock = (props) => {
  const TIMEZONE = props.timezone ? props.timezone : 'Asia/Tokyo';
  let prefix;
  switch (TIMEZONE) {
    case 'Asia/Tokyo':
      prefix = 'ja-JP';
      break;
    default:
  }

  let time = new Date();
  if (props.time) {
    const month = props.time.month - 1;
    time = new Date(
      props.time.year,
      month,
      props.time.day,
      props.time.hour,
      props.time.minutes,
      props.time.seconds
    );
  } else if (props.string) {
    let dt = props.string;
    if (props.string.includes('T')) {
      dt = formatDate(new Date(props.string), 'datetime').replace('T', ' ');
    }
    time = new Date(Date.parse(dt.replace(/-/g, '/')));
  }

  const hour = time.getHours() < 12 ? time.getHours() : time.getHours() - 12;
  const minute = time.getMinutes() === 0 ? '00' : time.getMinutes();
  const suffix = time.getHours() < 12 ? 'a.m.' : 'p.m.';

  return {
    now: time.toLocaleString(prefix, {
      timeZone: TIMEZONE,
      hour12: false,
      weekday: 'short',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }),
    today: time.toLocaleDateString(prefix, {
      timeZone: TIMEZONE,
      hour12: false,
      weekday: 'short',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }),
    time: time,
    year: time.getFullYear(),
    month: time.getMonth() + 1,
    day: time.getDate(),
    hour: time.getHours(),
    minutes: time.getMinutes(),
    seconds: time.getSeconds(),
    hourMinutes: hour + ':' + minute + ' ' + suffix,
  };
};
export default Clock;
