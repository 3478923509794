import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../auth';
import various from '../../../General/various';
import {
  newsDetailForAdmin,
  tagIndexForAdmin,
} from '../../../Apis/apiOfManaged';
import { updateInstance } from '../../../Apis/apiHandlers';
import { images as imageApi } from '../../../Apis/apis';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import Footer from '../../../Footer/footer';
import { selectDefaultCheck } from '../../../CommonFunction/validators';
import SitemapGenerator from '../../../sitemapGenerator';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  titles: {},
  inputs: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  helptext: {},
  container: {
    marginTop: '168px',
    width: '100%',
    '& > form': {
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '& > img': {
            maxWidth: '300px',
            maxHeight: '300px',
            objectFit: 'contain',
          },
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > label': {
            width: '100%',
            textAlign: 'end',
            '@media screen and (max-width:750px)': {
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
          '& > span': {
            color: 'red',
          },
        },

        '& $inputs': {
          width: '70%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input, & > textarea, & > select': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
          '& > textarea': {
            minHeight: '160px',
          },
          '& > input[type="checkbox"]': {
            boxShadow: 'none',
          },
          '& $helptext': {
            fontSize: '0.8rem',
            color: 'grey',
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    marginTop: '88px !important',
    justifyContent: 'end',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const NewsUpdateForAdmin = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const { isAuth } = useContext(AuthContext);
  const [news, setNews] = useState();
  const [tags, setTags] = useState();
  const [push, setPush] = useState(false);

  const [images, setImages] = useState();
  const [iconImages, setIconImages] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: 'ニュース一覧', path: '/news_index' },
    { name: 'ニュース更新', path: '/news_update_for_admin' },
  ];

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  // Init
  useEffect(() => {
    if (images) {
      const result = [];
      Object.keys(images).forEach((key) => {
        if (
          images[key].use_place === 'News' ||
          images[key].use_place === 'Seminar'
        ) {
          result.push(images[key]);
        }
      });
      setIconImages(result);
    }
  }, [images]);

  useEffect(() => {
    if (location) {
      newsDetailForAdmin(setNews, location.state.uuid);
      tagIndexForAdmin(setTags);
      imageApi(setImages);
    }
  }, [location]);

  useEffect(() => {
    if (news && iconImages) {
      const rejectList = ['uuid'];
      const keys = Object.keys(news);
      const resetValues = {};
      keys.forEach((k) => {
        if (!rejectList.includes(k)) {
          if (k === 'tag') {
            const tagResult = news[k].map((t) => {
              return t.split('#')[1];
            });
            resetValues[k] = tagResult;
          } else if (k === 'news_image') {
            iconImages.forEach((image) => {
              if (image.url === news[k]) {
                resetValues[k] = image.title;
              }
            });
          } else {
            resetValues[k] = news[k];
          }
        }
      });
      reset(resetValues);
    }
  }, [news, iconImages, reset]);

  // Submit
  const onSubmit = handleSubmit(async (data) => {
    // ここではイメージファイルを直接渡さないので、noImageのプロセスで登録（その代わりにimage.uuidを渡す）
    const imageFileKey = 'news_image';
    const memberOnlyValue = '会員向け';
    const commonValue = '一般向け';
    iconImages.forEach((image) => {
      if (image.title === data[imageFileKey]) data['image_uuid'] = image.uuid;
    });
    data['uuid'] = location.state.uuid;
    if (data.disclosure_range === 'the_whole_world') {
      data['is_members_only'] = false;
      if (!data.tag.includes(commonValue)) {
        data.tag.push(commonValue);
      }
      if (data.tag.includes(memberOnlyValue)) {
        const index = data.tag.indexOf(memberOnlyValue);
        data.tag.splice(index, 1);
      }
    } else if (data.disclosure_range === 'members_only') {
      data['is_members_only'] = true;
      if (!data.tag.includes(memberOnlyValue)) {
        data.tag.push(memberOnlyValue);
      }
      if (data.tag.includes(commonValue)) {
        const index = data.tag.indexOf(commonValue);
        data.tag.splice(index, 1);
      }
    }

    try {
      await updateInstance('News', data, imageFileKey);
      setPush(true);
      console.log('update finish!');
    } catch (error) {
      console.error('Error in updateInstance:', error);
    }
  });

  // Contents
  const form = () => {
    if (news && iconImages) {
      return (
        <>
          <div className={classes.container}>
            <form
              method="POST"
              encType="multipart/form-data"
              onSubmit={onSubmit}
            >
              <div>
                <div className={classes.titles}>
                  <label htmlFor="タイトル">{'タイトル'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="title"
                    type="text"
                    {...register('title', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.title?.message && (
                    <div className={classes.error}>{errors.title.message}</div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="内容案内">{'内容案内'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="description"
                    type="text"
                    {...register('description', {
                      required: { value: false, message: '入力必須の項目です' },
                      maxLength: {
                        value: 30,
                        message: '30字以内でお願いします',
                      },
                    })}
                  />
                  <div className={classes.helptext}>
                    現状、管理用に使用されます。将来的には、SNSでシェアされた際のサムネイルに付く説明文として使用予定です。
                  </div>
                  {errors.description?.message && (
                    <div className={classes.error}>
                      {errors.description.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="公開範囲">{'公開範囲'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <select
                    id="disclosure_range"
                    defaultValue="choice"
                    {...register('disclosure_range', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          selectDefaultCheck(v)
                            ? null
                            : `全世界または会員のみのいずれかを選択してください。`,
                      },
                    })}
                  >
                    <option value="choice">選択してください</option>
                    <option value="the_whole_world">全世界</option>
                    <option value="members_only">会員のみ</option>
                  </select>
                  {errors.disclosure_range?.message && (
                    <div className={classes.error}>
                      {errors.disclosure_range.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="公開予定日時">{'公開予定日時'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="delivery_date"
                    type="datetime-local"
                    {...register('delivery_date', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    一般公開画面に表示される日時を設定してください。過去の日付に設定することも可能です。
                    <br />
                    一般公開は一覧画面で「公開」ボタンから公開してください。
                  </div>
                  {errors.delivery_date?.message && (
                    <div className={classes.error}>
                      {errors.delivery_date.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="Tag">{'Tag'}</label>
                </div>
                <div className={classes.inputs}>
                  <select
                    id="tag"
                    defaultValue={['choice']}
                    multiple
                    style={{ height: '96px' }}
                    {...register('tag', {
                      required: { value: false, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          selectDefaultCheck(v)
                            ? null
                            : `いずれかを選択してください。`,
                      },
                    })}
                  >
                    <option value="choice">選択してください</option>
                    {tags
                      ? tags.map((tag, i) => {
                          return (
                            <option key={i} id={`choice_${i}`} value={tag.name}>
                              {tag.name}
                            </option>
                          );
                        })
                      : undefined}
                  </select>
                  <div className={classes.helptext}>
                    テーマに沿ったタグを選択してください。また、追加すべきタグがある場合は
                    <a href={various.general.tagIndex}>こちら</a>
                    のページで登録してください。
                  </div>
                  {errors.tag?.message && (
                    <div className={classes.error}>{errors.tag.message}</div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="ニュースイメージ">{'ニュースイメージ'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <select
                    id="news_image"
                    defaultValue="choice"
                    {...register('news_image', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          selectDefaultCheck(v)
                            ? null
                            : `いずれかをご選択ください。選択肢に無い場合は、新規画像制作依頼として管理者へご要請ください。`,
                      },
                    })}
                  >
                    <option value="choice">選択してください</option>
                    {iconImages
                      ? iconImages.map((image) => {
                          return (
                            <option
                              key={image.uuid}
                              id={`choice_${image.uuid}`}
                              value={image.title}
                            >
                              {image.title}
                            </option>
                          );
                        })
                      : undefined}
                  </select>
                  <div className={classes.helptext}>
                    テーマに沿ったイメージを選択してください。なお、相当するイメージが選択肢にない場合は、制作者に対して、制作＆登録の指示を出してください。また、登録するイメージがある場合は
                    <a href={various.general.imageIndexForAdmin}>こちら</a>
                    のページで登録してください。
                  </div>
                  {errors.news_image?.message && (
                    <div className={classes.error}>
                      {errors.news_image.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="Topics">{'Topics'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="is_topics"
                    type="checkbox"
                    {...register('is_topics', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    チェックを入れるとトップページ最上段に固定リンクが貼られます。
                  </div>
                  {errors.is_topics?.message && (
                    <div className={classes.error}>
                      {errors.is_topics.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="Topics掲載終了日">{'Topics掲載終了日'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="topics_end_date"
                    type="datetime-local"
                    {...register('topics_end_date', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    TopページにTopicsとして掲載する場合、掲載終了日を必ず設定してください。
                  </div>
                  {errors.topics_end_date?.message && (
                    <div className={classes.error}>
                      {errors.topics_end_date.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="記事内容">{'記事内容'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <textarea
                    id="content"
                    type="textarea"
                    {...register('content', {
                      required: { value: true, message: '入力必須の項目です' },
                      maxLength: {
                        value: 2500,
                        message: '長すぎます',
                      },
                    })}
                  />
                  {errors.content?.message && (
                    <div className={classes.error}>
                      {errors.content.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="メール配信">{'メール配信'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="distribution_is"
                    type="checkbox"
                    {...register('distribution_is', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    チェックを入れたら、ニュース配信希望者にメール配信されます。チェックをしなければメール配信はされません。
                  </div>
                  {errors.distribution_is?.message && (
                    <div className={classes.error}>
                      {errors.distribution_is.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="公開">{'公開'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="delivered"
                    type="checkbox"
                    {...register('delivered', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    当ニュースを公開する場合はチェックを入れてください。ただし、上記公開予定日時が未来の場合は、その日時を過ぎるまで公開されません。公開予定日時を過ぎてチェックを入れて更新した場合は、即刻公開されます。
                    <br />
                    ※一覧画面の「公開」ボタンから公開の手続きを行うことも可能です。
                  </div>
                  {errors.delivered?.message && (
                    <div className={classes.error}>
                      {errors.delivered.message}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.button}>
                <button type="submit">更新</button>
              </div>
            </form>
          </div>
        </>
      );
    }
  };

  const object = {
    previous: location.state.previous,
    state: { uuid: location.state.uuid },
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>ニュース情報更新</div>
      <hr />
      <BreadCrumbs crumbs={CRUMBS} />
      {isAuth ? (
        <>
          {form()}
          {push ? <SitemapGenerator push={push} navigate={object} /> : ''}
          <Footer />
        </>
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default NewsUpdateForAdmin;
