import { useContext, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';

import QuickLinks from './quickLinks';
import Follow from './follow';
import ToNewsLetter from './toNewsletter';
import { images as imageApi } from '../Apis/apis';
import various from '../General/various';
import { paramsIndexForGeneral } from '../Apis/apis';
import { myPage } from '../Apis/apiOfManaged';
import { AuthContext } from '../auth';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '480px',
    width: '100vw',
    margin: 'auto 0',
    marginLeft: 'calc(-50vw + 50%)',
    backgroundColor: theme.palette.primary.main,
    '& #gridContainer': {
      display: 'flex',
      flexWrap: 'nowrap',
      padding: '80px 0px',
      justifyContent: 'spacec-between',
      // gap: '0px 80px',
      '& #link': {
        textAlign: 'start',
        '& > a': {
          display: 'inline-block',
          marginLeft: '160px',
          '& > img': {
            width: '144px',
          },
        },
      },
      '& #childContainer': {
        width: '100%',
        display: 'flex',
        margin: '32px 114px',
        '& > div': {
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          marginRight: '80px',
          '& #quickLinks': {
            width: '55%',
            marginRight: '40px',
            '& > div': {
              '& #qlContent': {
                display: 'flex',
                justifyContent: 'space-between !important',
              },
            },
          },
          '& #follow': {},
        },
        '& #toNewsLetter': {
          // width: '30%',
          justifyContent: 'flex-end',
        },
      },
    },
    '& #copyParent': {
      width: '100%',
      height: '80px',
      '& > hr': {
        width: '90%',
      },

      '& > div': {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& > div': {
          fontSize: '0.9rem',
          '& > p': {
            color: '#FFF',
            textAlign: 'end',
            '& > a': {
              marginRight: '8px',
              color: '#FFF',
              textDecoration: 'underline',
              '&:hover': {
                color: 'black',
              },
            },
            '& > span': {
              marginRight: '8px',
            },
          },
        },
        '& #copylight': {
          marginRight: '56px',
        },
        '& #commerceDisclosure': {
          fontSize: '0.8rem',
        },
      },
    },
    '@media screen and (max-width:1200px)': {
      '& #gridContainer': {
        '& #link': {
          '& > a': {
            marginLeft: '80px !important',
          },
        },
        '& #childContainer': {
          // width: '100% !important',
          flexWrap: 'wrap',
          margin: '32px 56px !important',
          '& > div': {
            width: '100% !important',
            // justifyContent: 'flex-start !important',
            marginBottom: '56px',
          },
          '& #toNewsLetter': {
            width: '100% !important',
            justifyContent: 'flex-start !important',
          },
        },
      },
    },
    '@media screen and (max-width:800px)': {
      '& #gridContainer': {
        flexWrap: 'wrap',
        '& #link': {
          '& > a': {
            marginLeft: '32px !important',
          },
        },
        '& #childContainer': {
          flexWrap: 'nowrap',
        },
      },
    },
    '@media screen and (max-width:700px)': {
      '& #childContainer': {
        flexWrap: 'wrap !important',
        '& > div': {
          gap: '0px 96px',
        },
        '& #toNewsLetter': {
          marginRight: '0px !important',
        },
      },
      '& #copyParent': {
        '& > div': {
          // width: '100%',
          margin: '0px 16px !important',
          '& > p': {
            textAlign: 'center !important',
          },
        },
      },
    },
    '@media screen and (max-width:560px)': {
      '& #childContainer': {
        '& > div': {
          flexWrap: 'wrap',
          width: '100% !important',
          marginRight: '0px !important',
          '& #quickLinks': {
            width: '100% !important',
          },
          '& #follow': {},
        },
      },
      '& #copyParent': {
        '& > div': {
          flexDirection: 'column',
          '& > div': {
            margin: '0px !important',
            height: '20px',
          },
        },
      },
    },
  },
}));

const Footer = () => {
  const { isAuth } = useContext(AuthContext);

  const [image, setImage] = useState();
  const [year, setYear] = useState();
  const [params, setParams] = useState(null);
  const [showPayment, setShowPayment] = useState(false);
  const [user, setUser] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    imageApi(setImage);
    setYear(new Date().getFullYear());
    paramsIndexForGeneral(setParams);
  }, []);

  useEffect(() => {
    if (isAuth) {
      myPage(setUser);
    }
  }, [isAuth]);

  useEffect(() => {
    if (params) {
      const stripeParams = params.find((param) => {
        return param.title === 'run_stripe_all_members';
      });
      if (stripeParams.apply_is) {
        setShowPayment(true);
      } else {
        if (user) {
          if (various.stripe.testRunStaff.includes(user.email)) {
            setShowPayment(true);
          }
        }
      }
    }
  }, [params, user]);

  const contents = () => {
    if (image) {
      return (
        <footer>
          <div className={classes.container}>
            <div id="gridContainer">
              <div id="link">
                <a href={various.general.home}>
                  <img
                    className={classes.logo}
                    src={image.OfficialImage_TouzenLogoRed.url}
                    alt="logo"
                  />
                </a>
              </div>
              <div id="childContainer">
                <div>
                  <div id="quickLinks">
                    <QuickLinks />
                  </div>
                  <div id="follow">
                    <Follow />
                  </div>
                </div>
                <div id="toNewsLetter">
                  <ToNewsLetter />
                </div>
              </div>
            </div>
            <div id="copyParent">
              <hr />
              <div>
                {showPayment ? (
                  <div id="commerceDisclosure">
                    <p>
                      <a href={various.general.commerceDisclosure}>
                        特定商取引法に基づく表記
                      </a>
                      <span>&nbsp;|&nbsp;</span>
                    </p>
                  </div>
                ) : null}
                <div id="copylight">
                  <p className={classes.copyChild}>
                    ©︎ 刀禪 2010 - {year} All Rights Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      );
    }
  };
  return contents();
};

export default Footer;
