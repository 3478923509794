import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';

import OnOffByScroll from '../../../Animation/onOffByScroll';
import { AuthContext } from '../../../auth';
import { updateInstance } from '../../../Apis/apiHandlers';
import various from '../../../General/various';
import { fileSizeObserver } from '../../../CommonFunction/imageManagement';
import Footer from '../../../Footer/footer';
import { selectDefaultCheck } from '../../../CommonFunction/validators';
import { localStorageArrayHandler } from '../../../CommonFunction/managePermission';
import {
  documentIndex,
  instructorIndexForAdmin,
} from '../../../Apis/apiOfManaged';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
  },
  titles: {},
  inputs: {},
  helptext: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  container: {
    marginTop: '80px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media screen and (max-width:800px)': {
      '& > div': {
        width: '100%',
      },
    },
    '& > form': {
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '& > img': {
            maxWidth: '300px',
            maxHeight: '300px',
            objectFit: 'contain',
          },
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > label': {
            width: '100%',
            textAlign: 'end',
            fontSize: '1rem',
            '@media screen and (max-width:750px)': {
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
          '& > span': {
            color: 'red',
          },
        },

        '& $inputs': {
          width: '70%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input, & > textarea, & > select': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
          '& > textarea': {
            minHeight: '160px',
          },
          '& > input[type="checkbox"]': {
            boxShadow: 'none',
          },
          '& $helptext': {
            fontSize: '0.8rem',
            color: 'grey',
            '& > a': {
              textDecoration: 'underline',
              '&:link': {
                color: theme.palette.primary.main,
              },
            },
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    marginTop: '88px !important',
    justifyContent: 'end',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const DocumentsUpdate = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const { isAuth } = useContext(AuthContext);
  const permissions = localStorageArrayHandler('parse', various.permissionKey);

  const [date, setDate] = useState();
  const [documents, setDocuments] = useState();
  const [detail, setDetail] = useState();
  const [instructors, setInstructors] = useState();
  const [uuid, setUuid] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const LIMITSIZE = 4;

  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get('seminarId');

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '文書・資料一覧', path: '/manage/document_index' },
    { name: '文書・資料更新', path: '/manage/document_update' },
  ];

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  // Init
  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  useEffect(() => {
    instructorIndexForAdmin(setInstructors, 'working');
    documentIndex(setDocuments);
  }, []);

  useEffect(() => {
    if (location.state) {
      setUuid(location.state.uuid);
    }
    if (query) {
      setUuid(query);
    }
  }, [location, query]);

  useEffect(() => {
    if (uuid && documents) {
      setDetail(documents.filter((document) => document.uuid === uuid)[0]);
    }
  }, [uuid, documents]);

  useEffect(() => {
    if (detail && instructors) {
      const owner = instructors.filter((obj) => {
        return obj.user.email === detail.owner.email;
      })[0];
      const resetValues = {};
      resetValues['title'] = detail.title;
      resetValues['use_place'] = 'Seminar';
      resetValues['description'] = detail.description;
      resetValues['is_inuse'] = detail.is_inuse;
      resetValues['owner'] = owner.name;
      reset(resetValues);
    }
  }, [reset, detail, instructors]);

  // Submit
  const onSubmit = handleSubmit(async (data) => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    if (environment === 'development') {
      if (!data['title'].endsWith('_dev')) {
        data['title'] = data['title'] + '_dev';
      }
    } else if (environment === 'staging') {
      if (!data['title'].endsWith('_staging')) {
        data['title'] = data['title'] + '_staging';
      }
    }
    if (instructors) {
      data['owner'] = instructors.filter(
        (instructor) => instructor.name === data['owner']
      )[0].user.email;
    }
    data['uuid'] = uuid;

    const imageFileKey = 'document';

    try {
      await updateInstance('Documents', data, imageFileKey);
      console.log('update finish!');
      if (location.state) {
        navigate(location.state.previous);
      } else {
        navigate(various.general.managed);
      }
    } catch (error) {
      console.error('Error in updateInstance:', error);
    }
  });

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {!isAuth ? (
        <>
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleAuth}
            >
              ログイン
            </span>
            してください。
          </div>
          <Footer />
        </>
      ) : permissions.includes('admin') ||
        permissions.includes('instructor') ||
        permissions.includes('organizer') ? (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">文書・資料の更新</span>
              <span>{date}</span>
            </div>
          </div>
          <hr />
          <BreadCrumbs crumbs={CRUMBS} />
          <div className={classes.container}>
            <form
              method="POST"
              encType="multipart/form-data"
              onSubmit={onSubmit}
            >
              <div>
                <div className={classes.titles}>
                  <label htmlFor="イメージタイトル">文書・資料タイトル</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="title"
                    type="text"
                    {...register('title', {
                      required: { value: true, message: '入力必須の項目です' },
                      maxLength: {
                        value: 50,
                        message: '50字以内でお願いします',
                      },
                    })}
                  />
                  <div className={classes.helptext}>
                    50文字以内で記述してください。
                  </div>
                  {errors.title?.message && (
                    <div className={classes.error}>{errors.title.message}</div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="使用箇所">使用箇所</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <select
                    id="use_place"
                    defaultValue="choice"
                    {...register('use_place', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          selectDefaultCheck(v)
                            ? null
                            : `いずれかをご選択ください。`,
                      },
                    })}
                  >
                    <option value="choice">選択してください</option>
                    <option value="Seminar">Seminar</option>
                    <option value="News">News</option>
                    <option value="ForPublic">ForPublic</option>
                  </select>
                  <div className={classes.helptext}>
                    現状運用可能な箇所は、Seminarのみです。
                  </div>
                  {errors.use_place?.message && (
                    <div className={classes.error}>
                      {errors.use_place.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="文書・資料の説明">文書・資料の説明</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <textarea
                    id="description"
                    type="textarea"
                    {...register('description', {
                      required: { value: true, message: '入力必須の項目です' },
                      maxLength: {
                        value: 150,
                        message: '150字以内でお願いします',
                      },
                    })}
                  />
                  <div className={classes.helptext}>
                    150文字以内で記述してください。
                  </div>
                  {errors.description?.message && (
                    <div className={classes.error}>
                      {errors.description.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="文書・資料アップロード">
                    {'文書・資料アップロード'}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="document"
                    type="file"
                    accept="application/pdf,text/plain"
                    {...register('document', {
                      required: { value: false, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          fileSizeObserver(v, LIMITSIZE)
                            ? null
                            : `イメージファイルは${LIMITSIZE}MB以下にしてください`,
                      },
                    })}
                  />
                  <div className={classes.helptext}>
                    登録可能なファイル形式は、".pdf"と".txt"のみです。
                    <br />
                    また、ファイルサイズは{LIMITSIZE}
                    MB以下という制限があります。容量が少なすぎる場合、管理者へお問合せください。
                    <br />
                    <hr />
                    【注意】
                    <br />
                    セキュリティ上の観点から、登録済みの文書をプリセットすることはできませんので、「ファイルが選択されていません。」という表示がなされています。
                    登録済みの文書自体の更新の必要がないのであれば、当該項目にファイルを再度アップロードする必要はありません。当該項目を未設定で更新しても文書自体には何も影響はありません。
                  </div>
                  {errors.document?.message && (
                    <div className={classes.error}>
                      {errors.document.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="稼働区分">{'稼働区分'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="is_inuse"
                    type="checkbox"
                    {...register('is_inuse', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    稼働中の文書は、チェックを入れてください。
                  </div>
                  {errors.is_inuse?.message && (
                    <div className={classes.error}>
                      {errors.is_inuse.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="所有者">所有者</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <select
                    id="owner"
                    defaultValue="choice"
                    {...register('owner', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          selectDefaultCheck(v)
                            ? null
                            : `いずれかをご選択ください。`,
                      },
                    })}
                  >
                    <option value="choice">選択してください</option>
                    {instructors
                      ? instructors.map((instructor, i) => {
                          return (
                            <option
                              key={instructor.uuid}
                              id={`choice_${instructor.uuid}`}
                              value={instructor.name}
                            >
                              {instructor.name}
                            </option>
                          );
                        })
                      : undefined}
                  </select>
                  <div className={classes.helptext}>
                    指導者登録をしている方をリストしています。運用上問題があれば修正します。
                  </div>
                  {errors.owner?.message && (
                    <div className={classes.error}>{errors.owner.message}</div>
                  )}
                </div>
              </div>
              <div className={classes.button}>
                <button type="submit">更新</button>
              </div>
            </form>
          </div>
          <Footer />
        </>
      ) : permissions === 'null' ? (
        setTimeout(() => {
          window.location.reload(true);
        }, 500)
      ) : (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">文書・資料の更新</span>
              <span>{date}</span>
            </div>
          </div>
          <hr />
          <div className={classes.container}>権限がありません</div>
        </>
      )}
    </>
  );
};
export default DocumentsUpdate;
