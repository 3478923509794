import { makeStyles } from '@material-ui/core';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import OnOffByScroll from '../Animation/onOffByScroll';
import ArrowButton from '../General/arrowButton';
import ContentTitle from '../General/contentTitle';
import Clock from '../General/clock';
import various from '../General/various';
import {
  myPage,
  memberIndex,
  getStripeCustomerInfo,
  setDoNotShowAgain,
} from '../Apis/apiOfManaged';
import { paramsIndexForGeneral } from '../Apis/apis';
import {
  checkSeminarStatus,
  choiceSeminarProduct,
  productMasterDetailOnlyPrice,
  seminarApply,
} from '../Apis/apis';
import { seminarDetailPreview } from '../Apis/apis';
import { AuthContext } from '../auth';
import {
  emailRegValidator,
  emailValidator,
} from '../CommonFunction/validators';
import Footer from '../Footer/footer';
import { ogpGenerator } from '../ogp';
import StandardDialog from '../Dialog/standardDialog';
import { Spinner } from '../Spinner';

const useStyles = makeStyles((theme) => ({
  contentTitle: {
    marginTop: '168px',
    '& > div': {
      '& > span': {
        '& > span': {
          '& > span': {
            lineHeight: '64px !important',
            textAlign: 'justify',
          },
        },
      },
    },
  },
  applyContents: {
    marginTop: '176px',
    display: 'flex',
    margin: 'auto 0',

    '& #read': {
      width: 'calc(70vw * 0.7)',
      marginBottom: '56px',
      textAlign: 'start',
      fontWeight: 'bold',
      lineHeight: '3.5rem',
      fontSize: '1rem',

      '& > div': {
        width: '100%',
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'nowrap',
        lineHeight: '40px',
        '& > div': {
          width: '28%',
          textAlignLast: 'end',
          // marginRight: '16px',
        },
        '& > span': {
          display: 'inline-block',
          width: '72%',
          textAlignLast: 'end',
        },
      },
      '& #title': {
        '& > div': {
          fontSize: 'clamp(8px, 1rem, 1rem)',
        },
        '& > span': {
          display: 'inline-block',
          width: '72%',
          margin: 0,
          fontSize: '1.5rem',
          fontWeight: 'bold',
          verticalAlign: 'middle',
        },
      },
      '& #place, & #price': {
        width: '72%',
        display: 'flex',
        flexFlow: 'column',
        textAlignLast: 'end',
        '& #note, & #cashOnly': {
          display: 'block',
          width: '100%',
          fontSize: '0.7rem',
          fontWeight: 'normal',
          opacity: '0.5',
          verticalAlign: 'bottom',
          textAlignLast: 'end',
          lineHeight: '1rem !important',
        },
      },
    },
    '@media screen and (max-width:1000px)': {
      flexFlow: 'column',
      '& $image': {
        width: '92vw',
        '& > img': {
          display: 'inline-block',
          width: '92vw',
          // height: 'calc(92vw / 1.5)',
          height: '92vw',
        },
      },
      '& #read': {
        width: '100%',
        marginTop: '32px',
        '& #place, & #price': {
          '& #note, & #cashOnly': {},
        },
      },
    },
    '@media screen and (max-width:550px)': {
      '& #read': {
        '& > div': {
          display: 'flex',
          flexWrap: 'wrap !important',
          justifyContent: 'start',
          lineHeight: '2rem',
          marginBottom: '32px',
          '& > div, & > span': {
            textAlignLast: 'start !important',
            marginLeft: '0px !important',
            width: '100% !important',
            lineHeight: '24px',
          },
          '& > div,': {
            fontStyle: 'italic',
          },
        },
        '& #place, & #price': {
          '& #note, & #cashOnly': {
            textAlignLast: 'start !important',
          },
        },
      },
    },
  },
  image: {
    width: 'calc(70vw * 0.3)',
    marginRight: '3vw',
    '& > img': {
      width: 'calc(70vw * 0.3)',
      height: 'calc(70vw * 0.3)',
      objectFit: 'cover',
      // filter:
      //   'contrast(100%) grayscale(100%) saturate(0%) drop-shadow(2px 2px 2px black)',
      filter: 'drop-shadow(2px 2px 2px black)',
    },
  },

  titles: {},
  inputs: {},
  helptext: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  formContainer: {
    marginTop: '168px',
    width: '100%',
    fontSize: '0.9em',
    '& > form': {
      '& > p': {
        fontSize: '0.7em',
        textAlign: 'center',
      },
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          '& > label': {
            width: '100%',
            textAlign: 'end',
            fontSize: '1rem',
          },
          '& > span': {
            color: 'red',
          },
          '@media screen and (max-width:750px)': {
            width: '100%',
            '& > label': {
              width: '160px',
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
        },

        '& $inputs': {
          width: '70%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
            margin: 'auto 0',
            textAlign: 'center',
          },
          '& > input, & > textarea, & > select': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
          '& #image': {
            boxShadow: 'none',
            border: 'none',
          },
          '& > input[type="checkbox"]': {
            boxShadow: 'none',
          },
          '& > textarea': {
            height: '10em',
          },
          '& > img': {
            width: '100%',
            objectFit: 'contain',
            maxHeight: '300px',
          },
          '& $helptext': {
            fontSize: '0.8rem',
            color: 'grey',
            textAlign: 'start',
            '& > a': {
              textDecoration: 'underline',
              '&:link': {
                color: theme.palette.primary.main,
              },
            },
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  arrow: {
    margin: '80px auto !important',
    borderRadius: '0.5em',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SeminarApplyForm = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const { isAuth } = useContext(AuthContext);
  const [user, setUser] = useState();
  const [members, setMembers] = useState([]);
  const [member, setMember] = useState();
  const [apply, setApply] = useState(false);
  const [message, setMessage] = useState(null);
  const [singleClass, setSingleClass] = useState(false);
  const [detail, setDetail] = useState();
  const [komaNum, setKomaNum] = useState();
  const [entryKomaNum, setEntryKomaNum] = useState();
  const [unitPrice, setUnitPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  // 以下Stripe関連
  const [showPayment, setShowPayment] = useState(false);
  const [gotoCheckout, setGotoCheckout] = useState(false);
  const [stripeCustomer, setStripeCustomer] = useState();
  const [params, setParams] = useState(null);
  const [shownPaymentDialog, setShownPaymentDialog] = useState(false);
  const [shownPaymentDialog2, setShownPaymentDialog2] = useState(false);
  const [shownPaymentDialog3, setShownPaymentDialog3] = useState(false);
  const [seminarStatus, setSeminarStatus] = useState(undefined);
  const [applyErrorMessage, setApplyErrorMessage] = useState();
  const [applyData, setApplyData] = useState(null);
  const [disagreeAplly, setDisagreeApply] = useState(false);
  const [productUuid, setProductUuid] = useState();
  const [product, setProduct] = useState();
  const [isFreeMember, setIsFreeMember] = useState(false);
  const [seminarPaymentMethod, setSeminarPaymentMethod] = useState('isMixed');

  const [token, setToken] = useState('');
  const [submitData, setSubmitData] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const TIMEZONE = various.timezone;
  const location = useLocation();
  const navigate = useNavigate();

  const uuid = location.state.uuid;

  const element = useRef();
  const classes = useStyles();

  // if (detail) console.log('detail', detail);
  // if (product) console.log('product', product);
  // if (member) console.log('member', member);
  // if (isFreeMember) console.log('isFreeMember');
  // if (stripeCustomer) console.log('stripeCustomer', stripeCustomer);
  // if (applyData) console.log('applyData', applyData);
  // console.log('isAlwaysUseCreditCard', isAlwaysUseCreditCard);

  // OGP設定
  const ogp = useCallback((props) => {
    ogpGenerator(props);
  }, []);

  useLayoutEffect(() => {
    if (detail) {
      const ogpProps = {
        page: 'seminar_apply',
        pageTitle:
          detail.title +
          (detail.serial_number ? ` #${detail.serial_number}` : '') +
          'セミナー申し込みページ',
        path: location.pathname,
        imageUrl: detail.image,
      };
      ogp(ogpProps);
    }
  }, [detail, location, ogp]);
  // OGP設定ここまで

  // Init

  // パラメータ取得
  useEffect(() => {
    paramsIndexForGeneral(setParams);
  }, []);

  // 会員情報取得
  useEffect(() => {
    if (isAuth) myPage(setUser);
  }, [isAuth]);

  useEffect(() => {
    if (user) {
      memberIndex(setMembers);
    }
  }, [user]);

  useEffect(() => {
    if (members.length > 0 && user) {
      members.forEach((m) => {
        if (m.email === user.email && m.user === user.id) {
          setMember(m);
        }
      });
    }
  }, [members, user]);

  useEffect(() => {
    if (member) {
      const ids = [
        'last_name',
        'last_name_kana',
        'first_name',
        'first_name_kana',
        'email',
        'phone_number',
        'address1',
        'address2',
        'address3',
        'address4',
      ];
      const defaultValues = {};
      ids.forEach((id) => {
        defaultValues[id] = member[id];
      });
      defaultValues['email_confirm'] = member['email'];
      reset(defaultValues);
    }
  }, [member, reset]);

  // セミナー情報取得
  useEffect(() => {
    if (uuid) {
      seminarDetailPreview(setDetail, uuid);
    }
  }, [uuid]);

  useEffect(() => {
    if (detail) {
      if (
        new Date(detail.part_two_start_time).getHours() === 23 &&
        new Date(detail.part_two_start_time).getMinutes() === 59
      ) {
        setSingleClass(true);
      }
      let komanum = 1;
      const seminarTime = {
        part2: Clock({
          timezone: TIMEZONE,
          time: null,
          string: detail.part_two_end_time,
        }).hour,
        part3: detail.part_three_end_time
          ? Clock({
              timezone: TIMEZONE,
              time: null,
              string: detail.part_three_end_time,
            }).hour
          : null,
        part4: detail.part_four_end_time
          ? Clock({
              timezone: TIMEZONE,
              time: null,
              string: detail.part_four_end_time,
            }).hour
          : null,
      };
      if (seminarTime.part2 !== 23) komanum += 1;
      if (seminarTime.part3 !== null) komanum += 1;
      // 現状、4コマ目は肝稽古用として使用しているため、コマ数にはカウントせず
      // if (seminarTime.part4 !== null) komanum += 1;
      setKomaNum(komanum);
    }
  }, [detail, TIMEZONE]);

  useEffect(() => {
    if (komaNum && detail) {
      const oneDayPrice = detail.membership_fee;
      if (oneDayPrice) {
        setUnitPrice(oneDayPrice / komaNum);
      }
    }
  }, [komaNum, detail]);

  useEffect(() => {
    if (detail) {
      // クレジットカード決済のみのケースは、現状では想定し辛く、現金決済のみのケースだけ実装している。
      if (detail?.payment_method?.is_only_cash) {
        setSeminarPaymentMethod('is_only_cash');
      }
    }
  }, [detail]);

  async function apply_seminar(setState, setMessage, dataset) {
    await seminarApply(setState, setMessage, dataset);
  }

  // 決済関連Init
  function initializedPayments(
    params,
    member,
    seminarPaymentMethod,
    setShowPayment,
    setGotoCheckout,
    setStripeCustomer
  ) {
    let stripeParams = false;

    // 決済機能の表示on/off
    const is_run = params.find((param) => {
      return param.title === 'run_stripe_in_production';
    });
    const is_all_members = params.find((param) => {
      return param.title === 'run_stripe_all_members';
    });
    if (is_run?.apply_is) {
      if (is_all_members?.apply_is) {
        setShowPayment(true);
        // setStripeParams(true);
        stripeParams = true;
      } else {
        if (various.stripe.testRunStaff.includes(member.email)) {
          setShowPayment(true);
          // setStripeParams(true);
          stripeParams = true;
        }
      }
    }

    // 決済機能有効化の判定
    if (seminarPaymentMethod === 'is_only_cash') {
      setGotoCheckout(false);
    } else if (stripeParams) {
      setGotoCheckout(true);
    } else if (various.stripe.testRunStaff.includes(member.email)) {
      setGotoCheckout(true);
    }
    getStripeCustomerInfo(setStripeCustomer, member.email);
  }

  useEffect(() => {
    if (params && member) {
      initializedPayments(
        params,
        member,
        seminarPaymentMethod,
        setShowPayment,
        setGotoCheckout,
        setStripeCustomer
      );
    }
  }, [params, member, seminarPaymentMethod]);

  // 申し込み可能な商品の判定
  useEffect(() => {
    async function getSeminarProduct(data, setProductUuid, setIsFreeMember) {
      await choiceSeminarProduct(data, setProductUuid, setIsFreeMember);
    }

    if (member) {
      const data = {
        seminar_uuid: uuid,
        member_uuid: member.uuid,
      };
      getSeminarProduct(data, setProductUuid, setIsFreeMember);
    } else {
      const data = {
        seminar_uuid: uuid,
        member_uuid: null,
      };
      getSeminarProduct(data, setProductUuid, setIsFreeMember);
    }
    // eslint-disable-next-line
  }, [uuid, member]);

  // 申し込み商品の確定
  useEffect(() => {
    async function getProduct(setProduct, productUuid) {
      await productMasterDetailOnlyPrice(setProduct, productUuid);
    }
    if (productUuid) {
      getProduct(setProduct, productUuid.seminar_product_uuid);
    }
  }, [productUuid]);

  // Apply可否判定
  useEffect(() => {
    if (productUuid === 'error') {
      const date = new Date(detail.event_date_start_time).getTime();
      const implementationDate = various.stripe.implementationDate.getTime();
      if (date > implementationDate) {
        // 決済機能ローンチ後に作成したセミナーにしかproductインスタンスは紐づいていないため、それ以前のセミナーはエラーになる。
        alert('お申し込みできません。');
        navigate(various.general.seminar);
      }
    }
  }, [productUuid, detail, navigate]);

  // SUBMIT
  function APIHandler(
    data,
    seminarDetail,
    product,
    komaNum,
    member,
    isFreeMember,
    setEntryKomaNum,
    setLoading,
    setApply,
    setSeminarStatus,
    setApplyErrorMessage
  ) {
    // Front side validation
    const seminarDateTime = new Date(
      Date.parse(detail.event_date_start_time.replace(/-/g, '/'))
    );
    const today = new Date();
    if (today.getTime() > seminarDateTime.getTime()) {
      alert('本セミナーはすでに終了しました。');
      return navigate(various.general.seminar);
    }

    // Base info
    const seminar = seminarDetail;
    data['id'] = seminar.uuid;
    data['title'] =
      seminar.title +
      (seminar.serial_number ? ' #' + seminar.serial_number : '');
    data['date'] =
      Clock({
        timezone: TIMEZONE,
        time: null,
        string: seminar.event_date_start_time,
      }).today +
      ' ' +
      Clock({
        timezone: TIMEZONE,
        time: null,
        string: seminar.event_date_start_time,
      }).hourMinutes +
      ' ~';
    data['place'] = seminar.place.facility_name + ' ' + seminar.hall.name;
    data['address'] = seminar.place.address;
    data['gmap'] = seminar.place.gmap;

    // Pricing
    // 申し込みコマ数（最大３コマしか対応していない：４コマ目は肝稽古用として使用しているため）
    let entryKomaCount;
    if (data.radio === '全コマに参加する') {
      entryKomaCount = komaNum;
    } else if (
      data.radio === '第1部に参加する' ||
      data.radio === '第2部に参加する' ||
      data.radio === '第3部に参加する'
    ) {
      entryKomaCount = 1;
    } else {
      entryKomaCount = 2;
    }
    setEntryKomaNum(entryKomaCount);

    // 単価の把握
    let unitPrice;
    if (product) {
      unitPrice = product.price;
    } else {
      // Memoとしてelse節を残しているが、会員はもとより非会員でもproductを取得しているので、以下の処理は備忘録であり実行はされない想定
      if (member) {
        unitPrice = seminar.membership_fee / komaNum;
        if (data.is_screening) {
          // 常連組のスクリーニングを行っている。1/3というのは、2023年５月開催の「凝縮稽古」のみなのかもしれない。場当たり的な対応。
          unitPrice = seminar.membership_fee / 3;
        }
      } else {
        unitPrice = seminar.entry_fee / komaNum;
      }
    }
    data.fee = unitPrice * entryKomaCount;

    // Domain info
    data['detaildataPath'] = `/seminar/preview/${seminar.uuid}`;
    data['attention'] = seminar.notes;
    const protocol = window.location.protocol;
    const host = window.location.host;
    const domain = document.domain;
    const path = 'contact';
    const dataset = {
      email: data.email,
      protocol: protocol,
      host: host,
      path: path,
      domain: domain,
    };
    data['urlData'] = dataset;

    // console.log(data);

    // 処理の分岐
    if (member) {
      // セミナー無料の場合（運営者等が対象）
      if (isFreeMember) {
        console.log('isFreeMember');
        data.fee = 0;
        setLoading(true);
        apply_seminar(setApply, setMessage, data);
      } else {
        console.log('isMember');
        // 会員の場合
        data['member_uuid'] = member.uuid; // セミナー決済画面で定例判定を行う為に必要
        checkSeminarStatus(setSeminarStatus, setApplyErrorMessage, data);
        setApplyData(data);
      }
    } else {
      console.log('isNotMember');
      // 未ログインの場合 or 非会員
      setLoading(true);
      apply_seminar(setApply, setMessage, data);
    }
  }
  const onSubmit = async (data) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    } else {
      const reCaptchaToken = await executeRecaptcha('contact');
      setToken(reCaptchaToken);
      setSubmitData(data);
    }
  };

  useEffect(() => {
    if (submitData && token) {
      const data = { ...submitData };
      data['g_recaptcha_response'] = token;
      // apiHandler(data);
      APIHandler(
        data,
        detail,
        product,
        komaNum,
        member,
        isFreeMember,
        setEntryKomaNum,
        setLoading,
        setApply,
        setSeminarStatus,
        setApplyErrorMessage
      );
    }
    // eslint-disable-next-line
  }, [
    submitData,
    token,
    product,
    komaNum,
    member,
    isFreeMember,
    detail,
    seminarPaymentMethod,
  ]);

  // SeminarStatusCheck
  useEffect(() => {
    if (seminarStatus !== undefined) {
      if (seminarStatus) {
        setApply(true);
        setLoading(true);
      } else {
        alert(applyErrorMessage);
      }
    }
  }, [seminarStatus, applyErrorMessage]);

  // 以下、申し込み後の処理
  function applyOfFreeMember(setLoading) {
    // 運営者をはじめとした無料扱いの申し込み後の処理、決済画面に遷移しない
    setLoading(false);
    alert(
      'お申し込みいただき、ありがとうございます。受付メールを送信いたしました。ご確認ください。'
    );
    navigate(various.general.seminar);
  }

  function applyOfMemberPayments(
    stripeCustomer,
    applyData,
    entryKomaNum,
    productUuid
  ) {
    // 会員かつ既に決済情報を登録済みで、当該セミナーの決済画面へ遷移する場合（最も推奨されるパターン）
    applyData['apply_unit'] = entryKomaNum;
    applyData['product_uuid'] = productUuid.seminar_product_uuid;
    navigate(various.general.paymentViewOfOthers, {
      state: {
        previous: location.pathname,
        customer_id: stripeCustomer?.stripe_customer_id,
        customer_email: stripeCustomer?.email,
        user_email: member.email,
        apply_data: applyData,
      },
    });
  }

  function applyOfCashMemberPayments(setShownPaymentDialog2, setLoading) {
    // すでにStripeに登録済みだが、現金払いに戻した会員の場合
    setShownPaymentDialog2(true);
    setLoading(true);
  }

  function applyOfMemberPayments2(setShownPaymentDialog3, setLoading) {
    // すでにStripeに登録済みだが、今回のセミナー決済は現金払いを選択した場合
    setShownPaymentDialog3(true);
    setLoading(true);
  }

  function returnToAddPaymentMethod(setShownPaymentDialog, setLoading) {
    // 決済情報登録画面へ遷移する場合
    setShownPaymentDialog(true);
    setLoading(true);
  }

  function applyOfNonMemberPayments(setLoading) {
    // 未ログインの場合 or 非会員
    setLoading(false);
    alert(
      'お申し込みいただき、ありがとうございます。受付メールを送信いたしました。ご確認ください。'
    );
    navigate(various.general.seminar);
  }

  useEffect(() => {
    if (apply) {
      if (showPayment && gotoCheckout) {
        if (isFreeMember) {
          // 運営者をはじめとした無料扱いの申し込み後の処理、決済画面に遷移しない
          applyOfFreeMember(setLoading);
        } else if (stripeCustomer) {
          // console.log('stripeCustomer', stripeCustomer);
          if (
            stripeCustomer.by_stripe &&
            stripeCustomer.credit_cards &&
            stripeCustomer.credit_cards.length > 0
          ) {
            if (stripeCustomer.always_use_a_credit_card) {
              // 会員かつ既に決済情報を登録済みで、当該セミナーの決済画面へ遷移する場合（最も推奨されるパターン）
              applyOfMemberPayments(
                stripeCustomer,
                applyData,
                entryKomaNum,
                productUuid
              );
            } else {
              // すでにStripeに登録済みだが、今回のセミナーは現金払いを選択した場合
              applyOfMemberPayments2(setShownPaymentDialog3, setLoading);
            }
          } else {
            if (stripeCustomer.is_show_dialog) {
              // すでにStripeに登録済みだが、現金払いに戻した会員の場合
              applyOfCashMemberPayments(setShownPaymentDialog2, setLoading);
            } else {
              disagreePayment2();
            }
          }
        } else {
          if (member.is_show_dialog) {
            // 決済情報登録画面へ遷移する場合
            returnToAddPaymentMethod(setShownPaymentDialog, setLoading);
          } else {
            disagreePayment3();
          }
        }
      } else {
        if (member) {
          // ログイン済みの会員で決済機能非公開の場合 or セミナーが現金決済のみの場合
          apply_seminar(setApply, setMessage, applyData);
        } else {
          // 未ログインの場合 or 非会員
          applyOfNonMemberPayments(setLoading);
        }
      }
    }
    // eslint-disable-next-line
  }, [
    apply,
    navigate,
    location,
    showPayment,
    gotoCheckout,
    stripeCustomer,
    member,
    entryKomaNum,
    applyData,
    productUuid,
    isFreeMember,
  ]);

  // 会員だが決済を拒否した場合の後処理
  useEffect(() => {
    if (disagreeAplly) {
      setLoading(false);
      alert(
        'お申し込みいただき、ありがとうございます。受付メールを送信いたしました。ご確認ください。'
      );
      navigate(various.general.seminar);
    }
  }, [disagreeAplly, navigate]);

  // ログイン済みの会員で決済機能非公開の場合 or セミナーが現金決済のみの場合
  useEffect(() => {
    if (apply && member) {
      if (!gotoCheckout) {
        setLoading(false);
        if (seminarPaymentMethod === 'is_only_cash') {
          alert(
            'お申し込みいただき、ありがとうございます。本セミナーは『現金払いのみ』のセミナーです。ご注意ください。'
          );
        } else {
          alert(
            'お申し込みいただき、ありがとうございます。受付メールを送信いたしました。ご確認ください。'
          );
        }
        navigate(various.general.seminar);
      }
    }
  }, [apply, member, navigate, gotoCheckout, seminarPaymentMethod]);

  // Error message
  useEffect(() => {
    if (message) {
      setLoading(false);
      alert(message);
      navigate(various.general.seminar);
    }
  }, [message, navigate]);

  // Dialog
  // SimpleClose
  const handleClose = () => {
    setShownPaymentDialog(false);
    setLoading(false);
  };
  const handleClose2 = () => {
    setShownPaymentDialog2(false);
    setLoading(false);
  };
  const handleClose3 = () => {
    setShownPaymentDialog3(false);
    setLoading(false);
  };

  // Agree
  // 会員だが決済情報未登録で、このタイミングで情報登録＆当該セミナーの決済を行う場合
  const gotoPaymentView = () => {
    setLoading(false);
    applyData['apply_unit'] = entryKomaNum;
    applyData['customer_id'] = null;
    applyData['customer_email'] = null;
    applyData['user_email'] = member.email;
    applyData['redirect'] = true;
    applyData['product_uuid'] = productUuid.seminar_product_uuid;
    navigate(various.general.paymentView, {
      state: {
        previous: location.pathname,
        customer_id: null,
        customer_email: null,
        user_email: member.email,
        apply_data: applyData,
        isInitial: true,
      },
    });
  };
  // Stripe登録済みの会員で、再度自動決済に切り替えて申し込みを行う場合 or 今回のセミナー決済方法の選択で結局クレジットカードを選択した場合
  const gotoPaymentView2 = () => {
    // ここの処理は、結局「最も推奨されるパターン」と同じ処理です。
    setLoading(false);
    applyData['apply_unit'] = entryKomaNum;
    applyData['product_uuid'] = productUuid.seminar_product_uuid;
    navigate(various.general.paymentViewOfOthers, {
      state: {
        previous: location.pathname,
        customer_id: stripeCustomer?.stripe_customer_id,
        customer_email: stripeCustomer?.email,
        user_email: member.email,
        apply_data: applyData,
      },
    });
  };

  // DisAgree
  // 会員だが決済を拒否した場合
  const disagreePayment = () => {
    setShownPaymentDialog(false);
    apply_seminar(setDisagreeApply, setMessage, applyData);
  };
  // Stripe登録済みだが現金払いの会員が再登録を拒否した場合
  const disagreePayment2 = () => {
    setShownPaymentDialog2(false);
    apply_seminar(setDisagreeApply, setMessage, applyData);
  };
  // Stripe登録済みだが、今回のセミナー決済は現金払いを選択した場合
  const disagreePayment3 = () => {
    setShownPaymentDialog3(false);
    apply_seminar(setDisagreeApply, setMessage, applyData);
  };

  // Dialogの表示
  async function updateDoNotShowAgain() {
    await setDoNotShowAgain();
  }

  const form = () => {
    return (
      <div className={classes.formContainer}>
        <form
          method="POST"
          encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <div className={classes.titles}>
              <label htmlFor="お名前（姓）">{'お名前（姓）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="last_name"
                type="text"
                disabled={member ? true : false}
                {...register('last_name', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.last_name?.message && (
                <div className={classes.error}>{errors.last_name.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="お名前（カナ姓）">{'お名前（カナ姓）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="last_name_kana"
                type="text"
                disabled={member ? true : false}
                {...register('last_name_kana', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.last_name_kana?.message && (
                <div className={classes.error}>
                  {errors.last_name_kana.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="お名前（名）">{'お名前（名）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="first_name"
                type="text"
                disabled={member ? true : false}
                {...register('first_name', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.first_name?.message && (
                <div className={classes.error}>{errors.first_name.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="お名前（カナ名）">{'お名前（カナ名）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="first_name_kana"
                type="text"
                disabled={member ? true : false}
                {...register('first_name_kana', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.first_name_kana?.message && (
                <div className={classes.error}>
                  {errors.first_name_kana.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="Eメール">{'Eメール'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="email"
                type="email"
                disabled={member ? true : false}
                {...register('email', {
                  required: { value: true, message: '入力必須の項目です' },
                  validate: {
                    message: (v) =>
                      emailRegValidator(v)
                        ? null
                        : `※正しいEメールの形式で入力してください`,
                  },
                })}
              />
              {errors.email?.message && (
                <div className={classes.error}>{errors.email.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="Eメール（確認）">{'Eメール（確認）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="email_confirm"
                type="email"
                disabled={member ? true : false}
                {...register('email_confirm', {
                  required: { value: true, message: '入力必須の項目です' },
                  validate: {
                    message: (v) =>
                      emailValidator(v)
                        ? null
                        : `上と同一のものを入力してください。`,
                  },
                })}
              />
              {errors.email_confirm?.message && (
                <div className={classes.error}>
                  {errors.email_confirm.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="電話番号">{'電話番号'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="phone_number"
                type="text"
                disabled={member ? true : false}
                {...register('phone_number', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.phone_number?.message && (
                <div className={classes.error}>
                  {errors.phone_number.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="都道府県">{'都道府県'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="address1"
                type="text"
                disabled={member ? true : false}
                {...register('address1', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.address1?.message && (
                <div className={classes.error}>{errors.address1.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="市区町村">{'市区町村'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="address2"
                type="text"
                disabled={member ? true : false}
                {...register('address2', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.address2?.message && (
                <div className={classes.error}>{errors.address2.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="番地">{'番地'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="address3"
                type="text"
                disabled={member ? true : false}
                {...register('address3', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.address3?.message && (
                <div className={classes.error}>{errors.address3.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="建物名等">{'建物名等'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="address4"
                type="text"
                disabled={member ? true : false}
                {...register('address4', {
                  required: { value: false, message: '入力必須の項目です' },
                })}
              />
              {errors.address4?.message && (
                <div className={classes.error}>{errors.address4.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="参加希望のコマ">{'参加希望のコマ'}</label>
              <span> *</span>
            </div>
            <div
              className={classes.inputs}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div>
                {new Date(detail.part_two_start_time).getHours() === 23 &&
                new Date(detail.part_two_start_time).getMinutes() === 59 ? (
                  <>
                    <input
                      id="koma1"
                      type="radio"
                      name="koma_section"
                      value="全コマに参加する"
                      disabled={singleClass}
                      {...register('radio')}
                    />
                    <label htmlFor="koma1">全コマに参加する</label>
                  </>
                ) : (
                  <>
                    <input
                      id="koma1"
                      type="radio"
                      name="koma_section"
                      value="全コマに参加する"
                      defaultChecked
                      {...register('radio')}
                    />
                    <label htmlFor="koma1">全コマに参加する</label>
                  </>
                )}
              </div>
              <div>
                {new Date(detail.part_two_start_time).getHours() === 23 &&
                new Date(detail.part_two_start_time).getMinutes() === 59 ? (
                  <>
                    <input
                      id="koma2"
                      type="radio"
                      name="koma_section"
                      value="第1部に参加する"
                      defaultChecked
                      {...register('radio')}
                    />
                    <label htmlFor="koma2">第1部に参加する</label>
                  </>
                ) : (
                  <>
                    <input
                      id="koma2"
                      type="radio"
                      name="koma_section"
                      value="第1部に参加する"
                      {...register('radio')}
                    />
                    <label htmlFor="koma2">第1部に参加する</label>
                  </>
                )}
              </div>
              <div>
                <input
                  id="koma3"
                  type="radio"
                  name="koma_section"
                  value="第2部に参加する"
                  disabled={singleClass}
                  {...register('radio')}
                />
                <label htmlFor="koma3">第2部に参加する</label>
              </div>
              {detail.part_three_start_time ? (
                <>
                  <div>
                    <input
                      id="koma4"
                      type="radio"
                      name="koma_section"
                      value="第3部に参加する"
                      disabled={singleClass}
                      {...register('radio')}
                    />
                    <label htmlFor="koma4">第3部に参加する</label>
                  </div>
                  <div>
                    <input
                      id="koma5"
                      type="radio"
                      name="koma_section"
                      value="第1部&第2部に参加する"
                      disabled={singleClass}
                      {...register('radio')}
                    />
                    <label htmlFor="koma5">第1部&第2部に参加する</label>
                  </div>
                  <div>
                    <input
                      id="koma6"
                      type="radio"
                      name="koma_section"
                      value="第2部&第3部に参加する"
                      disabled={singleClass}
                      {...register('radio')}
                    />
                    <label htmlFor="koma6">第2部&第3部に参加する</label>
                  </div>
                  <div>
                    <input
                      id="koma7"
                      type="radio"
                      name="koma_section"
                      value="第1部&第3部に参加する"
                      disabled={singleClass}
                      {...register('radio')}
                    />
                    <label htmlFor="koma7">第1部&第3部に参加する</label>
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
          <br />
          {detail.part_four_start_time ? (
            <div>
              <div className={classes.titles}>
                <label htmlFor="懇親会への参加">{'懇親会への参加'}</label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="kangeiko"
                  type="checkbox"
                  {...register('kangeiko', {
                    required: { value: false, message: '入力必須の項目です' },
                  })}
                />
                <div className={classes.helptext}>
                  懇親会への参加を希望される方はチェックを入れてください。
                </div>
                {errors.kangeiko?.message && (
                  <div className={classes.error}>{errors.kangeiko.message}</div>
                )}
              </div>
            </div>
          ) : (
            ''
          )}
          <br />
          {detail.is_screening ? (
            <>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="講習会”常連”実績の有無">
                    {'講習会”常連”実績の有無'}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="is_screening"
                    type="checkbox"
                    {...register('is_screening', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    これまで小用先生のご開催された数々の講習会において、『20回以上』の参加実績がある方はチェックを入れてください。
                    <br />
                    ※自己申告で構いません。お問合せをいただいても、現在運営では当該データがありませんので回答はできかねます。
                  </div>
                  {errors.is_screening?.message && (
                    <div className={classes.error}>
                      {errors.is_screening.message}
                    </div>
                  )}
                </div>
              </div>
              <br />
            </>
          ) : (
            ''
          )}
          {detail.is_secret ? (
            <>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="秘伝講習への同意">{'秘伝講習への同意'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="is_secret"
                    type="checkbox"
                    {...register('is_secret', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                    checked={true}
                  />
                  <div className={classes.helptext}>
                    セミナーの内容に関して、刀禅老師が直接伝達する場面でのみ練行し、会員同士を含め、当該セミナーに参加していない人にセミナーの内容を伝えないことにご同意ください。なお、独り稽古は大いに励んで下さい。
                    <br />
                    ※ご同意いただけない場合は、お申し込みはできません。
                  </div>
                  {errors.is_secret?.message && (
                    <div className={classes.error}>
                      {errors.is_secret.message}
                    </div>
                  )}
                </div>
              </div>
              <br />
            </>
          ) : (
            ''
          )}
          <div>
            <div className={classes.titles}>
              <label htmlFor="自由記入欄">{'自由記入欄'}</label>
            </div>
            <div className={classes.inputs}>
              <textarea
                id="free_wright"
                type="text"
                {...register('free_wright', {
                  required: { value: false, message: '入力必須の項目です' },
                })}
              />
              {errors.free_wright?.message && (
                <div className={classes.error}>
                  {errors.free_wright.message}
                </div>
              )}
            </div>
          </div>
          <div className={classes.arrow}>
            <ArrowButton path={null} buttonName="SUBMIT" color="#FFF" />
          </div>
        </form>
      </div>
    );
  };

  const formMapping = () => {
    if (detail && komaNum) {
      // console.log('detail', detail);
      return (
        <>
          <div className={classes.contentTitle}>
            <ContentTitle
              title={{ titleList: ['セミナー参加申し込み'], sub: null }}
            />
          </div>
          <div className={classes.applyContents}>
            <div ref={element} id="target_apply_0" className={classes.image}>
              <img src={detail.image} alt="seminarImage" />
            </div>
            <div id="read">
              <div id="title">
                <div>対象セミナー :</div>
                <span>
                  {detail.serial_number
                    ? detail.title + ' #' + detail.serial_number
                    : detail.title}
                </span>
              </div>
              <div>
                <div>対象者:</div>
                <span>{detail.subject}</span>
              </div>
              <div>
                <div>定員:</div>
                <span>{detail.capacity}名&nbsp;/&nbsp;部</span>
              </div>
              <div>
                <div>参加費:</div>
                <div id="price">
                  {!unitPrice ? (
                    <span>{unitPrice.toLocaleString()}円</span>
                  ) : komaNum === 1 ? (
                    <span>1,000円〜{unitPrice.toLocaleString()}円 / 通し</span>
                  ) : (
                    <span>1,000円〜{unitPrice.toLocaleString()}円 / 1コマ</span>
                  )}
                  {detail.payment_method?.is_only_cash ? (
                    <span id="cashOnly">※現金払いのみ</span>
                  ) : null}
                </div>
                {/* {detail.subject === '会員' ? (
                  detail.membership_fee ? (
                    <span>
                      会員&nbsp;:&nbsp;
                      {detail.membership_fee.toLocaleString()}
                      円&nbsp;/&nbsp;日
                    </span>
                  ) : (
                    <span>会員&nbsp;:&nbsp;無料</span>
                  )
                ) : (
                  <>
                    <span>
                      会員&nbsp;:&nbsp;
                      {detail.membership_fee.toLocaleString()}
                      円&nbsp;/&nbsp;日
                    </span>
                    &nbsp;&nbsp;&nbsp;
                    <span>
                      一般&nbsp;:&nbsp;
                      {detail.entry_fee.toLocaleString()}
                      円&nbsp;/&nbsp;日
                    </span>
                  </>
                )} */}
              </div>
              <div>
                <div>開催日:</div>
                <span>
                  {
                    Clock({
                      timezone: TIMEZONE,
                      time: null,
                      string: detail.event_date_start_time,
                    }).today
                  }
                  &nbsp;
                  {
                    Clock({
                      timezone: TIMEZONE,
                      time: null,
                      string: detail.part_one_start_time,
                    }).hourMinutes
                  }
                  &nbsp;~
                </span>
              </div>
              {singleClass ? (
                <>
                  {/* 1コマのみの場合 */}
                  <div>
                    <div>第一部:</div>
                    <span>
                      {
                        Clock({
                          timezone: TIMEZONE,
                          time: null,
                          string: detail.part_one_start_time,
                        }).hourMinutes
                      }
                      &nbsp;-&nbsp;
                      {
                        Clock({
                          timezone: TIMEZONE,
                          time: null,
                          string: detail.part_one_end_time,
                        }).hourMinutes
                      }
                    </span>
                  </div>
                </>
              ) : (
                <>
                  {/* 2コマ以上の場合 */}
                  <div>
                    <div>第一部:</div>
                    <span>
                      {
                        Clock({
                          timezone: TIMEZONE,
                          time: null,
                          string: detail.part_one_start_time,
                        }).hourMinutes
                      }
                      &nbsp;-&nbsp;
                      {
                        Clock({
                          timezone: TIMEZONE,
                          time: null,
                          string: detail.part_one_end_time,
                        }).hourMinutes
                      }
                    </span>
                  </div>
                  <div>
                    <div>第二部:</div>
                    <span>
                      {
                        Clock({
                          timezone: TIMEZONE,
                          time: null,
                          string: detail.part_two_start_time,
                        }).hourMinutes
                      }
                      &nbsp;-&nbsp;
                      {
                        Clock({
                          timezone: TIMEZONE,
                          time: null,
                          string: detail.part_two_end_time,
                        }).hourMinutes
                      }
                    </span>
                  </div>
                </>
              )}
              {detail.part_three_start_time ? (
                <div>
                  <div>第三部:</div>
                  <span>
                    {
                      Clock({
                        timezone: TIMEZONE,
                        time: null,
                        string: detail.part_three_start_time,
                      }).hourMinutes
                    }
                    &nbsp;-&nbsp;
                    {
                      Clock({
                        timezone: TIMEZONE,
                        time: null,
                        string: detail.part_three_end_time,
                      }).hourMinutes
                    }
                  </span>
                </div>
              ) : null}
              {detail.part_four_start_time ? (
                <div>
                  <div>懇親会:</div>
                  <span>
                    {
                      Clock({
                        timezone: TIMEZONE,
                        time: null,
                        string: detail.part_four_start_time,
                      }).hourMinutes
                    }
                    &nbsp;-&nbsp;
                    {
                      Clock({
                        timezone: TIMEZONE,
                        time: null,
                        string: detail.part_four_end_time,
                      }).hourMinutes
                    }
                  </span>
                </div>
              ) : null}
              <div>
                <div>場所:</div>
                <div id="place">
                  <span>{detail.display_name_of_the_place}</span>
                  <span id="note">
                    ※詳細はお申し込みされた方のみにお知らせいたします。
                  </span>
                </div>
              </div>
            </div>
          </div>
          {loading ? <Spinner /> : form()}
          <StandardDialog
            open={shownPaymentDialog}
            handleClose={handleClose}
            text={{
              title: 'お申し込みありがとうございます。',
              content:
                '決済情報が未登録です。寡少な運営のため、決済情報の登録を"切に"お願いしております。決済情報の登録は、マイページまたは以下のボタンから行うことができます。',
              agree: '決済情報を登録する',
              disagree: '今はしない',
            }}
            func={{
              agreeFunc: gotoPaymentView,
              disagreeFunc: disagreePayment,
              updateDoNotShowAgain: updateDoNotShowAgain,
            }}
          />
          <StandardDialog
            open={shownPaymentDialog2}
            handleClose={handleClose2}
            text={{
              title: 'お申し込みありがとうございます。',
              content:
                '自動決済がキャンセルされています。寡少な運営のため、決済情報の登録を"切に"お願いしております。再度自動決済に戻す場合は以下のボタンから行うことができます。',
              agree: '自動決済に戻す',
              disagree: '今はしない',
            }}
            func={{
              agreeFunc: gotoPaymentView2,
              disagreeFunc: disagreePayment2,
              updateDoNotShowAgain: updateDoNotShowAgain,
            }}
          />
          <StandardDialog
            open={shownPaymentDialog3}
            handleClose={handleClose3}
            text={{
              title: 'お申し込みありがとうございます。',
              content: '本セミナーのお支払い方法をご選択ください。',
              agree: 'クレジットカード',
              disagree: '現金',
            }}
            func={{
              agreeFunc: gotoPaymentView2,
              disagreeFunc: disagreePayment3,
            }}
          />
          <Footer />
        </>
      );
    }
  };

  return (
    <>
      <div>
        <OnOffByScroll place="General" />
      </div>
      {formMapping()}
    </>
  );
};
export default SeminarApplyForm;
