import various from '../General/various';
import ContentTitle from '../General/contentTitle';
import TripleGrid from '../ImageGrids/tripleGrid';
import ArrowButton from '../General/arrowButton';
import FadeIn from '../Animation/fadeIn';

import { makeStyles } from '@material-ui/core';
import { seminarIndexForToppage } from '../Apis/apis';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  contentBox: {
    textAlign: 'start',
    '& > div': {
      '& > span': {
        '@media screen and (max-width:400px)': {
          lineHeight: '42px !important',
        },
      },
    },
  },
  content: {
    marginLeft: '32px',
    '@media screen and (max-width:650px)': {
      margin: '0 0 0 0',
    },
  },
  arrow: {
    marginTop: '32px',
    float: 'right',
  },
}));

const SeminarOnTopPage = () => {
  const [seminar, setSeminar] = useState();
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState();
  const [contents, setContents] = useState();

  const classes = useStyles();
  const seminarAreaTitle = {
    titleList: ['Latest', 'Seminar'],
    sub: 'セミナー',
  };
  const fadeProps = {
    direction: '$up',
    duration: '1s',
    fillMode: 'forwards',
    timingFunction: 'ease-out',
  };

  const callback = useCallback(() => {
    return (
      <div className={classes.contentBox}>
        {/* <FadeIn fadeProps={fadeProps}> */}
        <ContentTitle title={seminarAreaTitle} />
        {/* </FadeIn> */}
        <div className={classes.content}>
          <TripleGrid data={seminar} />
          <FadeIn fadeProps={fadeProps}>
            <div className={classes.arrow}>
              <Link to={various.general.seminar}>
                <ArrowButton buttonName="VIEW ALL" color="#000" />
              </Link>
            </div>
          </FadeIn>
        </div>
      </div>
    );
    // eslint-disable-next-line
  }, [seminar, classes]);

  useEffect(() => {
    seminarIndexForToppage(setSeminar);
  }, []);

  useEffect(() => {
    if (seminar) {
      setView(true);
      setLoading(false);
    }
  }, [seminar]);

  useEffect(() => {
    if (view) {
      setContents(callback());
    }
  }, [view, callback]);

  return <>{loading ? null : view ? contents : null}</>;
};
export default SeminarOnTopPage;
