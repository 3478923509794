import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resetEmailConfirm } from '../Apis/apis';
import OnOffByScroll from '../Animation/onOffByScroll';
import various from '../General/various';
import { Spinner } from '../Spinner';

const EmailChangeConfirm = () => {
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const [confirm, setConfirm] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const logout = () => {
    window.localStorage.removeItem('Authorization');
    window.localStorage.removeItem('accesstoken');
    window.localStorage.removeItem('refreshtoken');
    window.localStorage.setItem('permission', null);
    console.log('logout...');
  };

  useEffect(() => {
    async function reset_email_confirm() {
      const token = searchParams.get('token');
      await resetEmailConfirm(setConfirm, setMessage, { token: token });
    }
    reset_email_confirm();
  }, [searchParams]);

  useEffect(() => {
    if (message) {
      alert(message);
    }
  }, [message]);

  useEffect(() => {
    setLoading(false);
    if (confirm) {
      setTimeout(() => {
        console.log('email reset finish!');
        logout();
        navigate(various.general.login);
      }, 1500);
    }
  }, [confirm, navigate]);

  return (
    <>
      <div>
        <OnOffByScroll place="General" />
      </div>
      {confirm && !loading ? (
        <>
          <div style={{ margin: '160px auto' }}>
            <p>メールアドレスの変更が完了しました。</p>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};
export default EmailChangeConfirm;
