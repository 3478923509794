import { makeStyles } from '@material-ui/core';
import various from '../General/various';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& #title': {
      color: '#FFF',
      marginBottom: '24px',
      fontWeight: 'bold',
      textAlign: 'start',
    },
    '& #qlContent': {
      fontSize: '1rem',
      paddingLeft: '8px',
      '& > div': {
        '& > div': {
          marginTop: '8px',
          marginBottom: '8px',
          textAlign: 'left',

          '& > a': {
            color: '#FFF',
            '&:hover': {
              color: 'black',
            },
          },
        },
      },
    },
  },
}));

const Follow = () => {
  const classes = useStyles();
  const snsUrls = various.snsUrls;

  const routing = (urls) => {
    const result = [];
    urls.forEach((url, index) => {
      result.push(
        <div key={index}>
          <a href={url.path}>{url.name}</a>
        </div>
      );
    });
    return result;
  };

  return (
    <div className={classes.container}>
      <div id="title">Follow</div>
      <div id="qlContent">
        <div>{routing(snsUrls)}</div>
      </div>
    </div>
  );
};

export default Follow;
