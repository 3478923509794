import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import {
  emailRegValidator,
  emailValidator,
  birthdayValidator,
  sessionApplyValidator,
  phoneNumberValidator,
} from '../CommonFunction/validators';
import ArrowButton from '../General/arrowButton';
import ContentTitle from '../General/contentTitle';
import various from '../General/various';
import { memberCreateOfNonAuthByContactForTraining } from '../Apis/apis';
import Footer from '../Footer/footer';
import { images as imageApi } from '../Apis/apis';
import { ogpGenerator } from '../ogp';

const ContactForTraining = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const [image, setImage] = useState();
  const [message, setMessage] = useState();
  const [message2, setMessage2] = useState();
  const [sended, setSended] = useState();

  const [token, setToken] = useState('');
  const [submitData, setSubmitData] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const location = useLocation();
  const navigate = useNavigate();
  const { classes } = useOutletContext();

  const contactAreaTitle = {
    titleList: ['Contact'],
    sub: '体験稽古申込・ご入会について',
  };

  // OGP設定
  const ogp = useCallback((props) => {
    ogpGenerator(props);
  }, []);

  useLayoutEffect(() => {
    if (image) {
      const ogpProps = {
        page: 'contact_training',
        pageTitle: '刀禪OfficialWebSite体験稽古お問合せフォーム',
        path: location.pathname,
        imageUrl: image.OfficialImage_TouzenLogoBlack.url,
      };
      ogp(ogpProps);
    }
  }, [image, location, ogp]);
  // OGP設定ここまで

  useEffect(() => {
    imageApi(setImage);
  }, []);

  const onSubmit = async (data) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    } else {
      const reCaptchaToken = await executeRecaptcha('contact');
      setToken(reCaptchaToken);
      setSubmitData(data);
    }
  };

  useEffect(() => {
    if (submitData && token) {
      const data = { ...submitData };
      data['g_recaptcha_response'] = token;
      data['flag'] = 'contact_for_training';
      apiHandler(data);
    }
    // eslint-disable-next-line
  }, [submitData, token]);

  const apiHandler = (data) => {
    let birthday = data.birthday + 'T00:00:00+09:00';
    const date = new Date(Date.parse(birthday));
    data['year_of_birth'] = date.getFullYear();
    data['year_of_month'] = date.getMonth() + 1;
    data['year_of_day'] = date.getDate();

    const protocol = window.location.protocol;
    const host = window.location.host;
    const domain = document.domain;
    const path = 'member_detail';
    const dataset = {
      protocol: protocol,
      host: host,
      domain: domain,
      path: path,
    };
    data['urlData'] = dataset;
    console.log('data:', data);
    memberCreateOfNonAuthByContactForTraining(data, setMessage, setMessage2);
    setSended(true);
  };

  useEffect(() => {
    if (sended) {
      if (message) {
        alert('お申込を受け付けました。返信メールをご確認ください。');
        navigate('/');
      }
      if (message2) {
        alert('会員の方は本フォームからの申込はできません。');
      }
    }
  }, [sended, message, message2, navigate]);

  const form = () => {
    return (
      <div className={classes.formContainer}>
        <form
          method="POST"
          encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <div className={classes.titles}>
              <label htmlFor="氏名（姓）">{'氏名（姓）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="last_name"
                type="text"
                {...register('last_name', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.last_name?.message && (
                <div className={classes.error}>{errors.last_name.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="氏名（カナ姓）">{'氏名（カナ姓）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="last_name_kana"
                type="text"
                {...register('last_name_kana', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.last_name_kana?.message && (
                <div className={classes.error}>
                  {errors.last_name_kana.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="氏名（名）">{'氏名（名）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="first_name"
                type="text"
                {...register('first_name', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.first_name?.message && (
                <div className={classes.error}>{errors.first_name.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="氏名（カナ名）">{'氏名（カナ名）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="first_name_kana"
                type="text"
                {...register('first_name_kana', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.first_name_kana?.message && (
                <div className={classes.error}>
                  {errors.first_name_kana.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="Eメール">{'Eメール'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="email"
                type="email"
                {...register('email', {
                  required: { value: true, message: '入力必須の項目です' },
                  validate: {
                    message: (v) =>
                      emailRegValidator(v)
                        ? null
                        : `※正しいEメールの形式で入力してください`,
                  },
                })}
              />
              {errors.email?.message && (
                <div className={classes.error}>{errors.email.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="Eメール（確認）">{'Eメール（確認）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="email_confirm"
                type="email"
                {...register('email_confirm', {
                  required: { value: true, message: '入力必須の項目です' },
                  validate: {
                    message: (v) =>
                      emailValidator(v)
                        ? null
                        : `上と同一のものを入力してください。`,
                  },
                })}
              />
              {errors.email_confirm?.message && (
                <div className={classes.error}>
                  {errors.email_confirm.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="生年月日">{'生年月日'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="birthday"
                type="date"
                {...register('birthday', {
                  required: { value: true, message: '入力必須の項目です' },
                  validate: {
                    message: (v) =>
                      birthdayValidator(v)
                        ? null
                        : `ご入力いただいたご年齢ではお申し込みを承ることができません`,
                  },
                })}
              />
              <div className={classes.helptext}>
                中学生以上の方からお申し込みを受け付けますが、18歳未満の場合は保護者の同意が必要です
              </div>
              {errors.birthday?.message && (
                <div className={classes.error}>{errors.birthday.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="電話番号">{'電話番号'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="phone_number"
                type="text"
                {...register('phone_number', {
                  required: { value: true, message: '入力必須の項目です' },
                  minLength: {
                    value: 10,
                    message: '短すぎます。市外局番からご入力ください',
                  },
                  maxLength: { value: 11, message: '電話番号が長すぎます' },
                  validate: {
                    message: (v) =>
                      phoneNumberValidator(v)
                        ? null
                        : `0から始まるハイフン無しの半角数字でご記入ください`,
                  },
                })}
              />
              <div className={classes.helptext}>
                固定電話の場合は市外局番からご記入ください。また、固定・携帯電話いずれもハイフン無しでご記入ください
              </div>
              {errors.phone_number?.message && (
                <div className={classes.error}>
                  {errors.phone_number.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="都道府県">{'都道府県'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="address1"
                type="text"
                {...register('address1', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.address1?.message && (
                <div className={classes.error}>{errors.address1.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="市区町村">{'市区町村'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="address2"
                type="text"
                {...register('address2', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.address2?.message && (
                <div className={classes.error}>{errors.address2.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="番地">{'番地'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="address3"
                type="text"
                {...register('address3', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.address3?.message && (
                <div className={classes.error}>{errors.address3.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="建物名等">{'建物名等'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="address4"
                type="text"
                {...register('address4', {
                  required: { value: false, message: '入力必須の項目です' },
                })}
              />
              {errors.address4?.message && (
                <div className={classes.error}>{errors.address4.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="Facebookアカウント">{'Facebookアカウント'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="facebook_account"
                type="url"
                {...register('facebook_account', {
                  required: { value: false, message: '入力必須の項目です' },
                })}
              />
              <div className={classes.helptext}>
                Facebookアカウントをお持ちであれば、アカウントURL（例：https://www.facebook.com/yamada.taro）を教えてください
              </div>
              {errors.facebook_account?.message && (
                <div className={classes.error}>
                  {errors.facebook_account.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="運動歴">{'運動歴'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="exercise_career"
                type="text"
                {...register('exercise_career', {
                  required: { value: false, message: '入力必須の項目です' },
                  maxLength: { value: 50, message: '50字以内でご記入ください' },
                })}
              />
              {errors.exercise_career?.message && (
                <div className={classes.error}>
                  {errors.exercise_career.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="体験稽古希望日">{'体験稽古希望日'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="preferred_date"
                type="date"
                {...register('preferred_date', {
                  required: { value: true, message: '入力必須の項目です' },
                  validate: {
                    message: (v) =>
                      sessionApplyValidator(v) ? null : `ご希望日が不正です`,
                  },
                })}
              />
              <div className={classes.helptext}>
                ご希望日は、必ず本サイト内の「稽古カレンダー」を事前にご確認いただき、「一般公開」を承れる日に合わせてお申し込みください。その他の稽古日をご希望されてもお受けできません。また、セミナー実施日がご希望日の場合は、セミナー参加者として本サイトの「セミナー申込」からお申し込みください
              </div>
              {errors.preferred_date?.message && (
                <div className={classes.error}>
                  {errors.preferred_date.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="参加の理由・動機">{'参加の理由・動機'}</label>
            </div>
            <div className={classes.inputs}>
              <textarea
                id="reason_and_motivation"
                type="text"
                {...register('reason_and_motivation', {
                  required: { value: false, message: '入力必須の項目です' },
                  maxLength: {
                    value: 300,
                    message: '300字以内でご記入ください',
                  },
                })}
              />
              {errors.reason_and_motivation?.message && (
                <div className={classes.error}>
                  {errors.reason_and_motivation.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="質問・要望">{'質問・要望'}</label>
            </div>
            <div className={classes.inputs}>
              <textarea
                id="question_and_request"
                type="text"
                {...register('question_and_request', {
                  required: { value: false, message: '入力必須の項目です' },
                  maxLength: {
                    value: 300,
                    message: '300字以内でご記入ください',
                  },
                })}
              />
              {errors.question_and_request?.message && (
                <div className={classes.error}>
                  {errors.question_and_request.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="セミナー通知可否">{'セミナー通知可否'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="notification_of_seminar"
                type="checkbox"
                {...register('notification_of_seminar', {
                  required: { value: false, message: '入力必須の項目です' },
                })}
              />
              <div className={classes.helptext}>
                今後、セミナー情報の配信をご希望される場合は、チェックを入れてください。
              </div>
              {errors.notification_of_seminar?.message && (
                <div className={classes.error}>
                  {errors.notification_of_seminar.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="ニュース通知可否">{'ニュース通知可否'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="notification_of_news"
                type="checkbox"
                {...register('notification_of_news', {
                  required: { value: false, message: '入力必須の項目です' },
                })}
              />
              <div className={classes.helptext}>
                今後、ニュース配信をご希望される場合は、チェックを入れてください。
              </div>
              {errors.notification_of_news?.message && (
                <div className={classes.error}>
                  {errors.notification_of_news.message}
                </div>
              )}
            </div>
          </div>

          <div className={classes.arrow}>
            <ArrowButton path={null} buttonName="SUBMIT" color="#FFF" />
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <div className={classes.rootContainer}>
        <div className={classes.contentTitle}>
          <ContentTitle title={contactAreaTitle} />{' '}
        </div>
        <div className={classes.container}>
          <p>
            体験稽古へのお申し込みは以下のフォームからお申し込みください。
            <br />
            　なお、ご入会を検討されている方は、体験が可能な一般稽古日を
            <Link to={various.general.calendar}>カレンダー</Link>
            からご確認いただき、ご都合がつくようでしたらその日を希望日として体験稽古をお申し込みください（体験稽古可能稽古日が無い月もあります。ご了承ください）。
            <br />
            　その他の方法としては、お近くの同好会・稽古会を
            <Link to={various.general.branch}>こちら</Link>
            お探しいただき、同好会・稽古会へ直接お問合せ願います。
          </p>
          <div className={classes.links}>
            <span>その他のお問合せ</span>
            <div>
              <div>
                <span>同好会・稽古会に対して</span>
                <ArrowButton
                  path={various.general.contactForClub}
                  buttonName="CONTACT"
                  color="#000"
                />
              </div>
              <div>
                <span>その他問い合わせ</span>
                <ArrowButton
                  path={various.general.contact}
                  buttonName="CONTACT"
                  color="#000"
                />
              </div>
            </div>
          </div>
        </div>
        {form()}
      </div>
      <Footer />
    </>
  );
};
export default ContactForTraining;
