import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, makeStyles } from '@material-ui/core';
import { DataGrid, jaJP } from '@mui/x-data-grid';

import OnOffByScroll from '../../../Animation/onOffByScroll';
import { AuthContext } from '../../../auth';
import { images as imageApi } from '../../../Apis/apis';
import various from '../../../General/various';
import Footer from '../../../Footer/footer';
import { localStorageArrayHandler } from '../../../CommonFunction/managePermission';
import { Spinner } from '../../../Spinner';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
  },
  container: {
    marginTop: '64px',
  },
  dataGrid: {
    height: (props) =>
      props.heightSize ? props.heightSize : props.pageSize * props.rowHeight,
    width: '100%',
  },
}));

const ImageUpDater = () => {
  const { isAuth } = useContext(AuthContext);
  const permissions = localStorageArrayHandler('parse', various.permissionKey);

  const [images, setImages] = useState([]);
  const [date, setDate] = useState();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [heightSize, setHeightSize] = useState();
  const [loading, setLoading] = useState(true);

  const ROWHEIGHT = 80;
  const PAGESIZE = 30;
  const classes = useStyles({
    pageSize: PAGESIZE,
    rowHeight: ROWHEIGHT,
    heightSize: heightSize,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '静的イメージ一覧', path: '/manage/image_updater' },
  ];

  // Init
  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  useEffect(() => {
    imageApi(setImages);
  }, []);

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleClick = (row) => {
    navigate('/manage/updater_push', {
      state: { previous: location.pathname, uuid: row.uuid, title: row.title },
    });
  };

  // Contents
  useEffect(() => {
    if (images) {
      setColumns([
        {
          field: 'id',
          headerName: 'ID',
          width: 10,
        },
        {
          field: 'uuid',
        },
        {
          field: 'image',
          headerName: '登録イメージ',
          cellClassName: 'image-cell',
          width: 150,
          renderCell: (params) => (
            <img src={params.row.image} alt={`image_${params.id}`} />
          ),
        },
        {
          field: 'title',
          headerName: 'タイトル',
          width: 200,
        },
        {
          field: 'descs',
          headerName: '説明',
          cellClassName: 'descs-cell',
          width: 400,
        },
        {
          field: 'updateBtn',
          headerName: '更新',
          sortable: false,
          width: 200,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <div>
              <button onClick={() => handleClick(params.row)}>更新</button>
            </div>
          ),
        },
      ]);

      const renewalList = [
        'Tools_takejaku',
        'Tools_bokutou',
        'AboutPageImages_aboutTopImage',
        'BranchPageImages_branchTopImage',
        'Tools_maiougi_dialog2',
        'Tools_maiougi_dialog3',
        'Tools_takejaku_dialog1',
        'Tools_takejaku_dialog2',
        'Tools_takejaku_dialog3',
        'Tools_maiougi_dialog1',
        'Tools_bokutou_dialog2',
        'AboutPageImages_aboutImage1',
        'Tools_bokutou_dialog3',
        'OfficialImage_topPageImage',
        'Tools_maiougi',
        'Tools_bokutou_dialog1',
        'OfficialImage_aboutImage',
        'AboutPageImages_aboutImage16',
        'AboutPageImages_aboutImage12',
        'AboutPageImages_aboutImage15',
        'AboutPageImages_aboutImage14',
        'AboutPageImages_aboutImage17',
        'AboutPageImages_aboutImage13',
        '',
      ];
      const extractKeys = Object.keys(images).filter((key) => {
        return renewalList.includes(key);
      });
      setRows(
        extractKeys.map((k, index) => {
          const row = {};
          const obj = images[k];
          row['id'] = index + 1;
          row['uuid'] = obj.uuid;
          row['image'] = obj.url;
          row['title'] = obj.title;
          row['descs'] = obj.descs;
          return row;
        })
      );
      setHeightSize(extractKeys.length * ROWHEIGHT * 1.4 + 122);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [images]);

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {!isAuth ? (
        <>
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleAuth}
            >
              ログイン
            </span>
            してください。
          </div>
          <Footer />
        </>
      ) : permissions.includes('admin') ? (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">静的イメージの更新</span>
              <span>{date}</span>
            </div>
          </div>
          <hr />
          <BreadCrumbs crumbs={CRUMBS} />
          {loading ? (
            <Spinner />
          ) : (
            <div className={classes.container}>
              <Box
                className={classes.dataGrid}
                sx={{
                  '& .image-cell': {
                    '& > img': {
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    },
                  },
                  '& .descs-cell': {
                    '& > div': {
                      overFrow: 'scroll !important',
                    },
                  },
                }}
              >
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={PAGESIZE}
                  rowsPerPageOptions={[PAGESIZE]}
                  // checkboxSelection
                  density="comfortable"
                  columnVisibilityModel={{ uuid: false }}
                  pagination={true}
                  autoPageSize={true}
                  rowHeight={ROWHEIGHT}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                  localeText={
                    jaJP.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              </Box>
            </div>
          )}
          <Footer />
        </>
      ) : permissions === 'null' ? (
        setTimeout(() => {
          window.location.reload(true);
        }, 500)
      ) : (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">静的イメージの更新</span>
              <span>{date}</span>
            </div>
          </div>
          <hr />
          <div className={classes.container}>権限がありません</div>
        </>
      )}
    </>
  );
};
export default ImageUpDater;
