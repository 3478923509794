import various from '../General/various';
const Youtube = () => {
  return (
    <iframe
      src={`https://www.youtube.com/embed/${various.youtubeId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  );
};
export default Youtube;
