import { makeStyles } from '@material-ui/core';

import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import ContentTitle from '../General/contentTitle';
import various from '../General/various';
import imageIndex from '../img/imageIndex';
import ArrowButton from '../General/arrowButton';
import Clock from '../General/clock';
import { seminarDetailPreview } from '../Apis/apis';
import { ogpGenerator } from '../ogp';
import Footer from '../Footer/footer';
import { AuthContext } from '../auth';
import { memberIndex, myPage } from '../Apis/apiOfManaged';
import OnOffByScroll from '../Animation/onOffByScroll';
import { Spinner } from '../Spinner';

const useStyles = makeStyles((theme) => ({
  badge: {},
  badgeEnd: {},
  memberBadge: {},
  secretBadge: {},
  badgeContainer: {
    color: '#FFF',
    textAlign: 'start',
    width: '100%',
    marginTop: '168px',
    marginBottom: '4px',
    '& > span': {
      display: 'inline-block',
      width: '80px',
      height: '20px',
      textAlign: 'center',
      verticalAlign: 'middle',
      border: 'none',
      borderRadius: '20px',
      fontSize: '0.7rem',
      marginRight: '4px',
    },
    '& $badge': {
      backgroundColor: theme.palette.primary.main,
    },
    '& $badgeEnd': {
      backgroundColor: 'grey',
    },
    '& $memberBadge': {
      backgroundColor: 'darkslategray',
    },
    '& $secretBadge': {
      backgroundColor: theme.palette.background.default,
      color: '#000',
      border: 'solid 1px #000',
    },
  },
  contentTitle: {
    display: 'flex',
    justifyContent: 'start',
    '& > div': {
      marginBottom: '64px',
      '& > a': {
        '& > div': {
          fontSize: '2.5rem',
          '& > span': {
            '& > span': {
              '& > span': {
                lineHeight: '40px',
                textAlign: 'justify',
              },
            },
          },
          '@media screen and (max-width:550px)': {
            fontSize: '2rem',
            '& > span': {
              '& > span': {
                '& > span': {
                  lineHeight: '36px !important',
                },
              },
            },
          },
        },
      },
    },
  },
  hashTags: {
    textAlign: 'start',
    fontWeight: 'bold',
    lineHeight: '24px',
    '& > span': {
      display: 'inline-block',
      marginRight: '16px',
      fontSize: '1rem',
      fontStyle: 'italic',
    },
  },
  decoTop: {
    marginTop: '112px',
    marginBottom: '56px',
    '& > img': {
      width: '100%',
    },
  },
  container: {},
  descriptionWrapper: {
    width: '100%',
    minHeight: `calc(70vw * 0.45 + 32px)`,
    borderBottom: 'solid 1px #000',
    marginBottom: '64px',
    '& > p': {
      width: '100%',
      textAlign: 'justify',
      marginTop: 0,
      marginBottom: '48px ',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      lineHeight: '28px',
      fontSize: '1rem',
      '& > span': {
        '& > a': {
          display: 'inline-block',
          '&:link': {
            color: '#000',
          },
          '&:visited': {
            color: '#000',
          },
          '&:hover': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
  },
  seminarImage: {
    width: 'calc(70vw * 0.45)',
    margin: '0px 32px 0px 0px',
    float: 'left',
    '& > img': {
      display: 'inline-block',
      width: 'calc(70vw * 0.45)',
      height: 'calc(70vw * 0.45)',
      objectFit: 'cover',
      // filter:
      //   'contrast(100%) grayscale(100%) saturate(0%) drop-shadow(2px 2px 2px black)',
      filter: 'drop-shadow(2px 2px 2px black)',
    },
  },
  shown: {
    color: 'grey',
    fontWeight: 'bold',
    cursor: 'pointer',
  },

  readWrapper: {
    display: 'flex',
    width: '100%',
    gap: '0px 5vw',
    borderBottom: 'solid 1px #000',
    '@media screen and (max-width:1000px)': {
      flexFlow: 'column',
      '& $instructorImageWrapper': {
        width: '100%',
        '& $instructorImage': {
          float: 'left',
        },
      },
      '& $readElm': {
        width: '100%',
      },
    },
  },
  instructorImageWrapper: {
    width: 'calc(70vw * 0.45)',
    paddingBottom: '64px',
  },
  instructorImage: {
    width: 'calc(70vw * 0.45 * 0.8 )',
    height: 'calc(70vw * 0.45 * 0.8 )',
    margin: '0px 16px 8px 0px',
    '& > img': {
      width: 'calc(70vw * 0.45 * 0.8 )',
      height: 'calc(70vw * 0.45 * 0.8 )',
      filter:
        'contrast(100%) grayscale(100%) saturate(0%) drop-shadow(2px 2px 0px black)',
      objectFit: 'cover',
    },
  },
  instructorBiography: {
    textAlign: 'justify',
    fontSize: '1rem',
    '& > p': {
      marginTop: 0,
      fontWeight: 'bold',
    },
    '& > span': {
      whiteSpace: 'pre-wrap',
      letterSpacing: '0.02rem',
      wordBreak: 'break-word',
      lineHeight: '28px',
    },
  },
  readElm: {
    width: '55%',
    marginBottom: '56px',
    textAlign: 'start',
    fontWeight: 'bold',
    lineHeight: '2rem',
    fontSize: '1rem',
    '@media screen and (max-width:550px)': {
      '& > div': {
        marginBottom: '16px',
        '& > div': {
          width: '100px !important',
        },
        '& $place': {
          width: '70% !important',
        },
      },
    },

    '& > div': {
      display: 'flex',
      marginBottom: '40px',
      '& > div': {
        width: '90px',
        textAlignLast: 'justify',
        marginRight: '16px',
      },
    },
  },
  place: {
    width: '70% !important',
    display: 'flex',
    flexFlow: 'column',
    textAlignLast: 'start !important',
  },
  placeNote: {
    width: '100%',
    fontSize: '0.8rem',
    fontWeight: 'normal',
    opacity: '0.5',
    verticalAlign: 'bottom',
    textAlignLast: 'start !important',
    lineHeight: '1rem !important',
  },
  docsDownload: {
    borderBottom: 'solid 1px #000',
    '& > div': {
      width: '100%',
      marginTop: '64px',
      marginBottom: '64px',
      textAlign: 'start',
      '& > p': {
        fontSize: '0.8rem',
        margin: 0,
        opacity: '0.5',
      },
      '& > a': {
        '& > button': {
          width: '40%',
          fontFamily: theme.typography.fontFamily,
          display: 'inline-block',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          color: '#FFF',
          fontWeight: 'bold',
          backgroundColor: theme.palette.primary.main,
          textAlign: 'center',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease',
          '&:hover': {
            backgroundColor: '#4f455c',
          },
        },
      },
    },
  },
  attention: {
    marginTop: '64px',
    marginBottom: '56px',
    textAlign: 'start',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    fontSize: '1rem',
    '& > div': {
      display: 'flex',
      opacity: '0.5',
      '& > div': {
        verticalAlign: 'baseline',
      },
    },
    '& > p': {
      '& > a': {
        display: 'inline-block',
        '&:link': {
          color: '#000',
        },
        '&:visited': {
          color: '#000',
        },
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  mark: {
    paddingTop: '4px',
    marginRight: '8px',
  },

  decoBottom: {
    marginTop: '56px',
    '& > img': {
      width: '100%',
    },
  },
  arrow: {
    backgroundColor: theme.palette.primary.main,
    marginTop: '64px',
    marginRight: 0,
    marginLeft: 'auto',
    width: '120px',
  },
  arrowEnd: {
    backgroundColor: 'grey',
    marginTop: '64px',
    marginRight: 0,
    marginLeft: 'auto',
    width: '120px',
    cursor: 'not-allowed',
  },
  memberOnly: {
    display: 'block',
    width: '100%',
    marginTop: '16px',
    color: 'grey',
    fontSize: '0.8rem',
    textAlign: 'end',
    '& > span': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  },
}));

const SeminarDetailPreview = () => {
  const { isAuth } = useContext(AuthContext);
  const TIMEZONE = various.timezone;

  const [user, setUser] = useState();
  const [members, setMembers] = useState([]);
  const [member, setMember] = useState();
  const [isMember, setIsMember] = useState(false);
  const [uuid, setUuid] = useState();

  const [shown, setShown] = useState(false);
  const [badge, setBadge] = useState([]);
  const [detail, setDetail] = useState();
  const [komaNum, setKomaNum] = useState();
  const [unitPrice, setUnitPrice] = useState(0);

  const [loading, setLoading] = useState(true);

  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();

  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get('id');
  const params = useParams();

  // OGP設定
  const ogp = useCallback((props) => {
    ogpGenerator(props);
  }, []);

  useLayoutEffect(() => {
    if (detail) {
      const ogpProps = {
        page: 'seminar_detail',
        pageTitle:
          detail.title +
          (detail.serial_number ? ` #${detail.serial_number}` : '') +
          'セミナー開催',
        path: location.pathname,
        imageUrl: detail.image,
      };
      ogp(ogpProps);
    }
  }, [detail, location, ogp]);
  // OGP設定ここまで

  useEffect(() => {
    if (params.seminarId) {
      setUuid(params.seminarId);
    } else if (query) {
      navigate(`${various.general.seminarPreview}/${query}`);
    }
  }, [query, params, navigate]);

  useEffect(() => {
    if (uuid) {
      seminarDetailPreview(setDetail, uuid);
    }
  }, [uuid]);

  // useEffect(() => {
  //   if (detail) {
  //     if (!detail.is_reminded) {
  //       // 以下、セミナーのリマインドメール送信のための処理
  //       const seminarDateTime = new Date(
  //         Date.parse(detail.event_date_start_time.replace(/-/g, '/'))
  //       );
  //       // seminarDateTimeの前日の午後8時を取得
  //       let targetDateBefore = new Date(seminarDateTime.getTime());
  //       targetDateBefore.setDate(targetDateBefore.getDate() - 1);
  //       targetDateBefore.setHours(20);
  //       targetDateBefore.setMinutes(0);
  //       targetDateBefore.setSeconds(0);
  //       targetDateBefore.setMilliseconds(0);

  //       // seminarDateTimeの前日の23:59:59.999を取得
  //       let targetDateEnd = new Date(seminarDateTime.getTime());
  //       // targetDateEnd.setDate(targetDateEnd.getDate() - 1);
  //       // targetDateEnd.setHours(23);
  //       // targetDateEnd.setMinutes(59);
  //       // targetDateEnd.setSeconds(59);
  //       // targetDateEnd.setMilliseconds(999);

  //       const today = new Date();

  //       if (
  //         today.getTime() > targetDateBefore.getTime() &&
  //         today.getTime() <= targetDateEnd.getTime() &&
  //         !detail.is_reminded
  //       ) {
  //         const data = {};
  //         data['uuid'] = detail.uuid;
  //         data['title'] =
  //           detail.title +
  //           (detail.serial_number ? ' #' + detail.serial_number : '');
  //         data['date'] =
  //           Clock({
  //             timezone: TIMEZONE,
  //             time: null,
  //             string: detail.event_date_start_time,
  //           }).today +
  //           ' ' +
  //           Clock({
  //             timezone: TIMEZONE,
  //             time: null,
  //             string: detail.event_date_start_time,
  //           }).hourMinutes +
  //           ' ~';
  //         data['place'] = detail.place.facilityName + ' ' + detail.hall.name;
  //         data['address'] = detail.place.address;
  //         data['gmap'] = detail.place.gmap;
  //         data['detaildataPath'] = `/seminar/${detail.uuid}`;
  //         data['attention'] = detail.notes;
  //         const protocol = window.location.protocol;
  //         const host = window.location.host;
  //         const domain = document.domain;
  //         const path = 'contact';
  //         const dataset = {
  //           email: null,
  //           protocol: protocol,
  //           host: host,
  //           path: path,
  //           domain: domain,
  //         };
  //         data['urlData'] = dataset;
  //         data['announce'] = null;
  //         seminarRemindMail(data);
  //       }
  //     }
  //   }
  // }, [detail, TIMEZONE]);

  const handleClick = (e) => {
    navigate(various.general.seminarApplyForm, {
      state: { uuid: detail.uuid },
    });
  };

  const handleShown = () => {
    setShown(true);
  };

  const handleLogin = () => {
    navigate(`${various.general.login}`, {
      state: { previous: location.pathname },
    });
  };

  useEffect(() => {
    if (komaNum && detail) {
      const oneDayPrice = detail.membership_fee;
      if (oneDayPrice) {
        setUnitPrice(oneDayPrice / komaNum);
      }
      setLoading(false);
    }
  }, [komaNum, detail]);

  useEffect(() => {
    if (detail) {
      const NOW = Clock({ timezone: TIMEZONE }).time;
      const dead = Clock({
        timezone: TIMEZONE,
        time: null,
        string: detail.part_one_start_time,
      }).time;

      if (dead < NOW) {
        setBadge(false);
      } else {
        setBadge(true);
      }
      let komanum = 1;
      const seminarTime = {
        part2: Clock({
          timezone: TIMEZONE,
          time: null,
          string: detail.part_two_end_time,
        }).hour,
        part3: detail.part_three_end_time
          ? Clock({
              timezone: TIMEZONE,
              time: null,
              string: detail.part_three_end_time,
            }).hour
          : null,
        part4: detail.part_four_end_time
          ? Clock({
              timezone: TIMEZONE,
              time: null,
              string: detail.part_four_end_time,
            }).hour
          : null,
      };
      if (seminarTime.part2 !== 23) komanum += 1;
      if (seminarTime.part3 !== null) komanum += 1;
      // 現状、4コマ目は肝稽古用として使用しているため、コマ数にはカウントせず
      // if (seminarTime.part4 !== null) komanum += 1;
      setKomaNum(komanum);
    }
  }, [detail, TIMEZONE]);

  useEffect(() => {
    if (member) {
      setIsMember(member.is_member || member.is_training_group_member);
    }
  }, [member]);

  useEffect(() => {
    if (members.length > 0 && user) {
      members.forEach((m) => {
        if (m.email === user.email && m.user === user.id) {
          setMember(m);
        }
      });
    }
  }, [members, user]);

  useEffect(() => {
    if (user) {
      memberIndex(setMembers);
    }
  }, [user]);

  useEffect(() => {
    if (isAuth) myPage(setUser);
  }, [isAuth]);

  // 稽古会会員のセミナー申込受付の切り分けを実装する場合は以下の手順を想定
  // 1)Seminar.subjectフィールドに('稽古会会員','稽古会会員',)タプルを追加-->migrate
  // 2)セミナー新規登録画面でsubjectの選択肢を追加
  // 3)seminarDitail & seminarDetailPreviewにてbadgeとapplyの実装をそれに最適化する
  // 20230228現在：正会員、稽古会会員の区別なく、会員専用セミナーには申し込める仕様

  const seminarDetailMapping = () => {
    const linkedContent = (text) => {
      // 参考：https://omkz.net/text-url-link/
      const regExp = /(https?:\/\/[\w/:%#$&?()~.=+-]+)/g;
      return text.replace(regExp, '<a href="$1">$1</a>');
    };
    if (detail && komaNum) {
      // console.log('detail', detail);
      return (
        <>
          <div className={classes.badgeContainer}>
            {badge ? (
              <span className={classes.badge}>応募受付中</span>
            ) : (
              <span className={classes.badgeEnd}>受付終了</span>
            )}
            {detail.subject === '会員' ? (
              <span className={classes.memberBadge}>会員のみ</span>
            ) : null}
            {detail.is_secret ? (
              <span className={classes.secretBadge}>秘伝</span>
            ) : null}
          </div>
          <div className={classes.contentTitle}>
            <div>
              <a href={`${various.general.seminarPreview}/${detail.uuid}`}>
                <ContentTitle
                  title={
                    detail.serial_number
                      ? {
                          titleList: [
                            detail.title + ' #' + detail.serial_number,
                          ],
                          sub: null,
                        }
                      : {
                          titleList: [detail.title],
                          sub: null,
                        }
                  }
                />
              </a>
            </div>
          </div>
          <div className={classes.hashTags}>
            {detail.tag.map((h, i) => {
              return <span key={i}>#{h}</span>;
            })}
          </div>
          <div className={classes.decoTop}>
            <img
              src={imageIndex.semianrPage.detail.decoLineTop}
              alt="decoTop"
            />
          </div>
          <div className={classes.container}>
            <div className={classes.descriptionWrapper}>
              <div className={classes.seminarImage}>
                <img src={detail.image} alt="smeinarImage" />
              </div>
              <p>
                {shown ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: linkedContent(detail.guidance),
                    }}
                  />
                ) : detail.guidance.length < 401 ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: linkedContent(detail.guidance),
                    }}
                  />
                ) : (
                  <>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: linkedContent(detail.guidance.slice(0, 401)),
                      }}
                    />
                    <span className={classes.shown} onClick={handleShown}>
                      ...もっと見る
                    </span>
                  </>
                )}
              </p>
            </div>
            <div className={classes.readWrapper}>
              {detail.show_the_instructor ? (
                <div className={classes.instructorImageWrapper}>
                  <div className={classes.instructorImage}>
                    <img src={detail.instructor.image} alt="instructor" />
                  </div>
                  <div className={classes.instructorBiography}>
                    <p>講師:&nbsp;{detail.instructor.name}</p>
                    <span>{detail.instructor.biography}</span>
                  </div>
                </div>
              ) : undefined}
              <div className={classes.readElm}>
                <div>
                  <div>対象者:</div>
                  <span>{detail.subject}</span>
                </div>
                <div>
                  <div>定員:</div>
                  <span>{detail.capacity}名&nbsp;/&nbsp;部</span>
                </div>
                <div>
                  <div>参加費:</div>
                  <div className={classes.place}>
                    {!unitPrice ? (
                      <span>{unitPrice.toLocaleString()}円</span>
                    ) : komaNum === 1 ? (
                      <span>
                        1,000円〜{unitPrice.toLocaleString()}円 / 通し
                      </span>
                    ) : (
                      <span>
                        1,000円〜{unitPrice.toLocaleString()}円 / 1コマ
                      </span>
                    )}
                    {detail.payment_method?.is_only_cash ? (
                      <span className={classes.placeNote}>※現金払いのみ</span>
                    ) : null}
                  </div>
                  {/* {detail.subject === '会員' ? (
                    detail.membership_fee ? (
                      <span>
                        会員&nbsp;:&nbsp;
                        {detail.membership_fee.toLocaleString()}
                        円&nbsp;/&nbsp;日
                      </span>
                    ) : (
                      <span>会員&nbsp;:&nbsp;無料</span>
                    )
                  ) : (
                    <>
                      <span>
                        会員&nbsp;:&nbsp;
                        {detail.membership_fee.toLocaleString()}
                        円&nbsp;/&nbsp;日
                      </span>
                      &nbsp;&nbsp;&nbsp;
                      <span>
                        一般&nbsp;:&nbsp;
                        {detail.entry_fee.toLocaleString()}
                        円&nbsp;/&nbsp;日
                      </span>
                    </>
                  )} */}
                </div>
                <div>
                  <div>開催日:</div>
                  <span>
                    {
                      Clock({
                        timezone: TIMEZONE,
                        time: null,
                        string: detail.event_date_start_time,
                      }).today
                    }
                    &nbsp;
                    {
                      Clock({
                        timezone: TIMEZONE,
                        time: null,
                        string: detail.part_one_start_time,
                      }).hourMinutes
                    }
                    &nbsp;~
                  </span>
                </div>
                {Clock({
                  timezone: TIMEZONE,
                  time: null,
                  string: detail.part_two_start_time,
                }).hour === 23 &&
                Clock({
                  timezone: TIMEZONE,
                  time: null,
                  string: detail.part_two_start_time,
                }).minutes === 59 ? (
                  <>
                    {/* 1コマの場合 */}
                    <div>
                      <div>第一部:</div>
                      <span>
                        {
                          Clock({
                            timezone: TIMEZONE,
                            time: null,
                            string: detail.part_one_start_time,
                          }).hourMinutes
                        }
                        &nbsp;-&nbsp;
                        {
                          Clock({
                            timezone: TIMEZONE,
                            time: null,
                            string: detail.part_one_end_time,
                          }).hourMinutes
                        }
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    {/* 2コマ以上の場合 */}
                    <div>
                      <div>第一部:</div>
                      <span>
                        {
                          Clock({
                            timezone: TIMEZONE,
                            time: null,
                            string: detail.part_one_start_time,
                          }).hourMinutes
                        }
                        &nbsp;-&nbsp;
                        {
                          Clock({
                            timezone: TIMEZONE,
                            time: null,
                            string: detail.part_one_end_time,
                          }).hourMinutes
                        }
                      </span>
                    </div>
                    <div>
                      <div>第二部:</div>
                      <span>
                        {
                          Clock({
                            timezone: TIMEZONE,
                            time: null,
                            string: detail.part_two_start_time,
                          }).hourMinutes
                        }
                        &nbsp;-&nbsp;
                        {
                          Clock({
                            timezone: TIMEZONE,
                            time: null,
                            string: detail.part_two_end_time,
                          }).hourMinutes
                        }
                      </span>
                    </div>
                  </>
                )}
                {detail.part_three_start_time ? (
                  <div>
                    <div>第三部:</div>
                    <span>
                      {
                        Clock({
                          timezone: TIMEZONE,
                          time: null,
                          string: detail.part_three_start_time,
                        }).hourMinutes
                      }
                      &nbsp;-&nbsp;
                      {
                        Clock({
                          timezone: TIMEZONE,
                          time: null,
                          string: detail.part_three_end_time,
                        }).hourMinutes
                      }
                    </span>
                  </div>
                ) : null}
                {detail.part_four_start_time ? (
                  <div>
                    <div>懇親会:</div>
                    <span>
                      {
                        Clock({
                          timezone: TIMEZONE,
                          time: null,
                          string: detail.part_four_start_time,
                        }).hourMinutes
                      }
                      &nbsp;-&nbsp;
                      {
                        Clock({
                          timezone: TIMEZONE,
                          time: null,
                          string: detail.part_four_end_time,
                        }).hourMinutes
                      }
                    </span>
                  </div>
                ) : null}
                <div>
                  <div>場所:</div>
                  <div className={classes.place}>
                    <span>{detail.display_name_of_the_place}</span>
                    <span className={classes.placeNote}>
                      ※詳細はお申し込みされた方のみにお知らせいたします。
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {detail.documents.document && detail.documents.is_inuse ? (
              member && member.participated_seminars.includes(detail.uuid) ? (
                <div className={classes.docsDownload}>
                  <div>
                    <p>
                      ▶︎以下のボタンから配布資料をダウンロードしてください。
                    </p>
                    <a href={detail.documents.document} download>
                      <button>配布資料</button>
                    </a>
                  </div>
                </div>
              ) : null
            ) : null}
            <div className={classes.attention}>
              <div>
                <div className={classes.mark}>
                  <imageIndex.semianrPage.detail.warning fontSize="small" />
                </div>
                <div>注意事項</div>
              </div>
              <p>{detail.notes}</p>
            </div>
          </div>
          <div className={classes.decoBottom}>
            <img
              src={imageIndex.semianrPage.detail.decoLineBottom}
              alt="decoBottom"
            />
          </div>
          <div
            className={
              badge
                ? detail.capacity > detail.num_of_applicans
                  ? detail.subject === '会員'
                    ? isMember
                      ? classes.arrow
                      : classes.arrowEnd
                    : classes.arrow
                  : classes.arrowEnd
                : classes.arrowEnd
            }
          >
            <ArrowButton
              clickFunc={handleClick}
              color="#FFF"
              buttonName="APPLY"
              disabled={
                badge
                  ? detail.capacity > detail.num_of_applicans
                    ? detail.subject === '会員'
                      ? isMember
                        ? false
                        : true
                      : false
                    : true
                  : true
              }
            />
          </div>
          {badge ? (
            detail.capacity > detail.num_of_applicans ? (
              detail.subject === '会員' ? (
                isMember ? null : (
                  <span className={classes.memberOnly}>
                    本セミナーは会員の方のみお申し込み可能です。
                    <br />
                    会員の方は
                    <span onClick={handleLogin}>ログイン</span>
                    してください。
                  </span>
                )
              ) : null
            ) : (
              <span className={classes.memberOnly}>
                現在、定員を超える応募をいただいております。
                <br />
                キャンセルが出ましたら受付を再開いたします。
              </span>
            )
          ) : (
            <span className={classes.memberOnly}>
              本セミナーは終了いたしました。
            </span>
          )}
          <Footer />
        </>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {loading ? <Spinner /> : seminarDetailMapping()}
    </>
  );
};
export default SeminarDetailPreview;
