import { Badge, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';

import FadeIn from '../Animation/fadeIn';
import ArrowButton from '../General/arrowButton';
import Clock from '../General/clock';
import ContentTitle from '../General/contentTitle';
import various from '../General/various';
import { newsIndex } from '../Apis/apis';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'start',
    borderBottom: 'solid 1px grey',
    margin: '0 auto',
    marginTop: '64px',
    width: '70%',
    '& > span': {
      display: 'inline-block',
      marginRight: '64px',
      fontSize: '1rem',
    },
    '& > div': {
      fontWeight: 'bold',
      textAlign: 'start',
      '& > span': {
        '& > a': {
          fontSize: '1.1rem',
          '&:visited': {
            color: theme.palette.primary.main,
          },
          '&:link': {
            color: '#000',
          },
        },
        '& > span': {
          top: -10,
          left: 16,
        },
      },
    },
    '@media screen and (max-width:500px)': {
      width: '90%',
      '& > span': {
        marginRight: '16px',
        fontSize: '0.9rem',
      },
    },
  },
  fadeArrow: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 0,
    '@media screen and (max-width:500px)': {
      width: '90%',
    },
  },
  badge: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    border: `solid 1px ${theme.palette.primary.main}`,
    marginBottom: '8px',
  },
  fadein: {
    width: '70%',
  },
  arrow: {
    width: '100%',
    marginTop: '32px',
    marginLeft: 0,
    marginRight: 'auto',
    paddingRight: '10%',
    '@media screen and (max-width:1000px)': {
      paddingRight: 0,
    },
  },
}));

const NewsOnTopPage = () => {
  const TIMEZONE = various.timezone;
  const [badge, setBadge] = useState([]);
  const [news, setNews] = useState();

  const classes = useStyles();
  const newsAreaTitle = {
    titleList: ['News'],
    sub: 'ニュース',
  };
  const fadeProps = {
    direction: '$up',
    duration: '1s',
    fillMode: 'forwards',
    timingFunction: 'ease-out',
  };

  useEffect(() => {
    let result = [];
    const NOW = Clock({ timezone: TIMEZONE }).time;
    if (news) {
      news.forEach((el) => {
        let dead = Clock({
          timezone: TIMEZONE,
          dead: null,
          string: el.date,
        }).time;
        dead.setDate(dead.getDate() + 7);
        if (dead < NOW) {
          result.push(false);
        } else {
          result.push(true);
        }
      });
    }
    setBadge(result);
  }, [news, TIMEZONE]);

  useEffect(() => {
    newsIndex(setNews);
  }, []);

  const newsMapping = () => {
    const result = [];
    if (news) {
      news.slice(0, 5).map((elm, index) => {
        return result.push(
          <div key={index} className={classes.contentWrapper}>
            <span>{elm.date}</span>
            <div>
              <Badge
                badgeContent={'New!'}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                classes={{ badge: classes.badge }}
                invisible={!badge[index]}
                overlap="rectangular"
              >
                <Link to={`${various.general.news}/${elm.id}`}>
                  <span>{elm.title}</span>
                </Link>
              </Badge>
            </div>
          </div>
        );
      });
    }
    return result;
  };

  return (
    <div>
      {/* <FadeIn fadeProps={fadeProps}> */}
      <ContentTitle title={newsAreaTitle} />
      {/* </FadeIn> */}
      <div className={classes.container}>{newsMapping()}</div>
      <div className={classes.fadeArrow}>
        <FadeIn fadeProps={fadeProps}>
          <div className={classes.arrow}>
            <Link to={various.general.news}>
              <ArrowButton color="#000" buttonName="VIEW ALL" />
            </Link>
          </div>
        </FadeIn>
      </div>
    </div>
  );
};
export default NewsOnTopPage;
