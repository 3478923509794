import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../auth';
import various from '../General/various';
import {
  cancelServicePlan,
  getStripeCustomerInfo,
  myPage,
  seminarCancelPatchUpdate,
} from '../Apis/apiOfManaged';
import OnOffByScroll from '../Animation/onOffByScroll';
import Footer from '../Footer/footer';
import { Spinner } from '../Spinner';
import BreadCrumbs from '../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  para: {
    width: '70%',
    margin: '32px auto',
    fontSize: '0.9rem',
    textAlign: 'start',
  },
  titles: {},
  inputs: {},
  helptext: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  container: {
    marginTop: '168px',
    width: '100%',
    '& > form': {
      '& > p': {
        fontSize: '0.7em',
        textAlign: 'center',
      },
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          '& > label': {
            width: '100%',
            textAlign: 'end',
            fontSize: '1rem',
            '@media screen and (max-width:750px)': {
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
          '& > span': {
            color: 'red',
          },
        },

        '& $inputs': {
          width: '70%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input, & > textarea, & > select': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
          '& > textarea': {
            height: '10em',
          },
          '& $helptext': {
            fontSize: '0.8rem',
            color: 'grey',
            '& > a': {
              textDecoration: 'underline',
              '&:link': {
                color: theme.palette.primary.main,
              },
            },
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    marginTop: '88px !important',
    justifyContent: 'end',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const MyPageSeminarCancelForm = () => {
  const { isAuth } = useContext(AuthContext);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {},
  });

  const [uuid, setUuid] = useState();
  const [user, setUser] = useState();
  const [isStripeCustomer, setIsStripeCustomer] = useState(false);
  const [done, setDone] = useState(false);
  const [refund, setRefund] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  // console.log('location', location);

  const CRUMBS = [
    { name: 'MyPage', path: '/mypage' },
    {
      name: 'ManageSeminar',
      path: '/mypage/seminar',
      data: { uuid: location.state.memberUuid },
    },
    { name: 'CancelForm', path: '/mypage/seminar_cancel' },
  ];

  // Init
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  useEffect(() => {
    myPage(setUser);
  }, []);

  const gotoBook = () => {
    navigate(various.general.constitution);
  };

  useEffect(() => {
    if (location.state.uuid) {
      setUuid(location.state.uuid);
    }
    if (location.state.title) {
      reset({ target: location.state.title });
    }
  }, [location, reset]);

  useEffect(() => {
    if (user) {
      getStripeCustomerInfo(setIsStripeCustomer, user.email);
    }
  }, [user]);

  useEffect(() => {
    if (refund) {
      alert('セミナーをキャンセルしました。');
      setLoading(false);
      navigate(location.state.previous, {
        state: { uuid: location.state.memberUuid },
      });
      console.log('update finish!');
    }
  }, [refund, location, navigate]);

  useEffect(() => {
    async function cancel(data, setRefund) {
      await cancelServicePlan(data, setRefund);
    }
    if (done) {
      if (isStripeCustomer) {
        const data = {
          seminar_uuid: location.state.seminar_uuid,
          member_uuid: location.state.memberUuid,
        };
        cancel(data, setRefund);
      } else {
        setRefund(true);
      }
    }
  }, [done, location, isStripeCustomer]);

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    data.uuid = uuid;
    await seminarCancelPatchUpdate(setDone, data);
  });

  const form = () => {
    if (uuid) {
      return (
        <div className={classes.container}>
          <form method="POST" encType="multipart/form-data" onSubmit={onSubmit}>
            <div>
              <div className={classes.titles}>
                <label htmlFor="対象セミナー">対象セミナー</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="target"
                  type="text"
                  disabled={true}
                  {...register('target', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                {errors.target?.message && (
                  <div className={classes.error}>{errors.target.message}</div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="キャンセル理由">{'キャンセル理由'}</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <textarea
                  id="cancel_reason"
                  type="textarea"
                  {...register('cancel_reason', {
                    required: { value: true, message: '入力必須の項目です' },
                    maxLength: {
                      value: 2500,
                      message: '長すぎます',
                    },
                  })}
                />
                <div className={classes.helptext}>
                  本記入欄は小用先生および講師の先生に対して送信されます。
                </div>
                {errors.cancel_reason?.message && (
                  <div className={classes.error}>
                    {errors.cancel_reason.message}
                  </div>
                )}
              </div>
            </div>
            <div className={classes.button}>
              <button type="submit">キャンセルする</button>
            </div>
          </form>
        </div>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {isAuth ? (
        <>
          <div className={classes.pageTitle}>
            セミナーキャンセルフォーム
            <BreadCrumbs crumbs={CRUMBS} />
          </div>
          <hr />
          <p className={classes.para}>
            キャンセル理由をご記入の上送信してください。
          </p>
          {various.runInProduction && various.runStripeAllMembers ? (
            <p style={{ fontSize: '0.8rem' }} className={classes.para}>
              なお、クレジットカード等で既に申込時にお支払いの方は、
              <span
                onClick={gotoBook}
                style={{
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                会則のキャンセルポリシー
              </span>
              に則り、カード会社を通じて返金します。{' '}
            </p>
          ) : (
            ''
          )}
          {loading ? <Spinner /> : form()}
        </>
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
      <Footer />
    </>
  );
};
export default MyPageSeminarCancelForm;
