import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';

import OnOffByScroll from '../../../Animation/onOffByScroll';
import { AuthContext } from '../../../auth';
import various from '../../../General/various';
import Footer from '../../../Footer/footer';
import { localStorageArrayHandler } from '../../../CommonFunction/managePermission';
import { seminarDetailPreview, seminarRemindMail } from '../../../Apis/apis';
import Clock from '../../../General/clock';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
  },
  titles: {},
  inputs: {},
  helptext: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  attention: {
    fontSize: '0.9rem',
    textAlign: 'start',
    '& > p': {
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
  },
  container: {
    marginTop: '80px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media screen and (max-width:800px)': {
      '& > div': {
        width: '100%',
      },
    },
    '& > form': {
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '& > img': {
            maxWidth: '300px',
            maxHeight: '300px',
            objectFit: 'contain',
          },
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > label': {
            width: '100%',
            textAlign: 'end',
            fontSize: '1rem',
            '@media screen and (max-width:750px)': {
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
          '& > span': {
            color: 'red',
          },
        },

        '& $inputs': {
          width: '70%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input, & > textarea, & > select': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
          '& > textarea': {
            minHeight: '160px',
          },
          '& > input[type="checkbox"]': {
            boxShadow: 'none',
          },
          '& $helptext': {
            fontSize: '0.8rem',
            color: 'grey',
            '& > a': {
              textDecoration: 'underline',
              '&:link': {
                color: theme.palette.primary.main,
              },
            },
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    marginTop: '88px !important',
    justifyContent: 'end',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const SeminarRemindMail = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const { isAuth } = useContext(AuthContext);
  const permissions = localStorageArrayHandler('parse', various.permissionKey);

  const [date, setDate] = useState();
  const [detail, setDetail] = useState();
  const [uuid, setUuid] = useState();

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const TIMEZONE = various.timezone;

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: 'セミナー一覧', path: '/seminar_index' },
    { name: 'セミナーリマインドメール', path: '/seminar_remind_mail' },
  ];

  // Init
  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  useEffect(() => {
    setUuid(location.state.uuid);
  }, [location]);

  useEffect(() => {
    if (uuid) {
      seminarDetailPreview(setDetail, uuid);
    }
  }, [uuid]);

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  // Submit
  const onSubmit = handleSubmit((data) => {
    const seminar = detail;
    data['uuid'] = seminar.uuid;
    data['title'] =
      seminar.title +
      (seminar.serial_number ? ' #' + seminar.serial_number : '');
    data['date'] =
      Clock({
        timezone: TIMEZONE,
        time: null,
        string: seminar.event_date_start_time,
      }).today +
      ' ' +
      Clock({
        timezone: TIMEZONE,
        time: null,
        string: seminar.event_date_start_time,
      }).hourMinutes +
      ' ~';
    data['place'] = seminar.place.facility_name + ' ' + seminar.hall.name;
    data['address'] = seminar.place.address;
    data['gmap'] = seminar.place.gmap;
    data['detaildataPath'] = `/seminar/${seminar.uuid}`;
    data['attention'] = seminar.notes;
    const protocol = window.location.protocol;
    const host = window.location.host;
    const domain = document.domain;
    const path = 'contact';
    const dataset = {
      email: data.email,
      protocol: protocol,
      host: host,
      path: path,
      domain: domain,
    };
    data['urlData'] = dataset;

    if (data.announce && data.announce.length <= 3) {
      data['announce'] = null;
    }
    async function remindMail(data) {
      await seminarRemindMail(data);
    }
    remindMail(data);
    console.log('send mail!');
    navigate(location.state.previous);
  });

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {!isAuth ? (
        <>
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleAuth}
            >
              ログイン
            </span>
            してください。
          </div>
          <Footer />
        </>
      ) : (permissions.includes('admin') ||
          permissions.includes('instructor') ||
          permissions.includes('organizer')) &&
        detail ? (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">
                【{detail.title}
                {detail.serial_number ? ` #${detail.serial_number}` : null}
                】参加者への一斉メール送信
              </span>
              <span>{date}</span>
            </div>
          </div>
          <BreadCrumbs crumbs={CRUMBS} />
          <hr />
          <div className={classes.attention}>
            <p>留意事項：</p>
            <ul>
              <li>
                本機能とは別に、参加者に対する一斉リマインドメールは
                <span
                  style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                >
                  セミナー開催日"前日"の20時頃
                </span>
                に自動送信されます。
              </li>
              <li>
                配布資料が本セミナーに登録されていれば、前日の一斉送信時にメール添付の形で送られます。
              </li>
              <li>
                配布資料が本セミナーに登録されているか否かの確認は、セミナー一覧ページから"詳細画面"または"更新画面"にてご確認ください。
              </li>
              <li>本機能は何度でも使用可能です。ご注意ください。</li>
              <li>
                一斉送信メールは、現時点で参加登録している方々全てに送信されます。個別でのメール送信はサポート範囲外です。
              </li>
            </ul>
            <p>本機能の使用を想定できるケース：</p>
            <ul>
              <li>前日の一斉送信後に資料の配布を決定された場合。</li>
              <li>指導者から何らかの案内を送信したい場合。</li>
              ※運営からのアナウンスが必要な場合に使用するケースがあります。
            </ul>
          </div>
          <hr />
          <div className={classes.container}>
            <form
              method="POST"
              encType="multipart/form-data"
              onSubmit={onSubmit}
            >
              <div>
                <div className={classes.titles}>
                  <label htmlFor="指導者からのアナウンス">
                    指導者からのアナウンス
                  </label>
                </div>
                <div className={classes.inputs}>
                  <textarea
                    id="announce"
                    type="textarea"
                    {...register('announce', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    定型文に付加するアナウンスがありましたらご入力ください。指導者からの案内という形で付記されます。
                  </div>
                  {errors.announce?.message && (
                    <div className={classes.error}>
                      {errors.announce.message}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.button}>
                <button type="submit">送信</button>
              </div>
            </form>
          </div>
          <Footer />
        </>
      ) : permissions === 'null' ? (
        setTimeout(() => {
          window.location.reload(true);
        }, 500)
      ) : (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">参加者への一斉メール送信</span>
              <span>{date}</span>
            </div>
          </div>
          <hr />
          <div className={classes.container}>権限がありません</div>
        </>
      )}
    </>
  );
};
export default SeminarRemindMail;
