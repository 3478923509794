import { Box, makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { localStorageArrayHandler } from '../../../CommonFunction/managePermission';
import various from '../../../General/various';
import { useLocation, useNavigate } from 'react-router-dom';
import { productMasterIndex } from '../../../Apis/apiOfManaged';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import { DataGrid, jaJP } from '@mui/x-data-grid';
import { AuthContext } from '../../../auth';
import { Spinner } from '../../../Spinner';
import Footer from '../../../Footer/footer';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
  },
  container: {
    marginTop: '64px',
  },
  dataGrid: {
    height: (props) =>
      props.heightSize ? props.heightSize : props.pageSize * props.rowHeight,
    width: '100%',
  },
}));

const ProductMasterIndex = () => {
  const { isAuth } = useContext(AuthContext);
  const permissions = localStorageArrayHandler('parse', various.permissionKey);

  const [date, setDate] = useState();
  const [products, setProducts] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [heightSize, setHeightSize] = useState();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const ROWHEIGHT = 80;
  const PAGESIZE = 30;
  const classes = useStyles({
    pageSize: PAGESIZE,
    rowHeight: ROWHEIGHT,
    heightSize: heightSize,
  });

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: 'サービスマスター一覧', path: '/manage/product_master_index' },
  ];

  //   Init
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  useEffect(() => {
    async function fetchData() {
      await productMasterIndex(setProducts);
    }
    fetchData();
  }, []);

  // Handler
  const handleClick = (flag, row) => {
    if (flag === 'update') {
      navigate(various.general.productMasterUpdate, {
        state: { previous: location.pathname, uuid: row.uuid },
      });
    } else if (flag === 'detail') {
      navigate(various.general.productMasterDetail, {
        state: { previous: location.pathname, uuid: row.uuid },
      });
    }
  };

  // set columns and rows
  useEffect(() => {
    if (products) {
      setColumns([
        {
          field: 'id',
          headerName: 'ID',
          width: 10,
        },
        {
          field: 'uuid',
        },
        {
          field: 'management_tags',
          headerName: '管理用タグ',
          cellClassName: 'management_tags',
          width: 200,
        },
        {
          field: 'name',
          headerName: '名称',
          cellClassName: 'name',
          width: 300,
        },

        {
          field: 'is_active',
          headerName: '提供区分',
          width: 100,
          cellClassName: (params) =>
            params.value === '提供中' ? 'active' : 'inactive',
        },
        {
          field: 'price',
          headerName: '単価',
          cellClassName: 'price',
          width: 100,
        },
        {
          field: 'currency',
          headerName: '通貨',
          cellClassName: 'currency',
          width: 100,
        },
        {
          field: 'product_type',
          headerName: '決済タイプ',
          width: 100,
          cellClassName: (params) =>
            params.value === '定期' ? 'cycle' : 'non-cycle',
        },
        {
          field: 'product_category',
          headerName: '区分',
          width: 100,
        },
        {
          field: 'detailBtn',
          headerName: '詳細',
          sortable: false,
          width: 100,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <div>
              <button
                onClick={() => handleClick('detail', params.row)}
                style={{ cursor: 'pointer' }}
              >
                詳細
              </button>
            </div>
          ),
        },
        {
          field: 'updateBtn',
          headerName: '更新',
          sortable: false,
          width: 100,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <div>
              <button
                onClick={() => handleClick('update', params.row)}
                style={{ cursor: 'pointer' }}
              >
                更新
              </button>
            </div>
          ),
        },
      ]);
      setRows(
        products.map((product, index) => {
          const row = {};
          row['id'] = index + 1;
          row['uuid'] = product.uuid;
          row['management_tags'] = product.management_tags;
          row['name'] = product.name;
          row['is_active'] = product.is_active ? '提供中' : '提供中止';
          row['price'] = product.price.toLocaleString();
          row['currency'] = product.currency;
          row['product_type'] =
            product.product_type === 'subscription' ? '定期' : '都度';
          row['product_category'] =
            product.product_category === 'basic'
              ? '基本サービス'
              : 'オプションサービス';
          return row;
        })
      );
      setHeightSize(products.length * ROWHEIGHT * 1.4 + 122);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [products]);

  return (
    <>
      <>
        <div style={{ margin: '80px' }}>
          <OnOffByScroll place="General" />
        </div>
        {!isAuth ? (
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleAuth}
            >
              ログイン
            </span>
            してください。
          </div>
        ) : permissions.includes('admin') ? (
          <>
            <div className={classes.pageTitle}>
              <div>
                <span id="title">サービスモデル一覧</span>
                <span>{date}</span>
              </div>
            </div>
            <hr />
            <BreadCrumbs crumbs={CRUMBS} />
            {loading ? (
              <Spinner />
            ) : (
              <div className={classes.container}>
                <Box
                  className={classes.dataGrid}
                  sx={{
                    '& .name': {
                      fontStyle: 'italic',
                      fontFamily:
                        '"Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif',
                      fontWeight: 'bold',
                    },
                    '& .active': {
                      color: 'green',
                    },
                    '& .inactive': {
                      color: 'red',
                    },
                    '& .cycle': {
                      color: 'green',
                    },
                    '& .non-cycle': {
                      color: 'blue',
                    },
                  }}
                >
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={PAGESIZE}
                    rowsPerPageOptions={[PAGESIZE]}
                    // checkboxSelection
                    density="comfortable"
                    columnVisibilityModel={{ uuid: false }}
                    pagination={true}
                    autoPageSize={true}
                    rowHeight={ROWHEIGHT}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    localeText={
                      jaJP.components.MuiDataGrid.defaultProps.localeText
                    }
                  />
                </Box>
              </div>
            )}
          </>
        ) : permissions === 'null' ? (
          setTimeout(() => {
            window.location.reload(true);
          }, 500)
        ) : (
          <>
            <div className={classes.pageTitle}>
              <div>
                <span id="title">サービスモデル一覧</span>
                <span>{date}</span>
              </div>
            </div>
            <hr />
            <div className={classes.container}>権限がありません</div>
          </>
        )}
        <Footer />
      </>
    </>
  );
};
export default ProductMasterIndex;
