import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import {
  deleteStripeCustomer,
  getServicePlanDetail,
} from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import various from '../../../General/various';
import Footer from '../../../Footer/footer';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  updateBtn: {},
  withHelp: {},
  helptext: {},
  withHeltInput: {},
  withHeltSpan: {},
  withHelpDiv: {},
  container: {
    width: '100%',
    marginTop: '64px',
    '& > div, & $withHelpDiv': {
      width: '80%',
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      marginBottom: '24px',
      '& > span, & $withHeltSpan': {
        width: '40%',
        fontSize: '0.9rem',
        fontWeight: 'bold',
        textAlign: 'end',
        lineHeight: '40px',
        marginRight: '16px',
      },
      '& > input, & > textarea, & $withHeltInput': {
        width: '60%',
        height: '40px',
        padding: '8px',
        fontFamily: theme.typography.fontFamily,
        border: '0.5px solid grey',
        borderRadius: '0.9em',
        boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
        '&:focus': {
          outline: `1.2px ${theme.palette.primary.main} outset`,
        },
      },
      '& > textarea': {
        minHeight: '160px',
      },
      '& > img': {
        width: '60%',
        objectFit: 'contain',
      },

      '@media screen and (max-width:500px)': {
        width: '100%',
        flexWrap: 'wrap',
        '& > span': {
          width: '100%',
          textAlign: 'start',
        },
        '& > input, & > textarea, & > img': {
          width: '100%',
        },
      },
    },
    '&  $withHelp': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& > $withHelpDiv': {
        width: '80%',
        margin: '0',
        '@media screen and (max-width:500px)': {
          width: '100%',
          flexWrap: 'wrap',
          '& > span': {
            width: '100%',
            textAlign: 'start',
          },
          '& > input, & > textarea, & > img': {
            width: '100%',
          },
        },
      },
      '& $helptext': {
        width: '80%',
        marginLeft: '30%',
        color: 'grey',
        '& > span': {
          display: 'inline-block',
          width: '100%',
          textAlign: 'start',
          fontSize: '0.8rem',
        },
        '& > a': {
          textDecoration: 'underline',
          '&:link': {
            color: theme.palette.primary.main,
          },
        },
        '@media screen and (max-width:500px)': {
          width: '100%',
          marginLeft: '0%',
        },
      },
    },
    '& $updateBtn': {
      width: '100%',
      marginTop: '80px',
      '& > button': {
        width: '100%',
        display: 'block',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        borderRadius: '0.5em',
        border: 'none',
        padding: '4px 8px',
        backgroundColor: theme.palette.primary.main,
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        color: '#fff',
        cursor: 'pointer',
      },
    },
  },
}));

const MembersPaymentDetail = () => {
  const { isAuth } = useContext(AuthContext);
  const [detailData, setDetailData] = useState();
  const [member, setMember] = useState();
  const [refund, setRefund] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [deducted, setDeducted] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '決済情報一覧', path: '/members_payment' },
    { name: '決済情報詳細', path: '/members_payment_detail' },
  ];

  // if (detailData) console.log(detailData);

  //   Init
  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  useEffect(() => {
    let uuid;
    if (query.get('id')) {
      uuid = query.get('id');
    } else if (query.get('uuid')) {
      uuid = query.get('uuid');
    } else {
      uuid = location.state.uuid;
    }
    if (uuid) {
      getServicePlanDetail(setDetailData, uuid);
    }
  }, [location, query]);

  useEffect(() => {
    if (detailData) {
      setMember(detailData.member[0]);
      if (detailData.refund_id) {
        setRefund(true);
      }
      if (detailData.subscription_change_requests.length > 0) {
        setCancel(true);
      }
      if (detailData.is_deducted) {
        setDeducted(true);
      }
    }
  }, [detailData]);

  //   handle
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleClick = (uuid) => {
    navigate(various.general.memberAllPayments, {
      state: { uuid: uuid, previous: location.pathname },
    });
  };

  const handleDelete = (data) => {
    const customer_id = data.stripe_customer.stripe_customer_id;
    deleteStripeCustomer({ customer_id: customer_id }, setIsDelete);
  };

  useEffect(() => {
    if (isDelete) {
      alert(
        'Stripeダッシュボードおよび関連するDBインスタンスを完全に削除しました。'
      );
      navigate(various.general.membersPayment, {
        state: { previous: location.pathname },
      });
    }
  }, [isDelete, location, navigate]);

  // util
  function calculateNextBillingDate(billingCycle, startDateString) {
    const startDate = new Date(startDateString);
    let nextBillingDate = new Date(startDateString);

    switch (billingCycle?.billing_cycle_type) {
      case 'daily':
        nextBillingDate.setDate(startDate.getDate() + 1);
        break;
      case 'weekly':
        nextBillingDate.setDate(startDate.getDate() + 7);
        break;
      case 'monthly':
        nextBillingDate.setMonth(startDate.getMonth() + 1);
        nextBillingDate.setDate(billingCycle.billing_cycle_start_day);
        break;
      case 'yearly':
        nextBillingDate.setFullYear(startDate.getFullYear() + 1);
        nextBillingDate.setMonth(startDate.getMonth());
        nextBillingDate.setDate(billingCycle.billing_cycle_start_day);
        break;
      default:
        return null;
    }

    // 返り値の秒数を1秒少なくする
    nextBillingDate.setSeconds(nextBillingDate.getSeconds() - 1);
    return nextBillingDate.toISOString();
  }

  function dateFormatter(dateString) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    return new Intl.DateTimeFormat('ja-JP', options).format(
      new Date(dateString)
    );
  }

  //   contents

  const contentTable = () => {
    if (detailData) {
      return (
        <>
          <div className={classes.container}>
            <div>
              <span>決済者</span>
              <input
                type="text"
                value={
                  detailData.member[0].last_name +
                  detailData.member[0].first_name
                }
                readOnly
              />
            </div>
            <div>
              <span>決済対象</span>
              <input
                type="text"
                value={
                  detailData.service_name === 'membership'
                    ? '会費'
                    : detailData.service_name
                }
                readOnly
              />
            </div>
            <div>
              <span>決済区分</span>
              <input
                type="text"
                value={
                  detailData.payment_id_type === 'subscription'
                    ? '定期決済'
                    : detailData.payment_id_type === 'payment'
                    ? '都度決済'
                    : 'その他'
                }
                readOnly
              />
            </div>
            <div className={classes.withHelp}>
              <div className={classes.withHelpDiv}>
                <span>決済金額</span>
                <input
                  type="text"
                  value={detailData.price.toLocaleString()}
                  readOnly
                />
              </div>
              {deducted ? (
                <div className={classes.helptext}>
                  <span>※決済金額は入会金控除後の金額です。</span>
                </div>
              ) : null}
            </div>
            <div>
              <span>決済手数料額</span>
              <input
                type="text"
                value={detailData.payment_fee_total.toLocaleString()}
                readOnly
              />
            </div>
            <div>
              <span>差し引き入金額</span>
              <input
                type="text"
                value={(
                  detailData.price - detailData.payment_fee_total
                ).toLocaleString()}
                readOnly
              />
            </div>
            <div className={classes.withHelp}>
              <div className={classes.withHelpDiv}>
                <span className={classes.withHeltSpan}>決済ステータス</span>
                <input
                  className={classes.withHeltInput}
                  type="text"
                  value={detailData.payment_completed ? '決済完了' : '未決済'}
                  readOnly
                />
              </div>
              <div className={classes.helptext}>
                <span>備考:{detailData.payment_info}</span>
              </div>
            </div>
            <div>
              <span>サービス提供日時</span>
              {detailData.payment_id_type === 'subscription' ? (
                <input
                  type="text"
                  value={`${dateFormatter(
                    detailData.service_date_time
                  )} ~ ${dateFormatter(
                    new Date(
                      calculateNextBillingDate(
                        {
                          billing_cycle_start_day:
                            detailData.product?.billing_cycle_start_day,
                          billing_cycle_type:
                            detailData.product?.billing_cycle_type,
                        },
                        detailData.service_date_time
                      ) || detailData.service_date_time
                    )
                  )}`}
                  readOnly
                />
              ) : (
                <input
                  type="datetime"
                  value={dateFormatter(detailData.service_date_time)}
                  readOnly
                />
              )}
            </div>

            <div>
              <span>決済日時</span>
              <input
                type="datetime"
                value={dateFormatter(detailData.created_at)}
                readOnly
              />
            </div>
            <div>
              <span>更新日</span>
              <input
                type="datetime"
                value={dateFormatter(detailData.updated_at)}
                readOnly
              />
            </div>
            <div>
              <span>Stripe_payment_ID</span>
              <input type="text" value={detailData.payment_id} readOnly />
            </div>
            <div>
              <span>Stripe_payment_intent_ID</span>
              <input
                type="text"
                value={detailData.payment_intent_id}
                readOnly
              />
            </div>
            <div>
              <span>Stripe_charge_ID</span>
              <input
                type="text"
                value={detailData.charge_id ? detailData.charge_id : '-'}
                readOnly
              />
            </div>
            <div>
              <span>Stripe_invoice_ID</span>
              <input
                type="text"
                value={detailData.invoice ? detailData.invoice.invoice_id : '-'}
                readOnly
              />
            </div>
            <div>
              <span>サービスUUID</span>
              <input type="text" value={detailData.uuid} readOnly />
            </div>
            <hr
              style={{
                border: 0,
                borderTop: '1px dotted #000',
                width: '70%',
                margin: '56px auto',
              }}
            />
            <div>
              <span>返金</span>
              <input
                type="text"
                value={
                  !detailData.refund_id ? '-' : '申込キャンセルの上返金対応'
                }
                readOnly
              />
            </div>
            {refund ? (
              <>
                <div>
                  <span>返金額</span>
                  <input
                    type="text"
                    value={detailData.amount_refunded.toLocaleString()}
                    readOnly
                  />
                </div>
                <div>
                  <span>返金手数料額</span>
                  <input
                    type="text"
                    value={detailData.refund_fee_total.toLocaleString()}
                    readOnly
                  />
                </div>
                <div>
                  <span>合計出金額</span>
                  <input
                    type="text"
                    value={(
                      detailData.amount_refunded + detailData.refund_fee_total
                    ).toLocaleString()}
                    readOnly
                  />
                </div>
                <div>
                  <span>返金状況</span>
                  <input
                    type="text"
                    value={
                      detailData.refund_status === 'succeeded'
                        ? '返金完了'
                        : !detailData.refund_status
                        ? '-'
                        : '返金失敗'
                    }
                    readOnly
                  />
                </div>
                <div>
                  <span>返金処理日時</span>
                  <input
                    type="datetime"
                    value={dateFormatter(detailData.refund_created_at)}
                    readOnly
                  />
                </div>
                <div>
                  <span>Stripe返金ID</span>
                  <input type="text" value={detailData.refund_id} readOnly />
                </div>
              </>
            ) : null}
            {cancel ? (
              <>
                <hr
                  style={{
                    border: 0,
                    borderTop: '1px dotted #000',
                    width: '70%',
                    margin: '56px auto',
                  }}
                />
                <div>
                  <span>解約状況</span>
                  <input
                    type="text"
                    value={
                      detailData.subscription_change_requests[0].status ===
                      'completed'
                        ? '解約処理完了'
                        : detailData.subscription_change_requests[0].status ===
                          'pending'
                        ? '解約処理日未到来'
                        : '-'
                    }
                    readOnly
                  />
                </div>
                <div>
                  <span>キャンセル処理日時</span>
                  <input
                    type="datetime"
                    value={dateFormatter(
                      detailData.subscription_change_requests[0].execution_date
                    )}
                    readOnly
                  />
                </div>
                <div>
                  <span>備考</span>
                  <textarea
                    type="textarea"
                    value={detailData.subscription_change_requests[0].notes}
                    readOnly
                  />
                </div>
              </>
            ) : null}
            {deducted ? (
              <>
                <hr
                  style={{
                    border: 0,
                    borderTop: '1px dotted #000',
                    width: '70%',
                    margin: '56px auto',
                  }}
                />
                <div>
                  <span>入会金控除額</span>
                  <input
                    type="text"
                    value={detailData.deducted_amount.toLocaleString()}
                    readOnly
                  />
                </div>
              </>
            ) : null}
            <div className={classes.updateBtn}>
              <button onClick={() => handleClick(detailData.uuid)}>
                支払履歴
              </button>
            </div>
          </div>
        </>
      );
    }
  };

  //   Stripe
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        setShowDelete(false);
      } else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
        setShowDelete(true);
      }
    } else if (process.env.NODE_ENV === 'development') {
      setShowDelete(true);
    }
  }, []);

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>
        {member ? member.last_name + member.first_name : null}
        さんの決済情報詳細
      </div>
      <hr />
      <BreadCrumbs crumbs={CRUMBS} />
      {isAuth ? (
        <>
          {contentTable()}
          {showDelete ? (
            <div style={{ textAlign: 'start', marginTop: '64px' }}>
              <hr />
              <button
                type="button"
                style={{ cursor: 'pointer' }}
                onClick={() => handleDelete(detailData)}
              >
                削除
              </button>
              <p style={{ fontSize: '0.8rem', color: 'grey' }}>
                ※「削除ボタン」を押下するとこのデータのみならず、
                {member ? member.last_name : null}
                さんの支払い関連データが完全削除されます。この操作は取り消せません。
              </p>
            </div>
          ) : null}
          <div
            onClick={() =>
              navigate(various.general.membersPayment, { state: {} })
            }
            style={{ textAlign: 'start' }}
          >
            <span
              style={{
                color: 'grey',
                textDecoration: 'underline',
                fontSize: '0.9rem',
                cursor: 'pointer',
              }}
            >
              一覧に戻る
            </span>
          </div>
          <Footer />
        </>
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default MembersPaymentDetail;
