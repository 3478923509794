import { makeStyles, Paper } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
// import FadeIn from '../Animation/fadeIn';
import ContentTitle from '../General/contentTitle';
// import imageIndex from '../img/imageIndex';
// import JoinUsOnTopPage from './joinUsOnTopPage';

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'center',
    margin: '0px 32px 0px 32px',
    padding: '48px 16px 32px 16px ',
    backgroundImage: `linear-gradient(-135deg, ${theme.palette.primary.main} 32px, transparent 0),linear-gradient(45deg, ${theme.palette.primary.main} 32px, transparent 0)`,
    filter: 'drop-shadow(2px 2px 2px black)',
    '@media screen and (max-width:1000px)': {
      padding: '48px 16px 32px 16px ',
      margin: 0,
    },
    '@media screen and (max-width:450px)': {
      padding: '48px 8px 32px 8px ',
    },
  },
  paperTitle: {
    fontSize: '3.5rem',
    fontWeight: 'bold',
    marginBottom: '48px',
    letterSpacing: '8px',
  },
  name: {},
  descs: {},
  paperContents: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 32px',
    fontSize: '1.5rem',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: '64px',
      '& $name': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'nowrap',
        '& > div': {
          height: '40px',
          position: 'relative',
          display: 'inline-block',
          marginBottom: '40px',
          // padding: '0px 80px', 横線装飾
          padding: '0px 0px', // 下線装飾
          fontSize: '2rem',
          fontWeight: 900,
          lineHeight: '40px',

          // 両端横線装飾
          // '&::after, &::before': {
          //   content: '""',
          //   position: 'absolute',
          //   display: 'inline-block',
          //   top: '50%',
          //   width: '50px',
          //   height: '2px',
          //   backgroundColor: theme.palette.primary.main,
          // },
          // '&::before': {
          //   left: 0,
          //   background: `linear-gradient(-90deg, ${theme.palette.primary.main}, transparent)`,
          // },
          // '&::after': {
          //   right: 0,
          //   background: `linear-gradient(90deg, ${theme.palette.primary.main}, transparent)`,
          // },

          // 下線装飾
          '&::after': {
            content: '""',
            display: 'block',
            marginTop: '8px',
            height: '4px',
            background: `-webkit-linear-gradient(to right, ${theme.palette.primary.main}, transparent)`,
            // eslint-disable-next-line
            background: `linear-gradient(to right, ${theme.palette.primary.main}, transparent)`,
          },
        },
      },
      '& $descs': {
        display: 'flex',
        justifyContent: 'center',
        lineHeight: '24px',
        textAlign: 'justify',
        '& > span': {
          display: 'block',
          margin: 'auto 0',
          fontSize: '1.1rem',
        },
        '@media screen and (max-width:500px)': {
          marginTop: '40px',
        },
      },
    },
    '@media screen and (max-width:1000px)': {
      flexWrap: 'wrap',
      justifyContent: 'start',
    },
  },
  // joinUsBox: {
  //   margin: '84px 32px 32px 32px',
  //   '@media screen and (max-width:1000px)': {
  //     margin: '84px 8px 32px 8px',
  //   },
  // },
}));

const ClassOnTopPage = () => {
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState();
  const [contents, setContents] = useState();

  const classes = useStyles();
  const classAreaTitle = {
    titleList: ['Class'],
    sub: '稽古',
  };
  // const fadeProps = {
  //   direction: '$up',
  //   duration: '1s',
  //   fillMode: 'forwards',
  //   timingFunction: 'ease-out',
  // };

  const classType = [
    {
      name: '一般開放稽古',
      descs: '一般参加可能なクラスです。まずは体験稽古にお越しください。',
    },
    { name: '通常稽古', descs: '会員の会員による会員のための稽古です。' },
    { name: '舞扇道', descs: '女性専用かつ舞扇を通じて身体を養う御稽古です。' },
    // {
    //   name: '地に足をつけ直すクラス',
    //   descs: '刀禅の基本に則り「地に足をつけ直す」をテーマにした稽古です。',
    // },
    { name: '同好会での稽古', descs: '日本各地にある同好会での稽古です。' },
    {
      name: '指導者によるセミナー',
      descs: '刀禅古参会員、外部講師によるセミナーです。',
    },
    {
      name: '老師によるセミナー',
      descs: '小用茂夫によるセミナーです。',
    },
  ];

  const callback = useCallback(() => {
    return (
      <div className={classes.box}>
        {/* <FadeIn fadeProps={fadeProps}> */}
        <ContentTitle title={classAreaTitle} />
        {/* </FadeIn> */}
        <Paper className={classes.paper} elevation={3}>
          <div className={classes.paperTitle}>稽古</div>
          <div className={classes.paperContents}>
            {classType.map((cls, i) => {
              return (
                <div key={i}>
                  <div className={classes.name}>
                    <div>{cls.name}</div>
                  </div>
                  <div className={classes.descs}>
                    <span>{cls.descs}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </Paper>
        {/* <div className={classes.joinUsBox}>
          <JoinUsOnTopPage />
        </div> */}
      </div>
    );
    // eslint-disable-next-line
  }, [classes]);

  useEffect(() => {
    setView(true);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (view) {
      setContents(callback());
    }
  }, [view, callback]);

  return <>{loading ? null : view ? contents : null}</>;
};
export default ClassOnTopPage;
