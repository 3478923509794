import { fileNameChange } from '../CommonFunction/imageManagement';
import {
  authTestCreate,
  authTestFormDataUpdate,
  authTestPatchUpdate,
  imageFormDataUpdate,
  memberCreate,
  memberPatchUpdate,
  memberCreateSendForm,
  newsPatchUpdate,
  newsCreate,
  seminarPatchUpdate,
  seminarCreate,
  imagePatchUpdate,
  imageCreate,
  documentCreate,
  documentPatchUpdate,
  documentFormDataUpdate,
  instructorPatchUpdate,
  instructorFormDataUpdate,
  instructorCreate,
  branchPatchUpdate,
  branchFormDataUpdate,
  branchCreate,
  productMasterCreate,
  productMasterFormDataCreate,
  productMasterUpdate,
  productMasterFormDataUpdate,
} from './apiOfManaged';

export const createInstance = async (modelName, data, imageFileKey) => {
  const noImageData = { formData: false };
  Object.keys(data).forEach((k) => {
    if (k !== imageFileKey) {
      noImageData[k] = data[k];
    }
  });

  try {
    if (typeof data[imageFileKey] === 'string') {
      // console.log('no image data');
      switch (modelName) {
        case 'Member':
          await memberCreate(noImageData);
          break;
        case 'MemberCreateSendForm':
          await memberCreateSendForm(noImageData);
          break;
        case 'News':
          await newsCreate(noImageData);
          break;
        case 'Seminar':
          await seminarCreate(noImageData);
          break;
        case 'ProductMaster':
          await productMasterCreate(noImageData);
          break;
        default:
      }
    } else {
      const renamedFile = fileNameChange(data, imageFileKey);
      let formData = new FormData();
      formData.append(imageFileKey, renamedFile);
      let jsonData = JSON.stringify(data);
      formData.append('json', jsonData);

      // console.log('image data');
      switch (modelName) {
        case 'AuthTest':
          await authTestCreate(formData);
          break;
        case 'Images':
          await imageCreate(formData);
          break;
        case 'Documents':
          await documentCreate(formData);
          break;
        case 'Instructor':
          await instructorCreate(formData);
          break;
        case 'Branch':
          await branchCreate(formData);
          break;
        case 'ProductMaster':
          await productMasterFormDataCreate(formData);
          break;
        default:
      }
    }
  } catch (error) {
    console.error('Error in createInstance:', error);
  }
};

export const updateInstance = async (modelName, data, imageFileKey) => {
  const noImageData = { formData: false };
  Object.keys(data).forEach((k) => {
    if (k !== imageFileKey) {
      noImageData[k] = data[k];
    }
  });

  try {
    if (typeof data[imageFileKey] === 'string') {
      switch (modelName) {
        case 'AuthTest':
          await authTestPatchUpdate(noImageData);
          break;
        case 'MyPage':
          await memberPatchUpdate(noImageData);
          break;
        case 'Member':
          await memberPatchUpdate(noImageData);
          break;
        case 'News':
          await newsPatchUpdate(noImageData);
          break;
        case 'Seminar':
          await seminarPatchUpdate(noImageData);
          break;
        default:
      }
    } else {
      if (data[imageFileKey].length > 0) {
        const renamedFile = fileNameChange(data, imageFileKey);
        let formData = new FormData();
        formData.append(imageFileKey, renamedFile);
        switch (modelName) {
          case 'AuthTest':
            await authTestFormDataUpdate(formData, data.uuid);
            break;
          case 'Images':
            await imageFormDataUpdate(formData, data.uuid);
            break;
          case 'Instructor':
            await instructorFormDataUpdate(formData, data.uuid);
            break;
          case 'Branch':
            await branchFormDataUpdate(formData, data.uuid);
            break;
          case 'Documents':
            await documentFormDataUpdate(formData, data.uuid);
            break;
          case 'ProductMaster':
            await productMasterFormDataUpdate(formData, data.uuid);
            break;
          default:
        }
      } else {
        switch (modelName) {
          case 'AuthTest':
            await authTestPatchUpdate(noImageData);
            break;
          case 'Images':
            await imagePatchUpdate(noImageData);
            break;
          case 'Instructor':
            await instructorPatchUpdate(noImageData);
            break;
          case 'Branch':
            await branchPatchUpdate(noImageData);
            break;
          case 'Documents':
            await documentPatchUpdate(noImageData);
            break;
          case 'ProductMaster':
            await productMasterUpdate(noImageData);
            break;
          default:
        }
      }
    }
  } catch (error) {
    console.error('Error in updateInstance:', error);
  }
};
