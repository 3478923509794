import various from './General/various';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SitemapGenerator = (props) => {
  const [seminars, setSeminars] = useState();
  const [news, setNews] = useState();
  const [sitemapUrls, setSitemapUrls] = useState([]);
  const [sitemap, setSitemap] = useState();
  const [push, setPush] = useState(false);

  // オリジンをハードコーディングしているが、今後「https://touzen.jp」に一本化していく際に、サブドメインを除去することを忘れずに。
  const PRODUCTION_ENDPOINT = process.env.REACT_APP_PROD_API_URL;
  const PRODUCTION_ORIGIN = 'https://www.touzen.jp';

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${PRODUCTION_ENDPOINT}api/seminar_index`)
      .then((res) => {
        setSeminars(
          res.data.data.map((obj) => {
            return {
              id: obj.uuid,
            };
          })
        );
      })
      .catch((err) => {
        console.log(
          'Sitemap generateはテスト環境では機能させておりません(for SeminarContents)。'
        );
        if (props.navigate) {
          navigate(props.navigate.previous, {
            state: { uuid: props.navigate.state.uuid },
          });
        }
      });
    axios
      .get(`${PRODUCTION_ENDPOINT}api/news_index/`)
      .then((res) => {
        setNews(
          res.data.data.map((obj) => {
            return {
              id: obj.uuid,
            };
          })
        );
      })
      .catch((err) => {
        console.log(
          'Sitemap generateはテスト環境では機能させておりません(for NewsContents)。'
        );
        if (props.navigate) {
          navigate(props.navigate.previous, {
            state: { uuid: props.navigate.state.uuid },
          });
        }
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (seminars && news) {
      const targetSeminars = seminars.slice(0, 8);
      const targetNews = news.slice(0, 8);
      const urls = various.routingUrls;
      const rejectList = [
        '/seminar_preview',
        '/forgot_password',
        '/password_setting',
        '/forgot_password/confirm',
        '/email_change_confirm',
        '/unsubscribe',
        '/unsubscribe_confirm',
        '*',
      ];

      const result = [];
      urls.forEach((url) => {
        const page = {
          url: '',
          priority: 0.5,
        };
        if (!rejectList.includes(url.path) && !url.auth) {
          if (url.path === '/') {
            page.url = `${PRODUCTION_ORIGIN}${url.path}`;
          } else {
            page.url = `${PRODUCTION_ORIGIN}${url.path}/`;
          }
          if (url.path === '/' || url.path === '/about') {
            page.priority = 1.0;
          }
          result.push(page);
          if (url.children) {
            if (url.path === various.general.seminar) {
              targetSeminars.forEach((seminar) => {
                if (seminar.release_is) {
                  console.log(seminar.release_is);
                  const detail = {
                    url: `${PRODUCTION_ORIGIN}${url.path}/${seminar.id}/`,
                    priority: 1.0,
                  };
                  result.push(detail);
                }
                const preview = {
                  url: `${PRODUCTION_ORIGIN}${url.path}/preview/${seminar.id}/`,
                  priority: 1.0,
                };
                result.push(preview);
              });
            } else if (url.path === various.general.news) {
              targetNews.forEach((n) => {
                if (n.delivered) {
                  const detail = {
                    url: `${PRODUCTION_ORIGIN}${url.path}/${n.id}/`,
                    priority: 1.0,
                  };
                  result.push(detail);
                }
              });
            }
          }
        }
      });
      setSitemapUrls(result);
    }
  }, [seminars, news]);

  useEffect(() => {
    if (sitemapUrls.length > 0) {
      const result =
        '<?xml version="1.0" encoding="UTF-8"?>\n' +
        '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n' +
        sitemapUrls
          .map(
            (page) =>
              `<url>\n` +
              `<loc>${page.url}</loc>\n` +
              `<lastmod>${new Date().toISOString()}</lastmod>\n` +
              // `<changefreq>${page.changefreq}</changefreq>\n` +
              `<priority>${page.priority}</priority>\n` +
              `</url>\n`
          )
          .join('') +
        '</urlset>';
      setSitemap(result);
    }
  }, [sitemapUrls]);

  useEffect(() => {
    if (sitemap && push) {
      setPush(false);
      const endpoint = `http://localhost:3001/sitemap-generator`;
      const formInfo = {
        sitemapData: sitemap,
      };
      console.log('Sitemap generating...');
      axios({
        method: 'post',
        url: endpoint,
        data: formInfo,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (props.navigate) {
            navigate(props.navigate.previous, {
              state: { uuid: props.navigate.state.uuid },
            });
          }
          // console.log('Success:', res.data);
          // alert('サイトマップの生成が完了しました。');
        })
        .catch((error) => {
          console.log('Error in Sitemap generating:');
          // alert('何らかの問題が発生し、サイトマップの生成に失敗しました。');
          navigate(props.navigate.previous, {
            state: { uuid: props.navigate.state.uuid },
          });
        });
    }
  }, [sitemap, push, props, navigate]);

  useEffect(() => {
    if (props.push) {
      setPush(true);
    }
  }, [props]);

  const handlePush = () => {
    setPush(true);
  };

  if (props.push) {
    return null;
  } else {
    return <button onClick={handlePush}>Generate</button>;
  }
};

export default SitemapGenerator;
