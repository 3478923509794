import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import FadeIn from '../Animation/fadeIn';
import ArrowButton from '../General/arrowButton';
import ContentTitle from '../General/contentTitle';
import various from '../General/various';

const useStyles = makeStyles((them) => ({
  selectWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    flexWrap: 'wrap',
    width: '100%',
  },
  select: {
    width: '70%',
    marginLeft: '32px',
    marginBottom: '16px',
    '& > div': {
      width: '100%',
      fontSize: '0.9rem',
      '& > div': {
        opacity: '1',
        backgroundColor: 'rgba(0,0,0,0)',
        filter: 'drop-shadow(0px 0px 1px black)',
        '& > div': {
          fontSize: '1rem',
          textAlign: 'start',
          padding: '32px 32px 0px',
        },
      },
    },
    '@media screen and (max-width:500px)': {
      width: '100%',
      marginLeft: 0,
    },
  },
  menuItem: {
    fontSize: '1rem',
  },
  arrow: {
    marginRight: 0,
    marginLeft: 'auto',
  },
}));

const ContactOnTopPage = () => {
  const classes = useStyles();
  const [about, setAbout] = useState('');
  const [path, setPath] = useState('');

  const contactAreaTitle = {
    titleList: ['Contact'],
    sub: '問い合わせ',
  };
  // const fadeProps = {
  //   direction: '$up',
  //   duration: '1s',
  //   fillMode: 'forwards',
  //   timingFunction: 'ease-out',
  // };

  const aboutWhat = () => {
    return (
      <>
        <div className={classes.select}>
          <FormControl variant="filled">
            <InputLabel id="demo-simple-select-filled-label">for</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={about}
              onChange={handleChange}
            >
              <MenuItem
                classes={{ root: classes.menuItem }}
                value="contactForTraining"
              >
                入会・体験稽古について
              </MenuItem>
              <MenuItem
                classes={{ root: classes.menuItem }}
                value="contactForClub"
              >
                同好会・稽古会に対して
              </MenuItem>
              <MenuItem classes={{ root: classes.menuItem }} value="contact">
                その他問い合わせ
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.arrow}>
          <Link to={path}>
            <ArrowButton buttonName="CONTACT" color="#000" />
          </Link>
        </div>
      </>
    );
  };

  const handleChange = (event) => {
    setAbout(event.target.value);
  };
  useEffect(() => {
    if (about) {
      switch (about) {
        case 'contact':
          return setPath(various.general.contact);
        case 'contactForTraining':
          return setPath(various.general.contactForTraining);
        case 'contactForClub':
          return setPath(various.general.contactForClub);
        default:
      }
    } else {
      setPath(various.general.contact);
    }
  }, [about]);

  return (
    <div className={classes.box}>
      {/* <FadeIn fadeProps={fadeProps}> */}
      <ContentTitle title={contactAreaTitle} />
      {/* </FadeIn> */}
      <div className={classes.selectWrapper}>{aboutWhat()}</div>
    </div>
  );
};
export default ContactOnTopPage;
