import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import { newsDetailForAdmin } from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import Footer from '../../../Footer/footer';
import various from '../../../General/various';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  row: {},
  data: {},
  container: {
    width: '100%',
    '& > table': {
      width: '90%',
      margin: '0 auto',
      borderCollapse: 'collapse',
      fontSize: '0.9em',
      '& > tbody': {
        width: '100%',
        '& > tr': {
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          wordBreak: 'break-all',
          wordWrap: 'breal-all',
          lineHeight: '64px',
          borderBottom: '1px grey solid',
          '& $row': {
            width: '30%',
            margin: 'auto 0',
            lineHeight: '32px',
            textAlign: 'end',
            paddingRight: '32px',
          },
          '& $data': {
            width: '70%',
            lineHeight: '32px',
            textAlign: 'start',
            padding: '16px 8px 16px 32px',
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
          },
          '& #image': {
            padding: '16px 8px 16px 32px',
            textAlign: 'start',
            '& > img': {
              maxWidth: '250px',
              maxHeight: '250px',
              objectFit: 'contain',
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(220,220,220,0.3)',
          },
        },
      },
    },
  },
  updateBtn: {
    marginTop: '80px',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const NewsDetailForAdmin = () => {
  const { isAuth } = useContext(AuthContext);
  const [detailData, setDetailData] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: 'ニュース一覧', path: '/news_index' },
    { name: 'ニュース詳細', path: '/news_detail_for_admin' },
  ];

  // Init
  useEffect(() => {
    if (location.state.uuid) {
      newsDetailForAdmin(setDetailData, location.state.uuid);
    }
  }, [location]);

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleClick = (uuid) => {
    navigate(various.general.newsUpdateForAdmin, {
      state: { uuid: uuid, previous: location.pathname },
    });
  };

  // Contents
  const contentTable = () => {
    const rows = various.newsHeaders;
    if (detailData) {
      const date = new Date(detailData.delivery_date);
      const today = new Date();
      return (
        <>
          <div className={classes.container}>
            <table>
              <tbody>
                <tr>
                  <td className={classes.row}>{rows['title']}</td>
                  <td className={classes.data}>{detailData.title}</td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['description']}</td>
                  <td className={classes.data}>{detailData.description}</td>
                </tr>

                <tr>
                  <td className={classes.row}>{rows['is_topics']}</td>
                  <td className={classes.data}>
                    {detailData.is_topics ? 'Topics' : '-'}
                  </td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['is_members_only']}</td>
                  <td className={classes.data}>
                    {detailData.is_members_only ? '会員のみ' : '全世界'}
                  </td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['delivery_date']}</td>
                  <td className={classes.data}>{detailData.delivery_date}</td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['topics_end_date']}</td>
                  <td className={classes.data}>
                    {detailData.topics_end_date
                      ? detailData.topics_end_date
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['delivered']}</td>
                  <td className={classes.data}>
                    {detailData.delivered
                      ? date < today
                        ? '公開済み'
                        : '公開予定'
                      : '公開未定'}
                  </td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['distribution_is']}</td>
                  <td className={classes.data}>
                    {detailData.distribution_is ? '有り' : '無し'}
                  </td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['tag']}</td>
                  <td className={classes.data}>{detailData.tag}</td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['news_image']}</td>
                  <td className={classes.data} id="image">
                    <img src={detailData.news_image} alt="news_image" />
                  </td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['content']}</td>
                  <td className={classes.data}>{detailData.content}</td>
                </tr>
              </tbody>
            </table>
            <div className={classes.updateBtn}>
              <button onClick={() => handleClick(detailData.uuid)}>更新</button>
            </div>
          </div>
          <Footer />
        </>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>ニュース情報詳細</div>
      <BreadCrumbs crumbs={CRUMBS} />
      <hr />
      {isAuth ? (
        contentTable()
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default NewsDetailForAdmin;
