import React, { useCallback, useEffect, useState } from 'react';

import various from '../General/various';
import ContentTitle from '../General/contentTitle';
import ArrowButton from '../General/arrowButton';
import FadeIn from '../Animation/fadeIn';
import { images as imageApi } from '../Apis/apis';

import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  contentBox: {},
  content: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    width: '100%',
    '& #image': {
      width: 'calc(70vw * 0.5)',
      marginRight: '32px',
      minHeight: '490px',
      '& > a': {
        '& > img': {
          width: 'calc(70vw * 0.5)',
          height: 'calc(70vw * 0.5)',
          minHeight: '490px',
          objectFit: 'cover',
          filter:
            'contrast(100%) grayscale(100%) saturate(0%) drop-shadow(2px 2px 2px black)',
        },
      },
    },
    '@media screen and (max-width:1000px)': {
      flexWrap: 'wrap',
      '& #image': {
        width: 'calc(92vw * 1)',
        '& > a': {
          '& > img': {
            width: 'calc(92vw * 1) !important',
            height: 'calc(92vw * 0.5) !important',
            objectFit: 'cover',
            // filter: 'drop-shadow(2mm -10mm 10mm rgb(190 190 190))',
            // eslint-disable-next-line
          },
        },
      },
    },
  },
  textBox: {
    textAlign: 'start',
    width: '100%',
    // '@media screen and (max-width:1000px)': {
    //   margin: '0 0 0 0',
    //   width: '100%',
    // },
  },
  contentTitleSmallWidth: {
    display: 'block',
    '@media screen and (min-width:1000px)': {
      display: 'none',
    },
  },
  contentTitle: {
    display: 'block',
    '@media screen and (max-width:1000px)': {
      display: 'none',
    },
  },
  text: {
    textIndent: '1rem',
    textAlign: 'justify',
  },
  arrow: {
    float: 'right',
  },
}));

const AboutOnTopPage = () => {
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState();
  const [contents, setContents] = useState();

  const classes = useStyles();
  const aboutAreaTitle = {
    titleList: ['About'],
    sub: '刀禅とは何か',
  };
  const fadeProps = {
    direction: '$up',
    duration: '1s',
    fillMode: 'forwards',
    timingFunction: 'ease-out',
  };

  const callback = useCallback(() => {
    if (image) {
      return (
        <div className={classes.contentBox}>
          {/* <FadeIn fadeProps={fadeProps}> */}
          <div className={classes.contentTitleSmallWidth}>
            <ContentTitle title={aboutAreaTitle} />
          </div>
          {/* </FadeIn> */}
          <div className={classes.content}>
            <div id="image">
              <Link className={classes.imgWrap} to={various.general.about}>
                <img
                  src={image.OfficialImage_aboutImage.url}
                  alt="aboutImage"
                />
              </Link>
            </div>
            <div className={classes.textBox}>
              {/* <FadeIn fadeProps={fadeProps}> */}
              <div className={classes.contentTitle}>
                <ContentTitle title={aboutAreaTitle} />
              </div>
              {/* </FadeIn> */}
              <div className={classes.text}>
                刀禅は創始者・小用茂夫が、多年にわたって学んだ武道・武術から、最も重要なエッセンスを
                「身体の基準性」として抽出し、合理的な稽古体系にまとめた日本発のボディワークです。
                その特徴は個人の才能や環境に関係なく、シンプルな稽古を繰り返すことで、誰もが持続的に
                成長できる稽古システムにあります。
              </div>
            </div>
          </div>
          <FadeIn fadeProps={fadeProps}>
            <div className={classes.arrow}>
              <Link to={various.general.about}>
                <ArrowButton buttonName="VIEW ALL" color="#000" />
              </Link>
            </div>
          </FadeIn>
        </div>
      );
    }
    // eslint-disable-next-line
  }, [image, classes]);

  useEffect(() => {
    imageApi(setImage);
  }, []);

  useEffect(() => {
    if (image) {
      setView(true);
      setLoading(false);
    }
  }, [image]);

  useEffect(() => {
    if (view) {
      setContents(callback());
    }
  }, [view, callback]);

  // const contents = () => {
  //   if (image) {
  //     return (
  //       <div className={classes.contentBox}>
  //         {/* <FadeIn fadeProps={fadeProps}> */}
  //         <div className={classes.contentTitleSmallWidth}>
  //           <ContentTitle title={aboutAreaTitle} />
  //         </div>
  //         {/* </FadeIn> */}
  //         <div className={classes.content}>
  //           <Link className={classes.imgWrap} to={various.general.about}>
  //             <img
  //               className={classes.image}
  //               src={image.OfficialImage_aboutImage.url}
  //               alt="aboutImage"
  //             />
  //           </Link>
  //           <div className={classes.textBox}>
  //             {/* <FadeIn fadeProps={fadeProps}> */}
  //             <div className={classes.contentTitle}>
  //               <ContentTitle title={aboutAreaTitle} />
  //             </div>
  //             {/* </FadeIn> */}
  //             <div className={classes.text}>
  //               刀禅は創始者・小用茂夫が、多年にわたって学んだ武道・武術から、最も重要なエッセンスを
  //               「身体の基準性」として抽出し、合理的な稽古体系にまとめた日本発のボディワークです。
  //               その特徴は個人の才能や環境に関係なく、シンプルな稽古を繰り返すことで、誰もが持続的に
  //               成長できる稽古システムにあります。
  //             </div>
  //           </div>
  //         </div>
  //         {/* <FadeIn fadeProps={fadeProps}> */}
  //         <div className={classes.arrow}>
  //           <ArrowButton
  //             path={various.general.about}
  //             buttonName="VIEW ALL"
  //             color="#000"
  //           />
  //         </div>
  //         {/* </FadeIn> */}
  //       </div>
  //     );
  //   }
  // };

  return <>{loading ? null : view ? contents : null}</>;
};
export default AboutOnTopPage;
