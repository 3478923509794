import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import { getReceivedAmountDetail } from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import various from '../../../General/various';
import Footer from '../../../Footer/footer';
import { Spinner } from '../../../Spinner';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 40px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
    '& > p': {
      fontSize: '0.8rem',
      textAlign: 'start',
      color: 'grey',
    },
  },
  viewDataBtnContainer: {
    width: '100%',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',
    '@media screen and (max-width:600px)': {
      flexWrap: 'wrap',
      gap: '0px 16px',
    },
    '& > div': {
      margin: 'auto 0',
      '& > span': {
        display: 'inline-block',
      },
    },
    '& #viewBtn1, & #viewBtn3': {
      width: '20%',
      fontSize: '0.9rem',
      textAlign: 'start',
      '@media screen and (max-width:600px)': {
        width: '114px',
        textAlign: 'start',
      },
    },
    '& #viewBtn2, & #viewBtn4': {
      width: '100%',
      display: 'flex',
      textAlign: 'start',
      '& > select': {
        width: '100%',
        height: '40px',
        padding: '8px',
        fontFamily: theme.typography.fontFamily,
        border: '0.5px solid grey',
        borderRadius: '0.5em',
        boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
        '&:focus': {
          outline: `1.2px ${theme.palette.primary.main} outset`,
        },
      },
      '& > form': {
        display: 'flex',
        justifyContent: 'start',
        gap: '0px 16px',
        width: '100%',
        '& > input': {
          width: '70%',
          height: '40px',
          padding: '8px',
          fontFamily: theme.typography.fontFamily,
          border: '0.5px solid grey',
          borderRadius: '0.5em',
          boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
          '&:focus': {
            outline: `1.2px ${theme.palette.primary.main} outset`,
          },
        },
        '& > button': {
          width: '30%',
          display: 'block',
          fontFamily: theme.typography.fontFamily,
          fontWeight: 'bold',
          borderRadius: '0.5em',
          border: 'none',
          padding: '4px 8px',
          backgroundColor: theme.palette.primary.main,
          boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
          color: '#fff',
          cursor: 'pointer',
        },
      },
      '@media screen and (max-width:600px)': {
        width: '100%',
      },
    },
  },
  aggregate: {
    width: '100%',
    marginBottom: '40px',
    '& > table': {
      width: '100%',
      margin: '0 auto',
      borderCollapse: 'collapse',
      fontSize: '0.9em',
      '& > thead': {
        '& > tr': {
          '& > td': {
            minWidth: '96px',
            fontSize: '0.9rem',
            border: '1px solid #ccc',
          },
        },
      },
      '& > tbody': {
        '& > tr': {
          '& > td': {
            fontSize: '0.9rem',
            border: '1px solid #ccc',
            padding: '8px',
            textAlign: 'right',
            '& > button': {
              cursor: 'pointer',
            },
          },
          '& #category1, & #category2, & #category3': {
            textAlign: 'center',
          },
          '&:nth-child(even)': {
            backgroundColor: '#f8f8f8',
          },
          '&:focus': {
            backgroundColor: '#e0e0e0',
          },
        },
      },
    },
    '& > p': {
      fontSize: '0.8rem',
      textAlign: 'start',
      color: 'grey',
    },
  },
  container: {
    marginTop: '64px',
    '& > div': {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      gap: '16px 16px',
      '& > button': {
        border: 'none',
        textDecoration: 'underline',
        backgroundColor: 'transparent',
        cursor: 'pointer',
      },
    },
    '& > table': {
      width: '100%',
      margin: '0 auto',
      padding: '0 8',
      borderCollapse: 'collapse',
      fontSize: '0.9em',
      lineHeight: '0.9em',
      textAlign: 'center',
      '& > thead': {
        '& > tr': {
          '& > td': {
            minWidth: '96px',
            fontSize: '0.9rem',
            border: '1px solid #ccc',
          },
        },
      },
      '& > tbody': {
        '& > tr': {
          '& > td': {
            fontSize: '0.9rem',
            border: '1px solid #ccc',
            padding: '8px',
            '& > button': {
              cursor: 'pointer',
            },
          },
          '&:nth-child(even)': {
            backgroundColor: '#f8f8f8', // 偶数行に薄いグレーを適用
          },
          '&:focus': {
            backgroundColor: '#e0e0e0', // フォーカス時に濃いグレーを適用
          },
        },
      },
    },
  },
}));

const PayoutsDetail = () => {
  const { isAuth } = useContext(AuthContext);

  const [servicePlans, setServicePlans] = useState();
  const [receivedDetail, setReceivedDetail] = useState();
  const [refunds, setRefunds] = useState();
  const [title, setTitle] = useState();

  const [viewData, setViewData] = useState([]);
  const [content, setContent] = useState();
  const [aggregate, setAggregate] = useState();

  const [date, setDate] = useState();
  const [firstDay, setFirstDay] = useState();
  const [lastDay, setLastDay] = useState();

  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '入金情報一覧', path: '/payouts_index' },
    { name: '入金情報詳細', path: '/payouts_detail' },
  ];

  //   if (history) console.log('history in memberAllPayments.js: ', history);
  // console.log('location.state: ', location.state);
  // if (receivedDetail) console.log('receivedDetail: ', receivedDetail);
  // if (servicePlans) console.log('servicePlans: ', servicePlans);
  // if (viewData) console.log('viewData: ', viewData);
  // if (refunds) console.log('refunds: ', refunds);

  //   Init
  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  useEffect(() => {
    let uuid;
    if (query.get('id')) {
      uuid = query.get('id');
    } else {
      uuid = location.state.uuid;
    }
    if (uuid) {
      getReceivedAmountDetail(setReceivedDetail, { uuid: uuid });
    }
  }, [location, query]);

  useEffect(() => {
    if (receivedDetail) {
      setServicePlans(receivedDetail.service_plan);
      setRefunds(receivedDetail.stripe_refund);
      const titleDate = new Date(
        receivedDetail.arrival_date
      ).toLocaleDateString('ja-JP', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'short', // 曜日を追加
      });
      setTitle(titleDate);
    }
  }, [receivedDetail]);

  useEffect(() => {
    if (servicePlans) {
      setViewData(servicePlans);
    }
  }, [servicePlans]);

  useEffect(() => {
    if (viewData && refunds && receivedDetail) {
      setContent(contents());
      setAggregate(receivedAggregate());
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [viewData, refunds, receivedDetail]);

  //   date
  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = new Date().getMonth() + 1;

    // 月の1日を取得
    const firstDayFormatted = `${year}-${month.toString().padStart(2, '0')}-01`;

    // 月の最終日を取得
    const lastDay = new Date(year, month, 0);
    const lastDayFormatted = `${year}-${month
      .toString()
      .padStart(2, '0')}-${lastDay.getDate().toString().padStart(2, '0')}`;

    setFirstDay(firstDayFormatted);
    setLastDay(lastDayFormatted);
  }, []);

  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  //   handle
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleDetail = (uuid) => {
    navigate(various.general.membersPaymentDetail, {
      state: { uuid: uuid, previous: location.pathname },
    });
  };

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    // second: '2-digit',
  };
  const contents = () => {
    if (viewData && viewData.length > 0 && refunds && receivedDetail) {
      return (
        <>
          <div className={classes.container}>
            <div>
              <span>■決済要素</span>
            </div>
            <table>
              <thead>
                <tr>
                  <td>ID</td>
                  <td>決済日時</td>
                  <td>会員</td>
                  <td>決済対象</td>
                  <td>決済区分</td>
                  <td>決済金額</td>
                  <td>決済手数料</td>
                  <td>決済status</td>
                  <td>詳細</td>
                </tr>
              </thead>
              <tbody>
                {viewData.map((data, index) => {
                  return (
                    <tr key={`payments_${index}`}>
                      <td>{index + 1}</td>
                      <td style={{ textAlign: 'start' }}>
                        {new Intl.DateTimeFormat('ja-JP', options).format(
                          new Date(data.created_at)
                        )}
                      </td>
                      <td style={{ textAlign: 'start' }}>
                        {data.member[0].last_name + data.member[0].first_name}
                      </td>
                      <td>
                        {data.service_name === 'membership'
                          ? '会費'
                          : data.service_name}
                      </td>
                      <td>
                        {data.payment_id_type === 'subscription'
                          ? '定期決済'
                          : data.payment_id_type === 'payment'
                          ? '都度決済'
                          : 'その他'}
                      </td>
                      <td>{data.price.toLocaleString()}</td>
                      <td>{data.payment_fee_total.toLocaleString()}</td>
                      <td>
                        {data.payment_completed
                          ? '決済完了'
                          : data.payment_status === 'Failed'
                          ? '決済失敗'
                          : data.refund_id
                          ? '返金'
                          : '未決済'}
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={(e) => handleDetail(data.uuid)}
                        >
                          詳細
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={classes.container}>
            <div>
              <span>■返金要素</span>
            </div>
            <table>
              <thead>
                <tr>
                  <td>ID</td>
                  <td>決済日時</td>
                  <td>会員</td>
                  <td>決済対象</td>
                  <td>決済区分</td>
                  <td>返金額</td>
                  <td>返金手数料</td>
                  <td>返金status</td>
                  <td>詳細</td>
                </tr>
              </thead>
              <tbody>
                {refunds.map((data, index) => {
                  const service_plan = data.service_plan;
                  return (
                    <tr key={`refund_${index}`}>
                      <td>{index + 1}</td>
                      <td style={{ textAlign: 'start' }}>
                        {new Intl.DateTimeFormat('ja-JP', options).format(
                          new Date(service_plan.created_at)
                        )}
                      </td>
                      <td style={{ textAlign: 'start' }}>
                        {service_plan.member[0].last_name +
                          service_plan.member[0].first_name}
                      </td>
                      <td>
                        {service_plan.service_name === 'membership'
                          ? '会費'
                          : service_plan.service_name}
                      </td>
                      <td>
                        {service_plan.payment_id_type === 'subscription'
                          ? '定期決済'
                          : service_plan.payment_id_type === 'payment'
                          ? '都度決済'
                          : 'その他'}
                      </td>
                      <td>
                        {service_plan.amount_refunded
                          ? service_plan.amount_refunded
                          : '-'}
                      </td>
                      <td>{service_plan.refund_fee_total.toLocaleString()}</td>
                      <td>
                        {service_plan.refund_status === 'succeeded'
                          ? '返金完了'
                          : !service_plan.refund_status
                          ? '-'
                          : '返金失敗'}
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={(e) => handleDetail(service_plan.uuid)}
                        >
                          詳細
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={classes.container}>
            <div>
              <span>■定期決済手数料</span>
            </div>
            <table>
              <thead>
                <tr>
                  <td>手数料確定日時</td>
                  <td>手数料</td>
                  <td>消費税</td>
                  <td>手数料合計</td>
                </tr>
              </thead>
              <tbody>
                {receivedDetail.stripe_received_fee.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {new Intl.DateTimeFormat('ja-JP', options).format(
                          new Date(data.created_at)
                        )}
                      </td>
                      <td>{data.gross_amount.toLocaleString()}</td>
                      <td>{data.fee_amount.toLocaleString()}</td>
                      <td>{data.net_amount.toLocaleString()}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      );
    }
  };

  const receivedAggregate = () => {
    if (receivedDetail) {
      const paymentTotal = receivedDetail.service_plan.reduce(
        (sum, obj) => sum + obj.price,
        0
      );
      const txFee = receivedDetail.service_plan.reduce(
        (sum, obj) => sum + obj.payment_fee_total,
        0
      );
      const feeTotal =
        receivedDetail.stripe_received_fee.reduce(
          (sum, obj) => sum + obj.net_amount,
          0
        ) + txFee;
      const refundTotal = receivedDetail.stripe_refund.reduce(
        (sum, obj) => sum + obj.net_amount,
        0
      );
      const refundFeeTotal = receivedDetail.stripe_refund.reduce(
        (sum, obj) => sum + obj.fee_amount,
        0
      );
      const paymentsCount = receivedDetail.service_plan.length;
      const refundCount = receivedDetail.stripe_refund.length;

      return (
        <div className={classes.aggregate}>
          <table>
            <thead>
              <tr>
                <td></td>
                <td>決済金額</td>
                <td>手数料</td>
                <td>返金額</td>
                <td>返金手数料</td>
                <td>差し引き入金額</td>
                <td>決済件数</td>
                <td>返金件数</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td id="category1">合計</td>
                <td>{paymentTotal.toLocaleString()}</td>
                <td>{feeTotal.toLocaleString()}</td>
                <td>{refundTotal.toLocaleString()}</td>
                <td>{refundFeeTotal.toLocaleString()}</td>
                <td>
                  {(
                    paymentTotal -
                    (feeTotal + refundTotal + refundFeeTotal)
                  ).toLocaleString()}
                </td>
                <td>{paymentsCount.toLocaleString()}</td>
                <td>{refundCount.toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>

      {isAuth &&
      viewData.length > 0 &&
      receivedDetail &&
      firstDay &&
      lastDay ? (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">{title}の入金データ詳細</span>
              <span>{date}</span>
            </div>
          </div>
          {loading ? <Spinner /> : aggregate}
          <BreadCrumbs crumbs={CRUMBS} />
          <hr />
          {/* <div className={classes.viewDataBtnContainer}>
            <div id="viewBtn1">
              <span>絞り込み&nbsp;:&nbsp;</span>
            </div>
            <div id="viewBtn2">
              <select
                onChange={(e) => handleTransaction(e.target.value)}
                defaultValue="default"
              >
                <option value="default">選択してください</option>
                <option value="all">すべて</option>
                <option value="completed">決済完了</option>
                <option value="failed">決済失敗</option>
                <option value="refund">返金</option>
                <option value="subscription">定期決済</option>
                <option value="payment">都度決済</option>
              </select>
            </div>
          </div>
          <div className={classes.viewDataBtnContainer}>
            <div id="viewBtn3">
              <span>期間指定&nbsp;:&nbsp;</span>
            </div>
            <div id="viewBtn4">
              <form
                onSubmit={(e) => {
                  handleTransactions(e.target.start.value, e.target.end.value);
                  e.preventDefault();
                }}
              >
                <input
                  type="date"
                  id="start"
                  name="start"
                  value={firstDay}
                  onChange={(e) => setFirstDay(e.target.value)}
                />
                <input
                  type="date"
                  id="end"
                  name="end"
                  value={lastDay}
                  onChange={(e) => setLastDay(e.target.value)}
                />
                <button type="submit">期間絞り込み</button>
              </form>
            </div>
          </div> */}
          {loading ? <Spinner /> : content}
        </>
      ) : loading ? (
        <Spinner />
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
      <Footer />
    </>
  );
};
export default PayoutsDetail;
