import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../../auth';
import { useLocation, useNavigate } from 'react-router-dom';
import various from '../../../General/various';
import {
  notificationDetail,
  notificationUpdate,
} from '../../../Apis/apiOfManaged';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import BreadCrumbs from '../../../General/breadCrumbs';
import Footer from '../../../Footer/footer';
const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  titles: {},
  inputs: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  helptext: {},
  container: {
    marginTop: '168px',
    width: '100%',
    '& > form': {
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '& > img': {
            maxWidth: '300px',
            maxHeight: '300px',
            objectFit: 'contain',
          },
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > label': {
            width: '100%',
            textAlign: 'end',
            '@media screen and (max-width:750px)': {
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
          '& > span': {
            color: 'red',
          },
        },

        '& $inputs': {
          width: '70%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input, & > textarea, & > select': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
          '& > textarea': {
            minHeight: '160px',
          },
          '& > input[type="checkbox"]': {
            boxShadow: 'none',
          },
          '& $helptext': {
            fontSize: '0.8rem',
            color: 'grey',
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    marginTop: '88px !important',
    justifyContent: 'end',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const NotificationUpdate = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const { isAuth } = useContext(AuthContext);
  const [notification, setNotification] = useState();
  const [completed, setCompleted] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '会員への通知一覧', path: '/notification_index' },
    { name: '会員への通知更新', path: '/notification_update' },
  ];

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  // Init
  useEffect(() => {
    if (completed) {
      navigate('/notification_index');
    }
  }, [completed, navigate]);

  useEffect(() => {
    if (location) {
      notificationDetail(setNotification, location.state.uuid);
    }
  }, [location]);

  useEffect(() => {
    function toLocalDateTime(isoString) {
      const date = new Date(isoString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');

      return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

    if (notification) {
      const rejectList = [];
      const keys = Object.keys(notification);
      const resetValues = {};
      keys.forEach((k) => {
        if (!rejectList.includes(k)) {
          if (k === 'delivery_date') {
            resetValues[k] = toLocalDateTime(notification[k]);
          } else {
            resetValues[k] = notification[k];
          }
        }
      });
      reset(resetValues);
    }
  }, [notification, reset]);

  // Submit
  const onSubmit = handleSubmit(async (data) => {
    try {
      //   console.log('data: ', data);
      await notificationUpdate(data);
      setCompleted(true);
      console.log('update finish!');
    } catch (error) {
      console.error('Error in updateInstance:', error);
    }
  });

  // Contents
  const form = () => {
    if (notification) {
      return (
        <>
          <div className={classes.container}>
            <form
              method="POST"
              encType="multipart/form-data"
              onSubmit={onSubmit}
            >
              <div>
                <div className={classes.titles}>
                  <label htmlFor="タイトル">{'タイトル'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="title"
                    type="text"
                    {...register('title', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.title?.message && (
                    <div className={classes.error}>{errors.title.message}</div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="内容案内">{'内容案内'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="description"
                    type="text"
                    {...register('description', {
                      required: { value: false, message: '入力必須の項目です' },
                      maxLength: {
                        value: 30,
                        message: '30字以内でお願いします',
                      },
                    })}
                  />
                  <div className={classes.helptext}>
                    現状、管理用に使用されます。通知の目的等をご記載ください。
                  </div>
                  {errors.description?.message && (
                    <div className={classes.error}>
                      {errors.description.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="通知予定日時">{'通知予定日時'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="delivery_date"
                    type="datetime-local"
                    {...register('delivery_date', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    通知メールが送信される日時を設定してください。過去の日付に設定することも可能です。
                  </div>
                  {errors.delivery_date?.message && (
                    <div className={classes.error}>
                      {errors.delivery_date.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="記事内容">{'記事内容'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <textarea
                    id="content"
                    type="textarea"
                    {...register('content', {
                      required: { value: true, message: '入力必須の項目です' },
                      maxLength: {
                        value: 2500,
                        message: '長すぎます',
                      },
                    })}
                  />
                  <div className={classes.helptext}>
                    2500文字以内でお願いします。
                  </div>
                  {errors.content?.message && (
                    <div className={classes.error}>
                      {errors.content.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="署名">{'署名'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <textarea
                    id="signature"
                    type="textarea"
                    {...register('signature', {
                      required: { value: true, message: '入力必須の項目です' },
                      maxLength: {
                        value: 100,
                        message: '長すぎます',
                      },
                    })}
                  />
                  <div className={classes.helptext}>
                    メール本文に付記される署名です。必ず記入してください。
                  </div>
                  {errors.signature?.message && (
                    <div className={classes.error}>
                      {errors.signature.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="通知">{'通知'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="delivered"
                    type="checkbox"
                    {...register('delivered', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    チェックを入れたら全会員にメール配信されます。チェックをしなければ通知予定日が設定されていてもメール配信はされません。
                  </div>
                  {errors.delivered?.message && (
                    <div className={classes.error}>
                      {errors.delivered.message}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.button}>
                <button type="submit">更新</button>
              </div>
            </form>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>会員への通知内容更新</div>
      <hr />
      <BreadCrumbs crumbs={CRUMBS} />
      {isAuth ? (
        <>
          {form()}
          <Footer />
        </>
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default NotificationUpdate;
