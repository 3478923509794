import { Button, makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import imageIndex from '../img/imageIndex';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    flexWrap: 'nowrap',
    justifyContent: 'end',
    '&:hover': {
      fontWeight: 'bold',
    },
  },
  span: {
    whiteSpace: 'nowrap',
    fontSize: '1rem',
    fontFamily: theme.typography.fontFamily,
    '@media screen and (max-width:999px)': {
      fontSize: 'calc(100vw/1000*1rem)',
    },
  },
  arrow: {
    fill: 'currentColor',
    width: '32px',
    height: '32px',
    '& path': {
      fill: 'currentColor',
    },
    '@media screen and (max-width:999px)': {
      width: 'calc(100vw/1000*32)',
      minWidth: '24px',
      height: 'calc(100vw/1000*32)',
      minHeight: '24px',
    },
  },
}));

const ArrowButton = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = (e) => {
    if (props.path) {
      navigate(props.path);
    } else if (props.clickFunc) {
      props.clickFunc(e);
    }
  };

  return (
    <Button
      style={{ color: props.color }}
      className={classes.button}
      type="submit"
      onClick={handleClick}
      disabled={props.disabled}
    >
      <span className={classes.span}>{props.buttonName}</span>
      <imageIndex.arrowIcon className={classes.arrow} />
    </Button>
  );
};

export default ArrowButton;
