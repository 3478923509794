import { createTheme } from '@material-ui/core';
// import { useMediaQuery } from '@material-ui/core';

export function MainTheme() {
  // const isDarkMode = useMediaQuery('(prefers-color-scheme: dark');

  const mainTheme = {
    typography: {
      fontFamily:
        '"Times New Roman", "YuMincho", "Hiragino Mincho ProN", "MS PMincho", "Noto Serif CJK JP", "Noto Serif CJK SC", "Hiragino Mincho Pro", "Hiragino Mincho ProN", "游明朝", "Yu Mincho", "ＭＳ Ｐ明朝", "MS PMincho", "Noto Serif JP", serif',
      fontSize: 20,
    },
    palette: {
      // type: isDarkMode ? 'dark' : 'light',
      type: 'light',
      primary: {
        main: '#7C2A2E',
        default: '#F3F3F3',
      },
      secondary: {
        main: '#607d8b',
        contrastText: '#102027',
      },
      background: {
        default: '#F3F3F3',
      },
      text: { primary: '#102027' },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            lineHeight: 2,
            textAlign: 'center',
            fontWeight: 600,
            margin: '0px 15vw 0px',
            '@media screen and (max-width:1000px)': {
              margin: '0px 4vw 0px',
            },
          },
          'body > footer': {
            margin: '0px !important',
          },
          a: {
            textDecoration: 'none',
            '&:link': {
              color: '#FFF',
            },
            '&:visited': {
              color: '#770000',
            },
            '&:hover': {
              fontWeight: 'bold',
            },
          },
        },
      },
    },
  };

  return createTheme(mainTheme);
}
