import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../Animation/onOffByScroll';
import { AuthContext } from '../../auth';
import { localStorageArrayHandler } from '../../CommonFunction/managePermission';
import Footer from '../../Footer/footer';
import various from '../../General/various';
import SitemapGenerator from '../../sitemapGenerator.js';
import {
  addChargeIdToServicePlans,
  initializeForStripe,
  myPage,
} from '../../Apis/apiOfManaged.js';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
  },
  container: {
    marginTop: '64px',
    '& > div': {
      textAlign: 'start',
      marginBottom: '64px',
      '& > h2': {
        padding: '0.1px 16px',
        background: 'transparent',
        borderLeft: `solid 5px ${theme.palette.primary.main}`,
      },
      '& > p': {
        fontSize: '1rem',
        lineHeight: '28px',
      },
      '& > table': {
        width: '100%',
        margin: '16px 0px 32px 0px',
        fontSize: '1rem',
        borderCollapse: 'collapse',
        '& > tbody': {
          '& > tr': {
            '& > td': {
              lineHeight: '20px',
              padding: '8px',
              border: 'solid 0.2px grey',
            },
            '& #title': {
              minWidth: '48px',
            },
            '& > #desc': {},
            '& #link': {
              minWidth: '48px',
              textAlign: 'center',
              '& > a': {
                '& > button': {
                  fontFamily: theme.typography.fontFamily,
                  fontWeight: 'bold',
                  borderRadius: '0.5em',
                  border: 'none',
                  padding: '4px 8px',
                  backgroundColor: theme.palette.primary.main,
                  boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
                  color: '#fff',
                  cursor: 'pointer',
                },
              },
            },
          },
        },
      },
    },
  },
}));
const ManagedIndex = () => {
  const { isAuth } = useContext(AuthContext);
  const permissions = localStorageArrayHandler('parse', various.permissionKey);

  const [date, setDate] = useState();
  const [initialized, setInitialized] = useState(false);
  // Viewに制限をかける際に使用
  // eslint-disable-next-line
  const [user, setUser] = useState({});

  // if (user) console.log(user);

  const navigate = useNavigate();
  const location = useLocation();

  const classes = useStyles();

  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  useEffect(() => {
    myPage(setUser);
  }, []);

  useEffect(() => {
    if (initialized) {
      alert('全ての会員を回数会員にしました。');
    }
  }, [initialized]);

  const tableContainer = (flag) => {
    const element = [
      {
        subject: 'member',
        mainTitle: '会員およびサイト登録者の管理',
        guidance:
          '会員情報の登録・閲覧・更新が行えます。また、csv形式で整理した一覧表をもとに、複数名を一括して登録することもできます。',
        contents: [
          {
            title: '一覧画面',
            desc: `一覧画面にて、新規登録、閲覧、更新をしてください。`,
            url: various.general.memberIndex,
          },
          {
            title: '会員一括csv登録',
            desc: `所定の様式にて、複数名の一括登録が行えます。様式については管理者へお問合せください。`,
            url: various.general.memberBulkCreate,
          },
        ],
      },
      {
        subject: 'branch',
        mainTitle: '同好会および特別稽古会情報の管理',
        guidance: '同好会および特別稽古会情報の登録・閲覧・更新が行えます。',
        contents: [
          {
            title: '新規登録',
            desc: `同好会・特別稽古会情報の新規登録画面です。`,
            url: various.general.branchCreate,
          },
          {
            title: '一覧画面',
            desc: `一覧画面にて、閲覧、更新をしてください。`,
            url: various.general.branchIndex,
          },
        ],
      },
      {
        subject: 'seminar',
        mainTitle: 'セミナーの管理',
        guidance:
          'セミナー情報の登録・閲覧・更新が行えます。講師情報の表示の有無やニュース配信を同時に行うか否か、公開予定日時の登録など、セミナーにまつわる様々なことをコントロールすることができます。',
        contents: [
          {
            title: '新規登録',
            desc: `セミナー等の新規登録画面です。`,
            url: various.general.seminarCreateForAdmin,
          },
          {
            title: '一覧画面',
            desc: `一覧画面にて、新規登録、閲覧、更新をしてください。`,
            url: various.general.seminarIndex,
          },
        ],
      },
      {
        subject: 'instructor',
        mainTitle: '指導者情報の管理',
        guidance:
          '指導者情報の登録・閲覧・更新が行えます。現状、指導者はセミナーの「講師」欄に表示されます。',
        contents: [
          {
            title: '新規登録',
            desc: `指導者情報の新規登録画面です。`,
            url: various.general.instructorCreate,
          },
          {
            title: '一覧画面',
            desc: `一覧画面にて、閲覧、更新をしてください。`,
            url: various.general.instructorIndex,
          },
        ],
      },
      {
        subject: 'placeAndHall',
        mainTitle: '会場情報の管理',
        guidance:
          'セミナー会場や稽古場情報の登録・閲覧・更新が行えます。当該情報は、セミナー申込者へのメール配信や、会員専用のMyPage内で稽古場情報として一覧で表示されます。',
        contents: [
          {
            title: '新規登録',
            desc: `稽古場情報の新規登録画面です。`,
            url: various.general.placeAndHallCreate,
          },
          {
            title: '一覧画面',
            desc: `一覧画面にて、閲覧、更新をしてください。`,
            url: various.general.placeAndHallIndex,
          },
        ],
      },
      {
        subject: 'news',
        mainTitle: 'ニュースの管理',
        guidance: 'ニュース情報の登録・閲覧・更新が行えます。',
        contents: [
          {
            title: '新規登録',
            desc: `ニュースの新規登録画面です。`,
            url: various.general.newsCreateForAdmin,
          },
          {
            title: '一覧画面',
            desc: `一覧画面にて、新規登録、閲覧、更新をしてください。`,
            url: various.general.newsIndex,
          },
        ],
      },
      {
        subject: 'notification',
        mainTitle: '会員への通知管理',
        guidance: '会員へのメールでの案内の登録・閲覧・更新が行えます。',
        contents: [
          {
            title: '一覧画面',
            desc: `一覧画面にて、新規登録、閲覧、更新をしてください。`,
            url: various.general.notificationIndex,
          },
        ],
      },
      {
        subject: 'tag',
        mainTitle: 'タグの管理',
        guidance:
          'タグ情報の登録・閲覧・更新が行えます。タグはニュース一覧やセミナー一覧にて、ワンクリックでタグによる絞り込みが行えたり、その内容を瞬時に視認させることに有用です。また、タグが付されているコンテンツがSNSでシェアされた場合、ハッシュタグとしても機能します。',
        contents: [
          {
            title: '新規登録',
            desc: `タグの新規登録画面です。`,
            url: various.general.tagCreate,
          },
          {
            title: '一覧画面',
            desc: `一覧画面にて閲覧、更新をしてください。`,
            url: various.general.tagIndex,
          },
        ],
      },
      {
        subject: 'image',
        mainTitle: 'イメージの登録と更新',
        guidance:
          'ページトップ画像やイメージ写真・イラストなど、変更や動きのない静的イメージおよび、セミナーイメージやニュースイメージで使用する画像の登録・更新が行えます。',
        contents: [
          {
            title: '動的イメージの登録',
            desc: `セミナーイメージおよびニュースイメージの新規登録はこちらからお願いします。`,
            url: various.general.imageCreate,
          },
          {
            title: '動的イメージの更新',
            desc: `セミナーイメージおよびニュースイメージの更新はこちらからお願いします。`,
            url: various.general.imageIndexForAdmin,
          },
          {
            title: '静的イメージの更新',
            desc: `各種トップ画像など、こちらから更新してください。`,
            url: various.general.imageUpdater,
          },
        ],
      },
      {
        subject: 'documents',
        mainTitle: '文書・資料の登録と更新',
        guidance:
          '主にセミナーで使用する文書や資料の登録・更新が行えます。ここで文書・資料の登録を行うと、セミナー登録時に文書の選択が行えます。なお、文書・資料は「.pdf」または「.txt」しか登録できません。',
        contents: [
          {
            title: '文書・資料の登録',
            desc: `新規登録はこちらからお願いします。`,
            url: various.general.documentCreate,
          },
          {
            title: '一覧画面',
            desc: `一覧画面にて閲覧、更新をしてください。`,
            url: various.general.documentIndex,
          },
        ],
      },
      {
        subject: 'payments',
        mainTitle: '決済情報の管理',
        guidance:
          'ここでは基本的にカード決済の最新情報を管理します。なお現金管理は運営上共有していないため、表示されている現金決済扱いの情報は「カード決済ではない者」として扱ってください。',
        contents: [
          {
            title: '一覧画面',
            desc: `一覧画面にて閲覧、更新をしてください。`,
            url: various.general.membersPayment,
          },
        ],
      },
      {
        subject: 'payouts',
        mainTitle: '入金情報の管理',
        guidance: 'ここでは決済された金額の入金情報を管理します。',
        contents: [
          {
            title: '一覧画面',
            desc: `一覧画面にて閲覧、更新をしてください。`,
            url: various.general.payoutsIndex,
          },
        ],
      },
      {
        subject: 'product_master',
        mainTitle: 'サービスマスターの登録と更新',
        guidance:
          'ここでは刀禅の稽古をサービスとして仮定し、提供するサービスの型を登録・管理します。なお、ここで登録した型は決済代行業者のStripeの商品マスターとも連動しています。',
        contents: [
          {
            title: 'サービスマスターの登録',
            desc: `新規登録はこちらからお願いします。`,
            url: various.general.productMasterCreate,
          },
          {
            title: '一覧画面',
            desc: `一覧画面にて閲覧、更新をしてください。`,
            url: various.general.productMasterIndex,
          },
        ],
      },
      {
        subject: 'dev',
        mainTitle: '開発者向け機能',
        guidance:
          '現状では、ここでサイトマップが生成されます。サイトマップ生成の目的は、GoogleやFacebook,Twitterのcrawlerに対して静的なページ情報を適切に伝えるために行い、サイトユーザーに対しては関係はありません。',
        contents: [
          {
            title: 'サイトマップ生成',
            desc: `新規ページが作られた際など、必要なタイミングで生成してください。`,
            url: '',
            contentSubject: 'sitemap',
          },
          // 役割は終了したためコメントアウト
          // {
          //   title: '回数会員への更新',
          //   desc: `全ての会員が回数会員になります。確認ダイアログ等ありません。すぐに実行されます。十分ご注意ください。`,
          //   url: '',
          //   contentSubject: 'membership',
          // },
          {
            title: 'チャージIDの後付け',
            desc: `ServicePlanインスタンスにおいてcharge_idがNullの場合、対応するcharge_idの最新版をインスタンスに書き込みます。`,
            url: '',
            contentSubject: 'addChargeId',
          },
        ],
      },
    ];

    const handleMembership = () => {
      initializeForStripe(setInitialized);
    };

    const handleAddChargeId = () => {
      addChargeIdToServicePlans();
    };

    const target = element.filter((el) => {
      return flag === el.subject;
    })[0];

    return (
      <>
        <h2>{target.mainTitle}</h2>
        <p>{target.guidance}</p>
        <table>
          <tbody>
            {target.contents.map((el, index) => {
              return (
                <tr key={`${flag}_${index}`}>
                  <td id="title">{el.title}</td>
                  <td id="desc">{el.desc}</td>
                  {target.subject !== 'dev' ? (
                    <td id="link">
                      <a href={el.url}>
                        <button>LINK</button>
                      </a>
                    </td>
                  ) : (
                    <>
                      <td id="link">
                        {el.contentSubject === 'sitemap' ? (
                          <SitemapGenerator />
                        ) : el.contentSubject === 'membership' ? (
                          <button onClick={handleMembership}>Initialize</button>
                        ) : el.contentSubject === 'addChargeId' ? (
                          <button onClick={handleAddChargeId}>
                            addChargeId
                          </button>
                        ) : null}
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <>
      <>
        <div style={{ margin: '80px' }}>
          <OnOffByScroll place="General" />
        </div>
        {isAuth && permissions.includes('admin') ? (
          <>
            <div className={classes.pageTitle}>
              <div>
                <span id="title">管理画面</span>
                <span>{date}</span>
              </div>
            </div>
            <hr />
            <div className={classes.container}>
              <div className={classes.memberContainer}>
                {tableContainer('member')}
              </div>
              <div className={classes.seminarContainer}>
                {tableContainer('seminar')}
              </div>
              <div className={classes.imageContainer}>
                {tableContainer('payments')}
              </div>
              <div className={classes.imageContainer}>
                {tableContainer('payouts')}
              </div>
              <div className={classes.branchContainer}>
                {tableContainer('branch')}
              </div>
              <div className={classes.instructorContainer}>
                {tableContainer('instructor')}
              </div>
              <div className={classes.placeAndHallContainer}>
                {tableContainer('placeAndHall')}
              </div>
              <div className={classes.newsContainer}>
                {tableContainer('news')}
              </div>
              <div className={classes.newsContainer}>
                {tableContainer('notification')}
              </div>
              <div className={classes.tagContainer}>
                {tableContainer('tag')}
              </div>
              <div className={classes.imageContainer}>
                {tableContainer('image')}
              </div>
              <div className={classes.imageContainer}>
                {tableContainer('documents')}
              </div>
              <div className={classes.imageContainer}>
                {tableContainer('product_master')}
              </div>
              <div className={classes.imageContainer}>
                {tableContainer('dev')}
              </div>
            </div>
          </>
        ) : (
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleAuth}
            >
              ログイン
            </span>
            してください。
          </div>
        )}
        <Footer />
      </>
    </>
  );
};
export default ManagedIndex;
