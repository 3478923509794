import { makeStyles } from '@material-ui/core';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import OnOffByScroll from '../Animation/onOffByScroll';
import ContentTitle from '../General/contentTitle';
import various from '../General/various';
import Footer from '../Footer/footer';
import { images as imageApi } from '../Apis/apis';
import { Spinner } from '../Spinner';
import { ogpGenerator } from '../ogp';
import { useLocation } from 'react-router-dom';
import imageIndex from '../img/imageIndex';

const useStyles = makeStyles((theme) => ({
  contentTitle: {
    marginTop: '168px',
  },
  book_image: {
    width: '80%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    '& #title': {
      width: '50%',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      padding: '16px 24px 0 0',
      '& > span': {
        display: 'block',
        width: '100%',
        textAlign: 'start',
        lineHeight: '1.4',
      },
      '& > span:first-child': {
        lineHeight: '1.5',
      },
      '& > span:last-child': {
        fontSize: '3rem',
        '& > span': {
          color: theme.palette.primary.main,
        },
      },
    },
    '& #image': {
      width: '50%',
      '& > a': {
        cursor: 'pointer',
        '& > img': {
          width: '100%',
          minHeight: '240px',
          objectFit: 'contain',
        },
      },
    },
    '@media screen and (max-width:750px)': {
      flexDirection: 'column',
      '& #title': {
        order: '2',
        width: '100%',
        fontSize: '1.2rem',
        padding: '0 0 0 0',
        margin: '32px 0',
        '& > span': {
          fontSize: '1.5rem',
          textAlign: 'center !important',
        },
      },
      '& #image': {
        width: '100%',
        margin: '0 auto',
        order: '1',
        '& > img': {
          width: '100%',
          minHeight: '240px',
          objectFit: 'contain',
        },
      },
    },
  },
  information: {
    marginTop: '48px',
    width: '80%',
    margin: '0 auto',
    '& > div': {
      fontSize: '1rem',
      textAlign: 'start',
    },

    '& #description': {
      '& > span': {
        textIndent: '1em',
      },
    },
    '& #sight': {
      marginTop: '16px',
      display: 'flex',
      flexDirection: 'column',

      '& > div': {
        '& > span': {
          display: 'block',
          marginRight: '8px',
          fontSize: '1rem',
          fontWeight: 'bold',
        },
      },

      '& > div:first-child': {
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap',
      },
      '& > div:last-child': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: '48px 0',
        transition: 'transform 0.2s cubic-bezier(0.34, 1.56, 0.64, 1)', // トランジションを高速化し、ビジエ曲線を調整
        '&:hover': {
          transform: 'translateY(-5px)', // より高くジャンプさせる
        },
        '& > a > img': {
          display: 'block',
          width: '124px',
          objectFit: 'contain',
          margin: '0 auto',
        },
        '& > span > a': {
          display: 'block',
          textDecoration: 'underline',
          color: `${theme.palette.primary.main}`,
          textAlign: 'center',
          margin: '0 auto',
        },
      },
    },
    '& #button': {
      marginTop: '48px',
      '& > a': {
        '& > button': {
          width: '100%',
          height: '40px',
          marginTop: '24px',
          display: 'block',
          fontFamily: theme.typography.fontFamily,
          fontWeight: 'bold',
          borderRadius: '0.5em',
          border: 'none',
          padding: '4px 8px',
          backgroundColor: theme.palette.primary.main,
          boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
          color: '#fff',
          cursor: 'pointer',
          transition: 'transform 0.2s cubic-bezier(0.34, 1.56, 0.64, 1)',
          '&:hover': {
            transform: 'translateY(-5px)', // より高くジャンプさせる
          },
        },
      },
      '& > span': {
        fontSize: '0.8rem',
        display: 'block',
        textAlign: 'right',
        color: 'grey',
        marginTop: '8px',
      },
    },
    '& #spec': {
      marginTop: '48px',
      display: 'flex',
      flexDirection: 'column',
      '& > div': {
        display: 'flex',
        flexWrap: 'nowrap',
        '& > span:first-child': {
          width: '120px',
        },
      },
    },
    '@media screen and (max-width:500px)': {
      width: '95%',
    },
  },
}));

const Books = () => {
  const [images, setImages] = useState();
  const [bookImage, setBookImage] = useState();
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  const classes = useStyles();
  const booksAreaTitle = {
    titleList: ['Books'],
    sub: '書籍',
  };
  const AMAZON_PAGE_URL =
    'https://www.amazon.co.jp/%E6%8F%BA%E8%85%95%E6%B3%95-%E5%B0%8F%E7%94%A8-%E8%8C%82%E5%A4%AB/dp/4817060395';

  // OGP設定
  const ogp = useCallback((props) => {
    ogpGenerator(props);
  }, []);

  useLayoutEffect(() => {
    if (bookImage) {
      const ogpProps = {
        page: 'books',
        pageTitle: '刀禪出版物',
        path: location.pathname,
        imageUrl: bookImage.url,
      };
      ogp(ogpProps);
    }
  }, [bookImage, location, ogp]);
  // OGP設定ここまで

  useEffect(() => {
    imageApi(setImages);
  }, []);

  useEffect(() => {
    if (images) {
      Object.keys(images).forEach((key) => {
        if (images[key].title.includes('booksImage1')) {
          setBookImage(images[key]);
          setLoading(false);
        }
      });
    }
  }, [images]);

  return (
    <>
      <div>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.contentTitle}>
        <a href={various.general.books}>
          <ContentTitle title={booksAreaTitle} />
        </a>
      </div>
      <div className={classes.book_image}>
        <div id="title">
          <span>〝謎の老師〟が教える身体の基準の創り方　</span>
          <span>
            <span>揺</span>腕法
          </span>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <div id="image">
            <a href={AMAZON_PAGE_URL}>
              <img src={bookImage.url} alt="book_image" />
            </a>
          </div>
        )}
      </div>
      <div className={classes.information}>
        <div id="description">
          <span>
            　揺腕法は小用が発表した新しい身体運動法です。​
            今回は最も簡単で誰でもどこでも行える「腕振り体操　揺腕法」を広く一般の人に広めるために初の著書となりました。
            揺腕法の特色は「腕を振る」というシンプルな運動を通じて、身体の基準性「垂直性・水平性」を整えることで、すべての運動の出発点となる自分の身体を再構築するということにあります。
            パソコンの仕組みで例えると、揺腕法はすべてのアプリが動く基本OSに働きかける運動だといえるでしょう。
            <br />
            　シンプルな腕振り運動を通じて、身体に垂直・水平といった基準性を染み込ませることで、経年劣化で動きが不正確になっていたOS（身体）をバージョンアップすることで、そのOS上で動いているアプリケーション（運動）がより正確に快適に使えるようになるイメージです。
            <br />
            　でもどうして腕を振るだけでそんなことが起きるのでしょう？本書では小用の歩みとともに、その仕組と具体的な方法が詳細に紹介されています。
            ​身体の基準性を身につけることで性別や年齢に関わらず、スポーツや武術、格闘技はもちろん、日常的な歩く、立つ、起き上がる、物を取る、などのあらゆる運動のレベルをアップすることができます。
          </span>
        </div>
        <div id="sight">
          <div>
            <imageIndex.booksPage.doubleArrow />
            <span>More Information: </span>
          </div>
          <div>
            <a href="https://yowan.jp/">
              <img src={imageIndex.booksPage.yowanLogo} alt="yowan_logo" />
            </a>
            <span>
              <a href="https://yowan.jp/">https://yowan.jp/</a>
            </span>
          </div>
        </div>
        <hr />
        <div id="button">
          <a href={AMAZON_PAGE_URL}>
            <button type="button">購入</button>
          </a>
          <span>※Amazonサイトに遷移します</span>
        </div>

        <div id="spec">
          <div>
            <span>著者：</span>
            <span>小用茂夫</span>
          </div>
          <div>
            <span>定価：</span>
            <span>2,200円（税込み）</span>
          </div>
          <div>
            <span>出版社：</span>
            <span>日貿出版社</span>
          </div>
          <div>
            <span>発刊：</span>
            <span>2024年2月20日</span>
          </div>
          <div>
            <span>頁：</span>
            <span>160頁</span>
          </div>
          <div>
            <span>寸法：</span>
            <span>A5版（14.8 x 21 x 1.6 cm）</span>
          </div>
          <div>
            <span>ISBN-10：</span>
            <span>4817060395</span>
          </div>
          <div>
            <span>ISBN-13：</span>
            <span>978-4817060396</span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Books;
