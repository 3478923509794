export const fileNameChange = (data, key) => {
  const newFileName = data.title;
  // File名変更
  const inputFile = data[key][0];
  const fileName = inputFile.name;
  // ファイルの拡張子を取得
  const fileExtention = fileName.substring(fileName.lastIndexOf('.') + 1);
  const blob = inputFile.slice(0, inputFile.size, inputFile.type);
  // ファイル名称変更後のファイルオブジェクト
  const renamedFile = new File([blob], newFileName + '.' + fileExtention, {
    type: inputFile.type,
  });
  return renamedFile;
};

export const fileSizeObserver = (file, limitSize) => {
  const limit = 1024 * 1024 * limitSize; // limitSize == MB // 1024bite == 1KB, 1024KB == 1MB
  if (file.length > 0) {
    if (file[0].size > limit) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};
