import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import various from './various';
import imageIndex from '../img/imageIndex';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import { ListItemIcon, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { localStorageArrayHandler } from '../CommonFunction/managePermission';
import { myPage } from '../Apis/apiOfManaged';
import { permissionCheck } from '../Apis/apis';

const useStyles = makeStyles((theme) => ({
  icon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  blackIcon: {
    color: '#000',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  listRoot: {
    '& > div': {
      '& > li': {
        maxHeight: '48px !important',
      },
    },
  },
  itemIcon: {
    minWidth: '40px !important',
    '& $iconRoot': {
      fill: theme.palette.primary.main,
      // fillOpacity: '0.8',
      stroke: 'white',
      width: '24px',
      height: 'auto',
    },
  },
  iconRoot: {},
  itemList: {
    fontFamily:
      '"Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif',
    fontWeight: '800',
    fontSize: '0.9rem',
    fontStyle: 'italic',
    color: 'black',
  },
}));

function useHover() {
  const [hover, setHover] = useState(false);
  const ref = useRef(null);
  const handleMouseOver = () => setHover(true);
  const handleMouseOut = () => setHover(false);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);

      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, []);
  return [ref, hover];
}

export default function HumburgerMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hoverRef, isHovered] = useHover();
  const [open, setOpen] = useState(false);
  const [urls, setUrls] = useState([]);
  const [user, setUser] = useState(null);
  const [checked, setChecked] = useState(false);

  const isAuth = props.isAuth;

  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isHovered) {
      setAnchorEl(hoverRef.current);
      setOpen(true);
    }
  }, [isHovered, hoverRef]);

  useEffect(() => {
    let timeoutId;
    if (open) {
      const elm = document.getElementById('simple-menu');
      elm.onmousemove = (event) => {
        if (timeoutId) return;
        timeoutId = setTimeout(() => {
          timeoutId = 0;
          // 要素の座標を取得
          const target = document.getElementsByClassName('MuiList-root')[0];
          let targetCoordinate = target.getBoundingClientRect();
          // mouseLeave的Event発火
          const adjustableHeight =
            hoverRef.current.getBoundingClientRect().height;
          if (
            event.clientX < targetCoordinate.x &&
            event.clientY > adjustableHeight + targetCoordinate.y
          ) {
            setAnchorEl(null);
            setOpen(false);
          }
        }, 100);
      };
    }
  }, [open, hoverRef]);

  useEffect(() => {
    if (user) {
      permissionCheck({ email: user.email }, setChecked);
    }
  }, [user]);

  useEffect(() => {
    if (isAuth) {
      myPage(setUser);
    }
  }, [isAuth]);

  useEffect(() => {
    const adminView = ['MYPAGE', 'ManagedIndex'];
    const staffView = ['MYPAGE', 'IMAGEUPDATE'];
    const instructorView = ['MYPAGE', 'SeminarIndex', 'DocumentIndex'];
    const generalView = ['MYPAGE'];
    const memberOfGroupView = ['MYPAGE'];

    let permissions;
    if (checked) {
      permissions = localStorageArrayHandler('parse', various.permissionKey);
    }

    const result = [];
    for (const url of various.globalNavUrls) {
      if (!url.auth) {
        if (isAuth && url.name === 'LOGIN') {
          continue;
        } else {
          result.push(url);
        }
      } else if (!isAuth && url.auth) {
        continue;
      }

      if (isAuth && url.auth) {
        if (url.name === 'LOGOUT') {
          result.push(url);
          continue;
        }
        if (permissions) {
          if (permissions.includes('admin')) {
            if (adminView.includes(url.name)) {
              result.push(url);
            }
          } else if (permissions.includes('staff')) {
            if (staffView.includes(url.name)) {
              result.push(url);
            }
          } else if (
            permissions.includes('instructor') ||
            permissions.includes('seminar_staff') ||
            permissions.includes('organizer')
          ) {
            if (instructorView.includes(url.name)) {
              result.push(url);
            }
          } else if (permissions.includes('member_of_group')) {
            if (memberOfGroupView.includes(url.name)) {
              result.push(url);
            }
          } else if (permissions.includes('general')) {
            if (generalView.includes(url.name)) {
              result.push(url);
            }
          }
        }
      }
    }
    setUrls(result);
  }, [isAuth, checked]);

  const routing = (urls) => {
    const result = [];
    urls.forEach((url, index) => {
      const Icon = imageIndex.humMenu[url.name.toLowerCase()];
      result.push(
        <MenuItem
          key={index}
          onClick={(e) => {
            handleClose();
            if (url.path === '/') {
              if (e.metaKey) {
                window.open(url.path, '_blank');
              } else {
                navigate(url.path);
              }
            }
          }}
        >
          <ListItemIcon classes={{ root: classes.itemIcon }}>
            <Icon classes={{ root: classes.iconRoot }} />
          </ListItemIcon>
          <Link to={url.path !== '/' ? url.path : null}>
            <Typography className={classes.itemList} variant="inherit">
              {url.viewName}
            </Typography>
          </Link>
        </MenuItem>
      );
    });
    return <div>{result}</div>;
  };

  return (
    <div ref={hoverRef}>
      <MenuIcon
        className={props.isShown ? classes.blackIcon : classes.icon}
        fontSize="medium"
        onClick={handleClick}
      />
      <Menu
        id="simple-menu"
        classes={{ list: classes.listRoot }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {routing(urls)}
      </Menu>
    </div>
  );
}
