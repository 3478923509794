import { Box, makeStyles } from '@material-ui/core';
import { DataGrid, jaJP } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import {
  membersWithStripeCustomers,
  membersWithServicePlan,
  initializeForStripe,
} from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import { localStorageArrayHandler } from '../../../CommonFunction/managePermission';
import Footer from '../../../Footer/footer';
import various from '../../../General/various';
import imageIndex from '../../../img/imageIndex';
import { Spinner } from '../../../Spinner';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 16px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
    '& > p': {
      fontSize: '0.8rem',
      textAlign: 'start',
      color: 'grey',
    },
  },
  aggregate: {
    width: '100%',
    marginBottom: '40px',
    '& > table': {
      width: '100%',
      margin: '0 auto',
      borderCollapse: 'collapse',
      fontSize: '0.9em',
      '& > thead': {
        '& > tr': {
          '& > td': {
            minWidth: '96px',
            fontSize: '0.9rem',
            border: '1px solid #ccc',
          },
        },
      },
      '& > tbody': {
        '& > tr': {
          '& > td': {
            fontSize: '0.9rem',
            border: '1px solid #ccc',
            padding: '8px',
            textAlign: 'right',
            '& > button': {
              cursor: 'pointer',
            },
          },
          '& #category1, & #category2, & #category3': {
            textAlign: 'center',
          },
          '&:nth-child(even)': {
            backgroundColor: '#f8f8f8',
          },
          '&:focus': {
            backgroundColor: '#e0e0e0',
          },
        },
      },
    },
    '& > p': {
      fontSize: '0.8rem',
      textAlign: 'start',
      color: 'grey',
    },
  },
  yeard: {
    width: '100%',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '16px 16px',
    '& > button': {
      border: 'none',
      textDecoration: 'underline',
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
  },
  dataGrid: {
    height: (props) =>
      props.heightSize ? props.heightSize : props.pageSize * props.rowHeight,
    width: '100%',
    '& .MuiDataGrid-main': {
      fontSize: '0.9rem',
      fontFamily: theme.typography.fontFamily,
    },
  },
  viewDataBtnContainer: {
    width: '100%',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',
    '@media screen and (max-width:600px)': {
      flexWrap: 'wrap',
      gap: '0px 16px',
    },
    '& > div': {
      margin: 'auto 0',
      '& > span': {
        display: 'inline-block',
      },
    },
    '& #viewBtn1, & #viewBtn3': {
      width: '20%',
      fontSize: '0.9rem',
      textAlign: 'start',
      '@media screen and (max-width:600px)': {
        width: '114px',
        textAlign: 'start',
      },
    },
    '& #viewBtn2, & #viewBtn4': {
      width: '100%',
      display: 'flex',
      textAlign: 'start',
      '& > select': {
        width: '100%',
        height: '40px',
        padding: '8px',
        fontFamily: theme.typography.fontFamily,
        border: '0.5px solid grey',
        borderRadius: '0.5em',
        boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
        '&:focus': {
          outline: `1.2px ${theme.palette.primary.main} outset`,
        },
      },
      '& > form': {
        display: 'flex',
        justifyContent: 'start',
        gap: '0px 16px',
        width: '100%',
        '& > input': {
          width: '70%',
          height: '40px',
          padding: '8px',
          fontFamily: theme.typography.fontFamily,
          border: '0.5px solid grey',
          borderRadius: '0.5em',
          boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
          '&:focus': {
            outline: `1.2px ${theme.palette.primary.main} outset`,
          },
        },
        '& > button': {
          width: '30%',
          maxWidth: '160px',
          display: 'block',
          fontFamily: theme.typography.fontFamily,
          fontWeight: 'bold',
          borderRadius: '0.5em',
          border: 'none',
          padding: '4px 8px',
          backgroundColor: theme.palette.primary.main,
          boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
          color: '#fff',
          cursor: 'pointer',
        },
      },
      '@media screen and (max-width:600px)': {
        width: '100%',
      },
    },
  },
  viewBtn5: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    '& > button': {
      // width: '30%',
      maxWidth: '160px',
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      // border: 'none',
      borderColor: theme.palette.primary.main,
      padding: '12px 8px',
      // backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  downLoadBtnContainer: {
    width: '100%',
    marginBottom: '80px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',

    '@media screen and (max-width:600px)': {
      marginBottom: '0px',
    },
    '& > div': {
      width: '20%',
      margin: 'auto 0',
      '& > span': {
        display: 'inline-block',
      },
      '& > button': {
        display: 'inline-block',
        border: 'none',
        padding: '4px 8px',
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer', // downloadが機能したら復活
      },
    },
    '& #dlBtn1': {
      width: '20%',
      fontSize: '0.9rem',
      textAlign: 'start',
      '@media screen and (max-width:600px)': {
        width: '114px',
      },
    },
    '& #dlBtn2': {
      textAlign: 'start',
    },
  },
  detailBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
}));

const MembersPayment = () => {
  const { isAuth } = useContext(AuthContext);
  const [viewData, setViewData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [heightSize, setHeightSize] = useState();
  const [date, setDate] = useState();
  const [loading, setLoading] = useState(true);

  const [members, setMembers] = useState([]);
  const [servicePlans, setServicePlans] = useState([]);
  const [aggregate, setAggregate] = useState();

  const [numOfAllMembers, setNumOfAllMembers] = useState();
  const [numOfGeneralPaid, setNumOfGeneralPaid] = useState();
  const [numOfNumPaid, setNumOfNumPaid] = useState();
  const [numOfCash, setNumOfCash] = useState();
  const [firstDay, setFirstDay] = useState();
  const [lastDay, setLastDay] = useState();
  const [yeard, setYeard] = useState();
  const [yearIndex, setYearIndex] = useState(0);
  const [showInit, setShowInit] = useState(true);
  const [isInit, setIsInit] = useState(false);
  const [currentFlag, setCurrentFlag] = useState('default');

  const permissions = localStorageArrayHandler('parse', various.permissionKey);
  // console.log('permissions', permissions);

  const ROWHEIGHT = 80;
  const PAGESIZE = 30;
  const classes = useStyles({
    pageSize: PAGESIZE,
    rowHeight: ROWHEIGHT,
    heightSize: heightSize,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    // second: '2-digit',
  };

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '決済情報一覧', path: '/members_payment' },
  ];

  // if (servicePlans.length > 0) console.log('servicePlans', servicePlans);
  // if (viewData.length > 0) console.log('viewData', viewData);

  //   Init
  useEffect(() => {
    if (isAuth) {
      membersWithStripeCustomers(setMembers);
      membersWithServicePlan(setServicePlans);
    }
  }, [isAuth]);

  useEffect(() => {
    if (members.length > 0) {
      setNumOfAllMembers(members.filter((m) => m.is_member).length);
      setNumOfCash(
        members.filter((m) => {
          return (
            (m.stripe_customer && !m.stripe_customer.by_stripe) ||
            !m.stripe_customer
          );
        }).length
      );
      setNumOfGeneralPaid(
        members.reduce(
          (sum, member) =>
            sum +
            (member.service_plan && member.member_of_general_is
              ? member.service_plan.length
              : 0),
          0
        )
      );
      setNumOfNumPaid(
        members.reduce(
          (sum, member) =>
            sum +
            (member.service_plan && member.member_of_num_is
              ? member.service_plan.length
              : 0),
          0
        )
      );
    }
  }, [members]);

  useEffect(() => {
    function extractYears(dataArray) {
      const years = dataArray.map((item) =>
        new Date(item.service_date_time).getFullYear()
      );

      return [...new Set(years)].sort((a, b) => b - a);
    }

    if (servicePlans.length > 0) {
      setViewData(
        servicePlans.filter(
          (plan) =>
            plan.member[0].is_member || plan.member[0].is_training_group_member
        )
      );
      setYeard(extractYears(servicePlans));
      // setYeard([2023, 2022, 2021]);
    }
  }, [servicePlans]);

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = new Date().getMonth() + 1;

    // 月の1日を取得
    const firstDayFormatted = `${year}-${month.toString().padStart(2, '0')}-01`;

    // 月の最終日を取得
    const lastDay = new Date(year, month, 0);
    const lastDayFormatted = `${year}-${month
      .toString()
      .padStart(2, '0')}-${lastDay.getDate().toString().padStart(2, '0')}`;

    setFirstDay(firstDayFormatted);
    setLastDay(lastDayFormatted);
  }, []);

  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  useEffect(() => {
    if (viewData) {
      const size = viewData.length < PAGESIZE ? viewData.length : PAGESIZE;
      setHeightSize(size * ROWHEIGHT * 1.4 + 122);
    }
  }, [viewData]);

  // contents
  useEffect(() => {
    const headers = [
      {
        field: 'id',
        headerName: 'ID',
        width: 10,
      },
      {
        field: 'uuid',
      },
      {
        field: 'name',
        headerName: '会員氏名',
        cellClassName: 'bold',
        width: 110,
      },
      {
        field: 'email',
        headerName: 'Email',
        width: 150,
      },
      {
        field: 'kind',
        headerName: '会員区分',
        cellClassName: (params) =>
          params.value === '定例会員' ? 'green' : 'brown',
        width: 80,
      },
      {
        field: 'price',
        headerName: '決済金額',
        cellClassName: 'bold',
        width: `72`,
      },
      {
        field: 'price_fee',
        headerName: '手数料',
        cellClassName: 'bold',
        width: `72`,
      },
      {
        field: 'price_received',
        headerName: '受領金額',
        cellClassName: 'bold',
        width: `72`,
      },
      {
        field: 'target',
        headerName: '決済対象',
        cellClassName: (params) => (params.value === '会費' ? 'green' : 'blue'),
        width: 100,
      },
      {
        field: 'method',
        headerName: '決済方法',
        cellClassName: 'bold',
        width: 80,
      },
      {
        field: 'date',
        headerName: '決済日',
        width: 100,
      },
      {
        field: 'status',
        headerName: '決済ステータス',
        cellClassName: (params) =>
          params.value === '決済完了'
            ? 'green'
            : params.value === '返金済み'
            ? 'brown'
            : 'red',
        width: 120,
      },
      {
        field: 'paid-status',
        headerName: '入金ステータス',
        cellClassName: (params) =>
          params.value === '入金済'
            ? 'green'
            : params.value === '相殺済'
            ? 'blue'
            : params.value === '未入金'
            ? 'brown'
            : 'red',
        width: 120,
      },
    ];
    const operationBtn = [
      {
        field: 'detailBtn',
        headerName: '詳細',
        sortable: false,
        width: 80,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <div className={classes.detailBtn}>
            <button onClick={() => handleClick('detail', params.row.uuid)}>
              詳細
            </button>
          </div>
        ),
      },
      {
        field: 'historyBtn',
        headerName: '支払履歴',
        sortable: false,
        width: 80,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <div className={classes.detailBtn}>
            <button onClick={() => handleClick('allPayments', params.row.uuid)}>
              履歴
            </button>
          </div>
        ),
      },
    ];
    const columnsDataset = headers.concat(operationBtn);
    setColumns(columnsDataset);

    setRows(
      viewData.map((data, index) => {
        const member = data.member[0];
        const strypeCustomer = data.stripe_customer;
        const result = {};
        result['id'] = index + 1;
        result['uuid'] = data.uuid;
        result['name'] = member['last_name'] + member['first_name'];
        result['email'] = member.email;
        result['kind'] = member['member_of_general_is']
          ? '定例会員'
          : member['member_of_num_is']
          ? '回数会員'
          : member['is_training_group_member']
          ? '稽古会会員'
          : '非会員';
        result['price'] = data.price.toLocaleString();
        result['price_fee'] = data.payment_fee_total.toLocaleString();
        result['price_received'] = (
          data.price - data.payment_fee_total
        ).toLocaleString();
        result['target'] =
          data.service_name === 'membership' ? '会費' : data.service_name;
        result['method'] =
          strypeCustomer?.current_payment_method === 'credit_card'
            ? 'カード'
            : strypeCustomer?.current_payment_method === 'cash'
            ? '現金'
            : '-';
        result['date'] = new Date(data.created_at).toLocaleDateString();
        result['status'] =
          data.refund_id && data.refund_status === 'succeeded'
            ? '返金済み'
            : data.refund_id && data.refund_status === 'failed'
            ? '返金失敗'
            : data.payment_completed
            ? '決済完了'
            : '未決済';

        result['paid-status'] =
          data.is_offset && data.refund_id
            ? '相殺済'
            : data.is_received && data.payout_id
            ? '入金済'
            : '未入金';
        return result;
      })
    );
    setAggregate(viewDataAggregate());
    // eslint-disable-next-line
  }, [viewData]);

  useEffect(() => {
    if (aggregate) {
      setLoading(false);
    }
  }, [aggregate]);

  const viewDataAggregate = () => {
    if (viewData) {
      // subscription
      const subscriptionTotal = viewData.reduce(
        (sum, obj) =>
          obj.payment_id_type === 'subscription' ? sum + obj.price : sum,
        0
      );
      const subscriptionFeeTotal = viewData.reduce(
        (sum, obj) =>
          obj.payment_id_type === 'subscription'
            ? sum + obj.payment_fee_total
            : sum,
        0
      );
      const subscriptionRefundTotal = viewData.reduce(
        (sum, obj) =>
          obj.payment_id_type === 'subscription' && obj.refund_id
            ? sum + obj.amount_refunded
            : sum,
        0
      );
      const subscriptionRefundFeeTotal = viewData.reduce(
        (sum, obj) =>
          obj.payment_id_type === 'subscription'
            ? sum + obj.refund_fee_total
            : sum,
        0
      );
      const subscriptionCount = viewData.filter((data) => {
        return data.payment_id_type === 'subscription';
      }).length;
      const subscriptionRefundCount = viewData.filter((data) => {
        return data.payment_id_type === 'subscription' && data.refund_id;
      }).length;

      // payment
      const paymentTotal = viewData.reduce(
        (sum, obj) =>
          obj.payment_id_type === 'payment' ? sum + obj.price : sum,
        0
      );
      const paymentFeeTotal = viewData.reduce(
        (sum, obj) =>
          obj.payment_id_type === 'payment' ? sum + obj.payment_fee_total : sum,
        0
      );
      const paymentRefundTotal = viewData.reduce(
        (sum, obj) =>
          obj.payment_id_type === 'payment' && obj.refund_id
            ? sum + obj.amount_refunded
            : sum,
        0
      );
      const paymentRefundFeeTotal = viewData.reduce(
        (sum, obj) =>
          obj.payment_id_type === 'payment' ? sum + obj.refund_fee_total : sum,
        0
      );
      const paymentCount = viewData.filter((data) => {
        return data.payment_id_type === 'payment';
      }).length;
      const paymentRefundCount = viewData.filter((data) => {
        return data.payment_id_type === 'payment' && data.refund_id;
      }).length;

      // deducted
      // 入会金控除スキームの運用停止に伴い、以下のコードはコメントアウト
      // const deductedTotalOfSubscription = viewData.reduce(
      //   (sum, obj) =>
      //     obj.product.is_deduction_model &&
      //     obj.payment_id_type === 'subscription'
      //       ? sum + obj.deducted_amount
      //       : sum,
      //   0
      // );
      // const deductedTotalOfPayment = viewData.reduce(
      //   (sum, obj) =>
      //     obj.product.is_deduction_model && obj.payment_id_type === 'payment'
      //       ? sum + obj.deducted_amount
      //       : sum,
      //   0
      // );

      // total
      const total = subscriptionTotal + paymentTotal;
      const totalFee = subscriptionFeeTotal + paymentFeeTotal;
      const totalRefund = subscriptionRefundTotal + paymentRefundTotal;
      const totalRefundFee = subscriptionRefundFeeTotal + paymentRefundFeeTotal;
      // 入会金控除スキームの運用停止に伴い、以下のコードはコメントアウト
      // const totalDeductionFee =
      //   deductedTotalOfSubscription + deductedTotalOfPayment;
      const totalCount = subscriptionCount + paymentCount;
      const totalRefundCount = subscriptionRefundCount + paymentRefundCount;

      return (
        <div className={classes.aggregate}>
          <table>
            <thead>
              <tr>
                <td></td>
                <td>決済金額</td>
                <td>決済手数料</td>
                <td>返金額</td>
                <td>返金手数料</td>
                <td>差し引き</td>
                {/* <td>控除済入会金額</td> */}
                <td>決済件数</td>
                <td>返金件数</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td id="category1">定期決済</td>
                <td>{subscriptionTotal.toLocaleString()}</td>
                <td>{subscriptionFeeTotal.toLocaleString()}</td>
                <td>{subscriptionRefundTotal.toLocaleString()}</td>
                <td>{subscriptionRefundFeeTotal.toLocaleString()}</td>
                <td>
                  {(
                    subscriptionTotal -
                    (subscriptionFeeTotal +
                      subscriptionRefundTotal +
                      subscriptionRefundFeeTotal)
                  ).toLocaleString()}
                </td>
                {/* <td>{deductedTotalOfSubscription.toLocaleString()}</td> */}
                <td>{subscriptionCount.toLocaleString()}</td>
                <td>{subscriptionRefundCount.toLocaleString()}</td>
              </tr>
              <tr>
                <td id="category2">都度決済</td>
                <td>{paymentTotal.toLocaleString()}</td>
                <td>{paymentFeeTotal.toLocaleString()}</td>
                <td>{paymentRefundTotal.toLocaleString()}</td>
                <td>{paymentRefundFeeTotal.toLocaleString()}</td>
                <td>
                  {(
                    paymentTotal -
                    (paymentFeeTotal +
                      paymentRefundTotal +
                      paymentRefundFeeTotal)
                  ).toLocaleString()}
                </td>
                {/* <td>{deductedTotalOfPayment.toLocaleString()}</td> */}
                <td>{paymentCount.toLocaleString()}</td>
                <td>{paymentRefundCount.toLocaleString()}</td>
              </tr>
              <tr>
                <td id="category3">合計</td>
                <td>{total.toLocaleString()}</td>
                <td>{totalFee.toLocaleString()}</td>
                <td>{totalRefund.toLocaleString()}</td>
                <td>{totalRefundFee.toLocaleString()}</td>
                <td>
                  {(
                    total -
                    (totalFee + totalRefund + totalRefundFee)
                  ).toLocaleString()}
                </td>
                {/* <td>{totalDeductionFee.toLocaleString()}</td> */}
                <td>{totalCount.toLocaleString()}</td>
                <td>{totalRefundCount.toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
          <p>
            ※現在表示されているデータにおける集計結果です。
            {/* <br />
            ※「決済金額」は入会金控除額を差し引いた後の金額です。 */}
          </p>
        </div>
      );
    }
  };

  // handle
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const toAuth = () => {
    setTimeout(() => {
      return true;
    }, 60000);
  };

  const handleTransaction = (flag) => {
    if (servicePlans.length > 0) {
      if (flag === 'default') {
        setViewData(servicePlans);
      } else if (flag === 'general') {
        const generals = servicePlans.filter((plan) => {
          const member = plan.member[0];
          return member.member_of_general_is;
        });
        setViewData(generals);
      } else if (flag === 'number') {
        const numbers = servicePlans.filter((plan) => {
          const member = plan.member[0];
          return member.member_of_num_is;
        });
        setViewData(numbers);
      } else if (flag === 'card') {
        const cards = servicePlans.filter((plan) => {
          const customer = plan.stripe_customer;
          if (!customer) return [];
          return (
            customer &&
            customer.by_stripe &&
            customer.current_payment_method === 'credit_card'
          );
        });
        setViewData(cards);
      } else if (flag === 'cash') {
        const cash = servicePlans.filter((plan) => {
          const customer = plan.stripe_customer;
          if (!customer) return [];
          return (
            customer &&
            !customer.by_stripe &&
            customer.current_payment_method === 'cash'
          );
        });
        setViewData(cash);
      } else if (flag === 'completed') {
        const completed = servicePlans.filter((plan) => {
          if (!plan) return [];
          return plan && plan.payment_completed;
        });
        setViewData(completed);
      } else if (flag === 'failed') {
        const failed = servicePlans.filter((plan) => {
          if (!plan) return [];
          return plan && plan.payment_status === 'Failed';
        });
        setViewData(failed);
      } else if (flag === 'refund') {
        const refund = servicePlans.filter((plan) => {
          if (!plan) return [];
          return plan && plan.payment_status === 'Cancelled';
        });
        setViewData(refund);
      }
      setCurrentFlag(flag);
    }
  };

  const handleTransactions = (startDatetime, endDatetime) => {
    handleTransaction(currentFlag);
    const startDate = new Date(startDatetime);
    startDate.setHours(0, 0, 0, 0); // 時間を0:00:00に設定
    const endDate = new Date(endDatetime);
    endDate.setHours(23, 59, 59, 999); // 時間を23:59:59.999に設定

    setViewData(
      viewData.filter((plan) => {
        const paymentDate = new Date(plan.created_at);
        return paymentDate >= startDate && paymentDate <= endDate;
      })
    );
  };

  const handleReset = () => {
    setViewData(servicePlans);
  };

  const handleClick = (flag, uuid) => {
    if (flag === 'detail') {
      navigate(various.general.membersPaymentDetail, {
        state: { uuid: uuid, previous: location.pathname },
      });
    } else if (flag === 'allPayments') {
      navigate(various.general.memberAllPayments, {
        state: { uuid: uuid, previous: location.pathname },
      });
    }
  };

  const handleInitialize = () => {
    initializeForStripe(setIsInit);
  };

  useEffect(() => {
    if (isInit) {
      alert('初期化が完了しました。');
    }
  }, [isInit]);

  function handleNextYear() {
    setYearIndex(yearIndex - 1);
    setViewData(
      servicePlans.filter((plan) => {
        const paymentDate = new Date(plan.service_date_time);
        return paymentDate.getFullYear() === yeard[yearIndex - 1];
      })
    );
  }

  function handlePreviousYear() {
    setYearIndex(yearIndex + 1);
    setViewData(
      servicePlans.filter((plan) => {
        const paymentDate = new Date(plan.service_date_time);
        return paymentDate.getFullYear() === yeard[yearIndex + 1];
      })
    );
  }

  const downloadCsv = () => {
    const headersObject = various.paymentHeaders;
    headersObject.member_type = '会員区分';
    const rejectList = [
      'uuid',
      'created_at',
      'member',
      'stripe_customer',
      'product',
      'payment_fee_detail',
      'refund_fee_detail',
      'subscription_change_requests',
      'deducted_amount',
      'is_deducted',
      'seminar',
      'user',
    ];
    const headers = Object.keys(viewData[0]).filter((k) => {
      return !rejectList.includes(k);
    });
    headers.push('member_type');
    const rows = viewData.map((obj, index) => {
      let result = [];
      headers.forEach((h) => {
        let value = `${String(obj[h])
          .replace(/[\r\n]/g, ' ')
          .replace(/[,]/g, '、')
          .replace(/["]/g, '”')}`;

        if (!obj[h] && h !== 'member_type') {
          value = '-';
        } else if (h === 'service_name') {
          value = obj[h] === 'membership' ? '会費' : obj[h];
        } else if (h === 'user') {
          value = obj.member[0].last_name + obj.member[0].first_name;
        } else if (h === 'member_type') {
          const member = obj.member[0];
          value = member['member_of_general_is']
            ? '定例会員'
            : member['member_of_num_is']
            ? '回数会員'
            : member['is_training_group_member']
            ? '稽古会会員'
            : '非会員';
        } else if (
          h === 'service_date_time' ||
          h === 'updated_at' ||
          h === 'refund_created_at'
        ) {
          if (obj[h]) {
            value = new Intl.DateTimeFormat('ja-JP', options).format(
              new Date(obj[h])
            );
          } else {
            value = '-';
          }
        } else if (h === 'payment_id_type') {
          value =
            obj[h] === 'subscription'
              ? '定期決済'
              : obj[h] === 'payment'
              ? '都度決済'
              : 'その他';
        } else if (h === 'payment_status') {
          value =
            obj[h] === 'Active' || obj[h] === 'Completed'
              ? '決済完了'
              : obj[h] === 'Failed'
              ? '決済失敗'
              : '-';
        } else if (h === 'payment_completed') {
          value = obj[h] ? '決済完了' : '決済失敗';
        } else if (h === 'is_cancelled') {
          value = obj[h] ? 'キャンセル' : '-';
        } else if (h === 'refund_status') {
          value =
            obj[h] === 'succeeded'
              ? '返金済み'
              : obj[h] === 'failed'
              ? '返金失敗'
              : '-';
        } else if (h === 'admission_fee') {
          value = obj['is_deducted'] ? obj['deducted_amount'] : '-';
        }
        result.push(value);
      });
      return result;
    });

    const rowHead = [];
    headers.forEach((k) => {
      if (!rejectList.includes(k)) {
        rowHead.push(headersObject[k]);
      }
    });

    rows.unshift(rowHead);

    let csvString = '';
    for (let d of rows) {
      csvString += d.join(',');
      csvString += '\r\n';
    }

    const filename = 'payment.csv';
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csvString], { type: 'text/csv' });

    //IE10/11用(download属性が機能しないためmsSaveBlobを使用）
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, filename);
      //その他ブラウザ
    } else {
      const url = (window.URL || window.webkitURL).createObjectURL(blob);
      const download = document.createElement('a');
      download.href = url;
      download.download = filename;
      download.click();
      (window.URL || window.webkitURL).revokeObjectURL(url);
    }
  };

  //   Stripe
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        setShowInit(false);
      } else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
        setShowInit(true);
      }
    } else if (process.env.NODE_ENV === 'development') {
      setShowInit(true);
    }
  }, []);

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {isAuth && members.length > 0 && aggregate ? (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">決済情報一覧</span>
              <span>{date}</span>
            </div>
            <div>
              <span>定例会員決済件数</span>
              <span>{numOfGeneralPaid}件</span>
              <span>回数会員決済件数</span>
              <span>{numOfNumPaid}件</span>
              <span>自動決済総数</span>
              <span>{numOfGeneralPaid + numOfNumPaid}件</span>
              <br />
              <span>想定現金決済人数</span>
              <span>{numOfCash}名</span>
              <span>総会員数</span>
              <span>{numOfAllMembers}名</span>
            </div>
          </div>
          {loading ? <Spinner /> : aggregate}
          <BreadCrumbs crumbs={CRUMBS} />
          <hr />
          <div className={classes.viewDataBtnContainer}>
            <div id="viewBtn1">
              <span>絞り込み&nbsp;:&nbsp;</span>
            </div>
            <div id="viewBtn2">
              <select
                onChange={(e) => handleTransaction(e.target.value)}
                defaultValue="default"
              >
                <option value="default">選択してください</option>
                <option value="general">定例会員</option>
                <option value="number">回数会員</option>
                <option value="card">カード決済</option>
                <option value="cash">現金決済</option>
                <option value="completed">決済完了</option>
                <option value="failed">決済失敗</option>
                <option value="refund">返金決済</option>
              </select>
            </div>
          </div>
          <div className={classes.viewDataBtnContainer}>
            <div id="viewBtn3">
              <span>期間指定&nbsp;:&nbsp;</span>
            </div>
            <div id="viewBtn4">
              <form
                onSubmit={(e) => {
                  handleTransactions(e.target.start.value, e.target.end.value);
                  e.preventDefault();
                }}
              >
                <input
                  type="date"
                  id="start"
                  name="start"
                  value={firstDay}
                  onChange={(e) => setFirstDay(e.target.value)}
                />
                <input
                  type="date"
                  id="end"
                  name="end"
                  value={lastDay}
                  onChange={(e) => setLastDay(e.target.value)}
                />
                <button type="submit">期間絞り込み</button>
              </form>
            </div>
          </div>
          <div className={classes.viewBtn5}>
            <button onClick={handleReset}>絞り込みリセット</button>
          </div>
          {permissions.includes('admin') ? (
            <div className={classes.downLoadBtnContainer}>
              <div id="dlBtn1">
                <span>DownLoad&nbsp;:&nbsp;</span>
              </div>
              <div id="dlBtn2">
                <button id="dlbtn1" onClick={() => downloadCsv()}>
                  <img src={imageIndex.CloudDownloadIcon} alt="dlicon" />
                </button>
              </div>
            </div>
          ) : (
            <div className={classes.downLoadBtnContainer}></div>
          )}

          {loading ? (
            <Spinner />
          ) : (
            <>
              {yeard ? (
                <div className={classes.yeard}>
                  {yearIndex < yeard.length - 1 && (
                    <button onClick={handlePreviousYear}>
                      {'< ' + yeard[yearIndex + 1]}
                    </button>
                  )}
                  <span>{yeard[yearIndex]}年度</span>
                  {yearIndex > 0 && (
                    <button onClick={handleNextYear}>
                      {yeard[yearIndex - 1] + ' >'}
                    </button>
                  )}
                </div>
              ) : null}
              <Box
                className={classes.dataGrid}
                sx={{
                  '& .bold': {
                    fontWeight: 'bold',
                  },
                  '& .green': {
                    color: 'green',
                  },
                  '& .brown': {
                    color: 'brown',
                  },
                  '& .red': {
                    color: 'red',
                  },
                  '& .blue': {
                    color: 'blue',
                  },
                }}
              >
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={PAGESIZE}
                  rowsPerPageOptions={[PAGESIZE]}
                  density="comfortable"
                  columnVisibilityModel={{ uuid: false, email: false }}
                  pagination={true}
                  autoPageSize={true}
                  rowHeight={ROWHEIGHT}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                  localeText={
                    jaJP.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              </Box>
              {showInit ? (
                <div style={{ textAlign: 'start', marginTop: '64px' }}>
                  <hr />
                  <button
                    type="button"
                    style={{ cursor: 'pointer' }}
                    onClick={handleInitialize}
                  >
                    初期化
                  </button>
                  <p style={{ fontSize: '0.8rem', color: 'grey' }}>
                    ※「初期化ボタン」を押下すると全ての会員が回数会員になります。
                  </p>
                </div>
              ) : null}
            </>
          )}
        </>
      ) : (
        <>
          {toAuth() ? (
            <div>
              <span
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={handleAuth}
              >
                ログイン
              </span>
              してください。
            </div>
          ) : (
            <Spinner />
          )}
        </>
      )}
      <Footer />
    </>
  );
};
export default MembersPayment;
