import { Box, makeStyles } from '@material-ui/core';
import { DataGrid, jaJP } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import { seminarIndexForAdmin, myPage } from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import { localStorageArrayHandler } from '../../../CommonFunction/managePermission';
import Footer from '../../../Footer/footer';
import various from '../../../General/various';
import imageIndex from '../../../img/imageIndex';
import { Spinner } from '../../../Spinner';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
    '& > p': {
      fontSize: '0.8rem',
      textAlign: 'start',
      color: 'grey',
    },
  },
  dataGrid: {
    height: (props) =>
      props.heightSize ? props.heightSize : props.pageSize * props.rowHeight,
    width: '100%',
    '& .MuiDataGrid-main': {
      fontSize: '0.9rem',
      fontFamily: theme.typography.fontFamily,
    },
  },
  createBtnContainer: {
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',
    '@media screen and (max-width:600px)': {
      flexWrap: 'wrap',
      gap: '0px 16px',
    },
    '& > div': {
      margin: 'auto 0',
      '& > span': {
        display: 'inline-block',
      },
      '& > button': {
        display: 'inline-block',
        fontFamily: theme.typography.fontFamily,
        fontSize: '0.9rem',
        fontWeight: 'bold',
        borderRadius: '0.5em',
        border: 'none',
        padding: '4px 8px',
        height: '30px',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        cursor: 'pointer',
        '@media screen and (max-width:600px)': {
          display: 'block',
          width: '114px',
          overflow: 'hidden',
        },
      },
      '& #createbtn1': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
      '& #createbtn2': {
        backgroundColor: 'rgba(124,42,46,0.8)',
        color: '#fff',
      },
    },
    '& #createBtn1': {
      width: '20%',
      fontSize: '0.9rem',
      textAlign: 'end',
      '@media screen and (max-width:600px)': {
        width: '114px',
        textAlign: 'start',
      },
    },
    '& #createBtn2': {
      width: '80%',
      display: 'flex',
      gap: '0px 16px',
      textAlign: 'start',
      '@media screen and (max-width:600px)': {
        width: '100%',
      },
    },
  },
  viewDataBtnContainer: {
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',
    '@media screen and (max-width:600px)': {
      flexWrap: 'wrap',
      gap: '0px 16px',
    },
    '& > div': {
      margin: 'auto 0',
      '& > span': {
        display: 'inline-block',
      },
      '& > button': {
        display: 'inline-block',
        fontFamily: theme.typography.fontFamily,
        fontSize: '0.9rem',
        fontWeight: 'bold',
        borderRadius: '0.5em',
        border: 'none',
        padding: '0px 8px',
        height: '30px',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        cursor: 'pointer',
        '@media screen and (max-width:600px)': {
          display: 'block',
          width: '114px',
          overflow: 'hidden',
        },
      },
      '& #btn1': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
      '& #btn2': {
        backgroundColor: 'rgba(124,42,46,0.8)',
        color: '#fff',
      },
      '& #btn4': {
        backgroundColor: 'rgba(124,42,46,0.5)',
        color: '#000',
      },
      '& #btn3': {
        backgroundColor: 'rgba(124,42,46,0.3)',
        color: '#000',
      },
    },
    '& #viewBtn1': {
      width: '20%',
      fontSize: '0.9rem',
      textAlign: 'end',
      '@media screen and (max-width:600px)': {
        width: '114px',
        textAlign: 'start',
      },
    },
    '& #viewBtn2': {
      width: '80%',
      display: 'flex',
      gap: '0px 16px',
      textAlign: 'start',
      '@media screen and (max-width:600px)': {
        width: '100%',
      },
    },
  },
  downLoadBtnContainer: {
    marginBottom: '80px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',

    '@media screen and (max-width:600px)': {
      marginBottom: '0px',
    },
    '& > div': {
      width: '20%',
      margin: 'auto 0',
      // cursor: 'not-allowed', // downloadが機能したら削除
      '& > span': {
        display: 'inline-block',
      },
      '& > button': {
        display: 'inline-block',
        border: 'none',
        padding: '4px 8px',
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer', // downloadが機能したら復活
        // pointerEvents: 'none', // downloadが機能したら削除
      },
    },
    '& #dlBtn1': {
      fontSize: '0.9rem',
      textAlign: 'end',
      '@media screen and (max-width:600px)': {
        width: '114px',
      },
    },
    '& #dlBtn2': {
      textAlign: 'start',
    },
  },
  distributionBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      backgroundColor: 'green',
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
  previewBtn: {
    '& > a': {
      '& > button': {
        display: 'block',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        borderRadius: '0.5em',
        border: 'none',
        padding: '4px 8px',
        borderColor: theme.palette.primary.main,
        backgroundColor: 'green',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        color: '#fff',
        cursor: 'pointer',
      },
    },
  },
  distributionBtnConfirm: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      backgroundColor: 'grey',
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'not-allowed',
    },
  },
  detailBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  updateBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
  handoutsBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: '#deb068',
      backgroundColor: '#deb068',
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
  remindmailBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: '#8d6449',
      backgroundColor: '#8d6449',
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
  createMemberBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
}));

const SeminarIndex = () => {
  const { isAuth } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [heightSize, setHeightSize] = useState();
  const [date, setDate] = useState();
  const [seminarNum, setSeminarNum] = useState();
  const [nonSeminarNum, setNonSeminarNum] = useState();
  const [today, setToday] = useState();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  const permissions = localStorageArrayHandler('parse', various.permissionKey);

  const ROWHEIGHT = 80;
  const PAGESIZE = 30;
  const classes = useStyles({
    pageSize: PAGESIZE,
    rowHeight: ROWHEIGHT,
    heightSize: heightSize,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: 'セミナー一覧', path: '/seminar_index' },
  ];

  // if (viewData) console.log('viewData:', viewData);

  // Init
  useEffect(() => {
    async function fetchData(user) {
      const permissions = localStorageArrayHandler(
        'parse',
        various.permissionKey
      );
      await seminarIndexForAdmin(setData, user, permissions);
    }
    if (user) {
      fetchData(user);
    }
  }, [user]);

  useEffect(() => {
    setToday(new Date());
    myPage(setUser);
  }, []);

  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const seminars = data.map((d) => {
        const date = new Date(d.delivery_date);
        return d.release_is && today > date;
      });
      setSeminarNum(seminars.filter(Boolean).length);
      setNonSeminarNum(data.length - seminars.filter(Boolean).length);
    }
  }, [data, today]);

  useEffect(() => {
    if (viewData) {
      const size = viewData.length < PAGESIZE ? viewData.length : PAGESIZE;
      setHeightSize(size * ROWHEIGHT * 1.4 + 122);
    }
  }, [viewData]);

  useEffect(() => {
    if (data) {
      setViewData(data);
    }
  }, [data]);

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleClick = (flag, uuid) => {
    if (flag === 'update') {
      navigate(various.general.seminarUpdateForAdmin, {
        state: { uuid: uuid, previous: location.pathname },
      });
    } else if (flag === 'create') {
      navigate(various.general.seminarCreateForAdmin, {
        state: { previous: location.pathname },
      });
    } else if (flag === 'detail') {
      navigate(various.general.seminarDetailForAdmin, {
        state: { uuid: uuid, previous: location.pathname },
      });
    } else if (flag === 'distribution') {
      navigate(various.general.seminarDetailConfirm, {
        state: { uuid: uuid, previous: location.pathname },
      });
    } else if (flag === 'situation') {
      navigate(various.general.seminarApplySituation, {
        state: { uuid: uuid, previous: location.pathname },
      });
    } else if (flag === 'remind') {
      navigate(various.general.seminarRemindMail, {
        state: { uuid: uuid, previous: location.pathname },
      });
    } else if (flag === 'handouts') {
      navigate(various.general.documentCreate, {
        state: { uuid: uuid, previous: location.pathname },
      });
    }
  };

  const handleSeminar = (flag) => {
    if (data.length > 0) {
      const allSeminar = [...data];
      if (flag === 'all') {
        return setViewData(allSeminar);
      } else if (flag === 'positive') {
        const result = [];
        allSeminar.forEach((seminar) => {
          const date = new Date(seminar.delivery_date);
          if (seminar.release_is && today > date) {
            result.push(seminar);
          }
        });
        return setViewData(result);
      } else if (flag === 'participant') {
        const result = [];
        allSeminar.forEach((seminar) => {
          const date = new Date(seminar.delivery_date);
          if (!seminar.release_is || today < date) {
            result.push(seminar);
          }
        });
        return setViewData(result);
      }
    }
  };

  const downloadCsv = () => {
    const headersObject = various.seminarHeaders;
    const rejectList = [
      'uuid',
      'sub_title',
      'organizer',
      'image',
      'image_url',
      'created_at',
      'wallpaper',
    ];
    const headers = Object.keys(viewData[0]).filter((k) => {
      return !rejectList.includes(k);
    });
    const rows = viewData.map((obj) => {
      let result = [];
      headers.forEach((h) => {
        let value = `${String(obj[h])
          .replace(/[\r\n]/g, ' ')
          .replace(/[,]/g, '、')
          .replace(/["]/g, '”')}`;

        if (h === 'organizer') {
          value = obj[h].email;
        } else if (h === 'instructor') {
          value = obj[h].name;
        } else if (h === 'place') {
          value = obj[h].facility_name;
        } else if (h === 'hall') {
          value = obj[h].name;
        } else if (h === 'news') {
          if (obj[h].title !== 'InitialRecord') {
            value = obj[h].title;
          } else {
            value = '-';
          }
        }
        result.push(value);
      });
      return result;
    });

    const rowHead = [];
    headers.forEach((k) => {
      if (!rejectList.includes(k)) {
        rowHead.push(headersObject[k]);
      }
    });

    rows.unshift(rowHead);

    let csvString = '';
    for (let d of rows) {
      csvString += d.join(',');
      csvString += '\r\n';
    }

    const filename = 'seminar_index.csv';
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csvString], { type: 'text/csv' });

    //IE10/11用(download属性が機能しないためmsSaveBlobを使用）
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, filename);
      //その他ブラウザ
    } else {
      const url = (window.URL || window.webkitURL).createObjectURL(blob);
      const download = document.createElement('a');
      download.href = url;
      download.download = filename;
      download.click();
      (window.URL || window.webkitURL).revokeObjectURL(url);
    }
  };

  // Contents
  useEffect(() => {
    const headers = [
      {
        field: 'id',
        headerName: 'ID',
        width: 10,
      },
      {
        field: 'uuid',
      },
      {
        field: 'month',
        headerName: '開催月',
        cellClassName: (params) =>
          params.value === '1月'
            ? 'indigo'
            : params.value === '2月'
            ? 'darkgray'
            : params.value === '3月'
            ? 'coral'
            : params.value === '4月'
            ? 'cherry-blossom-pink'
            : params.value === '5月'
            ? 'olivedrab'
            : params.value === '6月'
            ? 'darkturquoise'
            : params.value === '7月'
            ? 'royalblue'
            : params.value === '8月'
            ? 'orange'
            : params.value === '9月'
            ? 'firebrick'
            : params.value === '10月'
            ? 'saddlebrown'
            : params.value === '11月'
            ? 'violet'
            : params.value === '12月'
            ? 'midnightblue'
            : 'black',
        width: 60,
      },
      {
        field: 'title',
        headerName: 'タイトル',
        cellClassName: 'title',
        width: 240,
      },
      {
        field: 'instructor',
        headerName: '講師',
        cellClassName: 'instructor',
        width: 100,
      },
      {
        field: 'subject',
        headerName: '対象',
        cellClassName: (params) =>
          params.value === '会員' ? 'green' : 'brown',
        width: 80,
      },
      {
        field: 'event_date_start_time',
        headerName: '開催日時',
        width: 150,
      },
      {
        field: 'capacity',
        headerName: '許容人数',
        width: 80,
      },
      {
        field: 'place',
        headerName: '会場',
        width: 150,
      },
      {
        field: 'paymentMethod',
        headerName: '支払方法',
        cellClassName: (params) =>
          params.value === '全支払方法OK'
            ? 'green'
            : params.value === '現金のみ'
            ? 'red'
            : 'blue',
        width: 150,
      },
      {
        field: 'delivery_date',
        headerName: '公開予定日',
        width: 150,
      },
      {
        field: 'release_is',
        headerName: 'Status',
        width: 100,
        cellClassName: (params) =>
          params.value === '公開済み' ? 'green' : 'red',
      },
      {
        field: 'distribution_is',
        headerName: '配信',
        width: 60,
        cellClassName: (params) => (params.value === '有り' ? 'green' : 'red'),
      },
    ];

    const permissions = localStorageArrayHandler(
      'parse',
      various.permissionKey
    );
    const operationBtn = [
      {
        field: 'distributionBtn',
        headerName: '公開',
        sortable: false,
        width: 60,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <>
            {params.row.release_is === '公開済み' ||
            params.row.release_is === '公開予定' ||
            !permissions.includes('admin') ? (
              <div className={classes.distributionBtnConfirm}>
                <button>公開</button>
              </div>
            ) : (
              <div className={classes.distributionBtn}>
                <button
                  onClick={() => handleClick('distribution', params.row.uuid)}
                >
                  公開
                </button>
              </div>
            )}
          </>
        ),
      },
      {
        field: 'detailBtn',
        headerName: '詳細',
        sortable: false,
        width: 60,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <div className={classes.detailBtn}>
            <button onClick={() => handleClick('detail', params.row.uuid)}>
              詳細
            </button>
          </div>
        ),
      },
      {
        field: 'updateBtn',
        headerName: '更新',
        sortable: false,
        width: 60,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <>
            {permissions.includes('admin') ||
            permissions.includes('instructor') ||
            permissions.includes('organizer') ? (
              <div className={classes.updateBtn}>
                <button onClick={() => handleClick('update', params.row.uuid)}>
                  修正
                </button>
              </div>
            ) : (
              <div className={classes.distributionBtnConfirm}>
                <button>修正</button>
              </div>
            )}
          </>
        ),
      },
      {
        field: 'handoutsBtn',
        headerName: '資料',
        sortable: false,
        width: 60,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <>
            {permissions.includes('admin') ||
            permissions.includes('instructor') ||
            permissions.includes('organizer') ? (
              <div className={classes.handoutsBtn}>
                <button
                  onClick={() => handleClick('handouts', params.row.uuid)}
                >
                  登録
                </button>
              </div>
            ) : (
              <div className={classes.distributionBtnConfirm}>
                <button>登録</button>
              </div>
            )}
          </>
        ),
      },
      {
        field: 'remindmailBtn',
        headerName: 'RM',
        sortable: false,
        width: 60,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <>
            {permissions.includes('admin') ||
            permissions.includes('instructor') ||
            permissions.includes('organizer') ? (
              <div className={classes.remindmailBtn}>
                <button onClick={() => handleClick('remind', params.row.uuid)}>
                  送信
                </button>
              </div>
            ) : (
              <div className={classes.distributionBtnConfirm}>
                <button>RM</button>
              </div>
            )}
          </>
        ),
      },

      {
        field: 'preview',
        headerName: 'プレビュー',
        sortable: false,
        width: 90,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <div className={classes.previewBtn}>
            <a href={`${various.general.seminarPreview}/${params.row.uuid}`}>
              <button>Preview</button>
            </a>
          </div>
        ),
      },
    ];
    const situationBtnObject = {
      field: 'situation',
      headerName: '申込状況',
      sortable: false,
      width: 80,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <>
          <div className={classes.distributionBtn}>
            <button onClick={() => handleClick('situation', params.row.uuid)}>
              参加者
            </button>
          </div>
        </>
      ),
    };

    const columnsDataset = headers.concat(operationBtn);
    columnsDataset.splice(4, 0, situationBtnObject);
    setColumns(columnsDataset);

    setRows(
      viewData.map((d, index) => {
        const result = {};
        result['id'] = index + 1;
        result['uuid'] = d['uuid'];
        result['instructor'] = d['instructor'].name;
        result['subject'] = d['subject'];
        result['capacity'] = d['capacity'] + '名';
        result['place'] = d['place'].facility_name;
        if (d['title']) {
          result['title'] =
            d['title'] + (d['serial_number'] ? ' #' + d['serial_number'] : '');
        }

        if (d['event_date_start_time']) {
          const date = new Date(d['event_date_start_time']);
          result['month'] = date.getMonth() + 1 + '月';
          result['event_date_start_time'] =
            date.toLocaleDateString() +
            ' ' +
            date.getHours() +
            ':' +
            date.getMinutes().toString().padStart(2, '0') +
            ' ~';
        } else {
          result['event_date_start_time'] = '-';
        }

        if (d['delivery_date']) {
          const date = new Date(d['delivery_date']);
          result['delivery_date'] =
            date.toLocaleDateString() +
            ' ' +
            date.getHours() +
            ':' +
            date.getMinutes().toString().padStart(2, '0');
        } else {
          result['delivery_date'] = '-';
        }

        if (d['release_is']) {
          if (d['delivery_date']) {
            const date = new Date(d['delivery_date']);
            const today = new Date();
            if (date < today) {
              result['release_is'] = '公開済み';
            } else {
              result['release_is'] = '公開予定';
            }
          }
        } else {
          result['release_is'] = '公開未定';
        }

        if (d['distribution_is']) {
          result['distribution_is'] = '有り';
        } else {
          result['distribution_is'] = '無し';
        }

        if (d['payment_method']) {
          const payment = d['payment_method'];
          result['paymentMethod'] = payment.is_mixed
            ? '全支払方法OK'
            : payment.is_only_cash
            ? '現金のみ'
            : payment.is_only_credit_card
            ? 'クレジットのみ'
            : '';
        } else {
          result['paymentMethod'] = '全支払方法OK';
        }

        return result;
      })
    );
    setLoading(false);
    // setTimeout(() => {
    // }, 3000);
    // eslint-disable-next-line
  }, [viewData]);

  // DataGridのmonthのための基盤スタイル
  const commonStyle = {
    fontStyle: 'italic',
    fontSize: '1.2rem',
    fontFamily:
      '"Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif',
    fontWeight: 'bold',
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {isAuth ? (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">セミナー一覧</span>
              <span>{date}</span>
            </div>
            <div>
              <span>公開数</span>
              <span>{seminarNum}件</span>
              <span>未公開数</span>
              <span>{nonSeminarNum}件</span>
              <span>総登録数</span>
              <span>{seminarNum + nonSeminarNum}件</span>
            </div>
            <p>
              ※本サイトでの申込開始が2023年2月以降のデータとなるため、「申込状況」内のデータもそれ以降のものとなります。
            </p>
          </div>
          <BreadCrumbs crumbs={CRUMBS} />
          <hr />
          {permissions.includes('admin') ||
          permissions.includes('organizer') ? (
            <div className={classes.createBtnContainer}>
              <div id="createBtn1">
                <span>新規登録&nbsp;:&nbsp;</span>
              </div>
              <div id="createBtn2">
                <button id="createbtn1" onClick={() => handleClick('create')}>
                  登録
                </button>
              </div>
            </div>
          ) : undefined}
          <div className={classes.viewDataBtnContainer}>
            <div id="viewBtn1">
              <span>絞り込み&nbsp;:&nbsp;</span>
            </div>
            <div id="viewBtn2">
              <button id="btn1" onClick={() => handleSeminar('all')}>
                すべて
              </button>
              <button id="btn2" onClick={() => handleSeminar('positive')}>
                公開済
              </button>
              <button id="btn4" onClick={() => handleSeminar('participant')}>
                未公開
              </button>
            </div>
          </div>
          {permissions.includes('admin') ||
          permissions.includes('instructor') ||
          permissions.includes('organizer') ? (
            <div className={classes.downLoadBtnContainer}>
              <div id="dlBtn1">
                <span>DownLoad&nbsp;:&nbsp;</span>
              </div>
              <div id="dlBtn2">
                <button id="dlbtn1" onClick={() => downloadCsv()}>
                  <img src={imageIndex.CloudDownloadIcon} alt="dlicon" />
                </button>
              </div>
            </div>
          ) : (
            <div className={classes.downLoadBtnContainer}></div>
          )}

          {loading ? (
            <Spinner />
          ) : (
            <Box
              className={classes.dataGrid}
              sx={{
                '& .title': {
                  ...commonStyle,
                  fontSize: '1rem',
                },
                '& .indigo': {
                  ...commonStyle,
                  color: 'indigo',
                },
                '& .darkgray': {
                  ...commonStyle,
                  color: 'darkgray',
                },
                '& .coral': {
                  ...commonStyle,
                  color: 'coral',
                },
                '& .cherry-blossom-pink': {
                  ...commonStyle,
                  color: 'hotpink',
                },
                '& .olivedrab': {
                  ...commonStyle,
                  color: 'olivedrab',
                },
                '& .darkturquoise': {
                  ...commonStyle,
                  color: 'darkturquoise',
                },
                '& .royalblue': {
                  ...commonStyle,
                  color: 'royalblue',
                },
                '& .orange': {
                  ...commonStyle,
                  color: 'orange',
                },
                '& .firebrick': {
                  ...commonStyle,
                  color: 'firebrick',
                },
                '& .saddlebrown': {
                  ...commonStyle,
                  color: 'saddlebrown',
                },
                '& .violet': {
                  ...commonStyle,
                  color: 'violet',
                },
                '& .midnightblue': {
                  ...commonStyle,
                  color: 'midnightblue',
                },
                '& .instructor': {
                  fontWeight: 'bold',
                },
                '& .green': {
                  color: 'green',
                },
                '& .brown': {
                  color: 'brown',
                },
                '& .red': {
                  color: 'red',
                },
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={PAGESIZE}
                rowsPerPageOptions={[PAGESIZE]}
                density="comfortable"
                columnVisibilityModel={{ uuid: false }}
                pagination={true}
                autoPageSize={true}
                rowHeight={ROWHEIGHT}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              />
            </Box>
          )}
        </>
      ) : loading ? (
        <Spinner />
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
      <Footer />
    </>
  );
};
export default SeminarIndex;
