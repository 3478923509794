import ogpItem from './ogpItem.json';

export const ogpGenerator = (props) => {
  const protocol = window.location.protocol;
  // const domain = document.domain;
  const host = window.location.host;
  const item = ogpItem[props.page];

  const title = props.pageTitle;
  const url = protocol + '//' + host + props.path;
  const image = props.imageUrl;
  const type = item.type;
  const description = item.description;

  const headData = document.head.children;
  for (let i = 0; i < headData.length; i++) {
    // OGPの設定
    const propertyVal = headData[i].getAttribute('property');
    if (propertyVal !== null) {
      // og:url
      if (propertyVal.indexOf('og:url') !== -1) {
        headData[i].setAttribute('content', url);
      }
      //   og:type
      if (propertyVal.indexOf('og:type') !== -1) {
        headData[i].setAttribute('content', type);
      }
      //   og:title
      if (propertyVal.indexOf('og:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      // og:description
      if (propertyVal.indexOf('og:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      //   og:image
      if (propertyVal.indexOf('og:image') !== -1) {
        headData[i].setAttribute('content', image);
      }
    }
    // OGP(twitter)とPrerender.io向けの設定
    const nameVal = headData[i].getAttribute('name');
    if (nameVal !== null) {
      if (nameVal.indexOf('twitter:title') !== -1) {
        headData[i].setAttribute('content', title);
      }
      if (nameVal.indexOf('twitter:url') !== -1) {
        headData[i].setAttribute('content', url);
      }
      if (nameVal.indexOf('twitter:description') !== -1) {
        headData[i].setAttribute('content', description);
      }
      if (nameVal.indexOf('twitter:image') !== -1) {
        headData[i].setAttribute('content', image);
      }
      if (nameVal.indexOf('prerender-status-code') !== -1) {
        headData[i].setAttribute('content', '200');
      }
    }
  }
};
