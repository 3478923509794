import React from 'react';
import { Routes, Route } from 'react-router-dom';
import various from './General/various';

const RoutingComponents = () => {
  const urls = various.routingUrls;
  const result = [];

  urls.forEach((url, index) => {
    const children = [];
    if (!url.children) {
      result.push(
        <Route key={index} path={url.path} element={url.component} />
      );
    } else {
      url.children.forEach((child_url, i) => {
        const grandChildren = [];
        if (!child_url.children) {
          children.push(
            <Route
              key={i}
              path={child_url.path}
              element={child_url.component}
            />
          );
        } else {
          child_url.children.forEach((g_child_url, _i) => {
            grandChildren.push(
              <Route
                key={_i}
                path={g_child_url.path}
                element={g_child_url.component}
              />
            );
          });
          children.push(
            <Route key={i} path={child_url.path} element={child_url.component}>
              {grandChildren}
            </Route>
          );
        }
      });
      result.push(
        <Route key={index} path={url.path} element={url.component}>
          {children}
        </Route>
      );
    }
  });

  return <Routes>{result}</Routes>;
};
export default RoutingComponents;
