// import TouzenLogoSVG from './logo_transparent.svg';
import TouzenHeaderLogoSVG from './bodywork_logo.svg';
// import TouzenLogoSVG from './bodywork_logo_white.svg';
import TouzenLogoSVG from './bodywork_logo_glay_2.svg';
// import TouzenLogoSVG from './bodywork_logo_w20.svg';
import { ReactComponent as arrowIcon } from './arrow.svg';
import checkIcon from './check.svg';
import facebookIcon from './sns_fb.svg';
import GreenCheckIcon from './check_icon_green.svg';
import MapPing from './map_ping.svg';
import recommendedIcon from './recommended.svg';

import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CloudDownloadIcon from './cloud_download.svg';

// HumMenuImages
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import InfoIcon from '@material-ui/icons/Info';
import SchoolIcon from '@material-ui/icons/School';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import MailIcon from '@material-ui/icons/Mail';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import TheatersIcon from '@material-ui/icons/Theaters';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';

// BranchPageImages
import webIcon from './BranchPage/Web.svg';
import fbIcon from './BranchPage/Facebook.svg';
import instagramIcon from './BranchPage/Instagram.svg';
import twIcon from './BranchPage/Twitter.svg';
import youtubeIcon from './BranchPage/YouTube.svg';
import BookIcon from '@material-ui/icons/Book';
import ScheduleIcon from '@material-ui/icons/Schedule';

// LogInPageImages
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';

// SeminarPageImages
import downArrow from './SeminarPage/downArrow.svg';
import decoLineTop from './SeminarPage/decoLineTop.svg';
import decoLineBottom from './SeminarPage/decoLineBottom.svg';
import WarningIcon from '@material-ui/icons/Warning';

// MemberPageImages
import GroupIcon from '@material-ui/icons/Group';

// BooksPageImages
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import YowanLogo from './yowan_logo.svg';

const imageIndex = {
  logo_svg: TouzenLogoSVG,
  header_svg: TouzenHeaderLogoSVG,
  facebook: facebookIcon,
  facebook_mui: FacebookIcon,
  twitter: TwitterIcon,
  instagram: InstagramIcon,
  arrowIcon: arrowIcon,
  ArrowUpwardIcon: ArrowUpwardIcon,
  checkIcon: checkIcon,
  GreenCheckIcon: GreenCheckIcon,
  MapPing: MapPing,
  CloudDownloadIcon: CloudDownloadIcon,
  recommendedIcon: recommendedIcon,
  humMenu: {
    home: HomeRoundedIcon,
    about: InfoIcon,
    seminar: SchoolIcon,
    branch: AcUnitIcon,
    contact: MailIcon,
    calendar: CalendarTodayIcon,
    media: TheatersIcon,
    books: ImportContactsIcon,
    news: AnnouncementIcon,
    login: AccountCircleIcon,
    logout: ExitToAppIcon,
    review: ExitToAppIcon,
    authtest: ExitToAppIcon,
    imageupdate: ExitToAppIcon,
    mypage: AccountCircleIcon,
    memberindex: GroupIcon,
    memberbulkcreate: GroupIcon,
    newsindex: AnnouncementIcon,
    seminarindex: SchoolIcon,
    documentindex: SchoolIcon,
    managedindex: SettingsIcon,
  },
  branchPage: {
    webIcon: webIcon,
    blogIcon: BookIcon,
    fbIcon: fbIcon,
    instagramIcon: instagramIcon,
    twIcon: twIcon,
    youtubeIcon: youtubeIcon,
    scheduleIcon: ScheduleIcon,
  },
  loginPage: {
    accountIcon: AccountCircleIcon,
    passwordIcon: LockIcon,
  },
  newsPage: {
    detail: {
      fbIcon: fbIcon,
      twIcon: twIcon,
    },
  },
  mediaPage: {
    fbIcon: fbIcon,
  },
  semianrPage: {
    downArrow: downArrow,
    detail: {
      decoLineTop: decoLineTop,
      decoLineBottom: decoLineBottom,
      warning: WarningIcon,
      fbIcon: fbIcon,
      twIcon: twIcon,
    },
  },
  booksPage: {
    doubleArrow: DoubleArrowIcon,
    yowanLogo: YowanLogo,
  },
};

export default imageIndex;
