import { makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import { AuthContext } from '../../../auth';
import { fileSizeObserver } from '../../../CommonFunction/imageManagement';
import { createInstance } from '../../../Apis/apiHandlers';
import various from '../../../General/various';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  titles: {},
  inputs: {},
  container: {
    marginTop: '168px',
    width: '100%',
    '& > form': {
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          '& > label': {
            width: '100%',
            textAlign: 'end',
            '@media screen and (max-width:750px)': {
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
        },

        '& $inputs': {
          width: '70%',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input, & > textarea': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
          '& #image': {
            boxShadow: 'none',
            border: 'none',
          },
          '& #bool-input': {
            boxShadow: 'none',
          },
          '& > textarea': {
            height: '5em',
          },
          '& > img': {
            width: '100%',
            objectFit: 'contain',
            maxHeight: '300px',
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    marginTop: '88px !important',
    justifyContent: 'end',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
    },
  },

  error: {},
}));

const Create = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const { isAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const onSubmit = handleSubmit((data) => {
    const imageFileKey = 'image';
    createInstance('AuthTest', data, imageFileKey);
    setTimeout(() => {
      console.log('create finish!');
      navigate(location.state.previous);
      window.location.reload(true);
    }, 2000);
  });

  const LIMITSIZE = 1.5;
  const form = () => {
    // 参考　https://reffect.co.jp/react/react-hook-form#i-4
    return (
      <div className={classes.container}>
        <form method="POST" encType="multipart/form-data" onSubmit={onSubmit}>
          <div>
            <div className={classes.titles}>
              <label htmlFor="title">{'title'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="title"
                {...register('title', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.title?.message && (
                <div className={classes.error}>{errors.title.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="number">{'number'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="number"
                type="number"
                {...register('number', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.number?.message && (
                <div className={classes.error}>{errors.number.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="datetime">{'datetime'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="datetime"
                type="datetime-local"
                {...register('datetime', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.datetime?.message && (
                <div className={classes.error}>{errors.datetime.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="image">{'Image'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="image"
                type="file"
                accept="image/*,.pdf"
                {...register('image', {
                  required: { value: true, message: '入力必須の項目です' },
                  validate: {
                    message: (v) =>
                      fileSizeObserver(v, LIMITSIZE)
                        ? null
                        : `イメージファイルは${LIMITSIZE}MB以下にしてください`,
                  },
                })}
              />
              {errors.image?.message && (
                <div className={classes.error}>{errors.image.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="bool">{'bool'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="bool-input"
                type="checkbox"
                {...register('bool', {
                  required: { value: false, message: '入力必須の項目です' },
                })}
              />
              {errors.bool?.message && (
                <div className={classes.error}>{errors.bool.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="char">{'char'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="char"
                type="text"
                {...register('char', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.char?.message && (
                <div className={classes.error}>{errors.char.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="text">{'text'}</label>
            </div>
            <div className={classes.inputs}>
              <textarea
                id="text"
                type="text"
                {...register('text', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.text?.message && (
                <div className={classes.error}>{errors.text.message}</div>
              )}
            </div>
          </div>
          <div className={classes.button}>
            <button type="submit">登録</button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>AuthTest新規登録</div>
      <hr />
      {isAuth ? (
        form()
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default Create;
