import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import OnOffByScroll from '../Animation/onOffByScroll';
import ArrowButton from '../General/arrowButton';
import ContentTitle from '../General/contentTitle';
import various from '../General/various';
import {
  emailRegValidator,
  emailValidator,
} from '../CommonFunction/validators';
import { contact } from '../Apis/apis';
import Footer from '../Footer/footer';
import { images as imageApi } from '../Apis/apis';
import { ogpGenerator } from '../ogp';

const useStyles = makeStyles((theme) => ({
  rootContainer: {},
  contentTitle: {
    marginTop: '168px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginLeft: '32px',
    marginTop: '168px',
    fontSize: '1rem',
    '& > p': {
      width: '45%',
      textAlign: 'start',
      textIndent: '1rem',
      marginRight: '56px',
      marginTop: 0,

      '& > a': {
        color: 'black',
        textDecoration: 'underline',
      },

      '@media screen and (max-width:1200px)': {
        width: '100%',
        marginRight: 0,
      },
    },

    '@media screen and (max-width:800px)': {
      marginLeft: 0,
    },
  },
  links: {
    display: 'flex',
    flexFlow: 'column',
    width: '45%',
    borderTop: 'solid 1px grey',
    borderBottom: 'solid 1px grey',
    padding: '32px 16px',

    '& > span': {
      fontWeight: 'bold',
      textAlign: 'start',
    },
    '& > div': {
      '& > div': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '56px',
        alignItems: 'flex-end',
        '& > span': {
          display: 'inline-block',
          width: '60%',
        },
        '& > button': {
          display: 'flex',
          alignItems: 'flex-end',
          paddingBottom: '0px',
          width: '40%',
        },
      },
    },

    '@media screen and (max-width:1200px)': {
      width: '100%',
      marginTop: '80px',
      '& > div': {
        marginRight: '64px',
      },
    },
    '@media screen and (max-width:600px)': {
      '& > div': {
        marginRight: '0px',
        '& > div': {
          flexFlow: 'column',
          flexWrap: 'wrap',
          '& > span': {
            width: '100% !important',
            textAlign: 'start',
          },
        },
      },
    },
  },

  titles: {},
  inputs: {},
  helptext: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  formContainer: {
    marginTop: '168px',
    width: '100%',
    '& > form': {
      '& > p': {
        fontSize: '0.7em',
        textAlign: 'center',
      },
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          textAlign: 'start',
          '& > label': {
            textAlign: 'end',
            fontSize: '1rem',
            '@media screen and (max-width:750px)': {
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
          '& > span': {
            color: 'red',
          },
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $inputs': {
          width: '70%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input, & > textarea, & > select': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
          '& #image': {
            boxShadow: 'none',
            border: 'none',
          },
          '& > input[type="checkbox"]': {
            boxShadow: 'none',
          },
          '& > textarea': {
            height: '10em',
          },
          '& > img': {
            width: '100%',
            objectFit: 'contain',
            maxHeight: '300px',
          },
          '& $helptext': {
            fontSize: '0.8rem',
            color: 'grey',
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  arrow: {
    margin: '80px auto !important',
    borderRadius: '0.5em',
    backgroundColor: theme.palette.primary.main,
  },
}));

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {},
  });

  const [image, setImage] = useState();

  const [token, setToken] = useState('');
  const [submitData, setSubmitData] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const contactAreaTitle = {
    titleList: ['Contact'],
    sub: '問い合わせ',
  };

  // OGP設定
  const ogp = useCallback((props) => {
    ogpGenerator(props);
  }, []);

  useLayoutEffect(() => {
    if (image) {
      const ogpProps = {
        page: 'contact',
        pageTitle: '刀禪OfficialWebSiteお問合せフォーム',
        path: location.pathname,
        imageUrl: image.OfficialImage_TouzenLogoBlack.url,
      };
      ogp(ogpProps);
    }
  }, [image, location, ogp]);
  // OGP設定ここまで

  useEffect(() => {
    imageApi(setImage);
  }, []);

  const onSubmit = async (data) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    } else {
      const reCaptchaToken = await executeRecaptcha('contact');
      setToken(reCaptchaToken);
      setSubmitData(data);
    }
  };

  useEffect(() => {
    if (submitData && token) {
      const data = { ...submitData };
      data['g_recaptcha_response'] = token;
      data['flag'] = 'contact';
      apiHandler(data);
    }
    // eslint-disable-next-line
  }, [submitData, token]);

  const apiHandler = (data) => {
    contact(data);
    alert('お問合せを受け付けました。返信メールをご確認ください。');
    setTimeout(() => {
      navigate('/');
    }, 2000);
  };

  const form = () => {
    return (
      <div className={classes.formContainer}>
        <form
          method="POST"
          encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <div className={classes.titles}>
              <label htmlFor="お名前">{'お名前'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="name"
                type="text"
                {...register('name', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.name?.message && (
                <div className={classes.error}>{errors.name.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="Eメール">{'Eメール'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="email"
                type="email"
                {...register('email', {
                  required: { value: true, message: '入力必須の項目です' },
                  validate: {
                    message: (v) =>
                      emailRegValidator(v)
                        ? null
                        : `※正しいEメールの形式で入力してください`,
                  },
                })}
              />
              {errors.email?.message && (
                <div className={classes.error}>{errors.email.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="Eメール（確認）">{'Eメール（確認）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="email_confirm"
                type="email"
                {...register('email_confirm', {
                  required: { value: true, message: '入力必須の項目です' },
                  validate: {
                    message: (v) =>
                      emailValidator(v)
                        ? null
                        : `上と同一のものを入力してください。`,
                  },
                })}
              />
              {errors.email_confirm?.message && (
                <div className={classes.error}>
                  {errors.email_confirm.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="お問合せ件名">{'お問合せ件名'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="title"
                type="text"
                {...register('title', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.title?.message && (
                <div className={classes.error}>{errors.title.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="問い合わせ内容">{'問い合わせ内容'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <textarea
                id="content"
                type="text"
                {...register('content', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.content?.message && (
                <div className={classes.error}>{errors.content.message}</div>
              )}
            </div>
          </div>
          <div className={classes.arrow}>
            <ArrowButton path={null} buttonName="SUBMIT" color="#FFF" />
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <div>
        <OnOffByScroll place="General" />
      </div>
      {location.pathname === various.general.contact ? (
        <div className={classes.rootContainer}>
          <div className={classes.contentTitle}>
            <ContentTitle title={contactAreaTitle} />{' '}
          </div>
          <div className={classes.container}>
            <p>
              一般的なお問合せは以下のフォームからお申し込みください。なお、入会、体験稽古へのご参加、同好会・稽古会へのお問合せをご希望の方は、次のリンクからお願いします。
            </p>
            <div className={classes.links}>
              <span>その他のお問合せ</span>
              <div>
                <div>
                  <span>同好会・稽古会に対して</span>
                  <ArrowButton
                    path={various.general.contactForClub}
                    buttonName="CONTACT"
                    color="#000"
                  />
                </div>
                <div>
                  <span>体験稽古・入会について</span>
                  <ArrowButton
                    path={various.general.contactForTraining}
                    buttonName="CONTACT"
                    color="#000"
                  />
                </div>
              </div>
            </div>
          </div>
          {form()}
          <Footer />
        </div>
      ) : (
        <Outlet context={{ classes: classes }} />
      )}
    </>
  );
};
export default Contact;
