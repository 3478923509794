import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, makeStyles, Box } from '@material-ui/core';

import various from '../General/various';
import imageIndex from '../img/imageIndex';
import HumburgerMenu from '../General/humburgerMenu';
import { AuthContext } from '../auth';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: '0 0 0 0',
  },
  toolBar: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '16px',
  },
  logoBox: {
    '& > a': {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
    },
    '@media screen and (max-width:500px)': {
      display: 'none',
    },
  },
  logo: {
    width: '80px',
  },
  testEnvHead: {
    // display: 'block',
    color: theme.palette.primary.main,
  },
  iconBox: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginRight: '16px',
    marginLeft: 'auto',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      '& > a': {
        display: 'flex',
        alignItems: 'center',
      },
      '& > div': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  snsIcons: {
    marginRight: '32px',
    color: (props) => (props.color === 'General' ? '#000' : '#FFF'),
  },
  humburgerMenu: {},
}));

const Header = (props) => {
  const { isAuth } = useContext(AuthContext);
  const [env, setEnv] = useState(false);
  const classes = useStyles({ color: props.color });
  const snsUrls = various.snsUrls;

  useEffect(() => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    if (environment === 'staging') {
      setEnv(true);
    }
  }, []);

  const routing = (urls) => {
    const result = [];
    urls.forEach((url, index) => {
      if (props.icons) {
        switch (url.name) {
          case 'FACEBOOK':
            result.push(
              <a key={index} href={url.path}>
                <imageIndex.facebook_mui
                  className={classes.snsIcons}
                  fontSize="small"
                />
              </a>
            );
            break;
          case 'TWITTER':
            result.push(
              <a key={index} href={url.path}>
                <imageIndex.twitter
                  className={classes.snsIcons}
                  fontSize="small"
                />
              </a>
            );
            break;
          case 'INSTAGRAM':
            result.push(
              <a key={index} href={url.path}>
                <imageIndex.instagram
                  className={classes.snsIcons}
                  fontSize="small"
                />
              </a>
            );
            break;
          default:
        }
      } else {
        return <></>;
      }
    });
    return result;
  };
  return (
    <AppBar className={classes.appBar} position="fixed">
      <Toolbar className={classes.toolBar}>
        <Box className={classes.logoBox}>
          <Link to={various.general.home}>
            {props.logo ? (
              <img
                className={classes.logo}
                src={imageIndex.header_svg}
                alt="official logo"
              />
            ) : undefined}
            {env ? (
              <span className={classes.testEnvHead}>TEST ENVIRONMENT</span>
            ) : undefined}
          </Link>
        </Box>
        <Box className={classes.iconBox}>
          <Box
            sx={{
              display: { xs: 'none', sm: 'block' },
            }}
          >
            {routing(snsUrls)}
          </Box>
          <div className={classes.humburgerMenu}>
            <HumburgerMenu isShown={props.logo} isAuth={isAuth} />
          </div>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
