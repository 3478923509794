import TopPage from '../Pages/topPage';
import About from '../Pages/about';
import Branch from '../Pages/branch';
import NoMatch from '../Pages/noMatch';
import LogIn from '../Pages/logIn';
import ForgotPassword from '../Pages/forgotPassword';
import PasswordSetting from '../Pages/passwordSetting';
import ForgotPasswordConfirm from '../Pages/forgotPasswordConfirm';
import Contact from '../Pages/contact';
import ContactForClub from '../Pages/contactForClub';
import ContactForTraining from '../Pages/contactForTraining';
import News from '../Pages/news';
import NewsDetail from '../Pages/newsDetail';
import Media from '../Pages/media';
import MediaContentShinkaratedo from '../Pages/mediaContentShinkaratedo';
import Seminar from '../Pages/seminar';
import SeminarDetail from '../Pages/seminarDetail';
import SeminarDetailPreview from '../Pages/seminarDetailPreview';
import SeminarApplyForm from '../Pages/seminarApplyForm';
import Calendar from '../Pages/calendar';
import Unsubscribe from '../LandingPage/unsubscribe';
import UnsubscribeConfirm from '../LandingPage/unsubscribeConfirm';
import EmailChangeConfirm from '../LandingPage/emailChangeConfirm';
import Books from '../Pages/books';

import ManagedIndex from '../PagesOfManaged/admin/manage';

import AuthTest from '../Pages/authTest';
import LogOut from '../Pages/logout';
import Update from '../PagesOfManaged/admin/sample/update';
import ImageUpDater from '../PagesOfManaged/admin/Image/imageUpdater';
import ImageUpdatePush from '../PagesOfManaged/admin/Image/imageUpdatePush';
import ImageCreatePush from '../PagesOfManaged/admin/Image/imageCreatePush';
import DocumentsCreatePush from '../PagesOfManaged/admin/Documents/documentCreatePush';
import DocumentIndex from '../PagesOfManaged/admin/Documents/documentIndex';
import DocumentUpdate from '../PagesOfManaged/admin/Documents/documentUpdate';
import Create from '../PagesOfManaged/admin/sample/create';
import Detail from '../PagesOfManaged/admin/sample/detail';
import MyPage from '../PagesOfManaged/myPage';
import MyPageUpdate from '../PagesOfManaged/myPageUpdate';
import MyPageSeminarUpdate from '../PagesOfManaged/myPageSeminarUpdate';
import MyPageSeminarCancelForm from '../PagesOfManaged/myPageSeminarCancelForm';
import Constitution from '../PagesOfManaged/constitution';
import TrainingPlaceInfo from '../PagesOfManaged/trainingPlaceInfo';
import CalendarInMyPage from '../PagesOfManaged/calendar';
import MemberIndex from '../PagesOfManaged/admin/Member/memberIndex';
import MemberDetail from '../PagesOfManaged/admin/Member/memberDetail';
import MemberUpdate from '../PagesOfManaged/admin/Member/memberUpdate';
import MemberCreate from '../PagesOfManaged/admin/Member/memberCreate';
import MemberCreateSendForm from '../PagesOfManaged/admin/Member/memberCreateSendForm';
import MemberCreateByHimself from '../PagesOfManaged/admin/Member/memberCreateByHimself';
import MemberBulkCreate from '../PagesOfManaged/admin/Member/memberBulkCreate';

import NewsIndex from '../PagesOfManaged/admin/News/newsIndex';
import NewsDetailForAdmin from '../PagesOfManaged/admin/News/newsDetailForAdmin';
import NewsDetailConfirm from '../PagesOfManaged/admin/News/newsDetailConfirm';
import NewsUpdateForAdmin from '../PagesOfManaged/admin/News/nerwsUpdateForAdmin';
import NewsCreateForAdmin from '../PagesOfManaged/admin/News/newsCreateForAdmin';
import SeminarIndex from '../PagesOfManaged/admin/Seminar/seminarIndex';
import SeminarDetailForAdmin from '../PagesOfManaged/admin/Seminar/seminarDetailForAdmin';
import SeminarUpdateForAdmin from '../PagesOfManaged/admin/Seminar/seminarUpdateForAdmin';
import SeminarCreate from '../PagesOfManaged/admin/Seminar/seminarCreate';
import SeminarDetailConfirm from '../PagesOfManaged/admin/Seminar/seminarDetailConfirm';
import SeminarApplySituation from '../PagesOfManaged/admin/Seminar/seminarApplySituation';
import SeminarRemindMail from '../PagesOfManaged/admin/Seminar/seminarRemindMail';
import PlaceAndHallIndex from '../PagesOfManaged/admin/PlaceAndHall/placeAndHallIndex';
import PlaceAndHallDetail from '../PagesOfManaged/admin/PlaceAndHall/placeAndHallDetail';
import PlaceAndHallUpdate from '../PagesOfManaged/admin/PlaceAndHall/placeAndHallUpdate';
import PlaceAndHallCreate from '../PagesOfManaged/admin/PlaceAndHall/placeAndHallCreate';
import TagIndex from '../PagesOfManaged/admin/Tag/tagIndex';
import TagUpdate from '../PagesOfManaged/admin/Tag/tagUpdate';
import TagCreate from '../PagesOfManaged/admin/Tag/tagCreate';
import ImageIndexForAdmin from '../PagesOfManaged/admin/Image/imageIndex';
import InstructorIndex from '../PagesOfManaged/admin/Instructor/instructorIndex';
import InstructorDetail from '../PagesOfManaged/admin/Instructor/instructorDetail';
import InstructorUpdate from '../PagesOfManaged/admin/Instructor/instructorUpdate';
import InstructorCreate from '../PagesOfManaged/admin/Instructor/instructorCreate';
import BranchIndex from '../PagesOfManaged/admin/Branch/branchIndex';
import BranchDetail from '../PagesOfManaged/admin/Branch/branchDetail';
import BranchUpdate from '../PagesOfManaged/admin/Branch/brnachUpdate';
import BranchCreate from '../PagesOfManaged/admin/Branch/branchCreate';
import SeminarApplyFreeWright from '../PagesOfManaged/admin/Seminar/seminarApplyFreeWright';
import PaymentView from '../Pages/paymentView';
import PaymentSuccess from '../Pages/paymentSuccess';
import PaymentCancel from '../Pages/paymentCancel';
import PaymentViewOfOthers from '../Pages/paymentViewOfOthers';
import ProductMasterCreate from '../PagesOfManaged/admin/ProductMaster/productMasterCreate';
import ProductMasterIndex from '../PagesOfManaged/admin/ProductMaster/productMasterIndex';
import ProductMasterDetail from '../PagesOfManaged/admin/ProductMaster/productMasterDetail';
import ProductMasterUpdate from '../PagesOfManaged/admin/ProductMaster/productMasterUpdate';
import MembersPayment from '../PagesOfManaged/admin/Payments/membersPayment';
import MembersPaymentDetail from '../PagesOfManaged/admin/Payments/memberPaymentsDetail';
import MemberAllPayments from '../PagesOfManaged/admin/Payments/memberAllPayments';
import PayoutsIndex from '../PagesOfManaged/admin/Payouts/payoutsIndex';
import PayoutsDetail from '../PagesOfManaged/admin/Payouts/payoutsDetail';

import CommerceDisclosure from '../Footer/commerceDisclosure';
import NotificationIndex from '../PagesOfManaged/admin/Notification/notificationIndex';
import NotificationCreate from '../PagesOfManaged/admin/Notification/notificationCreate';
import NotificationUpdate from '../PagesOfManaged/admin/Notification/notificationUpdate';

let ngrokHandler;
try {
  ngrokHandler = require('../ngrokHandler.json');
} catch (err) {
  ngrokHandler = null;
}

export const config = () => {
  let restful = {};
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      restful['url'] = process.env.REACT_APP_PROD_API_URL;
    } else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
      restful['url'] = process.env.REACT_APP_STAGING_API_URL;
    }
  } else if (process.env.NODE_ENV === 'development') {
    if (ngrokHandler && ngrokHandler.isTrue) {
      restful['url'] = ngrokHandler.backendAPIURL;
    } else {
      restful['url'] = process.env.REACT_APP_DEV_API_URL;
    }
  }
  restful['path'] = process.env.REACT_APP_API_AUTH_PATH;
  // 以下DEBUG用
  // console.log('restful:', restful);
  return restful;
};

const various = {
  general: {
    home: '/',
    about: '/about',
    seminar: '/seminar',
    seminarPreview: '/seminar/preview',
    seminarApplyForm: '/seminar/apply_form',
    branch: '/branch',
    contact: '/contact',
    contactForClub: '/contact/for_club',
    contactForTraining: '/contact/for_training',
    calendar: '/calendar',
    media: '/media',
    news: '/news',
    login: '/login',
    logout: '/logout',
    forgot: '/forgot_password',
    forgotConfirm: '/forgot_password/confirm',
    books: '/books',

    managed: '/manage/index',

    update: '/manage/sample/update',
    create: '/manage/sample/create',
    detail: '/manage/sample/detail',

    mypage: '/mypage',
    mypageUpdate: '/mypage/update',
    myPageSeminarUpdate: '/mypage/seminar',
    myPageSeminarCancelForm: '/mypage/seminar_cancel',
    constitution: '/mypage/constitution',
    trainingPlaceInfo: '/mypage/training_place_information',
    calendarInMypage: '/mypage/calendar',

    memberIndex: '/member_index',
    memberDetail: '/member_detail',
    memberUpdate: '/member_update',
    memberCreate: '/member_create',
    memberCreateSendForm: '/member_create_send_form',
    memberCreateByHimself: '/member_create_by_himself',
    memberBulkCreate: '/member_bulk_create',

    newsIndex: '/news_index',
    newsDetailForAdmin: '/news_detail_for_admin',
    newsDetailConfirm: '/news_detail_confirm',
    newsUpdateForAdmin: '/news_update_for_admin',
    newsCreateForAdmin: '/news_create_for_admin',

    notificationIndex: '/notification_index',
    notificationCreate: '/notification_create',
    notificationUpdate: '/notification_update',

    seminarIndex: '/seminar_index',
    seminarDetailForAdmin: '/seminar_detail_for_admin',
    seminarDetailConfirm: '/seminar_detail_confirm',
    seminarUpdateForAdmin: '/seminar_update_for_admin',
    seminarCreateForAdmin: '/seminar_create_for_admin',
    seminarApplySituation: '/seminar_apply_situation',
    seminarApplyFreeWright: '/seminar_apply_free_wright',
    seminarRemindMail: '/seminar_remind_mail',

    imageUpdater: '/manage/image_updater',
    imageCreate: '/manage/image_create',

    documentCreate: '/manage/document_create',
    documentUpdate: '/manage/document_update',
    documentIndex: '/manage/document_index',

    placeAndHallIndex: '/manage/place_hall_index',
    placeAndHallDetail: '/manage/place_hall_detail',
    placeAndHallUpdate: '/manage/place_hall_update',
    placeAndHallCreate: '/manage/place_hall_create',

    tagIndex: '/manage/tag_index',
    tagUpdate: '/manage/tag_update',
    tagCreate: '/manage/tag_create',

    imageIndexForAdmin: '/manage/image_index_for_admin',

    instructorIndex: '/manage/instructor_index',
    instructorDetail: '/manage/instructor_detail',
    instructorUpdate: '/manage/instructor_update',
    instructorCreate: '/manage/instructor_create',

    branchIndex: '/manage/branch_index',
    branchDetail: '/manage/branch_detail',
    branchUpdate: '/manage/branch_update',
    branchCreate: '/manage/branch_create',

    unsubscribe: '/unsubscribe',
    unsubscribeConfirm: '/unsubscribe_confirm',

    productMasterCreate: '/manage/product_master_create',
    productMasterIndex: '/manage/product_master_index',
    productMasterDetail: '/manage/product_master_detail',
    productMasterUpdate: '/manage/product_master_update',

    paymentView: '/payment_view',
    paymentSuccess: '/payment_success',
    paymentCancel: '/payment_cancel',
    paymentViewOfOthers: '/payment_view_of_others',
    membersPayment: '/members_payment',
    membersPaymentDetail: '/members_payment_detail',
    memberAllPayments: '/member_all_payments',

    payoutsIndex: '/payouts_index',
    payoutsDetail: '/payouts_detail',

    commerceDisclosure: '/commerce_disclosure',
  },
  routingUrls: [
    { path: '/', component: <TopPage />, auth: false },
    { path: '/about', component: <About />, auth: false },
    {
      path: '/seminar',
      component: <Seminar />,
      children: [
        {
          path: ':seminarId',
          component: <SeminarDetail />,
        },
        {
          path: 'preview/:seminarId',
          component: <SeminarDetailPreview />,
        },
      ],
      auth: false,
    },
    {
      path: '/seminar/apply_form',
      component: <SeminarApplyForm />,
      auth: false,
    },
    {
      path: '/seminar_preview',
      component: <SeminarDetailPreview />,
      auth: false,
      // 上記の通り、childrenになったが、まだURLが出回っているので、経過処置として当該pathも定義しておく。追って消す。
    },

    { path: '/branch', component: <Branch />, auth: false },
    {
      path: '/contact',
      component: <Contact />,
      children: [
        { path: 'for_club', component: <ContactForClub /> },
        { path: 'for_training', component: <ContactForTraining /> },
      ],
      auth: false,
    },
    { path: '/calendar', component: <Calendar />, auth: false },
    { path: '/media', component: <Media />, auth: false },
    {
      path: '/media/shin-karate-do',
      component: <MediaContentShinkaratedo />,
      auth: false,
    },
    {
      path: '/news',
      component: <News />,
      children: [{ path: ':newsId', component: <NewsDetail /> }],
      auth: false,
    },
    { path: '/login', component: <LogIn />, auth: false },
    { path: '/forgot_password', component: <ForgotPassword />, auth: false },
    { path: '/password_setting', component: <PasswordSetting />, auth: false },
    {
      path: '/forgot_password/confirm',
      component: <ForgotPasswordConfirm />,
      auth: false,
    },
    {
      path: '/email_change_confirm',
      component: <EmailChangeConfirm />,
      auth: false,
    },
    {
      path: '/commerce_disclosure',
      component: <CommerceDisclosure />,
      auth: false,
    },
    {
      path: '/books',
      component: <Books />,
      auth: false,
    },

    { path: '/payment_view', component: <PaymentView />, auth: true },
    { path: '/payment_success', component: <PaymentSuccess />, auth: true },
    { path: '/payment_cancel', component: <PaymentCancel />, auth: true },
    {
      path: '/payment_view_of_others',
      component: <PaymentViewOfOthers />,
      auth: true,
    },
    {
      path: '/members_payment',
      component: <MembersPayment />,
      auth: true,
    },
    {
      path: '/members_payment_detail',
      component: <MembersPaymentDetail />,
      auth: true,
    },
    {
      path: '/member_all_payments',
      component: <MemberAllPayments />,
      auth: true,
    },

    {
      path: '/payouts_index',
      component: <PayoutsIndex />,
      auth: true,
    },
    {
      path: '/payouts_detail',
      component: <PayoutsDetail />,
      auth: true,
    },

    { path: '/logout', component: <LogOut />, auth: true },
    { path: '/auth', component: <AuthTest />, auth: true },

    { path: '/mypage', component: <MyPage />, auth: true },
    { path: '/mypage/update', component: <MyPageUpdate />, auth: true },
    { path: '/mypage/constitution', component: <Constitution />, auth: true },
    {
      path: '/mypage/training_place_information',
      component: <TrainingPlaceInfo />,
      auth: true,
    },
    {
      path: '/mypage/calendar',
      component: <CalendarInMyPage />,
      auth: true,
    },
    {
      path: '/mypage/seminar',
      component: <MyPageSeminarUpdate />,
      auth: true,
    },
    {
      path: '/mypage/seminar_cancel',
      component: <MyPageSeminarCancelForm />,
      auth: true,
    },

    {
      path: '/manage/index',
      component: <ManagedIndex />,
      auth: true,
    },

    { path: '/manage/image_updater', component: <ImageUpDater />, auth: true },
    {
      path: '/manage/updater_push',
      component: <ImageUpdatePush />,
      auth: true,
    },
    {
      path: '/manage/image_create',
      component: <ImageCreatePush />,
      auth: true,
    },
    {
      path: '/manage/document_create',
      component: <DocumentsCreatePush />,
      auth: true,
    },
    {
      path: '/manage/document_index',
      component: <DocumentIndex />,
      auth: true,
    },
    {
      path: '/manage/document_update',
      component: <DocumentUpdate />,
      auth: true,
    },
    { path: '/manage/sample/create', component: <Create />, auth: true },
    { path: '/manage/sample/update', component: <Update />, auth: true },
    { path: '/manage/sample/detail', component: <Detail />, auth: true },

    {
      path: '/manage/place_hall_index',
      component: <PlaceAndHallIndex />,
      auth: true,
    },
    {
      path: '/manage/place_hall_detail',
      component: <PlaceAndHallDetail />,
      auth: true,
    },
    {
      path: '/manage/place_hall_update',
      component: <PlaceAndHallUpdate />,
      auth: true,
    },
    {
      path: '/manage/place_hall_create',
      component: <PlaceAndHallCreate />,
      auth: true,
    },
    {
      path: '/manage/tag_index',
      component: <TagIndex />,
      auth: true,
    },
    {
      path: '/manage/tag_update',
      component: <TagUpdate />,
      auth: true,
    },
    {
      path: '/manage/tag_create',
      component: <TagCreate />,
      auth: true,
    },
    {
      path: '/manage/image_index_for_admin',
      component: <ImageIndexForAdmin />,
      auth: true,
    },
    {
      path: '/manage/instructor_index',
      component: <InstructorIndex />,
      auth: true,
    },
    {
      path: '/manage/instructor_detail',
      component: <InstructorDetail />,
      auth: true,
    },
    {
      path: '/manage/instructor_update',
      component: <InstructorUpdate />,
      auth: true,
    },
    {
      path: '/manage/instructor_create',
      component: <InstructorCreate />,
      auth: true,
    },
    {
      path: '/manage/branch_index',
      component: <BranchIndex />,
      auth: true,
    },
    {
      path: '/manage/branch_detail',
      component: <BranchDetail />,
      auth: true,
    },
    {
      path: '/manage/branch_update',
      component: <BranchUpdate />,
      auth: true,
    },
    {
      path: '/manage/branch_create',
      component: <BranchCreate />,
      auth: true,
    },

    {
      path: '/manage/product_master_create',
      component: <ProductMasterCreate />,
      auth: true,
    },
    {
      path: '/manage/product_master_index',
      component: <ProductMasterIndex />,
      auth: true,
    },
    {
      path: '/manage/product_master_detail',
      component: <ProductMasterDetail />,
      auth: true,
    },
    {
      path: '/manage/product_master_update',
      component: <ProductMasterUpdate />,
      auth: true,
    },

    { path: '/member_index', component: <MemberIndex />, auth: true },
    { path: '/member_detail', component: <MemberDetail />, auth: true },
    { path: '/member_update', component: <MemberUpdate />, auth: true },
    { path: '/member_create', component: <MemberCreate />, auth: true },
    {
      path: '/member_create_send_form',
      component: <MemberCreateSendForm />,
      auth: true,
    },
    {
      path: '/member_create_by_himself',
      component: <MemberCreateByHimself />,
      auth: true,
    },
    {
      path: '/member_bulk_create',
      component: <MemberBulkCreate />,
      auth: true,
    },

    {
      path: '/news_index',
      component: <NewsIndex />,
      auth: true,
    },
    {
      path: '/news_detail_for_admin',
      component: <NewsDetailForAdmin />,
      auth: true,
    },
    {
      path: '/news_detail_confirm',
      component: <NewsDetailConfirm />,
      auth: true,
    },
    {
      path: '/news_update_for_admin',
      component: <NewsUpdateForAdmin />,
      auth: true,
    },
    {
      path: '/news_create_for_admin',
      component: <NewsCreateForAdmin />,
      auth: true,
    },

    {
      path: '/notification_index',
      component: <NotificationIndex />,
      auth: true,
    },
    {
      path: '/notification_create',
      component: <NotificationCreate />,
      auth: true,
    },
    {
      path: '/notification_update',
      component: <NotificationUpdate />,
      auth: true,
    },

    {
      path: '/seminar_index',
      component: <SeminarIndex />,
      auth: true,
    },
    {
      path: '/seminar_detail_for_admin',
      component: <SeminarDetailForAdmin />,
      auth: true,
    },
    {
      path: '/seminar_detail_confirm',
      component: <SeminarDetailConfirm />,
      auth: true,
    },
    {
      path: '/seminar_update_for_admin',
      component: <SeminarUpdateForAdmin />,
      auth: true,
    },
    {
      path: '/seminar_create_for_admin',
      component: <SeminarCreate />,
      auth: true,
    },
    {
      path: '/seminar_apply_situation',
      component: <SeminarApplySituation />,
      auth: true,
    },
    {
      path: '/seminar_apply_free_wright',
      component: <SeminarApplyFreeWright />,
      auth: true,
    },
    {
      path: '/seminar_remind_mail',
      component: <SeminarRemindMail />,
      auth: true,
    },

    { path: '/unsubscribe', component: <Unsubscribe />, auth: false },
    {
      path: '/unsubscribe_confirm',
      component: <UnsubscribeConfirm />,
      auth: false,
    },
    { path: '*', component: <NoMatch />, auth: false },
  ],
  globalNavUrls: [
    { path: '/', name: 'HOME', viewName: 'トップページ', auth: false },
    { path: '/about', name: 'ABOUT', viewName: '刀禅とは何か', auth: false },
    {
      path: '/seminar',
      name: 'SEMINAR',
      viewName: 'セミナー・講習会',
      auth: false,
    },
    {
      path: '/branch',
      name: 'BRANCH',
      viewName: '同好会・稽古会',
      auth: false,
    },
    {
      path: '/calendar',
      name: 'CALENDAR',
      viewName: 'カレンダー',
      auth: false,
    },
    {
      path: '/books',
      name: 'BOOKS',
      viewName: '書籍',
      auth: false,
    },
    {
      path: '/media',
      name: 'MEDIA',
      viewName: 'メディア掲載事例',
      auth: false,
    },
    { path: '/news', name: 'NEWS', viewName: 'ニュース', auth: false },
    { path: '/contact', name: 'CONTACT', viewName: 'お問合せ', auth: false },
    { path: '/login', name: 'LOGIN', viewName: 'Login', auth: false },
    { path: '/logout', name: 'LOGOUT', viewName: 'Logout', auth: true },
    // { path: '/auth', name: 'AUTHTEST', viewName:'ー', auth: true },
    { path: '/mypage', name: 'MYPAGE', viewName: 'マイページ', auth: true },
    {
      path: '/image_updater',
      name: 'IMAGEUPDATE',
      viewName: 'イメージ更新',
      auth: true,
    },
    {
      path: '/member_index',
      name: 'MemberIndex',
      viewName: '会員一覧',
      auth: true,
    },
    {
      path: '/member_bulk_create',
      name: 'MemberBulkCreate',
      viewName: '会員一括登録',
      auth: true,
    },
    {
      path: '/news_index',
      name: 'NewsIndex',
      viewName: 'ニュース一覧',
      auth: true,
    },
    {
      path: '/seminar_index',
      name: 'SeminarIndex',
      viewName: 'セミナー一覧',
      auth: true,
    },
    {
      path: '/manage/document_index',
      name: 'DocumentIndex',
      viewName: '文書管理',
      auth: true,
    },
    {
      path: '/manage/index',
      name: 'ManagedIndex',
      viewName: '管理画面',
      auth: true,
    },
  ],
  footerLeftUrls: [
    { path: '/', name: 'HOME' },
    { path: '/calendar', name: 'CALENDER' },
    { path: '/media', name: 'MEDIA' },
    { path: '/news', name: 'NEWS' },
  ],
  footerRightUrls: [
    { path: '/about', name: 'ABOUT' },
    { path: '/seminar', name: 'SEMINAR' },
    { path: '/branch', name: 'BRANCH' },
    { path: '/contact', name: 'CONTACT' },
    { path: '/login', name: 'LOGIN' },
    { path: '/logout', name: 'LOGOUT' },
  ],
  snsUrls: [
    { path: 'https://www.facebook.com/bodywork.touzen/', name: 'FACEBOOK' },
    { path: 'https://twitter.com/touzen__', name: 'TWITTER' },
    // { path: 'https://twitter.com/TOUZEN_', name: 'INSTAGRAM' },
  ],
  membersHeaders: {
    uuid: 'uuid',
    first_name: '名',
    last_name: '氏',
    first_name_kana: '名（カナ）',
    last_name_kana: '氏（カナ）',
    sex: '性別',
    is_student: '学生区分',
    is_worker: '社会人区分',
    email: 'Eメール',
    year_of_birth: '生年',
    year_of_month: '生月',
    year_of_day: '生日',
    phone_number: '電話番号',
    address1: '都道府県',
    address2: '市区町村',
    address3: '番地',
    address4: '建物名等',
    facebook_account: 'FBアカウント',
    work_place: '職業/勤務先',
    affiliation_history: '所属団体歴',
    exercise_career: '運動歴',
    anamnesis: '既往症',
    reason_and_motivation: '参加の理由・動機',
    question_and_request: '質問・要望',
    experience_training_preferred_date: '体験稽古希望日',
    discount_rate: '掛け率',
    admission_fee_exemption: '入会金免除',
    experience_training_participation_is: '体験稽古参加の有無',
    seminar_participation_is: 'セミナー参加の有無',
    recieved_is: '入会金受領の有無',
    is_member: '会員・非会員',
    is_training_group_member: '稽古会会員',
    belongs: '所属地域',
    introducer: '紹介者',
    member_of_num_is: '回数会員区分',
    member_of_general_is: '定例会員区分',
    member_of_kosan: '古参区分',
    member_of_honor: '名誉会員区分',
    member_of_club_manager_is: '同好会主宰者区分',
    member_of_instructor: '指導者区分',
    roushi_is: '老師',
    member_of_admin: '運営担当者区分',
    member_of_hamon: '破門者区分',
    active_is: '継続稽古中',
    withdrawal_is: '退会者区分',
    palnned_deposit_date: '入会金支払日',
    date_of_join: '入会日',
    date_of_hamon: '破門日',
    date_of_non_active: '休会日',
    date_of_withdrawal: '退会日',
    remarks: '備考',
    num_of_seinar_participation: 'セミナー参加回数',
    num_of_club_participation: '稽古会参加回数',
    num_of_training_participation: '本稽古参加回数',
    num_of_application_forms_sent: '入会申込書送付回数',
    notification_of_seminar: 'セミナー配信通知の可否',
    notification_of_news: 'ニュース配信通知の可否',
    image: 'イメージ写真',
    created_at: 'レコード作成日',
    user: 'User_pk',
    seminar: 'Seminar_pk',
    participated_seminars: 'Seminar_pk',
    permission_level: 'PermissionLevel_pk',
  },
  newsHeaders: {
    uuid: 'uuid',
    image_url: 'ニュースイメージ',
    title: 'タイトル',
    description: '内容案内',
    content: '内容',
    news_image: 'ニュースイメージ',
    distribution_is: 'メール配信',
    is_members_only: '公開範囲',
    is_topics: 'Topics',
    topics_end_date: 'Topics掲載終了日',
    delivery_date: '公開予定日時',
    disclosure_range: '公開範囲',
    delivered: 'Status',
    mail_delivered: 'メール配信済み',
    created_at: '作成日時',
    tag: 'Tag',
  },
  seminarHeaders: {
    uuid: 'uuid',
    title: 'タイトル',
    sub_title: 'サブタイトル',
    serial_number: '通し番号',
    guidance: 'ガイダンス',
    event_date_start_time: 'セミナー開催日',
    event_date_end_time: 'セミナー終了時間',
    part_one_start_time: '第1部開始時間',
    part_one_end_time: '第1部終了時間',
    part_two_start_time: '第2部開始時間',
    part_two_end_time: '第2部終了時間',
    part_three_start_time: '第3部開始時間',
    part_three_end_time: '第3部終了時間',
    part_four_start_time: '懇親会開始時間',
    part_four_end_time: '懇親会終了時間',
    capacity: '許容人数',
    part_one_current_num: '第1部申込人数',
    part_two_current_num: '第2部申込人数',
    part_three_current_num: '第3部申込人数',
    part_four_current_num: '第4部申込人数',
    num_of_applicans: '総申込人数',
    subject: '対象',
    seminar_type: 'セミナータイプ',
    entry_fee: '一般参加費',
    membership_fee: '会員参加費',
    release_is: '公開',
    distribution_is: '配信',
    is_secret: '秘伝区分',
    delivery_date: '公開予定日時',
    distribution_date: '配信予定日時',
    show_the_instructor: '講師公開',
    display_name_of_the_place: '公開表記会場情報',
    notes: '注意事項',
    information_to_management: '運営への申し送り事項',
    image: 'セミナーイメージ',
    image_url: 'セミナーイメージ',
    created_at: '作成日',
    wallpaper: '未使用項目',
    place: '会場',
    hall: '稽古場',
    organizer: '主宰者',
    instructor: '講師',
    news: '配信ニュース',
    tag: 'Tag',
    documents: '配布資料',
    products: 'サービスモデル',
    payment_method: '決済方法',
  },
  paymentHeaders: {
    uuid: 'uuid',
    service_date_time: 'サービス日時',
    service_name: 'サービス名',
    price: '料金',
    payment_id_type: '決済タイプ',
    payment_info: '決済情報',
    payment_status: '決済ステータス',
    payment_completed: '決済完了',
    is_cancelled: 'キャンセル',
    amount_refunded: '返金額',
    refund_currency: '返金通貨',
    refund_created_at: '返金作成日時',
    refund_status: '返金ステータス',
    user: '会員氏名',
    seminar: 'セミナー名',
    updated_at: '最終更新日時',
    payment_id: '決済ID',
    refund_id: '返金ID',
    payment_fee_total: '決済手数料',
    admission_fee: '入会金控除額',
    refund_fee_total: '返金手数料',
  },
  payoutHeaders: {
    uuid: 'uuid',
    amount: '入金額',
    currency: '通貨',
    application_fee: 'アプリケーション手数料',
    application_fee_amount: 'アプリケーション手数料額',
    arrival_date: '入金予定日',
    balance_transaction_id: 'transaction_id',
    bank_id: 'bank_id',
    create_date: '入金データ作成日',
    paid_status: '入金ステータス',
    payout_id: 'payout_id',
    reconciliation_status: '調整ステータス',
    service_plan: '決済金額',
    stripe_received_fee: '手数料',
    stripe_refund: '返金額',
    created_at: '作成日時',
    updated_at: '更新日時',
  },
  youtubeId: 'RQA-mdSPCMI',
  timezone: 'Asia/Tokyo',
  currentDomain: config().url,
  apiUrlsRequireAuth: config().path,
  operationalText: `本セミナーは以下の通り開催されます。\n奮ってご参加ください。\n講師からのアナウンスがありましたら追記します。`,
  memberDesignations: '正会員',
  otherMemberDesignations: '稽古会会員',
  nonMemberDesignations: '非会員',
  general_member: '会員',
  num_member: '回数会員',
  permissionKey: 'permission_v2',
  stripe: {
    test: 'https://billing.stripe.com/p/login/test_9AQ8zM0PC32j6ys4gg',
    production: 'https://billing.stripe.com/p/login/bIYg0Va8Fat53qU144',
    testRunStaff: [
      'shimpeishimazaki@gmail.com',
      'shimaciman@yahoo.co.jp',
      's.shimazaki@energy-creation.tech',
      'shimaciman1979@gmail.com',
      'hirotaka.murakami@gmail.com',
      'for.stripe.review@gmail.com',
      'touhoum_m@yahoo.co.jp',
      'kisinsei@yahoo.co.jp',
      'norio1019@gmail.com',
      'shimo.a@gmail.com',
      'kenjyutu21@gmail.com',
      'akumakunn999@gmail.com',
      'hattoritomoji@gmail.com',
      'stadtomita@gmail.com',
      'matsuuranium1026@gmail.com',
      'buyuuden1217@yahoo.co.jp',
      // 追って追加
    ],
    implementationDate: new Date('2024-01-01'),
    membershipFee: 6000,
    numMembershipFee: 1000,
    initialDeductedAmount: 500,
  },
};

export const frontSideRequireAuth = () => {
  const result = [];
  various.routingUrls.forEach((url) => {
    if (url.auth) {
      return result.push(url.path);
    }
  });
  return result;
};
export default various;
