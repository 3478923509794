import { Button, makeStyles } from '@material-ui/core';
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import OnOffByScroll from '../Animation/onOffByScroll';
import ContentTitle from '../General/contentTitle';
import various from '../General/various';
import ScrollToTop from '../General/scrollToTop';
import { newsIndex } from '../Apis/apis';
import { images as imageApi } from '../Apis/apis';
import Footer from '../Footer/footer';
import { ogpGenerator } from '../ogp';
import imageIndex from '../img/imageIndex';
import { AuthContext } from '../auth';

const useStyles = makeStyles((theme) => ({
  contentTitle: {
    marginTop: '168px',
  },
  hashTags: {
    textAlign: 'start',
    lineHeight: '16px',
    '& > button': {
      fontWeight: 'bold',
      padding: '0px 8px 0px',
      '& > span': {
        fontSize: '1rem',
        fontStyle: 'italic',
        '@media screen and (max-width:400px)': {
          fontSize: '0.9rem !important',
        },
      },
      '& #active': {
        color: theme.palette.primary.main,
        fontWeight: '900',
      },
    },
  },
  hashButton: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    lineHeight: '16px',
    '& > span': {
      fontSize: '1rem',
      fontStyle: 'italic',
      '@media screen and (max-width:400px)': {
        fontSize: '0.9rem !important',
      },
    },
    '& #active-h': {
      color: theme.palette.primary.main,
      fontWeight: '900',
    },
  },

  container: {
    display: 'flex',
    flexFlow: 'column',
    marginTop: '80px',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'start',
    marginBottom: '128px',
    '@media screen and (max-width:1400px)': {
      marginBottom: '64px',
      '& $content': {
        width: '62%',
      },
      '& $contentImage': {
        width: '35%',
        marginRight: '24px',
      },
      '& $title': {
        margin: 0,
      },
    },
    '@media screen and (max-width:1000px)': {
      '& $content': {
        width: '100%',
        '& $title': {
          '& > button': {
            '& > span': {
              fontSize: '2.5rem',
              marginTop: 0,
              whiteSpace: 'normal !important',
            },
          },
        },
      },
      '& $contentImage': {
        display: 'none !important',
      },
    },
  },
  contentImage: {
    width: 'calc(70vw * 0.3)',
    marginRight: '56px',
    '& > a': {
      '& > img': {
        width: 'calc(70vw * 0.3)',
        height: 'calc(70vw * 0.3)',
        // filter:
        //   'contrast(100%) grayscale(100%) saturate(0%) drop-shadow(2px 2px 2px black)',
        border: 'solid 0.1px grey',
        objectFit: 'cover',
      },
    },
  },
  datehash: {},
  title: {},
  text: {},
  toMemberText: {},
  content: {
    width: 'calc(70vw * 0.7)',
    height: 'calc(70vw * 0.3)',
    '& $datehash': {
      display: 'flex',
      height: 'calc(70vw * 0.3 * 0.15)',
      textAlign: 'start',
      fontWeight: 'bold',
      '& > span': {
        display: 'inline',
        marginRight: '16px',
        lineHeight: '16px',
      },
      '& > div': {
        display: 'flex',
        alignItems: 'start',
        flexWrap: 'wrap',
        width: '65%',
        lineHeight: '8px',
        '& > button': {
          padding: '0px 2px 0px 0px',
          '& > span': {
            '& > span': {
              fontSize: '0.8rem',
            },
          },
        },
      },
    },
    '& $title, $notAuthTitle': {
      width: '100%',
      height: 'calc(70vw * 0.3 * 0.2)',
      display: 'flex',
      margin: '0px 0px 16px',
      '& > button, & > a': {
        padding: 0,
        '& > span': {
          display: 'inline-block',
          fontSize: '1.5rem',
          fontWeight: 'bold',
          lineHeight: '24px',
          textAlign: 'start',
          fontFamily: theme.typography.fontFamily,
        },
        '&:link,&:visited': {
          color: '#000',
        },
      },
    },
    '& $toMemberText': {
      height: 'calc(70vw * 0.3 * 0.65)',
      position: 'relative',
      textAlign: 'start',
      lineHeight: '1.5em',
      whiteSpace: 'pre-wrap',
      letterSpacing: '0.02rem',
      wordBreak: 'break-word',
      fontSize: '1rem',
      color: 'grey',
      '& > span': {
        display: 'block',
        marginBottom: '16px',
      },
      '& > div': {
        display: 'flex',
        justifyContent: 'center',
        '& > button': {
          fontFamily: theme.typography.fontFamily,
          borderColor: theme.palette.background.default,
          color: 'grey',
          borderRadius: '20px',
          cursor: 'pointer',
        },
      },
    },
    '& $text': {
      display: 'flex',
      width: '100%',
      height: 'calc(70vw * 0.3 * 0.65)',
      position: 'relative',
      textAlign: 'start',
      overflow: 'hidden',
      lineHeight: '1.5em',
      whiteSpace: 'pre-wrap',
      letterSpacing: '0.02rem',
      wordBreak: 'break-word',
      fontSize: '1rem',
      '& > a': {
        display: 'inline-block',
        '&:link': {
          color: '#000',
        },
        '&:visited': {
          color: '#000',
        },
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        borderStyle: 'none',
        left: 0,
        bottom: 0,
        zIndex: 2,
        width: '100%',
        height: '120px',
        background: `linear-gradient(rgba(255,255,255,0) 0, ${theme.palette.background.default} 114px)`,
        pointerEvents: 'none',
      },
    },
    '@media screen and (max-width:1000px)': {
      width: '100% !important',
      height: 'calc(92vw * 0.3) !important',
      minHeight: '240px',
      '& $datehash': {
        height: 'calc(92vw * 0.3 * 0.15) !important',
        minHeight: '15%',
        marginBottom: '8px',
      },
      '& $title, & $notAuthTitle': {
        width: '100% !important',
        height: 'calc(92vw * 0.3 * 0.2) !important',
        minHeight: '20%',
        marginBottom: '4px',
      },
      '& $text': {
        width: '100% !important',
        height: 'calc(92vw * 0.3 * 0.65) !important',
        minHeight: '65%',
        fontSize: '1rem !important',
      },
    },
  },
  notAuthTitle: {
    '& > button': {
      cursor: (props) => (props.auth ? 'pointer' : 'initial'),
    },
  },
  arrow: {
    width: '100%',
    margin: '144px auto',
    '& > button': {
      '& > span': {
        '& > img': {
          display: 'inline-block',
          width: '100px',
          color: '#000',
        },
      },
    },
  },
}));

const News = () => {
  const { isAuth } = useContext(AuthContext);
  const [image, setImage] = useState();
  const [news, setNews] = useState();
  const [sort, setSort] = useState();
  const [hash, setHash] = useState([]);
  const [activeHash, setActiveHash] = useState();
  const element = useRef();

  const [viewSet, setViewSet] = useState(5);

  const navigate = useNavigate();
  const location = useLocation();

  const classes = useStyles({ auth: isAuth });
  const newsAreaTitle = {
    titleList: ['News'],
    sub: 'ニュース',
  };

  // OGP設定
  const ogp = useCallback((props) => {
    ogpGenerator(props);
  }, []);

  useLayoutEffect(() => {
    if (image) {
      const ogpProps = {
        page: 'news',
        pageTitle: '刀禪OfficialWebSiteニュース一覧',
        path: location.pathname,
        imageUrl: image.OfficialImage_TouzenLogoBlack.url,
      };
      ogp(ogpProps);
    }
  }, [image, location, ogp]);
  // OGP設定ここまで

  // Init
  useEffect(() => {
    imageApi(setImage);
  }, []);

  useEffect(() => {
    if (news) {
      const contents = news.slice(0, viewSet);
      setSort(contents);
    }
  }, [news, viewSet]);

  useEffect(() => {
    let result = [];
    if (news) {
      news.forEach((elm) => {
        elm.hash.forEach((hash_tag) => {
          result.push(hash_tag);
        });
      });
    }
    let set = new Set(result);
    setHash(Array.from(set));
  }, [news]);

  useEffect(() => {
    newsIndex(setNews, viewSet);
  }, [viewSet]);

  useEffect(() => {
    newsIndex(setNews);
  }, []);

  // Handler
  const handleSort = (e, key) => {
    e.preventDefault();
    let result = [];
    news.forEach((elm) => {
      if (elm.hash.includes(key)) {
        result.push(elm);
      }
    });
    setSort(result);
    setActiveHash(key);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setViewSet(viewSet + 5);
  };

  const handleAuth = (id) => {
    navigate(various.general.login, {
      state: { previous: location.pathname + '/' + id },
    });
  };

  // Contents
  const newsMapping = () => {
    if (sort) {
      return sort.map((elm, index) => {
        return (
          <div key={index} className={classes.contentContainer}>
            <div
              ref={index === 0 ? element : undefined}
              id={index === 0 ? `target_${index}` : undefined}
              className={classes.contentImage}
            >
              {elm.isMembersOnly && !isAuth ? (
                // eslint-disable-next-line
                <a>
                  <img src={elm.image} alt="contentImage" />
                </a>
              ) : (
                <Link to={`${various.general.news}/${elm.id}`}>
                  <img src={elm.image} alt="contentImage" />
                </Link>
              )}
            </div>
            <div className={classes.content}>
              <div className={classes.datehash}>
                <span>{elm.date}</span>
                <div>
                  {elm.hash.map((h, i) => {
                    return (
                      <Button
                        key={i}
                        onClick={(e) => handleSort(e, h)}
                        classes={{ root: classes.hashButton }}
                      >
                        {h === activeHash ? (
                          <span id="active-h">{h}&nbsp;</span>
                        ) : (
                          <span>{h}&nbsp;</span>
                        )}
                      </Button>
                    );
                  })}
                </div>
              </div>
              {elm.isMembersOnly && !isAuth ? (
                <div className={classes.notAuthTitle}>
                  <Button>{elm.title}</Button>
                </div>
              ) : (
                <div className={classes.title}>
                  {/* <Button onClick={(e) => handleTrans(e, elm.id)}>
                    {elm.title}
                  </Button> */}
                  <Link to={various.general.news + '/' + elm.id}>
                    <span>{elm.title}</span>
                  </Link>
                </div>
              )}
              {elm.isMembersOnly ? (
                isAuth ? (
                  <div className={classes.text}>
                    <Link to={`${various.general.news}/${elm.id}`}>
                      {elm.contentText}
                    </Link>
                  </div>
                ) : (
                  <div className={classes.toMemberText}>
                    <span>
                      会員専用コンテンツです。以下のボタンよりログインしてください。
                    </span>
                    <div>
                      <button onClick={() => handleAuth(elm.id)}>
                        <span>ログイン</span>
                      </button>
                    </div>
                  </div>
                )
              ) : (
                <div className={classes.text}>
                  <Link to={`${various.general.news}/${elm.id}`}>
                    {elm.contentText}
                  </Link>
                </div>
              )}
            </div>
          </div>
        );
      });
    }
  };

  const contents = () => {
    if (sort) {
      return (
        <>
          <div>
            <OnOffByScroll place="General" />
          </div>
          {location.pathname === various.general.news ? (
            <>
              <div className={classes.contentTitle}>
                <a href={various.general.news}>
                  <ContentTitle title={newsAreaTitle} />
                </a>
              </div>
              <ScrollToTop />
              <div className={classes.hashTags}>
                {hash.map((h, index) => {
                  return (
                    <Button
                      onClick={(e) => handleSort(e, h)}
                      key={index}
                      classes={{ root: classes.hashButton }}
                    >
                      {h === activeHash ? (
                        <span id="active">{h}</span>
                      ) : (
                        <span>{h}</span>
                      )}
                    </Button>
                  );
                })}
              </div>
              <div className={classes.container}>{newsMapping()}</div>
              <div className={classes.arrow}>
                <Button onClick={handleClick}>
                  <img src={imageIndex.semianrPage.downArrow} alt="arrow" />
                </Button>
              </div>
              <Footer />
            </>
          ) : (
            <Outlet context={{ contentElements: news }} />
          )}
        </>
      );
    }
  };

  return contents();
};
export default News;
