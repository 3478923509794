import axios from 'axios';
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import {
  Button,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { images as imageApi } from '../Apis/apis';
import imageIndex from '../img/imageIndex';
import various from '../General/various';
import { AuthContext } from '../auth';
import { memoPatchUpdate } from '../Apis/apiOfManaged';
import { ogpGenerator } from '../ogp';
import Footer from '../Footer/footer';
import OnOffByScroll from '../Animation/onOffByScroll';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column',
    width: '500px',
    margin: '0 auto',
    '@media screen and (max-width:600px)': {
      width: '100%',
    },
  },
  logo: {
    marginTop: '160px',
    margin: '0 auto',
    '& > a': {
      '& > img': {
        display: 'inline-block',
        width: '160px',
      },
    },
  },
  formWrapper: {
    position: 'relative',
    width: '100%',
    height: '640px',
    marginTop: '80px',
    margin: '0 auto',
  },
  formContainer: {
    backgroundColor: '#F3F3F3',
    width: '100%',
    height: '100%',
    border: 'solid',
    borderRadius: '10px',
    borderColor: theme.palette.primary.main,

    display: 'flex',
    flexFlow: 'column',
    alignItems: 'start',
    padding: '32px 24px 0px 24px',
    '& > p': {
      color: theme.palette.primary.main,
      margin: '0 auto',
      marginBottom: '24px',
    },
  },
  textWrapper: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
  },
  textField: {
    width: '100%',
    marginTop: '40px',
    '& > label': {
      color: theme.palette.primary.defalt,
      fontWeight: 'bold',
    },
    '& > div': {
      marginTop: '40px',
      paddingTop: '4px',
      paddingBottom: '4px',
      '& > div': {
        color: '#FFF',
      },
    },
  },
  input: {
    fontSize: '0.8rem !important',
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  buttonWrapper: {
    marginTop: '64px',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    '& > button': {
      display: 'inline-block',
    },
  },
  apply: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: '#dcdcdc',
    border: 'solid',
    borderTop: 'none',
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    color: theme.palette.primary.main,
    '& > p': {
      fontSize: '0.9rem',
      textAlign: 'center',
      '& > a': {
        fontWeight: 'bold',
        textDecoration: 'underline',
      },
    },
  },
  forgot: {
    margin: '114px auto',
    '& > a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  error: {
    color: 'red',
    fontSize: '0.9rem',
    textAlign: 'start',
    marginTop: '32px !important',
  },
}));

const LogIn = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const { login } = useContext(AuthContext);

  const [image, setImage] = useState();

  const [token, setToken] = useState('');
  const [submitData, setSubmitData] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();

  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const key = searchParams.get('key');
  const value = searchParams.get('value');

  const classes = useStyles();

  // OGP設定
  const ogp = useCallback((props) => {
    ogpGenerator(props);
  }, []);

  useLayoutEffect(() => {
    if (image) {
      const ogpProps = {
        page: 'login',
        pageTitle: '刀禪OfficialWebSiteログインページ',
        path: location.pathname,
        imageUrl: image.OfficialImage_TouzenLogoBlack.url,
      };
      ogp(ogpProps);
    }
  }, [image, location, ogp]);
  // OGP設定ここまで

  useEffect(() => {
    imageApi(setImage);
  }, []);

  const onSubmit = async (data) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    } else {
      const reCaptchaToken = await executeRecaptcha('contact');
      setToken(reCaptchaToken);
      setSubmitData(data);
    }
  };

  useEffect(() => {
    if (submitData && token) {
      const data = { ...submitData };
      data['g_recaptcha_response'] = token;
      getJwt(data);
    }
    // eslint-disable-next-line
  }, [submitData, token]);

  const getJwt = async (data) => {
    await axios
      .post(`${various.currentDomain}api/recaptcha/`, data)
      .then((res) => {
        axios
          .post(
            `${various.currentDomain}api/auth/jwt/create/`,
            {
              email: data.email,
              password: data.password,
            },
            {
              headers: {
                Accept: 'application/json; charset=utf-8',
                'Content-type': 'application/json; charset=utf-8',
              },
            }
          )
          .then(function (response) {
            window.localStorage.setItem('accesstoken', response.data.access);
            window.localStorage.setItem('refreshtoken', response.data.refresh);

            login();
            const dataset = {
              email: data.email,
              password: data.password,
            };

            setTimeout(() => {
              memoPatchUpdate(dataset);
            }, [100]);

            if (state) {
              if (state.previous !== '/logout') {
                if (key && value) {
                  const params = {};
                  params[key] = value;
                  navigate({
                    pathname: state.previous,
                    search: createSearchParams(params).toString(),
                  });
                } else {
                  navigate(state.previous);
                }
              } else {
                navigate('/');
              }
            } else {
              navigate('/');
            }
          })
          .catch((err) => {
            alert('EmailまたはPasswordが違います');
          });
      })
      .catch((err) => {
        console.log('not varifying by reCAPTCH.');
      });
  };

  const contents = () => {
    if (image) {
      return (
        <>
          <div>
            <OnOffByScroll place="General" />
          </div>
          <div className={classes.container}>
            <div className={classes.logo}>
              <Link to={various.general.home}>
                <img src={image.OfficialImage_TouzenLogoRed.url} alt="logo" />
              </Link>
            </div>
            <div className={classes.formWrapper}>
              <div className={classes.formContainer}>
                <p>Log in to your account</p>
                <form
                  className={classes.textWrapper}
                  method="POST"
                  onSubmit={handleSubmit(onSubmit)}
                  id="recaptcha_form"
                >
                  <TextField
                    classes={{ root: classes.textField }}
                    id="account"
                    label="Email address"
                    type="email"
                    variant="outlined"
                    placeholder="Email address"
                    autoComplete="off"
                    inputProps={{ className: classes.input }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <imageIndex.loginPage.accountIcon
                            classes={{ root: classes.icon }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    {...register('email', {
                      required: {
                        value: true,
                        message:
                          'accountIDとなるEメールアドレスを入力してください',
                      },
                    })}
                  />
                  {errors.email && (
                    <div className={classes.error}>{errors.email.message}</div>
                  )}
                  <TextField
                    classes={{ root: classes.textField }}
                    id="password"
                    label="Password"
                    type="password"
                    variant="outlined"
                    placeholder="Password"
                    autoComplete="off"
                    inputProps={{ className: classes.input }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <imageIndex.loginPage.passwordIcon
                            classes={{ root: classes.icon }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    {...register('password', {
                      required: {
                        value: true,
                        message:
                          '半角英数字を使用し8文字以上のパスワードを設定してください',
                      },
                      minLength: {
                        value: 8,
                        message:
                          'パスワードは8文字以上とし英数字をしてください',
                      },
                    })}
                  />
                  {errors.password && (
                    <div className={classes.error}>
                      {errors.password.message}
                    </div>
                  )}
                  <div className={classes.buttonWrapper}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                    >
                      Log in
                    </Button>
                  </div>
                </form>
              </div>
              <div className={classes.apply}>
                <p>
                  入会・体験申込は
                  <Link to={various.general.contactForTraining}>こちら</Link>
                </p>
              </div>
            </div>
            <div className={classes.forgot}>
              <Link to={various.general.forgot}>パスワードを忘れた方</Link>
            </div>
          </div>
          <Footer />
        </>
      );
    }
  };

  return contents();
};
export default LogIn;
