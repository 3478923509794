import React, { useState, useRef, useEffect } from 'react';
import { Grow, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  topImage: {
    // transformOrigin: '50% 50% 0',
    // display: 'block',
    // width: '100%',
    // height: '100%',
    // objectFit: 'cover',
  },
  topPageLogo: {
    transformOrigin: '50% 50% 0px',
    display: 'block',
    position: 'absolute',
    top: 50,
    bottom: 50,
    left: 0,
    right: 0,
    margin: 'auto',
    width: '192px',
    // filter: 'drop-shadow(0px 24px 24px #808080)',
  },
  notAnimation: {
    position: 'absolute',
    top: '40%',
    bottom: '60%',
    left: 0,
    right: 0,
    margin: 'auto',
    '& > img': {
      width: '114px',
      objectFit: 'cover',
    },
  },
}));

const GrowIn = (props) => {
  const classes = useStyles();
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();

  const fixClass = (props) => {
    switch (props) {
      // case 'topImage':
      //   return classes.topImage;
      case 'topPageLogo':
        return classes.topPageLogo;
      case 'branch':
        return classes.branch;
      default:
    }
  };

  useEffect(() => {
    if (props.animation) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisible(true);
            observer.unobserve(domRef.current);
          }
        });
      });
      observer.observe(domRef.current);
      return () => observer.disconnect();
    }
  }, [props]);

  if (props.animation) {
    return (
      <Grow
        ref={domRef}
        className={isVisible ? fixClass(props.page) : ''}
        in={true}
        timeout={props.timeout}
      >
        {props.children}
      </Grow>
    );
  } else {
    return <div className={classes.notAnimation}>{props.children}</div>;
  }
};
export default GrowIn;
