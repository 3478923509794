import ContentTitle from '../General/contentTitle';
import TripleGridForTools from '../ImageGrids/tripleGridforTools';
// import FadeIn from '../Animation/fadeIn';
import { images as imageApi } from '../Apis/apis';

import { makeStyles } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  box: {},
  content: {
    marginLeft: '32px',
    '@media screen and (max-width:1000px)': {
      marginLeft: 0,
    },
  },
}));

const ToolsOnTopPage = () => {
  const [image, setImage] = useState();
  const [tools, setTools] = useState();
  const [dialog, setDialog] = useState();

  const [loading, setLoading] = useState(true);
  const [view, setView] = useState();
  const [contents, setContents] = useState();

  const classes = useStyles();
  const toolsAreaTitle = {
    titleList: ['Tools'],
    sub: '主な稽古道具',
  };
  // const fadeProps = {
  //   direction: '$up',
  //   duration: '1s',
  //   fillMode: 'forwards',
  //   timingFunction: 'ease-out',
  // };

  const callback = useCallback(() => {
    return (
      <div className={classes.box}>
        {/* <FadeIn fadeProps={fadeProps}> */}
        <ContentTitle title={toolsAreaTitle} />
        {/* </FadeIn> */}
        <div className={classes.content}>
          <TripleGridForTools images={tools} dialogs={dialog} />
        </div>
      </div>
    );
    // eslint-disable-next-line
  }, [tools, dialog, classes]);

  useEffect(() => {
    imageApi(setImage);
  }, []);

  useEffect(() => {
    if (tools && dialog) {
      setView(true);
      setLoading(false);
    }
  }, [tools, dialog]);

  useEffect(() => {
    if (view) {
      setContents(callback());
    }
  }, [view, callback]);

  useEffect(() => {
    const toolImages = [
      {
        key: 'Tools_bokutou',
        name: '木刀',
        english_name: 'Bokutou',
        caption: '万古不易の稽古道具です。太刀とも称します',
      },
      {
        key: 'Tools_maiougi',
        name: '舞扇',
        english_name: 'Maiougi',
        caption:
          '太刀の代わりにもなり、舞扇にしか醸せない高雅さと面性を養います',
      },
      {
        key: 'Tools_takejaku',
        name: '竹尺・金尺・小尺',
        english_name: 'Takejaku・Kanajaku・Kojaku',
        caption:
          'より柔軟性と携帯性を兼ね揃えたボディーワーク的な太刀稽古ができます',
      },
    ];
    const dialogImages = [
      { key: 'Tools_bokutou_dialog1' },
      { key: 'Tools_bokutou_dialog2' },
      { key: 'Tools_bokutou_dialog3' },
      { key: 'Tools_maiougi_dialog1' },
      { key: 'Tools_maiougi_dialog2' },
      { key: 'Tools_maiougi_dialog3' },
      { key: 'Tools_takejaku_dialog1' },
      { key: 'Tools_takejaku_dialog2' },
      { key: 'Tools_takejaku_dialog3' },
    ];
    const toolsResult = [];
    const dialogResult = [];
    if (image) {
      toolImages.forEach((obj) => {
        obj['image'] = image[obj.key];
        toolsResult.push(obj);
      });
      dialogImages.forEach((obj) => {
        obj['dialog'] = image[obj.key];
        dialogResult.push(obj);
      });
    }
    setTools(toolsResult);
    setDialog(dialogResult);
  }, [image]);

  return <>{loading ? null : view ? contents : null}</>;
};
export default ToolsOnTopPage;
