import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import {
  getCustomerPaymentsAndInvoices,
  getServicePlanDetail,
} from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import various from '../../../General/various';
import Footer from '../../../Footer/footer';
import { Spinner } from '../../../Spinner';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 40px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
    '& > p': {
      fontSize: '0.8rem',
      textAlign: 'start',
      color: 'grey',
    },
  },
  viewDataBtnContainer: {
    width: '100%',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',
    '@media screen and (max-width:600px)': {
      flexWrap: 'wrap',
      gap: '0px 16px',
    },
    '& > div': {
      margin: 'auto 0',
      '& > span': {
        display: 'inline-block',
      },
    },
    '& #viewBtn1, & #viewBtn3': {
      width: '20%',
      fontSize: '0.9rem',
      textAlign: 'start',
      '@media screen and (max-width:600px)': {
        width: '114px',
        textAlign: 'start',
      },
    },
    '& #viewBtn2, & #viewBtn4': {
      width: '100%',
      display: 'flex',
      textAlign: 'start',
      '& > select': {
        width: '100%',
        height: '40px',
        padding: '8px',
        fontFamily: theme.typography.fontFamily,
        border: '0.5px solid grey',
        borderRadius: '0.5em',
        boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
        '&:focus': {
          outline: `1.2px ${theme.palette.primary.main} outset`,
        },
      },
      '& > form': {
        display: 'flex',
        justifyContent: 'start',
        gap: '0px 16px',
        width: '100%',
        '& > input': {
          width: '70%',
          height: '40px',
          padding: '8px',
          fontFamily: theme.typography.fontFamily,
          border: '0.5px solid grey',
          borderRadius: '0.5em',
          boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
          '&:focus': {
            outline: `1.2px ${theme.palette.primary.main} outset`,
          },
        },
        '& > button': {
          width: '30%',
          display: 'block',
          fontFamily: theme.typography.fontFamily,
          fontWeight: 'bold',
          borderRadius: '0.5em',
          border: 'none',
          padding: '4px 8px',
          backgroundColor: theme.palette.primary.main,
          boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
          color: '#fff',
          cursor: 'pointer',
        },
      },
      '@media screen and (max-width:600px)': {
        width: '100%',
      },
    },
  },
  aggregate: {
    width: '100%',
    marginBottom: '40px',
    '& > table': {
      width: '100%',
      margin: '0 auto',
      borderCollapse: 'collapse',
      fontSize: '0.9em',
      '& > thead': {
        '& > tr': {
          '& > td': {
            minWidth: '96px',
            fontSize: '0.9rem',
            border: '1px solid #ccc',
          },
        },
      },
      '& > tbody': {
        '& > tr': {
          '& > td': {
            fontSize: '0.9rem',
            border: '1px solid #ccc',
            padding: '8px',
            textAlign: 'right',
            '& > button': {
              cursor: 'pointer',
            },
          },
          '& #category1, & #category2, & #category3': {
            textAlign: 'center',
          },
          '&:nth-child(even)': {
            backgroundColor: '#f8f8f8',
          },
          '&:focus': {
            backgroundColor: '#e0e0e0',
          },
        },
      },
    },
    '& > p': {
      fontSize: '0.8rem',
      textAlign: 'start',
      color: 'grey',
    },
  },
  container: {
    marginTop: '64px',
    '& > div': {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      gap: '16px 16px',
      '& > button': {
        border: 'none',
        textDecoration: 'underline',
        backgroundColor: 'transparent',
        cursor: 'pointer',
      },
    },
    '& > table': {
      width: '100%',
      margin: '0 auto',
      borderCollapse: 'collapse',
      fontSize: '0.9em',
      '& > thead': {
        '& > tr': {
          '& > td': {
            minWidth: '96px',
            fontSize: '0.9rem',
            border: '1px solid #ccc',
          },
        },
      },
      '& > tbody': {
        '& > tr': {
          '& > td': {
            fontSize: '0.9rem',
            border: '1px solid #ccc',
            padding: '8px',
            '& > button': {
              cursor: 'pointer',
            },
          },
          '&:nth-child(even)': {
            backgroundColor: '#f8f8f8', // 偶数行に薄いグレーを適用
          },
          '&:focus': {
            backgroundColor: '#e0e0e0', // フォーカス時に濃いグレーを適用
          },
        },
      },
    },
  },
}));

const MemberAllPayments = () => {
  const { isAuth } = useContext(AuthContext);
  const [servicePlans, setServicePlans] = useState();
  const [member, setMember] = useState();
  const [history, setHistory] = useState();
  const [viewData, setViewData] = useState([]);
  const [content, setContent] = useState();
  const [aggregate, setAggregate] = useState();
  const [yeard, setYeard] = useState();
  const [yearIndex, setYearIndex] = useState(0);

  const [date, setDate] = useState();
  const [firstDay, setFirstDay] = useState();
  const [lastDay, setLastDay] = useState();

  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '決済情報一覧', path: '/members_payment' },
    {
      name: '決済情報詳細',
      path: '/members_payment_detail',
      data: { uuid: location.state?.uuid },
    },
    { name: '決済情報更新', path: '/member_all_payments' },
  ];

  //   支払履歴データをStripeAPIから取得するか、DBから取得するかを判断する ---> 現状、APIからの取得はできるが表示までは未実装
  const BY_API = false;
  if (BY_API && history)
    console.log('get payments history in memberAllPayments.js: ', history);

  //   Init
  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  useEffect(() => {
    let uuid;
    if (query.get('id')) {
      uuid = query.get('id');
    } else {
      uuid = location.state.uuid;
    }
    if (uuid) {
      getServicePlanDetail(setServicePlans, uuid);
    }
  }, [location, query]);

  //   if (history) console.log('history in memberAllPayments.js: ', history);

  useEffect(() => {
    if (servicePlans) {
      setMember(servicePlans.member[0]);
      const data = {
        customer_id: servicePlans.stripe_customer.stripe_customer_id,
        by_api: BY_API,
      };
      getCustomerPaymentsAndInvoices(setHistory, data);
      setLoading(false);
    }
  }, [servicePlans, BY_API]);

  useEffect(() => {
    function extractYears(dataArray) {
      const years = dataArray.map((item) =>
        new Date(item.service_date_time).getFullYear()
      );

      return [...new Set(years)].sort((a, b) => b - a);
    }

    if (history) {
      setViewData(history.all);
      setYeard(extractYears(history.all));
      //   setYeard([2023, 2022, 2021]);
    }
  }, [history]);

  useEffect(() => {
    if (viewData) {
      setContent(contents());
      setAggregate(viewDataAggregate());
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [viewData]);

  //   date
  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = new Date().getMonth() + 1;

    // 月の1日を取得
    const firstDayFormatted = `${year}-${month.toString().padStart(2, '0')}-01`;

    // 月の最終日を取得
    const lastDay = new Date(year, month, 0);
    const lastDayFormatted = `${year}-${month
      .toString()
      .padStart(2, '0')}-${lastDay.getDate().toString().padStart(2, '0')}`;

    setFirstDay(firstDayFormatted);
    setLastDay(lastDayFormatted);
  }, []);

  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  //   handle
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleTransactions = (startDatetime, endDatetime) => {
    const startDate = new Date(startDatetime);
    const endDate = new Date(endDatetime);

    setViewData(
      history.all.filter((plan) => {
        const paymentDate = new Date(plan.service_date_time);
        return paymentDate >= startDate && paymentDate <= endDate;
      })
    );
  };

  const handleTransaction = (flag) => {
    if (history.all.length > 0) {
      if (flag === 'all') {
        setViewData(history.all);
      } else if (flag === 'completed') {
        const completed = history.all.filter((plan) => {
          return plan.payment_completed;
        });
        setViewData(completed);
      } else if (flag === 'failed') {
        const failed = history.all.filter((plan) => {
          return plan.payment_status === 'Failed';
        });
        setViewData(failed);
      } else if (flag === 'refund') {
        const refund = history.all.filter((plan) => {
          return plan.refund_id;
        });
        setViewData(refund);
      } else if (flag === 'subscription') {
        setViewData(history.invoices);
      } else if (flag === 'payment') {
        setViewData(history.payments);
      }
    }
  };

  const handleDetail = (uuid) => {
    navigate(various.general.membersPaymentDetail, {
      state: { uuid: uuid, previous: location.pathname },
    });
  };

  function handleNextYear() {
    console.log('come on');
    setYearIndex(yearIndex - 1);
    setViewData(
      history.all.filter((plan) => {
        const paymentDate = new Date(plan.service_date_time);
        return paymentDate.getFullYear() === yeard[yearIndex - 1];
      })
    );
  }

  function handlePreviousYear() {
    setYearIndex(yearIndex + 1);
    setViewData(
      history.all.filter((plan) => {
        const paymentDate = new Date(plan.service_date_time);
        return paymentDate.getFullYear() === yeard[yearIndex + 1];
      })
    );
  }

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    // second: '2-digit',
  };
  const contents = () => {
    if (viewData && yeard) {
      return (
        <>
          <div className={classes.container}>
            <div>
              {yearIndex < yeard.length - 1 && (
                <button onClick={handlePreviousYear}>
                  {'< ' + yeard[yearIndex + 1]}
                </button>
              )}
              <span>{yeard[yearIndex]}年度</span>
              {yearIndex > 0 && (
                <button onClick={handleNextYear}>
                  {yeard[yearIndex - 1] + ' >'}
                </button>
              )}
            </div>
            <table>
              <thead>
                <tr>
                  <td>日時</td>
                  <td>決済対象</td>
                  <td>決済区分</td>
                  <td>決済金額</td>
                  <td>決済status</td>
                  <td>返金額</td>
                  <td>返金status</td>
                  <td>詳細</td>
                </tr>
              </thead>
              <tbody>
                {viewData.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {new Intl.DateTimeFormat('ja-JP', options).format(
                          new Date(data.created_at)
                        )}
                      </td>
                      <td>
                        {data.service_name === 'membership'
                          ? '会費'
                          : data.service_name}
                      </td>
                      <td>
                        {data.payment_id_type === 'subscription'
                          ? '定期決済'
                          : data.payment_id_type === 'payment'
                          ? '都度決済'
                          : 'その他'}
                      </td>
                      <td>{data.price.toLocaleString()}</td>
                      <td>
                        {data.payment_completed
                          ? '決済完了'
                          : data.payment_status === 'Failed'
                          ? '決済失敗'
                          : data.refund_id
                          ? '返金'
                          : '未決済'}
                      </td>
                      <td>
                        {data.amount_refunded ? data.amount_refunded : '-'}
                      </td>
                      <td>
                        {data.refund_status === 'succeeded'
                          ? '返金完了'
                          : !data.refund_status
                          ? '-'
                          : '返金失敗'}
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={(e) => handleDetail(data.uuid)}
                        >
                          詳細
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      );
    }
  };

  const viewDataAggregate = () => {
    if (viewData) {
      // subscription
      const subscriptionTotal = viewData.reduce(
        (sum, obj) =>
          obj.payment_id_type === 'subscription' ? sum + obj.price : sum,
        0
      );
      const subscriptionFeeTotal = viewData.reduce(
        (sum, obj) =>
          obj.payment_id_type === 'subscription'
            ? sum + obj.payment_fee_total
            : sum,
        0
      );
      const subscriptionRefundTotal = viewData.reduce(
        (sum, obj) =>
          obj.payment_id_type === 'subscription' && obj.refund_id
            ? sum + obj.amount_refunded
            : sum,
        0
      );
      const subscriptionRefundFeeTotal = viewData.reduce(
        (sum, obj) =>
          obj.payment_id_type === 'subscription'
            ? sum + obj.refund_fee_total
            : sum,
        0
      );
      const subscriptionCount = viewData.filter((data) => {
        return data.payment_id_type === 'subscription';
      }).length;
      const subscriptionRefundCount = viewData.filter((data) => {
        return data.payment_id_type === 'subscription' && data.refund_id;
      }).length;

      // payment
      const paymentTotal = viewData.reduce(
        (sum, obj) =>
          obj.payment_id_type === 'payment' ? sum + obj.price : sum,
        0
      );
      const paymentFeeTotal = viewData.reduce(
        (sum, obj) =>
          obj.payment_id_type === 'payment' ? sum + obj.payment_fee_total : sum,
        0
      );
      const paymentRefundTotal = viewData.reduce(
        (sum, obj) =>
          obj.payment_id_type === 'payment' && obj.refund_id
            ? sum + obj.amount_refunded
            : sum,
        0
      );
      const paymentRefundFeeTotal = viewData.reduce(
        (sum, obj) =>
          obj.payment_id_type === 'payment' ? sum + obj.refund_fee_total : sum,
        0
      );
      const paymentCount = viewData.filter((data) => {
        return data.payment_id_type === 'payment';
      }).length;
      const paymentRefundCount = viewData.filter((data) => {
        return data.payment_id_type === 'payment' && data.refund_id;
      }).length;

      // deducted
      // 入会金控除スキームの運用停止に伴い、以下のコードはコメントアウト
      // const deductedTotalOfSubscription = viewData.reduce(
      //   (sum, obj) =>
      //     obj.product.is_deduction_model &&
      //     obj.payment_id_type === 'subscription'
      //       ? sum + obj.deducted_amount
      //       : sum,
      //   0
      // );
      // const deductedTotalOfPayment = viewData.reduce(
      //   (sum, obj) =>
      //     obj.product.is_deduction_model && obj.payment_id_type === 'payment'
      //       ? sum + obj.deducted_amount
      //       : sum,
      //   0
      // );

      // total
      const total = subscriptionTotal + paymentTotal;
      const totalFee = subscriptionFeeTotal + paymentFeeTotal;
      const totalRefund = subscriptionRefundTotal + paymentRefundTotal;
      const totalRefundFee = subscriptionRefundFeeTotal + paymentRefundFeeTotal;
      // const totalDeductionFee =
      //   deductedTotalOfSubscription + deductedTotalOfPayment;
      const totalCount = subscriptionCount + paymentCount;
      const totalRefundCount = subscriptionRefundCount + paymentRefundCount;

      return (
        <div className={classes.aggregate}>
          <table>
            <thead>
              <tr>
                <td></td>
                <td>決済金額</td>
                <td>決済手数料</td>
                <td>返金額</td>
                <td>返金手数料</td>
                <td>差し引き</td>
                {/* <td>控除済入会金額</td> */}
                <td>決済件数</td>
                <td>返金件数</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td id="category1">定期決済</td>
                <td>{subscriptionTotal.toLocaleString()}</td>
                <td>{subscriptionFeeTotal.toLocaleString()}</td>
                <td>{subscriptionRefundTotal.toLocaleString()}</td>
                <td>{subscriptionRefundFeeTotal.toLocaleString()}</td>
                <td>
                  {(
                    subscriptionTotal -
                    (subscriptionFeeTotal +
                      subscriptionRefundTotal +
                      subscriptionRefundFeeTotal)
                  ).toLocaleString()}
                </td>
                {/* <td>{deductedTotalOfSubscription.toLocaleString()}</td> */}
                <td>{subscriptionCount.toLocaleString()}</td>
                <td>{subscriptionRefundCount.toLocaleString()}</td>
              </tr>
              <tr>
                <td id="category2">都度決済</td>
                <td>{paymentTotal.toLocaleString()}</td>
                <td>{paymentFeeTotal.toLocaleString()}</td>
                <td>{paymentRefundTotal.toLocaleString()}</td>
                <td>{paymentRefundFeeTotal.toLocaleString()}</td>
                <td>
                  {(
                    paymentTotal -
                    (paymentFeeTotal +
                      paymentRefundTotal +
                      paymentRefundFeeTotal)
                  ).toLocaleString()}
                </td>
                {/* <td>{deductedTotalOfPayment.toLocaleString()}</td> */}
                <td>{paymentCount.toLocaleString()}</td>
                <td>{paymentRefundCount.toLocaleString()}</td>
              </tr>
              <tr>
                <td id="category3">合計</td>
                <td>{total.toLocaleString()}</td>
                <td>{totalFee.toLocaleString()}</td>
                <td>{totalRefund.toLocaleString()}</td>
                <td>{totalRefundFee.toLocaleString()}</td>
                <td>
                  {(
                    total -
                    (totalFee + totalRefund + totalRefundFee)
                  ).toLocaleString()}
                </td>
                {/* <td>{totalDeductionFee.toLocaleString()}</td> */}
                <td>{totalCount.toLocaleString()}</td>
                <td>{totalRefundCount.toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
          <p>
            ※現在表示されているデータにおける集計結果です。
            {/* <br />
            ※「決済金額」は入会金控除額を差し引いた後の金額です。 */}
          </p>
        </div>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>

      {isAuth && member && firstDay && lastDay ? (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">
                {member.last_name + member.first_name}
                さんの決済履歴
              </span>
              <span>{date}</span>
            </div>
          </div>
          {loading ? <Spinner /> : aggregate}
          <BreadCrumbs crumbs={CRUMBS} />
          <hr />
          <div className={classes.viewDataBtnContainer}>
            <div id="viewBtn1">
              <span>絞り込み&nbsp;:&nbsp;</span>
            </div>
            <div id="viewBtn2">
              <select
                onChange={(e) => handleTransaction(e.target.value)}
                defaultValue="default"
              >
                <option value="default">選択してください</option>
                <option value="all">すべて</option>
                <option value="completed">決済完了</option>
                <option value="failed">決済失敗</option>
                <option value="refund">返金</option>
                <option value="subscription">定期決済</option>
                <option value="payment">都度決済</option>
              </select>
            </div>
          </div>
          <div className={classes.viewDataBtnContainer}>
            <div id="viewBtn3">
              <span>期間指定&nbsp;:&nbsp;</span>
            </div>
            <div id="viewBtn4">
              <form
                onSubmit={(e) => {
                  handleTransactions(e.target.start.value, e.target.end.value);
                  e.preventDefault();
                }}
              >
                <input
                  type="date"
                  id="start"
                  name="start"
                  value={firstDay}
                  onChange={(e) => setFirstDay(e.target.value)}
                />
                <input
                  type="date"
                  id="end"
                  name="end"
                  value={lastDay}
                  onChange={(e) => setLastDay(e.target.value)}
                />
                <button type="submit">期間絞り込み</button>
              </form>
            </div>
          </div>
          {loading ? <Spinner /> : content}
        </>
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
      <Footer />
    </>
  );
};
export default MemberAllPayments;
