import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import various from '../General/various';

const LogOut = () => {
  const navigate = useNavigate();
  const logouting = () => {
    console.log('logout...');
    alert('ログアウトしました');
  };

  useEffect(() => {
    window.localStorage.removeItem('Authorization');
    window.localStorage.removeItem('accesstoken');
    window.localStorage.removeItem('refreshtoken');
    window.localStorage.removeItem('permission');
    window.localStorage.setItem(various.permissionKey, null);
    navigate(various.general.home);
  }, [navigate]);

  return logouting();
};
export default LogOut;
