import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import AlertDialog from '../Dialog/alertDialog';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { memberExistanceCheckApi } from '../Apis/apis';
import { emailRegValidator } from '../CommonFunction/validators';
import ArrowButton from '../General/arrowButton';
import { userCreateByAddNewsLetter } from '../Apis/apis';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& #title': {
      color: '#FFF',
      textAlign: 'start',
      marginBottom: '24px',
      fontWeight: 'bold',
    },
    '& #itemRoot': {
      '& > form': {
        display: 'flex',
        '& > input': {
          width: '100%',
          minWidth: '104px',
          backgroundColor: 'transparent',
          height: '30px',
          padding: 'none',
          color: '#FFF',
          outline: 'none',
          border: 'none',
          borderBottom: '1px solid #FFF',
        },
        ' #arrow': {},
      },
      '& > div': {
        color: 'pink',
        fontSize: '0.7em',
        textAlign: 'start',
        '& > span': {
          display: 'inline-block',
          fontSize: '0.5rem',
          color: 'grey',
          '& > a': {
            color: 'grey',
            fontWeight: 'bold',
            textDecoration: 'underline',
          },
        },
      },
    },
  },
}));

const ToNewsLetter = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {},
  });

  const classes = useStyles();
  const [exist, setExist] = useState(true);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const [token, setToken] = useState('');
  const [submitData, setSubmitData] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const signUpToNewsletter = (open) => {
    let title;
    let content;
    if (open) {
      if (exist) {
        title = '刀禅ニュースレター配信のご登録完了';
        content = `ご登録いただき、ありがとうございました。次の配信をお待ちください。なお、ご登録いただいたメールアドレス宛に返信メールを送信しています。ご確認ください。`;
      } else {
        title = 'ご案内';
        content = `ご入力いただいたEメールアドレスでは登録できません。`;
      }
    }
    return { title: title, content: content, judge: open };
  };

  useEffect(() => {
    if (confirm) setOpen(true);
  }, [confirm]);

  const onSubmit = async (data) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    } else {
      const reCaptchaToken = await executeRecaptcha('contact');
      setToken(reCaptchaToken);
      setSubmitData(data);
    }
  };

  useEffect(() => {
    if (submitData && token) {
      const data = { ...submitData };
      data['token'] = token;
      apiHandler(data);
    }
    // eslint-disable-next-line
  }, [submitData, token]);

  const apiHandler = (data) => {
    if (exist) {
      const protocol = window.location.protocol;
      const host = window.location.host;
      const domain = document.domain;
      const path = 'unsubscribe';
      const dataset = {
        email: data.newsletter_input,
        protocol: protocol,
        host: host,
        domain: domain,
        path: path,
        g_recaptcha_response: data.token,
      };
      userCreateByAddNewsLetter(dataset, setConfirm);
    }
  };

  const memberExistanceCheck = (v) => {
    memberExistanceCheckApi(setExist, {
      email: v,
      flag: 'newsletter',
    });
    return true;
  };
  useEffect(() => {
    if (!exist) {
      setOpen(true);
    }
  }, [exist]);

  return (
    <div className={classes.container}>
      <div id="title">Sign up to our newsletter</div>
      <div id="itemRoot">
        <form
          autoComplete="off"
          method="POST"
          encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            id="newsletter_input"
            type="email"
            placeholder="E-MAIL"
            {...register('newsletter_input', {
              required: { value: true, message: '入力必須の項目です' },
              validate: {
                message: (v) =>
                  emailRegValidator(v)
                    ? memberExistanceCheck(v)
                      ? null
                      : `ご入力されたEメールでは送信できません。`
                    : `※正しいEメールの形式で入力してください`,
              },
            })}
          />
          <div id="arrow">
            <ArrowButton color="#FFF" />
          </div>
        </form>
        <div>
          <span>
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">
              Terms of Service
            </a>{' '}
            apply.
          </span>
        </div>
        {errors.newsletter_input?.message && (
          <div>{errors.newsletter_input.message}</div>
        )}
        <AlertDialog
          addFunc={signUpToNewsletter}
          setOpen={setOpen}
          open={open}
        />
      </div>
    </div>
  );
};

export default ToNewsLetter;
