import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { makeStyles } from '@material-ui/core';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import imageIndex from '../img/imageIndex';
import various from '../General/various';
import ArrowButton from '../General/arrowButton';
import ScrollToTop from '../General/scrollToTop';
import { branchIndexForFront } from '../Apis/apis';
import Footer from '../Footer/footer';
import { images as imageApi } from '../Apis/apis';
import OnOffByScroll from '../Animation/onOffByScroll';
import { ogpGenerator } from '../ogp';
import { useLocation, useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    marginRight: 'calc(50% - 50vw)',
    marginLeft: 'calc(50% - 50vw)',
    height: '100vh',
    position: 'relative',
    '& #topImage': {
      width: '100%',
      height: '100vh',
      marginTop: '56px',

      '& > img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: '50% 0%',
        filter: 'contrast(180%) grayscale(100%) saturate(0%)',
      },
    },
    '& #topImageWrap': {
      display: 'flex',
      justifyContent: 'end',
      '& > div': {
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        color: '#FFF',
        '& > div': {
          fontWeight: 'bold',
          fontSize: '3.5rem',
          color: '#FFF',

          '& > span': {
            color: theme.palette.primary.main,
          },
          '& > br': {
            display: 'none',
          },
        },
        '& > hr': {
          width: '20%',
        },
        '& > p': {
          textAlign: 'center',
          textStroke: '0.5px whitesmoke',
          fontSize: '1rem',
        },
        '& > span': {
          fontSize: '0.8rem',
          display: 'flex',
          flexDirection: 'column',
          '& > a': {
            color: `lightsteelblue !important`,
            fontWeight: 'bold',
            '&:visited': {
              color: '#FFF',
            },
          },
        },
        '@media screen and (max-height:400px)': {
          '& > p': {
            marginBottom: '0px',
          },
          '& > span': {
            fontSize: '0.7rem',
            lineHeight: '16px',
          },
        },
        '@media screen and (max-width:640px)': {
          top: '60%',
          '& > div': {
            '& > br': {
              display: 'inline !important',
            },
            '& #nakaguro': {
              display: 'none',
            },
          },
          '& > span': {
            display: 'inline-block',
            fontSize: '0.8rem',
          },
        },
      },
    },
  },

  contentBox: {
    marginTop: '168px',
  },
  branchWrapper: {
    marginBottom: '208px',
  },
  title: {
    display: 'flex',
    justifyContent: 'start',
    flexFlow: 'column',
    marginBottom: '48px',

    '& > div': {
      textAlign: 'start',
    },

    '& > p': {
      fontSize: '0.9rem',
      marginLeft: '12px',
      textAlign: 'start',
    },

    '@media screen and (max-width:600px)': {
      '& > p': {
        marginLeft: '0px',
      },
    },
  },
  branchName: {
    fontSize: '3rem',
    fontWeight: 'bold',
    textShadow: '1px 1px 1px grey',
    lineHeight: '48px',

    '& > span': {
      color: theme.palette.primary.main,
    },

    '@media screen and (max-width:600px)': {
      fontSize: '2.5rem',
    },
  },
  line: {
    display: 'none',
    '@media screen and (max-width:460px)': {
      display: 'inline',
    },
  },
  snsIcons: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'nowrap',

    '& > a': {
      display: 'flex',
      alignItems: 'center',
      marginRight: '24px',
    },
    '& #blog': {
      fill: 'transparent',
      stroke: 'black',
      width: '22px',
    },
    '& #calendar': {
      fill: '#000',
      width: '22px',
    },
  },

  image: {},
  branchContent: {},
  information: {},
  childInfo: {},
  byOrganizer: {},
  contentsContainer: {
    display: 'flex',
    margin: '0 auto',
    width: '100%',
    marginBottom: '208px',

    '& $branchContent': {
      display: 'flex',
      flexFlow: 'column',
      width: 'calc(70vw * 0.5)',
      marginRight: '64px',

      '& $information': {
        display: 'flex',
        flexFlow: 'column',
        margin: '0px 16px',

        '& > div': {
          display: 'flex',
          justifyContent: 'space-between',
          gap: '0px 8px',
          flexWrap: 'wrap',
          width: '100%',
          marginBottom: '16px',
          fontSize: '1.1rem',
          borderBottmo: 'solid 2px black',
          '&:hover': {
            fontWeight: 'bold',
          },
          '& $childInfo': {
            fontSize: '0.95rem',
          },
          '& > span': {
            display: 'inline-block',
            textAlign: 'end',
          },
          '@media screen and (max-width:800px)': {
            flexFlow: 'column',
            justifyContent: 'flex-start',
            textAlign: 'start',
            '& > span': {
              textAlign: 'start',
            },
          },
        },
      },
      '& $byOrganizer': {
        textAlign: 'justify',
        borderTop: 'solid 1px black',
        margin: '0px 16px',
        padding: '32px 0px',
        fontSize: '1rem',
        letterSpacing: '0.5px',
        lineHeight: '20px',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
      },
    },
    '& $image': {
      width: 'calc(70vw * 0.5)',
      '& > img': {
        width: 'calc(70vw * 0.5)',
        height: 'calc(70vw * 0.5)',
        objectFit: 'cover',
        filter:
          'contrast(120%) grayscale(100%) saturate(0%) drop-shadow(4px 4px 4px black)',
      },
    },

    '@media screen and (max-width:1000px)': {
      flexWrap: 'wrap-reverse',
      '& $branchContent': {
        marginRight: '0px !important',
      },
      '& > div': {
        width: '92vw !important',
        // paddingLeft: '32px',
        marginRight: 0,

        '& $information': {
          marginLeft: '0px',
          '& > div': {
            width: '100%',
            justifyContent: 'start !important',
            gap: '0px 32px !important',
            '& > span': {
              textAlign: 'start !important',
            },
          },
        },
        '& $byOrganizer': {
          padding: '32px 32px',
        },

        '& > img': {
          marginBottom: '32px',
          width: '92vw !important',
          height: 'calc(92vw / 1.5) !important',
        },
      },
    },
    '@media screen and (max-width:600px)': {
      '& > div': {
        paddingLeft: '0px',
      },
    },
  },
}));

const Branch = () => {
  const [branches, setBranches] = useState([]);

  const [image, setImage] = useState();

  const [loading, setLoading] = useState(true);
  const [view, setView] = useState();
  const [top, setTop] = useState();
  const [contents, setContents] = useState();
  const [hashList, setHashList] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const element = useRef();
  const classes = useStyles();

  // OGP設定
  const ogp = useCallback((props) => {
    ogpGenerator(props);
  }, []);

  useLayoutEffect(() => {
    if (image) {
      const ogpProps = {
        page: 'branch',
        pageTitle: '刀禪OfficialWebSite支部・同好会・稽古会一覧',
        path: location.pathname,
        imageUrl: image.OfficialImage_TouzenLogoBlack.url,
      };
      ogp(ogpProps);
    }
  }, [image, location, ogp]);
  // OGP設定ここまで

  const handleClick = (uuid) => {
    navigate(various.general.contactForClub, { state: { uuid: uuid } });
  };

  const callbackTop = useCallback(() => {
    return (
      <>
        <div>
          <OnOffByScroll place="General" />
        </div>
        <div className={classes.container}>
          <div id="topImage">
            <img
              src={image.BranchPageImages_branchTopImage.url}
              alt="branchTopImage"
            />
          </div>
          <div id="topImageWrap">
            <div>
              <div>
                <span>刀</span>禅同好会
                <br />
                <span id="nakaguro">・</span>
                <span>特</span>別稽古会
              </div>
              <hr />
              <p>
                日本各地で刀禅会員有志による
                <br />
                同好会・稽古会が開催されています
                <br />
                お近くの会をお探しください
              </p>
              <span>{hashList}</span>
            </div>
          </div>
        </div>
      </>
    );
    // eslint-disable-next-line
  }, [image, hashList, classes]);

  const callback = useCallback(() => {
    let areaList = [];
    const result = [];
    branches.map((branch, index) => {
      const snsKeys = Object.keys(branch.sns);

      let areaId;
      if (!areaList.includes(branch.area[0])) {
        areaList.push(branch.area[0]);
        areaId = branch.area[0];
      } else {
        areaId = null;
      }
      let branchName;
      switch (branch.division) {
        case 'club':
          branchName = (
            <>
              刀禅
              <br className={classes.line} />
              <span>{branch.name}</span>同好会
            </>
          );
          break;
        case 'specialClub':
          branchName = (
            <>
              <span>{branch.name}</span>
              {branch.subName}
            </>
          );
          break;
        default:
      }

      return result.push(
        <div
          id={areaId ? areaId : null}
          key={index}
          className={classes.branchWrapper}
        >
          <div id={branch.id} className={classes.title}>
            <div className={classes.branchName}>{branchName}</div>
            <p className={classes.snsIcons}>
              {snsKeys.map((key, _i) => {
                let icon;
                let Blog;
                let Calendar;
                if (branch.sns[key]) {
                  switch (key) {
                    case 'website':
                      icon = imageIndex.branchPage.webIcon;
                      break;
                    case 'blog':
                      Blog = imageIndex.branchPage.blogIcon;
                      break;
                    case 'twitter':
                      icon = imageIndex.branchPage.twIcon;
                      break;
                    case 'facebook':
                      icon = imageIndex.branchPage.fbIcon;
                      break;
                    case 'instagram':
                      icon = imageIndex.branchPage.instagramIcon;
                      break;
                    case 'youtube':
                      icon = imageIndex.branchPage.youtubeIcon;
                      break;
                    case 'calendar':
                      Calendar = imageIndex.branchPage.scheduleIcon;
                      break;
                    default:
                  }
                  if (key === 'blog') {
                    return (
                      <a href={branch.sns[key]} key={`snsKeys_${_i}`}>
                        <Blog id="blog" />
                      </a>
                    );
                  } else if (key === 'calendar') {
                    return (
                      <a href={branch.sns[key]} key={`snsKeys_${_i}`}>
                        <Calendar id="calendar" />
                      </a>
                    );
                  } else {
                    return (
                      <a href={branch.sns[key]} key={`snsKeys_${_i}`}>
                        <img src={icon} alt="" />
                      </a>
                    );
                  }
                } else {
                  return <span key={`snsKeys_${_i}`}></span>;
                }
              })}
            </p>
            <p>
              {branch.area.map((a, __i) => {
                return <span key={`area_${__i}`}>#&nbsp;{a}&nbsp;&nbsp;</span>;
              })}
            </p>
          </div>
          <div className={classes.contentsContainer}>
            <div className={classes.branchContent}>
              <div className={classes.information}>
                <div>
                  <span style={{ fontWeight: 'bold' }}>代表</span>
                  <span className={classes.childInfo}>
                    {branch.presidentName}
                  </span>
                </div>
                <div>
                  <span style={{ fontWeight: 'bold' }}>地域</span>
                  <span className={classes.childInfo}>{branch.areaDetail}</span>
                </div>
                <div>
                  <span style={{ fontWeight: 'bold' }}>稽古日</span>
                  <span className={classes.childInfo}>
                    {branch.schedule.map((s, ___i) => {
                      return (
                        <span key={`sch_${___i}`}>
                          {s}
                          <br />
                        </span>
                      );
                    })}
                  </span>
                </div>
                <div>
                  <span style={{ fontWeight: 'bold' }}>専門分野</span>
                  <span className={classes.childInfo}>
                    {branch.expertise.map((exp, i) => {
                      return (
                        <span key={`exp_${i}`}>
                          {exp}&nbsp;
                          {i !== branch.expertise.length - 1 ? '/' : undefined}
                          &nbsp;
                        </span>
                      );
                    })}
                  </span>
                </div>
              </div>
              <div
                id={`byOrganizer_${branch.id}`}
                className={classes.byOrganizer}
              >
                {branch.information}
              </div>
              <div
                onClick={() => handleClick(branch.id)}
                key={`arrow_${index}`}
              >
                <ArrowButton buttonName="CONTACT" color="#000" />
              </div>
            </div>
            <div
              className={classes.image}
              ref={index === 0 ? element : undefined}
              id={index === 0 ? `target_${index}` : undefined}
            >
              <img src={branch.image} alt={`branch_image_${index}`} />
            </div>
          </div>
        </div>
      );
    });
    return result;
    // eslint-disable-next-line
  }, [branches, classes]);

  useLayoutEffect(() => {
    // Test的に日吉のみテキストリンクを埋める。他からの要望等が多かったらDB改変の上、管理画面に実装予定
    if (contents) {
      const targetList = ['日吉'];
      let target = branches.filter((branch) => {
        return targetList.includes(branch.name);
      });
      if (target.length > 0) {
        target = target[0];
        const targetText = 'こちら';
        const targetURL = 'calendar';
        const information = document.querySelector(`#byOrganizer_${target.id}`);
        if (information) {
          // const object = branches[targetIndex];
          const url = target.sns[targetURL];
          const reg = new RegExp(targetText);
          const linkText = `<a style="color:#7C2A2E;" href=${url}>${targetText}</a>`;
          const changedText = information.innerHTML.replace(reg, linkText);
          information.innerHTML = changedText;
        }
      }
    }
  }, [contents, branches]);

  useEffect(() => {
    if (branches) {
      const raw = branches.map((b) => {
        let area;
        b.area.forEach((a) => {
          area = a;
        });
        return area;
      });
      let set = new Set(raw);
      setHashList(
        Array.from(set).map((area, index) => {
          return (
            <AnchorLink key={index} href={`#${area}`}>
              #&nbsp;{area}
              <br />
            </AnchorLink>
          );
        })
      );
    }
  }, [branches]);

  useEffect(() => {
    branchIndexForFront(setBranches);
  }, []);

  useEffect(() => {
    imageApi(setImage);
  }, []);

  useEffect(() => {
    if (image) {
      setTop(callbackTop());
      setView(true);
      setLoading(false);
    }
  }, [image, callbackTop]);

  useEffect(() => {
    if (top) {
      setContents(callback());
    }
  }, [top, callback]);

  return (
    <>
      <div>{top}</div>
      <ScrollToTop />
      {loading ? null : view ? (
        <>
          <div className={classes.contentBox}>{contents}</div>
          <Footer />
        </>
      ) : null}
    </>
  );
};
export default Branch;
