import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import { authTestDetail } from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import various from '../../../General/various';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  row: {},
  data: {},
  container: {
    width: '100%',
    '& > table': {
      width: '90%',
      margin: '0 auto',
      borderCollapse: 'collapse',
      '& > tbody': {
        width: '100%',
        '& > tr': {
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          wordBreak: 'break-all',
          wordWrap: 'breal-all',
          lineHeight: '64px',
          borderBottom: '1px grey solid',
          '& $row': {
            width: '30%',
            margin: 'auto 0',
            lineHeight: '32px',
            textAlign: 'end',
            paddingRight: '32px',
          },
          '& $data': {
            width: '70%',
            lineHeight: '32px',
            textAlign: 'start',
            padding: '16px 8px 16px 32px',
          },
          '& #image': {
            padding: '16px 8px 16px 32px',
            textAlign: 'start',
            '& > img': {
              maxWidth: '250px',
              maxHeight: '250px',
              objectFit: 'contain',
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(220,220,220,0.3)',
          },
        },
      },
    },
  },
  updateBtn: {
    marginTop: '80px',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
    },
  },
}));

const Detail = () => {
  const { isAuth } = useContext(AuthContext);
  const [detailData, setDetailData] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  useEffect(() => {
    if (location) {
      authTestDetail(setDetailData, location.state.uuid);
    }
  }, [location]);

  const handleClick = (uuid) => {
    navigate(various.general.update, {
      state: { uuid: uuid, previous: location.pathname },
    });
  };

  // useEffect(() => {
  //   if (detailData) {
  //     console.log('detaiData : ', detailData);
  //   }
  // }, [detailData]);

  const contentTable = () => {
    if (detailData) {
      return (
        <div className={classes.container}>
          <table>
            <tbody>
              {Object.keys(detailData).map((k, i) => {
                switch (k) {
                  case 'image':
                    return (
                      <tr key={i}>
                        <td className={classes.row}>{k}</td>
                        <td id="image">
                          <img src={detailData[k]} alt="detail_image" />
                        </td>
                      </tr>
                    );
                  default:
                }
                return (
                  <tr key={i}>
                    <td className={classes.row}>{k}</td>
                    <td className={classes.data}>{detailData[k]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className={classes.updateBtn}>
            <button onClick={() => handleClick(detailData.uuid)}>更新</button>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>AuthTest情報詳細</div>
      <hr />
      {isAuth ? (
        contentTable()
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default Detail;
