import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import {
  getStripeCustomerInfo,
  memberDetail,
  paramsIndex,
} from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import various from '../../../General/various';
import Footer from '../../../Footer/footer';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  updateBtn: {},
  container: {
    width: '100%',
    '& > div': {
      width: '80%',
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      marginTop: '40px',
      marginBottom: '48px',
      '& > span': {
        // display: 'inline',
        width: '40%',
        fontSize: '0.9rem',
        fontWeight: 'bold',
        textAlign: 'end',
        // verticalAlign: 'middle',
        lineHeight: '40px',
        marginRight: '16px',
      },
      '& > input, & > textarea': {
        width: '60%',
        height: '40px',
        padding: '8px',
        fontFamily: theme.typography.fontFamily,
        border: '0.5px solid grey',
        borderRadius: '0.9em',
        boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
        '&:focus': {
          outline: `1.2px ${theme.palette.primary.main} outset`,
        },
      },
      '& > textarea': {
        minHeight: '160px',
      },
      '@media screen and (max-width:500px)': {
        width: '100%',
      },
    },
    '& $updateBtn': {
      width: '100%',
      marginTop: '80px',
      '& > button': {
        width: '100%',
        display: 'block',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        borderRadius: '0.5em',
        border: 'none',
        padding: '4px 8px',
        backgroundColor: theme.palette.primary.main,
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        color: '#fff',
        cursor: 'pointer',
      },
    },
  },
}));

const MemberDetail = () => {
  const { isAuth } = useContext(AuthContext);
  const [detailData, setDetailData] = useState();
  const [stripeCustomer, setStripeCustomer] = useState();
  const [params, setParams] = useState();
  const [runStripe, setRunStripe] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '会員一覧', path: '/member_index' },
    { name: '会員情報詳細', path: '/member_detail' },
  ];

  // if (stripeCustomer) console.log(stripeCustomer);

  // Init
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  useEffect(() => {
    let uuid;
    if (query.get('id')) {
      uuid = query.get('id');
    } else {
      uuid = location.state.uuid;
    }
    if (uuid) {
      memberDetail(setDetailData, uuid);
    } else {
      memberDetail(setDetailData);
    }
  }, [location, query]);

  useEffect(() => {
    if (detailData) {
      getStripeCustomerInfo(setStripeCustomer, detailData.email);
    }
  }, [detailData]);

  useEffect(() => {
    paramsIndex(setParams);
  }, []);

  useEffect(() => {
    if (params && detailData) {
      const is_run = params.find((param) => {
        return param.title === 'run_stripe_in_production';
      });
      const is_all_members = params.find((param) => {
        return param.title === 'run_stripe_all_members';
      });
      if (is_run?.apply_is) {
        if (is_all_members?.apply_is) {
          setRunStripe(true);
        } else {
          if (various.stripe.testRunStaff.includes(detailData.email)) {
            setRunStripe(true);
          }
        }
      }
    }
  }, [params, detailData]);

  // handle
  const handleClick = (uuid) => {
    navigate(various.general.memberUpdate, {
      state: { uuid: uuid, previous: location.pathname },
    });
  };

  // util
  const getAddress = () => {
    if (detailData) {
      let result;
      if (detailData.address1) result = detailData.address1;
      if (detailData.address2) result += detailData.address2;
      if (detailData.address3) result += detailData.address3;
      if (detailData.address4) result += detailData.address4;
      return result;
    }
  };

  // content
  const contentTable = () => {
    if (detailData) {
      return (
        <>
          <div className={classes.container}>
            <div>
              <span>氏名</span>
              <input
                type="text"
                value={detailData.last_name + ' ' + detailData.first_name}
                readOnly
              />
            </div>
            <div>
              <span>氏名（カナ）</span>
              <input
                type="text"
                value={
                  detailData.last_name_kana + ' ' + detailData.first_name_kana
                }
                readOnly
              />
            </div>
            <div>
              <span>性別</span>
              <input
                type="text"
                value={
                  detailData.sex === 'male'
                    ? '男性'
                    : detailData.sex === '男性'
                    ? detailData.sex
                    : '女性'
                }
                readOnly
              />
            </div>
            <div>
              <span>学生・社会人区分</span>
              <input
                type="text"
                value={detailData.is_student ? '学生' : '社会人'}
                readOnly
              />
            </div>
            <div>
              <span>Emailアドレス</span>
              <input type="text" value={detailData.email} readOnly />
            </div>
            <div>
              <span>生年月日</span>
              <input
                type="text"
                value={
                  `${detailData.year_of_birth || ''}年` +
                  `${detailData.year_of_month || ''}月` +
                  `${detailData.year_of_day || ''}日`
                }
                readOnly
              />
            </div>
            <div>
              <span>電話番号</span>
              <input type="text" value={detailData.phone_number} readOnly />
            </div>
            <div>
              <span>住所</span>
              <input type="text" value={getAddress()} readOnly />
            </div>
            <div>
              <span>Facebookアカウント</span>
              <input
                type="text"
                value={
                  detailData.facebook_account
                    ? detailData.facebook_account
                    : '-'
                }
                readOnly
              />
            </div>
            <div>
              <span>勤務先</span>
              <input type="text" value={detailData.work_place} readOnly />
            </div>
            <div>
              <span>所属団体歴</span>
              <input
                type="text"
                value={
                  detailData.affiliation_history
                    ? detailData.affiliation_history
                    : '-'
                }
                readOnly
              />
            </div>
            <div>
              <span>運動歴</span>
              <input
                type="text"
                value={
                  detailData.exercise_career ? detailData.exercise_career : '-'
                }
                readOnly
              />
            </div>
            <div>
              <span>既往症</span>
              <input
                type="text"
                value={detailData.anamnesis ? detailData.anamnesis : '-'}
                readOnly
              />
            </div>
            <div>
              <span>参加の理由・動機</span>
              <textarea
                type="textarea"
                value={
                  detailData.reason_and_motivation
                    ? detailData.reason_and_motivation
                    : '-'
                }
                readOnly
              />
            </div>
            <div>
              <span>質問・要望</span>
              <textarea
                type="textarea"
                value={
                  detailData.question_and_request
                    ? detailData.question_and_request
                    : '-'
                }
                readOnly
              />
            </div>
            {/* <div>
              <span>入会金区分</span>
              <input
                type="text"
                value={detailData.admission_fee_exemption ? '免除' : '一般'}
                readOnly
              />
            </div>
            <div>
              <span>入会金受領区分</span>
              <input
                type="text"
                value={detailData.recieved_is ? '-' : '-'}
                readOnly
              />
            </div> */}
            <div>
              <span>入会日</span>
              <input
                type="text"
                value={detailData.date_of_join ? detailData.date_of_join : '-'}
                readOnly
              />
            </div>
            {/* <div>
              <span>入会金支払日</span>
              <input
                type="text"
                value={
                  detailData.palnned_deposit_date
                    ? detailData.palnned_deposit_date
                    : '-'
                }
                readOnly
              />
            </div> */}
            <div>
              <span>会員種別</span>
              <input
                type="text"
                value={
                  runStripe
                    ? detailData.is_member
                      ? detailData.member_of_general_is
                        ? various.general_member
                        : detailData.member_of_num_is
                        ? various.num_member
                        : various.otherMemberDesignations
                      : detailData.is_training_group_member
                      ? various.otherMemberDesignations
                      : various.nonMemberDesignations
                    : detailData.is_member
                    ? various.memberDesignations
                    : detailData.is_training_group_member
                    ? various.otherMemberDesignations
                    : various.nonMemberDesignations
                }
                readOnly
              />
            </div>
            <div>
              <span>その他のお立場</span>
              <input
                type="text"
                value={
                  detailData.roushi_is
                    ? '老師'
                    : detailData.member_of_kosan
                    ? '古参'
                    : detailData.member_of_honor
                    ? '名誉会員'
                    : '一般'
                }
                readOnly
              />
            </div>
            <div>
              <span>指導者区分</span>
              <input
                type="text"
                value={detailData.member_of_instructor ? '指導者' : '-'}
                readOnly
              />
            </div>
            <div>
              <span>稽古会主宰区分</span>
              <input
                type="text"
                value={detailData.member_of_club_manager_is ? '主宰者' : '-'}
                readOnly
              />
            </div>
            {/* <div>
              <span>セミナー主催者区分</span>
              <input
                type="text"
                value={detailData.member_of_organizer ? '主催者' : '-'}
                readOnly
              />
            </div> */}
            <div>
              <span>運営担当者区分</span>
              <input
                type="text"
                value={detailData.member_of_admin ? '運営担当者' : '-'}
                readOnly
              />
            </div>
            <div>
              <span>紹介者</span>
              <input
                type="text"
                value={detailData.introducer ? detailData.introducer : '-'}
                readOnly
              />
            </div>
            <div>
              <span>稽古ステータス</span>
              <input
                type="text"
                value={detailData.active_is ? '継続稽古中' : '休会中'}
                readOnly
              />
            </div>
            {/* <div>
              <span>休会日</span>
              <input
                type="text"
                value={
                  detailData.date_of_non_active
                    ? detailData.date_of_non_active
                    : '-'
                }
                readOnly
              />
            </div>
            <div>
              <span>退会・破門区分</span>
              <input
                type="text"
                value={
                  detailData.member_of_hamon
                    ? '破門者'
                    : detailData.withdrawal_is
                    ? '退会者'
                    : '-'
                }
                readOnly
              />
            </div>
            <div>
              <span>退会・破門日</span>
              <input
                type="text"
                value={
                  detailData.date_of_hamon
                    ? detailData.date_of_hamon
                    : detailData.date_of_withdrawal
                    ? detailData.date_of_withdrawal
                    : '-'
                }
                readOnly
              />
            </div> */}
            <div>
              <span>備考</span>
              <textarea
                type="textarea"
                value={detailData.remarks ? detailData.remarks : '-'}
                readOnly
              />
            </div>
            <div>
              <span>ニュース配信区分</span>
              <input
                type="text"
                value={
                  detailData.notification_of_seminar &&
                  detailData.notification_of_news
                    ? 'ニュース＆セミナー情報配信'
                    : detailData.notification_of_seminar
                    ? 'セミナー情報のみ配信'
                    : detailData.notification_of_news
                    ? 'ニュース情報のみ配信'
                    : '配信拒否'
                }
                readOnly
              />
            </div>
            <div>
              <span>決済区分</span>
              <input
                type="text"
                value={
                  stripeCustomer &&
                  stripeCustomer.by_stripe &&
                  stripeCustomer.current_payment_method === 'credit_card'
                    ? `カード決済`
                    : '現金決済'
                }
                readOnly
              />
            </div>
            <div>
              <span>Stripe顧客ID</span>
              <input
                type="text"
                value={
                  stripeCustomer ? stripeCustomer.stripe_customer_id : '未登録'
                }
                readOnly
              />
            </div>
            <div className={classes.updateBtn}>
              <button onClick={() => handleClick(detailData.uuid)}>更新</button>
            </div>
          </div>
          <Footer />
        </>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>会員情報詳細</div>
      <hr />
      <BreadCrumbs crumbs={CRUMBS} />
      {isAuth ? (
        contentTable()
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default MemberDetail;
