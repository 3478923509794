import { Box, makeStyles } from '@material-ui/core';
import { DataGrid, jaJP } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import { getReceivedAmountIndex } from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import { localStorageArrayHandler } from '../../../CommonFunction/managePermission';
import Footer from '../../../Footer/footer';
import various from '../../../General/various';
import imageIndex from '../../../img/imageIndex';
import { Spinner } from '../../../Spinner';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 16px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
    '& > p': {
      fontSize: '0.8rem',
      textAlign: 'start',
      color: 'grey',
    },
  },
  aggregate: {
    width: '100%',
    marginBottom: '40px',
    '& > table': {
      width: '100%',
      margin: '0 auto',
      borderCollapse: 'collapse',
      fontSize: '0.9em',
      '& > thead': {
        '& > tr': {
          '& > td': {
            minWidth: '96px',
            fontSize: '0.9rem',
            border: '1px solid #ccc',
          },
        },
      },
      '& > tbody': {
        '& > tr': {
          '& > td': {
            fontSize: '0.9rem',
            border: '1px solid #ccc',
            padding: '8px',
            textAlign: 'right',
            '& > button': {
              cursor: 'pointer',
            },
          },
          '& #category1, & #category2, & #category3': {
            textAlign: 'center',
          },
          '&:nth-child(even)': {
            backgroundColor: '#f8f8f8',
          },
          '&:focus': {
            backgroundColor: '#e0e0e0',
          },
        },
      },
    },
    '& > p': {
      fontSize: '0.8rem',
      textAlign: 'start',
      color: 'grey',
    },
  },
  yeard: {
    width: '100%',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '16px 16px',
    '& > button': {
      border: 'none',
      textDecoration: 'underline',
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
  },
  dataGrid: {
    height: (props) =>
      props.heightSize ? props.heightSize : props.pageSize * props.rowHeight,
    width: '100%',
    '& .MuiDataGrid-main': {
      fontSize: '0.9rem',
      fontFamily: theme.typography.fontFamily,
    },
  },
  viewDataBtnContainer: {
    width: '100%',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',
    '@media screen and (max-width:600px)': {
      flexWrap: 'wrap',
      gap: '0px 16px',
    },
    '& > div': {
      margin: 'auto 0',
      '& > span': {
        display: 'inline-block',
      },
    },
    '& #viewBtn1, & #viewBtn3': {
      width: '20%',
      fontSize: '0.9rem',
      textAlign: 'start',
      '@media screen and (max-width:600px)': {
        width: '114px',
        textAlign: 'start',
      },
    },
    '& #viewBtn2, & #viewBtn4': {
      width: '100%',
      display: 'flex',
      textAlign: 'start',
      '& > select': {
        width: '100%',
        height: '40px',
        padding: '8px',
        fontFamily: theme.typography.fontFamily,
        border: '0.5px solid grey',
        borderRadius: '0.5em',
        boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
        '&:focus': {
          outline: `1.2px ${theme.palette.primary.main} outset`,
        },
      },
      '& > form': {
        display: 'flex',
        justifyContent: 'start',
        gap: '0px 16px',
        width: '100%',
        '& > input': {
          width: '70%',
          height: '40px',
          padding: '8px',
          fontFamily: theme.typography.fontFamily,
          border: '0.5px solid grey',
          borderRadius: '0.5em',
          boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
          '&:focus': {
            outline: `1.2px ${theme.palette.primary.main} outset`,
          },
        },
        '& > button': {
          width: '30%',
          maxWidth: '160px',
          display: 'block',
          fontFamily: theme.typography.fontFamily,
          fontWeight: 'bold',
          borderRadius: '0.5em',
          border: 'none',
          padding: '4px 8px',
          backgroundColor: theme.palette.primary.main,
          boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
          color: '#fff',
          cursor: 'pointer',
        },
      },
      '@media screen and (max-width:600px)': {
        width: '100%',
      },
    },
  },
  viewBtn5: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    '& > button': {
      // width: '30%',
      maxWidth: '160px',
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      // border: 'none',
      borderColor: theme.palette.primary.main,
      padding: '12px 8px',
      // backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  downLoadBtnContainer: {
    width: '100%',
    marginBottom: '80px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',

    '@media screen and (max-width:600px)': {
      marginBottom: '0px',
    },
    '& > div': {
      width: '20%',
      margin: 'auto 0',
      '& > span': {
        display: 'inline-block',
      },
      '& > button': {
        display: 'inline-block',
        border: 'none',
        padding: '4px 8px',
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer', // downloadが機能したら復活
      },
    },
    '& #dlBtn1': {
      width: '20%',
      fontSize: '0.9rem',
      textAlign: 'start',
      '@media screen and (max-width:600px)': {
        width: '114px',
      },
    },
    '& #dlBtn2': {
      textAlign: 'start',
    },
  },
  detailBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
}));

const PayoutsIndex = () => {
  const { isAuth } = useContext(AuthContext);
  const [viewData, setViewData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [heightSize, setHeightSize] = useState();
  const [date, setDate] = useState();
  const [loading, setLoading] = useState(true);

  const [aggregate, setAggregate] = useState();

  const [receivedAmount, setReceivedAmount] = useState();

  const [firstDay, setFirstDay] = useState();
  const [lastDay, setLastDay] = useState();
  const [yeard, setYeard] = useState();
  const [yearIndex, setYearIndex] = useState(0);
  const [currentFlag, setCurrentFlag] = useState('default');

  const permissions = localStorageArrayHandler('parse', various.permissionKey);
  // console.log('permissions', permissions);

  const ROWHEIGHT = 80;
  const PAGESIZE = 30;
  const classes = useStyles({
    pageSize: PAGESIZE,
    rowHeight: ROWHEIGHT,
    heightSize: heightSize,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '入金情報一覧', path: '/payouts_index' },
  ];

  // if (servicePlans.length > 0) console.log('servicePlans', servicePlans);
  // if (viewData.length > 0) console.log('viewData', viewData);
  // if (receivedAmount) console.log('receivedAmount', receivedAmount);

  //   Init
  useEffect(() => {
    if (isAuth) {
      async function fetchData() {
        await getReceivedAmountIndex(setReceivedAmount);
      }
      fetchData(setReceivedAmount);
    }
  }, [isAuth]);

  useEffect(() => {
    function extractYears(dataArray) {
      const years = dataArray.map((item) =>
        new Date(item.arrival_date).getFullYear()
      );

      return [...new Set(years)].sort((a, b) => b - a);
    }

    if (receivedAmount && receivedAmount.length > 0) {
      setViewData(receivedAmount);
      setYeard(extractYears(receivedAmount));
      // setYeard([2023, 2022, 2021]);
    }
  }, [receivedAmount]);

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = new Date().getMonth() + 1;

    // 月の1日を取得
    const firstDayFormatted = `${year}-${month.toString().padStart(2, '0')}-01`;

    // 月の最終日を取得
    const lastDay = new Date(year, month, 0);
    const lastDayFormatted = `${year}-${month
      .toString()
      .padStart(2, '0')}-${lastDay.getDate().toString().padStart(2, '0')}`;

    setFirstDay(firstDayFormatted);
    setLastDay(lastDayFormatted);
  }, []);

  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  useEffect(() => {
    if (viewData) {
      const size = viewData.length < PAGESIZE ? viewData.length : PAGESIZE;
      setHeightSize(size * ROWHEIGHT * 1.4 + 122);
    }
  }, [viewData]);

  // contents
  function calculateDates(friday) {
    // 直前の月曜日を計算
    const monday = new Date(friday);
    const day = monday.getDay();
    const diff = day >= 1 ? day - 1 : 6;
    monday.setDate(monday.getDate() - diff);

    // 直前の月曜日からその前週の火曜日を計算
    const tuesday = new Date(monday);
    tuesday.setDate(tuesday.getDate() - 6);

    // 日付を 'M月D日' 形式に変換
    const options = { month: 'long', day: 'numeric' };
    const mondayStr = monday.toLocaleDateString('ja-JP', options);
    const tuesdayStr = tuesday.toLocaleDateString('ja-JP', options);

    return { monday: mondayStr, tuesday: tuesdayStr };
  }

  function calculateDates2(service_plans) {
    function getOldestAndNewestDates(data) {
      return data.reduce(
        (acc, curr) => {
          const currDate = new Date(curr.created_at);
          return {
            oldest: acc.oldest < currDate ? acc.oldest : currDate,
            newest: acc.newest > currDate ? acc.newest : currDate,
          };
        },
        {
          oldest: new Date(data[0].created_at),
          newest: new Date(data[0].created_at),
        }
      );
    }
    if (service_plans.length === 0) return { oldest: '', newest: '' };

    const { oldest, newest } = getOldestAndNewestDates(service_plans);
    const options = { month: 'long', day: 'numeric' };
    const oldestStr = oldest.toLocaleDateString('ja-JP', options);
    const newestStr = newest.toLocaleDateString('ja-JP', options);
    return { oldest: oldestStr, newest: newestStr };
  }

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'short', // 曜日を追加
  };

  useEffect(() => {
    const headers = [
      {
        field: 'id',
        headerName: 'ID',
        width: 10,
      },
      {
        field: 'uuid',
        headerName: 'UUID',
        width: 100,
      },
      {
        field: 'month',
        headerName: '月度',
        cellClassName: 'bold',
        width: 50,
      },
      {
        field: 'date',
        headerName: '入金予定日',
        width: 140,
      },
      {
        field: 'range',
        headerName: '集計期間',
        width: 160,
      },
      {
        field: 'amount',
        headerName: '決済金額',
        cellClassName: 'bold',
        width: `72`,
      },
      {
        field: 'fee',
        headerName: '手数料',
        cellClassName: 'bold',
        width: `72`,
      },
      {
        field: 'refund',
        headerName: '返金額',
        cellClassName: 'bold',
        width: `72`,
      },
      {
        field: 'received',
        headerName: '入金額',
        cellClassName: 'bold',
        width: `72`,
      },
      {
        field: 'currency',
        headerName: '通貨',
        width: `50`,
      },
      {
        field: 'status',
        headerName: 'ステータス',
        cellClassName: (params) =>
          params.value === '入金済'
            ? 'green'
            : params.value === '入金額確定'
            ? 'brown'
            : 'red',
        width: 100,
      },
    ];
    const operationBtn = [
      {
        field: 'detailBtn',
        headerName: '詳細',
        sortable: false,
        width: 80,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <div className={classes.detailBtn}>
            <button
              disabled={params.row.status === '処理中...'}
              style={
                params.row.status === '処理中...'
                  ? { cursor: 'not-allowed' }
                  : null
              }
              onClick={() => handleClick('detail', params.row.uuid)}
            >
              詳細
            </button>
          </div>
        ),
      },
    ];
    const columnsDataset = headers.concat(operationBtn);
    setColumns(columnsDataset);

    setRows(
      viewData.map((data, index) => {
        // const member = data.member[0];
        // const strypeCustomer = data.stripe_customer;
        const result = {};
        result['id'] = index + 1;
        result['uuid'] = data.uuid;
        result['month'] = new Date(data.arrival_date).getMonth() + 1 + '月';
        result['date'] = new Date(data.arrival_date).toLocaleDateString(
          'ja-JP',
          options
        );
        // arrival_dateが祝日の場合など破綻するが、日本の祝祭日ライブラリを入れたくないので使用を検討
        const { monday, tuesday } = calculateDates(data.arrival_date);
        result['range'] = `${tuesday} 〜 ${monday}`;
        // ここまで。問題がなければ上2行とcalculateDates関数を削除のこと。

        // 完全に締め日を正しく表現できないが、入金額を構成する取引の最古と最新の日付を表示できるので一旦これで運用
        const { oldest, newest } = calculateDates2(data.service_plan);
        result['range'] = `${oldest} 〜 ${newest}`;
        result['amount'] = (
          data.service_plan.reduce((sum, obj) => sum + obj.price, 0) || 0
        ).toLocaleString();
        const subscriptionFee =
          data.stripe_received_fee.reduce(
            (sum, obj) => sum + obj.net_amount,
            0
          ) || 0;
        const txFee =
          data.service_plan.reduce(
            (sum, obj) => sum + obj.payment_fee_total,
            0
          ) || 0;
        result['fee'] = (subscriptionFee + txFee).toLocaleString();
        result['refund'] = (
          data.stripe_refund.reduce((sum, obj) => sum + obj.net_amount, 0) || 0
        ).toLocaleString();
        result['received'] = data.amount.toLocaleString();
        result['currency'] = data.currency.toUpperCase();
        result['status'] =
          data.paid_status === 'paid'
            ? '入金済'
            : data.reconciliation_status === 'completed'
            ? '請求額確定'
            : '処理中...';
        return result;
      })
    );
    setAggregate(viewDataAggregate());
    // eslint-disable-next-line
  }, [viewData]);

  useEffect(() => {
    if (aggregate) {
      setLoading(false);
    }
  }, [aggregate]);

  const viewDataAggregate = () => {
    if (viewData.length > 0) {
      const paymentTotal = viewData.reduce(
        (sum, obj) =>
          sum +
          (obj.service_plan.reduce((sum, obj) => sum + obj.price, 0) || 0),
        0
      );
      const txFee = viewData.reduce(
        (sum, obj) =>
          sum +
          (obj.service_plan.reduce(
            (sum, obj) => sum + obj.payment_fee_total,
            0
          ) || 0),
        0
      );
      const feeTotal =
        viewData.reduce(
          (sum, obj) =>
            sum +
            (obj.stripe_received_fee.reduce(
              (sum, obj) => sum + obj.net_amount,
              0
            ) || 0),
          0
        ) + txFee;
      const refundTotal = viewData.reduce(
        (sum, obj) =>
          sum +
          (obj.stripe_refund.reduce((sum, obj) => sum + obj.net_amount, 0) ||
            0),
        0
      );
      const refundFeeTotal = viewData.reduce(
        (sum, obj) =>
          sum +
          (obj.stripe_refund.reduce((sum, obj) => sum + obj.fee_amount, 0) ||
            0),
        0
      );
      const receiveCount = viewData.length;

      return (
        <div className={classes.aggregate}>
          <table>
            <thead>
              <tr>
                <td></td>
                <td>決済金額</td>
                <td>手数料</td>
                <td>返金額</td>
                <td>返金手数料</td>
                <td>差し引き入金額</td>
                <td>入金件数</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td id="category1">合計</td>
                <td>{paymentTotal.toLocaleString()}</td>
                <td>{feeTotal.toLocaleString()}</td>
                <td>{refundTotal.toLocaleString()}</td>
                <td>{refundFeeTotal.toLocaleString()}</td>
                <td>
                  {(
                    paymentTotal -
                    (feeTotal + refundTotal + refundFeeTotal)
                  ).toLocaleString()}
                </td>
                <td>{receiveCount.toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
          <p>
            ※現在表示されているデータにおける集計結果です。
            {/* <br />
            ※「決済金額」は入会金控除額を差し引いた後の金額です。 */}
          </p>
        </div>
      );
    }
  };

  // handle
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleTransaction = (flag) => {
    if (receivedAmount.length > 0) {
      if (flag === 'default') {
        setViewData(receivedAmount);
      } else if (flag === 'received') {
        const paids = receivedAmount.filter((receive) => {
          return receive.paid_status === 'paid';
        });
        setViewData(paids);
      } else if (flag === 'completed') {
        const completeds = receivedAmount.filter((receive) => {
          return (
            receive.reconciliation_status === 'completed' &&
            receive.paid_status !== 'paid'
          );
        });
        setViewData(completeds);
      } else if (flag === 'processing') {
        const processings = receivedAmount.filter((plan) => {
          return plan.reconciliation_status !== 'completed';
        });
        setViewData(processings);
        setCurrentFlag(flag);
      }
    }
  };

  const handleTransactions = (startDatetime, endDatetime) => {
    handleTransaction(currentFlag);
    const startDate = new Date(startDatetime);
    startDate.setHours(0, 0, 0, 0); // 時間を0:00:00に設定
    const endDate = new Date(endDatetime);
    endDate.setHours(23, 59, 59, 999); // 時間を23:59:59.999に設定

    setViewData(
      viewData.filter((receive) => {
        const arrivalDate = new Date(receive.arrival_date);
        return arrivalDate >= startDate && arrivalDate <= endDate;
      })
    );
  };

  const handleReset = () => {
    setViewData(receivedAmount);
  };

  const handleClick = (flag, uuid) => {
    if (flag === 'detail') {
      navigate(various.general.payoutsDetail, {
        state: { uuid: uuid, previous: location.pathname },
      });
    }
  };

  function handleNextYear() {
    setYearIndex(yearIndex - 1);
    setViewData(
      receivedAmount.filter((plan) => {
        const receivedDate = new Date(plan.arrival_date);
        return receivedDate.getFullYear() === yeard[yearIndex - 1];
      })
    );
  }

  function handlePreviousYear() {
    setYearIndex(yearIndex + 1);
    setViewData(
      receivedAmount.filter((plan) => {
        const receivedDate = new Date(plan.service_date_time);
        return receivedDate.getFullYear() === yeard[yearIndex + 1];
      })
    );
  }

  const downloadCsv = () => {
    const headersObject = various.payoutHeaders;
    headersObject.month = '月度';

    const rejectList = [
      'uuid',
      'payout_id',
      'balance_transaction_id',
      'bank_id',
      'reconciliation_status',
      'application_fee',
      'application_fee_amount',
      'created_at',
      'updated_at',
    ];
    const headers = Object.keys(viewData[0]).filter((k) => {
      return !rejectList.includes(k);
    });
    headers.push('month');
    const rows = viewData.map((obj, index) => {
      let result = [];
      headers.forEach((h) => {
        let value = `${String(obj[h])
          .replace(/[\r\n]/g, ' ')
          .replace(/[,]/g, '、')
          .replace(/["]/g, '”')}`;

        if (h === 'arrival_date' || h === 'create_date') {
          value = new Date(obj[h]).toLocaleString('ja-JP', options);
        } else if (h === 'month') {
          value = new Date(obj['arrival_date']).getMonth() + 1 + '月';
        } else if (h === 'paid_status') {
          value =
            obj[h] === 'paid'
              ? '入金済'
              : obj['reconciliation_status'] === 'completed'
              ? '入金額確定'
              : '処理中...';
        } else if (h === 'service_plan') {
          value = obj[h].reduce((sum, obj) => sum + obj.price, 0);
        } else if (h === 'stripe_received_fee') {
          value = obj[h].reduce((sum, obj) => sum + obj.net_amount, 0);
        } else if (h === 'stripe_refund') {
          value = obj[h].reduce((sum, obj) => sum + obj.net_amount, 0);
        } else {
          value = obj[h];
        }
        result.push(value);
      });
      return result;
    });

    const rowHead = [];
    headers.forEach((k) => {
      if (!rejectList.includes(k)) {
        rowHead.push(headersObject[k]);
      }
    });

    rows.unshift(rowHead);

    let csvString = '';
    for (let d of rows) {
      csvString += d.join(',');
      csvString += '\r\n';
    }

    const filename = 'payouts.csv';
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csvString], { type: 'text/csv' });

    //IE10/11用(download属性が機能しないためmsSaveBlobを使用）
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, filename);
      //その他ブラウザ
    } else {
      const url = (window.URL || window.webkitURL).createObjectURL(blob);
      const download = document.createElement('a');
      download.href = url;
      download.download = filename;
      download.click();
      (window.URL || window.webkitURL).revokeObjectURL(url);
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {isAuth && receivedAmount ? (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">入金情報一覧</span>
              <span>{date}</span>
            </div>
            {/* <div>
              <span>定例会員決済件数</span>
              <span>{numOfGeneralPaid}件</span>
              <span>回数会員決済件数</span>
              <span>{numOfNumPaid}件</span>
              <span>自動決済総数</span>
              <span>{numOfGeneralPaid + numOfNumPaid}件</span>
              <br />
              <span>想定現金決済人数</span>
              <span>{numOfCash}名</span>
              <span>総会員数</span>
              <span>{numOfAllMembers}名</span>
            </div> */}
          </div>
          {loading ? <Spinner /> : aggregate}
          <BreadCrumbs crumbs={CRUMBS} />
          <hr />
          <div className={classes.viewDataBtnContainer}>
            <div id="viewBtn1">
              <span>絞り込み&nbsp;:&nbsp;</span>
            </div>
            <div id="viewBtn2">
              <select
                onChange={(e) => handleTransaction(e.target.value)}
                defaultValue="default"
              >
                <option value="default">選択してください</option>
                <option value="received">入金済</option>
                <option value="completed">請求額確定</option>
                <option value="processing">処理中...</option>
              </select>
            </div>
          </div>
          <div className={classes.viewDataBtnContainer}>
            <div id="viewBtn3">
              <span>期間指定&nbsp;:&nbsp;</span>
            </div>
            <div id="viewBtn4">
              <form
                onSubmit={(e) => {
                  handleTransactions(e.target.start.value, e.target.end.value);
                  e.preventDefault();
                }}
              >
                <input
                  type="date"
                  id="start"
                  name="start"
                  value={firstDay}
                  onChange={(e) => setFirstDay(e.target.value)}
                />
                <input
                  type="date"
                  id="end"
                  name="end"
                  value={lastDay}
                  onChange={(e) => setLastDay(e.target.value)}
                />
                <button type="submit">期間絞り込み</button>
              </form>
            </div>
          </div>
          <div className={classes.viewBtn5}>
            <button onClick={handleReset}>絞り込みリセット</button>
          </div>
          {permissions.includes('admin') ? (
            <div className={classes.downLoadBtnContainer}>
              <div id="dlBtn1">
                <span>DownLoad&nbsp;:&nbsp;</span>
              </div>
              <div id="dlBtn2">
                <button id="dlbtn1" onClick={() => downloadCsv()}>
                  <img src={imageIndex.CloudDownloadIcon} alt="dlicon" />
                </button>
              </div>
            </div>
          ) : (
            <div className={classes.downLoadBtnContainer}></div>
          )}

          {loading ? (
            <Spinner />
          ) : (
            <>
              {yeard ? (
                <div className={classes.yeard}>
                  {yearIndex < yeard.length - 1 && (
                    <button onClick={handlePreviousYear}>
                      {'< ' + yeard[yearIndex + 1]}
                    </button>
                  )}
                  <span>{yeard[yearIndex]}年度</span>
                  {yearIndex > 0 && (
                    <button onClick={handleNextYear}>
                      {yeard[yearIndex - 1] + ' >'}
                    </button>
                  )}
                </div>
              ) : null}
              <Box
                className={classes.dataGrid}
                sx={{
                  '& .bold': {
                    fontWeight: 'bold',
                  },
                  '& .green': {
                    color: 'green',
                  },
                  '& .brown': {
                    color: 'brown',
                  },
                  '& .red': {
                    color: 'red',
                  },
                }}
              >
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={PAGESIZE}
                  rowsPerPageOptions={[PAGESIZE]}
                  density="comfortable"
                  columnVisibilityModel={{ uuid: false }}
                  pagination={true}
                  autoPageSize={true}
                  rowHeight={ROWHEIGHT}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                  localeText={
                    jaJP.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              </Box>
            </>
          )}
        </>
      ) : loading ? (
        <Spinner />
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
      <Footer />
    </>
  );
};
export default PayoutsIndex;
