import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import OnOffByScroll from '../Animation/onOffByScroll';
import { images as imageApi } from '../Apis/apis';
import { memberDestroyByUnsubscribe } from '../Apis/apis';
import AlertDialog from '../Dialog/alertDialog';
import various from '../General/various';
import { ogpGenerator } from '../ogp';

const Unsubscribe = () => {
  const [image, setImage] = useState();
  const [open, setOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  // OGP設定
  const ogp = useCallback((props) => {
    ogpGenerator(props);
  }, []);

  useLayoutEffect(() => {
    if (image) {
      const ogpProps = {
        page: 'unsubscribe',
        pageTitle: '刀禪OfficialWebSiteニュースレター登録解除ページ',
        path: location.pathname,
        imageUrl: image.OfficialImage_TouzenLogoRed.url,
      };
      ogp(ogpProps);
    }
  }, [image, location, ogp]);
  // OGP設定ここまで

  useEffect(() => {
    imageApi(setImage);
  }, []);

  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const unsubscribeDialog = (open) => {
    let title;
    let content;
    if (open) {
      title = '刀禅ニュースレター配信のご登録解除完了';
      content = ``;
    }
    return { title: title, content: content, judge: open };
  };

  const handleClick = () => {
    const uuid = query.get('id');
    if (uuid) {
      memberDestroyByUnsubscribe(uuid);
      setOpen(true);
      setTimeout(() => {
        navigate(various.general.unsubscribeConfirm);
      }, 3000);
    }
  };

  return (
    <>
      <div>
        <OnOffByScroll place="General" />
      </div>
      <div style={{ margin: '160px auto' }}>
        <p>以下のボタンを押下すると登録解除となります。</p>
        <button onClick={handleClick}>登録解除</button>
      </div>
      <AlertDialog addFunc={unsubscribeDialog} setOpen={setOpen} open={open} />
    </>
  );
};
export default Unsubscribe;
