import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import {
  createCheckoutSession,
  cancelSubscriptionRequest,
} from '../Apis/apiOfManaged';
import { Spinner } from '../Spinner';
import { makeStyles } from '@material-ui/core';
import StandardDialog from '../Dialog/standardDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import various from '../General/various';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    marginTop: '48px',
    display: 'flex',
    justifyContent: 'center',
    '& > button': {
      width: '90%',
      height: '40px',
      marginTop: '24px',
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
      '&:disabled': {
        backgroundColor: 'grey',
        cursor: 'not-allowed',
      },
    },
  },
}));

let stripePromise;
if (process.env.NODE_ENV === 'production') {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    stripePromise = loadStripe(
      process.env.REACT_APP_STRIPE_PUBLIC_KEY_PRODUCTION
    );
  } else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  }
} else if (process.env.NODE_ENV === 'development') {
  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
}

const Payment = (props) => {
  const [sessionId, setSessionId] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [subscriptionChangeDialog, setSubscriptionChangeDialog] =
    useState(false);
  const [subscriptionChange, setSubscriptionChange] = useState(false);
  const [dialogTitle, setDialogTitle] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(false);
  const [paymentType, setPaymentType] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const classes = useStyles();

  // console.log('props in payment.js: ', props);

  // Init validation
  const validateProps = () => {
    if (props.disabled && props.disabled === true) {
      setIsDisabled(true);
      return;
    }
    if (props.planFlag === 'membership') {
      if (props.selectedProductType) {
        if (props.userEmail) {
          setIsDisabled(false);
        } else {
          console.log('applyData validation failed for membership...');
        }
      }
    } else if (props.planFlag === 'seminar') {
      if (
        props.selectedProductType &&
        props.selectedApplyUnit &&
        props.customerId &&
        props.customerEmail &&
        props.userEmail &&
        props.applyData
      ) {
        setIsDisabled(false);
      } else {
        console.log('applyData validation failed for seminar...');
      }
    }
  };

  useEffect(() => {
    validateProps();
    // eslint-disable-next-line
  }, [props]);

  // Dialogからの処理
  const handleSubscriptionChangeClose = () => {
    setSubscriptionChangeDialog(false);
  };

  const handleSubscriptionChangeAgree = () => {
    // 定例会員から回数会員への変更
    const data = {
      user_email: props.userEmail,
      change_type: '本人による変更',
      payment_method: 'credit_card',
      payment_type: paymentType,
    };
    cancelSubscriptionRequest(data, setSubscriptionChange);
  };

  useEffect(() => {
    if (subscriptionChange) {
      setSubscriptionChangeDialog(false);
      navigate(various.general.mypage, {
        state: { previous: location.pathname },
      });
    }
  }, [subscriptionChange, navigate, location]);

  // Stripe Checkout Sessionの作成
  const createSession = async () => {
    setLoading(true);
    let sessionData;
    if (props.planFlag === 'membership') {
      if (
        props.customerType === 'subscription' &&
        props.selectedProductType === 'setup'
      ) {
        setDialogTitle(`翌月1日に${various.num_member}へ変更します`);
        setDialogMessage(
          `今月末日までは${various.general_member}として稽古にご参加いただけます。`
        );
        setPaymentType('payment');
        setLoading(false);
        setSubscriptionChangeDialog(true);
      }
      // else if (
      //   (props.customerType === 'setup' &&
      //     props.selectedProductType === 'subscription') ||
      //   (props.customerType === 'payment' &&
      //     props.selectedProductType === 'subscription')
      // ) {
      //   setDialogTitle(`翌月1日に${various.general_member}へ変更します`);
      //   setDialogMessage(
      //     `今月末日までは${various.num_member}として稽古にご参加いただけます。`
      //   );
      //   setPaymentType('subscription');
      //   setLoading(false);
      //   setSubscriptionChangeDialog(true);
      // }
      else {
        sessionData = {
          product_type: props.selectedProductType,
          product_uuid: props.selectedProductUuid,
          customer_id: props.customerId,
          customer_email: props.customerEmail,
          user_email: props.userEmail,
          plan_flag: props.planFlag,
          apply_data: props.applyData,
        };
      }
    } else if (props.planFlag === 'seminar') {
      sessionData = {
        product_type: props.selectedProductType,
        product_uuid: props.selectedProductUuid,
        apply_unit: props.selectedApplyUnit,
        customer_id: props.customerId,
        customer_email: props.customerEmail,
        user_email: props.userEmail,
        plan_flag: props.planFlag,
        apply_data: props.applyData,
      };
    }

    if (sessionData) {
      createCheckoutSession(sessionData, setSessionId);
    }
  };

  // Stripeへのリダイレクト
  useEffect(() => {
    const handleCheckout = async () => {
      const stripe = await stripePromise;
      if (sessionId) {
        const result = await stripe.redirectToCheckout({
          sessionId: sessionId,
        });
        if (result.error) {
          console.log(result.error.message);
        }
        setLoading(false);
      }
    };
    if (sessionId) {
      // debug用;
      // setLoading(false);
      handleCheckout();
    }
  }, [sessionId]);

  return (
    <div className={classes.button}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <button onClick={createSession} disabled={isDisabled}>
            {props.buttonName}
          </button>
          {subscriptionChangeDialog ? (
            <StandardDialog
              open={subscriptionChangeDialog}
              handleClose={handleSubscriptionChangeClose}
              text={{
                title: dialogTitle,
                content: dialogMessage,
                agree: '変更する',
                disagree: 'やはり止める',
              }}
              func={{
                agreeFunc: handleSubscriptionChangeAgree,
                disagreeFunc: handleSubscriptionChangeClose,
              }}
            />
          ) : null}
        </>
      )}
    </div>
  );
};
export default Payment;
