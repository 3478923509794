import { Box, makeStyles } from '@material-ui/core';
import { DataGrid, jaJP } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import { AuthContext } from '../../../auth';
import Footer from '../../../Footer/footer';
import various from '../../../General/various';
import imageIndex from '../../../img/imageIndex';
import { newsIndexForAdmin } from '../../../Apis/apiOfManaged';
import { Spinner } from '../../../Spinner';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
  },
  dataGrid: {
    height: (props) =>
      props.heightSize ? props.heightSize : props.pageSize * props.rowHeight,
    width: '100%',
    '& .MuiDataGrid-main': {
      fontSize: '0.9rem',
      fontFamily: theme.typography.fontFamily,
    },
  },
  createBtnContainer: {
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',
    '@media screen and (max-width:600px)': {
      flexWrap: 'wrap',
      gap: '0px 16px',
    },
    '& > div': {
      margin: 'auto 0',
      '& > span': {
        display: 'inline-block',
      },
      '& > button': {
        display: 'inline-block',
        fontFamily: theme.typography.fontFamily,
        fontSize: '0.9rem',
        fontWeight: 'bold',
        borderRadius: '0.5em',
        border: 'none',
        padding: '4px 8px',
        height: '30px',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        cursor: 'pointer',
        '@media screen and (max-width:600px)': {
          display: 'block',
          width: '114px',
          overflow: 'hidden',
        },
      },
      '& #createbtn1': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
      '& #createbtn2': {
        backgroundColor: 'rgba(124,42,46,0.8)',
        color: '#fff',
      },
    },
    '& #createBtn1': {
      width: '20%',
      fontSize: '0.9rem',
      textAlign: 'end',
      '@media screen and (max-width:600px)': {
        width: '114px',
        textAlign: 'start',
      },
    },
    '& #createBtn2': {
      width: '80%',
      display: 'flex',
      gap: '0px 16px',
      textAlign: 'start',
      '@media screen and (max-width:600px)': {
        width: '100%',
      },
    },
  },
  viewDataBtnContainer: {
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',
    '@media screen and (max-width:600px)': {
      flexWrap: 'wrap',
      gap: '0px 16px',
    },
    '& > div': {
      margin: 'auto 0',
      '& > span': {
        display: 'inline-block',
      },
      '& > button': {
        display: 'inline-block',
        fontFamily: theme.typography.fontFamily,
        fontSize: '0.9rem',
        fontWeight: 'bold',
        borderRadius: '0.5em',
        border: 'none',
        padding: '0px 8px',
        height: '30px',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        cursor: 'pointer',
        '@media screen and (max-width:600px)': {
          display: 'block',
          width: '114px',
          overflow: 'hidden',
        },
      },
      '& #btn1': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
      '& #btn2': {
        backgroundColor: 'rgba(124,42,46,0.8)',
        color: '#fff',
      },
      '& #btn4': {
        backgroundColor: 'rgba(124,42,46,0.5)',
        color: '#000',
      },
      '& #btn3': {
        backgroundColor: 'rgba(124,42,46,0.3)',
        color: '#000',
      },
    },
    '& #viewBtn1': {
      width: '20%',
      fontSize: '0.9rem',
      textAlign: 'end',
      '@media screen and (max-width:600px)': {
        width: '114px',
        textAlign: 'start',
      },
    },
    '& #viewBtn2': {
      width: '80%',
      display: 'flex',
      gap: '0px 16px',
      textAlign: 'start',
      '@media screen and (max-width:600px)': {
        width: '100%',
      },
    },
  },
  downLoadBtnContainer: {
    marginBottom: '80px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',

    '@media screen and (max-width:600px)': {
      marginBottom: '0px',
    },
    '& > div': {
      width: '20%',
      margin: 'auto 0',
      // cursor: 'not-allowed', // downloadが機能したら削除
      '& > span': {
        display: 'inline-block',
      },
      '& > button': {
        display: 'inline-block',
        border: 'none',
        padding: '4px 8px',
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer', // downloadが機能したら復活
        // pointerEvents: 'none', // downloadが機能したら削除
      },
    },
    '& #dlBtn1': {
      fontSize: '0.9rem',
      textAlign: 'end',
      '@media screen and (max-width:600px)': {
        width: '114px',
      },
    },
    '& #dlBtn2': {
      textAlign: 'start',
    },
  },
  distributionBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      backgroundColor: 'green',
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
  distributionBtnConfirm: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      backgroundColor: 'grey',
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'not-allowed',
    },
  },
  detailBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  updateBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
  createMemberBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
}));

const NewsIndex = () => {
  const { isAuth } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [heightSize, setHeightSize] = useState();
  const [date, setDate] = useState();
  const [newsNum, setNewsNum] = useState();
  const [nonNewsNum, setNonNewsNum] = useState();
  const [today, setToday] = useState();
  const [loading, setLoading] = useState(true);

  const ROWHEIGHT = 80;
  const PAGESIZE = 30;
  const classes = useStyles({
    pageSize: PAGESIZE,
    rowHeight: ROWHEIGHT,
    heightSize: heightSize,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: 'ニュース一覧', path: '/news_index' },
  ];

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleClick = (flag, uuid) => {
    if (flag === 'update') {
      navigate(various.general.newsUpdateForAdmin, {
        state: { uuid: uuid, previous: location.pathname },
      });
    } else if (flag === 'create') {
      navigate(various.general.newsCreateForAdmin, {
        state: { previous: location.pathname },
      });
    } else if (flag === 'detail') {
      navigate(various.general.newsDetailForAdmin, {
        state: { uuid: uuid, previous: location.pathname },
      });
    } else if (flag === 'distribution') {
      navigate(various.general.newsDetailConfirm, {
        state: { uuid: uuid, previous: location.pathname },
      });
    }
  };

  const handleNews = (flag) => {
    if (data.length > 0) {
      const allNews = [...data];
      if (flag === 'all') {
        return setViewData(allNews);
      } else if (flag === 'positive') {
        const result = [];
        allNews.forEach((news) => {
          const date = new Date(news.delivery_date);
          if (news.delivered && today > date) {
            result.push(news);
          }
        });
        return setViewData(result);
      } else if (flag === 'participant') {
        const result = [];
        allNews.forEach((news) => {
          const date = new Date(news.delivery_date);
          if (!news.delivered || today < date) {
            result.push(news);
          }
        });
        return setViewData(result);
      }
    }
  };

  const downloadCsv = () => {
    const headersObject = various.newsHeaders;
    const headers = Object.keys(viewData[0]).map((k) => {
      return k;
    });
    const rows = viewData.map((obj) => {
      let result = [];
      headers.forEach((h) => {
        const r = `${String(obj[h])
          .replace(/[\r\n]/g, ' ')
          .replace(/[,]/g, '、')
          .replace(/["]/g, '”')}`;
        result.push(r);
      });
      return result;
    });
    const rowHead = headers.map((k) => {
      return headersObject[k];
    });
    rows.unshift(rowHead);

    let csvString = '';
    for (let d of rows) {
      csvString += d.join(',');
      csvString += '\r\n';
    }

    const filename = 'news_index.csv';
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csvString], { type: 'text/csv' });

    //IE10/11用(download属性が機能しないためmsSaveBlobを使用）
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, filename);
      //その他ブラウザ
    } else {
      const url = (window.URL || window.webkitURL).createObjectURL(blob);
      const download = document.createElement('a');
      download.href = url;
      download.download = filename;
      download.click();
      (window.URL || window.webkitURL).revokeObjectURL(url);
    }
  };

  // Init
  useEffect(() => {
    newsIndexForAdmin(setData);
  }, []);

  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const news = data.map((d) => {
        const date = new Date(d.delivery_date);
        return d.delivered && today > date;
      });
      setNewsNum(news.filter(Boolean).length);
      setNonNewsNum(data.length - news.filter(Boolean).length);
    }
  }, [data, today]);

  useEffect(() => {
    setViewData(data);
  }, [data]);

  useEffect(() => {
    setToday(new Date());
  }, []);

  useEffect(() => {
    if (data) {
      const size = data.length < PAGESIZE ? data.length : PAGESIZE;
      setHeightSize(size * ROWHEIGHT * 1.4 + 122);
    }
  }, [data]);

  // Contents
  useEffect(() => {
    if (viewData) {
      const headers = [
        {
          field: 'id',
          headerName: 'ID',
          width: 90,
        },
        {
          field: 'uuid',
        },
        {
          field: 'delivered',
        },
        {
          field: 'title',
          headerName: 'タイトル',
          width: 250,
        },
        {
          field: 'description',
          headerName: '内容案内（管理用）',
          width: 250,
        },
        {
          field: 'range',
          headerName: '公開範囲',
          width: 100,
          cellClassName: 'range',
        },
        {
          field: 'topics',
          headerName: 'トピックス',
          width: 100,
          cellClassName: 'topics',
        },
        {
          field: 'delivery_date',
          headerName: '公開予定日時',
          width: 150,
        },
        {
          field: 'status',
          headerName: 'Status',
          width: 100,
          cellClassName: 'status',
        },
        {
          field: 'distribution',
          headerName: 'ニュース配信',
          width: 100,
          cellClassName: 'distribution',
        },
      ];

      const operationBtn = [
        {
          field: 'distributionBtn',
          headerName: '公開',
          sortable: false,
          width: 90,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <>
              {params.row.delivered ? (
                <div className={classes.distributionBtnConfirm}>
                  <button>公開</button>
                </div>
              ) : (
                <div className={classes.distributionBtn}>
                  <button
                    onClick={() => handleClick('distribution', params.row.uuid)}
                  >
                    公開{params.row.delivered}
                  </button>
                </div>
              )}
            </>
          ),
        },
        {
          field: 'detailBtn',
          headerName: '詳細',
          sortable: false,
          width: 90,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <div className={classes.detailBtn}>
              <button onClick={() => handleClick('detail', params.row.uuid)}>
                詳細
              </button>
            </div>
          ),
        },
        {
          field: 'updateBtn',
          headerName: '更新',
          sortable: false,
          width: 90,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <div className={classes.updateBtn}>
              <button onClick={() => handleClick('update', params.row.uuid)}>
                修正
              </button>
            </div>
          ),
        },
      ];
      const columnsDataset = headers.concat(operationBtn);
      setColumns(columnsDataset);

      setRows(
        viewData.map((d, index) => {
          const result = {};
          result['id'] = index + 1;
          result['uuid'] = d['uuid'];
          result['delivered'] = d['delivered'];
          result['title'] = d['title'];
          result['description'] = d['description'];

          if (d['is_members_only']) {
            result['range'] = '会員のみ';
          } else {
            result['range'] = '全世界';
          }

          if (d['is_topics']) {
            result['topics'] = 'Topics';
          } else {
            result['topics'] = '-';
          }

          if (d['delivered']) {
            if (d['delivery_date']) {
              const date = new Date(d['delivery_date']);
              const today = new Date();
              if (date < today) {
                result['status'] = '公開済み';
              } else {
                result['status'] = '公開予定';
              }
            }
          } else {
            result['status'] = '公開未定';
          }
          if (d['distribution_is']) {
            result['distribution'] = '有り';
          } else {
            result['distribution'] = '無し';
          }

          if (d['delivery_date']) {
            const date = new Date(d['delivery_date']);
            result['delivery_date'] =
              date.toLocaleDateString() +
              ' ' +
              date.getHours() +
              ':' +
              date.getMinutes().toString().padStart(2, '0');
          } else {
            result['delivery_date'] = '-';
          }

          return result;
        })
      );
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [viewData]);

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {isAuth ? (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">ニュース一覧</span>
              <span>{date}</span>
            </div>
            <div>
              <span>公開数</span>
              <span>{newsNum}件</span>
              <span>未公開数</span>
              <span>{nonNewsNum}件</span>
              <span>総登録数</span>
              <span>{newsNum + nonNewsNum}件</span>
            </div>
          </div>
          <BreadCrumbs crumbs={CRUMBS} />
          <hr />
          <div className={classes.createBtnContainer}>
            <div id="createBtn1">
              <span>新規登録&nbsp;:&nbsp;</span>
            </div>
            <div id="createBtn2">
              <button id="createbtn1" onClick={() => handleClick('create')}>
                登録
              </button>
            </div>
          </div>
          <div className={classes.viewDataBtnContainer}>
            <div id="viewBtn1">
              <span>絞り込み&nbsp;:&nbsp;</span>
            </div>
            <div id="viewBtn2">
              <button id="btn1" onClick={() => handleNews('all')}>
                すべて
              </button>
              <button id="btn2" onClick={() => handleNews('positive')}>
                公開済
              </button>
              <button id="btn4" onClick={() => handleNews('participant')}>
                未公開
              </button>
            </div>
          </div>
          <div className={classes.downLoadBtnContainer}>
            <div id="dlBtn1">
              <span>DownLoad&nbsp;:&nbsp;</span>
            </div>
            <div id="dlBtn2">
              <button id="dlbtn1" onClick={() => downloadCsv()}>
                <img src={imageIndex.CloudDownloadIcon} alt="dlicon" />
              </button>
            </div>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <Box className={classes.dataGrid}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={PAGESIZE}
                rowsPerPageOptions={[PAGESIZE]}
                density="comfortable"
                columnVisibilityModel={{ uuid: false, delivered: false }}
                pagination={true}
                autoPageSize={true}
                rowHeight={ROWHEIGHT}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              />
            </Box>
          )}
        </>
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
      <Footer />
    </>
  );
};
export default NewsIndex;
