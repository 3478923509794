import { Box, makeStyles } from '@material-ui/core';
import { DataGrid, jaJP } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import { documentIndex, memberIndex, myPage } from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import { localStorageArrayHandler } from '../../../CommonFunction/managePermission';
import Footer from '../../../Footer/footer';
import various from '../../../General/various';
import Clock from '../../../General/clock';
import { Spinner } from '../../../Spinner';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
  },
  container: {
    marginTop: '64px',
  },
  dataGrid: {
    height: (props) =>
      props.heightSize ? props.heightSize : props.pageSize * props.rowHeight,
    width: '100%',
  },
}));

const DocumentIndex = () => {
  const { isAuth } = useContext(AuthContext);
  const permissions = localStorageArrayHandler('parse', various.permissionKey);

  const [date, setDate] = useState();
  const [documents, setDocuments] = useState();
  const [members, setMembers] = useState();
  const [user, setUser] = useState();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [heightSize, setHeightSize] = useState();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const TIMEZONE = various.timezone;
  const ROWHEIGHT = 80;
  const PAGESIZE = 30;
  const classes = useStyles({
    pageSize: PAGESIZE,
    rowHeight: ROWHEIGHT,
    heightSize: heightSize,
  });

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '文書・資料一覧', path: '/manage/document_index' },
  ];

  // Init
  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  useEffect(() => {
    documentIndex(setDocuments);
    memberIndex(setMembers);
    myPage(setUser);
  }, []);

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleClick = (row, flag) => {
    if (flag === 'update') {
      navigate(various.general.documentUpdate, {
        state: {
          previous: location.pathname,
          uuid: row.uuid,
        },
      });
    } else if (flag === 'create') {
      navigate(various.general.documentCreate, {
        state: {
          previous: location.pathname,
        },
      });
    }
  };

  // Contents
  useEffect(() => {
    if (documents && members && user) {
      setColumns([
        {
          field: 'id',
          headerName: 'ID',
          width: 10,
        },
        {
          field: 'uuid',
        },
        {
          field: 'link',
        },
        {
          field: 'name',
          headerName: '名称',
          cellClassName: 'docs-name',
          width: 200,
        },
        {
          field: 'owner',
          headerName: '所有者',
          cellClassName: 'owner-name',
          width: 200,
        },
        {
          field: 'inuse',
          headerName: '稼働区分',
          cellClassName: 'inuse-cell',
          width: 100,
        },
        {
          field: 'use_place',
          headerName: '使用場所',
          cellClassName: 'place-cell',
          width: 250,
        },
        {
          field: 'description',
          headerName: '説明',
          cellClassName: 'descs-cell',
          width: 300,
        },
        {
          field: 'date',
          headerName: '登録日時',
          cellClassName: 'link-cell',
          width: 200,
        },
        {
          field: 'linkBtn',
          headerName: 'Link',
          sortable: false,
          width: 100,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <div>
              <a href={params.row.link} download>
                <button style={{ cursor: 'pointer' }}>Link</button>
              </a>
            </div>
          ),
        },
        {
          field: 'updateBtn',
          headerName: '更新',
          sortable: false,
          width: 100,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <div>
              <button
                style={{ cursor: 'pointer' }}
                onClick={() => handleClick(params.row, 'update')}
              >
                更新
              </button>
            </div>
          ),
        },
      ]);

      const viewData = documents.map((doc) => {
        let result;
        if (
          permissions.includes('admin') ||
          permissions.includes('seminar_staff')
        ) {
          result = doc;
        } else if (
          permissions.includes('instructor') ||
          permissions.includes('organizer')
        ) {
          if (doc.owner.email === user.email) {
            result = doc;
          }
        } else {
          result = null;
        }
        return result;
      });
      const result = [];
      viewData.forEach((doc, index) => {
        if (doc) {
          const owner = members.filter((member) => {
            return member.email === doc.owner.email;
          })[0];

          const seminars = doc.seminars.filter((obj) => {
            return doc.uuid === obj.documents.uuid;
          });

          const date = Clock({
            timezone: TIMEZONE,
            time: null,
            string: doc.created_at,
          }).today;

          const row = {};
          row['id'] = index + 1;
          row['uuid'] = doc.uuid;
          row['link'] = doc.document;
          row['name'] = `${doc.title}`;
          row['owner'] = owner ? owner.last_name + owner.first_name : '-';
          row['inuse'] = doc.is_inuse ? '稼働中' : '未使用';
          row['use_place'] =
            seminars.length > 0
              ? seminars.map((seminar) => {
                  return (
                    seminar.title +
                    (seminar.serial_number
                      ? ` #${seminar.serial_number}`
                      : '') +
                    '\n'
                  );
                })
              : doc.use_place;
          row['description'] = doc.description;
          row['date'] = date;
          result.push(row);
        }
      });

      setRows(result);
      setHeightSize(documents.length * ROWHEIGHT * 1.4 + 122);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [documents, members, user]);

  return (
    <>
      <>
        <div style={{ margin: '80px' }}>
          <OnOffByScroll place="General" />
        </div>
        {!isAuth ? (
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleAuth}
            >
              ログイン
            </span>
            してください。
          </div>
        ) : permissions.includes('admin') ||
          permissions.includes('instructor') ||
          permissions.includes('organizer') ||
          permissions.includes('seminar_staff') ? (
          <>
            <div className={classes.pageTitle}>
              <div>
                <span id="title">文書・資料一覧</span>
                <span>{date}</span>
                <p style={{ fontSize: '0.9rem', marginLeft: '32px' }}>
                  文書・資料の登録：
                  <button onClick={() => handleClick(null, 'create')}>
                    登録
                  </button>
                </p>
              </div>
            </div>
            <hr />
            <BreadCrumbs crumbs={CRUMBS} />
            {loading ? (
              <Spinner />
            ) : (
              <div className={classes.container}>
                <Box
                  className={classes.dataGrid}
                  sx={{
                    '& .descs-cell': {
                      '& > div': {
                        overFrow: 'scroll !important',
                      },
                    },
                    '& .tag-name': {
                      fontStyle: 'italic',
                      fontFamily:
                        '"Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif',
                      fontWeight: 'bold',
                    },
                  }}
                >
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={PAGESIZE}
                    rowsPerPageOptions={[PAGESIZE]}
                    // checkboxSelection
                    density="comfortable"
                    columnVisibilityModel={{
                      uuid: false,
                      link: false,
                    }}
                    pagination={true}
                    autoPageSize={true}
                    rowHeight={ROWHEIGHT}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    localeText={
                      jaJP.components.MuiDataGrid.defaultProps.localeText
                    }
                  />
                </Box>
              </div>
            )}
          </>
        ) : permissions === 'null' ? (
          setTimeout(() => {
            window.location.reload(true);
          }, 500)
        ) : (
          <>
            <div className={classes.pageTitle}>
              <div>
                <span id="title">文書・資料一覧</span>
                <span>{date}</span>
              </div>
            </div>
            <hr />
            <div className={classes.container}>権限がありません</div>
          </>
        )}
        <Footer />
      </>
    </>
  );
};
export default DocumentIndex;
