import axios from 'axios';
// import { envParams } from './envParams';
import various from '../General/various';

// Images
export function imageDetail(setState, uuid) {
  // const params = { ...envParams };
  // params.uuid = uuid;
  // axios.get(`/api/image_detail`, { params: params }).then((res) => {
  //   setState(res.data);
  //   console.log('get ImageDetail');
  // });

  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}image_detail/${uuid}/`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in apiOfManaged/imageDetail :');
    });
}

export function imageFormDataUpdate(formData, uuid) {
  // const params = { ...envParams };
  // params.uuid = uuid;
  // params.formData = formData;
  // axios.get(`/api/image_formdata_update`, { params: params }).then((res) => {
  //   console.log('imageFormDataUpdate done : ');
  // });

  let config = { headers: { 'content-type': 'multipart/form-data' } };
  return axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}image_update/${uuid}/`,
      formData,
      config
    )
    .then((res) => {
      console.log('imageFormDataUpdate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/imageUpdate :', err);
    });

  // fetch(`/api/image_formdata_update?query` + params).then((res) =>
  //   console.log('imageFormDataUpdate done : ')
  // );
}

export function imagePatchUpdate(dataset) {
  return axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}image_update/${dataset.uuid}/`,
      dataset
    )
    .then((res) => {
      console.log('imagePatchUpdate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/imageUpdate :', err);
    });
}

export function imageCreate(formData) {
  let config = { headers: { 'content-type': 'multipart/form-data' } };
  return axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}image_create/`,
      formData,
      config
    )
    .then((res) => {
      console.log('imageCreate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/imageCreate :');
    });
}

export function documentIndex(setState) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}documetns_index/`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in  apiOfManaged/documentIndex :');
    });
}

export function documentCreate(formData) {
  let config = { headers: { 'content-type': 'multipart/form-data' } };
  return axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}documetns_create/`,
      formData,
      config
    )
    .then((res) => {
      console.log('DocumentCreate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/documentCreate :');
    });
}

export function documentFormDataUpdate(formData, uuid) {
  let config = { headers: { 'content-type': 'multipart/form-data' } };
  return axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}documetns_update/${uuid}/`,
      formData,
      config
    )
    .then((res) => {
      console.log('documentFormDataUpdate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/documentUpdate :', err);
    });
}

export function documentPatchUpdate(dataset) {
  return axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}documetns_update/${dataset.uuid}/`,
      dataset
    )
    .then((res) => {
      console.log('documentPatchUpdate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/documentUpdate :', err);
    });
}

//   AuthTest
export function auth_test(setState) {
  // const params = { ...envParams };
  // axios.get(`/api/auth_test`, { params: params }).then((res) => {
  //   setState(res.data);
  //   console.log('set data');
  // });

  axios
    .post(`${various.currentDomain}${various.apiUrlsRequireAuth}auth_test/`)
    .then((res) => {
      setState(res.data);
      console.log('set data');
    })
    .catch((err) => {
      console.log('error in apiOfManaged :');
    });
}

export function authTestDetail(setState, uuid) {
  // const params = { ...envParams };
  // params.uuid = uuid;
  // axios.get(`/api/auth_test_detail`, { params: params }).then((res) => {
  //   setState(res.data);
  //   console.log('get authTestDetail');
  // });

  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}auth_test_detail/${uuid}`
    )
    .then((res) => {
      setState(res.data);
      console.log('get authTestDetail');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/authTestDetail :');
    });
}

export function authTestPatchUpdate(dataset) {
  // const params = { ...envParams };
  // params.uuid = dataset.uuid;
  // params.dataset = dataset;
  // axios.get(`/api/auth_test_patch_update`, { params: params }).then((res) => {
  //   console.log('authTestPathUpdate done : ');
  // });

  return axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}auth_test_update/${dataset.uuid}`,
      dataset
    )
    .then((res) => {
      console.log('authTestPathUpdate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/authTestUpdate :');
    });
}

export function authTestFormDataUpdate(formData, uuid) {
  // const params = { ...envParams };
  // params.uuid = uuid;
  // params.formData = formData;
  // axios
  //   .get(`/api/auth_test_formdata_update`, { params: params })
  //   .then((res) => {
  //     console.log('authTestFormDataUpdate done : ');
  //   });

  let config = { headers: { 'content-type': 'multipart/form-data' } };
  return axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}auth_test_update/${uuid}`,
      formData,
      config
    )
    .then((res) => {
      console.log('authTestFormDataUpdate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/authTestUpdate :');
    });
}

export function authTestCreate(formData) {
  let config = { headers: { 'content-type': 'multipart/form-data' } };
  return axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}auth_test_create/`,
      formData,
      config
    )
    .then((res) => {
      console.log('authTestCreate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/authTestCreate :');
    });
}

export function paramsIndex(setState) {
  axios
    .get(`${various.currentDomain}${various.apiUrlsRequireAuth}params_index/`)
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in  apiOfManaged/paramsIndex :');
    });
}

export function trainingPlaceIndex(setState, query) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}trainning_place_index/?is_useful=${query}`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in  apiOfManaged/trainingPlaceIndex :');
    });
}

export function trainingPlaceDetailForAdmin(setState, uuid) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}training_place_detail/${uuid}/`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in training_place_detail', err);
    });
}

export function trainingPlacePatchUpdate(dataset) {
  axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}training_place_update/${dataset.uuid}/`,
      dataset
    )
    .then((res) => {
      console.log('training_place_updatePathUpdate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/training_place_updateUpdate');
    });
}

export function trainingPlaceCreate(dataset) {
  axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}trainning_place_create/`,
      dataset
    )
    .then((res) => {
      console.log('trainning_place_create done');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/trainning_place_create :', err);
    });
}

export function trainingHallIndex(setState) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}trainning_hall_index/`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in  apiOfManaged/trainingHallIndex :');
    });
}

export function myPage(setState) {
  axios
    .get(`${various.currentDomain}api/auth/users/me/`)
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('err in myPage()');
    });
}

export function memberIndex(setState) {
  axios
    .get(`${various.currentDomain}${various.apiUrlsRequireAuth}member_index/`)
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in member_index');
    });
}

export function memberDetail(setState, uuid) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}member_detail/${uuid}/`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in apiOfManaged/memberDetail');
    });
}

export function memberPatchUpdate(dataset) {
  return axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}member_update/${dataset.uuid}/`,
      dataset
    )
    .then((res) => {
      console.log('memberPathUpdate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/memberUpdate :');
    });
}

export function memberPatchUpdateOfHimself(dataset) {
  axios
    .post(`${various.currentDomain}api/recaptcha/`, dataset)
    .then((res) => {
      axios
        .patch(
          `${various.currentDomain}${various.apiUrlsRequireAuth}member_update_himself/${dataset.uuid}/`,
          res.data.verify_data
        )
        .then((res) => {
          console.log('memberPathUpdateHimself done : ');
        })
        .catch((err) => {
          console.log('error in apiOfManaged/memberPathUpdateHimself :');
        });
    })
    .catch((err) => {
      console.log('not varifying by reCAPTCH.');
    });
}

export function memberCreate(dataset) {
  return axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}member_create/`,
      dataset
    )
    .then((res) => {
      console.log('memberCreate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/memberCreate :');
    });
}

export function memberCreateSendForm(dataset) {
  return axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}member_create_send_form/`,
      dataset
    )
    .then((res) => {
      console.log('response :', res);
      console.log('memberCreateSendForm done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/memberCreateSendForm :');
    });
}

export function memberBulkCreate(setState, setMessage, dataset) {
  axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}member_bulk_create/`,
      dataset
    )
    .then((res) => {
      console.log('memberBulkCreate done : ');
      if (res.data.code === 400) {
        setMessage(res.data.message);
      } else if (res.data.code === 200) {
        setState(true);
      }
    })
    .catch((err) => {
      console.log('error in apiOfManaged/memberBulkCreate :');
      setState(false);
      setMessage(err.data.message);
    });
}

export function memoIndex(setState) {
  axios
    .get(`${various.currentDomain}${various.apiUrlsRequireAuth}memo_index/`)
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in memo_index');
    });
}

export function memoPatchUpdate(dataset) {
  axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}memo_update/`,
      dataset
    )
    .then((res) => {
      console.log('done.');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/memoUpdate');
    });
}

export function passwordResetInMyPage(setState, dataset) {
  return axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}password_reset/${dataset.pk}/`,
      dataset
    )
    .then((res) => {
      console.log('password reset done : ');
      setState(true);
    })
    .catch((err) => {
      console.log('error in apiOfManaged/passwordResetInMyPage');
    });
}

export function passwordResetForAdmin(setState, dataset) {
  axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}password_reset_for_admin/${dataset.pk}/`,
      dataset
    )
    .then((res) => {
      console.log('password reset done : ');
      setState(true);
    })
    .catch((err) => {
      console.log('error in apiOfManaged/passwordResetForAdmin', err);
    });
}

export function emailResetInMyPage(setState, setMessage, dataset) {
  return axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}email_reset/`,
      dataset
    )
    .then((res) => {
      console.log('email reset done : ');
      if (res.data.code === 200) {
        setState(true);
      } else if (res.data.code === 400) {
        setState(false);
        setMessage(res.data.message);
      }
    })
    .catch((err) => {
      setMessage(err.data.message);
    });
}

export function tagIndexForAdmin(setState, flag) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}tag_index/?is_useful=${flag}`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in tag_index_for_admin', err);
    });
}

export function tagDetailForAdmin(setState, uuid) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}tag_detail/${uuid}/`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in tag_detail_for_admin', err);
    });
}

export function tagPatchUpdate(dataset) {
  axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}tag_update/${dataset.uuid}/`,
      dataset
    )
    .then((res) => {
      console.log('tagPathUpdate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/tagUpdate');
    });
}

export function tagCreate(dataset) {
  axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}tag_create/`,
      dataset
    )
    .then((res) => {
      console.log('tagCreate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/tagCreate :', err);
    });
}

export function instructorIndexForAdmin(setState, flag) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}instructor_index/?is_working=${flag}`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in instructor_index_for_admin', err);
    });
}

export function instructorDetailForAdmin(setState, uuid) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}instructor_detail/${uuid}/`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in instructor_detail_for_admin', err);
    });
}

export function instructorFormDataUpdate(formData, uuid) {
  let config = { headers: { 'content-type': 'multipart/form-data' } };
  return axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}instructor_update/${uuid}/`,
      formData,
      config
    )
    .then((res) => {
      console.log('instructorFormDataUpdate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/instructorUpdate :', err);
    });
}

export function instructorPatchUpdate(dataset) {
  return axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}instructor_update/${dataset.uuid}/`,
      dataset
    )
    .then((res) => {
      console.log('instructorPatchUpdate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/instructorUpdate :', err);
    });
}

export function instructorCreate(formData) {
  let config = { headers: { 'content-type': 'multipart/form-data' } };
  return axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}instructor_create/`,
      formData,
      config
    )
    .then((res) => {
      console.log('instructorCreate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/instructorCreate :');
    });
}

export function branchesIndexForAdmin(setState) {
  axios
    .get(`${various.currentDomain}${various.apiUrlsRequireAuth}branches_index/`)
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in branches_index_for_admin', err);
    });
}

export function branchDetailForAdmin(setState, uuid) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}branch_detail/${uuid}/`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in branch_detail_for_admin', err);
    });
}

export function branchFormDataUpdate(formData, uuid) {
  let config = { headers: { 'content-type': 'multipart/form-data' } };
  return axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}branch_update/${uuid}/`,
      formData,
      config
    )
    .then((res) => {
      console.log('branchFormDataUpdate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/branchUpdate :', err);
    });
}

export function branchPatchUpdate(dataset) {
  return axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}branch_update/${dataset.uuid}/`,
      dataset
    )
    .then((res) => {
      console.log('branchPatchUpdate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/branchUpdate :', err);
    });
}

export function branchCreate(formData) {
  let config = { headers: { 'content-type': 'multipart/form-data' } };
  return axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}branch_create/`,
      formData,
      config
    )
    .then((res) => {
      console.log('branchCreate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/branchCreate :');
    });
}

export function newsIndexForAdmin(setState) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}news_index_for_admin/`
    )
    .then((res) => {
      setState(res.data.data);
    })
    .catch((err) => {
      console.log('error in news_index_for_admin', err);
    });
}

export function newsDetailForAdmin(setState, uuid) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}news_detail_for_admin/${uuid}/`
    )
    .then((res) => {
      const obj = res.data.data;
      const result = {
        uuid: obj.uuid,
        title: obj.title,
        delivery_date: obj.delivery_date,
        tag: obj._ik,
        content: obj.content,
        news_image: obj.news_image,
        delivered: obj.delivered,
        description: obj.description,
        disclosure_range: obj.disclosure_range,
        distribution_is: obj.distribution_is,
        is_members_only: obj.is_members_only,
        is_topics: obj.is_topics,
        topics_end_date: obj.topics_end_date,
      };
      setState(result);
    })
    .catch((err) => {
      console.log('error in news_detail_for_admin', err);
    });
}

export function newsPatchUpdate(dataset) {
  return axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}news_update_for_admin/${dataset.uuid}/`,
      dataset
    )
    .then((res) => {
      console.log('newsPathUpdate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/newsUpdate :', err);
    });
}

export function newsCreate(dataset) {
  return axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}news_create/`,
      dataset
    )
    .then((res) => {
      console.log('newsCreate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/newsCreate :', err);
    });
}

export function notificationCreate(dataset) {
  return axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}notification_create/`,
      dataset
    )
    .then((res) => {
      console.log('notificationCreate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/notificationCreate :', err);
    });
}

export function notificationIndex(setState) {
  return axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}notification_index/`
    )
    .then((res) => {
      setState(res.data);
      console.log('notificationIndex done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/notificationIndex :', err);
    });
}

export function notificationUpdate(dataset) {
  return axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}notification_update/${dataset.uuid}/`,
      dataset
    )
    .then((res) => {
      console.log('notificationUpdate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/notificationUpdate :', err);
    });
}

export function notificationDetail(setState, uuid) {
  return axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}notification_detail/${uuid}/`
    )
    .then((res) => {
      setState(res.data);
      console.log('notificationDetail done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/notificationDetail :', err);
    });
}

export function seminarIndexForAdmin(setState, user, permissions) {
  return axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}seminar_index_for_admin/`
    )
    .then((res) => {
      if (res.data.data) {
        if (
          permissions.includes('admin') ||
          permissions.includes('seminar_staff')
        ) {
          setState(res.data.data);
        } else {
          if (permissions.includes('instructor')) {
            const result = [];
            res.data.data.forEach((d) => {
              if (d.instructor.user.email === user.email) {
                result.push(d);
              }
            });
            setState(result);
          } else if (permissions.includes('organizer')) {
            const result = [];
            res.data.data.forEach((d) => {
              if (d.organizer.email === user.email) {
                result.push(d);
              }
            });
            setState(result);
          }
        }
      }
    })
    .catch((err) => {
      console.log('error in seminar_index_for_admin', err);
    });
}

export function seminarDetailForAdmin(setState, uuid) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}seminar_detail_for_admin/${uuid}/`
    )
    .then((res) => {
      setState(res.data.data);
    })
    .catch((err) => {
      console.log('error in seminar_detail_for_admin', err);
    });
}

export function seminarPatchUpdate(dataset) {
  return axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}seminar_update_for_admin/${dataset.uuid}/`,
      dataset
    )
    .then((res) => {
      console.log('seminarPathUpdate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/seminarUpdate :', err);
    });
}

export function seminarCreate(dataset) {
  return axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}seminar_create/`,
      dataset
    )
    .then((res) => {
      console.log('seminarCreate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/seminarCreate :', err);
    });
}

export function seminarAssistanceIndex(setState, seminarUUID) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}seminar_assistance_index/?uuid=${seminarUUID}`
    )
    .then((res) => {
      setState(res.data.data);
    })
    .catch((err) => {
      console.log('error in seminar_assistance_index', err);
    });
}

export function seminarAssistanceIndexSortedByMember(setState, memberUUID) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}seminar_assistance_index_sorted_by_member/?uuid=${memberUUID}`
    )
    .then((res) => {
      setState(res.data.data);
    })
    .catch((err) => {
      console.log('error in seminar_assistance_index_sorted_by_member', err);
    });
}

export function seminarCancelPatchUpdate(setState, dataset) {
  return axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}seminar_assistance_update/${dataset.uuid}/`,
      dataset
    )
    .then((res) => {
      console.log('seminarCancelPatchUpdate done : ');
      if (res.data.code === 200) {
        setState(true);
      }
    })
    .catch((err) => {
      console.log('error in apiOfManaged/seminarCancelPatchUpdate :', err);
    });
}

export function seminarCancelPatchUpdateByAdmin(setState, dataset) {
  return axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}seminar_assistance_update_by_admin/${dataset.uuid}/`,
      dataset
    )
    .then((res) => {
      console.log('seminarCancelPatchUpdate done : ');
      setState(true);
    })
    .catch((err) => {
      console.log(
        'error in apiOfManaged/seminarCancelPatchUpdate :',
        err.response
      );
    });
}

export function getSeminarOrganizers(setState) {
  return axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}seminar_organizer_index/`
    )
    .then((res) => {
      setState(res.data.data);
    })
    .catch((err) => {
      console.log('error in getSeminarOrganizers :', err);
    });
}

// Stripe決済
export function createCheckoutSession(data, setState) {
  axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}create_checkout_session/`,
      data
    )
    .then((res) => {
      setState(res.data.id);
    })
    .catch((err) => {
      console.log('error in createCheckoutSession :', err);
    });
}

export function getStripeCustomerInfo(setState, user_email) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}stripe_customer_info/${user_email}/`
    )
    .then((res) => {
      // 以下、少し意味不明だが、APIコールしてレコードがなくても(status=404)200とobjectが返るので、以下のようにしている。
      if ('detail' in res.data) {
        if (res.data.detail === 'Not found.') {
          setState(null);
        } else {
          setState(res.data);
        }
      } else {
        setState(res.data);
      }
    })
    .catch((err) => {
      if (err.response && err.response.status === 404) {
        setState(null);
      } else {
        console.log('error in getStripeCustomerInfo', err);
      }
    });
}

export function updateStripeCustomerEmail(data, setState) {
  axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}update_stripe_customer_email/`,
      data
    )
    .then((res) => {
      setState(res.status);
    })
    .catch((err) => {
      console.log('error in updateStripeCustomerEmail :', err);
    });
}

export function cancelServicePlan(data, setState) {
  return axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}cancel_service_plan/`,
      data
    )
    .then((res) => {
      setState(true);
    })
    .catch((err) => {
      console.log('error in cancelServicePlan :', err);
    });
}

export function cancelSubscriptionRequest(data, setState) {
  axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}cancel_subscription_request/`,
      data
    )
    .then((res) => {
      setState(true);
    })
    .catch((err) => {
      console.log('error in cancelSubscriptionRequest :', err);
    });
}

export function limitationsOfSubscriptionChanges(data, setState) {
  return axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}limitations_of_subscription_changes/`,
      data
    )
    .then((res) => {
      if (res.data.data.judge) {
        console.log('limitationsOfSubscriptionChanges: Allowed');
        setState(true);
      } else {
        console.log(
          `limitationsOfSubscriptionChanges: Not Allowed, reason: ${res.data.data.reason}`
        );
        setState(false);
      }
    })
    .catch((err) => {
      console.log('error in limitationsOfSubscriptionChanges :', err);
    });
}

export function limitationsOfMembershipChanges(data, setState) {
  return axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}limitations_of_membership_cahnges/`,
      data
    )
    .then((res) => {
      if (res.data.data.judge) {
        console.log('limitationsOfMembershipChanges: Allowed');
        setState(true);
      } else {
        console.log(
          `limitationsOfMembershipChanges: Not Allowed, reason: ${res.data.data.reason}`
        );
        setState(false);
      }
    })
    .catch((err) => {
      console.log('error in limitationsOfMembershipChanges :', err);
    });
}

export function returnToCach(data, setState) {
  axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}return_to_cash/`,
      data
    )
    .then((res) => {
      setState(true);
    })
    .catch((err) => {
      console.log('error in returnToCash :', err);
    });
}

export function returnToCard(data, setState) {
  return axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}return_to_card/`,
      data
    )
    .then((res) => {
      setState(true);
    })
    .catch((err) => {
      console.log('error in returnToCash :', err);
    });
}

export function productMasterCreate(data) {
  return axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}product_master_create/`,
      data
    )
    .then((res) => {
      console.log('productMasterCreate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/productMasterCreate :');
    });
}

export function productMasterFormDataCreate(formData) {
  let config = { headers: { 'content-type': 'multipart/form-data' } };
  return axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}product_master_create/`,
      formData,
      config
    )
    .then((res) => {
      console.log('productMasterCreate done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/productMasterCreate :');
    });
}

export function productMasterIndex(setState) {
  return axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}product_master_index/`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in product_master_index', err);
    });
}

export function productMasterDetail(setState, uuid) {
  return axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}product_master_detail/${uuid}/`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in product_master_detail', err);
    });
}

export function productMasterFormDataUpdate(formData, uuid) {
  let config = { headers: { 'content-type': 'multipart/form-data' } };
  return axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}product_master_update/${uuid}/`,
      formData,
      config
    )
    .then((res) => {
      console.log('product_master_update done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/product_master_update :', err);
    });
}

export function productMasterUpdate(data) {
  return axios
    .patch(
      `${various.currentDomain}${various.apiUrlsRequireAuth}product_master_update/${data.uuid}/`,
      data
    )
    .then((res) => {
      if (res.status === 200) {
        console.log('product_master_update done : ');
      } else if (res.status === 204) {
        alert(
          '更新した商品はアーカイブされました。過去の決済は継続されますが、新規の決済はできなくなります。'
        );
      }
    })
    .catch((err) => {
      console.log('error in apiOfManaged/product_master_update :', err);
    });
}

export function getStripeCustomers(setState) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}stripe_customer_index/`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in stripe_customer_index', err);
    });
}

export function membersWithStripeCustomers(setState) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}members_with_customers_index/`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in members_with_customers_index', err);
    });
}

export function membersWithServicePlan(setState) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}service_plan_with_members/`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in service_plan_with_members', err);
    });
}

export function getServicePlan(setState) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}service_plan_index/`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in service_plan_index', err);
    });
}

export function getServicePlanDetail(setState, uuid) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}service_plan_detail/${uuid}`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in service_plan_detail', err);
    });
}

export function getCustomerPaymentsAndInvoices(setState, data) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}get_customer_payments_and_invoices/?customer_id=${data.customer_id}&by_api=${data.by_api}`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in get_customer_payments_and_invoices', err);
    });
}

export function deleteStripeCustomer(data, setState) {
  axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}delete_stripe_customer_for_test_env/`,
      data
    )
    .then((res) => {
      setState(true);
    })
    .catch((err) => {
      console.log('error in delete_stripe_customer_for_test_env :', err);
    });
}

export function initializeForStripe(setState) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}initialize_for_stripe/`
    )
    .then((res) => {
      setState(true);
    })
    .catch((err) => {
      console.log('error in initialize_for_stripe :', err);
    });
}

export function userRelatedSubscriptionChangeRequestIndex(
  setState,
  user_email
) {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}user_related_subscription_change_request_index/?user_email=${user_email}`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in userRelatedSubscriptionChangeRequestIndex :', err);
    });
}

export function addChargeIdToServicePlans() {
  axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}add_charge_id_to_service_plan/`
    )
    .then((res) => {
      console.log('addChargeIdToServicePlans done : ');
    })
    .catch((err) => {
      console.log('error in initialize_for_stripe :', err);
    });
}

export function getReceivedAmountIndex(setState) {
  return axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}received_amount_index/`
    )
    .then((res) => {
      setState(res.data);
      console.log('getReceivedAmountIndex done : ');
    })
    .catch((err) => {
      console.log('error in getReceivedAmountIndex :', err);
    });
}

export function getReceivedAmountDetail(setState, dataset) {
  return axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}received_amount_detail/${dataset.uuid}`
    )
    .then((res) => {
      setState(res.data);
      console.log('getReceivedAmountDetail done : ');
    })
    .catch((err) => {
      console.log('error in getReceivedAmountDetail :', err);
    });
}

export function alwaysUseCreditCard(user_email) {
  return axios
    .post(
      `${various.currentDomain}${various.apiUrlsRequireAuth}handle_always_use_credit_card/`,
      { email: user_email }
    )
    .then((res) => {
      console.log('alwaysUseCreditCard done : ');
    })
    .catch((err) => {
      console.log('error in alwaysUseCreditCard :', err);
    });
}

export function setDoNotShowAgain() {
  return axios
    .get(
      `${various.currentDomain}${various.apiUrlsRequireAuth}set_do_not_show_again/`
    )
    .then((res) => {
      console.log('setDoNotShowAgain done : ');
    })
    .catch((err) => {
      console.log('error in setDoNotShowAgain :', err);
    });
}
