import { makeStyles } from '@material-ui/core';
import OnOffByScroll from '../Animation/onOffByScroll';
import Footer from '../Footer/footer';
import { useLocation, useNavigate } from 'react-router-dom';
import various from '../General/various';
import { useEffect, useState } from 'react';
import { getJsonData, seminarApply } from '../Apis/apis';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { getStripeCustomerInfo } from '../Apis/apiOfManaged';
import { Spinner } from '../Spinner';

const useStyles = makeStyles((theme) => ({
  container: {
    '& > p': {
      fontSize: '0.9em',
      textAlign: 'start',
      '& > span': {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
      },
    },
    '& > span': {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: theme.palette.primary.main,
      fontSize: '0.8em',
      fontWeight: 'bold',
    },
  },
}));

const PaymentSuccess = () => {
  const [applyData, setApplyData] = useState(null);
  const [seminarView, setSeminarView] = useState(false);
  const [apply, setApply] = useState(null);
  const [message, setMessage] = useState(null);
  const [stripeCustomer, setStripeCustomer] = useState();
  const [loading, setLoading] = useState(true);
  const [membershipView, setMembershipView] = useState(true);

  const [token, setToken] = useState('');
  const [submitData, setSubmitData] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();

  const MAX_RETRIES = 3;
  const RETRY_DELAY = 2000; // 2秒
  const [retryCount, setRetryCount] = useState(0);

  // if (applyData) console.log('applyData.data:', applyData?.data?.redirect);

  // Init
  const handleClick = () => {
    navigate(various.general.mypage, {
      state: { previous: location.pathname },
    });
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const session_id = query.get('session_id');
    getJsonData(setApplyData, session_id);
  }, [location]);

  useEffect(() => {
    if (applyData) {
      getStripeCustomerInfoWithRetry(applyData.data.email);
    } else {
      setMembershipView(true);
    }
    // eslint-disable-next-line
  }, [applyData]);

  const getStripeCustomerInfoWithRetry = (email) => {
    if (retryCount < MAX_RETRIES && !stripeCustomer) {
      getStripeCustomerInfo((response) => {
        if (response === null) {
          setRetryCount((prevCount) => prevCount + 1);
        } else {
          setStripeCustomer(response);
        }
      }, email);
    } else if (!stripeCustomer) {
      alert(
        `決済代行会社からの顧客情報の取得ができませんでした。
        メールをご確認いただき、決済が完了していること及びセミナー申し込みが完了していることをご確認ください。
        もしメールが届いていない場合は、お手数ですがお問い合わせください。`
      );
      setLoading(false); // ローディングを終了
      navigate(various.general.seminar);
    }
  };

  useEffect(() => {
    if (
      !stripeCustomer &&
      retryCount > 0 &&
      retryCount < MAX_RETRIES &&
      applyData
    ) {
      const timeoutId = setTimeout(() => {
        getStripeCustomerInfoWithRetry(applyData.data.email);
      }, RETRY_DELAY);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line
  }, [retryCount, stripeCustomer, applyData]);

  useEffect(() => {
    if (applyData && stripeCustomer) {
      if (applyData.data.redirect) {
        // 会員かつ決済情報未登録の者がセミナーに申し込んだ際に、まず決済情報を登録し、その後本ページにリダイレクトされ、以下の処理でセミナー決済画面へとリダイレクトされる。
        delete applyData.data.redirect; //falseにするか削除しないと決済画面と本ページの間で無限ループに陥る
        navigate(various.general.paymentViewOfOthers, {
          state: {
            previous: location.pathname,
            customer_id: stripeCustomer?.stripe_customer_id,
            customer_email: stripeCustomer?.email,
            user_email: applyData.data.user_email,
            apply_data: applyData.data,
          },
        });
      } else {
        // 決済情報登録済みの会員がセミナーへ申し込んだ際に、本ページにリダイレクトされ、以下の処理でセミナー決済画面へとリダイレクトされる。
        callRecaptcha(applyData.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyData, stripeCustomer, executeRecaptcha]); // 本コンポーネントは全て自動で動くがexecuteRecaptchaがマウント前に実行される可能性があるため依存関係に追加

  // Submit
  const callRecaptcha = async (data) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    } else {
      const reCaptchaToken = await executeRecaptcha('contact');
      setToken(reCaptchaToken);
      setSubmitData(data);
      console.log('Successfully got recaptcha token');
    }
  };

  useEffect(() => {
    if (submitData && token) {
      const data = { ...submitData };
      data['g_recaptcha_response'] = token;
      handleSubmit(data);
    }
    // eslint-disable-next-line
  }, [submitData, token]);

  const handleSubmit = async (data) => {
    setSeminarView(true);
    // カード決済の場合、領収済みの要素を追加
    data['received_is'] = true;
    await seminarApply(setApply, setMessage, data);
    navigate(various.general.seminar);
  };

  useEffect(() => {
    // Error message
    if (message) {
      alert(message);
      navigate(various.general.seminar);
    }
  }, [message, navigate]);

  // handle view
  useEffect(() => {
    if (apply && seminarView) {
      setLoading(false);
    }
  }, [apply, seminarView]);

  useEffect(() => {
    if (membershipView) {
      setLoading(false);
    }
  }, [membershipView]);

  const applySuccess = () => {
    return (
      <>
        <div style={{ margin: '80px' }}>
          <OnOffByScroll place="General" />
        </div>
        <div className={classes.container}>
          <h1>申し込み完了</h1>
          <p>
            お申し込み並びにご決済いただき、ありがとうございます。受付メールを送信いたしました。ご確認ください。
          </p>
          <p>
            ※この画面は数秒後に<span>セミナー一覧</span>
            画面へとリダイレクトします。
          </p>
        </div>
      </>
    );
  };

  const membershipSuccess = () => {
    if (!applyData?.data?.redirect) {
      // マイページから会員登録を行なった場合の成功画面
      return (
        <>
          <div style={{ margin: '80px' }}>
            <OnOffByScroll place="General" />
          </div>
          <div className={classes.container}>
            <h1>登録完了</h1>
            <span onClick={handleClick}>MyPage</span>
            で登録内容を確認できます。
          </div>
        </>
      );
    } else {
      // セミナー申し込みと同時に会員登録した場合、リダイレクトされるが挙動が遅かった場合他のページに遷移（クリック）してしまうケースがあったため、リンクを排除。
      return (
        <div className={classes.container}>
          <h1 style={{ marginTop: '160px' }}>登録完了</h1>
          <p>
            ※この画面は数秒後に"セミナーの決済画面"へとリダイレクトします。このままお待ちください。
          </p>
        </div>
      );
    }
  };

  return (
    <>
      {!loading ? (
        membershipView ? (
          membershipSuccess()
        ) : (
          applySuccess()
        )
      ) : (
        <Spinner />
      )}
      <Footer />
    </>
  );
};
export default PaymentSuccess;
