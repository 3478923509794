import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { branchIndexForFront } from '../Apis/apis';
import ArrowButton from '../General/arrowButton';
import ContentTitle from '../General/contentTitle';
import various from '../General/various';
import {
  emailRegValidator,
  emailValidator,
  selectDefaultCheck,
} from '../CommonFunction/validators';
import { contact } from '../Apis/apis';
import Footer from '../Footer/footer';
import { images as imageApi } from '../Apis/apis';
import { ogpGenerator } from '../ogp';

const ContactForClub = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {},
  });

  const [image, setImage] = useState();
  const [branchIndex, setBranchIndex] = useState();

  const [token, setToken] = useState('');
  const [submitData, setSubmitData] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const location = useLocation();
  const navigate = useNavigate();

  const { classes } = useOutletContext();

  const contactAreaTitle = {
    titleList: ['Contact'],
    sub: '同好会・稽古会への問い合わせ',
  };

  // OGP設定
  const ogp = useCallback((props) => {
    ogpGenerator(props);
  }, []);

  useLayoutEffect(() => {
    if (image) {
      const ogpProps = {
        page: 'contact_club',
        pageTitle:
          '刀禪OfficialWebSite各支部・同好会・稽古会へのお問合せフォーム',
        path: location.pathname,
        imageUrl: image.OfficialImage_TouzenLogoBlack.url,
      };
      ogp(ogpProps);
    }
  }, [image, location, ogp]);
  // OGP設定ここまで

  useEffect(() => {
    imageApi(setImage);
  }, []);

  useEffect(() => {
    branchIndexForFront(setBranchIndex);
  }, []);

  useEffect(() => {
    if (branchIndex) {
      const defaultValues = {};
      if (location.state) {
        branchIndex.map((b, i) => {
          if (b.id === location.state.uuid) {
            defaultValues[`club`] = b.name;
          }
          if (defaultValues) {
            return reset(defaultValues);
          } else {
            return reset({ club: 'choice' });
          }
        });
      }
    }
  }, [reset, location, branchIndex]);

  const onSubmit = async (data) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    } else {
      const reCaptchaToken = await executeRecaptcha('contact');
      setToken(reCaptchaToken);
      setSubmitData(data);
    }
  };

  useEffect(() => {
    if (submitData && token) {
      const data = { ...submitData };
      data['g_recaptcha_response'] = token;
      data['flag'] = 'contact_for_club';
      apiHandler(data);
    }
    // eslint-disable-next-line
  }, [submitData, token]);

  const apiHandler = (data) => {
    branchIndex.forEach((b) => {
      if (b.name === data.club) {
        data['president'] = b.presidentName;
        data['uuid'] = b.id;
        if (b.division !== 'specialClub') {
          data['branch'] = '刀禅' + data.club + '同好会';
        } else {
          data['branch'] = b.name + b.subName;
        }
      }
    });
    contact(data);
    alert('お問合せを受け付けました。返信メールをご確認ください。');
    setTimeout(() => {
      navigate('/');
    }, 2000);
  };

  const form = () => {
    return (
      <div className={classes.formContainer}>
        <form
          method="POST"
          encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <div className={classes.titles}>
              <label htmlFor="氏名（姓）">{'氏名（姓）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="last_name"
                type="text"
                {...register('last_name', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.last_name?.message && (
                <div className={classes.error}>{errors.last_name.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="氏名（カナ姓）">{'氏名（カナ姓）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="last_name_kana"
                type="text"
                {...register('last_name_kana', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.last_name_kana?.message && (
                <div className={classes.error}>
                  {errors.last_name_kana.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="氏名（名）">{'氏名（名）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="first_name"
                type="text"
                {...register('first_name', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.first_name?.message && (
                <div className={classes.error}>{errors.first_name.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="氏名（カナ名）">{'氏名（カナ名）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="first_name_kana"
                type="text"
                {...register('first_name_kana', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.first_name_kana?.message && (
                <div className={classes.error}>
                  {errors.first_name_kana.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="Eメール">{'Eメール'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="email"
                type="email"
                {...register('email', {
                  required: { value: true, message: '入力必須の項目です' },
                  validate: {
                    message: (v) =>
                      emailRegValidator(v)
                        ? null
                        : `※正しいEメールの形式で入力してください`,
                  },
                })}
              />
              {errors.email?.message && (
                <div className={classes.error}>{errors.email.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="Eメール（確認）">{'Eメール（確認）'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="email_confirm"
                type="email"
                {...register('email_confirm', {
                  required: { value: true, message: '入力必須の項目です' },
                  validate: {
                    message: (v) =>
                      emailValidator(v)
                        ? null
                        : `上と同一のものを入力してください。`,
                  },
                })}
              />
              {errors.email_confirm?.message && (
                <div className={classes.error}>
                  {errors.email_confirm.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="居住エリア">{'居住エリア'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="area"
                type="text"
                placeholder="例）東京都"
                {...register('area', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.area?.message && (
                <div className={classes.error}>{errors.area.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="運動歴">{'運動歴'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <textarea
                id="exercise_career"
                type="text"
                {...register('exercise_career', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              <div className={classes.helptext}>
                武道・武術やスポーツ等のご経験があればご記入ください。
              </div>
              {errors.exercise_career?.message && (
                <div className={classes.error}>
                  {errors.exercise_career.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="お問合せ件名">{'お問合せ件名'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="title"
                type="text"
                {...register('title', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.title?.message && (
                <div className={classes.error}>{errors.title.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="お問合せ先の稽古会">{'お問合せ先の稽古会'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <select
                id="club"
                disabled={
                  location.state ? (location.state.uuid ? true : false) : false
                }
                {...register('club', {
                  required: { value: true, message: '入力必須の項目です' },
                  validate: {
                    message: (v) =>
                      selectDefaultCheck(v)
                        ? null
                        : `いずれかのお問い合わせ先を選択してください。`,
                  },
                })}
              >
                <option value="choice">選択してください</option>
                {branchIndex
                  ? branchIndex.map((b, i) => {
                      if (b.division !== 'specialClub') {
                        return (
                          <option key={i} id={`choice_${i}`} value={b.name}>
                            刀禅{b.name}同好会
                          </option>
                        );
                      } else {
                        return (
                          <option key={i} id={`choice_${i}`} value={b.name}>
                            {b.name + b.subName}
                          </option>
                        );
                      }
                    })
                  : undefined}
              </select>
              {errors.club?.message && (
                <div className={classes.error}>{errors.club.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="問い合わせ内容">{'問い合わせ内容'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <textarea
                id="content"
                type="text"
                {...register('content', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              <div className={classes.helptext}>
                見学や体験稽古のご希望の場合は、ご参加されるにあたっての動機等と併せ、ご希望日をご記入ください。
              </div>
              {errors.content?.message && (
                <div className={classes.error}>{errors.content.message}</div>
              )}
            </div>
          </div>

          <div className={classes.arrow}>
            <ArrowButton path={null} buttonName="SUBMIT" color="#FFF" />
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <div className={classes.rootContainer}>
        <div className={classes.contentTitle}>
          <ContentTitle title={contactAreaTitle} />{' '}
        </div>
        <div className={classes.container}>
          <p>刀禅同好会・稽古会へのお問合せは以下からお願いいたします。</p>
          <div className={classes.links}>
            <span>その他のお問合せ</span>
            <div>
              <div>
                <span>体験稽古・入会について</span>
                <ArrowButton
                  path={various.general.contactForTraining}
                  buttonName="CONTACT"
                  color="#000"
                />
              </div>
              <div>
                <span>その他問い合わせ</span>
                <ArrowButton
                  path={various.general.contact}
                  buttonName="CONTACT"
                  color="#000"
                />
              </div>
            </div>
          </div>
        </div>
        {form()}
      </div>
      <Footer />
    </>
  );
};
export default ContactForClub;
