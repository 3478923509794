import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import { branchDetailForAdmin, memberIndex } from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import various from '../../../General/various';
import Footer from '../../../Footer/footer';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  updateBtn: {},
  helptext: {},
  container: {
    width: '100%',
    marginTop: '64px',
    '& > div': {
      width: '80%',
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      marginBottom: '48px',
      '& > span': {
        // display: 'inline',
        width: '40%',
        fontSize: '0.9rem',
        fontWeight: 'bold',
        textAlign: 'end',
        // verticalAlign: 'middle',
        lineHeight: '40px',
        marginRight: '16px',
      },
      '& > input, & > textarea': {
        width: '60%',
        height: '40px',
        padding: '8px',
        fontFamily: theme.typography.fontFamily,
        border: '0.5px solid grey',
        borderRadius: '0.9em',
        boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
        '&:focus': {
          outline: `1.2px ${theme.palette.primary.main} outset`,
        },
      },
      '& > textarea': {
        minHeight: '160px',
      },
      '& > img': {
        width: '60%',
        objectFit: 'contain',
      },

      '@media screen and (max-width:500px)': {
        width: '100%',
        flexWrap: 'wrap',
        '& > span': {
          width: '100%',
          textAlign: 'start',
        },
        '& > input, & > textarea, & > img': {
          width: '100%',
        },
      },
    },
    '& $helptext': {
      fontSize: '0.8rem',
      textAlign: 'start',
      color: 'grey',
      '& > a': {
        textDecoration: 'underline',
        '&:link': {
          color: theme.palette.primary.main,
        },
      },
    },
    '& $updateBtn': {
      width: '100%',
      marginTop: '80px',
      '& > button': {
        width: '100%',
        display: 'block',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        borderRadius: '0.5em',
        border: 'none',
        padding: '4px 8px',
        backgroundColor: theme.palette.primary.main,
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        color: '#fff',
        cursor: 'pointer',
      },
    },
  },
}));

const BranchDetail = () => {
  const { isAuth } = useContext(AuthContext);
  const [detailData, setDetailData] = useState();
  const [members, setMembers] = useState();
  const [account, setAccount] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '同好会・特別稽古会一覧', path: '/manage/branch_index' },
    { name: '同好会・特別稽古会詳細', path: '/manage/branch_detail' },
  ];

  // Init
  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  useEffect(() => {
    let uuid;
    if (query.get('id')) {
      uuid = query.get('id');
    } else {
      uuid = location.state.uuid;
    }
    if (uuid) {
      branchDetailForAdmin(setDetailData, uuid);
      memberIndex(setMembers);
    }
  }, [location, query]);

  useEffect(() => {
    if (detailData && members) {
      const member = members.filter((member) => {
        return member.email === detailData.user.email;
      })[0];
      if (member) {
        setAccount(member.last_name + member.first_name);
      }
    }
  }, [detailData, members]);

  // Hnadler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleClick = (uuid) => {
    navigate(various.general.branchUpdate, {
      state: { uuid: uuid, previous: location.pathname },
    });
  };

  // Content
  const contentTable = () => {
    if (detailData) {
      return (
        <>
          <div className={classes.container}>
            <div>
              <span>稽古会名称</span>
              <input type="text" value={detailData.branch_name} readOnly />
            </div>
            <div>
              <span>稽古会名称（カナ）</span>
              <input type="text" value={detailData.branch_name_kana} readOnly />
            </div>
            <div>
              <span>稽古会区分</span>
              <input
                type="text"
                value={
                  detailData.branch_is
                    ? '支部'
                    : detailData.club_is
                    ? '同好会'
                    : detailData.special_class_is
                    ? '特別稽古会'
                    : 'その他'
                }
                readOnly
              />
            </div>
            <div>
              <span>エリア</span>
              <input type="text" value={detailData.area_categories} readOnly />
            </div>
            <div className={classes.helptext}>
              <span></span>
              便宜上、現状のUIでは特別稽古会の団体は当該項目に「特別稽古会」と表示します。
            </div>
            <div>
              <span></span>
              <input type="text" value={detailData.activity_area} readOnly />
            </div>
            <div>
              <span>主宰者</span>
              <input type="text" value={detailData.representative} readOnly />
            </div>
            <div>
              <span>主宰者（カナ）</span>
              <input
                type="text"
                value={detailData.representative_kana}
                readOnly
              />
            </div>
            <div>
              <span>専門領域</span>
              <input
                type="text"
                value={detailData.specialized_field}
                readOnly
              />
            </div>
            <div>
              <span>団体Eメール</span>
              <input type="text" value={detailData.email} readOnly />
            </div>
            <div>
              <span>主な稽古日等</span>
              <input
                type="text"
                value={detailData.main_training_days}
                readOnly
              />
            </div>
            <div>
              <span>紹介文</span>
              <textarea
                type="textarea"
                value={detailData.information ? detailData.information : '-'}
                readOnly
              />
            </div>
            <div>
              <span>Webサイト</span>
              <input
                type="text"
                value={detailData.branch_url ? detailData.branch_url : '-'}
                readOnly
              />
            </div>
            <div>
              <span>Instagram</span>
              <input
                type="text"
                value={
                  detailData.instagram_url ? detailData.instagram_url : '-'
                }
                readOnly
              />
            </div>
            <div>
              <span>Twitter</span>
              <input
                type="text"
                value={detailData.twitter_url ? detailData.twitter_url : '-'}
                readOnly
              />
            </div>
            <div>
              <span>Facebook</span>
              <input
                type="text"
                value={detailData.facebook_url ? detailData.facebook_url : '-'}
                readOnly
              />
            </div>
            <div>
              <span>Blog</span>
              <input
                type="text"
                value={detailData.blog_url ? detailData.blog_url : '-'}
                readOnly
              />
            </div>
            <div>
              <span>YouTubu</span>
              <input
                type="text"
                value={detailData.youtube_url ? detailData.youtube_url : '-'}
                readOnly
              />
            </div>
            <div>
              <span>カレンダー</span>
              <input
                type="text"
                value={detailData.calendar_url ? detailData.calendar_url : '-'}
                readOnly
              />
            </div>
            <div>
              <span>イメージ</span>
              <img src={detailData.image} alt="branch_image" />
            </div>
            <div>
              <span>表示順位</span>
              <input
                type="text"
                value={
                  detailData.display_order ? detailData.display_order : '-'
                }
                readOnly
              />
            </div>
            <div>
              <span>活動区分</span>
              <input
                type="text"
                value={detailData.is_useful ? '活動中' : '休止中'}
                readOnly
              />
            </div>
            <div>
              <span>アカウント</span>
              <input
                type="text"
                value={account ? account : 'initial setting'}
                readOnly
              />
            </div>
            <div className={classes.updateBtn}>
              <button onClick={() => handleClick(detailData.uuid)}>更新</button>
            </div>
          </div>
          <Footer />
        </>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>稽古会情報詳細</div>
      <hr />
      <BreadCrumbs crumbs={CRUMBS} />
      {isAuth ? (
        contentTable()
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default BranchDetail;
