import { Badge, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';

// import FadeIn from '../Animation/fadeIn';
import Clock from '../General/clock';
import ContentTitle from '../General/contentTitle';
import various from '../General/various';
import { newsIndex, topicsIndex } from '../Apis/apis';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'start',
    borderBottom: 'solid 1px grey',
    margin: '0 auto',
    marginTop: '64px',
    width: '70%',
    '& > span': {
      display: 'inline-block',
      marginRight: '64px',
      fontSize: '1rem',
    },
    '& > div': {
      fontWeight: 'bold',
      textAlign: 'start',
      '& > span': {
        '& > a': {
          fontSize: '1.1rem',
          '&:visited': {
            color: theme.palette.primary.main,
          },
          '&:link': {
            color: '#000',
          },
        },
        '& > span': {
          top: -10,
          left: 16,
        },
      },
    },
    '@media screen and (max-width:500px)': {
      width: '90%',
      '& > span': {
        marginRight: '16px',
        fontSize: '0.9rem',
      },
    },
  },
  latestNews: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    border: `solid 1px ${theme.palette.primary.main}`,
    marginBottom: '8px',
  },
  topics: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    border: `solid 1px ${theme.palette.primary.main}`,
    marginBottom: '8px',
  },
}));

const TopicsOnTopPage = () => {
  const TIMEZONE = various.timezone;
  // eslint-disable-next-line
  const [badge, setBadge] = useState([]);
  const [news, setNews] = useState();
  const [topics, setTopics] = useState();

  const classes = useStyles();
  const newsAreaTitle = {
    titleList: ['Topics'],
    sub: '最新情報',
  };
  // const fadeProps = {
  //   direction: '$up',
  //   duration: '1s',
  //   fillMode: 'forwards',
  //   timingFunction: 'ease-out',
  // };

  // if (news) console.log('news:', news);
  // if (topics) console.log('topics:', topics);

  useEffect(() => {
    let result = [];
    const NOW = Clock({ timezone: TIMEZONE }).time;
    if (news) {
      news.forEach((el) => {
        if (!el.topics) {
          let dead = Clock({
            timezone: TIMEZONE,
            dead: null,
            string: el.date,
          }).time;
          dead.setDate(dead.getDate() + 7);
          if (dead < NOW) {
            result.push(false);
          } else {
            result.push(true);
          }
        }
      });
    }
    setBadge(result);
  }, [news, TIMEZONE]);

  useEffect(() => {
    newsIndex(setNews);
    topicsIndex(setTopics);
  }, []);

  const newsMapping = () => {
    let result = [];
    if (news) {
      const latestNews = [];
      news.forEach((elm) => {
        if (!elm.topics) {
          latestNews.push(elm);
        }
      });
      latestNews.slice(0, 1).map((elm, index) => {
        return result.push(
          <div key={index} className={classes.contentWrapper}>
            <span>{elm.date}</span>
            <div>
              <Badge
                badgeContent={'New!'}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                classes={{ badge: classes.latestNews }}
                invisible={!badge[index]}
                overlap="rectangular"
              >
                <Link to={`${various.general.news}/${elm.id}`}>
                  <span>{elm.title}</span>
                </Link>
              </Badge>
            </div>
          </div>
        );
      });
    }

    if (result.length > 4) {
      result = result.slice(0, 4);
    }

    if (topics) {
      topics.map((elm, index) => {
        return result.push(
          <div key={`topics_${index}`} className={classes.contentWrapper}>
            <span>{elm.date}</span>
            <div>
              <Badge
                badgeContent={'Topics'}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                classes={{ badge: classes.topics }}
                invisible={false}
                overlap="rectangular"
              >
                <Link to={`${various.general.news}/${elm.id}`}>
                  <span>{elm.title}</span>
                </Link>
              </Badge>
            </div>
          </div>
        );
      });
    }

    return result;
  };

  return (
    <div>
      {/* <FadeIn fadeProps={fadeProps}> */}
      <ContentTitle title={newsAreaTitle} />
      {/* </FadeIn> */}
      <div className={classes.container}>{newsMapping()}</div>
    </div>
  );
};
export default TopicsOnTopPage;
