import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

import OnOffByScroll from '../Animation/onOffByScroll';
import ArrowButton from '../General/arrowButton';
import various from '../General/various';
import { images as imageApi, noMatch } from '../Apis/apis';
import Footer from '../Footer/footer';
import { ogpGenerator } from '../ogp';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'start',
    marginTop: '168px',
    flexWrap: 'wrap-reverse',

    '& > div': {
      width: '45%',
      maxHeight: '800px',
    },

    '@media screen and (max-width:1000px)': {
      '& > div': {
        width: '100%',
        margin: 0,
      },
    },
  },
  image: {
    marginRight: '40px',
    textAlign: 'start',
    '& > img': {
      width: '100%',
      display: 'inline-block',
      objectFit: 'cover',
    },
  },
  text: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'start',
    '& > span': {
      display: 'inline-block',
      fontSize: '5rem',
      textAlign: 'start',
    },
    '& > p': {
      textAlign: 'start',
    },
  },
}));

const NoMatch = () => {
  const [image, setImage] = useState();
  const classes = useStyles();

  const location = useLocation();

  // OGP設定
  const ogp = useCallback((props) => {
    ogpGenerator(props);
  }, []);

  useLayoutEffect(() => {
    if (image) {
      const ogpProps = {
        page: 'nomatch',
        pageTitle: '刀禪OfficialWebSite404ページ',
        path: location.pathname,
        imageUrl: image.OfficialImage_TouzenLogoRed.url,
      };
      ogp(ogpProps);
    }
  }, [image, location, ogp]);
  // OGP設定ここまで

  useEffect(() => {
    imageApi(setImage);
    noMatch();
  }, []);

  useEffect(() => {
    imageApi(setImage);
  }, []);
  const contents = () => {
    if (image) {
      return (
        <>
          <div>
            <OnOffByScroll place="General" />
          </div>
          <div className={classes.container}>
            {/* <div className={classes.image}>
              <img src={image.OfficialImage_TouzenLogo.url} alt="noMatch" />
            </div> */}
            <div className={classes.text}>
              <span>404.</span>
              <p>お探しのページは見つかりませんでした。</p>
            </div>
          </div>
          <div>
            <ArrowButton
              path={various.general.home}
              buttonName="BACK HOME"
              color="#000"
            />
          </div>
          <Footer />
        </>
      );
    }
  };
  return contents();
};

export default NoMatch;
