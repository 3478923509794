import React from 'react';

import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core';

import { MainTheme } from './Theme/theme';
import AuthContextProvider from './auth';
import RoutingComponents from './RoutingComponets';
import { LoadingProvider } from './Spinner';

function App() {
  const theme = MainTheme();

  return (
    <>
      <LoadingProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthContextProvider>
            <RoutingComponents />
          </AuthContextProvider>
        </ThemeProvider>
      </LoadingProvider>
    </>
  );
}

export default App;
