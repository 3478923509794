import ContentTitle from '../General/contentTitle';
import ArrowButton from '../General/arrowButton';
import various from '../General/various';
import FadeIn from '../Animation/fadeIn';
// import ScrollTriggers from '../Animation/scrollTriggers';

import { makeStyles } from '@material-ui/core';
import { branchNameIndex } from '../Apis/apis';
import { useCallback, useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  box: {},
  clubIndex: {
    textAlign: 'end',
    fontWeight: 'bold',
    letterSpacing: '0.5rem',
    marginBottom: '16px',
    lineHeight: 3,
    fontSize: '1.5rem',
    '@media screen and (max-width:500px)': {
      fontSize: '1.1rem !important',
    },
  },
  clubArea: {
    color: theme.palette.primary.main,
  },
  link: {
    '&:link': {
      color: '#000',
    },
    '&:visited': {
      color: '#000',
    },
    '&:hover': {
      fontWeight: 900,
    },
  },
  arrow: {
    float: 'right',
  },
}));

const BranchOnTopPage = () => {
  const [raw, setRaw] = useState();
  const [branches, setBranches] = useState({});
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState();
  const [clubs, setClubs] = useState();
  const [specials, setSpecials] = useState();
  // const [clubLength, setClubLength] = useState();

  const classes = useStyles();
  const branchAreaTitle = {
    titleList: ['Branch'],
    sub: '同好会・特別稽古会',
  };
  const fadeProps = {
    direction: '$up',
    duration: '1s',
    fillMode: 'forwards',
    timingFunction: 'ease-out',
  };

  useEffect(() => {
    branchNameIndex(setRaw);
  }, []);

  useEffect(() => {
    // このコンポーネントのクソみたいなコードに適応させるために、
    // 整理したエンドポイントからのDataを以下の通り変換する。
    if (raw) {
      const ids = [];
      const clubNames = [];
      const specialClubNames = [];

      raw.forEach((branch) => {
        if (branch.club) {
          ids.push(branch.uuid);
          clubNames.push(branch.club);
        } else if (branch.specialClub) {
          specialClubNames.push({
            id: branch.uuid,
            colorText: branch.specialClub.colorText,
            normalText: branch.specialClub.normalText,
          });
        }
      });
      setBranches({ id: ids, club: clubNames, specialClub: specialClubNames });
    }
  }, [raw]);

  const callback = useCallback(() => {
    function clubs() {
      const clubList = [];
      const specialClubList = [];
      Object.keys(branches).forEach((key) => {
        if (key === 'club') {
          branches[key].map((club, index) => {
            return clubList.push(
              <HashLink
                key={index}
                className={classes.link}
                to={`${various.general.branch}/#${branches['id'][index]}`}
                style={{ display: 'inline-block' }}
              >
                <span style={{ color: '#000' }}>刀禅</span>
                <span className={classes.clubArea}>{club}</span>
                <span style={{ color: '#000' }}>同好会</span>
              </HashLink>
            );
          });
        } else if (key === 'specialClub') {
          branches[key].map((specialClub, i) => {
            return specialClubList.push(
              <HashLink
                key={i}
                className={classes.link}
                to={`${various.general.branch}/#${specialClub.id}`}
                style={{ display: 'inline-block' }}
              >
                <span className={classes.clubArea}>
                  {specialClub.colorText}
                </span>
                <span style={{ color: '#000' }}>{specialClub.normalText}</span>
              </HashLink>
            );
          });
        }
      });
      return { club: clubList, specialClub: specialClubList };
    }

    // const baseTimeout = 400;
    // const interval = 48;
    // let timeout = baseTimeout;
    // const compProps = {
    //   derection: 'right',
    // };

    const clubResult = [];
    const specialClubResult = [];

    clubs().club.map((club, index) => {
      // timeout += interval;
      return clubResult.push(
        <div key={index}>
          {/* <ScrollTriggers
            component="Slide"
            compProps={compProps}
            timeout={timeout}
          > */}
          {club}
          {/* </ScrollTriggers> */}
        </div>
      );
    });
    clubs().specialClub.map((club, i) => {
      if (i === 0) {
        // timeout = interval * clubLength + baseTimeout;
      }
      // timeout += interval;
      return specialClubResult.push(
        <div key={i}>
          {/* <ScrollTriggers
            component="Slide"
            compProps={compProps}
            timeout={timeout}
          > */}
          {club}
          {/* </ScrollTriggers> */}
        </div>
      );
    });
    return { club: clubResult, specialClub: specialClubResult };
  }, [branches, classes]);

  useEffect(() => {
    if (branches) {
      setView(true);
      setLoading(false);
    }
  }, [branches]);

  useEffect(() => {
    if (view) {
      setClubs(callback().club);
      setSpecials(callback().specialClub);
    }
  }, [view, callback]);

  // useEffect(() => {
  //   if (Object.keys(branches).length) {
  //     if (branches.club) {
  //       setClubLength(branches.club.length);
  //     }
  //   }
  // }, [branches]);

  return (
    <div className={classes.box}>
      {loading ? null : view ? (
        <>
          {/* <FadeIn fadeProps={fadeProps}> */}
          <ContentTitle title={branchAreaTitle} />
          {/* </FadeIn> */}
          <div className={classes.clubIndex}>
            {clubs}
            {specials}
          </div>
          <FadeIn fadeProps={fadeProps}>
            <div className={classes.arrow}>
              <Link to={various.general.branch}>
                <ArrowButton buttonName="VIEW ALL" color="#000" />
              </Link>
            </div>
          </FadeIn>
        </>
      ) : null}
    </div>
  );
};
export default BranchOnTopPage;
