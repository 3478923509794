import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DataGrid, jaJP } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/core';

import OnOffByScroll from '../Animation/onOffByScroll';
import { AuthContext } from '../auth';
import various from '../General/various';
import { Box } from '@mui/material';
import { auth_test } from '../Apis/apiOfManaged';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  dataGrid: {
    height: (props) =>
      props.heightSize ? props.heightSize : props.pageSize * props.rowHeight,
    width: '100%',
    '& .MuiDataGrid-main': {
      fontSize: '1rem',
    },
  },
  createBtn: {
    marginBottom: '168px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',
    '& > div': {
      margin: 'auto 0',
      '& > button': {
        display: 'block',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        borderRadius: '0.5em',
        border: 'none',
        padding: '4px 8px',
        backgroundColor: theme.palette.primary.main,
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        color: '#fff',
      },
    },
  },
  updateBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
    },
  },
  detailBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: theme.palette.primary.main,
    },
  },
}));

const AuthTest = () => {
  const { isAuth } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [heightSize, setHeightSize] = useState();

  const ROWHEIGHT = 80;
  const PAGESIZE = 30;
  const classes = useStyles({
    pageSize: PAGESIZE,
    rowHeight: ROWHEIGHT,
    heightSize: heightSize,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  useEffect(() => {
    auth_test(setData);
  }, []);

  const handleClick = (flag, uuid) => {
    if (flag === 'update') {
      navigate(various.general.update, {
        state: { uuid: uuid, previous: location.pathname },
      });
    } else if (flag === 'create') {
      navigate(various.general.create, {
        state: { previous: location.pathname },
      });
    } else if (flag === 'detail') {
      navigate(various.general.detail, {
        state: { uuid: uuid, previous: location.pathname },
      });
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      const keys = Object.keys(data[0]);
      const dataset = () => {
        const result = [
          {
            field: 'id',
            headerName: 'ID',
            width: 90,
          },
        ];
        keys.forEach((key) => {
          switch (key) {
            case 'uuid':
              return result.push({
                field: 'uuid',
              });
            case 'title':
              return result.push({
                field: 'title',
                headerName: 'TITLE',
                width: 150,
              });
            case 'number':
              return result.push({
                field: 'number',
                headerName: 'NUMBER',
                type: 'number',
                width: 100,
              });
            case 'bool':
              return result.push({
                field: 'bool',
                headerName: 'BOOLEAN',
                type: 'boolean',
                width: 90,
              });
            case 'char':
              return result.push({
                field: 'char',
                headerName: 'CHAR',
                width: 150,
              });
            case 'datetime':
              return result.push({
                field: 'datetime',
                headerName: 'DATATIME',
                width: 120,
              });
            case 'text':
              return result.push({
                field: 'text',
                headerName: 'TEXT',
                width: 150,
              });
            case 'image':
              return result.push({
                field: 'image',
                headerName: 'IMAGE',
                cellClassName: 'image-cell',
                width: 150,
                renderCell: (params) => (
                  <img src={params.row.image} alt={`image_${params.id}`} />
                ),
              });
            case 'created_at':
              return result.push({
                field: 'created_at',
                headerName: '登録日',
                width: 150,
              });
            default:
          }
        });
        return result;
      };
      const operationBtn = [
        {
          field: 'detailBtn',
          headerName: '詳細',
          sortable: false,
          width: 90,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <div className={classes.detailBtn}>
              <button onClick={() => handleClick('detail', params.row.uuid)}>
                詳細
              </button>
            </div>
          ),
        },
        {
          field: 'updateBtn',
          headerName: '更新',
          sortable: false,
          width: 90,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <div className={classes.updateBtn}>
              <button onClick={() => handleClick('update', params.row.uuid)}>
                更新
              </button>
            </div>
          ),
        },
      ];
      const columnsDataset = dataset().concat(operationBtn);
      setColumns(columnsDataset);

      setRows(
        data.map((d, index) => {
          const result = {};
          result['id'] = index + 1;
          Object.keys(d).forEach((k) => {
            if (k === 'datetime') {
              const date = new Date(d[k]);
              result[k] = date.toLocaleDateString();
            } else if (k === 'created_at') {
              const date = new Date(d[k]);
              result[k] = date.toLocaleString();
            } else {
              result[k] = d[k];
            }
          });
          return result;
        })
      );
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (data) {
      setHeightSize(data.length * ROWHEIGHT * 1.4 + 122);
    }
  }, [data]);

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {isAuth ? (
        <>
          <div className={classes.pageTitle}>AuthTest一覧</div>
          <hr />
          <div className={classes.createBtn}>
            <span>新規追加&nbsp;:&nbsp;</span>
            <div>
              <button onClick={() => handleClick('create')}>登録</button>
            </div>
          </div>

          <Box
            className={classes.dataGrid}
            sx={{
              '& .image-cell': {
                '& > img': {
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                },
              },
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={PAGESIZE}
              rowsPerPageOptions={[PAGESIZE]}
              // checkboxSelection
              density="comfortable"
              columnVisibilityModel={{ uuid: false }}
              pagination={true}
              autoPageSize={true}
              rowHeight={ROWHEIGHT}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        </>
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default AuthTest;
