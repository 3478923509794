import { makeStyles } from '@material-ui/core';
import {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import ContentTitle from '../General/contentTitle';
import various from '../General/various';
import imageIndex from '../img/imageIndex';
import { newsDetail } from '../Apis/apis';
import Pagination from '../General/pagiNation';
import Footer from '../Footer/footer';
import { ogpGenerator } from '../ogp';
import { AuthContext } from '../auth';

const useStyles = makeStyles((theme) => ({
  contentTitle: {
    marginTop: '168px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'baseline',
    '& > div': {
      marginBottom: '64px',
      lineHeight: '72px',
      '& > span': {
        '& > span': {
          '& > span': {
            lineHeight: '64px !important',
            textAlign: 'justify',
          },
        },
      },
    },
  },
  dateHash: {
    width: '100%',
    textAlign: 'start',
    fontWeight: 'bold',
    display: 'flex',
    flexFlow: 'column',
    gap: '16px 16px',
    marginBottom: '80px',
    borderBottom: 'solid 0.1px grey',
    '& > span': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '0px 16px',
      fontSize: '1rem',
      fontStyle: 'italic',
      lineHeight: '24px',
      marginBottom: '32px',
    },
    '& #share': {
      width: '100%',
      display: 'flex',
      justifyContent: 'end',
      '& > button': {
        display: 'block',
        width: '56px',
        '& > img': {
          color: '#000',
          minWidth: '16px',
        },
      },
    },
  },
  content: {
    // display: 'flex',
  },
  contentImage: {
    width: 'calc(70vw * 0.35)',
    marginRight: '24px',
    float: 'left',
    '& > a': {
      '& > img': {
        width: 'calc(70vw * 0.3)',
        height: 'calc(70vw * 0.3)',
        objectFit: 'contain',
        // filter:
        //   'contrast(100%) grayscale(100%) saturate(0%) drop-shadow(2px 2px 2px black)',
        border: 'solid 0.1px grey',
      },
    },
  },
  contentText: {
    width: '100%',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    lineHeight: '28px',
    fontSize: '1rem',
    textAlign: 'start',
    '& > span': {
      '& > a': {
        display: 'inline-block',
        '&:link': {
          color: '#000',
        },
        '&:visited': {
          color: '#000',
        },
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  authText: {
    fontSize: '1rem',
    textAlign: 'start',
    '& > span': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  },
}));

const NewsDetail = () => {
  const { isAuth } = useContext(AuthContext);
  const { contentElements } = useOutletContext();
  const ONE_PAGE_DISPLAY_CONTENTS = 1;
  const protocol = window.location.protocol;
  const domain = document.domain;

  const [page, setPage] = useState(0);
  const [forcePage, setForcePage] = useState(0);
  const [detail, setDetail] = useState();
  const [ids, setIds] = useState();
  const [currentId, setCurrentId] = useState();

  const element = useRef([]);
  contentElements.forEach((_, i) => {
    element.current[i] = createRef();
  });

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const cls = useStyles();

  const ogp = useCallback((props) => {
    ogpGenerator(props);
  }, []);

  useLayoutEffect(() => {
    if (detail) {
      const ogpProps = {
        page: 'news_detail',
        pageTitle: detail.title,
        path: location.pathname,
        imageUrl: detail.image,
      };
      ogp(ogpProps);
    }
  }, [detail, location, ogp]);

  useEffect(() => {
    newsDetail(setDetail, params.newsId);
  }, [params]);

  useEffect(() => {
    setForcePage(currentId);
  }, [currentId]);

  useEffect(() => {
    const id = contentElements.map((obj, index) => {
      if (obj.id === params.newsId) {
        setCurrentId(index);
      }
      return obj.id;
    });
    setIds(id);
  }, [contentElements, params]);

  const handleAuth = () => {
    navigate(various.general.login, {
      state: { previous: location.pathname },
    });
  };

  const contentMapping = () => {
    const linkedContent = (text) => {
      // 参考：https://omkz.net/text-url-link/
      const regExp = /(https?:\/\/[\w/:%#$&?()~.=+-]+)/g;
      return text.replace(regExp, '<a href="$1">$1</a>');
    };
    let result = [];
    contentElements.map((elm, index) => {
      result.push(
        <div key={index}>
          <div className={cls.contentTitle}>
            <ContentTitle title={{ titleList: [elm.title], sub: null }} />
          </div>
          <div className={cls.dateHash}>
            <div id="share">
              <FacebookShareButton
                url={
                  protocol + '//' + domain + various.general.news + '/' + elm.id
                }
                title={elm.title}
              >
                <img src={imageIndex.newsPage.detail.fbIcon} alt="fb" />
              </FacebookShareButton>
              <TwitterShareButton
                url={
                  protocol + '//' + domain + various.general.news + '/' + elm.id
                }
                title={elm.title}
                via="TOUZEN_"
                related={['TOUZEN_']}
                hashtags={elm.hash.map((h) => h.slice(1))}
              >
                <img src={imageIndex.newsPage.detail.twIcon} alt="tw" />
              </TwitterShareButton>
            </div>
            <div>{elm.date}</div>
            <span>
              {elm.hash.map((h, i) => (
                <span key={i}>{h}</span>
              ))}
            </span>
          </div>
          {elm.isMembersOnly && !isAuth ? (
            <div className={cls.authText}>
              <span onClick={handleAuth}>こちら</span>
              からログインしてください。
            </div>
          ) : (
            <div className={cls.content}>
              <div
                ref={element.current[index]}
                id={`target_nd_${index}`}
                className={cls.contentImage}
              >
                <Link to={`${various.general.news}/${elm.id}`}>
                  <img src={elm.image} alt="contentImage" />
                </Link>
              </div>
              <div className={cls.contentText}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: linkedContent(elm.contentText),
                  }}
                />
              </div>
            </div>
          )}
        </div>
      );
      return null;
    });
    return result;
  };

  return (
    <>
      {contentMapping()[page]}
      <Pagination
        contentsSize={contentElements.length}
        perPage={ONE_PAGE_DISPLAY_CONTENTS}
        setCurrentPageNumber={setPage}
        setForcePageNumber={setForcePage}
        forcePage={forcePage}
        ids={{ news: ids }}
      />
      <Footer />
    </>
  );
};
export default NewsDetail;
