import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';

import OnOffByScroll from '../../../Animation/onOffByScroll';
import { AuthContext } from '../../../auth';
import { imageDetail } from '../../../Apis/apiOfManaged';
import { updateInstance } from '../../../Apis/apiHandlers';
import various from '../../../General/various';
import { fileSizeObserver } from '../../../CommonFunction/imageManagement';
import Footer from '../../../Footer/footer';
import { localStorageArrayHandler } from '../../../CommonFunction/managePermission';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
  },
  image: {
    margin: '0 auto',
    width: '50%',
    marginBottom: '80px',
    '& > img': {
      width: '100%',
      objectFit: 'contain',
      maxHeight: '500px',
    },
  },
  titles: {},
  inputs: {},
  helptext: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  container: {
    marginTop: '80px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media screen and (max-width:800px)': {
      '& > div': {
        width: '100%',
      },
    },
    '& > form': {
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '& > img': {
            maxWidth: '300px',
            maxHeight: '300px',
            objectFit: 'contain',
          },
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > label': {
            width: '100%',
            textAlign: 'end',
            fontSize: '1rem',
            '@media screen and (max-width:750px)': {
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
          '& > span': {
            color: 'red',
          },
        },

        '& $inputs': {
          width: '70%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input, & > textarea, & > select': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
          '& > textarea': {
            minHeight: '160px',
          },
          '& > input[type="checkbox"]': {
            boxShadow: 'none',
          },
          '& $helptext': {
            fontSize: '0.8rem',
            color: 'grey',
            '& > a': {
              textDecoration: 'underline',
              '&:link': {
                color: theme.palette.primary.main,
              },
            },
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    marginTop: '88px !important',
    justifyContent: 'end',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const ImageUpdatePush = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const { isAuth } = useContext(AuthContext);
  const permissions = localStorageArrayHandler('parse', various.permissionKey);

  const [image, setImage] = useState([]);
  const [date, setDate] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const LIMITSIZE = 5;

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: 'イメージ一覧', path: '/manage/image_index_for_admin' },
    { name: '静的イメージ一覧', path: '/manage/image_updater' },
    { name: '画像・イメージの更新', path: '/manage/updater_push' },
  ];

  // Init
  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  useEffect(() => {
    if (image) {
      reset({
        title: image.title,
        use_place: image.use_place,
        description: image.description,
      });
    }
  }, [image, reset]);

  useEffect(() => {
    imageDetail(setImage, location.state.uuid);
  }, [location]);

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  // Submit
  const onSubmit = handleSubmit(async (data) => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    if (environment === 'development') {
      data['title'] = location.state.title + '_dev';
    } else if (environment === 'staging') {
      data['title'] = location.state.title + '_staging';
    } else if (environment === 'production') {
      data['title'] = location.state.title;
    }
    data['uuid'] = location.state.uuid;
    const imageFileKey = 'content';

    try {
      await updateInstance('Images', data, imageFileKey);
      console.log('update finish!');
      navigate(location.state.previous);
    } catch (error) {
      console.error('Error in updateInstance:', error);
    }
  });

  // Contents
  const form = () => {
    return (
      <>
        <form method="POST" encType="multipart/form-data" onSubmit={onSubmit}>
          <div>
            <div className={classes.titles}>
              <label htmlFor="イメージタイトル">イメージタイトル</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="title"
                type="text"
                {...register('title', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.title?.message && (
                <div className={classes.error}>{errors.title.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="使用箇所">使用箇所</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="use_place"
                type="text"
                disabled={true}
                {...register('use_place', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              <div className={classes.helptext}>変更できません。</div>
              {errors.use_place?.message && (
                <div className={classes.error}>{errors.use_place.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="イメージの説明">イメージの説明</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <textarea
                id="description"
                type="textarea"
                {...register('description', {
                  required: { value: true, message: '入力必須の項目です' },
                  maxLength: {
                    value: 150,
                    message: '150字以内でお願いします',
                  },
                })}
              />
              <div className={classes.helptext}>
                150文字以内で記述してください。
              </div>
              {errors.description?.message && (
                <div className={classes.error}>
                  {errors.description.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="content">{'差し替え画像アップロード'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="content"
                type="file"
                accept="image/*,.pdf"
                {...register('content', {
                  required: { value: false, message: '入力必須の項目です' },
                  validate: {
                    message: (v) =>
                      fileSizeObserver(v, LIMITSIZE)
                        ? null
                        : `イメージファイルは${LIMITSIZE}MB以下にしてください`,
                  },
                })}
              />
              {errors.content?.message && (
                <div className={classes.error}>{errors.content.message}</div>
              )}
            </div>
          </div>
          <div className={classes.button}>
            <button type="submit">更新</button>
          </div>
        </form>
      </>
    );
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {!isAuth ? (
        <>
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleAuth}
            >
              ログイン
            </span>
            してください。
          </div>
        </>
      ) : permissions.includes('admin') ? (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">画像・イメージの更新</span>
              <span>{date}</span>
            </div>
          </div>
          <hr />
          <BreadCrumbs crumbs={CRUMBS} />
          <div className={classes.container}>
            <div className={classes.image}>
              <p style={{ textAlign: 'start', fontSize: '0.8rem' }}>
                ※現在の画像
              </p>
              {image ? (
                <img src={image.content} alt="detailimage" />
              ) : undefined}
            </div>
            {form()}
          </div>
          <Footer />
        </>
      ) : permissions === 'null' ? (
        setTimeout(() => {
          window.location.reload(true);
        }, 500)
      ) : (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">画像・イメージの更新</span>
              <span>{date}</span>
            </div>
          </div>
          <hr />
          <div className={classes.container}>権限がありません</div>
        </>
      )}
      <Footer />
    </>
  );
};
export default ImageUpdatePush;
