import { makeStyles } from '@material-ui/core';
import React, { useState, useRef, useLayoutEffect } from 'react';

// 本コンポーネントは以下のstackoverflowをほぼ完全に参考にした。曖昧な理解なので記録しておく。
// https://stackoverflow.com/questions/59595700/how-to-make-a-react-component-fade-in-on-scroll-using-intersectionobserver-but

// どうやらMUIv4だと、makeStyles内でpropsと@keyframesを同居させられないバグがある模様。クソ。後でどうにかする。
// https://stackoverflow.com/questions/64234091/passing-props-in-makestyles-keyframe-animations

const useStyles = makeStyles((theme) => ({
  // fadeUp: (props) => ({
  //   animationName: props.direction,
  //   animationDuration: props.duration,
  //   animationFillMode: props.fillMode,
  //   animationTimingFunction:props.timingFunction,
  //   opacity: 0,
  // }),
  fadeUp: {
    animationName: '$up',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-out',
    opacity: 0,
  },
  fadeDown: {
    animationName: '$down',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-out',
    opacity: 0,
  },
  fadeLeft: {
    animationName: '$left',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-out',
    opacity: 0,
  },
  fadeRight: {
    animationName: '$right',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-out',
    opacity: 0,
  },
  '@keyframes up': {
    '0%': { opacity: 0, transform: 'translateY(100px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  },
  '@keyframes down': {
    '0%': { opacity: 0, transform: 'translateY(100px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  },
  '@keyframes left': {
    '0%': { opacity: 0, transform: 'translateY(100px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  },
  '@keyframes right': {
    '0%': { opacity: 0, transform: 'translateY(100px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  },
}));

const FadeIn = (props) => {
  const classes = useStyles(props.fadeProps);
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();

  const fixClass = (obj) => {
    switch (obj.direction) {
      case '$up':
        return classes.fadeUp;
      case '$down':
        return classes.fadeDown;
      case '$left':
        return classes.fadeLeft;
      case '$right':
        return classes.fadeRight;
      default:
    }
  };

  useLayoutEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible(true);
          observer.unobserve(domRef.current);
        }
      });
    });
    observer.observe(domRef.current);
    return () => observer.disconnect();
  }, []);

  return (
    <div ref={domRef} className={isVisible ? fixClass(props.fadeProps) : ''}>
      {props.children}
    </div>
  );
};
export default FadeIn;
