import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DataGrid, jaJP } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/core';
import { Box } from '@mui/material';

import various from '../../../General/various';
import imageIndex from '../../../img/imageIndex';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import { AuthContext } from '../../../auth';
import { memberIndex, passwordResetForAdmin } from '../../../Apis/apiOfManaged';
import Footer from '../../../Footer/footer';
import { Spinner } from '../../../Spinner';
import BreadCrumbs from '../../../General/breadCrumbs';
import StandardDialog from '../../../Dialog/standardDialog';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
  },
  dataGrid: {
    height: (props) =>
      props.heightSize ? props.heightSize : props.pageSize * props.rowHeight,
    width: '100%',
    '& .MuiDataGrid-main': {
      fontSize: '0.9rem',
      fontFamily: theme.typography.fontFamily,
    },
  },
  createBtn1: {},
  createBtn2: {},
  createbtn1: {},
  createbtn2: {},
  createBtnContainer: {
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',
    '@media screen and (max-width:600px)': {
      flexWrap: 'wrap',
      gap: '0px 16px',
    },
    '& > div': {
      margin: 'auto 0',
      '& > span': {
        display: 'inline-block',
      },
      '& > button': {
        display: 'inline-block',
        fontFamily: theme.typography.fontFamily,
        fontSize: '0.9rem',
        fontWeight: 'bold',
        borderRadius: '0.5em',
        border: 'none',
        padding: '4px 8px',
        height: '30px',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        cursor: 'pointer',
        '@media screen and (max-width:600px)': {
          display: 'block',
          width: '114px',
          overflow: 'hidden',
        },
      },
      '& $createbtn1': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
      '& $createbtn2': {
        backgroundColor: 'rgba(124,42,46,0.8)',
        color: '#fff',
      },
    },
    '& $createBtn1': {
      width: '20%',
      fontSize: '0.9rem',
      textAlign: 'end',
      '@media screen and (max-width:600px)': {
        width: '114px',
        textAlign: 'start',
      },
    },
    '& $createBtn2': {
      width: '80%',
      display: 'flex',
      gap: '0px 16px',
      textAlign: 'start',
      '@media screen and (max-width:600px)': {
        width: '100%',
      },
    },
  },
  viewDataBtnContainer: {
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',
    '@media screen and (max-width:600px)': {
      flexWrap: 'wrap',
      gap: '0px 16px',
    },
    '& > div': {
      margin: 'auto 0',
      '& > span': {
        display: 'inline-block',
      },
      '& > button': {
        display: 'inline-block',
        fontFamily: theme.typography.fontFamily,
        fontSize: '0.9rem',
        fontWeight: 'bold',
        borderRadius: '0.5em',
        border: 'none',
        padding: '0px 8px',
        height: '30px',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        cursor: 'pointer',
        '@media screen and (max-width:600px)': {
          display: 'block',
          width: '114px',
          overflow: 'hidden',
        },
      },
      '& #btn1': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
      '& #btn2, & #btn5': {
        backgroundColor: 'rgba(124,42,46,0.8)',
        color: '#fff',
      },
      '& #btn4': {
        backgroundColor: 'rgba(124,42,46,0.5)',
        color: '#000',
      },
      '& #btn3': {
        backgroundColor: 'rgba(124,42,46,0.3)',
        color: '#000',
      },
    },
    '& #viewBtn1': {
      width: '20%',
      fontSize: '0.9rem',
      textAlign: 'end',
      '@media screen and (max-width:600px)': {
        width: '114px',
        textAlign: 'start',
      },
    },
    '& #viewBtn2': {
      width: '80%',
      display: 'flex',
      gap: '0px 16px',
      textAlign: 'start',
      '@media screen and (max-width:600px)': {
        width: '100%',
      },
    },
  },
  downLoadBtnContainer: {
    marginBottom: '80px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',

    '@media screen and (max-width:600px)': {
      marginBottom: '0px',
    },
    '& > div': {
      width: '20%',
      margin: 'auto 0',
      '& > span': {
        display: 'inline-block',
      },
      '& > button': {
        display: 'inline-block',
        border: 'none',
        padding: '4px 8px',
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer',
      },
    },
    '& #dlBtn1': {
      fontSize: '0.9rem',
      textAlign: 'end',
      '@media screen and (max-width:600px)': {
        width: '114px',
      },
    },
    '& #dlBtn2': {
      textAlign: 'start',
    },
  },
  updateBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
  detailBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  createMemberBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
}));

const MemberIndex = () => {
  const { isAuth } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [heightSize, setHeightSize] = useState();
  const [date, setDate] = useState();
  const [memberNum, setMemberNum] = useState();
  const [nonMemberNum, setNonMemberNum] = useState();
  const [loading, setLoading] = useState(true);
  const [resetDialog, setResetDialog] = useState(false);
  const [resetPasswordUser, setResetPasswordUser] = useState('');
  const [resetPasswordCompleted, setResetPasswordCompleted] = useState(false);

  const ROWHEIGHT = 80;
  const PAGESIZE = 30;
  const classes = useStyles({
    pageSize: PAGESIZE,
    rowHeight: ROWHEIGHT,
    heightSize: heightSize,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '会員一覧', path: '/member_index' },
  ];

  // Init
  useEffect(() => {
    if (isAuth) {
      memberIndex(setData);
    }
  }, [isAuth]);

  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const members = data.map((d) => {
        return d.is_member;
      });
      setMemberNum(members.filter(Boolean).length);
      setNonMemberNum(data.length - members.filter(Boolean).length);
    }
  }, [data]);

  useEffect(() => {
    setViewData(data);
  }, [data]);

  useEffect(() => {
    if (viewData) {
      const size = viewData.length < PAGESIZE ? viewData.length : PAGESIZE;
      setHeightSize(size * ROWHEIGHT * 1.4 + 122);
    }
  }, [viewData]);

  useEffect(() => {
    if (viewData) {
      const headers = [
        {
          field: 'id',
          headerName: 'ID',
          width: 90,
        },
        {
          field: 'uuid',
        },
        {
          field: 'is_member',
        },
        {
          field: 'is_training_group_member',
        },
        {
          field: 'name',
          headerName: '氏名',
          width: 150,
        },
        {
          field: 'email',
          headerName: 'Eメール',
          width: 150,
        },
        {
          field: 'kinds',
          headerName: '会員種別',
          width: 100,
          cellClassName: 'kinds',
        },
        {
          field: 'position',
          headerName: '役',
          width: 100,
          cellClassName: 'position',
        },
        {
          field: 'status',
          headerName: 'Status',
          width: 100,
          cellClassName: 'status',
          //   sortable: false,
          //   disableClickEventBubbling: true,
          //   renderCell: (params) => {
          //     return (
          //       <ul>
          //         {params.row.status.map((obj, index) => {
          //           return (
          //             <li key={index} style={{ listStyleType: 'none' }}>
          //               {/* <img src={obj.icon} alt="icon" /> */}
          //               <span>{obj.text}</span>
          //             </li>
          //           );
          //         })}
          //       </ul>
          //     );
          //   },
        },
        {
          field: 'joinDate',
          headerName: '入会日',
          width: 150,
        },
        {
          field: 'his',
          headerName: '刀禅歴',
          width: 150,
        },
        {
          field: 'area',
          headerName: 'エリア',
          width: 150,
        },
      ];

      const operationBtn = [
        {
          field: 'detailBtn',
          headerName: '詳細',
          sortable: false,
          width: 90,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <div className={classes.detailBtn}>
              <button onClick={() => handleClick('detail', params.row.uuid)}>
                詳細
              </button>
            </div>
          ),
        },
        {
          field: 'updateBtn',
          headerName: '更新',
          sortable: false,
          width: 90,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <div className={classes.updateBtn}>
              <button onClick={() => handleClick('update', params.row.uuid)}>
                更新
              </button>
            </div>
          ),
        },
        {
          field: 'createMemberBtn',
          headerName: '登録',
          sortable: false,
          width: 90,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <div className={classes.createMemberBtn}>
              {params.row.is_member || params.row.is_training_group_member ? (
                <span>-</span>
              ) : (
                <button onClick={() => handleClick('himself', params.row.uuid)}>
                  登録
                </button>
              )}
            </div>
          ),
        },
        {
          field: 'resetPasswordBtn',
          headerName: 'PassWord更新',
          sortable: false,
          width: 160,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <div className={classes.resetPassword}>
              <button
                onClick={() => handleResetPasswordOpen(params.row.uuid)}
                style={{ cursor: 'pointer' }}
              >
                更新
              </button>
            </div>
          ),
        },
      ];
      const columnsDataset = headers.concat(operationBtn);
      setColumns(columnsDataset);

      setRows(
        viewData.map((d, index) => {
          const result = {};
          result['id'] = index + 1;
          result['uuid'] = d['uuid'];
          result['is_member'] = d['is_member'];
          result['is_training_group_member'] = d['is_training_group_member'];

          if (!d['last_name']) {
            result['name'] = '-';
          } else {
            result['name'] = d['last_name'] + ' ' + d['first_name'];
          }

          result['email'] = d['email'];

          if (d['date_of_join']) {
            const today = new Date();
            const date = new Date(d['date_of_join']);
            result['joinDate'] = date.toLocaleDateString();
            const time = today.getTime() - date.getTime();
            const years = Math.floor(time / (86400000 * 365));
            const month = Math.floor((time / (86400000 * 365) - years) * 12);
            result['his'] = String(years) + '年' + String(month) + 'ヶ月';
          } else {
            result['his'] = '-';
          }

          result['area'] = d['address1'];

          if (d['roushi_is']) {
            // kinds
            result['kinds'] = '老師';
            // position
            result['position'] = '-';
            // status
            result['status'] = '-';
            // dateOfJoin
            result['joinDate'] = '-';
            // history
            result['his'] = '-';
            // area
            result['area'] = '-';
          } else {
            if (d['is_member'] || d['is_training_group_member']) {
              // kinds
              if (d['member_of_kosan']) {
                result['kinds'] = '古参';
              } else if (d['member_of_honor']) {
                result['kinds'] = '名誉会員';
              } else {
                if (d['is_member']) {
                  result['kinds'] = various.memberDesignations;
                } else if (d['is_training_group_member']) {
                  result['kinds'] = various.otherMemberDesignations;
                }
              }

              // position
              if (d['member_of_club_manager_is']) {
                result['position'] = '会主宰者';
                if (d['member_of_instructor']) {
                  result['position'] += ' / 指導者';
                  if (d['member_of_admin']) {
                    result['position'] += ' / 運営担当';
                  }
                }
              } else if (d['member_of_instructor']) {
                result['position'] = '指導者';
                if (d['member_of_admin']) {
                  result['position'] += ' / 運営担当';
                }
              } else if (d['member_of_admin']) {
                result['position'] = '運営担当';
              }

              // status
              if (!d['active_is']) {
                result['status'] = '休会中';
              }
              // else if (!d['recieved_is'] && !d['admission_fee_exemption']) {
              //   result['status'] = '入会金未払';
              // }
              else {
                result['status'] = '継続活動中';
              }
            } else {
              if (d['member_of_hamon']) {
                result['status'] = '破門';
              }
              // else if (d['is_training_group_member']) {
              //   result['kinds'] = various.otherMemberDesignations;
              // }
              else if (
                d['experience_training_participation_is'] ||
                d['seminar_participation_is']
              ) {
                result['kinds'] = '稽古会参加者';
              } else {
                result['kinds'] = various.nonMemberDesignations;
              }
            }
          }
          return result;
        })
      );
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [viewData]);

  // handle
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleClick = (flag, uuid) => {
    if (flag === 'update') {
      navigate(various.general.memberUpdate, {
        state: { uuid: uuid, previous: location.pathname },
      });
    } else if (flag === 'orthodox') {
      navigate(various.general.memberCreate, {
        state: { previous: location.pathname, memberClass: flag },
      });
    } else if (flag === 'member_of_group') {
      navigate(various.general.memberCreate, {
        state: { previous: location.pathname, memberClass: flag },
      });
    } else if (flag === 'detail') {
      navigate(various.general.memberDetail, {
        state: { uuid: uuid, previous: location.pathname },
      });
    } else if (flag === 'himself') {
      navigate(various.general.memberCreateSendForm, {
        state: { uuid: uuid, previous: location.pathname, memberClass: flag },
      });
    } else if (flag === 'member_of_group_himself') {
      navigate(various.general.memberCreateSendForm, {
        state: { uuid: uuid, previous: location.pathname, memberClass: flag },
      });
    }
  };

  const handleResetPasswordOpen = (member_uuid) => {
    const userPk = viewData.find((m) => m.uuid === member_uuid).user;
    if (!userPk) {
      alert('ユーザー情報がありません。画面を更新してください。');
      return;
    }
    setResetDialog(true);
    setResetPasswordUser(userPk);
  };

  const handleClose = () => {
    setResetDialog(false);
  };

  const handleResetPassword = () => {
    if (resetPasswordUser) {
      setResetDialog(false);
      passwordResetForAdmin(setResetPasswordCompleted, {
        pk: resetPasswordUser,
      });
    } else {
      alert('画面を更新して再試行してください。');
    }
  };

  useEffect(() => {
    if (resetPasswordCompleted) {
      alert('パスワードを更新しました。');
    }
  }, [resetPasswordCompleted]);

  const handleMember = (flag) => {
    if (data.length > 0) {
      const allMembers = [...data];
      if (flag === 'all') {
        return setViewData(allMembers);
      } else if (flag === 'positive') {
        const result = [];
        allMembers.forEach((m) => {
          if (m.is_member) {
            result.push(m);
          }
        });
        return setViewData(result);
      } else if (flag === 'training') {
        const result = [];
        allMembers.forEach((m) => {
          if (m.is_training_group_member) {
            result.push(m);
          }
        });
        return setViewData(result);
      } else if (flag === 'participant') {
        const result = [];
        allMembers.forEach((m) => {
          if (
            !m.is_member &&
            (m.experience_training_participation_is ||
              m.seminar_participation_is)
          ) {
            result.push(m);
          }
        });
        return setViewData(result);
      } else if (flag === 'non') {
        const result = [];
        allMembers.forEach((m) => {
          if (!m.is_member && !m.is_training_group_member) {
            result.push(m);
          }
        });
        return setViewData(result);
      }
    }
  };

  const downloadCsv = () => {
    const headersObject = various.membersHeaders;
    const headers = Object.keys(viewData[0]).map((k) => {
      return k;
    });
    const rows = viewData.map((obj) => {
      let result = [];
      headers.forEach((h) => {
        const value = `${String(obj[h])
          .replace(/[\r\n]/g, ' ')
          .replace(/[,]/g, '、')
          .replace(/["]/g, '”')}`;

        result.push(value);
      });
      return result;
    });
    const rowHead = headers.map((k) => {
      return headersObject[k];
    });
    rows.unshift(rowHead);

    let csvString = '';
    for (let d of rows) {
      csvString += d.join(',');
      csvString += '\r\n';
    }

    const filename = 'member_index.csv';
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csvString], { type: 'text/csv' });

    //IE10/11用(download属性が機能しないためmsSaveBlobを使用）
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, filename);
      //その他ブラウザ
    } else {
      const url = (window.URL || window.webkitURL).createObjectURL(blob);
      const download = document.createElement('a');
      download.href = url;
      download.download = filename;
      download.click();
      (window.URL || window.webkitURL).revokeObjectURL(url);
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {isAuth ? (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">会員一覧</span>
              <span>{date}</span>
            </div>
            <div>
              <span>会員数</span>
              <span>{memberNum}名</span>
              <span>非会員数</span>
              <span>{nonMemberNum}名</span>
              <span>総登録数</span>
              <span>{memberNum + nonMemberNum}名</span>
            </div>
          </div>
          <BreadCrumbs crumbs={CRUMBS} />
          <hr />
          <div className={classes.createBtnContainer}>
            <div className={classes.createBtn1}>
              <span>管理者による登録&nbsp;:&nbsp;</span>
            </div>
            <div className={classes.createBtn2}>
              <button
                className={classes.createbtn1}
                onClick={() => handleClick('orthodox')}
              >
                {various.memberDesignations}を登録する
              </button>
              <button
                className={classes.createbtn2}
                onClick={() => handleClick('member_of_group')}
              >
                {various.otherMemberDesignations}を登録する
              </button>
            </div>
          </div>
          <div className={classes.createBtnContainer}>
            <div className={classes.createBtn1}>
              <span>本人による登録&nbsp;:&nbsp;</span>
            </div>
            <div className={classes.createBtn2}>
              <button
                className={classes.createbtn1}
                onClick={() => handleClick('himself')}
              >
                {various.memberDesignations}登録フォームを送る
              </button>
              <button
                className={classes.createbtn2}
                onClick={() => handleClick('member_of_group_himself')}
              >
                {various.otherMemberDesignations}登録フォームを送る
              </button>
            </div>
          </div>
          <div className={classes.viewDataBtnContainer}>
            <div id="viewBtn1">
              <span>絞り込み&nbsp;:&nbsp;</span>
            </div>
            <div id="viewBtn2">
              <button id="btn1" onClick={() => handleMember('all')}>
                すべて
              </button>
              <button id="btn2" onClick={() => handleMember('positive')}>
                {various.memberDesignations}
              </button>
              <button id="btn5" onClick={() => handleMember('training')}>
                {various.otherMemberDesignations}
              </button>
              <button id="btn4" onClick={() => handleMember('participant')}>
                セ・稽参加者
              </button>
              <button id="btn3" onClick={() => handleMember('non')}>
                非会員
              </button>
            </div>
          </div>
          <div className={classes.downLoadBtnContainer}>
            <div id="dlBtn1">
              <span>DownLoad&nbsp;:&nbsp;</span>
            </div>
            <div id="dlBtn2">
              <button id="dlbtn1" onClick={() => downloadCsv()}>
                <img src={imageIndex.CloudDownloadIcon} alt="dlicon" />
              </button>
            </div>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Box className={classes.dataGrid}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={PAGESIZE}
                  rowsPerPageOptions={[PAGESIZE]}
                  // checkboxSelection
                  density="comfortable"
                  columnVisibilityModel={{
                    uuid: false,
                    is_member: false,
                    is_training_group_member: false,
                  }}
                  pagination={true}
                  autoPageSize={true}
                  rowHeight={ROWHEIGHT}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                  localeText={
                    jaJP.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              </Box>
              <StandardDialog
                open={resetDialog}
                handleClose={handleClose}
                text={{
                  title: 'この会員に新たなパスワードを設定しますか？',
                  content:
                    '※新たなパスワードは自動で設定され、会員には通知されません。',
                  agree: '設定する',
                  disagree: 'キャンセル',
                }}
                func={{
                  agreeFunc: handleResetPassword,
                  disagreeFunc: handleClose,
                }}
              />
            </>
          )}
        </>
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
      <Footer />
    </>
  );
};
export default MemberIndex;
