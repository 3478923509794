import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../../auth';
import { useLocation, useNavigate } from 'react-router-dom';
import various from '../../../General/various';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import BreadCrumbs from '../../../General/breadCrumbs';
import Footer from '../../../Footer/footer';
import { notificationCreate } from '../../../Apis/apiOfManaged';
const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  titles: {},
  inputs: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  helptext: {},
  container: {
    marginTop: '168px',
    width: '100%',
    '& > form': {
      '& > p': {
        fontSize: '0.7em',
        textAlign: 'center',
      },
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          '& > label': {
            width: '100%',
            textAlign: 'end',
            '@media screen and (max-width:750px)': {
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
          '& > span': {
            color: 'red',
          },
        },

        '& $inputs': {
          width: '70%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input, & > textarea, & > select': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
          '& #image': {
            boxShadow: 'none',
            border: 'none',
          },
          '& > input[type="checkbox"]': {
            boxShadow: 'none',
          },
          '& > textarea': {
            height: '5em',
          },
          '& > img': {
            width: '100%',
            objectFit: 'contain',
            maxHeight: '300px',
          },
          '& $helptext': {
            fontSize: '0.8rem',
            color: 'grey',
            '& > a': {
              textDecoration: 'underline',
              '&:link': {
                color: theme.palette.primary.main,
              },
            },
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    marginTop: '88px !important',
    justifyContent: 'end',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const NotificationCreate = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {},
  });

  const { isAuth } = useContext(AuthContext);
  const [completed, setCompleted] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '会員への通知一覧', path: '/notification_index' },
    { name: '会員への通知登録', path: '/notification_create' },
  ];

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  // Init
  useEffect(() => {
    if (completed) {
      navigate('/notification_index');
    }
  }, [completed, navigate]);

  // Submit
  const onSubmit = handleSubmit(async (data) => {
    try {
      await notificationCreate(data);
      console.log('create finish!');
      setCompleted(true);
    } catch (error) {
      console.error('Error in createInstance:', error);
    }
  });

  // Contents
  const form = () => {
    return (
      <div className={classes.container}>
        <form method="POST" encType="multipart/form-data" onSubmit={onSubmit}>
          <div>
            <div className={classes.titles}>
              <label htmlFor="タイトル">{'タイトル'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="title"
                type="text"
                {...register('title', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              {errors.title?.message && (
                <div className={classes.error}>{errors.title.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="内容案内">{'内容案内'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="description"
                type="text"
                {...register('description', {
                  required: { value: false, message: '入力必須の項目です' },
                  maxLength: {
                    value: 30,
                    message: '30字以内でお願いします',
                  },
                })}
              />
              <div className={classes.helptext}>
                現状、管理用に使用されます。通知の目的等をご記載ください。
              </div>
              {errors.description?.message && (
                <div className={classes.error}>
                  {errors.description.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="通知予定日">{'通知予定日'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <input
                id="delivery_date"
                type="datetime-local"
                {...register('delivery_date', {
                  required: { value: true, message: '入力必須の項目です' },
                })}
              />
              <div className={classes.helptext}>
                通知メールが送信される日時を設定してください。過去の日付に設定することも可能です。
              </div>
              {errors.delivery_date?.message && (
                <div className={classes.error}>
                  {errors.delivery_date.message}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="記事内容">{'記事内容'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <textarea
                id="content"
                type="textarea"
                {...register('content', {
                  required: { value: true, message: '入力必須の項目です' },
                  maxLength: {
                    value: 2500,
                    message: '長すぎます',
                  },
                })}
              />
              <div className={classes.helptext}>
                2500文字以内でお願いします。
              </div>
              {errors.content?.message && (
                <div className={classes.error}>{errors.content.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="署名">{'署名'}</label>
              <span> *</span>
            </div>
            <div className={classes.inputs}>
              <textarea
                id="signature"
                type="textarea"
                {...register('signature', {
                  required: { value: true, message: '入力必須の項目です' },
                  maxLength: {
                    value: 100,
                    message: '長すぎます',
                  },
                })}
              />
              <div className={classes.helptext}>
                メール本文に付記される署名です。必ず記入してください。
              </div>
              {errors.signature?.message && (
                <div className={classes.error}>{errors.signature.message}</div>
              )}
            </div>
          </div>
          <div>
            <div className={classes.titles}>
              <label htmlFor="メール配信">{'メール配信'}</label>
            </div>
            <div className={classes.inputs}>
              <input
                id="delivered"
                type="checkbox"
                {...register('delivered', {
                  required: { value: false, message: '入力必須の項目です' },
                })}
              />
              <div className={classes.helptext}>
                チェックを入れたら全会員にメール配信されます。チェックをしなければ通知予定日が設定されていてもメール配信はされません。
              </div>
              {errors.delivered?.message && (
                <div className={classes.error}>{errors.delivered.message}</div>
              )}
            </div>
          </div>
          <div className={classes.button}>
            <button type="submit">登録</button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>会員への通知新規登録</div>
      <hr />
      <BreadCrumbs crumbs={CRUMBS} />
      {isAuth ? (
        <>{form()}</>
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
      <Footer />
    </>
  );
};
export default NotificationCreate;
