import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

import OnOffByScroll from '../Animation/onOffByScroll';
import ContentTitle from '../General/contentTitle';
import various from '../General/various';
import Footer from '../Footer/footer';
import { images as imageApi } from '../Apis/apis';
import imageIndex from '../img/imageIndex';
import { ogpGenerator } from '../ogp';

const useStyles = makeStyles((theme) => ({
  contentTitle: {
    marginTop: '168px',
  },
  yowan: {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    margin: '64px 0px ',
    '& > p': {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      fontSize: '1rem',
      marginBottom: '32px',
    },
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      '& > a': {
        '& > img': {
          width: '50%',
        },
      },
      '& > span': {
        fontSize: '0.9rem',
        '& > a': {
          textDecoration: 'underline',
          color: '#000',
        },
      },
    },
  },
  attention: {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    border: 'solid 1px black',
    fontSize: '1.1rem',
    padding: '16px',
    margin: '32px 0px 32px',
    '& > p': {
      textAlign: 'justify',
    },
    '& > ul': {
      fontSize: '0.9rem',
      textAlign: 'start',
      '& > li': {
        marginBottom: '16px',
      },
    },
  },
}));

const Calendar = () => {
  const SRC_URL =
    'https://calendar.google.com/calendar/embed?src=touzen.noreply%40gmail.com&ctz=Asia%2FTokyo';
  const YOWAN_CALENDAR_URL =
    'https://calendar.google.com/calendar/embed?src=yowan.ist%40gmail.com&ctz=Asia%2FTokyo';
  const [image, setImage] = useState();

  const location = useLocation();
  const classes = useStyles();
  const calendarAreaTitle = {
    titleList: ['Calendar'],
    sub: '稽古カレンダー',
  };

  // OGP設定
  const ogp = useCallback((props) => {
    ogpGenerator(props);
  }, []);

  useLayoutEffect(() => {
    if (image) {
      const ogpProps = {
        page: 'calendar',
        pageTitle: '刀禪OfficialWebSite稽古日程',
        path: location.pathname,
        imageUrl: image.OfficialImage_TouzenLogoBlack.url,
      };
      ogp(ogpProps);
    }
  }, [image, location, ogp]);
  // OGP設定ここまで

  useEffect(() => {
    imageApi(setImage);
  }, []);

  return (
    <>
      <div>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.contentTitle}>
        <a href={various.general.calendar}>
          <ContentTitle title={calendarAreaTitle} />
        </a>
      </div>
      <div>
        <iframe
          src={SRC_URL}
          title="touzen-calendar"
          style={{
            border: 'solid 1px #777',
            width: '100%',
            height: 'calc(100vw*0.5)',
            minHeight: '600px',
            frameBorder: '0',
            scrolling: 'no',
          }}
        />
      </div>
      <div className={classes.yowan}>
        <p>
          <imageIndex.booksPage.doubleArrow />
          揺腕法講座の稽古日程は以下のリンクからご確認ください。
        </p>
        <div>
          <a href={YOWAN_CALENDAR_URL}>
            <img src={imageIndex.booksPage.yowanLogo} alt="yowan_logo" />
          </a>
          <span>
            <a href={YOWAN_CALENDAR_URL}>講座カレンダー</a>
          </span>
        </div>
      </div>
      <div className={classes.attention}>
        <p>
          Safariで本カレンダーを確認できない方（reCAPTCHAが作動し続ける方）は以下をご確認ください。
        </p>
        <ul>
          <li>
            Safariのセキュリティー設定を見直す（”環境設定→プライバシー→サイト越えトラッキングを防ぐのチェックを外す”等の対応）。
            <br />
            ※セキュリティー関連の設定のため、適宜お調べの上自己責任でご対応ください。
          </li>
          <li>または、以下のリンクから直接カレンダーをご確認ください。</li>
        </ul>
        <a
          href={SRC_URL}
          style={{ textDecoration: 'underline', color: '#000' }}
        >
          カレンダーはこちらから
        </a>
      </div>
      <Footer />
    </>
  );
};
export default Calendar;
