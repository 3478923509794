import { useLocation, useNavigate } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

import OnOffByScroll from '../Animation/onOffByScroll';
import various from '../General/various';
import Footer from '../Footer/footer';
import { useContext } from 'react';
import { AuthContext } from '../auth';
import BreadCrumbs from '../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
    '& > span': {
      color: 'grey',
      fontSize: '0.8rem',
    },
  },
  attention: {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    border: 'solid 1px black',
    fontSize: '1.1rem',
    padding: '16px',
    margin: '32px 0px 32px',
    '& > p': {
      textAlign: 'justify',
    },
    '& > ul': {
      fontSize: '0.9rem',
      textAlign: 'start',
      '& > li': {
        marginBottom: '16px',
      },
    },
  },
}));

const CalendarInMyPage = () => {
  const { isAuth } = useContext(AuthContext);
  const CRUMBS = [
    { name: 'MyPage', path: '/mypage' },
    { name: 'Calendar', path: '/mypage/calendar' },
  ];

  const SRC_URL =
    'https://calendar.google.com/calendar/embed?src=touzen.information%40gmail.com&ctz=Asia%2FTokyo';

  const location = useLocation();
  const navigate = useNavigate();

  const classes = useStyles();

  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>
        稽古カレンダー
        <BreadCrumbs crumbs={CRUMBS} />
      </div>
      {isAuth ? (
        <>
          <div>
            <iframe
              src={SRC_URL}
              title="touzen-calendar"
              style={{
                border: 'solid 1px #777',
                width: '100%',
                height: 'calc(100vw*0.5)',
                minHeight: '600px',
                frameBorder: '0',
                scrolling: 'no',
              }}
            />
          </div>
          <div className={classes.attention}>
            <p>
              Safariで本カレンダーを確認できない方（reCAPTCHAが作動し続ける方）は以下をご確認ください。
            </p>
            <ul>
              <li>
                Safariのセキュリティー設定を見直す（”環境設定→プライバシー→サイト越えトラッキングを防ぐのチェックを外す”等の対応）。
                <br />
                ※セキュリティー関連の設定のため、適宜お調べの上自己責任でご対応ください。
              </li>
              <li>または、以下のリンクから直接カレンダーをご確認ください。</li>
            </ul>
            <a
              href={SRC_URL}
              style={{ textDecoration: 'underline', color: '#000' }}
            >
              カレンダーはこちらから
            </a>
          </div>
        </>
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
      <Footer />
    </>
  );
};
export default CalendarInMyPage;
