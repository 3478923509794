// import React, { useEffect } from 'react';
// import React, { useState } from 'react';

import {
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  makeStyles,
} from '@material-ui/core';

// import TripleImageGridDialog from '../Dialog/tripleImageGridDialog';

const useStyles = makeStyles((theme) => ({
  imageList: {},
  imageListItem: {},
  imgWrapper: {},
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    '& $imageList': {
      width: 'calc(70vw * 0.7)',
      height: 'calc(70vw * 0.7)',
      flexWrap: 'nowrap',
      transform: 'translateZ(0)',
      '& > li': {
        width: 'calc(70vw * 0.7) !important',
        height: 'calc(70vw * 0.7) !important',
        filter: 'drop-shadow(2px 2px 2px black)',
        '& $imageListItem': {
          width: 'calc(70vw * 0.7)',
          height: 'calc(70vw * 0.7)',
          filter: 'drop-shadow(0 0rem 1rem rgb(190 190 190))',
          '& $imgWrapper': {
            width: 'calc(70vw * 0.7)',
            height: 'calc(70vw * 0.7)',
            padding: '8px 8px',
            '& > span': {
              width: 'calc(70vw * 0.7)',
              height: 'calc(70vw * 0.7)',
              '& > img': {
                objectFit: 'cover',
                width: 'calc(70vw * 0.7)',
                height: 'calc(70vw * 0.7)',
                filter: 'contrast(100%) grayscale(100%) saturate(0%)',
                cursor: 'auto', // Dialogを復活させたら消す
              },
            },
          },
        },
      },
      '@media screen and (max-width:1000px)': {
        width: 'calc(92vw * 0.7) !important',
        height: 'calc(92vw * 0.7) !important',
        '& > li': {
          width: 'calc(92vw * 0.7) !important',
          height: 'calc(92vw * 0.7) !important',
          '& $imageListItem': {
            width: 'calc(92vw * 0.7) !important',
            height: 'calc(92vw * 0.7) !important',
            '& $imgWrapper': {
              width: 'calc(92vw * 0.7) !important',
              height: 'calc(92vw * 0.7) !important',
              '& > span': {
                width: 'calc(92vw * 0.7) !important',
                height: 'calc(92vw * 0.7) !important',
                '& > img': {
                  width: 'calc(92vw * 0.7) !important',
                  height: 'calc(92vw * 0.7) !important',
                },
              },
            },
          },
        },
      },
    },
  },

  rootSubtitle: {
    height: 'auto',
    background: 'none',
  },
  titleWrap: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'start',
    padding: '8px 8px',
    '@media screen and (max-width:800px)': {
      margin: '0px',
    },
  },
  title: {
    textAlign: 'start',
    fontWeight: 'bold',
    fontSize: '24px',
    paddingTop: '16px',
    '@media screen and (max-width:800px)': {
      fontSize: '3vw',
      // whiteSpace: 'pre-wrap',
    },
  },
  subTitle: {
    textAlign: 'start',
    '@media screen and (max-width:800px)': {
      fontSize: '2vw',
      whiteSpace: 'pre-wrap',
    },
  },
}));

const TripleGridForTools = (props) => {
  // const [open, setOpen] = useState(false);
  // const [choice, setChoice] = useState(null);
  // const [dialog, setDialog] = useState([]);

  const data = props.images;
  // const dialogs = props.dialogs;

  const classes = useStyles();

  // const handleClickOpen = (index) => {
  //   setOpen(true);
  //   setChoice(`choice_${index}`);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  // useEffect(() => {
  //   const judge = (tool) => {
  //     const result = [];
  //     dialogs.forEach((obj) => {
  //       if (obj.key.includes(tool)) {
  //         result.push(obj);
  //       }
  //     });
  //     return result;
  //   };
  //   if (choice) {
  //     switch (choice) {
  //       case 'choice_0':
  //         return setDialog(judge('bokutou'));
  //       case 'choice_1':
  //         return setDialog(judge('maiougi'));
  //       case 'choice_2':
  //         return setDialog(judge('takejaku'));
  //       default:
  //     }
  //   }
  // }, [dialogs, choice]);

  const dataMapping = () => {
    const result = [];
    if (data) {
      data.map((obj, index) => {
        return result.push(
          <ImageListItem
            classes={{ item: classes.imageListItem }}
            key={index}
            cols={1}
            rows={1}
          >
            <Button
              // onClick={() => handleClickOpen(index)}
              className={classes.imgWrapper}
            >
              <img src={obj.image.url} alt={`${obj.image.place}_${index}`} />
            </Button>

            {/* <TripleImageGridDialog
              open={open}
              handleClose={handleClose}
              dialogs={dialog}
            /> */}
            <ImageListItemBar
              classes={{
                rootSubtitle: classes.rootSubtitle,
                titleWrap: classes.titleWrap,
                title: classes.title,
                subtitle: classes.subTitle,
              }}
              title={`${obj.name}
              ${obj.english_name}`}
              subtitle={obj.caption}
            />
          </ImageListItem>
        );
      });
    }
    return (
      <ImageList
        className={classes.imageList}
        cols={1}
        // gap={4}
        // rowHeight={688}
      >
        {result}
      </ImageList>
    );
  };

  return (
    <>
      <div className={classes.root}>{dataMapping()}</div>
    </>
  );
};
export default TripleGridForTools;
