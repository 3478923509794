import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import OnOffByScroll from '../Animation/onOffByScroll';
import { images as imageApi } from '../Apis/apis';
import { ogpGenerator } from '../ogp';

const UnsubscribeConfirm = () => {
  const [image, setImage] = useState();
  const location = useLocation();

  // OGP設定
  const ogp = useCallback((props) => {
    ogpGenerator(props);
  }, []);

  useLayoutEffect(() => {
    if (image) {
      const ogpProps = {
        page: 'unsubscribe_confirm',
        pageTitle: '刀禪OfficialWebSiteニュースレター登録解除完了ページ',
        path: location.pathname,
        imageUrl: image.OfficialImage_TouzenLogoRed.url,
      };
      ogp(ogpProps);
    }
  }, [image, location, ogp]);
  // OGP設定ここまで

  useEffect(() => {
    imageApi(setImage);
  }, []);

  return (
    <>
      <div>
        <OnOffByScroll place="General" />
      </div>
      <div style={{ margin: '160px auto' }}>
        <p>ニュース配信の登録を抹消いたしました。</p>
      </div>
    </>
  );
};
export default UnsubscribeConfirm;
