import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    '& > h2': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: '1.1rem',
    },
  },
  contentRoot: {
    '& > p': {
      fontFamily: theme.typography.fontFamily,
      fontSize: '1rem',
    },
  },
  actionsRoot: {
    '& > button': {
      fontFamily: theme.typography.fontFamily,
    },
  },
}));

const AlertDialog = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const handleClose = () => {
    props.setOpen(false);
    navigate(0);
  };

  const dialog = (props, state, closeFunc) => {
    return (
      <div>
        <Dialog
          open={state}
          onClose={closeFunc}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              fontFamily:
                '"Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif',
              textAlign: 'start',
            },
          }}
        >
          <DialogTitle
            classes={{ root: classes.titleRoot }}
            id="alert-dialog-title"
          >
            {props.title}
          </DialogTitle>
          <DialogContent classes={{ root: classes.contentRoot }}>
            <DialogContentText id="alert-dialog-description">
              {props.content}
            </DialogContentText>
          </DialogContent>
          <DialogActions classes={{ root: classes.actionsRoot }}>
            <Button onClick={closeFunc} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  let p = {};
  if (props.open) {
    p = props.addFunc(props.open);
  }

  if (p.judge) {
    return dialog(p, props.open, handleClose);
  } else {
    return dialog(p, props.open, handleClose);
  }
};

export default AlertDialog;
