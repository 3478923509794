import { makeStyles } from '@material-ui/core';
import FadeIn from '../Animation/fadeIn';
import ContentTitle from '../General/contentTitle';
import various from '../General/various';
import ArrowButton from '../General/arrowButton';
import { images as imageApi } from '../Apis/apis';
import { useCallback, useEffect, useState } from 'react';
import Youtube from './youtube';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  contentBox: {
    textAlign: 'start',
  },
  readTextWrap: {},
  readText: {},
  voicesImage: {},
  content: {
    display: 'flex',
    justifyContent: 'start',
    marginLeft: '32px',
    flexWrap: 'nowrap',

    '& $readTextWrap': {
      width: '50%',
      marginRight: '32px',
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'start',
      flexWrap: 'wrap',
      '& $readText': {
        textIndent: '1rem',
        height: '90%',
        textAlign: 'justify',
      },
    },

    '& $voicesImage': {
      width: '50%',
      position: 'relative',
      // paddingBottom: '56.25%', // YouTube動画は横1.7777:縦1(1:0.5625)というアスペクト比のため、その比率に応じたフレーム内全画面としてのレスポンシブ対応
      // height: 0,
      height: 'calc(100vw*0.7*0.5625)',
      overflow: 'hidden',
      '& > iframe': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
    },

    '@media screen and (max-width:1000px)': {
      flexWrap: 'wrap',

      '& $readTextWrap': {
        width: '100%',
        margin: '0px 0px 32px 0px',
        '& $readText': {
          height: 'auto !important',
        },
      },

      '& $voicesImage': {
        width: '100%',
        height: 'calc(100vw*0.7)',
      },
    },
    '@media screen and (max-width:650px)': {
      margin: '0 0 0 0',
    },
  },
  arrow: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'end',
    '@media screen and (max-width:1000px)': {
      marginTop: '64px',
    },
  },
  arrowRead: {
    width: '50%',
    fontSize: '0.5em',
  },
}));

const VoicesOnTopPage = (props) => {
  const [image, setImage] = useState();

  const [loading, setLoading] = useState(true);
  const [view, setView] = useState();
  const [contents, setContents] = useState();

  const classes = useStyles();
  const voicesAreaTitle = {
    titleList: ['Voices'],
    sub: '稽古人の声',
  };
  const fadeProps = {
    direction: '$up',
    duration: '1s',
    fillMode: 'forwards',
    timingFunction: 'ease-out',
  };

  const callback = useCallback(() => {
    return (
      <div className={classes.contentBox}>
        {/* <FadeIn fadeProps={fadeProps}> */}
        <ContentTitle title={voicesAreaTitle} />
        {/* </FadeIn> */}
        <div className={classes.content}>
          <div className={classes.readTextWrap}>
            <span className={classes.readText}>
              刀禅では、武術・武道家のみならず、アスリート、芸術家、ダンサーはもとより、運動未経験の方々も多く在籍しています。
              刀禅に取り組み出してからの変化や、稽古を通じて獲得できたこと、専門分野や日常生活への影響など、語っていただきました。入会をご検討の方は是非とも参考にされてください。
            </span>
            <FadeIn fadeProps={fadeProps}>
              <div className={classes.arrow}>
                <Link to={various.general.media}>
                  <ArrowButton
                    path={various.general.media}
                    buttonName="MEDIA"
                    color="#000"
                  />
                </Link>
                <span className={classes.arrowRead}>
                  刀禪會に在籍しておられる出版人が手がけた、小用茂夫と著名人の対談記事も併せてご覧ください。
                </span>
              </div>
            </FadeIn>
          </div>
          <div className={classes.voicesImage}>
            <Youtube />
          </div>
        </div>
      </div>
    );
    // eslint-disable-next-line
  }, [classes]);

  useEffect(() => {
    imageApi(setImage);
  }, []);

  useEffect(() => {
    if (image) {
      setView(true);
      setLoading(false);
    }
  }, [image]);

  useEffect(() => {
    if (view) {
      setContents(callback());
    }
  }, [view, callback]);

  return <>{loading ? null : view ? contents : null}</>;
};
export default VoicesOnTopPage;
