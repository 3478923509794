import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../Animation/onOffByScroll';
import {
  myPage,
  memberIndex,
  memoIndex,
  passwordResetInMyPage,
  emailResetInMyPage,
  getStripeCustomerInfo,
  updateStripeCustomerEmail,
  userRelatedSubscriptionChangeRequestIndex,
  paramsIndex,
  alwaysUseCreditCard,
} from '../Apis/apiOfManaged';
import { AuthContext } from '../auth';
import various from '../General/various';
import imageIndex from '../img/imageIndex';
import {
  passwordValidator,
  passwordRegValidator,
  emailRegValidator,
} from '../CommonFunction/validators';
import StandardDialog from '../Dialog/standardDialog';
import Footer from '../Footer/footer';
import { Link } from 'react-router-dom';
import { localStorageArrayHandler } from '../CommonFunction/managePermission';
import { Spinner } from '../Spinner';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  title: {},
  dataContainer: {},
  inputDiv: {},
  icon: {},
  iconNull: {},
  button: {},
  openBtn: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  container: {
    display: 'flex',
    margin: '72px 0px',
    '@media screen and (max-width:750px)': {
      flexWrap: 'wrap',
    },
    '& $title': {
      width: '50%',
      marginRight: '32px',
      textAlign: 'start',
      '& > span': {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
      },
      '& > p': {
        fontSize: '0.8em',
      },
      '& > div': {
        width: '100%',
        margin: '32px 0px',
        fontSize: '0.8rem',
        '& > table': {
          borderCollapse: 'collapse',
          textAlign: 'center',
          '& > thead': {
            width: '100%',
            '& > tr': {
              '& >td': {
                minWidth: '112px',
                border: '1px solid #e0e0e0',
                borderBottom: '2px solid #e0e0e0', // 1行目のすべてのセルの下段の罫線を太くする
                '&:first-child': {
                  borderRight: '2px solid #e0e0e0', // 1列目の右側の罫線を太くする
                },
              },
            },
          },
          ' & > tbody': {
            width: '100%',
            '& > tr': {
              height: '80px',
              backgroundColor: 'white',
              '& > td:first-child': {
                borderRight: '2px solid #e0e0e0', // 1列目の右側の罫線を太くする
              },
              '& > td': {
                padding: '0px 8px',
                border: '1px solid #e0e0e0',
                width: '33%',
              },
              '&:hover': {
                backgroundColor: theme.palette.background.default,
              },
            },
            '& #method': {
              textAlign: 'start',
              lineHeight: '1.5',
              '& #methodTitle': {
                textAlign: 'center',
              },
            },
          },
        },
      },
      '@media screen and (max-width:750px)': {
        width: '80%',
      },
    },
    '& $dataContainer': {
      width: '50%',
      marginLeft: '56px',
      textAlign: 'start',
      '@media screen and (max-width:750px)': {
        width: '100%',
        marginLeft: '0px',
      },
      '& > div, & $inputDiv': {
        marginBottom: '48px',
        flexWrap: 'wrap',
        '& > span': {
          fontSize: '0.9em',
          fontWeight: 'bold',
        },
        '& > input': {
          width: '100%',
          height: '40px',
          padding: '8px',
          fontFamily: theme.typography.fontFamily,
          border: '0.5px solid grey',
          borderRadius: '0.9em',
          boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
          '&:focus': {
            outline: `1.2px ${theme.palette.primary.main} outset`,
          },
        },
        '& > ul': {
          fontSize: '0.8em',
        },
        '& > ol': {
          fontSize: '0.8em',
          listStyleType: 'decimal',
        },
      },
      '& $icon': {
        display: 'flex',
        margin: 'auto 0',
        gap: '0px 24px',
        '& > img, & > span': {
          display: 'block',
        },
        '& $iconNull': {
          color: 'red',
        },
      },
      '& #crTable, & #limitedText': {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '0.8rem',
        marginTop: '40px',
        '& > span': {
          fontSize: '0.7rem',
          color: 'grey',
        },
        '& > input': {
          width: '100%',
          height: '40px',
          padding: '8px',
          marginBottom: '16px',
          fontFamily: theme.typography.fontFamily,
          border: '0.5px solid grey',
          borderRadius: '0.9em',
          boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
          '&:focus': {
            outline: `1.2px ${theme.palette.primary.main} outset`,
          },
        },
      },
      '& #alwaysUseCreditCard': {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '0.8rem',
        marginTop: '40px',
        alignItems: 'flex-start',
        '& > hr': {
          width: '100%',
          marginBottom: '16px',
        },
        '& > div': {
          display: 'flex',
          flexWrap: 'nowrap',
          '& > span': {
            width: '70%',
            fontSize: '0.7rem',
            color: 'grey',
          },
          '& > input': {
            width: '30%',
            height: '40px',
            backgroundColor: 'grey !important',
            borderRadius: '100px',
            cursor: 'pointer',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
        },
      },
    },
    '& $button, & $openBtn': {
      width: '100%',
      textAlign: 'start',
      '& > a': {
        '& > button': {
          display: 'inline-block',
          fontFamily: theme.typography.fontFamily,
          fontWeight: 'bold',
          borderRadius: '0.9em',
          border: 'none',
          padding: '8px 12px',
          backgroundColor: 'rgba(192,192,192, 1)',
          boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
          color: '#000',
          cursor: 'pointer',
        },
      },
      '& > button': {
        display: 'inline-block',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        borderRadius: '0.9em',
        border: 'none',
        padding: '8px 12px',
        backgroundColor: 'rgba(192,192,192, 1)',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        color: '#000',
        cursor: 'pointer',
      },
      '& #payment_container1': {
        margin: '16px 16px 0px 0px',
        '& > div': {
          whiteSpace: 'nowrap',
          '& > input': {
            display: 'inline-block',
            padding: '8px',
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            fontFamily: theme.typography.fontFamily,
            // fontWeight: 'bold',
          },
          '& > button': {
            display: 'inline-block',
            padding: '8px',
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            fontSize: '0.5rem',
            marginLeft: '8px',
            cursor: 'pointer',
            verticalAlign: 'middle',
          },
          '& > span': {
            fontSize: '0.8rem',
            marginRight: '8px',
          },
        },
        '& #email_change_dialog': {
          position: 'fixed',
          top: '50%',
          left: '50%',
          borderRadius: '0.5em',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          padding: '20px',
          zIndex: 1000,
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.26)',
          '& > div': {
            '& > p': {
              fontSize: '0.8rem',
            },
            '& > form': {
              '& > input': {
                width: '100%',
                padding: '8px',
                border: '0.5px solid grey',
                borderRadius: '0.5em',
                boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
                fontWeight: 'bold',
              },
              '& > div': {
                display: 'flex',
                justifyContent: 'end',
                marginTop: '8px',
                '& > button': {
                  display: 'inline-block',
                  padding: '8px',
                  border: '0.5px solid grey',
                  borderRadius: '0.5em',
                  fontSize: '0.5rem',
                  marginLeft: '8px',
                  cursor: 'pointer',
                  verticalAlign: 'middle',
                },
              },
            },
          },
        },
      },
      '& #payment_container2': {
        margin: '16px 16px 32px 0px',
        '& > input': {
          display: 'inline-block',
          padding: '8px',
          border: '0.5px solid grey',
          borderRadius: '0.5em',
          boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
          fontFamily: theme.typography.fontFamily,
          // fontWeight: 'bold',
        },
        '& > button': {
          display: 'inline-block',
          padding: '8px',
          border: '0.5px solid grey',
          borderRadius: '0.5em',
          fontSize: '0.5rem',
          marginLeft: '8px',
          cursor: 'pointer',
          verticalAlign: 'middle',
        },
        '& > span': {
          fontSize: '0.8rem',
          marginRight: '8px',
        },
      },
      '& #payment_container3': {
        margin: '32px 16px 0px 0px',
        '& > button': {
          display: 'inline-block',
          fontFamily: theme.typography.fontFamily,
          fontWeight: 'bold',
          borderRadius: '0.9em',
          border: 'none',
          padding: '8px 12px',
          backgroundColor: 'rgba(192,192,192, 1)',
          boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
          color: '#000',
          cursor: 'pointer',
        },
        '& > p': {
          fontSize: '0.8rem',
          marginRight: '8px',
        },
      },
    },
  },
}));

const EmailChangeForm = (props) => {
  const oldEmail = props.email;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {},
  });

  const [dialog, setDialog] = useState(false);
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const classes = useStyles();

  const handleClose = () => {
    setDialog(false);
    navigate(0);
  };

  useEffect(() => {
    if (message) {
      alert(message);
      setLoading(false);
      navigate(0);
    }
  }, [message, navigate]);

  const onSubmit = handleSubmit((data) => {
    async function reset_email(data) {
      await emailResetInMyPage(setDialog, setMessage, data);
    }

    setLoading(true);
    data['protocol'] = window.location.protocol;
    data['host'] = window.location.host;
    data['domain'] = document.domain;
    data['old_email'] = oldEmail;
    reset_email(data);
  });

  return (
    <>
      <div className={classes.container}>
        <div className={classes.title}>
          <span>アカウントID</span>
          <p>ID（Eメール）を変更する場合は次のフォームから変更してください。</p>
        </div>
        <div className={classes.dataContainer}>
          <form method="POST" encType="multipart/form-data" onSubmit={onSubmit}>
            <div className={classes.inputDiv}>
              <span>新しいID（Eメール）</span>
              <input
                id="email"
                type="email"
                {...register('email', {
                  required: { value: true, message: '入力必須の項目です' },
                  validate: {
                    message: (v) =>
                      emailRegValidator(v) ? null : `Eメールの形式が不正です`,
                  },
                })}
              />
              {errors.email?.message && (
                <div className={classes.error}>{errors.email.message}</div>
              )}
            </div>
            {loading ? (
              <Spinner />
            ) : (
              <div className={classes.button}>
                <button type="submit" onClick={onSubmit}>
                  ID（Eメール）の変更
                </button>
              </div>
            )}
          </form>
        </div>
        <StandardDialog
          open={dialog}
          handleClose={handleClose}
          text={{
            title: '変更されたEメールアドレス宛にメッセージを送信しました。',
            content: 'メッセージをご確認の上、この変更を認証してください。',
            agree: '了解',
            disagree: null,
          }}
          func={{ agreeFunc: null, disagreeFunc: null }}
        />
      </div>
      <hr />
    </>
  );
};

const MyPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {},
  });

  const { isAuth } = useContext(AuthContext);
  const permissions = localStorageArrayHandler('parse', various.permissionKey);

  const [user, setUser] = useState();
  const [members, setMembers] = useState([]);
  const [member, setMember] = useState();
  const [memo, setMemo] = useState([]);
  // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [passLoading, setPassLoading] = useState(false);
  const [stripeCustomer, setStripeCustomer] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [response, setResponse] = useState();
  const [params, setParams] = useState(null);
  const [showPayment, setShowPayment] = useState(false);
  const [changeRequest, setChangeRequest] = useState();
  const [isDebug, setIsDebug] = useState(false);
  const [isLimited, setIsLimited] = useState(false);
  const [isAlwaysUseCreditCard, setIsAlwaysUseCreditCard] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  // if (stripeCustomer) console.log(stripeCustomer);
  // if (changeRequest) console.log(changeRequest);
  // if (member) console.log('member:', member);

  // Init
  const MEMBERSHIP_FEE = various.stripe.membershipFee;
  const NUM_MEMBERSHIP_FEE = various.stripe.numMembershipFee;

  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  useEffect(() => {
    if (isAuth) {
      memoIndex(setMemo);
    }
  }, [isAuth]);

  useEffect(() => {
    myPage(setUser);
  }, []);

  useEffect(() => {
    if (user && isAuth) {
      memberIndex(setMembers);
      setLoading(false);
      getStripeCustomerInfo(setStripeCustomer, user.email);
      userRelatedSubscriptionChangeRequestIndex(setChangeRequest, user.email);
      paramsIndex(setParams);
    }
  }, [user, isAuth]);

  useEffect(() => {
    if (members.length > 0 && user) {
      members.forEach((m) => {
        if (m.email === user.email && m.user === user.id) {
          setMember(m);
        }
      });
    }
  }, [members, user]);

  // 会員情報の更新
  const handleClick = (flag, uuid) => {
    if (flag === 'update') {
      navigate(various.general.mypageUpdate, {
        state: { uuid: uuid, previous: location.pathname },
      });
    }
  };

  // AccountUpdate
  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
  };

  // Password変更
  const handleClose = () => {
    setDialog(false);
    setPassLoading(false);
    navigate(0);
  };
  const onSubmit = handleSubmit((data) => {
    async function reset_password(data) {
      await passwordResetInMyPage(setDialog, data);
    }
    setPassLoading(true);

    let old;
    memo.forEach((m) => {
      if (user.id === m.user) {
        old = m.memo;
      }
    });
    data['old_password'] = old;
    data['pk'] = user.id;
    reset_password(data);
  });

  // MemberStatus
  const statusIs = () => {
    const result = [];
    if (member.roushi_is) {
      result.push({ icon: imageIndex.GreenCheckIcon, text: '老師' });
    } else {
      // 会員区分の切り分け
      if (member.active_is) {
        // カード決済運用後（定例・回数区分の復活後）の表示
        if (showPayment) {
          // 以前に存在していた正会員と稽古会会員の区分を取りやめる
          if (member.is_member || member.is_training_group_member) {
            if (stripeCustomer) {
              if (member.member_of_general_is) {
                result.push({
                  icon: imageIndex.GreenCheckIcon,
                  text: various.general_member,
                });
              } else if (member.member_of_num_is) {
                result.push({
                  icon: imageIndex.GreenCheckIcon,
                  text: various.num_member,
                });
              }
            } else {
              // クレカ登録していない会員は全て回数会員
              result.push({
                icon: imageIndex.GreenCheckIcon,
                text: various.num_member,
              });
            }
            // } else if (member.is_training_group_member) {
            //   result.push({
            //     icon: imageIndex.GreenCheckIcon,
            //     text: various.otherMemberDesignations,
            //   });
          } else {
            // 定例会院・回数会員以外はすてべ非会員
            result.push({ icon: '', text: '非会員' });
          }
        }
        // カード決済運用前（定例・回数区分の復活前）の表示
        else {
          if (member.is_member) {
            result.push({
              icon: imageIndex.GreenCheckIcon,
              text: various.memberDesignations,
            });
          } else if (member.is_training_group_member) {
            result.push({
              icon: imageIndex.GreenCheckIcon,
              text: various.otherMemberDesignations,
            });
          } else if (!member.is_member && !member.is_training_group_member) {
            result.push({ icon: '', text: '非会員' });
          }
        }
        // 会員属性の追加
        if (member.member_of_kosan)
          result.push({ icon: imageIndex.GreenCheckIcon, text: '古参' });
        if (member.member_of_honor)
          result.push({ icon: imageIndex.GreenCheckIcon, text: '名誉会員' });
        if (member.member_of_club_manager_is)
          result.push({ icon: imageIndex.GreenCheckIcon, text: '会主宰者' });
        if (member.member_of_instructor)
          result.push({ icon: imageIndex.GreenCheckIcon, text: '指導者' });
        if (member.member_of_organizer)
          result.push({
            icon: imageIndex.GreenCheckIcon,
            text: 'セミナー主催者',
          });
        if (member.member_of_admin)
          result.push({ icon: imageIndex.GreenCheckIcon, text: '運営担当' });
        // 休会中
      } else if (member.is_member && !member.active_is) {
        result.push({ icon: null, text: '休会中' });
        // 非会員・稽古会参加者
      } else {
        result.push({
          icon: imageIndex.GreenCheckIcon,
          text: '非会員 / 稽古参加者様',
        });
      }
    }
    return result;
  };
  const getAddress = () => {
    if (member) {
      let result;
      if (member.address1) result = member.address1;
      if (member.address2) result += member.address2;
      if (member.address3) result += member.address3;
      if (member.address4) result += member.address4;
      return result;
    }
  };

  // SeminarManage
  const seminarManaged = () => {
    if (member) {
      navigate(various.general.myPageSeminarUpdate, {
        state: { uuid: member.uuid },
      });
    }
  };

  // Payment
  useEffect(() => {
    if (params && member) {
      // 決済機能を運用しているか否か
      const is_run = params.find((param) => {
        return param.title === 'run_stripe_in_production';
      });
      // 決済機能を全体へ適用しているか否か
      const is_all_members = params.find((param) => {
        return param.title === 'run_stripe_all_members';
      });
      if (is_run?.apply_is) {
        if (is_all_members?.apply_is) {
          setShowPayment(true);
        } else {
          // テスト運用者のみに表示
          if (various.stripe.testRunStaff.includes(member.email)) {
            setShowPayment(true);
          }
        }
      }
      // 会員区分の変更の可否判定
      if (
        member.paymentmethodchangehistory &&
        member.paymentmethodchangehistory?.is_update_allowed === false
      ) {
        setIsLimited(true);
      }
    }
  }, [params, member]);

  useEffect(() => {
    if (stripeCustomer) {
      setIsAlwaysUseCreditCard(stripeCustomer.always_use_a_credit_card);
    }
  }, [stripeCustomer]);

  const gotoStripe = () => {
    let url;
    if (member) {
      if (process.env.NODE_ENV === 'production') {
        if (process.env.REACT_APP_ENVIRONMENT === 'production') {
          url = various.stripe.production;
        } else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
          url = various.stripe.test;
          setIsDebug(true);
        }
      } else if (process.env.NODE_ENV === 'development') {
        url = various.stripe.test;
        setIsDebug(true);
      }
      window.location.href = url;
    }
  };
  const gotoPaymentView = (isInitial) => {
    navigate(various.general.paymentView, {
      state: {
        previous: location.pathname,
        customer_id: stripeCustomer?.stripe_customer_id,
        customer_email: stripeCustomer?.email,
        customer_type: stripeCustomer?.customer_type,
        user_email: user.email,
        isInitial: isInitial,
        stripeCustomer: stripeCustomer,
        member: member,
      },
    });
  };
  const handleOpenDialog = () => {
    setShowDialog(true);
  };
  const handleCloseDialog = () => {
    setShowDialog(false);
    setDialogMessage(null);
    setNewEmail('');
    getStripeCustomerInfo(setStripeCustomer, user.email);
  };
  const handleStripeCustomerEmail = (e) => {
    e.preventDefault();
    try {
      const data = {
        customer_id: stripeCustomer.stripe_customer_id,
        new_email: newEmail,
      };
      updateStripeCustomerEmail(data, setResponse);
    } catch (error) {
      console.error('Error updating email:', error);
    }
  };
  const handleInputChange = async (user_email) => {
    setIsAlwaysUseCreditCard(!isAlwaysUseCreditCard);
    await alwaysUseCreditCard(user_email);
    if (isAlwaysUseCreditCard) {
      alert(
        '変更しました。セミナーの決済時には毎回お支払い方法をご選択ください。'
      );
    } else {
      alert(
        '変更しました。セミナーの決済時にはクレジットカードが自動選択されます。'
      );
    }
  };
  useEffect(() => {
    if (response) {
      if (response === 200) {
        setDialogMessage(
          '決済業者（Stripe)に登録しているEメールアドレスを変更しました'
        );
      } else {
        setDialogMessage(
          'Eメールアドレスの変更に失敗しました。再試行してください。'
        );
      }
    }
  }, [response]);

  // 休会・退会
  // eslint-disable-next-line
  const handleOpen = (flag) => {
    if (flag === 'open') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const contents = () => {
    if (member) {
      if (member.is_member || member.is_training_group_member) {
        return (
          <>
            {!permissions.includes('member_of_group') ? (
              <>
                <div className={classes.container}>
                  <div className={classes.title}>
                    <span>稽古日程</span>
                    <p>稽古日程については、次のボタンからご確認ください。</p>
                  </div>
                  <div className={classes.dataContainer}>
                    <div className={classes.button}>
                      <Link to={various.general.calendarInMypage}>
                        <button type="submit">稽古カレンダー</button>
                      </Link>
                    </div>
                  </div>
                </div>
                <hr />
                <div className={classes.container}>
                  <div className={classes.title}>
                    <span>稽古場</span>
                    <p>主な稽古場については、次のボタンからご確認ください。</p>
                  </div>
                  <div className={classes.dataContainer}>
                    <div className={classes.button}>
                      <Link to={various.general.trainingPlaceInfo}>
                        <button type="submit">稽古場Infomation</button>
                      </Link>
                    </div>
                  </div>
                </div>
                <hr />
              </>
            ) : undefined}
            <div className={classes.container}>
              <div className={classes.title}>
                <span>プロフィール</span>
                <p>
                  Eメールアドレスは、本システムでのあなたのIDです。連絡手段としてだけではなく、ログイン時に使用されます。
                </p>
              </div>
              <div className={classes.dataContainer}>
                <div>
                  <span>氏名</span>
                  <input
                    type="text"
                    value={member.last_name + ' ' + member.first_name}
                    readOnly
                  />
                </div>
                <div>
                  <span>Emailアドレス</span>
                  <input type="text" value={member.email} readOnly />
                </div>
                <div>
                  <span>住所</span>
                  <input type="text" value={getAddress()} readOnly />
                </div>
                <div>
                  <span>電話番号</span>
                  <input type="text" value={member.phone_number} readOnly />
                </div>
                <div className={classes.button}>
                  <button
                    type="submit"
                    onClick={() => handleClick('update', member.uuid)}
                  >
                    登録内容の変更
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <EmailChangeForm email={user.email ? user.email : member.email} />
            <div className={classes.container}>
              <div className={classes.title}>
                <span>パスワード</span>
                <p>
                  パスワードは暗号化の上保存されるため、設定されたご本人しか把握できません。大切に保管してください。
                </p>
              </div>
              <div className={classes.dataContainer}>
                <form
                  method="POST"
                  encType="multipart/form-data"
                  onSubmit={onSubmit}
                >
                  <div className={classes.inputDiv}>
                    <span>新しいパスワード</span>
                    <input
                      id="new_password"
                      type="password"
                      autoComplete="off"
                      {...register('new_password', {
                        required: {
                          value: true,
                          message: '入力必須の項目です',
                        },
                        validate: {
                          message: (v) =>
                            passwordRegValidator(v)
                              ? null
                              : `パスワードは8文字以上の半角英数字で設定してください。`,
                        },
                      })}
                    />
                    {errors.new_password?.message && (
                      <div className={classes.error}>
                        {errors.new_password.message}
                      </div>
                    )}
                  </div>
                  <div className={classes.inputDiv}>
                    <span>新しいパスワード（確認）</span>
                    <input
                      id="confirm"
                      type="password"
                      autoComplete="off"
                      {...register('confirm', {
                        required: {
                          value: true,
                          message: '入力必須の項目です',
                        },
                        validate: {
                          message: (v) =>
                            passwordValidator()
                              ? null
                              : `同じパスワードを入力してください。`,
                        },
                      })}
                    />
                    {errors.confirm?.message && (
                      <div className={classes.error}>
                        {errors.confirm.message}
                      </div>
                    )}
                  </div>
                  <div className={classes.button}>
                    {passLoading ? (
                      <Spinner />
                    ) : (
                      <button type="submit" onClick={onSubmit}>
                        パスワードの変更
                      </button>
                    )}
                  </div>
                </form>
              </div>
              <StandardDialog
                open={dialog}
                handleClose={handleClose}
                text={{
                  title: '新たなパスワードを設定しました。',
                  content:
                    '返信メール等はありません。パスワードは大切に保管してください。',
                  agree: '了解',
                  disagree: null,
                }}
                func={{ agreeFunc: null, disagreeFunc: null }}
              />
            </div>
            <hr />
            <div className={classes.container}>
              <div className={classes.title}>
                <span>会員区分</span>
                <p> </p>
              </div>
              <div className={classes.dataContainer}>
                {statusIs().map((obj, i) => {
                  return (
                    <div key={i} className={classes.icon}>
                      {obj.icon ? <img src={obj.icon} alt="icon" /> : null}
                      {obj.icon ? (
                        <span>{obj.text}</span>
                      ) : (
                        <span className={classes.iconNull}>{obj.text}</span>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <hr />
            <div className={classes.container}>
              <div className={classes.title}>
                <span>セミナー管理</span>
                <p>
                  申し込まれたセミナーをキャンセルする場合のみ、次のボタンからお進みください。
                </p>
              </div>
              <div className={classes.dataContainer}>
                <div className={classes.button}>
                  <button type="submit" onClick={seminarManaged}>
                    Manage seminar
                  </button>
                </div>
              </div>
            </div>
            <hr />
            {showPayment ? (
              <>
                <div className={classes.container}>
                  <div className={classes.title}>
                    <span>会費等の決済（会員区分の選択）</span>
                    <p>
                      次のボタンから会費等の決済に使用している支払い方法を登録・管理することができます。
                    </p>
                    <div>
                      <p>●会員区分と会費等の関係早見表</p>
                      <table>
                        <thead>
                          <tr>
                            <td></td>
                            <td>{various.general_member}</td>
                            <td>{various.num_member}</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>会費</td>
                            <td>
                              {MEMBERSHIP_FEE.toLocaleString()}円&nbsp;/&nbsp;月
                            </td>
                            <td>-</td>
                          </tr>
                          <tr>
                            <td>一般稽古参加費</td>
                            <td style={{ fontWeight: 'bold' }}>無料</td>
                            <td>
                              {NUM_MEMBERSHIP_FEE.toLocaleString()}
                              円&nbsp;/&nbsp;一コマ
                            </td>
                          </tr>
                          <tr>
                            <td>セミナー参加費</td>
                            <td style={{ fontWeight: 'bold' }}>半額</td>
                            <td>定額</td>
                          </tr>
                          <tr id="method">
                            <td id="methodTitle">決済方法</td>
                            <td style={{ fontWeight: 'bold' }}>
                              クレジットカード
                              <br />
                              のみ
                            </td>
                            <td>
                              クレジットカード
                              <br />
                              または現金
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className={classes.dataContainer}>
                    <div className={classes.button}>
                      {stripeCustomer ? (
                        <>
                          <button type="button" onClick={gotoStripe}>
                            決済方法の管理
                          </button>
                          <span
                            style={{
                              marginLeft: '8px',
                              fontSize: '0.7rem',
                              color: 'grey',
                              verticalAlign: 'bottom',
                            }}
                          >
                            ※外部ページに遷移します。
                          </span>
                          <div id="payment_container1">
                            <div>
                              <span>登録済Eメール: </span>
                              <input
                                type="text"
                                value={stripeCustomer.email}
                                readOnly
                              />
                              <button onClick={handleOpenDialog}>変更</button>
                            </div>
                            <p style={{ fontSize: '0.7rem', color: 'grey' }}>
                              ※決済業者Stripeに登録済みのEメールアドレスです。
                            </p>
                            {showDialog && (
                              <div
                                id="email_change_dialog"
                                // onClick={() => setShowDialog(false)}
                              >
                                {dialogMessage ? (
                                  <div>
                                    <p>{dialogMessage}</p>
                                    <button onClick={handleCloseDialog}>
                                      OK
                                    </button>
                                  </div>
                                ) : (
                                  <div>
                                    <p>
                                      新たに決済業者に登録するEメールアドレスを入力してください
                                    </p>
                                    <form onSubmit={handleStripeCustomerEmail}>
                                      <input
                                        type="email"
                                        value={newEmail}
                                        onChange={handleEmailChange}
                                        required
                                      />
                                      <div>
                                        <button type="submit">更新</button>
                                        <button
                                          onClick={() => setShowDialog(false)}
                                        >
                                          キャンセル
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <button
                          type="button"
                          onClick={() => gotoPaymentView(true)}
                        >
                          決済手段を登録する
                        </button>
                      )}
                      <div id="payment_container2">
                        <span>現在の支払方法: </span>
                        <input
                          type="text"
                          value={
                            !stripeCustomer ||
                            !stripeCustomer.by_stripe ||
                            stripeCustomer?.current_payment_method === 'cash'
                              ? '現金'
                              : stripeCustomer?.current_payment_method ===
                                'credit_card'
                              ? 'クレジットカード'
                              : stripeCustomer?.current_payment_method ===
                                'other'
                              ? stripeCustomer?.payment_type
                              : null
                          }
                          readOnly
                        />
                      </div>
                      {stripeCustomer ? (
                        <>
                          <hr />
                          <div id="payment_container3">
                            <button
                              type="button"
                              onClick={() => gotoPaymentView(false)}
                            >
                              決済方法の変更
                            </button>
                            <p
                              style={{
                                fontSize: '0.7rem',
                                color: 'grey',
                                verticalAlign: 'bottom',
                              }}
                            >
                              ※決済方法の変更は会員区分（定例会員or回数会員）の変更と同義です。
                              なお、会員区分の変更は”
                              <span
                                style={{
                                  fontWeight: 900,
                                  textDecoration: 'underline',
                                }}
                              >
                                半年に一度
                              </span>
                              ”しか出来ません。変更前に以下会則をご確認ください。
                            </p>
                            {changeRequest && changeRequest.length > 0
                              ? changeRequest.map((cr, i) => {
                                  const options = {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                  };
                                  return (
                                    <div key={cr.uuid} id="crTable">
                                      <span>会員区分変更予定日:</span>
                                      <input
                                        type="text"
                                        value={new Date(
                                          cr.execution_date
                                        ).toLocaleDateString('ja-JP', options)}
                                        readOnly
                                      />
                                      <span>会員区分:</span>
                                      <input
                                        type="text"
                                        value={`${various.general_member}から${various.num_member}へ変更`}
                                        readOnly
                                      />
                                      {isDebug ? (
                                        <p
                                          style={{
                                            fontSize: '0.8rem',
                                            color: 'grey',
                                          }}
                                        >
                                          ※開発・テスト環境の場合、会員区分変更予定日は『即時』になります。実際に変更を実行するには、以下のエンドポイントをPostmanやcurlコマンドで叩いてください。
                                          <br />
                                          https://test-touzen-official-front-v5.herokuapp.com/api/cron3hour/
                                          <br />
                                          もしローカルでテスト中であれば、ngrok等でエンドポイントを
                                          https://endpoint.ngrok.io/api/cron3hour/
                                          のように公開し実行してください（併せて、API側の設定において、settings.local.ALLOWED_HOSTSに公開したドメインを追加してください）。
                                        </p>
                                      ) : null}
                                    </div>
                                  );
                                })
                              : null}
                            {isLimited ? (
                              <div id="limitedText">
                                <span>前回の会員区分変更日:</span>
                                <input
                                  type="text"
                                  value={new Date(
                                    member.paymentmethodchangehistory?.latest_change_date
                                  ).toLocaleDateString('ja-JP', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                  })}
                                  readOnly
                                />
                                <span>次回の会員区分変更可能日:</span>
                                <input
                                  type="text"
                                  value={new Date(
                                    member.paymentmethodchangehistory?.next_update_date
                                  ).toLocaleDateString('ja-JP', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                  })}
                                  readOnly
                                />
                              </div>
                            ) : null}
                            <div id="alwaysUseCreditCard">
                              <hr />
                              <div>
                                <span>
                                  セミナー参加費の支払いに対して"常に"クレジットカードを使用する場合はチェックを入れてください。
                                  チェックが入っていない場合は、セミナー参加費の支払い時に都度ご選択願います。
                                </span>
                                <input
                                  type="checkbox"
                                  id="useCreditCard"
                                  checked={isAlwaysUseCreditCard}
                                  onChange={() =>
                                    handleInputChange(stripeCustomer.email)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
                <hr />
              </>
            ) : null}
            <div className={classes.container}>
              <div className={classes.title}>
                <span>会則</span>
                <p>刀禪會の会則については、次のボタンからご確認ください。</p>
              </div>
              <div className={classes.dataContainer}>
                <div className={classes.button}>
                  <Link to={various.general.constitution}>
                    <button type="submit">会則を読む</button>
                  </Link>
                </div>
              </div>
            </div>
            <hr />
            {!permissions.includes('member_of_group') ? (
              <>
                {/* <div className={classes.container}>
                  <div className={classes.title}>
                    <span>休会・退会</span>
                    <p>
                      休会・退会されたい方は、次の内容を明記の上、直接小用先生へ連絡してください。
                    </p>
                  </div>
                  {open ? (
                    <div className={classes.dataContainer}>
                      <div>
                        <span>明記すべき内容</span>
                        <hr />
                        <ul>
                          <li>休会／退会のいずれかを明示してください。</li>
                          <li>休会または退会の理由を明示してください</li>
                        </ul>
                        <hr />
                      </div>
                      <div>
                        <span>休会における注意事項</span>
                        <ol>
                          <li>休会であっても会員資格は失われません。</li>
                          <li>
                            休会であっても会員専用ページ・コンテンツは閲覧可能です。
                          </li>
                          <li>
                            現状では、休会の期限はありません。何らか制限ができた場合は会則の更新情報にてお知らせします。
                          </li>
                          <li>
                            講習会・セミナー等については、会員として申し込むことが出来ません。その際は復会のご連絡を本部までしてください。
                          </li>
                        </ol>
                      </div>
                      <div>
                        <span>退会における注意事項</span>
                        <ol>
                          <li>
                            一般公開している情報以外の刀禅情報を遮断させていただきます。本サイトのユーザー情報消失はもとより、
                            すべての刀禅関係団体への出入りをお断りさせていただきます。
                          </li>
                          <li>
                            特別な事由がない限り、再入会は可能です。ただし、その際は入会金が再度発生いたします。ご了承ください。
                          </li>
                        </ol>
                      </div>
                      <div className={classes.openBtn}>
                        <button type="submit" onClick={() => handleOpen('close')}>
                          閉じる
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.dataContainer}>
                      <div className={classes.openBtn}>
                        <button type="submit" onClick={() => handleOpen('open')}>
                          確認する
                        </button>
                      </div>
                    </div>
                  )}
                </div> */}
              </>
            ) : undefined}
            <Footer />
          </>
        );
      } else {
        return (
          <p style={{ textAlign: 'start', fontSize: '0.9rem', color: 'grey' }}>
            ※MyPageは会員の方のみ利用することができます。
          </p>
        );
      }
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>MyPage</div>
      <hr />
      {loading ? (
        <Spinner />
      ) : isAuth ? (
        contents()
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default MyPage;
