import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  title: {
    '& > h2': {
      fontSize: '1.1rem',
      textAlign: 'start',
    },
  },
  content: {
    fontSize: '0.9rem',
    textAlign: 'start',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    '& > span': {
      fontSize: '0.9rem',
    },
  },
  button: {
    color: theme.palette.primary.main,
  },
}));

const StandardDialog = (props) => {
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const classes = useStyles();

  const handleCheckboxChange = (event) => {
    setDoNotShowAgain(event.target.checked);
  };

  const handleAgree = async () => {
    if (doNotShowAgain && props.func?.updateDoNotShowAgain) {
      // ここでAPIコールを実行してモデルフィールドを更新する
      try {
        await props.func?.updateDoNotShowAgain();
      } catch (error) {
        console.error('Failed to update model field', error);
      }
    }
    // 他のagreeアクションの処理を行う
    if (props.func.agreeFunc) {
      props.func.agreeFunc();
    } else {
      props.handleClose();
    }
  };

  const handleDisAgree = async () => {
    if (doNotShowAgain && props.func?.updateDoNotShowAgain) {
      // ここでAPIコールを実行してモデルフィールドを更新する
      try {
        await props.func?.updateDoNotShowAgain();
      } catch (error) {
        console.error('Failed to update model field', error);
      }
    }
    // 他のagreeアクションの処理を行う
    if (props.func.agreeFunc) {
      props.func.disagreeFunc();
    } else {
      props.handleClose();
    }
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle classes={{ root: classes.title }} id="alert-dialog-title">
          {props.text.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            classes={{ root: classes.content }}
            id="alert-dialog-description"
          >
            {props.text.content}
          </DialogContentText>
          {props.func?.updateDoNotShowAgain ? (
            <FormControlLabel
              classes={{ root: classes.checkbox }}
              control={
                <Checkbox
                  checked={doNotShowAgain}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label="2度と表示しない"
            />
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          {props.text.agree ? (
            <Button classes={{ root: classes.button }} onClick={handleAgree}>
              {props.text.agree}
            </Button>
          ) : (
            <></>
          )}
          {props.text.disagree ? (
            <Button classes={{ root: classes.button }} onClick={handleDisAgree}>
              {props.text.disagree}
            </Button>
          ) : (
            <></>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StandardDialog;
