import { useCallback, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { Slide } from '@material-ui/core';

import Header from '../Header/header';

const useStyles = makeStyles((theme) => ({
  inVisible: {
    visibility: 'hidden',
  },
}));

const OnOffByScroll = (props) => {
  const classes = useStyles();
  const [isShown, setIsShown] = useState(true);
  // const [lastPosition, setLastPosition] = useState(0);
  const targetHeight = 1000;

  const scrollEvent = useCallback(() => {
    const offset = window.pageYOffset;
    if (offset > targetHeight) {
      setIsShown(false);
    } else {
      setIsShown(true);
    }

    // scrollUpの際にOn/Offしたければコメントイン
    // if (offset < lastPosition) {
    //   setIsShown(true);
    // }

    // setLastPosition(offset);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollEvent, { passive: true });
    return () => {
      window.removeEventListener('scroll', scrollEvent, { passive: true });
    };
  }, [scrollEvent]);

  const headerElm = (isShown, flag) => {
    switch (flag) {
      case 'General':
        return (
          <div>
            <Header logo={true} icons={isShown} color="General" />
            {/* なぜ以下のelementを追加しないとSlideエフェクトが効かないのか理由がわからないが、これでうまくいく。 */}
            <span className={classes.inVisible}>hidden</span>
          </div>
        );
      case 'TopPageHeader':
        return (
          <div>
            <Header logo={!isShown} icons={isShown} />
            <span className={classes.inVisible}>hidden</span>
          </div>
        );
      default:
    }
  };

  switch (props.place) {
    case 'General':
      return <>{headerElm(isShown, 'General')}</>;
    case 'TopPageHeader':
      if (isShown) {
        return (
          <Slide direction="right" in={isShown} timeout={1000}>
            {headerElm(isShown, 'TopPageHeader')}
          </Slide>
        );
      } else {
        return (
          <Slide direction="right" in={!isShown} timeout={1000} appear={true}>
            {headerElm(isShown, 'TopPageHeader')}
          </Slide>
        );
      }
    default:
  }
};
export default OnOffByScroll;
