import React from 'react';

import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';

import various from '../General/various';
import { Link } from 'react-router-dom';
import Clock from '../General/clock';

const useStyles = makeStyles((theme) => ({
  imageList: {},
  imageListItem: {},
  imgWrapper: {},
  rootSubtitle: {},
  titleWrap: {},
  title: {},
  subTitle: {},
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    height: '560px',

    '& $imgWrapper': {
      width: '100%',
      height: '70%',
      '& > span': {
        display: 'block',
        width: '100%',
        height: '70%',
        textAlign: 'center',
        '& > img': {
          display: 'inline-block',
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
        },
      },
    },

    '& $imageList': {
      flexWrap: 'nowrap',
      transform: 'translateZ(0)',

      '& > li': {
        width: '40% !important',
        height: 'auto !important',
        '& $imageListItem': {
          borderRadius: '16px',
          background: 'rgba(0,0,0,0.5) !important',
          '& $rootSubtitle': {
            height: '30%',
            borderRadius: '0px !important',
          },
          '& $titleWrap': {
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
          },
          '& $title': {
            fontWeight: 'bold',
            paddingTop: '16px',
            fontSize: '1rem',
            textAlign: 'start',
            whiteSpace: 'pre-wrap',
          },
          '& $subTitle': {
            textAlign: 'start',
            fontSize: '0.9rem',
          },
        },
        '@media screen and (max-width:1000px)': {
          width: '48% !important',
          '@media screen and (max-width:500px)': {
            width: '64% !important',
          },
        },
      },
    },
  },
}));

const TripleGrid = (props) => {
  const classes = useStyles();
  const data = props.data.slice(0, 8);
  const TIMEZONE = various.timezone;

  const seminarMapping = () => {
    const result = [];
    if (data) {
      data.map((obj, index) => {
        return result.push(
          <ImageListItem
            classes={{ item: classes.imageListItem }}
            key={index}
            cols={1}
            rows={1}
          >
            <Link
              to={`${various.general.seminar}/${obj.uuid}`}
              className={classes.imgWrapper}
            >
              <span>
                <img src={obj.image} alt={`seminar_image_${index}`} />
              </span>
            </Link>
            <ImageListItemBar
              classes={{
                rootSubtitle: classes.rootSubtitle,
                titleWrap: classes.titleWrap,
                title: classes.title,
                subtitle: classes.subTitle,
              }}
              title={obj.title}
              subtitle={[
                `開催日:${
                  Clock({
                    timezone: TIMEZONE,
                    time: null,
                    string: obj.event_date_start_time,
                  }).today
                }`,
                `時間:${
                  Clock({
                    timezone: TIMEZONE,
                    time: null,
                    string: obj.event_date_start_time,
                  }).hourMinutes
                } ~`,
                `場所:${
                  obj.display_name_of_the_place
                    ? obj.display_name_of_the_place
                    : '-'
                }`,
              ].map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
            />
          </ImageListItem>
        );
      });
    }
    return result;
  };

  const matches_1500 = useMediaQuery('(min-width:1500px)');
  const matches_1000 = useMediaQuery('(min-width:1000px)');
  let cols;
  const imageList = () => {
    if (matches_1500) {
      cols = 3;
    } else if (matches_1000) {
      cols = 2;
    } else {
      cols = 1;
    }
    return (
      <ImageList
        className={classes.imageList}
        cols={cols}
        gap={4}
        rowHeight={688}
      >
        {seminarMapping()}
      </ImageList>
    );
  };

  return (
    <>
      <div className={classes.root}>{imageList()}</div>
    </>
  );
};
export default TripleGrid;
