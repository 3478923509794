import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

import { memberBulkCreate } from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import various from '../../../General/various';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import Footer from '../../../Footer/footer';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  table: {
    display: 'block',
    tableLayout: 'auto',
    width: 'auto',
    fontSize: '0.9rem',
    lineHeight: '32px',
    overflow: 'scroll',
    whiteSpace: 'nowrap',
    '& > thead': {
      '& > tr': {
        '& > th': {
          minWidth: '72px',
          padding: '0px 16px',
        },
      },
    },
    '& > tbody': {
      '& > tr': {
        '& > td': {
          maxWidth: 0,
          overflow: 'scroll',
          whiteSpace: 'nowrap',
        },
      },
    },
  },
  button: {
    width: '70%',
    margin: '0 auto',
    marginTop: '64px',
    padding: '32px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderTop: 'solid 1px black',
    borderBottom: 'solid 1px black',
    '& > p': {
      fontSize: '0.9rem',
      textAlign: 'justify',
    },
    '& > button': {
      display: 'block',
      width: '25%',
      margin: '0 auto',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const MemberBulkCreate = () => {
  const { isAuth } = useContext(AuthContext);
  const [response, setResponse] = useState(true);
  const [message, setMessage] = useState();

  const [file, setFile] = useState();
  const [array, setArray] = useState([]);

  const fileReader = new FileReader();

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

    const array = csvRows.map((i) => {
      const values = i.split(',');
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    setArray(array);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  const handleCreate = () => {
    if (array.length > 0) {
      function inverseObject(obj, keyIsNumber) {
        return Object.keys(obj).reduceRight(function (ret, k) {
          // eslint-disable-next-line
          return (ret[obj[k]] = keyIsNumber ? parseInt(k, 10) : k), ret;
        }, {});
      }

      const japaneseKeyHeaders = inverseObject(various.membersHeaders);
      const result = array.map((obj) => {
        const changeKeyResult = {};
        Object.keys(japaneseKeyHeaders).forEach((k) => {
          if (k === 'PermissionLevel_pk') {
            changeKeyResult[japaneseKeyHeaders[k]] =
              obj['PermissionLevel_pk\r'];
          } else {
            changeKeyResult[japaneseKeyHeaders[k]] = obj[k];
          }
        });
        return changeKeyResult;
      });
      const protocol = window.location.protocol;
      const host = window.location.host;
      const domain = document.domain;
      const path = 'mypage';
      result.push({
        protocol: protocol,
        host: host,
        domain: domain,
        path: path,
      });
      console.log('result :', result);
      memberBulkCreate(setResponse, setMessage, result);
    }
  };

  useEffect(() => {
    if (!response) {
      alert('登録に失敗しました。登録様式等を見直してください。');
    }
    if (message) {
      alert(message);
    }
  }, [response, message]);

  const headerKeys = Object.keys(Object.assign({}, ...array));

  const rejectKeys = [
    'uuid',
    'PermissionLevel_pk\r',
    'Seminar_pk',
    'User_pk',
    'イメージ写真',
    'レコード作成日',
  ];
  const form = () => {
    return (
      <div style={{ textAlign: 'center', marginTop: '64px' }}>
        <form>
          <input
            type={'file'}
            id={'csvFileInput'}
            accept={'.csv'}
            onChange={handleOnChange}
          />

          <button
            onClick={(e) => {
              handleOnSubmit(e);
            }}
          >
            IMPORT CSV
          </button>
        </form>

        {array.length > 0 ? (
          <div className={classes.button}>
            <p>
              以下の内容で会員情報一括登録を行います。確定させる場合は以下の登録ボタンを押下してください。
            </p>
            <button onClick={handleCreate}>登録</button>
          </div>
        ) : null}

        <br />

        <table className={classes.table}>
          <thead>
            <tr key={'header'}>
              {headerKeys.map((key, i) =>
                !rejectKeys.includes(key) ? (
                  <th key={`head_${i}`}>{key}</th>
                ) : null
              )}
            </tr>
          </thead>

          <tbody>
            {array.map((item, i) => (
              <tr key={`row_${i}`}>
                {Object.keys(item).map((key, _i) =>
                  !rejectKeys.includes(key) ? (
                    <td key={`body_${_i}`}>{item[key]}</td>
                  ) : null
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>会員一括登録</div>
      <hr />
      {isAuth ? (
        form()
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
      <Footer />
    </>
  );
};
export default MemberBulkCreate;
