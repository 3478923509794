import { Box, makeStyles } from '@material-ui/core';
import { DataGrid, jaJP } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import {
  trainingHallIndex,
  trainingPlaceIndex,
} from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import { localStorageArrayHandler } from '../../../CommonFunction/managePermission';
import Footer from '../../../Footer/footer';
import various from '../../../General/various';
import { Spinner } from '../../../Spinner';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
  },
  container: {
    marginTop: '64px',
  },
  dataGrid: {
    height: (props) =>
      props.heightSize ? props.heightSize : props.pageSize * props.rowHeight,
    width: '100%',
  },
}));

const PlaceAndHallIndex = () => {
  const { isAuth } = useContext(AuthContext);
  const permissions = localStorageArrayHandler('parse', various.permissionKey);

  const [date, setDate] = useState();
  const [places, setPlaces] = useState();
  const [halls, setHalls] = useState();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [heightSize, setHeightSize] = useState();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const ROWHEIGHT = 80;
  const PAGESIZE = 30;
  const classes = useStyles({
    pageSize: PAGESIZE,
    rowHeight: ROWHEIGHT,
    heightSize: heightSize,
  });

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '稽古場一覧', path: '/manage/place_hall_index' },
  ];

  // Init
  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  useEffect(() => {
    trainingPlaceIndex(setPlaces, 'inuse');
    trainingHallIndex(setHalls);
  }, []);

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleClick = (flag, row) => {
    if (flag === 'update') {
      navigate(various.general.placeAndHallUpdate, {
        state: { previous: location.pathname, uuid: row.uuid },
      });
    } else if (flag === 'detail') {
      navigate(various.general.placeAndHallDetail, {
        state: { previous: location.pathname, uuid: row.uuid },
      });
    }
  };

  // Contents
  useEffect(() => {
    if (places && halls) {
      setColumns([
        {
          field: 'id',
          headerName: 'ID',
          width: 10,
        },
        {
          field: 'uuid',
        },
        {
          field: 'facility_name',
          headerName: '施設名称',
          cellClassName: 'facility_name',
          width: 200,
        },
        {
          field: 'facility_name_short',
          headerName: '略称',
          cellClassName: 'facility_name_short',
          width: 200,
        },
        {
          field: 'in_use_is',
          headerName: '使用区分',
          width: 100,
        },
        {
          field: 'halls',
          headerName: '稽古場',
          cellClassName: 'halls',
          width: 400,
        },
        {
          field: 'detailBtn',
          headerName: '詳細',
          sortable: false,
          width: 100,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <div>
              <button onClick={() => handleClick('detail', params.row)}>
                詳細
              </button>
            </div>
          ),
        },
        {
          field: 'updateBtn',
          headerName: '更新',
          sortable: false,
          width: 100,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <div>
              <button onClick={() => handleClick('update', params.row)}>
                更新
              </button>
            </div>
          ),
        },
      ]);

      setRows(
        places.map((place, index) => {
          const hallsObj = halls.filter((hall) => {
            return place.uuid === hall.training_place;
          });

          const row = {};
          row['id'] = index + 1;
          row['uuid'] = place.uuid;
          row['facility_name'] = place.facility_name;
          row['facility_name_short'] = place.facility_name_short;
          row['in_use_is'] = place.in_use_is ? '使用中' : '使用停止';
          row['halls'] = hallsObj.map((hall) => {
            return hall.name;
          });
          return row;
        })
      );
      setHeightSize(places.length * ROWHEIGHT * 1.4 + 122);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [places, halls]);

  return (
    <>
      <>
        <div style={{ margin: '80px' }}>
          <OnOffByScroll place="General" />
        </div>
        {!isAuth ? (
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleAuth}
            >
              ログイン
            </span>
            してください。
          </div>
        ) : permissions.includes('admin') ? (
          <>
            <div className={classes.pageTitle}>
              <div>
                <span id="title">稽古場一覧</span>
                <span>{date}</span>
              </div>
            </div>
            <hr />
            <BreadCrumbs crumbs={CRUMBS} />
            <div className={classes.container}>
              {loading ? (
                <Spinner />
              ) : (
                <Box
                  className={classes.dataGrid}
                  sx={{
                    '& .halls': {
                      '& > div': {
                        overFrow: 'scroll !important',
                      },
                    },
                    '& .facility_name, & .halls': {
                      fontStyle: 'italic',
                      fontFamily:
                        '"Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif',
                      fontWeight: 'bold',
                    },
                  }}
                >
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={PAGESIZE}
                    rowsPerPageOptions={[PAGESIZE]}
                    // checkboxSelection
                    density="comfortable"
                    columnVisibilityModel={{ uuid: false }}
                    pagination={true}
                    autoPageSize={true}
                    rowHeight={ROWHEIGHT}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    localeText={
                      jaJP.components.MuiDataGrid.defaultProps.localeText
                    }
                  />
                </Box>
              )}
            </div>
          </>
        ) : permissions === 'null' ? (
          setTimeout(() => {
            window.location.reload(true);
          }, 500)
        ) : (
          <>
            <div className={classes.pageTitle}>
              <div>
                <span id="title">稽古場一覧</span>
                <span>{date}</span>
              </div>
            </div>
            <hr />
            <div className={classes.container}>権限がありません</div>
          </>
        )}
        <Footer />
      </>
    </>
  );
};
export default PlaceAndHallIndex;
