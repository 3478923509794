import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import { productMasterDetail } from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import various from '../../../General/various';
import Footer from '../../../Footer/footer';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  updateBtn: {},
  helptext: {},
  container: {
    width: '100%',
    marginTop: '64px',
    '& > div': {
      width: '80%',
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      marginBottom: '24px',
      '& > span': {
        // display: 'inline',
        width: '40%',
        fontSize: '0.9rem',
        fontWeight: 'bold',
        textAlign: 'end',
        // verticalAlign: 'middle',
        lineHeight: '40px',
        marginRight: '16px',
      },
      '& > input, & > textarea': {
        width: '60%',
        height: '40px',
        padding: '8px',
        fontFamily: theme.typography.fontFamily,
        border: '0.5px solid grey',
        borderRadius: '0.9em',
        boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
        '&:focus': {
          outline: `1.2px ${theme.palette.primary.main} outset`,
        },
      },
      '& > textarea': {
        minHeight: '160px',
      },
      '& > img': {
        width: '60%',
        objectFit: 'contain',
      },

      '@media screen and (max-width:500px)': {
        width: '100%',
        flexWrap: 'wrap',
        '& > span': {
          width: '100%',
          textAlign: 'start',
        },
        '& > input, & > textarea, & > img': {
          width: '100%',
        },
      },
    },
    '& $helptext': {
      width: '100%',
      margin: '24px auto',
      fontSize: '0.8rem',
      textAlign: 'start',
      color: 'grey',
      '& > a': {
        textDecoration: 'underline',
        '&:link': {
          color: theme.palette.primary.main,
        },
      },
    },
    '& $updateBtn': {
      width: '100%',
      marginTop: '80px',
      '& > button': {
        width: '100%',
        display: 'block',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        borderRadius: '0.5em',
        border: 'none',
        padding: '4px 8px',
        backgroundColor: theme.palette.primary.main,
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        color: '#fff',
        cursor: 'pointer',
      },
    },
  },
}));

const ProductMasterDetail = () => {
  const { isAuth } = useContext(AuthContext);
  const [detailData, setDetailData] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: 'サービスマスター一覧', path: '/manage/product_master_index' },
    { name: 'サービスマスター詳細', path: '/manage/product_master_detail' },
  ];

  //   Init
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };
  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  useEffect(() => {
    let uuid;
    if (query.get('id')) {
      uuid = query.get('id');
    } else {
      uuid = location.state.uuid;
    }
    if (uuid) {
      productMasterDetail(setDetailData, uuid);
    }
  }, [location, query]);

  //   Handler
  const handleClick = (uuid) => {
    navigate(various.general.productMasterUpdate, {
      state: { uuid: uuid, previous: location.pathname },
    });
  };

  //   Util
  function formatJapaneseDate(dateString) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}年${month}月${day}日 ${hours}時${minutes}分`;
  }

  // Contents
  const contentTable = () => {
    if (detailData) {
      return (
        <>
          <div className={classes.container}>
            <div>
              <span>名称</span>
              <input type="text" value={detailData.name} readOnly />
            </div>
            <div className={classes.helptext}>
              Stripeの決済画面に表示される名称です。
            </div>
            <div>
              <span>説明書き</span>
              <textarea
                type="textarea"
                value={detailData.description ? detailData.description : '-'}
                readOnly
              />
            </div>
            <div className={classes.helptext}>
              Stripeの決済画面に表示される説明書きです。
            </div>
            <div>
              <span>明細書表記</span>
              <input
                type="text"
                value={detailData.statement_descriptor}
                readOnly
              />
            </div>
            <div className={classes.helptext}>
              クレジットカード明細書に記載される表記です。
            </div>
            <div>
              <span>管理用タグ</span>
              <input
                type="text"
                value={
                  detailData.management_tags ? detailData.management_tags : '-'
                }
                readOnly
              />
            </div>
            <div>
              <span>ブランド</span>
              <input
                type="text"
                value={detailData.brand ? detailData.brand : '-'}
                readOnly
              />
            </div>
            <div className={classes.helptext}>
              管理用の区分です。現在未使用です。
            </div>
            <div>
              <span>機能リスト</span>
              <textarea
                type="textarea"
                value={detailData.features ? detailData.features : '-'}
                readOnly
              />
            </div>
            <div>
              <span>単価</span>
              <input type="text" value={detailData.price} readOnly />
            </div>
            <div>
              <span>通貨</span>
              <input type="text" value={detailData.currency} readOnly />
            </div>
            <div>
              <span>提供区分</span>
              <input
                type="text"
                value={detailData.is_active ? '提供中' : '提供中止'}
                readOnly
              />
            </div>
            <div>
              <span>半額モデル区分</span>
              <input
                type="text"
                value={detailData.is_half_model ? '半額' : '通常価格'}
                readOnly
              />
            </div>
            <div>
              <span>決済タイプ</span>
              <input
                type="text"
                value={
                  detailData.product_type === 'subscription' ? '定期' : '都度'
                }
                readOnly
              />
            </div>
            {detailData.product_type === 'subscription' && (
              <div>
                <span>請求周期</span>
                <input
                  type="text"
                  value={`毎${
                    detailData.billing_cycle_type === 'yearly'
                      ? '年'
                      : detailData.billing_cycle_type === 'monthly'
                      ? '月'
                      : detailData.billing_cycle_type === 'weekly'
                      ? '週'
                      : '日'
                  }${detailData.billing_cycle_start_day}日に${
                    detailData.billing_cycle_value
                  }度請求する`}
                  readOnly
                />
              </div>
            )}
            <div>
              <span>区分</span>
              <input
                type="text"
                value={
                  detailData.product_category === 'basic'
                    ? '基本サービス'
                    : 'オプションサービス'
                }
                readOnly
              />
            </div>
            <div>
              <span>MetaData</span>
              <textarea
                type="textarea"
                value={
                  detailData.metadata
                    ? JSON.stringify(detailData.metadata)
                    : '-'
                }
                readOnly
              />
            </div>
            {detailData.image && (
              <div>
                <span>イメージ</span>
                <img src={detailData.image} alt="product_image" />
              </div>
            )}
            <div>
              <span>Stripeへの登録</span>
              <input
                type="text"
                value={detailData.create_stripe_product ? '登録済み' : '未登録'}
                readOnly
              />
            </div>
            <div>
              <span>StripeプロダクトID</span>
              <input
                type="text"
                value={detailData.stripe_product_id}
                readOnly
              />
            </div>
            <div>
              <span>Stripe価格ID</span>
              <input type="text" value={detailData.stripe_price_id} readOnly />
            </div>
            <div>
              <span>最新更新日</span>
              <input
                type="text"
                value={formatJapaneseDate(detailData.updated_at)}
                readOnly
              />
            </div>
            <div>
              <span>作成日</span>
              <input
                type="text"
                value={formatJapaneseDate(detailData.created_at)}
                readOnly
              />
            </div>
            <div className={classes.updateBtn}>
              <button onClick={() => handleClick(detailData.uuid)}>更新</button>
            </div>
          </div>
          <Footer />
        </>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>サービスマスター詳細</div>
      <hr />
      <BreadCrumbs crumbs={CRUMBS} />
      {isAuth ? (
        contentTable()
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default ProductMasterDetail;
