import { Box, makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import various from '../../../General/various';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import BreadCrumbs from '../../../General/breadCrumbs';
import { Spinner } from '../../../Spinner';
import { DataGrid, jaJP } from '@mui/x-data-grid';
import Footer from '../../../Footer/footer';
import { AuthContext } from '../../../auth';
import {
  notificationIndex,
  notificationUpdate,
} from '../../../Apis/apiOfManaged';
import StandardDialog from '../../../Dialog/standardDialog';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
  },
  dataGrid: {
    height: (props) =>
      props.heightSize ? props.heightSize : props.pageSize * props.rowHeight,
    width: '100%',
    '& .MuiDataGrid-main': {
      fontSize: '0.9rem',
      fontFamily: theme.typography.fontFamily,
    },
  },
  createBtnContainer: {
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',
    '@media screen and (max-width:600px)': {
      flexWrap: 'wrap',
      gap: '0px 16px',
    },
    '& > div': {
      margin: 'auto 0',
      '& > span': {
        display: 'inline-block',
      },
      '& > button': {
        display: 'inline-block',
        fontFamily: theme.typography.fontFamily,
        fontSize: '0.9rem',
        fontWeight: 'bold',
        borderRadius: '0.5em',
        border: 'none',
        padding: '4px 8px',
        height: '30px',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        cursor: 'pointer',
        '@media screen and (max-width:600px)': {
          display: 'block',
          width: '114px',
          overflow: 'hidden',
        },
      },
      '& #createbtn1': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
      '& #createbtn2': {
        backgroundColor: 'rgba(124,42,46,0.8)',
        color: '#fff',
      },
    },
    '& #createBtn1': {
      width: '20%',
      fontSize: '0.9rem',
      textAlign: 'end',
      '@media screen and (max-width:600px)': {
        width: '114px',
        textAlign: 'start',
      },
    },
    '& #createBtn2': {
      width: '80%',
      display: 'flex',
      gap: '0px 16px',
      textAlign: 'start',
      '@media screen and (max-width:600px)': {
        width: '100%',
      },
    },
  },
  viewDataBtnContainer: {
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',
    '@media screen and (max-width:600px)': {
      flexWrap: 'wrap',
      gap: '0px 16px',
    },
    '& > div': {
      margin: 'auto 0',
      '& > span': {
        display: 'inline-block',
      },
      '& > button': {
        display: 'inline-block',
        fontFamily: theme.typography.fontFamily,
        fontSize: '0.9rem',
        fontWeight: 'bold',
        borderRadius: '0.5em',
        border: 'none',
        padding: '0px 8px',
        height: '30px',
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        cursor: 'pointer',
        '@media screen and (max-width:600px)': {
          display: 'block',
          width: '114px',
          overflow: 'hidden',
        },
      },
      '& #btn1': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
      '& #btn2': {
        backgroundColor: 'rgba(124,42,46,0.8)',
        color: '#fff',
      },
      '& #btn4': {
        backgroundColor: 'rgba(124,42,46,0.5)',
        color: '#000',
      },
      '& #btn3': {
        backgroundColor: 'rgba(124,42,46,0.3)',
        color: '#000',
      },
    },
    '& #viewBtn1': {
      width: '20%',
      fontSize: '0.9rem',
      textAlign: 'end',
      '@media screen and (max-width:600px)': {
        width: '114px',
        textAlign: 'start',
      },
    },
    '& #viewBtn2': {
      width: '80%',
      display: 'flex',
      gap: '0px 16px',
      textAlign: 'start',
      '@media screen and (max-width:600px)': {
        width: '100%',
      },
    },
  },
  downLoadBtnContainer: {
    marginBottom: '80px',
    display: 'flex',
    justifyContent: 'start',
    gap: '16px 16px',

    '@media screen and (max-width:600px)': {
      marginBottom: '0px',
    },
    '& > div': {
      width: '20%',
      margin: 'auto 0',
      // cursor: 'not-allowed', // downloadが機能したら削除
      '& > span': {
        display: 'inline-block',
      },
      '& > button': {
        display: 'inline-block',
        border: 'none',
        padding: '4px 8px',
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer', // downloadが機能したら復活
        // pointerEvents: 'none', // downloadが機能したら削除
      },
    },
    '& #dlBtn1': {
      fontSize: '0.9rem',
      textAlign: 'end',
      '@media screen and (max-width:600px)': {
        width: '114px',
      },
    },
    '& #dlBtn2': {
      textAlign: 'start',
    },
  },
  distributionBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      backgroundColor: 'green',
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
  distributionBtnConfirm: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      backgroundColor: 'grey',
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'not-allowed',
    },
  },
  detailBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  updateBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
  createMemberBtn: {
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
}));

const NotificationIndex = () => {
  const { isAuth } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [heightSize, setHeightSize] = useState();
  const [date, setDate] = useState();
  const [notificationsNum, setNotificationsNum] = useState();
  const [nonNotificationsNum, setNonNotificationsNum] = useState();
  const [today, setToday] = useState();
  const [loading, setLoading] = useState(true);
  const [dialog, setDialog] = useState(false);
  const [uuid, setUuid] = useState();

  const ROWHEIGHT = 80;
  const PAGESIZE = 30;
  const classes = useStyles({
    pageSize: PAGESIZE,
    rowHeight: ROWHEIGHT,
    heightSize: heightSize,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '会員への通知一覧', path: '/notification_index' },
  ];

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleClick = (flag, uuid) => {
    if (flag === 'update') {
      navigate(various.general.notificationUpdate, {
        state: { uuid: uuid, previous: location.pathname },
      });
    } else if (flag === 'create') {
      navigate(various.general.notificationCreate, {
        state: { previous: location.pathname },
      });
    } else if (flag === 'distribution') {
      setUuid(uuid);
      setDialog(true);
    }
  };

  const handleNotification = (flag) => {
    if (data.length > 0) {
      const allNotifications = [...data];
      if (flag === 'all') {
        return setViewData(allNotifications);
      } else if (flag === 'positive') {
        const result = [];
        allNotifications.forEach((notice) => {
          const date = new Date(notice.delivery_date);
          if (notice.delivered && today > date) {
            result.push(notice);
          }
        });
        return setViewData(result);
      } else if (flag === 'participant') {
        const result = [];
        allNotifications.forEach((notice) => {
          const date = new Date(notice.delivery_date);
          if (!notice.delivered || today < date) {
            result.push(notice);
          }
        });
        return setViewData(result);
      }
    }
  };

  // Init
  useEffect(() => {
    notificationIndex(setData);
  }, []);

  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const notifications = data.map((d) => {
        const date = new Date(d.delivery_date);
        return d.delivered && today > date;
      });
      setNotificationsNum(notifications.filter(Boolean).length);
      setNonNotificationsNum(
        data.length - notifications.filter(Boolean).length
      );
    }
  }, [data, today]);

  useEffect(() => {
    setViewData(data);
  }, [data]);

  useEffect(() => {
    setToday(new Date());
  }, []);

  useEffect(() => {
    if (data) {
      const size = data.length < PAGESIZE ? data.length : PAGESIZE;
      setHeightSize(size * ROWHEIGHT * 1.4 + 122);
    }
  }, [data]);

  //   Dialog
  const handleClose = () => {
    setDialog(false);
  };

  const pushNotification = async () => {
    const sedData = {
      uuid: uuid,
      delivered: true,
    };
    await notificationUpdate(sedData);
    setDialog(false);
    navigate(0);
  };

  // Contents
  useEffect(() => {
    if (viewData) {
      const headers = [
        {
          field: 'id',
          headerName: 'ID',
          width: 90,
        },
        {
          field: 'uuid',
        },
        {
          field: 'delivered',
        },
        {
          field: 'title',
          headerName: 'タイトル',
          width: 250,
        },
        {
          field: 'description',
          headerName: '内容案内（管理用）',
          width: 250,
        },
        {
          field: 'delivery_date',
          headerName: '通知予定日時',
          width: 150,
        },
        {
          field: 'status',
          headerName: 'Status',
          width: 100,
          cellClassName: 'status',
        },
      ];

      const operationBtn = [
        {
          field: 'distributionBtn',
          headerName: '通知',
          sortable: false,
          width: 90,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <>
              {params.row.delivered ? (
                <div className={classes.distributionBtnConfirm}>
                  <button>通知</button>
                </div>
              ) : (
                <div className={classes.distributionBtn}>
                  <button
                    onClick={() => handleClick('distribution', params.row.uuid)}
                  >
                    通知{params.row.delivered}
                  </button>
                </div>
              )}
            </>
          ),
        },
        {
          field: 'updateBtn',
          headerName: '更新',
          sortable: false,
          width: 90,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <div className={classes.updateBtn}>
              <button onClick={() => handleClick('update', params.row.uuid)}>
                修正
              </button>
            </div>
          ),
        },
      ];
      const columnsDataset = headers.concat(operationBtn);
      setColumns(columnsDataset);

      setRows(
        viewData.map((d, index) => {
          const result = {};
          result['id'] = index + 1;
          result['uuid'] = d['uuid'];
          result['delivered'] = d['delivered'];
          result['title'] = d['title'];
          result['description'] = d['description'];

          if (d['delivered']) {
            if (d['delivery_date']) {
              const date = new Date(d['delivery_date']);
              const today = new Date();
              if (date < today) {
                result['status'] = '通知済み';
              } else {
                result['status'] = '通知予定';
              }
            }
          } else {
            result['status'] = '通知未定';
          }

          if (d['delivery_date']) {
            const date = new Date(d['delivery_date']);
            result['delivery_date'] =
              date.toLocaleDateString() +
              ' ' +
              date.getHours() +
              ':' +
              date.getMinutes().toString().padStart(2, '0');
          } else {
            result['delivery_date'] = '-';
          }

          return result;
        })
      );
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [viewData]);

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {isAuth ? (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">会員への通知一覧</span>
              <span>{date}</span>
            </div>
            <div>
              <span>公開数</span>
              <span>{notificationsNum}件</span>
              <span>未公開数</span>
              <span>{nonNotificationsNum}件</span>
              <span>総登録数</span>
              <span>{notificationsNum + nonNotificationsNum}件</span>
            </div>
          </div>
          <BreadCrumbs crumbs={CRUMBS} />
          <hr />
          <div className={classes.createBtnContainer}>
            <div id="createBtn1">
              <span>新規登録&nbsp;:&nbsp;</span>
            </div>
            <div id="createBtn2">
              <button id="createbtn1" onClick={() => handleClick('create')}>
                登録
              </button>
            </div>
          </div>
          <div className={classes.viewDataBtnContainer}>
            <div id="viewBtn1">
              <span>絞り込み&nbsp;:&nbsp;</span>
            </div>
            <div id="viewBtn2">
              <button id="btn1" onClick={() => handleNotification('all')}>
                すべて
              </button>
              <button id="btn2" onClick={() => handleNotification('positive')}>
                公開済
              </button>
              <button
                id="btn4"
                onClick={() => handleNotification('participant')}
              >
                未公開
              </button>
            </div>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Box className={classes.dataGrid}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={PAGESIZE}
                  rowsPerPageOptions={[PAGESIZE]}
                  density="comfortable"
                  columnVisibilityModel={{ uuid: false, delivered: false }}
                  pagination={true}
                  autoPageSize={true}
                  rowHeight={ROWHEIGHT}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                  localeText={
                    jaJP.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              </Box>
              {dialog ? (
                <StandardDialog
                  open={dialog}
                  handleClose={handleClose}
                  text={{
                    title: '全会員へメールで通知します。',
                    content: '通知を行う場合はOKボタンを押してください。',
                    agree: 'OK',
                    disagree: 'やはり止める',
                  }}
                  func={{
                    agreeFunc: pushNotification,
                    disagreeFunc: handleClose,
                  }}
                />
              ) : null}
            </>
          )}
        </>
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
      <Footer />
    </>
  );
};
export default NotificationIndex;
