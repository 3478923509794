import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

import OnOffByScroll from '../Animation/onOffByScroll';
import ArrowButton from '../General/arrowButton';
import ContentTitle from '../General/contentTitle';
import various from '../General/various';
import imageIndex from '../img/imageIndex';
import { images as imageApi } from '../Apis/apis';
import Footer from '../Footer/footer';
import { ogpGenerator } from '../ogp';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  contentTitle: {
    marginTop: '168px',
  },
  container: {
    marginTop: '192px',
  },
  image: {
    textAlign: 'center',
    '& > a': {
      '& > img': {
        width: '50%',
        height: 'auto',
        maxHeight: '1000px',
        filter: 'drop-shadow(2px 2px 2px black)',
        objectFit: 'contain',
      },
    },
    '@media screen and (max-width:900px)': {
      '& > a': {
        '& > img': {
          width: '80% !important',
        },
      },
    },
  },
  editorWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '112px',
    '@media screen and (max-width:900px)': {
      flexWrap: 'wrap',
    },
  },
  editorRead: {
    width: '45%',
    marginRight: '24px',
    textAlign: 'justify',
    textIndent: '1rem',
    lineHeight: '3rem',
    '@media screen and (max-width:900px)': {
      width: '100%',
      marginRight: '0px',
    },
  },
  editorContentWrapper: {
    width: '45%',
    display: 'flex',
    flexFlow: 'column',
    '& > p': {
      color: 'grey',
    },
    '@media screen and (max-width:1200px)': {
      width: '50%',
    },
    '@media screen and (max-width:900px)': {
      width: '100%',
      marginTop: '40px',
    },
  },
  editorImageWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media screen and (max-width:1200px)': {
      flexWrap: 'wrap',
      '& $editorContent': {
        width: '100%',
      },
    },
    '@media screen and (max-width:900px)': {
      flexWrap: 'nowrap',
      '& $editorContent': {
        width: '55%',
      },
    },
  },
  editorImage: {
    width: '40%',
    marginRight: '16px',
    '& > img': {
      width: '100%',
      height: 'auto',
      maxHeight: '300px',
      filter:
        'contrast(100%) grayscale(100%) saturate(0%) drop-shadow(2px 2px 2px black)',
      objectFit: 'cover',
    },
  },
  editorContent: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'start',
    width: '55%',
    '& > p': {
      textAlign: 'justify',
      fontSize: '0.8rem',
    },
  },
  editorName: {
    display: 'flex',
    alignItems: 'bottom',
    fontWeight: 'bold',
    textAlign: 'start',
    '& > img': {
      width: '20px',
      marginLeft: '16px',
    },
  },
  editorCareer: {
    textAlign: 'start',
    fontSize: '0.8rem',
  },
  links: {
    display: 'flex',
    justifyContent: 'start',
    flexFlow: 'column',
    verticalAlign: 'top',

    '& > span': {
      textAlign: 'start',
    },
    '& > div': {
      display: 'flex',
      justifyContent: 'start',
      flexFlow: 'column',
      borderTop: 'solid 1px grey',
      borderBottom: 'solid 1px grey',
      padding: '16px 0px',
      '& > a': {
        display: 'flex',
        justifyContent: 'space-between',
        '& > span': {
          display: 'inline-block',
        },
        '&:link': {
          color: '#000',
        },
        '&:visited': {
          color: '#000',
        },
        '@media screen and (max-width:1200px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  arrow: {
    backgroundColor: theme.palette.primary.main,
    marginTop: '64px',
    marginRight: 0,
    marginLeft: 'auto',
    width: '184px',
  },
}));

const Media = () => {
  const [image, setImage] = useState();

  const [loading, setLoading] = useState(true);
  const [view, setView] = useState();
  const [contents, setContents] = useState();

  const location = useLocation();
  const classes = useStyles();

  const mediaAreaTitle = {
    titleList: ['Media'],
    sub: 'メディア掲載事例',
  };

  // OGP設定
  const ogp = useCallback((props) => {
    ogpGenerator(props);
  }, []);

  useLayoutEffect(() => {
    if (image) {
      const ogpProps = {
        page: 'media',
        pageTitle: '刀禪OfficialWebSiteメディア掲載事例紹介',
        path: location.pathname,
        imageUrl: image.OfficialImage_TouzenLogoBlack.url,
      };
      ogp(ogpProps);
    }
  }, [image, location, ogp]);
  // OGP設定ここまで

  const callback = useCallback(() => {
    const result = [];
    const contentElements = [
      {
        id: 'media1',
        mediaImage: image.MediaPageImages_shin_karate_do.url,
        mediaLink: '/media/shin-karate-do/',
        imageAlt: 'media front',
        readText: `新・空手道第3号では、空手界のレジェンドでもあり小用に師事される二人、極真空手元軽重量級世界チャンピョン髙久昌義氏、極真空手元重量級世界チャンピョン数見肇氏との座談会が催されました。今回は特別に、「新・空手道」編集人でもあり、刀禅生徒でもあり、世界一の空手古書蒐集家（当会調べ）でもある藍原氏のご厚意により、本サイトのみこの座談会企画コンテンツの掲載を快く許可していただいたので、ここに掲載します。どうぞお楽しみください。`,
        editorImage: image.MediaPageImages_aihara.url,
        editorImageAlt: 'aihara',
        editorName: '藍原しんや',
        editorSns: [
          {
            key: 'facebook',
            url: 'https://www.facebook.com/groups/3386816158019324/user/100000366154049',
          },
        ],
        editorCareer: 'Editor／ 空手古書道連盟主宰／空手古書蒐集家',
        editorIntro: `刀禅発足以前から小用とは同じ出版業会の仲間として旧知の中であった。刀禅発足後は一生徒として仕事にも稽古にも心血を注ぐ。 類いまれなる空手愛により、その蔵書はゆうに500箱（500冊ではない）を超え、止まることを知らない。2017年に竣工した 沖縄空手会館からも請われ、様々な協力と資料提供をした。`,
        links: [
          { name: '新・空手道', url: 'http://shinkaratedou.com/' },
          { name: '錬空武館 髙久道場', url: 'https://takaku-dojo.com/' },
          { name: '数見道場', url: 'http://www.kazumi-dojo.jp/' },
        ],
        buttonPath: { inner: true, url: '/media/shin-karate-do/' },
      },
      {
        id: 'media2',
        mediaImage: image.MediaPageImages_ko2.url,
        mediaLink:
          'https://www.ko2.tokyo/?s=%E5%AF%BE%E8%AB%87%E3%80%80%E8%97%A4%E7%94%B0%E4%B8%80%E7%85%A7%C3%97%E5%B0%8F%E7%94%A8%E8%8C%82%E5%A4%AB',
        imageAlt: 'media front',
        readText: `武術関連のコンテンツはもとより、身体関連の非常にディープな話を見聞できるサイト「コ2」。2017年には、 藤田一照禅師と小用との対談企画が催されました。先達同士の対談は必見です。「禅的」なアプローチに関心がある方は是非とも熟読されたし。ここまで本質的な対談には なかなかお目にかかれる機会はないでしょう。どうぞお楽しみください。`,
        editorImage: image.MediaPageImages_shimomura.url,
        editorImageAlt: 'shimomura',
        editorName: '下村敦夫',
        editorSns: [
          { key: 'facebook', url: 'https://www.facebook.com/ko2.web' },
        ],
        editorCareer: 'Editor／ コ2発行人',
        editorIntro: `下村氏も藍原氏同様、刀禅発足以前から小用とは同じ出版業会の仲間として旧知の中であった。 刀禅発足後は一生徒として研鑽に励む。また、武術界隈のみならず格闘技業界も含め多くの知古を持ち 、編集者として数々の武的関連著作を手がける。`,
        links: [{ name: 'コ2[KOTSU]', url: 'https://www.ko2.tokyo/' }],
        buttonPath: {
          inner: false,
          url: 'https://www.ko2.tokyo/?s=%E5%AF%BE%E8%AB%87%E3%80%80%E8%97%A4%E7%94%B0%E4%B8%80%E7%85%A7%C3%97%E5%B0%8F%E7%94%A8%E8%8C%82%E5%A4%AB',
        },
      },
    ];
    contentElements.forEach((elm, index) => {
      let buttonElement;
      if (elm.buttonPath.inner) {
        buttonElement = (
          <Link to={elm.buttonPath.url}>
            <ArrowButton color="#FFF" buttonName="GO TO CONTENT" />
          </Link>
        );
      } else {
        buttonElement = (
          <a href={elm.buttonPath.url}>
            <ArrowButton color="#FFF" buttonName="GO TO CONTENT" />
          </a>
        );
      }
      result.push(
        <div key={`${elm.id}_${index}`} className={classes.container}>
          <div className={classes.image}>
            <a href={elm.mediaLink}>
              <img src={elm.mediaImage} alt={elm.imageAlt} />
            </a>
          </div>
          <div className={classes.editorWrapper}>
            <div className={classes.editorRead}>
              <span>{elm.readText}</span>
            </div>
            <div className={classes.editorContentWrapper}>
              <div className={classes.editorImageWrapper}>
                <div className={classes.editorImage}>
                  <img src={elm.editorImage} alt={elm.imageAlt} />
                </div>
                <div className={classes.editorContent}>
                  <div className={classes.editorName}>
                    <span>{elm.editorName}</span>
                    {elm.editorSns.map((sns, i) => {
                      let result;
                      switch (sns.key) {
                        case 'facebook':
                          result = (
                            <a key={i} href={sns.url}>
                              <img
                                src={imageIndex.mediaPage.fbIcon}
                                alt="fbIcon"
                              />
                            </a>
                          );
                          break;
                        default:
                      }
                      return result;
                    })}
                  </div>
                  <div className={classes.editorCareer}>
                    <span>{elm.editorCareer}</span>
                  </div>
                  <p>{elm.editorIntro}</p>
                </div>
              </div>
              <div className={classes.links}>
                <span>Links</span>
                <div>
                  {elm.links.map((link, _i) => {
                    return (
                      <a key={_i} href={link.url}>
                        <span>{link.name}</span>
                        <span>{link.url}</span>
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.arrow}>{buttonElement}</div>
        </div>
      );
    });
    return <>{result}</>;
    // eslint-disable-next-line
  }, [classes, image]);

  useEffect(() => {
    imageApi(setImage);
  }, []);

  useEffect(() => {
    if (image) {
      setView(true);
      setLoading(false);
    }
  }, [image]);

  useEffect(() => {
    if (view) {
      setContents(callback());
    }
  }, [view, callback]);

  return (
    <>
      {loading ? null : (
        <>
          <div>
            <OnOffByScroll place="General" />
          </div>
          <div className={classes.contentTitle}>
            <ContentTitle title={mediaAreaTitle} link={various.general.media} />
          </div>
          {contents}
          <Footer />
        </>
      )}
    </>
  );
};
export default Media;
