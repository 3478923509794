import various from '../General/various';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& #title': {
      color: '#FFF',
      marginBottom: '24px',
      fontWeight: 'bold',
      textAlign: 'start',
    },
    '& #qlContent': {
      display: 'flex',
      flexWrap: 'nowrap',
      fontSize: '1rem',
      gap: '0px 32px',
      paddingLeft: '8px',
      '& > div': {
        '& > div': {
          marginTop: '8px',
          marginBottom: '8px',
          textAlign: 'start',
          '& > a': {
            color: '#FFF',
            '&:hover': {
              color: 'black',
            },
          },
        },
      },
      '@media screen and (max-width:480px)': {
        gap: '0px 56px',
      },
    },
  },
}));

const QuickLinks = () => {
  const isAuth = window.localStorage.getItem('Authorization');
  const classes = useStyles();
  const leftUrls = various.footerLeftUrls;
  const rightUrls = various.footerRightUrls
    .map((url) => {
      if (isAuth && url.name !== 'LOGIN') {
        return url;
      } else if (!isAuth && url.name !== 'LOGOUT') {
        return url;
      }
      return null;
    })
    .filter((url) => url);

  const routing = (urls) => {
    const result = [];
    urls.forEach((url, index) => {
      result.push(
        <div key={index}>
          <a href={url.path}>{url.name}</a>
        </div>
      );
    });
    return result;
  };

  return (
    <div className={classes.container}>
      <div id="title">QuickLinks</div>
      <div id="qlContent">
        <div>{routing(leftUrls)}</div>
        <div>{routing(rightUrls)}</div>
      </div>
    </div>
  );
};

export default QuickLinks;
