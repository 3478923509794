import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import { updateInstance } from '../../../Apis/apiHandlers';
import {
  documentIndex,
  instructorIndexForAdmin,
  productMasterIndex,
  seminarDetailForAdmin,
  tagIndexForAdmin,
  trainingHallIndex,
  trainingPlaceIndex,
  getSeminarOrganizers,
} from '../../../Apis/apiOfManaged';
import { images as imageApi } from '../../../Apis/apis';
import { AuthContext } from '../../../auth';
import { selectDefaultCheck } from '../../../CommonFunction/validators';
import Footer from '../../../Footer/footer';
import { formatDate } from '../../../General/clock';
import various from '../../../General/various';
import SitemapGenerator from '../../../sitemapGenerator';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  titles: {},
  inputs: {},
  helptext: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  container: {
    marginTop: '168px',
    width: '100%',
    '& > form': {
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '& > img': {
            maxWidth: '300px',
            maxHeight: '300px',
            objectFit: 'contain',
          },
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > label': {
            width: '100%',
            textAlign: 'end',
            '@media screen and (max-width:750px)': {
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
          '& > span': {
            color: 'red',
          },
        },

        '& $inputs': {
          width: '70%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input, & > textarea, & > select': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
          '& > textarea': {
            minHeight: '160px',
          },
          '& > input[type="checkbox"]': {
            boxShadow: 'none',
          },
          '& $helptext': {
            fontSize: '0.8rem',
            color: 'grey',
            '& > a': {
              textDecoration: 'underline',
              '&:link': {
                color: theme.palette.primary.main,
              },
            },
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    marginTop: '88px !important',
    justifyContent: 'end',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const SeminarUpdateForAdmin = () => {
  const {
    register,
    reset,
    handleSubmit,
    getValues,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const { isAuth } = useContext(AuthContext);
  const [seminar, setSeminar] = useState();
  const [tags, setTags] = useState();
  const [places, setPlaces] = useState();
  const [place, setPlace] = useState();
  const [halls, setHalls] = useState();
  const [targetHalls, setTargetHalls] = useState();
  const [instructors, setInstructors] = useState();
  const [organizers, setOrganizers] = useState();
  const [documents, setDocuments] = useState();
  const [_documents, _setDocuments] = useState();
  const [products, setProducts] = useState();
  const [eventProducts, setEventProducts] = useState();
  const [push, setPush] = useState(false);
  const [uuid, setUuid] = useState();
  const [seminarProducts, setSeminarProducts] = useState();
  const [basicProducts, setBasicProducts] = useState();
  const [komaNum, setKomaNum] = useState();

  const [images, setImages] = useState();
  const [iconImages, setIconImages] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: 'セミナー一覧', path: '/seminar_index' },
    { name: 'セミナー更新', path: '/seminar_update_for_admin' },
  ];

  // Init
  useEffect(() => {
    if (images) {
      const result = [];
      Object.keys(images).forEach((key) => {
        if (images[key].use_place === 'Seminar') {
          result.push(images[key]);
        }
      });
      setIconImages(result);
    }
  }, [images]);

  useEffect(() => {
    if (location) {
      seminarDetailForAdmin(setSeminar, location.state.uuid);
      tagIndexForAdmin(setTags);
      trainingPlaceIndex(setPlaces);
      trainingHallIndex(setHalls);
      instructorIndexForAdmin(setInstructors);
      getSeminarOrganizers(setOrganizers);
      documentIndex(_setDocuments);
      imageApi(setImages);
      productMasterIndex(setProducts);
    }
  }, [location]);

  useEffect(() => {
    if (products) {
      setEventProducts(
        products.filter((product) => {
          return (
            product.product_type === 'payment' &&
            (product.product_category === 'event' ||
              product.product_category === 'basic') &&
            product.is_active
          );
        })
      );
    }
  }, [products]);

  useEffect(() => {
    if (_documents) {
      setDocuments(
        _documents.filter((_doc) => {
          return _doc.is_inuse === true;
        })
      );
    }
  }, [_documents]);

  useEffect(() => {
    if (seminar && iconImages) {
      const rejectList = [
        'uuid',
        // 'organizer',
        'news',
        'num_of_applicans',
        // 'part_three_start_time',
        // 'part_three_end_time',
        // 'part_four_start_time',
        // 'part_four_end_time',
        'part_one_current_num',
        'part_two_current_num',
        'part_three_current_num',
        'part_four_current_num',
        'num_of_applicans',
      ];
      const keys = Object.keys(seminar);
      const resetValues = {};
      keys.forEach((k) => {
        if (!rejectList.includes(k)) {
          if (k === 'tag') {
            resetValues[k] = seminar[k].map((t) => {
              return t.name;
            });
          } else if (k === 'image') {
            iconImages.forEach((image) => {
              if (image.url === seminar[k]) {
                resetValues[k] = image.title;
              }
            });
          } else if (k === 'place') {
            resetValues[k] = seminar[k].facility_name;
            setPlace(seminar[k]);
          } else if (k === 'hall') {
            resetValues[k] = seminar[k].name;
          } else if (k === 'instructor') {
            resetValues[k] = seminar[k].name;
          } else if (k === 'organizer') {
            resetValues[k] = seminar[k].email;
          } else if (k === 'documents') {
            resetValues[k] = seminar[k].title ? seminar[k].title : 'choice';
          } else if (k === 'event_date_start_time') {
            resetValues[k] = formatDate(new Date(seminar[k]), 'date');
          } else if (k.includes('part_')) {
            if (seminar[k]) {
              resetValues[k] = new Date(seminar[k]).toLocaleTimeString();
            }
          } else if (k === 'delivery_date' || k === 'distribution_date') {
            let dt;
            if (seminar[k]) {
              dt = formatDate(new Date(seminar[k]), 'datetime');
            }
            resetValues[k] = dt;
          } else if (k === 'products') {
            resetValues[k] = seminar[k].map((product) => {
              console.log('product.management_tags: ', product.management_tags);
              return product.management_tags;
            });
          } else if (k === 'payment_method') {
            if (seminar.payment_method) {
              const payment_method = seminar[k];
              resetValues[k] = payment_method.is_mixed
                ? 'is_mixed'
                : payment_method.is_only_cash
                ? 'is_only_cash'
                : payment_method.is_only_credit_card
                ? 'is_only_credit_card'
                : null;
            } else {
              resetValues[k] = 'choice';
            }
          } else {
            resetValues[k] = seminar[k];
          }
        }
      });
      reset(resetValues);
      setUuid(seminar.uuid);
    }
  }, [seminar, iconImages, documents, reset]);

  useEffect(() => {
    if (place && halls) {
      const targets = halls
        .filter((hall) => {
          return place.uuid === hall.training_place;
        })
        .map((hall) => {
          return hall;
        });
      setTargetHalls(targets);
    }
  }, [place, halls]);

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleChange = (e) => {
    const place = places.filter((place) => {
      return e.target.value === place.facility_name;
    })[0];
    setPlace(place);
  };

  // if (products) console.log('products: ', products);
  // if (tags) console.log('tags: ', tags);
  // if (komaNum) console.log('komaNum', komaNum);
  // if (seminar) console.log('seminar', seminar);

  // Submit
  const onSubmit = handleSubmit(async (data) => {
    // ここではイメージファイルを直接渡さないので、noImageのプロセスで登録（その代わりにimage.uuidを渡す）

    data['uuid'] = location.state.uuid;

    // Imageの下拵え
    const imageFileKey = 'image';
    iconImages.forEach((image) => {
      if (image.title === data[imageFileKey]) data['image_uuid'] = image.uuid;
    });

    // Documentsがある場合の表記の調整
    const docs = documents.filter((document) => {
      return document.title === data['documents'];
    });
    data['documents'] = docs.length > 0 ? docs[0].uuid : null;
    if (docs.length > 0 && docs[0].is_inuse) {
      const text = '\n※本セミナーには配布資料があります。';
      if (!data['notes'].includes('※本セミナーには配布資料があります。')) {
        data['notes'] += text;
      }
    }

    if (data.guidance.length < 2) {
      data.guidance = various.operationalText;
    }

    const time_list = [
      'part_one_start_time',
      'part_one_end_time',
      'part_two_start_time',
      'part_two_end_time',
      'part_three_start_time',
      'part_three_end_time',
      'part_four_start_time',
      'part_four_end_time',
    ];
    time_list.forEach((t) => {
      if (data[t]) {
        if (data[t].length < 8) {
          data[t] += ':00';
        }
      }
    });

    // Hallの調整
    const sabmitHallData = {
      name: data['hall'],
      training_place: data['place'],
    };
    data['hall'] = sabmitHallData;

    // Tagの調整
    if (data.subject === 'すべての方') {
      if (!data.tag.includes('一般向け')) {
        data.tag.push('一般向け');
      }
      if (data.tag.includes('会員向け')) {
        const index = data.tag.indexOf('会員向け');
        data.tag.splice(index, 1);
      }
    } else if (data.subject === '会員') {
      if (!data.tag.includes('会員向け')) {
        data.tag.push('会員向け');
      }
      if (data.tag.includes('一般向け')) {
        const index = data.tag.indexOf('一般向け');
        data.tag.splice(index, 1);
      }
    }

    // TagのUUID化
    let tagUuids = [];
    tags.forEach((tag) => {
      if (data['tag'].includes(tag.name)) tagUuids.push(tag.uuid);
    });
    data['tag'] = tagUuids;

    // ProductMasterのUUID化
    let productUuids = [];
    products.forEach((product) => {
      if (data['products'].includes(product.management_tags))
        productUuids.push(product.uuid);
    });
    data['products'] = productUuids;

    // console.log('data: ', data);
    try {
      await updateInstance('Seminar', data, imageFileKey);
      setPush(true);
      console.log('update finish!');
    } catch (error) {
      console.log(error);
    }
  });

  // Util
  const dependentValidation = (value) => {
    const otherFieldValue = getValues('distribution_is');
    if (otherFieldValue && !value) {
      return 'ニュース配信をする場合は、必ず「配信予定日時」を設定してください';
    } else if (!otherFieldValue && value) {
      return 'ニュース配信をしない場合は、「配信予定日時」に日時を入れないでください';
    }
    return true;
  };

  function arraysAreEqual(array1, array2) {
    // まず、長さが異なる場合は、配列が異なると判断
    if (array1.length !== array2.length) {
      return false;
    }

    // 配列をソートしてから、各要素を比較
    array1.sort();
    array2.sort();

    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        // 一つでも違う要素があれば、配列は異なる
        return false;
      }
    }

    // すべての要素が一致していれば、配列は同じ
    return true;
  }

  function isSubset(array1, array2) {
    // array1 の各要素が array2 に含まれているか確認
    return array1.every((element) => array2.includes(element));
  }

  useEffect(() => {
    if (products) {
      // seminar_products_nameは、回数会員がセミナー申し込みの際に決済させるマスターと定例会員がセミナー申し込みの際に決済させるマスターの配列。基本的には二つを想定。
      setSeminarProducts(
        products.filter((product) => {
          return (
            product.product_category === 'event' &&
            product.product_type === 'payment' &&
            product.is_active
          );
        })
      );

      // basic_productは、回数会員が通常稽古の申し込みの際に決済する商品マスターであり、基本的には一つしかない状況を想定。
      setBasicProducts(
        products.filter((product) => {
          return (
            product.product_category === 'basic' &&
            product.product_type === 'payment' &&
            product.is_active
          );
        })
      );
    }
  }, [products]);

  useEffect(() => {
    // koma4は懇親会用なのでカウントせず
    const koma1 = watch('part_one_start_time');
    let koma2 = watch('part_two_start_time');
    koma2 = koma2?.slice(0, 2) === '23' ? null : koma2;
    const koma3 = watch('part_three_start_time');
    const result = [koma1, koma2, koma3].filter((koma) => {
      return koma;
    });
    setKomaNum(result);
  }, [
    // eslint-disable-next-line
    watch,
    // eslint-disable-next-line
    watch('part_one_start_time'),
    // eslint-disable-next-line
    watch('part_two_start_time'),
    // eslint-disable-next-line
    watch('part_three_start_time'),
    // eslint-disable-next-line
    watch('part_four_start_time'),
  ]);

  // productsフィールドを状況に応じて変更する
  useEffect(() => {
    // 現在のフォームの値を取得
    const currentValues = getValues();

    const kind = watch('seminar_type');
    const productsValues = watch('products');
    const membership_fee = parseInt(watch('membership_fee'), 10);

    if (
      membership_fee === 0 &&
      currentValues.products[0] !== 'event_seminar_000'
    ) {
      const updateValues = {
        ...currentValues,
        products: ['event_seminar_000'],
        membership_fee: 0,
      };
      reset(updateValues);
    } else if (
      membership_fee !== 0 &&
      seminarProducts &&
      basicProducts &&
      komaNum
    ) {
      const unitPrice = parseInt(membership_fee / komaNum.length, 10);
      const halfPrice = parseInt(unitPrice / 2, 10);

      // console.log('productsValues', productsValues);
      // console.log('currentValues.products', currentValues.products);
      // console.log('membership_fee', membership_fee);
      // console.log('unitPrice', unitPrice);
      // console.log('halfPrice', halfPrice);
      // console.log('seminarProducts', seminarProducts);
      // console.log(
      //   'seminarProducts',
      //   seminarProducts.map((p) => p.price)
      // );

      let seminar_products_management_tags;
      if (kind === 'seminar') {
        if (seminarProducts.length <= 2) {
          seminar_products_management_tags = seminarProducts.map((product) => {
            return product.management_tags;
          });
        } else if (seminarProducts.length > 2) {
          seminar_products_management_tags = seminarProducts
            .filter(
              (product) =>
                (!product.is_half_model &&
                  product.price === unitPrice &&
                  product.price * komaNum.length === membership_fee) ||
                (product.is_half_model &&
                  product.price === halfPrice &&
                  product.price * komaNum.length === membership_fee / 2)
            )
            .map((product) => product.management_tags);
        } else {
          setError('products', {
            type: 'custom',
            message: `設定する商品マスターが2つになりません。商品マスター一覧を確認し登録・更新をするか管理者へご一報ください。`,
          });
        }
        if (
          !arraysAreEqual(
            currentValues.products,
            seminar_products_management_tags
          )
        ) {
          const updateValues = {
            ...currentValues,
            products: seminar_products_management_tags,
          };
          reset(updateValues);
        } else {
          clearErrors('products');
          // console.log('clear');
        }
      } else if (kind === 'event') {
        const basic_product_management_tags = basicProducts.map((product) => {
          return product.management_tags;
        });
        // console.log('basic_product_management_tags: ', basic_product_management_tags);
        // console.log('productsValues: ', productsValues);
        if (!arraysAreEqual(productsValues, basic_product_management_tags)) {
          const updateValues = {
            ...currentValues,
            products: basic_product_management_tags,
          };
          reset(updateValues);
        } else {
          clearErrors('products');
          // console.log('clear');
        }
      }
    }
  }, [
    seminarProducts,
    basicProducts,
    komaNum,
    reset,
    getValues,
    setError,
    watch,
    // eslint-disable-next-line
    watch('membership_fee'),
    // eslint-disable-next-line
    watch('seminar_type'),
    // eslint-disable-next-line
    watch('products'),
    clearErrors,
  ]);

  // productsフィールド以外のフィールドの状況に応じて、productsフィールドにエラーを出す
  useEffect(() => {
    const membership_fee = parseInt(watch('membership_fee'), 10);
    const kind = watch('seminar_type');

    if (membership_fee === 0) {
      console.log('do nothing');
    } else if (seminarProducts && basicProducts && komaNum) {
      const unitPrice = membership_fee / komaNum.length;
      const seminar_products_price = seminarProducts
        .filter((product) => {
          return !product.is_half_model && product.price === unitPrice;
        })
        .map((product) => {
          return product.price;
        })[0];

      if (kind === 'seminar') {
        const totalFee = parseInt(seminar_products_price * komaNum.length, 10);
        if (membership_fee !== totalFee) {
          setError('products', {
            type: 'custom',
            message: `設定されている会員参加費と選択した商品マスターの金額が一致しません。///`,
          });
        }
      } else if (kind === 'event') {
        const basic_product_price = basicProducts.map((product) => {
          return product.price;
        })[0];

        const totalFee = parseInt(basic_product_price * komaNum.length, 10);
        // console.log('totalFee: ', totalFee);
        // console.log('membership_fee: ', membership_fee);
        if (membership_fee !== totalFee) {
          console.log('error');
          setError('products', {
            type: 'custom',
            message: `設定されている会員参加費と選択した商品マスターの金額が一致しません。/`,
          });
        }
      } else {
        clearErrors('products');
      }
    }
  }, [
    seminarProducts,
    basicProducts,
    komaNum,
    watch,
    // eslint-disable-next-line
    watch('membership_fee'),
    // eslint-disable-next-line
    watch('products'),
    setError,
    clearErrors,
  ]);

  // productsフィールドのバリデーション
  // 上記のuseEffectでproductsフィールド以外に依存してエラーを出しているが、バリデーターにはならないのでここでバリデーターを定義する
  const integrityCheckForProductMaster = (value) => {
    const kind = watch('seminar_type');
    const price = parseInt(watch('membership_fee'), 10);
    const unitPrice = price / komaNum.length;

    // validation target
    const seminar_products_management_tags = seminarProducts.map((product) => {
      return product.management_tags;
    });

    const seminar_products_price = seminarProducts
      .filter((product) => {
        return !product.is_half_model && product.price === unitPrice;
      })
      .map((product) => {
        return product.price;
      })[0];

    const basic_product_management_tags = basicProducts.map((product) => {
      return product.management_tags;
    });

    const basic_product_price = basicProducts.map((product) => {
      return product.price;
    })[0];

    // validation
    if (kind === 'seminar') {
      if (!isSubset(value, seminar_products_management_tags)) {
        return `セミナーの場合は、『${seminar_products_management_tags}』の中から条件に合致する二つを選択してください。`;
      }

      const totalFee = parseInt(seminar_products_price * komaNum.length, 10);
      if (price !== totalFee) {
        return `設定されている会員参加費と選択した商品マスターの金額が一致しません。====`;
      }
    } else if (kind === 'event') {
      if (price === 0) return;
      if (!arraysAreEqual(value, basic_product_management_tags)) {
        return `通常稽古の場合は、『${basic_product_management_tags[0]}』のみを選択してください。`;
      }

      const totalFee = parseInt(basic_product_price * komaNum.length, 10);
      if (price !== totalFee) {
        return `設定されている会員参加費と選択した商品マスターの金額が一致しません。`;
      }
    }
  };

  // zero price seminar or event の場合、seminar_typeをEventに変更する
  useEffect(() => {
    const product_tag = watch('products');
    const seminar_type = watch('seminar_type');
    if (product_tag && product_tag.length === 1 && seminar_type === 'seminar') {
      const product = product_tag[0];

      if (product && product === 'event_seminar_000') {
        reset({ seminar_type: 'event' });
      }
    }
  }, [
    watch,
    // eslint-disable-next-line
    watch('products'),
    reset,
  ]);

  // Contents
  const form = () => {
    if (
      seminar &&
      iconImages &&
      instructors &&
      organizers &&
      place &&
      targetHalls
    ) {
      return (
        <>
          <div className={classes.container}>
            <form
              method="POST"
              encType="multipart/form-data"
              onSubmit={onSubmit}
            >
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.title}>
                    {various.seminarHeaders.title}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="title"
                    type="text"
                    {...register('title', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.title?.message && (
                    <div className={classes.error}>{errors.title.message}</div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.serial_number}>
                    {various.seminarHeaders.serial_number}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="serial_number"
                    type="number"
                    {...register('serial_number', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    連続セミナーの場合は、通し番号をご記載ください。なお、そうではない場合は空欄で構いません。
                  </div>
                  {errors.serial_number?.message && (
                    <div className={classes.error}>
                      {errors.serial_number.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.seminar_type}>
                    {various.seminarHeaders.seminar_type}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <select
                    id="seminar_type"
                    defaultValue="choice"
                    {...register('seminar_type', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          selectDefaultCheck(v)
                            ? null
                            : `いずれかをご選択ください。選択肢に無い場合は管理者へご要請ください。`,
                      },
                    })}
                  >
                    <option value="choice">選択してください</option>
                    <option value="seminar">セミナー</option>
                    <option value="event">通常稽古</option>
                  </select>
                  {errors.seminar_type?.message && (
                    <div className={classes.error}>
                      {errors.seminar_type.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.subject}>
                    {various.seminarHeaders.subject}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <select
                    id="subject"
                    defaultValue="choice"
                    {...register('subject', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          selectDefaultCheck(v)
                            ? null
                            : `いずれかをご選択ください。選択肢に無い場合は、会場登録をしていただくか管理者へご要請ください。`,
                      },
                    })}
                  >
                    <option value="choice">選択してください</option>
                    <option value="すべての方">すべての方</option>
                    <option value="会員">会員</option>
                  </select>
                  {errors.subject?.message && (
                    <div className={classes.error}>
                      {errors.subject.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.place}>
                    {various.seminarHeaders.place}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <select
                    id="place"
                    defaultValue="choice"
                    {...register('place', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          selectDefaultCheck(v)
                            ? null
                            : `いずれかをご選択ください。選択肢に無い場合は、会場登録をしていただくか管理者へご要請ください。`,
                      },
                      onChange: handleChange,
                    })}
                  >
                    <option value="choice">選択してください</option>
                    {places
                      ? places.map((place, i) => {
                          return (
                            <option
                              key={place.uuid}
                              id={`choice_${place.uuid}`}
                              value={place.facility_name}
                            >
                              {place.facility_name}
                            </option>
                          );
                        })
                      : undefined}
                  </select>
                  <div className={classes.helptext}>
                    セミナー会場施設情報をご選択ください。なお、選択肢にない場合は
                    <a href={various.general.placeAndHallIndex}>こちら</a>
                    のページで登録してください。
                  </div>
                  {errors.place?.message && (
                    <div className={classes.error}>{errors.place.message}</div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.hall}>
                    {various.seminarHeaders.hall}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <select
                    id="hall"
                    defaultValue="choice"
                    {...register('hall', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          selectDefaultCheck(v)
                            ? null
                            : `いずれかをご選択ください。選択肢に無い場合は、会場登録をしていただくか管理者へご要請ください。`,
                      },
                    })}
                  >
                    <option value="choice">選択してください</option>
                    {targetHalls
                      ? targetHalls.map((hall, i) => {
                          return (
                            <option
                              key={hall.uuid}
                              id={`choice_${hall.uuid}`}
                              value={hall.name}
                            >
                              {hall.name}
                            </option>
                          );
                        })
                      : undefined}
                  </select>
                  <div className={classes.helptext}>
                    セミナー会場施設内にある、具体的な稽古場（部屋）情報をご選択ください。なお、選択肢にない場合は
                    <a href={various.general.placeAndHallIndex}>こちら</a>
                    のページで登録してください。
                  </div>
                  {errors.hall?.message && (
                    <div className={classes.error}>{errors.hall.message}</div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.capacity}>
                    {various.seminarHeaders.capacity}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="capacity"
                    type="number"
                    {...register('capacity', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    「1コマ」あたりの許容人数を設定してください。なお、申込者数が許容人数を超えると、開催前であっても申し込みフォームはクローズします。
                  </div>
                  {errors.capacity?.message && (
                    <div className={classes.error}>
                      {errors.capacity.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.instructor}>
                    {various.seminarHeaders.instructor}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <select
                    id="instructor"
                    defaultValue="choice"
                    {...register('instructor', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          selectDefaultCheck(v)
                            ? null
                            : `いずれかをご選択ください。選択肢に無い場合は、講師登録をしていただくか管理者へご要請ください。`,
                      },
                    })}
                  >
                    <option value="choice">選択してください</option>
                    {instructors
                      ? instructors.map((instructor, i) => {
                          return (
                            <option
                              key={instructor.uuid}
                              id={`choice_${instructor.uuid}`}
                              value={instructor.name}
                            >
                              {instructor.name}
                            </option>
                          );
                        })
                      : undefined}
                  </select>
                  <div className={classes.helptext}>
                    講師となられる指導者をご選択ください。なお、選択肢にない場合は
                    <a href={various.general.instructorIndex}>こちら</a>
                    のページからご登録ください。
                  </div>
                  {errors.instructor?.message && (
                    <div className={classes.error}>
                      {errors.instructor.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.organizer}>
                    {various.seminarHeaders.organizer}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <select
                    id="organizer"
                    defaultValue="choice"
                    {...register('organizer', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          selectDefaultCheck(v)
                            ? null
                            : `いずれかをご選択ください。選択肢に無い場合は、会員の中から主催者設定をしていただくか管理者へご要請ください。`,
                      },
                    })}
                  >
                    <option value="choice">選択してください</option>
                    {organizers
                      ? organizers.map((organizer, i) => {
                          return (
                            <option
                              key={organizer.uuid}
                              id={`choice_${organizer.uuid}`}
                              value={organizer.email}
                            >
                              {organizer.name}
                            </option>
                          );
                        })
                      : undefined}
                  </select>
                  <div className={classes.helptext}>
                    このセミナー・講習会の主催者をご選択ください。なお、選択肢にない場合は会員ステータスの更新画面で主催者として設定してください。
                  </div>
                  {errors.organizer?.message && (
                    <div className={classes.error}>
                      {errors.organizer.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.event_date_start_time}>
                    {various.seminarHeaders.event_date_start_time}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="event_date_start_time"
                    type="date"
                    {...register('event_date_start_time', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.event_date_start_time?.message && (
                    <div className={classes.error}>
                      {errors.event_date_start_time.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.part_one_start_time}>
                    {various.seminarHeaders.part_one_start_time}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="part_one_start_time"
                    type="time"
                    {...register('part_one_start_time', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    第1部の「開始時刻」ご記載ください。
                  </div>
                  {errors.part_one_start_time?.message && (
                    <div className={classes.error}>
                      {errors.part_one_start_time.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.part_one_end_time}>
                    {various.seminarHeaders.part_one_end_time}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="part_one_end_time"
                    type="time"
                    {...register('part_one_end_time', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    第1部の「終了時刻」ご記載ください。
                  </div>
                  {errors.part_one_end_time?.message && (
                    <div className={classes.error}>
                      {errors.part_one_end_time.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.part_two_start_time}>
                    {various.seminarHeaders.part_two_start_time}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="part_two_start_time"
                    type="time"
                    {...register('part_two_start_time', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    第2部の「開始時刻」ご記載ください。なお、第2部が存在しない場合、お手数ですが「23:59」を設定してください。
                  </div>
                  {errors.part_two_start_time?.message && (
                    <div className={classes.error}>
                      {errors.part_two_start_time.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.part_two_end_time}>
                    {various.seminarHeaders.part_two_end_time}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="part_two_end_time"
                    type="time"
                    {...register('part_two_end_time', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    第2部の「終了時刻」ご記載ください。なお、第2部が存在しない場合、お手数ですが「23:59」を設定してください。
                  </div>
                  {errors.part_two_end_time?.message && (
                    <div className={classes.error}>
                      {errors.part_two_end_time.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.part_three_start_time}>
                    {various.seminarHeaders.part_three_start_time}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="part_three_start_time"
                    type="time"
                    {...register('part_three_start_time', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.part_three_start_time?.message && (
                    <div className={classes.error}>
                      {errors.part_three_start_time.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.part_three_end_time}>
                    {various.seminarHeaders.part_three_end_time}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="part_three_end_time"
                    type="time"
                    {...register('part_three_end_time', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.part_three_end_time?.message && (
                    <div className={classes.error}>
                      {errors.part_three_end_time.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.part_four_start_time}>
                    {various.seminarHeaders.part_four_start_time}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="part_four_start_time"
                    type="time"
                    {...register('part_four_start_time', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.part_four_start_time?.message && (
                    <div className={classes.error}>
                      {errors.part_four_start_time.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.part_four_end_time}>
                    {various.seminarHeaders.part_four_end_time}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="part_four_end_time"
                    type="time"
                    {...register('part_four_end_time', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    懇親会の会場情報や参加費用については、本サイトではサポートできていません。必要に応じて、適宜注意事項欄等でアナウンスしてください。
                  </div>
                  {errors.part_four_end_time?.message && (
                    <div className={classes.error}>
                      {errors.part_four_end_time.message}
                    </div>
                  )}
                </div>
              </div>

              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.entry_fee}>
                    {various.seminarHeaders.entry_fee}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="entry_fee"
                    type="number"
                    {...register('entry_fee', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    一般参加者が支払う「1日を通した参加費」をご記載ください。なお、先の「対象」欄で「会員」を選択された場合は、当該一般参加費は必ず「0」にしてください。
                  </div>
                  {errors.entry_fee?.message && (
                    <div className={classes.error}>
                      {errors.entry_fee.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.membership_fee}>
                    {various.seminarHeaders.membership_fee}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="membership_fee"
                    type="number"
                    {...register('membership_fee', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    会員が支払う「1日を通した参加費」をご記載ください。
                  </div>
                  {errors.membership_fee?.message && (
                    <div className={classes.error}>
                      {errors.membership_fee.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.payment_method}>
                    {various.seminarHeaders.payment_method}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <select
                    id="payment_method"
                    defaultValue="choice"
                    {...register('payment_method', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          selectDefaultCheck(v)
                            ? null
                            : `いずれかをご選択ください。選択肢に無い場合は管理者へご要請ください。`,
                      },
                    })}
                  >
                    <option value="choice">選択してください</option>
                    <option value="is_mixed">全支払方法OK</option>
                    <option value="is_only_cash">現金決済</option>
                    <option value="is_only_credit_card">
                      クレジットカード決済
                    </option>
                  </select>
                  <div className={classes.helptext}>
                    このセミナー・講習会の支払方法を選択してください。
                  </div>
                  {errors.payment_method?.message && (
                    <div className={classes.error}>
                      {errors.payment_method.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.guidance}>
                    {various.seminarHeaders.guidance}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <textarea
                    id="guidance"
                    type="textarea"
                    {...register('guidance', {
                      required: { value: true, message: '入力必須の項目です' },
                      maxLength: {
                        value: 2000,
                        message: '2000字以内でお願いします',
                      },
                    })}
                  />
                  <div className={classes.helptext}>
                    当セミナーについてのガイダンスをご記載ください。
                  </div>
                  {errors.guidance?.message && (
                    <div className={classes.error}>
                      {errors.guidance.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.notes}>
                    {various.seminarHeaders.notes}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <textarea
                    id="notes"
                    type="textarea"
                    {...register('notes', {
                      required: { value: false, message: '入力必須の項目です' },
                      maxLength: {
                        value: 500,
                        message: '500字以内でお願いします',
                      },
                    })}
                  />
                  <div className={classes.helptext}>
                    持ち物や履き物等、何か注意事項がありましたらご記入ください（500文字以内）。
                  </div>
                  {errors.notes?.message && (
                    <div className={classes.error}>{errors.notes.message}</div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label
                    htmlFor={various.seminarHeaders.information_to_management}
                  >
                    {various.seminarHeaders.information_to_management}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <textarea
                    id="information_to_management"
                    type="textarea"
                    {...register('information_to_management', {
                      required: { value: false, message: '入力必須の項目です' },
                      maxLength: {
                        value: 500,
                        message: '500字以内でお願いします',
                      },
                    })}
                  />
                  <div className={classes.helptext}>
                    運営スタッフへの申し送り事項があればご記載ください。なお当該内容は一般公開はされません。
                  </div>
                  {errors.information_to_management?.message && (
                    <div className={classes.error}>
                      {errors.information_to_management.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.tag}>
                    {various.seminarHeaders.tag}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <select
                    id="tag"
                    defaultValue={['choice']}
                    multiple
                    style={{ height: '96px' }}
                    {...register('tag', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          selectDefaultCheck(v)
                            ? null
                            : `いずれかをご選択ください。`,
                      },
                    })}
                  >
                    <option value="choice">選択してください</option>
                    {tags
                      ? tags.map((tag, i) => {
                          return (
                            <option
                              key={tag.uuid}
                              id={`choice_${tag.uuid}`}
                              value={tag.name}
                            >
                              {tag.name}
                            </option>
                          );
                        })
                      : undefined}
                  </select>
                  <div className={classes.helptext}>
                    テーマに沿ったタグを選択してください。また、追加すべきタグがある場合は
                    <a href={various.general.tagIndex}>こちら</a>
                    のページで登録してください。
                  </div>
                  {errors.tag?.message && (
                    <div className={classes.error}>{errors.tag.message}</div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.image}>
                    {various.seminarHeaders.image}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <select
                    id="image"
                    defaultValue="choice"
                    {...register('image', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          selectDefaultCheck(v)
                            ? null
                            : `いずれかをご選択ください。選択肢に無い場合は、新規画像制作依頼として管理者へご要請ください。`,
                      },
                    })}
                  >
                    <option value="choice">選択してください</option>
                    {iconImages
                      ? iconImages.map((image) => {
                          return (
                            <option
                              key={image.uuid}
                              id={`choice_${image.uuid}`}
                              value={image.title}
                            >
                              {image.title}
                            </option>
                          );
                        })
                      : undefined}
                  </select>
                  <div className={classes.helptext}>
                    テーマに沿ったイメージを選択してください。なお、相当するイメージが選択肢にない場合は、制作者に対して、制作＆登録の指示を出してください。また、登録するイメージがある場合は
                    <a href={various.general.imageIndexForAdmin}>こちら</a>
                    のページで登録してください。
                  </div>
                  {errors.image?.message && (
                    <div className={classes.error}>{errors.image.message}</div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.documents}>
                    {various.seminarHeaders.documents}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <select
                    id="documents"
                    defaultValue="choice"
                    {...register('documents', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  >
                    <option value="choice">選択してください</option>
                    {documents
                      ? documents.map((doc) => {
                          return (
                            <option
                              key={doc.uuid}
                              id={`choice_${doc.uuid}`}
                              value={doc.title}
                            >
                              {doc.title}
                            </option>
                          );
                        })
                      : undefined}
                  </select>
                  <div className={classes.helptext}>
                    指導者の指示に従い配布資料を登録してください。なお、相当する資料が選択肢にない場合は、
                    <a
                      href={
                        various.general.documentCreate + '?seminarId=' + uuid
                      }
                    >
                      こちら
                    </a>
                    のページで登録してください。
                  </div>
                  {errors.documents?.message && (
                    <div className={classes.error}>
                      {errors.documents.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label
                    htmlFor={various.seminarHeaders.display_name_of_the_place}
                  >
                    {various.seminarHeaders.display_name_of_the_place}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="display_name_of_the_place"
                    type="text"
                    {...register('display_name_of_the_place', {
                      required: { value: true, message: '入力必須の項目です' },
                      maxLength: {
                        value: 50,
                        message: '50字以内でお願いします',
                      },
                    })}
                  />
                  <div className={classes.helptext}>
                    一般公開画面にて「開催場所」として表記される場所情報を入力してください。なお、セミナーに申し込まれた方に対しては、具体的なセミナー開催会場が記載されたメールが配信されます。
                  </div>
                  {errors.display_name_of_the_place?.message && (
                    <div className={classes.error}>
                      {errors.display_name_of_the_place.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.products}>
                    {various.seminarHeaders.products}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <select
                    id="products"
                    defaultValue={['choice']}
                    multiple
                    style={{ height: '96px' }}
                    {...register('products', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          selectDefaultCheck(v)
                            ? null
                            : `いずれかをご選択ください。`,
                        integrityCheckForProductMaster,
                      },
                    })}
                  >
                    <option value="choice">選択してください</option>
                    {eventProducts
                      ? eventProducts.map((product, i) => {
                          return (
                            <option
                              key={product.uuid}
                              id={`choice_${product.uuid}`}
                              value={product.management_tags}
                            >
                              {product.management_tags}
                            </option>
                          );
                        })
                      : undefined}
                  </select>
                  <div className={classes.helptext}>
                    セミナーコンテンツに紐付けるサービスモデルを選択してください。なお、ここで紐づけたモデルが決済対象となるため選択には十分ご注意ください。
                    基本的には通常のセミナーサービス（定価）や、その半額のモデル、入会金控除モデルおよびその組み合わせモデルがプリセットされています。
                    もし関連づけたいモデルがない場合は、
                    <a href={various.general.productMasterCreate}>こちら</a>
                    のページで登録してください。
                  </div>
                  {errors.products?.message && (
                    <div className={classes.error}>
                      {errors.products.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.delivery_date}>
                    {various.seminarHeaders.delivery_date}
                  </label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="delivery_date"
                    type="datetime-local"
                    {...register('delivery_date', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    一般公開画面に表示される日時を設定してください。過去の日付に設定することも可能です。その場合は以下の「公開」にチェックが入っていれば即刻公開されます。
                  </div>
                  {errors.delivery_date?.message && (
                    <div className={classes.error}>
                      {errors.delivery_date.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.distribution_is}>
                    {various.seminarHeaders.distribution_is}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="distribution_is"
                    type="checkbox"
                    {...register('distribution_is', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    新着セミナーのニュースとして、当セミナー開催の旨を配信する場合はチェックを入れてください。ニュース配信が不要な場合はチェックを外してください。なお、以下の「公開」にチェックが入っていなければ、メール配信が行われることはありません。
                  </div>
                  {errors.distribution_is?.message && (
                    <div className={classes.error}>
                      {errors.distribution_is.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.distribution_date}>
                    {various.seminarHeaders.distribution_date}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="distribution_date"
                    type="datetime-local"
                    {...register('distribution_date', {
                      required: { value: false, message: '入力必須の項目です' },
                      validate: dependentValidation,
                    })}
                  />
                  <div className={classes.helptext}>
                    本セミナーをメール配信する場合は、配信される予定日時を設定してください。
                  </div>
                  {errors.distribution_date?.message && (
                    <div className={classes.error}>
                      {errors.distribution_date.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.show_the_instructor}>
                    {various.seminarHeaders.show_the_instructor}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="show_the_instructor"
                    type="checkbox"
                    {...register('show_the_instructor', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    講師情報を一般公開する場合はチェックを入れてください。講師非公開でセミナー開催をする場合はチェックを外してください。
                  </div>
                  {errors.show_the_instructor?.message && (
                    <div className={classes.error}>
                      {errors.show_the_instructor.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.is_secret}>
                    {various.seminarHeaders.is_secret}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="is_secret"
                    type="checkbox"
                    {...register('is_secret', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    秘伝扱いのセミナーの場合はチェックを入れてください。チェックを入れた場合は、セミナーコンテンツに「秘伝」アイコンが付され、申込フォームには同意のためのチェックボックスが追加されます。
                    <br />
                    ※2023年5月現在では、「撥行」および「核行」関連のセミナーが秘伝扱いとなります。
                  </div>
                  {errors.is_secret?.message && (
                    <div className={classes.error}>
                      {errors.is_secret.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor={various.seminarHeaders.release_is}>
                    {various.seminarHeaders.release_is}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="release_is"
                    type="checkbox"
                    {...register('release_is', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    当セミナーを公開する場合はチェックを入れてください。ただし、上記公開予定日時が未来の場合は、その日時を過ぎるまで公開されません。公開予定日時を過ぎてチェックを入れて更新した場合は、即刻公開されます。
                  </div>
                  {errors.release_is?.message && (
                    <div className={classes.error}>
                      {errors.release_is.message}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.button}>
                <button type="submit">更新</button>
              </div>
            </form>
          </div>
        </>
      );
    }
  };

  const object = {
    previous: location.state.previous,
    state: { uuid: location.state.uuid },
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>セミナー情報更新</div>
      <hr />
      <BreadCrumbs crumbs={CRUMBS} />
      {isAuth ? (
        <>
          {form()}
          {push ? <SitemapGenerator push={push} navigate={object} /> : ''}
          <Footer />
        </>
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default SeminarUpdateForAdmin;
