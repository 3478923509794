import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { makeStyles } from '@material-ui/core';

import OnOffByScroll from '../Animation/onOffByScroll';
import ContentTitle from '../General/contentTitle';
import ScrollToTop from '../General/scrollToTop';
import { images as imageApi } from '../Apis/apis';
import Footer from '../Footer/footer';
import { ogpGenerator } from '../ogp';
import { useLocation } from 'react-router-dom';
import { Spinner } from '../Spinner';

const useStyles = makeStyles((theme) => ({
  container: {
    marginRight: 'calc(50% - 50vw)',
    marginLeft: 'calc(50% - 50vw)',
    height: '100vh',
    position: 'relative',
    '& > #topImage': {
      width: '100%',
      height: '100vh',
      marginTop: '56px',

      '& > img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: '50% 0%',
        filter: 'contrast(200%) grayscale(100%) saturate(0%)',
      },
    },
    '& #topImageWrap': {
      display: 'flex',
      justifyContent: 'end',
      '& > div': {
        width: '30%',
        marginLeft: '32px',
        marginTop: '144px',
        position: 'absolute',
        top: 0,
        left: 0,
        '& > p': {
          // backdropFilter: 'blur(1px) brightness(1.05)',
          color: '#FFF',
          textAlign: 'start',
          textIndent: '1rem',
          padding: '8px 8px 8px 8px',
        },
        '@media screen and (max-width:800px)': {
          width: '90%',
          marginTop: '208px',
          marginRight: '32px',
        },
      },
    },
  },

  contentBox: {
    marginTop: '168px',
    marginBottom: '32px',
    margin: '0 auto',
    width: '70%',
    '@media screen and (min-width:1001px)': {
      '& $newline': {
        display: 'none',
      },
    },
    '@media screen and (max-width:1000px)': {
      width: '80%',
    },
    '@media screen and (max-width:700px)': {
      width: '90%',
    },
  },
  contentWrapper: {
    marginBottom: '112px',
  },
  newline: {
    display: 'inline',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '80px',
  },
  text1: {
    textIndent: '1rem',
    textAlign: 'justify',
    marginBottom: '80px',
    fontSize: '1rem',
  },
  adjustmentImage: {
    width: '100%',
    marginBottom: '80px',
    '& > img': {
      width: '100%',
      maxHeight: '1000px',
      objectFit: 'contain',
      filter:
        'contrast(100%) grayscale(100%) saturate(0%) drop-shadow(2px 2px 2px black)',
    },
  },
  image: {
    width: '100%',
    marginBottom: '80px',
    '& > img': {
      width: '100%',
      maxHeight: '1000px',
      filter:
        'contrast(100%) grayscale(100%) saturate(0%) drop-shadow(2px 2px 2px black)',
      objectFit: 'contain',
    },
  },
  text2: {
    textIndent: '1rem',
    textAlign: 'justify',
    fontSize: '1rem',
  },

  touzenImages: {
    width: '70vw',
    height: '70vw',
    margin: '0 auto',
    position: 'relative',

    '& > div': {
      position: 'absolute',
      overflow: 'hidden',
      width: '32.6%',
      height: '32.6%',

      '& > img': {
        filter:
          'contrast(100%) grayscale(100%) saturate(0%) drop-shadow(2px 2px 2px black)',
        objectFit: 'cover',
        width: '100%',
        height: '100%',
      },
    },
    '& $images1': {
      top: 0,
      left: 0,
    },
    '& $images2': {
      height: '65.6% !important',
      top: '33%',
      left: 0,
    },
    '& $images3': {
      top: 0,
      left: '33%',
    },
    '& $images4': {
      top: '33%',
      left: '33%',
    },
    '& $images5': {
      width: '66% !important',
      top: '66%',
      left: '33%',
    },
    '& $images6': {
      height: '65.6% !important',
      top: 0,
      left: '66%',
    },

    '@media screen and (max-width:1000px)': {
      position: 'static',
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      width: '92vw',
      height: '92vw',
      marginBottom: 'calc(92vw / 2 * (3-1))', // images（余白含む）の高さと概ね揃えている

      '& > div': {
        position: 'static',
        marginRight: '8px',
        marginBottom: '8px',
      },
      '& $images1, & $images2, & $images3, & $images4, & $images5, & $images6':
        {
          objectFit: 'cover',
          width: 'calc(92vw * 0.40) !important',
          height: 'calc(92vw * 0.40) !important',
        },
    },
    // '@media screen and (max-width:500px)': {
    //   flexDirection: 'column',
    //   '& > div': {
    //     position: 'static !important',
    //   },
    // },
  },
  images1: {},
  images2: {},
  images3: {},
  images4: {},
  images5: {},
  images6: {},

  roushiBox: {
    position: 'relative',
    marginTop: '480px',
    width: '100%',
    '@media screen and (max-width:1000px)': {
      position: 'static',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  roushiImageWrapper: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',

    '& > img': {
      display: 'inline-block',
      width: '100%',
      maxHeight: '1000px',
      filter:
        'contrast(100%) grayscale(100%) saturate(0%) drop-shadow(4px 4px 4px black)',
      objectFit: 'cover',
    },

    '& > p': {
      fontSize: '0.8rem',
      textAlign: 'start',
      '@media screen and (max-width:1000px)': {
        position: 'absolute',
        bottom: 0,
        left: '12px',
        width: '95%',
        color: '#FFF',
      },
    },

    '@media screen and (max-width:1000px)': {
      width: '100%',
      position: 'relative',
    },
  },
  roushiTextWrapper: {
    position: 'absolute',
    top: '10%',
    right: '0px',
    width: '50%',
    height: '100%',

    '& > div': {
      position: 'relative',
      width: '100%',
      height: '60%',

      '& > div': {
        position: 'absolute',
        top: 0,
        right: 0,
        borderRadius: '10% 30% 500% 70%',
        backgroundColor: 'rgba(243,243,243,0.5)',
        filter: 'blur(0.8rem)',
        width: '100%',
        height: '100%',
      },

      '& > p': {
        position: 'absolute',
        top: 0,
        right: 0,
        textAlign: 'justify',
        verticalAlign: 'center !important',
        lineHeight: '2.2rem',
        letterSpacing: '0.2rem',
        fontSize: '1.1rem',
        backgroundColor: 'rgba(243,243,243,0)',
        width: '95%',
        '& > span': {
          display: 'inline-block',
          width: '100%',
          textAlign: 'end',
        },
        '@media screen and (max-width:1000px)': {
          lineHeight: '3rem',
          top: '8%',
        },
      },
    },
    '@media screen and (max-width:1000px)': {
      position: 'static',
      width: '100%',
      marginTop: '64px',
      marginBottom: '480px',
    },
  },
}));

const About = () => {
  const element = useRef([]);
  const [image, setImage] = useState();

  const [view, setView] = useState();
  const [top, setTop] = useState();
  const [contents, setContents] = useState();

  const location = useLocation();
  const classes = useStyles();

  const aboutTitle = {
    titleList: ['About'],
    sub: '刀禅とは何か',
  };

  // OGP設定
  const ogp = useCallback((props) => {
    ogpGenerator(props);
  }, []);

  useLayoutEffect(() => {
    if (image) {
      const ogpProps = {
        page: 'about',
        pageTitle: '刀禪OfficialWebSite刀禅とは何か',
        path: location.pathname,
        imageUrl: image.OfficialImage_TouzenLogoBlack.url,
      };
      ogp(ogpProps);
    }
  }, [image, location, ogp]);
  // OGP設定ここまで

  const contentsElement = () => {
    const contentsList = [
      {
        id: 'contentImage1',
        title: '武術から生まれたボディーワーク',
        image: image.AboutPageImages_aboutImage1.url,
      },
      {
        id: 'contentImage2',
        title: '刀禅の目指すもの',
        image: image.AboutPageImages_aboutImage2.url,
      },
      { id: 'contentImage3', title: '身体の癖から抜け出す', image: null },
      {
        id: 'contentImage4',
        title: '刀禅が養う身体',
        image: image.AboutPageImages_aboutImage3.url,
      },
      {
        id: 'contentImage5',
        title: 'シンプルな刀禅の稽古',
        image: image.AboutPageImages_aboutImage4.url,
      },
      {
        id: 'contentImage6',
        title: '刀禅の求める身体の「基準性」',
        image: null,
      },
      {
        id: 'contentImage7',
        title: '全身に満ちる力「内圧」',
        image: image.AboutPageImages_aboutImage5.url,
      },
      { id: 'contentImage8', title: '持続的な接触が身体を変える', image: null },
      {
        id: 'contentImage9',
        title: '相対で立つことで生まれる自律性',
        image: null,
      },
      { id: 'contentImage10', title: '思い込みに陥らない', image: null },
      { id: 'contentImage11', title: '絶望から生まれた「刀禅」', image: null },
      {
        id: 'contentImage12',
        title: '形を解体することで現れた「原理原則」',
        image: null,
      },
      { id: 'contentImage13', title: '身体に眠る無限の可能性', image: null },
      { id: 'contentImage14', title: '刀禅の現在', image: null },
    ];
    const result = [];
    contentsList.map((content, index) => {
      switch (content.title) {
        case '武術から生まれたボディーワーク':
          contentsList[index].title = (
            <span>
              武術から生まれた
              <br className={classes.newline} />
              ボディワーク
            </span>
          );
          contentsList[index].text1 = (
            <p>
              刀禅は創始者・小用茂夫が、多年にわたって学んだ武道・武術から、最も重要なエッセンスを「身体の基準性」として抽出し、合理的な稽古体系にまとめた日本発のボディワークです。その特徴は個人の才能や環境に関係なく、シンプルな稽古を繰り返すことで、誰もが持続的に成長できる稽古システムにあります。
            </p>
          );
          contentsList[index].text2 = <span></span>;
          break;
        case '刀禅の目指すもの':
          contentsList[index].text1 = (
            <>
              <p>
                刀禅の稽古で養われるのは、私たちが普段何気なく行なっている動きを生み出す身体　=「基盤」の質を上げることにあります。ですからスポーツや武術、踊り、伝統芸能などを学んでいる人にとって全体のクオリティの底上げになるでしょう。
              </p>
              <p>
                様々な競技や流派を木に例えるなら、刀禅はそれぞれの木が生える土壌と言えます。
              </p>
            </>
          );
          contentsList[index].text2 = (
            <>
              <p>
                豊かな土壌が丈夫な幹と枝葉を広げる木を育むように、刀禅の稽古は身体の質を豊かにすることで、動き全体のクオリティーを上げ、できることの幅と可能性を広げます。
              </p>
              <p>
                刀禅はそのメソッドが武術から生まれたことと、創始者・小用茂夫の武術的なキャリアもあり、現在の稽古には空手や合気道、剣術、中国拳法などの経験者・指導者からボディワーカー、スポーツトレーナー、アスリートなどが多彩な面々が集まっています。
              </p>
              <p>
                ですが刀禅自体は一般の人にも開かれたもので、実際の稽古にも突きや蹴りなどの格闘技的な動きはありません。ゆっくりと自分自身の身体を感じながら動くなかで、普段意識しないで行なっている「身体の癖」に気づきこれを解消するものです。ですから老若男女、武術やスポーツ経験の有無にかかわらず、まったくの初心者でも参加が可能になっています。
              </p>
            </>
          );
          break;
        case '身体の癖から抜け出す':
          contentsList[index].title = (
            <span>
              身体の癖から
              <br className={classes.newline} />
              抜け出す
            </span>
          );
          contentsList[index].text1 = (
            <>
              <p>
                ここで言う「身体の癖」とは、本人にも自覚がなく反射レベルで行なっている動きのことです。私たちの日常的な運動の多くは、大きな関節をメインに「身体の一部分」の筋肉を「オン」「オフ」することを繰り返して動いています。
              </p>
              <p>
                これは近代化が進み、多くのことが自動化された現代では効率の良い動き方とも言えるでしょう。ですが、改めて「生き物」という視点で見直すと、環境に頼った「偏った身体の使い方」だと言えます。
              </p>
              <p>
                その結果、運動能力はもちろん「世界の感じ方」といった心の部分を含み、私たち人間が本来持っている心体能力がスポイルされた状態だと言えます。
              </p>
            </>
          );
          contentsList[index].text2 = <span></span>;
          break;
        case '刀禅が養う身体':
          contentsList[index].text1 = (
            <>
              <p>
                刀禅では「身体全体を通して（連動させて）動く」という稽古を繰り返すことで、環境や思い込みから生まれた身体の癖から自由になり、「オン」「オフ」といった切れ間がない動きを生み出す身体になることを目的としています。
              </p>
              <p>
                それは手や足、頭といった関節で身体が区切られず、体全体が動きに参加してしなやかに動くイメージです。
              </p>
            </>
          );
          contentsList[index].text2 = (
            <span>
              こうしたことから刀禅で学ぶのは、今ある身体を上手に使う方法を学ぶのではなく、丸ごと身体の仕組みを変えることを目指します。パソコンで例えるなら「身体のOSを変える」と言えるでしょう。
            </span>
          );
          break;
        case 'シンプルな刀禅の稽古':
          contentsList[index].title = (
            <span>
              シンプルな
              <br className={classes.newline} />
              刀禅の稽古
            </span>
          );
          contentsList[index].text1 = (
            <>
              <p>
                刀禅の稽古は人間にとって最も基本の、二本足で立つ・歩く、といったことを核にしたとてもシンプルなものです。
              </p>
              <p>
                多くは2人1組で行われる「相対稽古」が中心で、手や拳、時には木刀や竹尺などの道具を持ち、互いに合わせた状態でゆっくり動きを繰り返すことで進みます。このシンプルな運動のなかで、接点から伝わってくる相手の力を感じつつ、その力を邪魔せず足の裏まで「力を通す」ことが求められます。
              </p>
            </>
          );
          contentsList[index].text2 = (
            <span>
              こうした稽古で養われ常に試されるのが刀禅の原理原則である「基準性」と「内圧」です。
            </span>
          );
          break;
        case '刀禅の求める身体の「基準性」':
          contentsList[index].title = (
            <span>
              刀禅の求める
              <br className={classes.newline} />
              身体の「基準性」
            </span>
          );
          contentsList[index].text1 = (
            <>
              <p>
                刀禅で言う「基準性」とは、垂直に働く重心線とそれに対する水平線といった私たちの生きる世界にある絶対的な基準のことを指します。
              </p>
              <p>
                この絶対的な基準に対して寄り沿うように骨盤を水平にし、頭を含む状態の重さを内くるぶしからかかとへと垂直に下ろすことが「基準」になります。
              </p>
              <p>
                このシンプルな基準を身体に持つことが、あらゆる動きの精度を上げ、重力と自分の重さを無駄なく力と動きに変えることを可能にします。
              </p>
              <p>
                逆にこの基準を持たず、なんとなく動いている限りでは精度に限界があり、それを誤魔化すためどこかで無理が必要になり、いわゆる雑な動きになってしまうのです。
              </p>
            </>
          );
          contentsList[index].text2 = <span></span>;
          break;
        case '全身に満ちる力「内圧」':
          contentsList[index].title = (
            <span>
              全身に満ちる力
              <br className={classes.newline} />
              「内圧」
            </span>
          );
          contentsList[index].text1 = (
            <>
              <p>
                一方の内圧は、基準に沿った身体から構造的に生まれる力で、身体の内側から外側へと持続的に膨張するような圧力のことを言います。積極的に筋力を使った硬さはなく、柔構造で常に動きの可能性が満ち溢れた感覚があり、イメージするなら滑らかな液体金属に満ちた身体です。足裏の僅かな圧力変化がそのまま指先へと伝わり動きへと変化するような緊密性と思うがままに舞い上がれるような軽快性を備えた力。それが刀禅が養う内圧です。
              </p>
              <p>
                刀禅の稽古では基準性を保ったまま互いの内圧を身体に通すことを繰り返すなかで、軸性があり全身が一つにまとまった自在な身体へと変化させることを目的にしています。
              </p>
            </>
          );
          contentsList[index].text2 = <span></span>;
          break;
        case '持続的な接触が身体を変える':
          contentsList[index].title = (
            <span>
              持続的な接触が
              <br className={classes.newline} />
              身体を変える
            </span>
          );
          contentsList[index].text1 = (
            <>
              <p>
                刀禅の稽古で特にユニークな点は、相対でゆっくりと持続的な動きを行うことにあります。
              </p>
              <p>
                一般に武術の稽古でも相対で行われるものが多くありますが、「受け」「取り」や「打太刀」「仕太刀」などに分かれて攻防の形になることが多く、そこには間合いや動き方などの技術的な要素が多く含まれ、純粋に相手と接触する絶対的な時間が短くなります。
              </p>
              <p>
                一方刀禅の稽古は、シンプルな動きのなかで接触し続けているため、普段は気がつかない緊張や対抗心といった反射的な反応に意識的になることができます。その上で、こうした身体の癖を基準性のなかに落とし込むことで着実に身体を変えていけるわけです。
              </p>
            </>
          );
          contentsList[index].text2 = <span></span>;
          break;
        case '相対で立つことで生まれる自律性':
          contentsList[index].title = (
            <span>
              相対で立つことで
              <br className={classes.newline} />
              生まれる自律性
            </span>
          );
          contentsList[index].text1 = (
            <>
              <p>
                またこうした相手の存在や力を感じつつ基準性を追求するなかで養われるのは確固たる自律性です。
              </p>
              <p>
                私たちは日々の生活のなかで、自分以外の他者や情報の影響を受けています。特にネット・SNSの登場は、否応なく自分と他者・情報との境界線を危うくさせ、時に自分の存在を希薄に感じることもあるでしょう。そうした時代にあって、相手と向き合い真っ直ぐに立つ刀禅の稽古は、自分の存在を明確にするとともに身体から自律性を育む、生きることに直結したものと言えるでしょう。
              </p>
            </>
          );
          contentsList[index].text2 = <span></span>;
          break;
        case '思い込みに陥らない':
          contentsList[index].title = (
            <span>
              思い込みに
              <br className={classes.newline} />
              陥らない
            </span>
          );
          contentsList[index].text1 = (
            <>
              <p>
                またこうした相対稽古を繰り返し行うことで、1人の稽古ではなかなか気がつかない自分の身体の癖や緊張、力が抜けた部分、力が通る場所などに気がつかせてもらえ、ともすれば誤解の元となる腑抜け状態の「脱力」や、感覚を優先させた「思い込み」といった落とし穴にも陥らず稽古をすすめることができます。
              </p>
              <p>
                これも相対で行なう稽古ならではの魅力で、稽古者のレベルに関係なく互いに相手の未明な部分を照らす灯として存在しているのです。
              </p>
              <p>
                刀禅では初心者からベテランまでが分け隔てなく一緒に稽古をしますが、それは言葉上の理念ではなく実際的な意味で相手の存在を認めることが稽古の質を決める方法だからです。
              </p>
            </>
          );
          contentsList[index].text2 = <span></span>;
          break;
        case '絶望から生まれた「刀禅」':
          contentsList[index].title = (
            <span>
              絶望から生まれた
              <br className={classes.newline} />
              「刀禅」
            </span>
          );
          contentsList[index].text1 = (
            <>
              <p>
                刀禅のこうした特徴は、創始者・小用茂夫が多年にわたり伝統的な武術を学んだ背景から生み出されたものです。少年時代に学んだ空手をはじめ、新陰流、大東流などを学び、様々な知己との交流のなかでも、決定的な出会いは形意拳の利根川謙先生でした。
              </p>
              <p>
                自分の持っている技術を惜しみなく与えようとする利根川先生の教えに対して、全力で応えようとすればするほど真似すらできず、先生の動きから離れていくという現実。
              </p>
              <p>
                そこで痛感したのは、身体が時代や環境と強く結びついた上に成り立ったもので、現代的な身体のままでは伝統的な武術を本当の意味で理解、実践することはできないということでした。この
                時、
              </p>
              <p>
                「(このまま)自分の人生、一生をかけてもここには到達できない」
              </p>
              <p>と絶望し、一旦は、武術の道を諦めようとしたといいます。</p>
              <p>
                それでも武術への想いは絶ち難く、また自分を頼り教えを求める弟子との出会いから始めたのが、これまでとはまったく違う「武術の形を見直す」というアプローチでした。
              </p>
            </>
          );
          contentsList[index].text2 = <span></span>;
          break;
        case '形を解体することで現れた「原理原則」':
          contentsList[index].title = (
            <span>
              形を解体することで現れた
              <br />
              「原理原則」
            </span>
          );
          contentsList[index].text1 = (
            <>
              <p>
                武術の形（套路）はそれぞれの流派・流儀の思想から技術、哲学、鍛錬法などが込められたもので、先人の知恵の精華と言えるものです。その一方で、そうした要素の多さから抽象度が高く、学ぶ側がどこに着目するかによって解釈が無数に存在することになります。またその形が成立した環境や時代背景も現在とはまったく異なります。
              </p>
              <p>
                そこで小用が始めたのは、形からその形を成立させている「最も基本的な身体的要素」を抽出する作業でした。この時、研究の対象になったのは自身も学び、文献的な資料の多さから原初の形から変化の過程までが追いやすい剣術・柳生新陰流と、シンプルで原理性が明確であり、また絶望とともに大きな気づきを与えてくれた利根川先生から学んだ中国拳法・形意拳でした。
              </p>
              <p>
                「形の因数分解」と呼べるような作業の果てに現れたのは、新陰流と形意拳に共通した要素、正確で強靭な軸性と内圧を持ち、進んで相手の中心を取り圧倒するという原理原則でした。
              </p>
              <p>
                こうした気づきは武術からだけではなく、小用自身が20代前半で腰椎圧迫骨折を経験したこともベースにあります。多くの病院を訪ね様々な治療法を試したものの効果はなく、最後に残ったのは自分で治すという方法でした。
              </p>
              <p>
                手探りのまま行なう作業は、激痛を手がかりにして痛みとぶつからないルートを身体のなかに見つけるというもので、刀禅成立の過程のなかでいつしか腰の痛みが消えていったことを考えると「必然」であったともいえます。
              </p>
              <p>
                また付け加えれば武術とともに青年期に強い影響を受けた舞踏の世界の影響もあったでしょう。
              </p>
            </>
          );
          contentsList[index].text2 = <span></span>;
          break;
        case '身体に眠る無限の可能性':
          contentsList[index].title = (
            <span>
              身体に眠る
              <br className={classes.newline} />
              無限の可能性
            </span>
          );
          contentsList[index].text1 = (
            <>
              <p>
                そしてこのシンプルな原理原則を研究、稽古するなかで見えてきたのは、遥か昔に安定した四足歩行から、不安定な二足歩行を選び生き抜いてきた人間が本来持っていた豊かな可能性です。
              </p>
              <p>
                遥かアフリカから仲間とともに長い旅を続けた私たちの祖先にとっては、二本の脚で立ち上がり、大地を踏みしめて歩くことは生きることと同じだったでしょう。そうしたなかで培われた二足歩行の原理原則には、生き抜くことの苦しさと同時に、生き物としての原初的な喜びを包含したものであり、それはいまもなお私たちの身体のなかにあるのです。
              </p>
              <p>この身体に眠る可能性を掘り下げるのが刀禅の稽古と言えます。</p>
              <p>
                稽古が進むほどにそれまで気がつくことがなかった自分のなかの可能性に気がつき、それまで分からなかった先生の動きや言葉の意味が身体で読み解ける面白さは格別です。
              </p>
              <p>
                そして自分の基盤を整え掘り下げる作業はそのまま、そこから生まれる動きや技術の高さとなって現れ、物の捉え方や自分の在りようも変えるでしょう。そこに限界はありません。
              </p>
              <p>
                ボディワーク刀禅はそのための方法論であり、一生をかけて発展し追求できることがその魅力なのです。
              </p>
            </>
          );
          contentsList[index].text2 = <span></span>;
          break;
        case '刀禅の現在':
          contentsList[index].text1 = (
            <p>
              小用の研究は少しずつ口コミや知人の紹介などで広がり、「より根源的な動きの原理を知りたい」という熱心な稽古人とともに試行錯誤が繰り返され、「素振倶楽部」「身法研究会」などを経て、2010年にボディワーク「刀禅」として埼玉県蕨市を中心に活動を開始し、現在、同好会を含む16か所で稽古が行われています。
            </p>
          );
          contentsList[index].text2 = <span></span>;
          break;
        default:
      }
      result.push(
        <div className={classes.contentWrapper} key={index}>
          <div className={classes.title}>{content.title}</div>
          <div className={classes.text1}>{content.text1}</div>
          {content.id === 'contentImage1' ? (
            <div className={classes.adjustmentImage}>
              {content.image ? (
                <img src={content.image} alt={`image_${index}`} />
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div className={classes.image}>
              {content.image ? (
                <img src={content.image} alt={`image_${index}`} />
              ) : (
                <></>
              )}
            </div>
          )}
          <div className={classes.text2}>{content.text2}</div>
        </div>
      );
      return null;
    });
    return result;
  };

  const callback = useCallback(() => {
    if (image) {
      return (
        <>
          <div className={classes.contentBox}>{contentsElement()}</div>
          <div ref={element} id="target_0" className={classes.touzenImages}>
            <div className={classes.images1}>
              <img src={image.AboutPageImages_aboutImage12.url} alt="images1" />
            </div>
            <div className={classes.images2}>
              <img src={image.AboutPageImages_aboutImage13.url} alt="images2" />
            </div>
            <div className={classes.images3}>
              <img src={image.AboutPageImages_aboutImage14.url} alt="images3" />
            </div>
            <div className={classes.images4}>
              <img src={image.AboutPageImages_aboutImage15.url} alt="images4" />
            </div>
            <div className={classes.images5}>
              <img src={image.AboutPageImages_aboutImage16.url} alt="images5" />
            </div>
            <div className={classes.images6}>
              <img src={image.AboutPageImages_aboutImage17.url} alt="images6" />
            </div>
          </div>
          <div className={classes.roushiBox}>
            <div className={classes.roushiImageWrapper}>
              <img src={image.OfficialImage_roushi.url} alt="roushi" />
              <p>
                刀禅創始者　小用 茂夫
                <br />
                略歴：1950年埼玉県出身。刀禅創始者。新陰流、形意拳、大東流などの武道・武術を研鑽。独自の稽古会を重ねて、2010年からボディワーク刀禅として公開。女性を中心とした舞扇道を考案。現在、埼玉県蕨市を拠点として活動。稽古会は埼玉県、東京都、神奈川県、愛知県、大阪府、岡山県、広島県、福岡県に同好会がある。
              </p>
            </div>
            <div className={classes.roushiTextWrapper}>
              <div>
                <div className={classes.background}></div>
                <p>
                  “ <br />
                  「刀禅」と名称に“禅”を冠したのは、人は根底から変われるのかという模索を続けていく中で、
                  大きな動きから小さな動きへ。瞬間的な動きから持続的な動きへと、稽古の内容が“動”から“静”へ向かって収斂していく。
                  いわゆる武術的な稽古からどんどん離れていって、禅的なものへ近づいているのではないかと思えたからなんです。
                  そして静の持続のほうに価値を置くようになっていったわけです。
                  <br />”<span>(小用茂夫談)</span>
                </p>
              </div>
            </div>
          </div>
          <Footer />
        </>
      );
    }
    // eslint-disable-next-line
  }, [image, classes]);

  const callbackTop = useCallback(() => {
    return (
      <>
        <div>
          <OnOffByScroll place="General" />
        </div>
        <div className={classes.container}>
          <div id="topImage">
            <img
              src={image.AboutPageImages_aboutTopImage.url}
              alt="aboutTopImage"
            />
          </div>
          <div id="topImageWrap">
            <div>
              <div>
                <ContentTitle title={aboutTitle} color="#FFF" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
    // eslint-disable-next-line
  }, [image, classes]);

  useEffect(() => {
    imageApi(setImage);
  }, []);

  useEffect(() => {
    if (image) {
      setTop(callbackTop());
      setView(true);
    }
  }, [image, callbackTop]);

  useEffect(() => {
    if (top) {
      setContents(callback());
    }
  }, [top, callback]);

  return (
    <>
      <div>{top}</div>
      <ScrollToTop />
      {view ? contents : <Spinner />}
    </>
  );
};
export default About;
