import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';

import OnOffByScroll from '../../../Animation/onOffByScroll';
import { AuthContext } from '../../../auth';
import various from '../../../General/various';
import { updateInstance } from '../../../Apis/apiHandlers';
import {
  instructorDetailForAdmin,
  memberIndex,
} from '../../../Apis/apiOfManaged';
import { selectDefaultCheck } from '../../../CommonFunction/validators';
import Footer from '../../../Footer/footer';
import { fileSizeObserver } from '../../../CommonFunction/imageManagement';
import { localStorageArrayHandler } from '../../../CommonFunction/managePermission';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
  },
  titles: {},
  inputs: {},
  helptext: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  container: {
    marginTop: '64px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media screen and (max-width:800px)': {
      '& > div': {
        width: '100%',
      },
    },
    '& > form': {
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '& > img': {
            maxWidth: '300px',
            maxHeight: '300px',
            objectFit: 'contain',
          },
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > label': {
            width: '100%',
            textAlign: 'end',
            fontSize: '1rem',
            '@media screen and (max-width:750px)': {
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
          '& > span': {
            color: 'red',
          },
        },

        '& $inputs': {
          width: '70%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input, & > textarea, & > select': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
          '& > textarea': {
            minHeight: '160px',
          },
          '& > input[type="checkbox"]': {
            boxShadow: 'none',
          },
          '& $helptext': {
            fontSize: '0.8rem',
            color: 'grey',
            '& > a': {
              textDecoration: 'underline',
              '&:link': {
                color: theme.palette.primary.main,
              },
            },
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    marginTop: '88px !important',
    justifyContent: 'end',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const InstructorUpdate = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {},
  });

  const { isAuth } = useContext(AuthContext);
  const permissions = localStorageArrayHandler('parse', various.permissionKey);
  const LIMITSIZE = 5;

  const [date, setDate] = useState();
  const [members, setMembers] = useState();
  const [instructor, setInstructor] = useState();
  const [targets, setTargets] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '指導者更新', path: '/manage/instructor_update' },
  ];

  // Init
  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  useEffect(() => {
    const uuid = location.state.uuid;
    if (uuid) {
      instructorDetailForAdmin(setInstructor, uuid);
      memberIndex(setMembers);
    }
  }, [location]);

  useEffect(() => {
    if (members) {
      setTargets(
        // eslint-disable-next-line
        members.map((member, index) => {
          if (member.member_of_instructor) {
            return (
              <option key={`member_${index}`} value={member.email}>
                {member.last_name + member.first_name}
              </option>
            );
          }
        })
      );
    }
  }, [members]);

  useEffect(() => {
    if (instructor && members) {
      const member = members.filter((m) => {
        return m.email === instructor.user.email;
      })[0];
      reset({
        name: instructor.name,
        is_working: instructor.is_working,
        career: instructor.career,
        biography: instructor.biography,
        user: member ? member.email : '',
      });
    }
  }, [instructor, members, reset]);

  // Submit
  const onSubmit = handleSubmit(async (data) => {
    if (data.image.length > 0) {
      data.title = data.name + '_instructor_image';
    }
    data['uuid'] = location.state.uuid;
    const imageFileKey = 'image';

    try {
      await updateInstance('Instructor', data, imageFileKey);
      console.log('update finish!');
      navigate(location.state.previous, {
        state: { previous: location.pathname, uuid: location.state.uuid },
      });
    } catch (error) {
      console.error('Error in updateInstance:', error);
    }
  });

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  // Contents
  const form = () => {
    if (instructor && members) {
      return (
        <>
          <form method="POST" encType="multipart/form-data" onSubmit={onSubmit}>
            <div>
              <div className={classes.titles}>
                <label htmlFor="指導者氏名">指導者氏名</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <input
                  id="name"
                  type="text"
                  {...register('name', {
                    required: { value: true, message: '入力必須の項目です' },
                  })}
                />
                {errors.name?.message && (
                  <div className={classes.error}>{errors.name.message}</div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="活動区分">{'活動区分'}</label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="is_working"
                  type="checkbox"
                  {...register('is_working', {
                    required: { value: false, message: '入力必須の項目です' },
                  })}
                />
                <div className={classes.helptext}>
                  継続指導中であればチェックを入れてください。活動を休止される場合はチェックを外してください。
                </div>
                {errors.is_working?.message && (
                  <div className={classes.error}>
                    {errors.is_working.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="経歴">経歴</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <textarea
                  id="career"
                  type="textarea"
                  {...register('career', {
                    required: { value: true, message: '入力必須の項目です' },
                    maxLength: {
                      value: 250,
                      message: '250字以内でお願いします',
                    },
                  })}
                />
                <div className={classes.helptext}>
                  250文字以内で記述してください。
                </div>
                {errors.career?.message && (
                  <div className={classes.error}>{errors.career.message}</div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="略歴">略歴</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <textarea
                  id="biography"
                  type="textarea"
                  {...register('biography', {
                    required: { value: true, message: '入力必須の項目です' },
                    maxLength: {
                      value: 100,
                      message: '100字以内でお願いします',
                    },
                  })}
                />
                <div className={classes.helptext}>
                  100文字以内で記述してください。
                </div>
                {errors.biography?.message && (
                  <div className={classes.error}>
                    {errors.biography.message}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="アカウント">{'アカウント'}</label>
                <span> *</span>
              </div>
              <div className={classes.inputs}>
                <select
                  id="user"
                  defaultValue="choice"
                  {...register('user', {
                    required: { value: true, message: '入力必須の項目です' },
                    validate: {
                      message: (v) =>
                        selectDefaultCheck(v)
                          ? null
                          : `指導者となられる会員を選択してください。`,
                    },
                  })}
                >
                  <option value="choice">選択してください</option>
                  {targets}
                </select>
                <div className={classes.helptext}>
                  選択肢に該当の方がいない場合は、
                  <a href={various.general.memberIndex}>会員一覧</a>
                  より会員更新画面にいき、「指導者区分」にチェックを入れてください。
                </div>
                {errors.user?.message && (
                  <div className={classes.error}>{errors.user.message}</div>
                )}
              </div>
            </div>
            <div>
              <div className={classes.titles}>
                <label htmlFor="image">{'差し替え画像アップロード'}</label>
              </div>
              <div className={classes.inputs}>
                <input
                  id="image"
                  type="file"
                  accept="image/*,.pdf"
                  {...register('image', {
                    required: { value: false, message: '入力必須の項目です' },
                    validate: {
                      message: (v) =>
                        fileSizeObserver(v, LIMITSIZE)
                          ? null
                          : `イメージファイルは${LIMITSIZE}MB以下にしてください`,
                    },
                  })}
                />
                {errors.image?.message && (
                  <div className={classes.error}>{errors.image.message}</div>
                )}
              </div>
            </div>
            <div className={classes.button}>
              <button type="submit">更新</button>
            </div>
          </form>
        </>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {!isAuth ? (
        <>
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleAuth}
            >
              ログイン
            </span>
            してください。
          </div>
        </>
      ) : permissions.includes('admin') ? (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">指導者情報の更新</span>
              <span>{date}</span>
            </div>
          </div>
          <hr />
          <BreadCrumbs crumbs={CRUMBS} />
          <div className={classes.container}>{form()}</div>
        </>
      ) : permissions === 'null' ? (
        setTimeout(() => {
          window.location.reload(true);
        }, 500)
      ) : (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">指導者情報の更新</span>
              <span>{date}</span>
            </div>
          </div>
          <hr />
          <div className={classes.container}>権限がありません</div>
        </>
      )}
      <Footer />
    </>
  );
};
export default InstructorUpdate;
