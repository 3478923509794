import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import {
  trainingHallIndex,
  trainingPlaceDetailForAdmin,
} from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import various from '../../../General/various';
import Footer from '../../../Footer/footer';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  updateBtn: {},
  container: {
    marginTop: '64px',
    width: '100%',
    '& > div': {
      width: '80%',
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      marginBottom: '48px',
      '& > span': {
        // display: 'inline',
        width: '40%',
        fontSize: '0.9rem',
        fontWeight: 'bold',
        textAlign: 'end',
        // verticalAlign: 'middle',
        lineHeight: '40px',
        marginRight: '16px',
      },
      '& > input, & > textarea': {
        width: '60%',
        height: '40px',
        padding: '8px',
        fontFamily: theme.typography.fontFamily,
        border: '0.5px solid grey',
        borderRadius: '0.9em',
        boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
        '&:focus': {
          outline: `1.2px ${theme.palette.primary.main} outset`,
        },
      },
      '& > textarea': {
        minHeight: '160px',
      },
      '& > img': {
        width: '60%',
        objectFit: 'contain',
      },
      '@media screen and (max-width:500px)': {
        width: '100%',
        flexWrap: 'wrap',
        '& > span': {
          width: '100%',
          textAlign: 'start',
        },
        '& > input, & > textarea, & > img': {
          width: '100%',
        },
      },
    },
    '& $updateBtn': {
      width: '100%',
      marginTop: '80px',
      '& > button': {
        width: '100%',
        display: 'block',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        borderRadius: '0.5em',
        border: 'none',
        padding: '4px 8px',
        backgroundColor: theme.palette.primary.main,
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        color: '#fff',
        cursor: 'pointer',
      },
    },
  },
}));

const PlaceAndHallDetail = () => {
  const { isAuth } = useContext(AuthContext);
  const [detailData, setDetailData] = useState();
  const [halls, setHalls] = useState();
  const [keikoba, setKeikoba] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '稽古場一覧', path: '/manage/place_hall_index' },
    { name: '稽古場詳細', path: '/manage/place_hall_detail' },
  ];

  // Init
  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  useEffect(() => {
    let uuid;
    if (query.get('id')) {
      uuid = query.get('id');
    } else {
      uuid = location.state.uuid;
    }
    if (uuid) {
      trainingPlaceDetailForAdmin(setDetailData, uuid);
      trainingHallIndex(setHalls);
    }
  }, [location, query]);

  useEffect(() => {
    if (detailData && halls) {
      const result = halls.filter((hall) => {
        return hall.training_place === detailData.uuid;
      });
      if (result) {
        setKeikoba(
          result.map((hall) => {
            return hall.name;
          })
        );
      }
    }
  }, [detailData, halls]);

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleClick = (uuid) => {
    navigate(various.general.placeAndHallUpdate, {
      state: { uuid: uuid, previous: location.pathname },
    });
  };

  // Contents
  const contentTable = () => {
    if (detailData) {
      return (
        <>
          <div className={classes.container}>
            <div>
              <span>施設名称</span>
              <input type="text" value={detailData.facility_name} readOnly />
            </div>
            <div>
              <span>施設名略称</span>
              <input
                type="text"
                value={detailData.facility_name_short}
                readOnly
              />
            </div>
            <div>
              <span>使用区分</span>
              <input
                type="text"
                value={detailData.in_use_is ? '使用中' : '使用停止'}
                readOnly
              />
            </div>
            <div>
              <span>電話番号</span>
              <input
                type="text"
                value={detailData.phone_number ? detailData.phone_number : '-'}
                readOnly
              />
            </div>
            {keikoba.length > 0
              ? keikoba.map((name, index) => {
                  return (
                    <div key={index}>
                      <span>{`稽古場${index + 1}`}</span>
                      <input type="text" value={name} readOnly />
                    </div>
                  );
                })
              : ''}
            <div>
              <span>URL</span>
              <input
                type="url"
                value={detailData.url ? detailData.url : '-'}
                readOnly
              />
            </div>
            <div>
              <span>GoogleMap</span>
              <input
                type="url"
                value={detailData.gmap ? detailData.gmap : '-'}
                readOnly
              />
            </div>
            <div>
              <span>備考</span>
              <textarea
                type="textarea"
                value={detailData.remarks ? detailData.remarks : '-'}
                readOnly
              />
            </div>
            <div className={classes.updateBtn}>
              <button onClick={() => handleClick(detailData.uuid)}>更新</button>
            </div>
          </div>
          <Footer />
        </>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>稽古場情報詳細</div>
      <hr />
      <BreadCrumbs crumbs={CRUMBS} />
      {isAuth ? (
        contentTable()
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default PlaceAndHallDetail;
