import { Box, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  contentTitle: {
    fontSize: '3.5rem',
    fontWeight: 'bold',
    lineHeight: '16px',
    textAlign: 'start',
    marginBottom: '64px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'start',
    alignItems: 'baseline',
    gap: '0px 8px',
  },
  titleWrapper: {
    flexWrap: 'nowrap',
    lineHeight: '16px',
    padding: '0px 0px 32px 0px',
    // textShadow: `1px 1px 2px grey`,
  },
  contentTitleHead: {
    color: theme.palette.primary.main,
    textShadow: `1px 1px 2px grey`,
  },
  remaining: {
    color: (props) => (props.color ? props.color : '#000'),
    textShadow: `1px 1px 2px grey`,
  },
  subTitle: {
    color: (props) => (props.color ? props.color : '#000'),
    opacity: '0.65',
    fontSize: 'calc(1em*3/7)',
    fontWeight: 'bold',
    lineHeight: '24px',
    marginLeft: '8px',
    letterSpacing: '4px',
    // textShadow: '1px 1px 1px grey',
    '@media screen and (max-width:550px)': {
      marginLeft: 0,
    },
  },
}));

const ContentTitle = (props) => {
  const classes = useStyles({ color: props.color });
  const titleList = [...props.title.titleList];

  const titleSplitter = (title) => {
    // eslint-disable-next-line
    let match = title.match(/^([【「\[\]',"（）()]+)(.*)$/);
    let firstPart = match ? match[1] : '';
    let secondPart = match ? match[2] : title;
    return (
      <span>
        <span className={classes.remaining}>{firstPart}</span>
        <span className={classes.contentTitleHead}>
          {secondPart.slice(0, 1)}
        </span>
        <span className={classes.remaining}>{secondPart.slice(1)}</span>
      </span>
    );
  };

  return (
    <Box className={classes.contentTitle}>
      <span className={classes.titleWrapper}>
        {titleList.map((title, index) => {
          if (props.link) {
            return (
              <span key={index}>
                <Link to={props.link}>{titleSplitter(title)}</Link>
              </span>
            );
          } else {
            return <span key={index}>{titleSplitter(title)}</span>;
          }
        })}
      </span>
      <span className={classes.subTitle}> {props.title.sub}</span>
    </Box>
  );
};

export default ContentTitle;
