import React from 'react';
import { makeStyles } from '@material-ui/core';
import imageIndex from '../img/imageIndex';

const useStyles = makeStyles((theme) => ({
  iconContainer: {},
  optionContainer: {
    position: 'relative', // for iconContainer
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '40%',
    height: 'auto',
    minHeight: '400px',
    padding: '32px',
    margin: '16px',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
      transform: 'scale(0.98)',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    '&.selected': {
      transform: 'scale(1.02)',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
      borderColor: theme.palette.primary.main,
      borderWidth: '2px',
    },
    '& > div': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& $iconContainer': {
      position: 'absolute',
      top: '-40px',
      left: '-28px',
      justifyContent: 'flex-start',
      '& > img': {
        width: '80px',
        height: '80px',
      },
    },
    '@media screen and (max-width: 600px)': {
      width: '100%',
      height: 'auto',
      maxWidth: '100%',
      margin: '32px 0',
      '& $iconContainer': {
        left: '-12px',
      },
    },
  },
  label: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '8px',
    '& > span': {
      color: theme.palette.primary.main,
      fontWeight: 900,
    },
  },
  price: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '24px',
  },
  description: {
    fontSize: '0.95rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& > div': {
      width: '90%',
      textAlign: 'start',
      '& > span': {
        filter:
          'invert(70%) sepia(60%) saturate(300%) hue-rotate(80deg) brightness(100%) contrast(85%)', //緑色に変更している
        margin: '0 8px 8px 0',
      },
    },
  },
  radioButton: {
    display: 'none',
  },
}));

const MembershipOption = ({
  label,
  value,
  price,
  description,
  selectedValue,
  productUuid,
  onChange,
}) => {
  const classes = useStyles();

  function convertTextToHtml(description) {
    // 改行でテキストを分割
    const lines = description.split('\n');
    // 各行を変換
    const convertedLines = lines.map((line) => {
      if (line.startsWith('・')) {
        // 「・」をチェックマークに変更
        return `<div><span>✔️</span>${line.slice(1)}</div>`;
      } else {
        return `<div>${line}</div>`;
      }
    });
    // 変換された行を連結して返す
    return convertedLines.join('');
  }

  return (
    <div
      className={`${classes.optionContainer} ${
        selectedValue === value ? 'selected' : ''
      }`}
      onClick={() => onChange({ target: { value }, productUuid })}
    >
      {value === 'subscription' && (
        <div className={classes.iconContainer}>
          <img src={imageIndex.recommendedIcon} alt="Recommended Icon" />
        </div>
      )}
      <input
        type="radio"
        name="membership"
        value={value}
        checked={selectedValue === value}
        readOnly
        className={classes.radioButton}
      />
      <div className={classes.label}>
        <span>{label.charAt(0)}</span>
        {label.slice(1)}
      </div>
      <div className={classes.price}>
        <span>
          {price.toLocaleString()}
          <span style={{ fontSize: '1.1rem' }}>
            &nbsp;円 /&nbsp;
            {value === 'subscription' ? '月' : '1コマ'}
          </span>
        </span>
      </div>
      <div
        className={classes.description}
        dangerouslySetInnerHTML={{ __html: convertTextToHtml(description) }}
      />
    </div>
  );
};

export default MembershipOption;
