import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import { Slide } from '@material-ui/core';
import { images as imageApi } from '../Apis/apis';

import imageIndex from '../img/imageIndex';
import various from '../General/various';
import { ogpGenerator } from '../ogp';

import ArrowButton from '../General/arrowButton';
import GrowIn from '../Animation/growIn';
import OnOffByScroll from '../Animation/onOffByScroll';
import AboutOnTopPage from '../PageComponents/aboutOnTopPage';
import SeminarOnTopPage from '../PageComponents/seminarOnTopPage';
import BranchOnTopPage from '../PageComponents/branchOnTopPage';
import ToolsOnTopPage from '../PageComponents/toolsOnTopPage';
import VoicesOnTopPage from '../PageComponents/voicesOnTopPage';
import ClassOnTopPage from '../PageComponents/classOnTopPage';
import ContactOnTopPage from '../PageComponents/contactOnTopPage';
import NewsOnTopPage from '../PageComponents/newsOnTopPage';
import Footer from '../Footer/footer';
import { Link } from 'react-router-dom';
import TopicsOnTopPage from '../PageComponents/topcsOnTopPage';
import { Spinner } from '../Spinner';

const useStyles = makeStyles((theme) => ({
  box: {
    marginRight: 'calc(50% - 50vw)',
    marginLeft: 'calc(50% - 50vw)',
    height: '96vh',
    position: 'relative',
    '& #image': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: '20% 50%',
      // filter: 'contrast(100%) grayscale(100%) saturate(0%)',
    },
    '& #header': {
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100%',
    },
    '& > #arrow': {
      position: 'absolute',
      bottom: '10%',
      right: '3%',
      '& > a': {
        '& > button': {
          '& > span': {
            '& > span': {
              textStroke: '0.5px darkgray',
            },
          },
        },
      },
    },
    '&@media screen and (max-width:400px)': {
      height: '88vh',
    },
    '& #umekusa': {
      // width: '100vh',
      marginRight: 'calc(50% - 50vw)',
      marginLeft: 'calc(50% - 50vw)',
      height: '4vh',
      backgroundColor: 'black',
    },
  },

  container: {
    '& > div': {
      marginTop: '168px',
      marginBottom: '32px',
    },
  },
}));

const TopPage = () => {
  const [image, setImage] = useState();
  const [view, setView] = useState();
  const [animation, setAnimation] = useState();
  const [top, setTop] = useState();
  const [contents, setContents] = useState();

  const location = useLocation();
  const classes = useStyles();

  const MAXDIMENTION = 450;

  // OGP設定
  const ogp = useCallback((props) => {
    ogpGenerator(props);
  }, []);

  useLayoutEffect(() => {
    if (image) {
      const ogpProps = {
        page: 'top',
        pageTitle: '刀禪OfficialWebsite Topページ',
        path: location.pathname,
        imageUrl: image.OfficialImage_TouzenLogoBlack.url,
      };
      ogp(ogpProps);
    }
  }, [image, location, ogp]);
  // OGP設定ここまで

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    if (
      windowDimensions.width < MAXDIMENTION ||
      windowDimensions.height < MAXDIMENTION
    )
      return setAnimation(false);
    else return setAnimation(true);
  }, [windowDimensions]);

  const callback = useCallback(() => {
    if (image) {
      return (
        <div className={classes.box}>
          <img
            id="image"
            src={image.OfficialImage_topPageImage.url}
            alt="topImage"
          />
          <div id="header">
            <OnOffByScroll place="TopPageHeader" />
          </div>
          <GrowIn timeout={3333} page="topPageLogo" animation={animation}>
            <img src={imageIndex.logo_svg} alt="topPageLogo" />
          </GrowIn>
          <Slide direction="left" in={true} timeout={1000}>
            <div id="arrow">
              <Link to={various.general.seminar}>
                <ArrowButton color="#FFF" buttonName="METHOD ON SEMINAR" />
              </Link>
            </div>
          </Slide>
          <div id="umekusa"></div>
        </div>
      );
    }
  }, [image, classes, animation]);

  // useEffect(() => {
  //   newsDistribution();
  // }, []);

  useEffect(() => {
    imageApi(setImage);
  }, []);

  useEffect(() => {
    if (image) {
      setView(true);
    }
  }, [image]);

  useEffect(() => {
    if (view) {
      setTop(callback());
      setContents(true);
    }
  }, [view, callback]);

  return (
    <>
      {top}
      {contents ? (
        <>
          <div className={classes.container}>
            <div>
              <TopicsOnTopPage />
            </div>
            <div>
              <ClassOnTopPage />
            </div>
            <div>
              <AboutOnTopPage />
            </div>
            <div>
              <SeminarOnTopPage />
            </div>
            <div>
              <BranchOnTopPage />
            </div>
            <div>
              <ToolsOnTopPage />
            </div>
            <div>
              <VoicesOnTopPage />
            </div>
            <div>
              <ContactOnTopPage />
            </div>
            <div>
              <NewsOnTopPage />
            </div>
          </div>
          <Footer />
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default TopPage;
