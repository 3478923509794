import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import { updateInstance } from '../../../Apis/apiHandlers';
import { newsDetailForAdmin } from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import Footer from '../../../Footer/footer';
import ContentTitle from '../../../General/contentTitle';
import various from '../../../General/various';
import imageIndex from '../../../img/imageIndex';
import SitemapGenerator from '../../../sitemapGenerator';

const useStyles = makeStyles((theme) => ({
  published: {
    marginTop: '168px',
    marginBottom: '84px',
    textAlign: 'start',
    '& > span': {
      display: 'block',
      fontSize: '1rem',
      padding: '16px',
    },
    '& > button': {
      width: '100%',
      margin: '40px 0px 80px',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '8px 16px',
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
    '& > div': {
      width: '100%',
      fontSize: '0.9rem',
      color: 'grey',
      textAlign: 'start',
      marginBottom: '32px',
    },
    borderBottom: '2px dotted #8c8b8b',
  },
  contentTitle: {
    marginTop: '168px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'baseline',
    '& > div': {
      marginBottom: '64px',
      lineHeight: '72px',
      '& > span': {
        '& > span': {
          '& > span': {
            lineHeight: '64px !important',
            textAlign: 'justify',
          },
        },
      },
    },
  },
  dateHash: {
    width: '100%',
    textAlign: 'start',
    fontWeight: 'bold',
    display: 'flex',
    flexFlow: 'column',
    gap: '16px 16px',
    marginBottom: '80px',
    borderBottom: 'solid 0.1px grey',
    '& > span': {
      display: 'flex',
      gap: '0px 16px',
      fontSize: '1rem',
      fontStyle: 'italic',
      marginBottom: '32px',
    },
    '& #share': {
      width: '100%',
      display: 'flex',
      justifyContent: 'end',
      '& > button': {
        display: 'block',
        width: '56px',
        '& > img': {
          color: '#000',
          minWidth: '16px',
        },
      },
    },
  },
  content: {
    // display: 'flex',
  },
  contentImage: {
    width: 'calc(70vw * 0.35)',
    marginRight: '24px',
    float: 'left',
    '& > img': {
      width: 'calc(70vw * 0.3)',
      height: 'calc(70vw * 0.3)',
      objectFit: 'cover',
      // filter:
      //   'contrast(100%) grayscale(100%) saturate(0%) drop-shadow(2px 2px 2px black)',
    },
    '& > a': {},
  },
  contentText: {
    width: '100%',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    lineHeight: '28px',
    fontSize: '1rem',
    textAlign: 'justify',
    '& > span': {
      '& > a': {
        display: 'inline-block',
        '&:link': {
          color: '#000',
        },
        '&:visited': {
          color: '#000',
        },
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  authText: {
    fontSize: '1rem',
    textAlign: 'start',
    '& > span': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  },
}));

const NewsDetailConfirm = () => {
  const { isAuth } = useContext(AuthContext);
  const [detailData, setDetailData] = useState();
  const [push, setPush] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const cls = useStyles();

  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handlePublished = async () => {
    const data = {};
    const imageFileKey = 'news_image';
    data[imageFileKey] = '';
    data['uuid'] = location.state.uuid;
    data['delivered'] = true;

    try {
      await updateInstance('News', data, imageFileKey);
      setPush(true);
      console.log('update finish!');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.state.uuid) {
      newsDetailForAdmin(setDetailData, location.state.uuid);
    }
  }, [location]);

  const contentMapping = () => {
    const linkedContent = (text) => {
      // 参考：https://omkz.net/text-url-link/
      const regExp = /(https?:\/\/[\w/:%#$&?()~.=+-]+)/g;
      return text.replace(regExp, '<a href="$1">$1</a>');
    };
    if (detailData) {
      return (
        <div>
          <div className={cls.contentTitle}>
            <ContentTitle
              title={{ titleList: [detailData.title], sub: null }}
            />
          </div>
          <div className={cls.dateHash}>
            <div id="share">
              <FacebookShareButton>
                <img src={imageIndex.newsPage.detail.fbIcon} alt="fb" />
              </FacebookShareButton>
              <TwitterShareButton>
                <img src={imageIndex.newsPage.detail.twIcon} alt="tw" />
              </TwitterShareButton>
            </div>
            <div>{detailData.delivery_date.split(' ')[0]}</div>
            <span>
              {detailData.tag.map((h, i) => (
                <span key={i}>{h}</span>
              ))}
            </span>
          </div>
          <div className={cls.content}>
            <div className={cls.contentImage}>
              <img src={detailData.news_image} alt="contentImage" />
            </div>
            <div className={cls.contentText}>
              <span
                dangerouslySetInnerHTML={{
                  __html: linkedContent(detailData.content),
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  const object = {
    previous: location.state.previous,
    state: { uuid: location.state.uuid },
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {isAuth ? (
        <>
          <div className={cls.published}>
            <p>
              以下の内容で公開してよろしければ、「公開する」ボタンを押下してください。
            </p>
            <span>
              ご注意：
              <br />
              押下後は、「公開予定日時」を過ぎていれば即刻公開され、過ぎていなければ「公開予定」として、ニュース一覧にリストされます。公開予定日時を過ぎれば自動的に公開されます。また、併せて、ニュース配信を「有り」に設定していれば、公開と同時にニュース配信も行われます。
            </span>
            <button onClick={handlePublished}>公開する</button>
            <div>※以下、ニュース公開イメージです。</div>
          </div>
          {contentMapping()}
          {push ? <SitemapGenerator push={push} navigate={object} /> : ''}
          <Footer />
        </>
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default NewsDetailConfirm;
