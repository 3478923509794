import { makeStyles } from '@material-ui/core';
import OnOffByScroll from '../Animation/onOffByScroll';
import Footer from './footer';
import various from '../General/various';

const useStyles = makeStyles((theme) => ({
  container: {
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      fontSize: '1rem',
      '& > h3': {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        textAlign: 'start',
        margin: '24px 0px 0px 0px',
      },
      '& > span': {
        textAlign: 'start',
      },
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        textAlign: 'start',
        '& > p': {
          fontWeight: 'bold',
          margin: '16px 0px 0px 0px',
        },
      },
    },
    '& > p': {
      fontSize: '0.9em',
      textAlign: 'start',
      color: 'gray',
    },
  },
}));

const CommerceDisclosure = () => {
  const classes = useStyles();
  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.container}>
        <h2>特定商取引法に基づく表記</h2>
        <hr />
        <div>
          <h3>販売業者</h3>
          <span>刀禅 小用茂夫</span>
        </div>
        <div>
          <h3>所在地</h3>
          <span>請求があったら遅滞なく開示します。</span>
        </div>
        <div>
          <h3>電話番号</h3>
          <span>請求があったら遅滞なく開示します。</span>
        </div>
        <div>
          <h3>メールアドレス</h3>
          <span>touzen.noreply@gmail.com</span>
        </div>
        <div>
          <h3>運営統括責任者</h3>
          <span>小用茂夫</span>
        </div>
        <div>
          <h3>追加手数料等の追加料金</h3>
          <span>なし</span>
        </div>
        <div>
          <h3>返金ポリシー</h3>
          <div>
            <p>{various.general_member}の場合</p>
            <span>
              定例会費についての返金対応はいたしません。会員区分を変更または退会した場合、月末までは
              {various.general_member}
              としての権利を有し、翌月1日に区分の変更または退会扱いとなります。
            </span>
          </div>
          <div>
            <p>{various.num_member}の場合</p>
            <span>
              稽古への参加やセミナー参加時点で決済が完了しているため、MyPageから参加（申込）キャンセルの手続きが完了した時点で即時に返金手続きを行います。
              なお、返金額は決済時に生じた決済手数料を差し引いた金額となります。
            </span>
          </div>
        </div>
        <div>
          <h3>引渡時期</h3>
          <span>稽古開催日またはセミナー開催日に提供されます。</span>
        </div>
        <div>
          <h3>受付可能な決済手段</h3>
          <span>現金またはクレジットカード</span>
        </div>
        <div>
          <h3>決済期間</h3>
          <span>
            クレジットカード決済の場合、申込時点でただちに決済処理がなされます。
          </span>
        </div>
        <div>
          <h3>販売価格</h3>
          <span>MyPage、会則、セミナーページをご参照ください。</span>
        </div>
        <p>更新日:令和6年8月19日</p>
      </div>
      <Footer />
    </>
  );
};
export default CommerceDisclosure;
