import axios from 'axios';
// import { envParams } from './envParams';
import various from '../General/various';
import { localStorageArrayHandler } from '../CommonFunction/managePermission';

export function branchIndexForFront(setState) {
  axios
    .get(`${various.currentDomain}api/branch_index_for_front/`)
    .then((res) => {
      setState(res.data);
    });
}

export function branchNameIndex(setState) {
  axios.get(`${various.currentDomain}api/branch_name_index/`).then((res) => {
    setState(res.data);
  });
}

export function images(setState, setLoading) {
  // axios.get(`/api/images`, { params: envParams }).then((res) => {
  //   setState(res.data);
  // });
  axios.get(`${various.currentDomain}api/images/`).then((res) => {
    const result = {};
    res.data.forEach((obj) => {
      let title = obj.title;
      let pattern = /_dev|_staging/;
      if (pattern.test(title)) {
        title = title.replace(pattern, '');
      }
      result[`${obj.use_place}_${title}`] = {
        uuid: obj.uuid,
        title: title,
        use_place: obj.use_place,
        url: obj.content,
        descs: obj.description,
      };
    });
    setState(result);
    // setLoading(false);
  });
}

export function paramsIndexForGeneral(setState) {
  axios
    .get(`${various.currentDomain}api/params_index_for_general/`)
    .then((res) => {
      setState(res.data);
    });
}

export function newsIndex(setState, page_size) {
  axios
    .get(`${various.currentDomain}api/news_index/?page_size=${page_size}`)
    .then((res) => {
      const result = [];
      res.data.data.forEach((obj, index) => {
        result.push({
          id: obj.uuid,
          title: obj.title,
          distribution_is: obj.distribution_is,
          isMembersOnly: obj.is_members_only,
          topics: obj.is_topics,
          delivered: obj.delivered,
          disclosureRange: obj.disclosure_range,
          date: obj.delivery_date,
          hash: obj.tag.map((news) => `#${news.name}`),
          contentText: obj.content,
          image: obj.news_image,
        });
      });
      setState(result);
    });
}

export function topicsIndex(setState) {
  axios.get(`${various.currentDomain}api/topics_index/`).then((res) => {
    const result = [];
    res.data.forEach((obj, index) => {
      result.push({
        id: obj.uuid,
        title: obj.title,
        distribution_is: obj.distribution_is,
        isMembersOnly: obj.is_members_only,
        topics: obj.is_topics,
        topics_end_date: obj.topics_end_date,
        delivered: obj.delivered,
        disclosureRange: obj.disclosure_range,
        date: obj.delivery_date,
        hash: obj.tag.map((news) => `#${news.name}`),
        contentText: obj.content,
        image: obj.news_image,
      });
    });
    setState(result);
  });
}

export function newsDetail(setState, uuid) {
  axios
    .get(`${various.currentDomain}api/news_detail/${uuid}/`)
    .then((res) => {
      const obj = res.data.data;
      const result = {
        id: obj.uuid,
        title: obj.title,
        date: obj.date,
        hash: obj.tags,
        contentText: obj.content,
        image: obj.news_image,
      };
      setState(result);
    })
    .catch((err) => {});
}

export function seminarIndex(setState) {
  return axios
    .get(`${various.currentDomain}api/seminar_index_for_front/`)
    .then((res) => {
      const result = [];
      res.data.data.forEach((obj, index) => {
        result.push({
          id: obj.uuid,
          title: obj.title ? obj.title : 'No Title',
          num: obj.serial_number,
          hash: obj.tag.map((obj) => `#${obj.name}`),
          image: obj.image,
          documents: obj.documents,
          instructor: obj.instructor.image,
          instructorName: obj.instructor ? obj.instructor.name : '刀禅講師',
          biography: obj.instructor ? obj.instructor.biography : '-',
          read: obj.guidance ? obj.guidance : '-',
          target: obj.subject,
          cap: obj.capacity ? obj.capacity : '-',
          numOfApplicans: obj.num_of_applicans,
          fee: obj.entry_fee ? obj.entry_fee : 0,
          membership_fee: obj.membership_fee ? obj.membership_fee : 0,
          date: obj.event_date_start_time,
          section1Start: obj.part_one_start_time,
          section1End: obj.part_one_end_time,
          section2Start: obj.part_two_start_time,
          section2End: obj.part_two_end_time,
          section3Start: obj.part_three_start_time,
          section3End: obj.part_three_end_time,
          section4Start: obj.part_four_start_time,
          section4End: obj.part_four_end_time,
          facilityName: obj.place.facility_name,
          placeAddress: obj.place.address,
          googleMap: obj.place.gmap,
          hall: obj.hall.name,
          place: obj.display_name_of_the_place
            ? obj.display_name_of_the_place
            : '-',
          placeSub: '※詳細はお申し込みされた方のみにお知らせいたします。',
          attention: obj.notes ? obj.notes : '-',
          show_the_instructor: obj.show_the_instructor,
          is_secret: obj.is_secret,
          is_reminded: obj.is_reminded,
          payment_method: obj.payment_method,
        });
      });
      setState(result);
    });
}

export function seminarIndexForToppage(setState) {
  axios
    .get(`${various.currentDomain}api/seminar_index_for_toppage/`)
    .then((res) => {
      setState(res.data.results);
    });
}

export function seminarDetail(setState, uuid) {
  // axios.get(`/api/seminar`, { params: envParams }).then((res) => {
  //   setState(res.data);
  // });
  axios
    .get(`${various.currentDomain}api/seminar_detail/${uuid}`)
    .then((res) => {
      setState(res.data);
    });
}

export function seminarDetailPreview(setState, uuid) {
  axios
    .get(`${various.currentDomain}api/seminar_detail_preview/${uuid}`)
    .then((res) => {
      setState(res.data.data);
    });
}

export function seminarApply(setState, setMessage, dataset) {
  return axios
    .post(`${various.currentDomain}api/recaptcha/`, dataset)
    .then((res) => {
      axios
        .patch(
          `${various.currentDomain}api/seminar_apply/${dataset.id}/`,
          res.data.verify_data
        )
        .then((res) => {
          console.log('seminar apply done...');
          if (res.data.code === 400) {
            setMessage(res.data.message);
          } else if (res.data.code === 200) {
            setState(true);
          }
        })
        .catch((err) => {
          console.log('error on add seminar apply', err);
          if (err.response && err.response.data) {
            // サーバーからのエラーメッセージを取得し、setMessageに渡します
            setMessage(err.response.data.message);
          }
        });
    })
    .catch((err) => {
      console.log('not varifying by reCAPTCH.');
    });
}

export function seminarRemindMail(dataset) {
  return axios
    .patch(
      `${various.currentDomain}api/seminar_remind_mail/${dataset.uuid}/`,
      dataset
    )
    .then((res) => {
      console.log('send mail done : ');
    })
    .catch((err) => {
      console.log('error in apiOfManaged/semina_remind_mail :', err);
    });
}

export function checkSeminarStatus(setState, setErrorMessage, data) {
  axios
    .post(`${various.currentDomain}api/check_seminar_status/`, data)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status === 'success') {
          setState(true);
        } else {
          setState(false);
          setErrorMessage(res.data.message);
        }
      } else {
        console.log('Unexpected status code:', res.status);
      }
    })
    .catch((err) => {
      console.log('error in check_seminar_status :', err);
    });
}

// セキュリティ上問題あり不使用
// export function memberIndexOfNonAuth(setState) {
//   axios.get(`${various.currentDomain}api/member_index_no_auth`).then((res) => {
//     setState(res.data);
//   });
// }

export function memberExistanceCheckApi(setState, dataset) {
  axios
    .post(`${various.currentDomain}api/member_existance_check/`, dataset)
    .then((res) => {
      if (res.data.code === 200) {
        console.log(res.data.message);
        setState(true);
      } else {
        console.log('memberExistanceCheck error...');
        setState(false);
      }
    })
    .catch((err) => {
      console.log('memberExistanceCheck error...');
      setState(false);
    });
}

export function permissionCheck(dataset, setState) {
  axios
    .post(`${various.currentDomain}api/permission_check/`, dataset)
    .then((res) => {
      if (res.data.code === 200) {
        localStorageArrayHandler(
          'stringify',
          various.permissionKey,
          res.data.data.permissions
        );
        setState(true);
      }
    })
    .catch((err) => {
      console.log('Error in permission check..:', err);
    });
}

// セキュリティ上問題あり不使用
// export function memberDetailOfNonAuth(setState, uuid) {
//   axios
//     .get(`${various.currentDomain}api/member_detail_no_auth/${uuid}/`)
//     .then((res) => {
//       setState(res.data);
//       console.log('get memberDetail');
//     })
//     .catch((err) => {
//       console.log('error in apiOfManaged/memberDetail :');
//     });
// }

export function userCreateByAddNewsLetter(dataset, setState) {
  axios
    .post(`${various.currentDomain}api/recaptcha/`, dataset)
    .then((res) => {
      axios
        .post(
          `${various.currentDomain}api/create_user/by_add_news_letter/`,
          res.data.verify_data
        )
        .then((res) => {
          setState(true);
          console.log('Add newsLetter done...');
        })
        .catch((err) => {
          console.log('error on add newsLetter :');
        });
    })
    .catch((err) => {
      console.log('not varifying by reCAPTCH.');
    });
}

export function memberDestroyByUnsubscribe(uuid) {
  axios
    .delete(`${various.currentDomain}api/member_destroy_no_auth/${uuid}/`)
    .then((res) => {
      console.log('status changed done...');
    })
    .catch((err) => {
      console.log('error in member destroy...');
    });
}

// resetPassword2系に以降、forgotPassword.js, passwordSetting.jsで使用／paiは/api/password_reset/というライブラリを使用していたが、自作の2系に移行したという経緯
// export function resetPassword(setState, email) {
//   axios
//     .post(`${various.currentDomain}api/password_reset/`, {
//       email: email,
//     })
//     .then((res) => {
//       console.log('send token');
//       setState(true);
//     })
//     .catch((err) => {
//       console.log('error in send password reset token');
//       setState(true);
//     });
// }
// export function resetPassword(setState, dataset) {
//   axios
//     .post(`${various.currentDomain}api/recaptcha/`, dataset)
//     .then((res) => {
//       axios
//         .post(`${various.currentDomain}api/password_reset/`, {
//           email: dataset.email,
//         })
//         .then((res) => {
//           console.log('send token');
//           setState(true);
//         })
//         .catch((err) => {
//           console.log('error in send password reset token');
//           setState(true);
//         });
//     })
//     .catch((err) => {
//       console.log('not varifying by reCAPTCH.');
//     });
// }
// export function resetPasswordConfirm(setState, token, password) {
//   axios
//     .post(`${various.currentDomain}api/password_reset/confirm/`, {
//       token: token,
//       password: password,
//     })
//     .then((res) => {
//       console.log('send token');
//       setState(true);
//     })
//     .catch((err) => {
//       console.log('error in send password reset token');
//       setState(false);
//     });
// }

export function resetPassword2(setState, dataset) {
  axios
    .post(`${various.currentDomain}api/recaptcha/`, dataset)
    .then((res) => {
      axios
        .post(
          `${various.currentDomain}api/reset_password/`,
          res.data.verify_data
        )
        .then((res) => {
          if (res.data.code === 200) {
            console.log('send token');
            setState(true);
          } else {
            console.log('error in send password reset token');
            setState(true);
          }
        })
        .catch((err) => {
          console.log('error in send password reset token');
          setState(true);
        });
    })
    .catch((err) => {
      console.log('not varifying by reCAPTCH. : ', err);
    });
}

export function resetPasswordConfirm2(setState, token, password) {
  axios
    .post(`${various.currentDomain}api/reset_password_confirm/`, {
      token: token,
      password: password,
    })
    .then((res) => {
      console.log('send token');
      setState(true);
    })
    .catch((err) => {
      console.log('error in send password reset token');
      setState(false);
    });
}

export function resetEmailConfirm(setState, setMessage, dataset) {
  return axios
    .post(`${various.currentDomain}api/email_change_confirm/`, dataset)
    .then((res) => {
      if (res.data.code === 400) {
        setMessage(res.data.message);
      } else if (res.data.code === 200) {
        setState(true);
      }
    })
    .catch((err) => {
      setMessage(err.data.message);
      setState(false);
    });
}

export function contact(dataset) {
  axios
    .post(`${various.currentDomain}api/recaptcha/`, dataset)
    .then((res) => {
      axios
        .post(`${various.currentDomain}api/contact/`, res.data.verify_data)
        .then((res) => {
          console.log('contact reply done...');
        })
        .catch((err) => {
          console.log('error on add contact');
        });
    })
    .catch((err) => {
      console.log('not varifying by reCAPTCH.');
    });
}

export function memberCreateOfNonAuthByContactForTraining(
  dataset,
  setState,
  setState2
) {
  axios
    .post(`${various.currentDomain}api/recaptcha/`, dataset)
    .then((res) => {
      axios
        .post(
          `${various.currentDomain}api/member_create_by_contactfortraining_no_auth/`,
          dataset
        )
        .then((res) => {
          console.log('member create done...');
          axios
            .post(
              `${various.currentDomain}api/contact/`,
              res.data.response_data
            )
            .then((res) => {
              console.log('contact reply done...');
              setState(true);
            })
            .catch((err) => {
              console.log('error on add contact');
              setState(false);
            });
        })
        .catch((err) => {
          setState2(true);
        });
    })
    .catch((err) => {
      console.log('not varifying by reCAPTCH.');
    });
}

export function noMatch() {
  axios
    .get(`${various.currentDomain}api/nomatch/`)
    .then((res) => {
      console.log('page not found...');
    })
    .catch((err) => {});
}

// 以下、Loading状態を管理しSpinner表示用の関数

export function fetchImages(setState, setLoading) {
  axios
    .get(`${various.currentDomain}api/images/`)
    .then((res) => {
      const result = {};
      res.data.forEach((obj) => {
        let title = obj.title;
        let pattern = /_dev|_staging/;
        if (pattern.test(title)) {
          title = title.replace(pattern, '');
        }
        result[`${obj.use_place}_${title}`] = {
          uuid: obj.uuid,
          title: title,
          use_place: obj.use_place,
          url: obj.content,
          descs: obj.description,
        };
      });
      setState(result);
    })
    .finally(() => {
      setLoading(false);
    });
}

// Stripe関連
export function getProductsInfo(setState) {
  axios
    .get(`${various.currentDomain}api/get_products_info/`)
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in getProductsInfo :', err);
    });
}

export function getJsonData(setState, session_id) {
  axios
    .get(`${various.currentDomain}api/get_json_data/?session_id=${session_id}`)
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in get_json_data :', err);
    });
}

export function choiceSeminarProduct(data, setState, setIsFreeMember) {
  return axios
    .post(`${various.currentDomain}api/choice_seminar_product/`, data)
    .then((res) => {
      if (res.data.seminar_product_uuid) {
        setState(res.data);
        setIsFreeMember(false);
      } else {
        setState(null);
        setIsFreeMember(true);
      }
    })
    .catch((err) => {
      setState('error');
      console.log('error in choice_seminar_product :', err);
    });
}

export function productMasterDetailOnlyPrice(setState, uuid) {
  return axios
    .get(
      `${various.currentDomain}api/product_master_detail_only_price/${uuid}/`
    )
    .then((res) => {
      setState(res.data);
    })
    .catch((err) => {
      console.log('error in product_master_detail_only_price', err);
    });
}
