import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';

import OnOffByScroll from '../../../Animation/onOffByScroll';
import { AuthContext } from '../../../auth';
import various from '../../../General/various';
import { updateInstance } from '../../../Apis/apiHandlers';
import {
  memberDetail,
  memberIndex,
  paramsIndex,
} from '../../../Apis/apiOfManaged';
import {
  emailRegValidator,
  selectDefaultCheck,
} from '../../../CommonFunction/validators';
import Footer from '../../../Footer/footer';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  titles: {},
  inputs: {},
  helptext: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  container: {
    marginTop: '168px',
    width: '100%',
    '& > form': {
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '& > img': {
            maxWidth: '300px',
            maxHeight: '300px',
            objectFit: 'contain',
          },
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > label': {
            width: '100%',
            textAlign: 'end',
            fontSize: '0.9rem',
            '@media screen and (max-width:750px)': {
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
          '& > span': {
            color: 'red',
          },
        },

        '& $inputs': {
          width: '70%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input, & > textarea, & > select': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
          '& > textarea': {
            minHeight: '160px',
          },
          '& > input[type="checkbox"]': {
            boxShadow: 'none',
          },
          '& $helptext': {
            fontSize: '0.8rem',
            color: 'grey',
            '& > a': {
              textDecoration: 'underline',
              '&:link': {
                color: theme.palette.primary.main,
              },
            },
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    width: '100% !important',
    marginTop: '160px !important',
    '& > button': {
      width: '100%',
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const MemberUpdate = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {},
  });

  const { isAuth } = useContext(AuthContext);
  const [member, setMember] = useState();
  const [members, setMembers] = useState([]);
  const [params, setParams] = useState();
  const [runStripe, setRunStripe] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '会員一覧', path: '/member_index' },
    { name: '会員情報更新', path: '/member_update' },
  ];

  // Init
  useEffect(() => {
    if (location) {
      memberDetail(setMember, location.state.uuid);
    }
  }, [location]);

  useEffect(() => {
    memberIndex(setMembers);
  }, []);

  useEffect(() => {
    if (member) {
      const rejectList = [
        'belongs',
        'member_of_honor',
        'roushi_is',
        'num_of_seinar_participation',
        'num_of_club_participation',
        'num_of_training_participation',
        'num_of_application_forms_sent',
        'image',
        'created_at',
        'user',
        'seminar',
        'participated_seminars',
        'permission_level',
      ];
      const keys = Object.keys(member);
      const resetValues = {};
      keys.forEach((k) => {
        if (!rejectList.includes(k)) {
          if (k === 'is_member') {
            if (runStripe) {
              if (member['member_of_general_is']) {
                resetValues['is_member'] = 'member_of_general_is';
              } else if (member['member_of_num_is']) {
                resetValues['is_member'] = 'member_of_num_is';
              } else if (member['is_training_group_member']) {
                resetValues['is_member'] = 'is_training_group_member';
              } else {
                resetValues['is_member'] = 'non_member';
              }
            } else {
              if (member[k]) {
                resetValues[k] = k;
              } else {
                if (member['is_training_group_member']) {
                  resetValues['is_member'] = 'is_training_group_member';
                } else {
                  resetValues['is_member'] = 'non_member';
                }
              }
            }
          } else {
            resetValues[k] = member[k];
          }
        }
      });
      reset(resetValues);
    }
  }, [member, runStripe, reset]);

  useEffect(() => {
    paramsIndex(setParams);
  }, []);

  useEffect(() => {
    if (params && member) {
      const is_run = params.find((param) => {
        return param.title === 'run_stripe_in_production';
      });
      const is_all_members = params.find((param) => {
        return param.title === 'run_stripe_all_members';
      });
      if (is_run?.apply_is) {
        if (is_all_members?.apply_is) {
          setRunStripe(true);
        } else {
          if (various.stripe.testRunStaff.includes(member.email)) {
            setRunStripe(true);
          }
        }
      }
    }
  }, [params, member]);

  // Submit
  const onSubmit = handleSubmit(async (data) => {
    const imageFileKey = 'image';
    data[imageFileKey] = '';

    // console.log('data:', data);
    if (data.is_member === 'is_member') {
      data.is_member = true;
      data.is_training_group_member = false;
    } else if (data.is_member === 'is_training_group_member') {
      data.is_member = false;
      data.is_training_group_member = true;
    } else if (data.is_member === 'non_member') {
      data.is_member = false;
      data.is_training_group_member = false;
    }

    if (data.member_of_general_is) {
      data.is_member = true;
      data.is_training_group_member = false;
      data.member_of_num_is = false;
    } else if (data.member_of_num_is) {
      console.log('come here');
      data.is_member = true;
      data.is_training_group_member = false;
      data.member_of_general_is = false;
    }

    try {
      // console.log('data:', data);
      await updateInstance('Member', data, imageFileKey);
      console.log('update finish!');
      navigate(location.state.previous, { state: { uuid: data.uuid } });
    } catch (error) {
      console.error('Error in updateInstance:', error);
    }
  });

  const memberExistenceCheck = (v) => {
    if (members.length > 0) {
      const emails = members.map((m) => {
        return m.email;
      });
      if (v === member.email) return true;
      else return !emails.includes(v);
    }
  };

  // Handle
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  // Contents
  const form = () => {
    if (member) {
      return (
        <>
          <div className={classes.container}>
            <form
              method="POST"
              encType="multipart/form-data"
              onSubmit={onSubmit}
            >
              <div>
                <div className={classes.titles}>
                  <label htmlFor="氏名（姓）">{'氏名（姓）'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="last_name"
                    type="text"
                    {...register('last_name', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.last_name?.message && (
                    <div className={classes.error}>
                      {errors.last_name.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="氏名（名）">{'氏名（名）'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="first_name"
                    type="text"
                    {...register('first_name', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.first_name?.message && (
                    <div className={classes.error}>
                      {errors.first_name.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="氏名（カナ姓）">{'氏名（カナ姓）'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="last_name_kana"
                    type="text"
                    {...register('last_name_kana', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.last_name_kana?.message && (
                    <div className={classes.error}>
                      {errors.last_name_kana.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="氏名（カナ名）">{'氏名（カナ名）'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="first_name_kana"
                    type="text"
                    {...register('first_name_kana', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.first_name_kana?.message && (
                    <div className={classes.error}>
                      {errors.first_name_kana.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="性別">{'性別'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <select
                    id="sex"
                    defaultValue="choice"
                    {...register('sex', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          selectDefaultCheck(v)
                            ? null
                            : `男性または女性のいずれかを選択してください。`,
                      },
                    })}
                  >
                    <option value="choice">選択してください</option>
                    <option value="male">男性</option>
                    <option value="female">女性</option>
                  </select>
                  {errors.sex?.message && (
                    <div className={classes.error}>{errors.sex.message}</div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="学生区分">{'学生区分'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="is_student"
                    type="checkbox"
                    {...register('is_student', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.is_student?.message && (
                    <div className={classes.error}>
                      {errors.is_student.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="社会人区分">{'社会人区分'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="is_worker"
                    type="checkbox"
                    {...register('is_worker', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.is_worker?.message && (
                    <div className={classes.error}>
                      {errors.is_worker.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="Eメール">{'Eメール'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="email"
                    type="email"
                    {...register('email', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          emailRegValidator(v)
                            ? memberExistenceCheck(v)
                              ? null
                              : `ご入力されたEメールはすでに登録済みです。`
                            : '※正しいEメールの形式で入力してください',
                      },
                    })}
                  />
                  {errors.email?.message && (
                    <div className={classes.error}>{errors.email.message}</div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="電話番号">{'電話番号'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="phone_number"
                    type="text"
                    {...register('phone_number', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.phone_number?.message && (
                    <div className={classes.error}>
                      {errors.phone_number.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="都道府県">{'都道府県'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="address1"
                    type="text"
                    {...register('address1', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.address1?.message && (
                    <div className={classes.error}>
                      {errors.address1.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="市区町村">{'市区町村'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="address2"
                    type="text"
                    {...register('address2', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.address2?.message && (
                    <div className={classes.error}>
                      {errors.address2.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="番地">{'番地'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="address3"
                    type="text"
                    {...register('address3', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.address3?.message && (
                    <div className={classes.error}>
                      {errors.address3.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="建物名">{'建物名'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="address4"
                    type="text"
                    {...register('address4', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.address4?.message && (
                    <div className={classes.error}>
                      {errors.address4.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="Facebookアカウント">
                    {'Facebookアカウント'}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="facebook_account"
                    type="url"
                    {...register('facebook_account', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.facebook_account?.message && (
                    <div className={classes.error}>
                      {errors.facebook_account.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="職業/勤務先">{'職業/勤務先'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="work_place"
                    type="text"
                    {...register('work_place', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.work_place?.message && (
                    <div className={classes.error}>
                      {errors.work_place.message}
                    </div>
                  )}
                </div>
              </div>
              {/* <div>
                <div className={classes.titles}>
                  <label htmlFor="入会金免除">{'入会金免除'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="admission_fee_exemption"
                    type="checkbox"
                    {...register('admission_fee_exemption', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    入会金を免除する場合は、チェックを入れてください。なお、入会金免除の有無は、会員本人には通知・表示されません。口頭等でお伝えください。
                  </div>
                  {errors.admission_fee_exemption?.message && (
                    <div className={classes.error}>
                      {errors.admission_fee_exemption.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="入会金受領の有無">{'入会金受領の有無'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="recieved_is"
                    type="checkbox"
                    {...register('recieved_is', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    入会金をを受領した場合は、チェックを入れてください。
                  </div>
                  {errors.recieved_is?.message && (
                    <div className={classes.error}>
                      {errors.recieved_is.message}
                    </div>
                  )}
                </div>
              </div> */}
              <div>
                <div className={classes.titles}>
                  <label htmlFor="会員区分">{'会員区分'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <select
                    id="is_member"
                    defaultValue="choice"
                    {...register('is_member', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          selectDefaultCheck(v)
                            ? null
                            : `${various.memberDesignations}、${various.otherMemberDesignations}、${various.nonMemberDesignations}のいずれかを選択してください。`,
                      },
                    })}
                  >
                    <option value="choice">選択してください</option>
                    <option value="is_member">
                      {various.memberDesignations}
                    </option>
                    <option value="member_of_general_is">
                      {various.general_member}
                    </option>
                    <option value="member_of_num_is">
                      {various.num_member}
                    </option>
                    <option value="is_training_group_member">
                      {various.otherMemberDesignations}
                    </option>
                    <option value="non_member">
                      {various.nonMemberDesignations}
                    </option>
                  </select>
                  {errors.is_member?.message && (
                    <div className={classes.error}>
                      {errors.is_member.message}
                    </div>
                  )}
                </div>
              </div>
              {/* <div>
                <div className={classes.titles}>
                  <label htmlFor="会員・非会員">{'会員・非会員'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="is_member"
                    type="checkbox"
                    {...register('is_member', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.is_member?.message && (
                    <div className={classes.error}>
                      {errors.is_member.message}
                    </div>
                  )}
                </div>
              </div> */}
              {/* <div>
                <div className={classes.titles}>
                  <label htmlFor="稽古会会員">{'稽古会会員'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="is_training_group_member"
                    type="checkbox"
                    {...register('is_training_group_member', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.is_training_group_member?.message && (
                    <div className={classes.error}>
                      {errors.is_training_group_member.message}
                    </div>
                  )}
                </div>
              </div> */}
              <div>
                <div className={classes.titles}>
                  <label htmlFor="紹介者">{'紹介者'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="introducer"
                    type="text"
                    {...register('introducer', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.introducer?.message && (
                    <div className={classes.error}>
                      {errors.introducer.message}
                    </div>
                  )}
                </div>
              </div>
              {/* <div>
                <div className={classes.titles}>
                  <label htmlFor="回数会員区分">{'回数会員区分'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="member_of_num_is"
                    type="checkbox"
                    {...register('member_of_num_is', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.member_of_num_is?.message && (
                    <div className={classes.error}>
                      {errors.member_of_num_is.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="定例会員区分">{'定例会員区分'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="member_of_general_is"
                    type="checkbox"
                    {...register('member_of_general_is', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.member_of_general_is?.message && (
                    <div className={classes.error}>
                      {errors.member_of_general_is.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="古参区分">{'古参区分'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="member_of_kosan"
                    type="checkbox"
                    {...register('member_of_kosan', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.member_of_kosan?.message && (
                    <div className={classes.error}>
                      {errors.member_of_kosan.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="入会金支払日">{'入会金支払日'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="palnned_deposit_date"
                    type="date"
                    {...register('palnned_deposit_date', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.palnned_deposit_date?.message && (
                    <div className={classes.error}>
                      {errors.palnned_deposit_date.message}
                    </div>
                  )}
                </div>
              </div> */}
              <div>
                <div className={classes.titles}>
                  <label htmlFor="入会日">{'入会日'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="date_of_join"
                    type="date"
                    {...register('date_of_join', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.date_of_join?.message && (
                    <div className={classes.error}>
                      {errors.date_of_join.message}
                    </div>
                  )}
                </div>
              </div>

              <div>
                <div className={classes.titles}>
                  <label htmlFor="備考">{'備考'}</label>
                </div>
                <div className={classes.inputs}>
                  <textarea
                    id="remarks"
                    type="textarea"
                    {...register('remarks', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.remarks?.message && (
                    <div className={classes.error}>
                      {errors.remarks.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="セミナー情報配信の可否">
                    {'セミナー情報配信の可否'}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="notification_of_seminar"
                    type="checkbox"
                    {...register('notification_of_seminar', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    新着セミナーのニュースとして、セミナー開催の旨を配信する場合はチェックを入れてください。配信が不要な場合はチェックを外してください。
                  </div>
                  {errors.notification_of_seminar?.message && (
                    <div className={classes.error}>
                      {errors.notification_of_seminar.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="ニュース情報配信の可否">
                    {'ニュース情報配信の可否'}
                  </label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="notification_of_news"
                    type="checkbox"
                    {...register('notification_of_news', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    新着ニュースの配信をする場合はチェックを入れてください。ニュース配信が不要な場合はチェックを外してください。
                  </div>
                  {errors.notification_of_news?.message && (
                    <div className={classes.error}>
                      {errors.notification_of_news.message}
                    </div>
                  )}
                </div>
              </div>
              <hr style={{ margin: '160px 0px 160px 0px' }} />
              <div>
                <div className={classes.titles}>
                  <label htmlFor="指導者区分">{'指導者区分'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="member_of_instructor"
                    type="checkbox"
                    {...register('member_of_instructor', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    指導者になられる場合は、ここにチェックを入れてください。
                  </div>
                  {errors.member_of_instructor?.message && (
                    <div className={classes.error}>
                      {errors.member_of_instructor.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="同好会主宰者区分">{'同好会主宰者区分'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="member_of_club_manager_is"
                    type="checkbox"
                    {...register('member_of_club_manager_is', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    同好会・特別稽古会の主宰者になられる場合は、ここにチェックを入れてください。
                  </div>
                  {errors.member_of_club_manager_is?.message && (
                    <div className={classes.error}>
                      {errors.member_of_club_manager_is.message}
                    </div>
                  )}
                </div>
              </div>
              {/* 現状では運用しない。運用開始する場合は、front & api双方ともに、セミナー系の機能をアップデート、front側apiの確認、humnmenuの見え方確認等をすること。*/}
              <div>
                <div className={classes.titles}>
                  <label htmlFor="セミナー主催者">{'セミナー主催者'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="member_of_organizer"
                    type="checkbox"
                    {...register('member_of_organizer', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    セミナーの主催者になられる場合は、ここにチェックを入れてください。
                  </div>
                  {errors.member_of_organizer?.message && (
                    <div className={classes.error}>
                      {errors.member_of_organizer.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="運営担当者区分">{'運営担当者区分'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="member_of_admin"
                    type="checkbox"
                    {...register('member_of_admin', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  <div className={classes.helptext}>
                    セミナーの現場運営担当になられる場合は、ここにチェックを入れてください。
                  </div>
                  {errors.member_of_admin?.message && (
                    <div className={classes.error}>
                      {errors.member_of_admin.message}
                    </div>
                  )}
                </div>
              </div>
              <hr style={{ margin: '160px 0px 160px 0px' }} />
              {/* <div>
                <div className={classes.titles}>
                  <label htmlFor="破門者区分">{'破門者区分'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="member_of_hamon"
                    type="checkbox"
                    {...register('member_of_hamon', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.member_of_hamon?.message && (
                    <div className={classes.error}>
                      {errors.member_of_hamon.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="継続稽古中">{'継続稽古中'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="active_is"
                    type="checkbox"
                    {...register('active_is', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.active_is?.message && (
                    <div className={classes.error}>
                      {errors.active_is.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="退会者区分">{'退会者区分'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="withdrawal_is"
                    type="checkbox"
                    {...register('withdrawal_is', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.withdrawal_is?.message && (
                    <div className={classes.error}>
                      {errors.withdrawal_is.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="破門日">{'破門日'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="date_of_hamon"
                    type="date"
                    {...register('date_of_hamon', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.date_of_hamon?.message && (
                    <div className={classes.error}>
                      {errors.date_of_hamon.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="休会日">{'休会日'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="date_of_non_active"
                    type="date"
                    {...register('date_of_non_active', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.date_of_non_active?.message && (
                    <div className={classes.error}>
                      {errors.date_of_non_active.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="退会日">{'退会日'}</label>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="date_of_withdrawal"
                    type="date"
                    {...register('date_of_withdrawal', {
                      required: { value: false, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.date_of_withdrawal?.message && (
                    <div className={classes.error}>
                      {errors.date_of_withdrawal.message}
                    </div>
                  )}
                </div>
              </div> */}
              <div className={classes.button}>
                <button type="submit">更新</button>
              </div>
            </form>
          </div>
          <Footer />
        </>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>会員情報更新</div>
      <hr />
      <BreadCrumbs crumbs={CRUMBS} />
      {isAuth ? (
        form()
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default MemberUpdate;
