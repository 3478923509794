import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import {
  instructorDetailForAdmin,
  memberIndex,
} from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import various from '../../../General/various';
import Footer from '../../../Footer/footer';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  updateBtn: {},
  container: {
    width: '100%',
    '& > div': {
      width: '80%',
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      marginBottom: '48px',
      '& > span': {
        // display: 'inline',
        width: '40%',
        fontSize: '0.9rem',
        fontWeight: 'bold',
        textAlign: 'end',
        // verticalAlign: 'middle',
        lineHeight: '40px',
        marginRight: '16px',
      },
      '& > input, & > textarea': {
        width: '60%',
        height: '40px',
        padding: '8px',
        fontFamily: theme.typography.fontFamily,
        border: '0.5px solid grey',
        borderRadius: '0.9em',
        boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
        '&:focus': {
          outline: `1.2px ${theme.palette.primary.main} outset`,
        },
      },
      '& > textarea': {
        minHeight: '160px',
      },
      '& > img': {
        width: '60%',
        objectFit: 'contain',
      },
      '@media screen and (max-width:500px)': {
        width: '100%',
        flexWrap: 'wrap',
        '& > span': {
          width: '100%',
          textAlign: 'start',
        },
        '& > input, & > textarea, & > img': {
          width: '100%',
        },
      },
    },
    '& $updateBtn': {
      width: '100%',
      marginTop: '80px',
      '& > button': {
        width: '100%',
        display: 'block',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        borderRadius: '0.5em',
        border: 'none',
        padding: '4px 8px',
        backgroundColor: theme.palette.primary.main,
        boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
        color: '#fff',
        cursor: 'pointer',
      },
    },
  },
}));

const InstructorDetail = () => {
  const { isAuth } = useContext(AuthContext);
  const [detailData, setDetailData] = useState();
  const [members, setMembers] = useState();
  const [account, setAccount] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '指導者詳細', path: '/manage/instructor_detail' },
  ];

  // Init
  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  useEffect(() => {
    let uuid;
    if (query.get('id')) {
      uuid = query.get('id');
    } else {
      uuid = location.state.uuid;
    }
    if (uuid) {
      instructorDetailForAdmin(setDetailData, uuid);
      memberIndex(setMembers);
    }
  }, [location, query]);

  useEffect(() => {
    if (detailData && members) {
      const member = members.filter((member) => {
        return member.email === detailData.user.email;
      })[0];
      if (member) {
        setAccount(member.last_name + member.first_name);
      }
    }
  }, [detailData, members]);

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleClick = (uuid) => {
    navigate(various.general.instructorUpdate, {
      state: { uuid: uuid, previous: location.pathname },
    });
  };

  // Contents
  const contentTable = () => {
    if (detailData) {
      return (
        <>
          <div className={classes.container}>
            <div>
              <span>肖像</span>
              <img src={detailData.image} alt="instructor_image" />
            </div>
            <div>
              <span>氏名</span>
              <input type="text" value={detailData.name} readOnly />
            </div>
            <div>
              <span>活動区分</span>
              <input
                type="text"
                value={detailData.is_working ? '指導中' : '休止中'}
                readOnly
              />
            </div>
            <div>
              <span>経歴</span>
              <textarea
                type="textarea"
                value={detailData.career ? detailData.career : '-'}
                readOnly
              />
            </div>
            <div>
              <span>略歴</span>
              <textarea
                type="textarea"
                value={detailData.biography ? detailData.biography : '-'}
                readOnly
              />
            </div>
            <div>
              <span>アカウント</span>
              <input type="text" value={account} readOnly />
            </div>
            <div className={classes.updateBtn}>
              <button onClick={() => handleClick(detailData.uuid)}>更新</button>
            </div>
          </div>
          <Footer />
        </>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>指導者情報詳細</div>
      <hr />
      <BreadCrumbs crumbs={CRUMBS} />
      {isAuth ? (
        contentTable()
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default InstructorDetail;
