import { useEffect, useState } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import imageIndex from '../img/imageIndex';

const useStyles = makeStyles((theme) => ({
  scrolltt: {
    position: 'fixed',
    right: '32px',
    bottom: '32px',
    transition: '1s',
    opacity: '0.7',
    '&:hover': {
      opacity: '1',
    },
    '@media screen and (max-width:500px)': {
      right: '16px',
    },
  },
}));

const PAGE_Y_OFFSET = 2000;

const ScrollToTop = () => {
  const [show, setShow] = useState(false);
  const classes = useStyles();

  const changeShow = () => {
    if (window.pageYOffset > PAGE_Y_OFFSET) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const onScrollTop = () => {
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    // このスクロールイベントの頻度はsetTimeout()等の使用で減らした方がよいかもしれない
    window.addEventListener('scroll', changeShow);
    return () => window.removeEventListener('scroll', changeShow);
  }, []);

  if (show) {
    return (
      <IconButton
        className={classes.scrolltt}
        onClick={onScrollTop}
        aria-label="scroll to top iconButton"
      >
        <imageIndex.ArrowUpwardIcon />
      </IconButton>
    );
  } else return null;
};

export default ScrollToTop;
