import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';

import OnOffByScroll from '../../../Animation/onOffByScroll';
import { AuthContext } from '../../../auth';
import { createInstance } from '../../../Apis/apiHandlers';
import various from '../../../General/various';
import { fileSizeObserver } from '../../../CommonFunction/imageManagement';
import Footer from '../../../Footer/footer';
import { selectDefaultCheck } from '../../../CommonFunction/validators';
import { localStorageArrayHandler } from '../../../CommonFunction/managePermission';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'start',
      '& > span': {
        display: 'inline-block',
        marginRight: '24px',
        fontSize: '0.9rem',
      },
      '& #title': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize,
      },
    },
  },
  titles: {},
  inputs: {},
  helptext: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  container: {
    marginTop: '80px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media screen and (max-width:800px)': {
      '& > div': {
        width: '100%',
      },
    },
    '& > form': {
      '& > div': {
        width: '70%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '& > img': {
            maxWidth: '300px',
            maxHeight: '300px',
            objectFit: 'contain',
          },
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '30%',
          marginRight: '32px',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > label': {
            width: '100%',
            textAlign: 'end',
            fontSize: '1rem',
            '@media screen and (max-width:750px)': {
              textAlign: 'start',
              marginBottom: '16px',
            },
          },
          '& > span': {
            color: 'red',
          },
        },

        '& $inputs': {
          width: '70%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input, & > textarea, & > select': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
          '& > textarea': {
            minHeight: '160px',
          },
          '& > input[type="checkbox"]': {
            boxShadow: 'none',
          },
          '& $helptext': {
            fontSize: '0.8rem',
            color: 'grey',
            '& > a': {
              textDecoration: 'underline',
              '&:link': {
                color: theme.palette.primary.main,
              },
            },
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    marginTop: '88px !important',
    justifyContent: 'end',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const ImageCreatePush = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const { isAuth } = useContext(AuthContext);
  const permissions = localStorageArrayHandler('parse', various.permissionKey);

  const [date, setDate] = useState();

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const LIMITSIZE = 5;

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: '動的イメージの登録', path: '/manage/image_create' },
  ];

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  // Init
  useEffect(() => {
    const weekday = ['日', '月', '火', '水', '木', '金', '土'];
    const d = new Date();
    setDate(
      d.getFullYear() +
        '年' +
        (d.getMonth() + 1) +
        '月' +
        d.getDate() +
        '日' +
        '（' +
        weekday[d.getDay()] +
        '）'
    );
  }, []);

  // Submit
  const onSubmit = handleSubmit(async (data) => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    if (environment === 'development') {
      data['title'] = data['title'] + '_dev';
    } else if (environment === 'staging') {
      data['title'] = data['title'] + '_staging';
    }

    const imageFileKey = 'content';

    try {
      await createInstance('Images', data, imageFileKey);
      console.log('create finish!');
      navigate(various.general.managed);
    } catch (error) {
      console.error('Error in createInstance:', error);
    }
  });

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {!isAuth ? (
        <>
          <div>
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleAuth}
            >
              ログイン
            </span>
            してください。
          </div>
          <Footer />
        </>
      ) : permissions.includes('admin') ? (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">動的イメージの登録</span>
              <span>{date}</span>
            </div>
          </div>
          <hr />
          <BreadCrumbs crumbs={CRUMBS} />
          <div className={classes.container}>
            <form
              method="POST"
              encType="multipart/form-data"
              onSubmit={onSubmit}
            >
              <div>
                <div className={classes.titles}>
                  <label htmlFor="イメージタイトル">イメージタイトル</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="title"
                    type="text"
                    {...register('title', {
                      required: { value: true, message: '入力必須の項目です' },
                    })}
                  />
                  {errors.title?.message && (
                    <div className={classes.error}>{errors.title.message}</div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="使用箇所">使用箇所</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <select
                    id="use_place"
                    defaultValue="choice"
                    {...register('use_place', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          selectDefaultCheck(v)
                            ? null
                            : `いずれかをご選択ください。`,
                      },
                    })}
                  >
                    <option value="choice">選択してください</option>
                    <option value="News">News</option>
                    <option value="Seminar">Seminar</option>
                  </select>
                  {errors.use_place?.message && (
                    <div className={classes.error}>
                      {errors.use_place.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="イメージの説明">イメージの説明</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <textarea
                    id="description"
                    type="textarea"
                    {...register('description', {
                      required: { value: true, message: '入力必須の項目です' },
                      maxLength: {
                        value: 150,
                        message: '150字以内でお願いします',
                      },
                    })}
                  />
                  <div className={classes.helptext}>
                    150文字以内で記述してください。
                  </div>
                  {errors.description?.message && (
                    <div className={classes.error}>
                      {errors.description.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={classes.titles}>
                  <label htmlFor="content">{'登録画像アップロード'}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="content"
                    type="file"
                    accept="image/*,.pdf"
                    {...register('content', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          fileSizeObserver(v, LIMITSIZE)
                            ? null
                            : `イメージファイルは${LIMITSIZE}MB以下にしてください`,
                      },
                    })}
                  />
                  {errors.content?.message && (
                    <div className={classes.error}>
                      {errors.content.message}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.button}>
                <button type="submit">登録</button>
              </div>
            </form>
          </div>
          <Footer />
        </>
      ) : permissions === 'null' ? (
        setTimeout(() => {
          window.location.reload(true);
        }, 500)
      ) : (
        <>
          <div className={classes.pageTitle}>
            <div>
              <span id="title">動的イメージの登録</span>
              <span>{date}</span>
            </div>
          </div>
          <hr />
          <div className={classes.container}>権限がありません</div>
        </>
      )}
    </>
  );
};
export default ImageCreatePush;
