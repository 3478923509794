import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  crumbs: {
    textAlign: 'start',
    color: 'grey',
    fontSize: '0.8rem',
    '& > span': {
      '& > a': {
        color: 'grey',
        textDecoration: 'underline',
      },
    },
  },
}));

const BreadCrumbs = ({ crumbs }) => {
  const classes = useStyles();

  if (crumbs.length <= 1) {
    return null;
  }

  const crumbRender = () => {
    const lastCrumb = crumbs.length - 1;
    return crumbs.map((crumb, index) => {
      if (index === lastCrumb) {
        return <span key={index}>{crumb.name}</span>;
      } else {
        return (
          <span key={index}>
            <Link to={`${crumb.path}?uuid=${crumb?.data?.uuid}`}>
              {crumb.name}
            </Link>
            {' > '}
          </span>
        );
      }
    });
  };

  return <div className={classes.crumbs}>{crumbRender()}</div>;
};
export default BreadCrumbs;
