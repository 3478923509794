export const selectDefaultCheck = (v) => {
  if (v === 'choice') {
    return false;
  } else {
    return true;
  }
};

export const passwordValidator = () => {
  const newPass = document.getElementById('new_password');
  const confirm = document.getElementById('confirm');
  if (newPass.value === confirm.value) return true;
  else return false;
};

export const emailValidator = () => {
  const email = document.getElementById('email');
  const email_confirm = document.getElementById('email_confirm');
  if (email.value === email_confirm.value) return true;
  else return false;
};

export const emailRegValidator = (v) => {
  // https://www.javadrive.jp/regex-basic/sample/index13.html
  var pattern =
    /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
  if (pattern.test(v)) return true;
  else return false;
};

export const passwordRegValidator = (v) => {
  var pattern = /^[a-z\d]{8,100}$/i;
  if (pattern.test(v)) return true;
  else return false;
};

export const birthdayValidator = (v) => {
  const today = new Date();
  const birthday = new Date(v);
  const microSeconds = today.getTime() - birthday.getTime();
  const years = Math.floor(microSeconds / (1000 * 60 * 60 * 24 * 365));

  if (today < birthday) return false;
  else if (years < 11) return false;
  else return true;
};

export const sessionApplyValidator = (v) => {
  const today = new Date();
  const session = new Date(v);
  if (session < today) return false;
  else return true;
};

export const phoneNumberValidator = (v) => {
  const pattern = /^0\d{9,10}$/;
  if (pattern.test(v)) return true;
  else return false;
};

export const facebookValidator = (v) => {
  const pattern = 'https://www.facebook.com';
  if (v.indexOf(pattern) !== -1) return true;
  else return false;
};

export const validateLatinCharactersAndLength = (str) => {
  const pattern = /^[a-zA-Z0-9_]{1,22}$/;
  if (pattern.test(str)) {
    return true;
  }
  if (str.length > 22) {
    return '22文字を超えることはできません。';
  }
  return 'ラテン文字と数字のみ許容します。全角文字は使用できません。';
};

export const managementTagsValidator4Segments = (value) => {
  const segments = value.split('_');

  if (segments.length !== 4) {
    return '4つの部分で構成してください。';
  }

  if (
    segments[2] !== 'half' &&
    segments[2] !== 'deduction' &&
    segments[2] !== 'halfDeduction'
  ) {
    return "3つ目の部分は 'half'または'deduction' である必要があります。";
  }

  return commonValidation(segments);
};

export const managementTagsValidator3Segments = (value) => {
  const segments = value.split('_');

  if (segments.length !== 3) {
    return '3つの部分で構成してください。';
  }

  return commonValidation(segments);
};

const commonValidation = (segments) => {
  if (!segments[0].match(/^[a-zA-Z]+$/) || !segments[1].match(/^[a-zA-Z]+$/)) {
    return '最初の2部分はアルファベットである必要があります。';
  }

  if (!segments[segments.length - 1].match(/^\d{3,5}$/)) {
    return '最後の部分は3桁から5桁の数字である必要があります。';
  }

  return true;
};
