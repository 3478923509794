import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import OnOffByScroll from '../Animation/onOffByScroll';
import Payment from './payment';
import Footer from '../Footer/footer';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../auth';
// import { localStorageArrayHandler } from '../CommonFunction/managePermission';
import various from '../General/various';
import {
  memberDetail,
  productMasterIndex,
  seminarDetailForAdmin,
} from '../Apis/apiOfManaged';
import Clock from '../General/clock';

const useStyles = makeStyles((theme) => ({
  img: {},
  option: {
    '& > h1': {
      fontSize: '5vw !important',
    },
    '& $img': {
      margin: '32px auto',
      width: '80%',
      display: 'flex',
      '& > img': {
        margin: 'auto',
        display: 'inline-block',
        width: '50%',
        height: '50%',
        objectFit: 'cover',
      },
    },
    '& > form': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      flexWrap: 'wrap',

      '& > label': {
        margin: '10px 10px 10px 0px',
        width: '80%',
        textAlign: 'start',
      },
      '& > textarea': {
        display: 'inline-block',
        width: '80%',
        height: '80px',
        padding: '24px 16px 24px 16px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        fontSize: '1.1rem',
        border: '0.5px solid grey',
        borderRadius: '0.9em',
        boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
        textAlign: 'center',
        '&:focus': {
          outline: `1.2px ${theme.palette.primary.main} outset`,
        },
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        lineHeight: '1.5',
      },
      '& > div': {
        marginTop: '40px',
      },
    },
    '& > #atention': {
      margin: '20px',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      width: '100%',
      fontSize: '0.9rem',
      '& > p': {
        textAlign: 'start',
      },
      '& > ul': {
        textAlign: 'start',
        '& > li': {
          margin: '10px 0px',
        },
      },
    },
    '@media screen and (max-width:750px)': {
      '& > h1': {
        fontSize: '10vw !important',
      },
      '& $img': {
        '& > img': {
          width: '100% !important',
        },
      },
    },
  },
  anounce: {
    margin: '20px',
    color: 'grey',
    fontSize: '0.8rem',
    '& > span': {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: theme.palette.primary.main,
    },
  },
}));

const PaymentViewOfOthers = () => {
  const { isAuth } = useContext(AuthContext);
  // const permissions = localStorageArrayHandler('parse', various.permissionKey);
  const TIMEZONE = various.timezone;

  const [seminar, setSeminar] = useState();
  const [member, setMember] = useState();
  const [tempProducts, setTempProducts] = useState();
  const [products, setProducts] = useState();
  const [currentProduct, setCurrentProduct] = useState();
  const [applyData, setApplyData] = useState();

  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();

  // if (location.state) console.log('location.state: ', location.state);
  // if (seminar) console.log('seminar: ', seminar);
  // if (member) console.log('member: ', member);
  // if (products) console.log('products: ', products);
  // if (currentProduct) console.log('currentProduct: ', currentProduct);
  // if (applyData) console.log('applyData: ', applyData);

  // Init
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const productJudge = (member, current_product) => {
    if (current_product.product_type === 'subscription') {
      // Subscriptionの場合は、そのまま進める
      return true;
    }

    let member_class;
    if (member.member_of_general_is) {
      member_class = 'general';
    } else if (member.member_of_num_is) {
      member_class = 'num';
    }

    if (member_class === 'general') {
      if (!current_product.is_half_model) {
        return false;
      }
    } else if (member_class === 'num') {
      if (current_product.is_half_model) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    seminarDetailForAdmin(setSeminar, location.state.apply_data.id);
    productMasterIndex(setTempProducts);

    if (location?.state?.apply_data?.member_uuid) {
      memberDetail(setMember, location.state.apply_data.member_uuid);
    }
  }, [location]);

  useEffect(() => {
    if (tempProducts) {
      const current_product = tempProducts.find(
        (p) => p.uuid === location.state.apply_data.product_uuid
      );
      setCurrentProduct(current_product);

      // アーカイブ済みを除去
      const available_products = tempProducts.filter((p) => p.is_active);
      setProducts(available_products);
    }
  }, [tempProducts, location]);

  // 適正なプロダクトを選択できているか判定の上、適切なApplyDataに変更する
  useEffect(() => {
    if (member && products && currentProduct) {
      const apply_data = location?.state?.apply_data;
      if (!productJudge(member, currentProduct)) {
        if (
          member.member_of_general_is &&
          !currentProduct.management_tags.includes('half')
        ) {
          // 定例会員なのに半額モデルを選択できていなかった場合
          const newManagementTags = currentProduct.management_tags.replace(
            'seminar_',
            'seminar_half_'
          );
          const half_product = products.find(
            (p) => p.management_tags === newManagementTags
          );
          if (half_product) {
            setApplyData({
              ...apply_data,
              product_uuid: half_product.uuid,
              fee: Math.floor(apply_data.fee / 2), // 小数点以下切り捨て
            });
          }
        } else if (
          member.member_of_num_is &&
          currentProduct.management_tags.includes('half')
        ) {
          // 数稽古会員なのに半額モデルを選択してしまっていた場合
          const newManagementTags = currentProduct.management_tags.replace(
            'seminar_half_',
            'seminar_'
          );
          const full_product = products.find(
            (p) => p.management_tags === newManagementTags
          );
          if (full_product) {
            setApplyData({
              ...apply_data,
              product_uuid: full_product.uuid,
              fee: apply_data.fee / 2,
            });
          }
        }
      } else {
        console.log('適切なプロダクトを選択できています。');
        setApplyData(apply_data);
      }
    }
  }, [member, products, currentProduct, location]);

  // handle
  const handleApply = () => {
    navigate(various.general.contactForTraining, {
      state: { previous: location.pathname },
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {seminar && applyData ? (
        <div className={classes.option}>
          <h1 style={{ lineHeight: '1.2' }}>
            お申し込みされる
            <br />
            講習会・セミナー
          </h1>
          <div className={classes.img}>
            <img src={seminar.image} alt="講習会・セミナーの画像" />
          </div>
          <form onSubmit={handleSubmit}>
            <label>講習会・セミナータイトル：</label>
            <textarea
              name="title"
              value={
                seminar.title +
                (seminar.serial_number ? ' #' + seminar.serial_number : '')
              }
              readOnly
            />
            <label>開催日時：</label>
            <textarea
              name="date"
              value={
                Clock({
                  timezone: TIMEZONE,
                  time: null,
                  string: seminar.event_date_start_time,
                }).today +
                ' ' +
                Clock({
                  timezone: TIMEZONE,
                  time: null,
                  string: seminar.part_one_start_time,
                }).hourMinutes +
                ' ~'
              }
              readOnly
            />
            <label>お申し込みコマ数：</label>
            <textarea
              name="koma"
              value={`${applyData.apply_unit}コマ`}
              readOnly
            />
            <Payment
              isAuth={isAuth}
              selectedProductType="payment"
              selectedProductUuid={applyData.product_uuid}
              selectedApplyUnit={applyData.apply_unit}
              customerId={location.state?.customer_id}
              customerEmail={location.state?.customer_email}
              userEmail={location.state.user_email}
              planFlag="seminar"
              applyData={applyData}
              buttonName="決済する"
            />
          </form>
          <div id="atention">
            <p>【注意】</p>
            <ul>
              <li>
                リダイレクト拒否やポップアップブロック等の設定をしている場合、決済に失敗する場合があります、
              </li>
              <li>
                3DSecureのカードを使用している場合、速やかに認証してください。
              </li>
              <li>
                当決済システムは、Chrome、Safari、Edge、Firefoxでの動作確認をしています。その他のブラウザをご使用の方で
                決済がうまく動作しなかった方は、これらのブラウザに切り替えて再度お試しください。
              </li>
            </ul>
          </div>
        </div>
      ) : (
        ''
      )}
      {!isAuth ? (
        <>
          <div className={classes.anounce}>
            進むためには、
            <span onClick={handleAuth}>ログイン</span>
            が必要です。
            <br />
            まだ会員ではない方は、まずは
            <span onClick={handleApply}>体験稽古</span>
            へお申込みください。
          </div>
        </>
      ) : null}
      <Footer />
    </>
  );
};
export default PaymentViewOfOthers;
