import axios from 'axios';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import various, { frontSideRequireAuth } from './General/various';
import { myPage } from './Apis/apiOfManaged';
import { permissionCheck } from './Apis/apis';

export const AuthContext = createContext();
export let isRetry = false;

const AuthContextProvider = (props) => {
  const isAuth = window.localStorage.getItem('Authorization');
  const [user, setUser] = useState(null);
  // eslint-disable-next-line
  const [checked, setChecked] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const login = () => {
    window.localStorage.setItem('Authorization', true);
    console.log('login...');
  };

  const logout = () => {
    window.localStorage.removeItem('Authorization');
    window.localStorage.removeItem('accesstoken');
    window.localStorage.removeItem('refreshtoken');
    window.localStorage.removeItem('permission');
    window.localStorage.setItem(various.permissionKey, null);
    console.log('logout...');
  };

  axios.interceptors.request.use(
    (config) => {
      if (
        config.url.indexOf(various.apiUrlsRequireAuth) !== -1 ||
        config.url.indexOf('api/auth/users/') !== -1
      ) {
        // 参考
        // https://pypi.org/project/django-cors-headers/
        // https://developer.mozilla.org/ja/docs/Web/HTTP/CORS
        // https://stackoverflow.com/questions/33265812/best-http-authorization-header-type-for-jwt

        // config.headers['Content-Type'] = 'application/json; charset=utf-8';
        config.headers['Authorization'] = `Bearer ${window.localStorage.getItem(
          'accesstoken'
        )}`;
        config.headers['Access-Contorol-Allow-Origin'] = various.currentDomain;
        config.headers['Access-Control-Allow-Headers'] = 'Authorization, *';
        // 以下の「 config.withCredentials = true; 」について：headerにAuthorizationを付与する場合はtrue => その場合、Access-Control-Allow-OriginやAccess-Control-Allow-Headersには'*'を付与できない
        config.withCredentials = true;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !isRetry) {
        isRetry = true;
        return axios
          .post(`${various.currentDomain}api/auth/jwt/refresh/`, {
            refresh: window.localStorage.getItem('refreshtoken'),
          })
          .then((res) => {
            if (res.status === 200) {
              console.log('Refresh successfuly in retry');
              window.localStorage.setItem('accesstoken', res.data.access);
              window.localStorage.setItem('refreshtoken', res.data.refresh);
              return axios(originalRequest);
            }
          })
          .catch(() => {
            console.log('Refresh failed in retry');
            logout();
            return Promise.reject(error);
          });
      } else {
        return Promise.reject(error);
      }
    }
  );

  const refreshToken = useCallback(async () => {
    if (frontSideRequireAuth().includes(location.pathname)) {
      axios
        .post(`${various.currentDomain}api/auth/jwt/refresh/`, {
          refresh: window.localStorage.getItem('refreshtoken'),
        })
        .then((res) => {
          console.log('Refresh successfuly');
          window.localStorage.setItem('accesstoken', res.data.access);
          window.localStorage.setItem('refreshtoken', res.data.refresh);
        })
        .catch((err) => {
          console.log('Refresh failed');
          // 以下の切り分けは、chrome以外だとrefreshTokenが壊れるからである（なおAPI側でエラーは吐かない）。
          // 切り分けを実行した背景としてはPOSTRequestをするとrefreshTokenが無くなり認証が通らないためである
          // 原因としては、window.localStrageにトークンを保存しているからという理由が濃厚でブラウザの仕様による挙動と思われる
          // 無論、window.localStrageにトークンを保存することはセキュリティ的にバッドプラクティスであるが故に、早急にcookie等で代替することが必要
          // 現状でCookieでの実装をしていない理由としては、実装に時間を要するためであるが、
          // 以下のように、logout()を行わずとも、上記axios.interceptors.response.use()内でトークンのリフレッシュは行われているのでなんとか使用に耐え得ると思料
          const browser = window.navigator.userAgent.toLowerCase();
          if (browser.indexOf('chrome') !== -1) {
            logout();
            navigate(various.general.login, {
              state: { previous: location.pathname },
            });
          } else {
            navigate(0);
          }
        });
    }
    // eslint-disable-next-line
  }, [location, navigate]);

  useEffect(() => {
    if (user) {
      console.log('permissionCheck');
      permissionCheck({ email: user.email }, setChecked);
    }
  }, [user]);

  useEffect(() => {
    if (isAuth) {
      console.log('refreshToken');
      refreshToken();
    }
  }, [isAuth, refreshToken]);

  useEffect(() => {
    if (isAuth) {
      console.log('myPage');
      myPage(setUser);
    } else {
      setUser(null);
      window.localStorage.setItem(various.permissionKey, null);
    }
  }, [isAuth]);

  return (
    <AuthContext.Provider value={{ login, logout, isAuth }}>
      {props.children}
    </AuthContext.Provider>
  );
};
export default AuthContextProvider;
