import OnOffByScroll from '../Animation/onOffByScroll';
import Footer from '../Footer/footer';

const PaymentCancel = () => {
  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div>
        <h1>決済をキャンセルしました</h1>
        <a href="/">ホームに戻る</a>
        <Footer />
      </div>
    </>
  );
};
export default PaymentCancel;
