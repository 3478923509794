import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

import OnOffByScroll from '../Animation/onOffByScroll';
import various from '../General/various';
import { images } from '../Apis/apis';
import { myPage, paramsIndex } from '../Apis/apiOfManaged';
import Footer from '../Footer/footer';
import BreadCrumbs from '../General/breadCrumbs';
import { Spinner } from '../Spinner';
import { AuthContext } from '../auth';

const useStyles = makeStyles((theme) => ({
  logo: {
    textAlign: 'start',
    marginBottom: '64px',
    '& > img': {
      width: '100px',
      height: '100px',
      objectFit: 'cover',
    },
  },
  container: {
    textAlign: 'start',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '64px',
  },
  list: {
    marginBottom: '64px',
    '& > ol': {
      '& > li': {
        marginBottom: '32px',
        '& > ul': {
          fontSize: '0.9rem',
        },
        '& > span': {
          fontSize: '0.9rem',
        },
        '& > table': {
          margin: '0 auto',
          borderCollapse: 'collapse',
          textAlign: 'center',
          fontSize: '0.9rem',
          '& > thead': {
            width: '100%',
            '& > tr': {
              '& >td': {
                minWidth: '112px',
                border: '1px solid #e0e0e0',
                borderBottom: '2px solid #e0e0e0', // 1行目のすべてのセルの下段の罫線を太くする
                '&:first-child': {
                  borderRight: '2px solid #e0e0e0', // 1列目の右側の罫線を太くする
                },
              },
            },
          },
          ' & > tbody': {
            width: '100%',
            '& > tr': {
              height: '80px',
              backgroundColor: 'white',
              '& > td:first-child': {
                borderRight: '2px solid #e0e0e0', // 1列目の右側の罫線を太くする
              },
              '& > td': {
                padding: '0px 8px',
                border: '1px solid #e0e0e0',
                width: '33%',
              },
              '&:hover': {
                backgroundColor: theme.palette.background.default,
              },
            },
            '& #method': {
              textAlign: 'start',
              lineHeight: '1.5',
              '& #methodTitle': {
                textAlign: 'center',
              },
            },
          },
        },
      },
      '& > span': {
        display: 'block',
        marginBottom: '32px',
        fontSize: '0.9rem',
      },
    },
  },
  husoku: {
    fontSize: '0.9rem',
  },
}));
const Constitution = () => {
  const { isAuth } = useContext(AuthContext);
  const [logo, setLogo] = useState();
  const [params, setParams] = useState();
  const [newConstitution, setNewConstitution] = useState();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const CRUMBS = [
    { name: 'MyPage', path: '/mypage' },
    { name: '会則', path: '/mypage/constitution' },
  ];

  const MEMBERSHIP_FEE = various.stripe.membershipFee;
  const NUM_MEMBERSHIP_FEE = various.stripe.numMembershipFee;

  // Init
  useEffect(() => {
    images(setLogo);
  }, []);

  useEffect(() => {
    paramsIndex(setParams);
    myPage(setUser);
  }, []);
  // if (params) console.log(params);

  useEffect(() => {
    if (params) {
      const newConstitution = params.find(
        (obj) => obj.title === 'is_new_constitution'
      );
      if (newConstitution.apply_is) {
        setNewConstitution(true);
      } else {
        if (user) {
          if (various.stripe.testRunStaff.includes(user.email)) {
            setNewConstitution(true);
          }
        }
      }
      setLoading(false);
    }
  }, [params, user]);

  const getParams = () => {
    const result = {};
    const collationList = [
      '入会金',
      '蕨月謝',
      '駒込月謝',
      '稽古会参加費',
      '体験稽古参加費（日）',
      '体験稽古参加費（土）',
      '都度謝礼1000',
      '都度謝礼2000',
      '都度謝礼3000',
    ];
    if (params) {
      params.forEach((obj) => {
        const title = obj.title;
        if (collationList.includes(title)) {
          result[title] = obj.amount.toLocaleString();
        }
      });
    }
    return result;
  };

  // Contents
  const contents = () => {
    if (logo && params) {
      const par = getParams();
      return (
        <>
          <div className={classes.logo}>
            <img src={logo.OfficialImage_TouzenLogoRed.url} alt="logo" />
            <BreadCrumbs crumbs={CRUMBS} />
          </div>
          <div className={classes.container}>
            <div className={classes.title}>刀禪會會則</div>
            <div className={classes.list}>
              <ol>
                <li>新規⼊会条件</li>
                <span>⽉２回以上稽古に参加出来る環境と意思の有る⽅。</span>
                <li>会員の所属</li>
                <span>
                  会員は蕨or駒込のいずれかに所属を選択し申請する。⼑禅の通常会員は定例会員とするが、定期的に参加出来ない特別の事情が有る場合は回数（不定期参加）会員とする。
                </span>
                <li>
                  会費規定
                  <ul>
                    <li>⼊会⾦ {par['入会金']}円</li>
                    <li>
                      蕨⽇曜（13:00~17:00）定例会員⽉会費
                      {par['蕨月謝']}円（⼟曜稽古参加無料）
                    </li>
                    <li>蕨⽉曜稽古会参加費 {par['稽古会参加費']}円/回</li>
                    <li>蕨⽉曜稽古会参加費 {par['稽古会参加費']}円/回</li>
                    <li>駒込⾦曜稽古会参加費 {par['稽古会参加費']}円/回</li>
                    <li>
                      駒込⾦曜（19:00~21:00）定例会員⽉会費
                      {par['駒込月謝']}円（⼟曜稽古参加無料）
                    </li>
                    <li>蕨⽇曜稽古会参加費 {par['都度謝礼2000']}円/回</li>
                    <li>蕨⽉曜稽古会参加費 {par['都度謝礼1000']}円/回</li>
                    <li>
                      回数会員参加費（同好会会員、舞扇道会員、⾼久道場直伝講習会参加者、体験参加者も同額）
                    </li>
                    <li>蕨⽇曜稽古会 {par['都度謝礼3000']}円/回</li>
                    <li>蕨⼟曜稽古会 {par['都度謝礼2000']}円/回</li>
                    <li>蕨⽉曜稽古会 {par['都度謝礼2000']}円/回</li>
                    <li>駒込⾦曜稽古会 {par['都度謝礼2000']}円/回</li>
                  </ul>
                  <span>
                    ※体験参加は原則1回までとし、2回⽬以降参加希望の場合は、蕨または駒込定例会員あるいは回数会員として⼊会を要する。
                  </span>
                </li>
                <li>
                  会員資格、会費等の補⾜
                  <ul>
                    <li>⽉会費は毎⽉初の稽古⽇に納めるものとする。</li>
                    <li>
                      2ヶ⽉間正当な理由なく会費が未納の場合は、退会処分とする。また、
                      同様の⾏為を繰り返す場合も退会処分とする。
                    </li>
                    <li>
                      ⽉会費に関しては、1ヶ⽉間の出席の有無に関わらず⽀払うものとする。1ヶ⽉間出席出来なかった場合は、翌⽉に当⽉分を含む2ヶ⽉分の会費を⽀払うものとする。ただし、正当な理由があり、⼑禅本部がやむをえないと認めた場合は、⽀払いを考慮する場合がある。2ヶ⽉以上出席出来ない
                      場合は、休会の申請を要するものとする。
                    </li>
                    <li>
                      素⾏等会員個⼈に問題があると⼑禅本部が認めた場合は退会処分とする。
                    </li>
                  </ul>
                </li>
                <li>
                  特例処置
                  <ul>
                    <li>
                      蕨市在住の蕨稽古会への⼊会希望者の⼊会⾦は半額とする。
                    </li>
                    <li>
                      学⽣の⼊会⾦は半額とする。なお、学⽣とは中学⽣〜⼤学⽣までとし、⼤学院⽣は除くものとする。また、⼩学⽣以下は原則⼊会不可とする。
                    </li>
                  </ul>
                </li>
                <li>
                  会費、参加費の⽀払い⽅法等の変更
                  <ul>
                    <li>
                      定例会員から回数会員、回数会員から定例会員と⽀払い⽅法を変更する
                      場合は、変更した⽉から6ヶ⽉間はその⽀払い⽅法を継続するものとする。
                    </li>
                    <li>⽉ごとに⽀払い⽅法の変更を⾏うことは認めない。</li>
                  </ul>
                </li>
                <li>
                  会則変更、⼊会特例等
                  <ul>
                    <li>
                      本会則は必要に応じて理事会で討議の上、⼩⽤会⻑が改廃を決定する。
                    </li>
                    <li>
                      本会と交流のある武術団体等で⼩⽤会⻑が認めた者は会友とし、稽古に際しての⼊会⾦を免除出来るものとする。
                    </li>
                    <li>
                      本会則に該当しない案件が⽣じた場合は、理事会で協議し、⼩⽤会⻑が対応を決定する。
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
            <div>
              <p>附則</p>
              <div className={classes.husoku}>
                <p>2017年9⽉9⽇付補⾜規定</p>
                <ul>
                  <li>「4.会員資格、会費等の補⾜」における申請について</li>
                  休・退会の申請は⼑禅本部、蕨会計担当者、駒込会計担当者のいずれかにメールか⼝頭で⾏うものとする。
                  <br />
                  申請先：{<Link to={various.general.contact}>こちら</Link>}
                </ul>
              </div>
              <div className={classes.husoku}>
                <p>2022年12⽉29⽇付補⾜規定</p>
                <ul>
                  <li>「1.新規入会条件」の運用保留</li>
                  <li>「2.会員の所属」の運用保留</li>
                  <li>「3.会費規定」の運用保留</li>
                </ul>
              </div>
            </div>
          </div>
          <Footer />
        </>
      );
    }
  };

  const contents_version2 = () => {
    if (logo && params) {
      return (
        <>
          <div className={classes.logo}>
            <img src={logo.OfficialImage_TouzenLogoRed.url} alt="logo" />
            <BreadCrumbs crumbs={CRUMBS} />
          </div>
          <div className={classes.container}>
            <div className={classes.title}>刀禪會會則</div>
            <div className={classes.list}>
              <ol>
                <li style={{ marginBottom: '8px' }}>新規⼊会条件</li>
                <span>
                  月2回以上稽古または講習会・セミナーに参加出来る環境と意思のある方。
                </span>
                <li style={{ marginBottom: '8px' }}>会員の所属</li>
                <span>
                  会員は「{various.general_member}」または「{various.num_member}
                  」に区分される。刀禅の通常会員は{various.general_member}
                  とするが、
                  定期的に参加出来ない特別の事情や地理的事情がある場合は
                  {various.num_member}(不定期参加)とする。
                </span>
                <li>
                  会費規定
                  <br />
                  <span>会員は以下の表に記載の会費等を支払うものとする。</span>
                  <table>
                    <thead>
                      <tr>
                        <td></td>
                        <td>{various.general_member}</td>
                        <td>{various.num_member}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>会費</td>
                        <td>
                          {MEMBERSHIP_FEE.toLocaleString()}円&nbsp;/&nbsp;月
                        </td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>一般稽古参加費</td>
                        <td style={{ fontWeight: 'bold' }}>無料</td>
                        <td>
                          {NUM_MEMBERSHIP_FEE.toLocaleString()}
                          円&nbsp;/&nbsp;一コマ
                        </td>
                      </tr>
                      <tr>
                        <td>セミナー参加費</td>
                        <td style={{ fontWeight: 'bold' }}>半額</td>
                        <td>定額</td>
                      </tr>
                      <tr id="method">
                        <td id="methodTitle">決済方法</td>
                        <td style={{ fontWeight: 'bold' }}>
                          クレジットカード
                          <br />
                          のみ
                        </td>
                        <td>
                          クレジットカード
                          <br />
                          または現金
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                <li>
                  会員資格、会費等の補⾜
                  <ul>
                    <li>会費は毎月1日に決済するものとする。</li>
                    <li>
                      月の途中で{various.general_member}
                      になった場合、初月の会費は日割り計算の上決済することとする。
                    </li>
                    <li>
                      稽古参加費およびセミナー参加費は申込時点での決済または参加時に支払うものとする。
                    </li>
                    <li>
                      既に決済が完了したが参加日未到来の場合に参加を取りやめる際は、MyPageから参加のキャンセル手続きを行うことで返金処理を行うものとする。
                    </li>
                    <li>
                      会費については返金しない。月の途中で退会等の手続きがあった場合、その手続きが完了した月の月末までは
                      {various.general_member}
                      としての資格を有し、翌月1日に手続きが実行される。
                    </li>
                    <li>
                      2ヶ月間正当な理由なく会費が未納の場合は退会処分とする。また、
                      類似の行為を繰り返す場合も退会処分とする。
                    </li>
                    <li>
                      会費に関しては、1ヶ月間の出席の有無に関わらず支払うものとする。1ヶ月間出席出来なかった場合は、翌月に当月分を含む2ヶ月分の会費を支払うものとする。ただし、正当な理由があり刀禅本部がやむをえないと認めた場合は、支払いを考慮する場合がある。
                    </li>
                    <li>
                      2ヶ月以上出席出来ない場合は、休会の申請を要するものとする。
                    </li>
                    <li>
                      素行等会員個人に問題があると刀禅本部が認めた場合は退会処分とする。
                    </li>
                  </ul>
                </li>
                <li>
                  会費、参加費の⽀払い⽅法等の変更
                  <ul>
                    <li>
                      {various.general_member}
                      はクレジットカードでの会費納入以外は認めない。
                    </li>
                    <li>会員区分の変更は、"半年に一度"のみ可能とする。</li>
                    <li>
                      {various.general_member}から{various.num_member}
                      の変更予約は会員区分変更可能期間内であれば随時受け付けるが、会員区分の変更は翌月1日に変更となる。
                    </li>
                    <li>
                      {various.general_member}
                      において、やむを得ず現金払いに変更する場合、変更月のみ特例として"現金払いの
                      {various.general_member}"となるが、翌月1日に
                      {various.num_member}に変更となる。
                    </li>
                  </ul>
                </li>
                <li>
                  会則変更、⼊会特例等
                  <ul>
                    <li>
                      本会則は必要に応じて理事会で討議の上、⼩⽤会⻑が改廃を決定する。
                    </li>
                    <li>
                      本会則に該当しない案件が⽣じた場合は、理事会で協議し⼩⽤会⻑が対応を決定する。
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
            <div>
              <p>附則</p>
              <div className={classes.husoku}>
                <p>2017年9⽉9⽇付補⾜改定</p>
                <ul>
                  <li>「4.会員資格、会費等の補⾜」における申請について</li>
                  休・退会の申請は⼑禅本部、蕨会計担当者、駒込会計担当者のいずれかにメールか⼝頭で⾏うものとする。
                  <br />
                  申請先：{<Link to={various.general.contact}>こちら</Link>}
                </ul>
              </div>
              <div className={classes.husoku}>
                <p>2022年12⽉29⽇付補⾜改定</p>
                <ul>
                  <li>「1.新規入会条件」の運用保留</li>
                  <li>「2.会員の所属」の運用保留</li>
                  <li>「3.会費規定」の運用保留</li>
                </ul>
              </div>
              <div className={classes.husoku}>
                <p>2024年8月19日付補足改定</p>
                <ul>
                  <li>会則全面改訂の上、即日適用する。</li>
                </ul>
              </div>
            </div>
          </div>
          <Footer />
        </>
      );
    }
  };

  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      {isAuth ? (
        <>
          {loading ? (
            <Spinner />
          ) : newConstitution ? (
            contents_version2()
          ) : (
            contents()
          )}
        </>
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default Constitution;
