import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { emailRegValidator } from '../CommonFunction/validators';

import { makeStyles } from '@material-ui/core';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { memberExistanceCheckApi, resetPassword2 } from '../Apis/apis';
// import { resetPassword } from '../Apis/apis';
import OnOffByScroll from '../Animation/onOffByScroll';
import StandardDialog from '../Dialog/standardDialog';
import various from '../General/various';
import Footer from '../Footer/footer';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '500px',
  },
  formWrapper: {
    marginTop: '168px',
    '& > p': {
      textAlign: 'start',
    },
  },

  titles: {},
  inputs: {},
  error: {
    color: 'red',
    fontSize: '0.7em',
  },
  formcontainer: {
    marginTop: '32px',
    width: '100%',
    '& > form': {
      '& > div': {
        width: '100%',
        display: 'flex',
        margin: '0 auto',
        marginBottom: '32px',

        '& > div': {
          textAlign: 'start',
          display: 'flex',
          justifyContent: 'start',
          '& > img': {
            maxWidth: '300px',
            maxHeight: '300px',
            objectFit: 'contain',
          },
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
        },

        '& $titles': {
          width: '5%',
          marginRight: '4px',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > span': {
            color: 'red',
          },
        },

        '& $inputs': {
          width: '80%',
          flexWrap: 'wrap',
          '@media screen and (max-width:750px)': {
            width: '100%',
          },
          '& > input': {
            width: '100%',
            height: '40px',
            padding: '8px',
            fontFamily: theme.typography.fontFamily,
            border: '0.5px solid grey',
            borderRadius: '0.5em',
            boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.1) inset',
            '&:focus': {
              outline: `1.2px ${theme.palette.primary.main} outset`,
            },
          },
        },

        '@media screen and (max-width:750px)': {
          flexWrap: 'wrap',
        },
      },
    },
  },
  button: {
    width: '85% !important',
    margin: '32px 0px 0px 0px !important',
    justifyContent: 'end',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const PasswordSetting = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  });

  const [open, setOpen] = useState(false);
  const [exist, setExist] = useState(true);
  const [dialogText, setDialogText] = useState({});

  const [token, setToken] = useState('');
  const [submitData, setSubmitData] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const navigate = useNavigate();
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const toNavigate = () => {
    if (exist) return navigate(various.general.home);
    else return navigate(0);
  };

  const onSubmit = async (data) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    } else {
      const reCaptchaToken = await executeRecaptcha('contact');
      setToken(reCaptchaToken);
      setSubmitData(data);
    }
  };

  useEffect(() => {
    if (submitData && token) {
      const data = { ...submitData };
      data['g_recaptcha_response'] = token;
      apiHandler(data);
    }
    // eslint-disable-next-line
  }, [submitData, token]);

  const apiHandler = (data) => {
    if (exist) {
      resetPassword2(setOpen, data);
      // resetPassword(setOpen, data.email);
    } else {
      setOpen(true);
    }
  };

  const memberExistanceCheck = (v) => {
    memberExistanceCheckApi(setExist, { email: v, flag: 'setting' });
    return true;
  };
  useEffect(() => {
    if (!exist) {
      setDialogText({
        title:
          'ご入力いただいたEメールアドレスは本サイトに登録されておりません。',
        content: '再度このページのURLが記載のメールをご確認ください。',
        agree: 'OK',
      });
    } else {
      setDialogText({
        title: 'ご登録のアドレスへ新規パスワード設定用リンクを送信しました',
        content: 'ご登録アドレスのメールボックスをご確認ください。',
        agree: 'OK',
      });
    }
  }, [exist]);

  return (
    <>
      <div className={classes.container}>
        <div>
          <OnOffByScroll place="General" />
        </div>
        <div className={classes.formWrapper}>
          <p>
            このページのURLが記載されていたメールアドレスを入力の上、送信してください。
          </p>
          <div className={classes.formcontainer}>
            <form
              method="POST"
              encType="multipart/form-data"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <div className={classes.titles}>
                  <label htmlFor="">{''}</label>
                  <span> *</span>
                </div>
                <div className={classes.inputs}>
                  <input
                    id="email"
                    type="email"
                    {...register('email', {
                      required: { value: true, message: '入力必須の項目です' },
                      validate: {
                        message: (v) =>
                          emailRegValidator(v)
                            ? memberExistanceCheck(v)
                              ? null
                              : `ご入力されたEメールでは送信できません。`
                            : `※正しいEメールの形式で入力してください`,
                      },
                    })}
                  />
                  {errors.email?.message && (
                    <div className={classes.error}>{errors.email.message}</div>
                  )}
                </div>
              </div>
              <div className={classes.button}>
                <button type="submit">送信</button>
              </div>
            </form>
          </div>
        </div>
        <StandardDialog
          open={open}
          handleClose={handleClose}
          text={{
            title: dialogText.title,
            content: dialogText.content,
            agree: dialogText.agree,
            disagree: null,
          }}
          func={{ agreeFunc: toNavigate, disagreeFunc: null }}
        />
      </div>
      <Footer />
    </>
  );
};
export default PasswordSetting;
