import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnOffByScroll from '../../../Animation/onOffByScroll';
import { seminarDetailForAdmin } from '../../../Apis/apiOfManaged';
import { AuthContext } from '../../../auth';
import { localStorageArrayHandler } from '../../../CommonFunction/managePermission';
import Footer from '../../../Footer/footer';
import various from '../../../General/various';
import BreadCrumbs from '../../../General/breadCrumbs';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    margin: '0px 0px 80px 0px',
    textAlign: 'start',
  },
  row: {},
  data: {},
  container: {
    width: '100%',
    '& > table': {
      width: '90%',
      margin: '0 auto',
      borderCollapse: 'collapse',
      fontSize: '0.85em',
      '& > tbody': {
        width: '100%',
        '& > tr': {
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          wordBreak: 'break-all',
          wordWrap: 'breal-all',
          lineHeight: '64px',
          borderBottom: '1px grey solid',
          '& $row': {
            width: '30%',
            margin: 'auto 0',
            lineHeight: '32px',
            textAlign: 'end',
            paddingRight: '32px',
          },
          '& $data': {
            width: '70%',
            lineHeight: '32px',
            textAlign: 'start',
            padding: '16px 8px 16px 32px',
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            '& > a': {
              textDecoration: 'underline',
              '&:link': {
                color: theme.palette.primary.main,
              },
              '&:visited': {
                color: '#770000',
              },
              '&:hover': {
                fontWeight: 'bold',
              },
            },
          },
          '& #image': {
            padding: '16px 8px 16px 32px',
            textAlign: 'start',
            '& > img': {
              maxWidth: '250px',
              maxHeight: '250px',
              objectFit: 'contain',
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(220,220,220,0.3)',
          },
        },
      },
    },
  },
  updateBtn: {
    marginTop: '80px',
    '& > button': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      borderRadius: '0.5em',
      border: 'none',
      padding: '4px 8px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

const SeminarDetailForAdmin = () => {
  const { isAuth } = useContext(AuthContext);
  const [detailData, setDetailData] = useState();

  const permissions = localStorageArrayHandler('parse', various.permissionKey);

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const CRUMBS = [
    { name: '管理画面Top', path: '/manage/index' },
    { name: 'セミナー一覧', path: '/seminar_index' },
    { name: 'セミナー詳細', path: '/seminar_detail_for_admin' },
  ];

  // if (detailData) console.log('detailData:', detailData);

  // Init
  useEffect(() => {
    if (location.state.uuid) {
      seminarDetailForAdmin(setDetailData, location.state.uuid);
    }
  }, [location]);

  // Handler
  const handleAuth = () => {
    navigate(various.general.login, { state: { previous: location.pathname } });
  };

  const handleClick = (uuid) => {
    navigate(various.general.seminarUpdateForAdmin, {
      state: { uuid: uuid, previous: location.pathname },
    });
  };

  // Contents
  const contentTable = () => {
    const rows = various.seminarHeaders;
    if (detailData) {
      let part_three_start_time;
      let part_three_end_time;
      let part_four_start_time;
      let part_four_end_time;
      const event_date_start_time = new Date(detailData.event_date_start_time);
      const part_one_start_time = new Date(detailData.part_one_start_time);
      const part_one_end_time = new Date(detailData.part_one_end_time);
      const part_two_start_time = new Date(detailData.part_two_start_time);
      const part_two_end_time = new Date(detailData.part_two_end_time);
      if (detailData.part_three_end_time) {
        part_three_start_time = new Date(detailData.part_three_start_time);
        part_three_end_time = new Date(detailData.part_three_end_time);
      }
      if (detailData.part_four_end_time) {
        part_four_start_time = new Date(detailData.part_four_start_time);
        part_four_end_time = new Date(detailData.part_four_end_time);
      }
      const d = new Date(detailData.delivery_date);
      const delivery_date =
        d.toLocaleDateString() +
        ' ' +
        d.getHours() +
        ':' +
        d.getMinutes().toString().padStart(2, '0');

      let distribution_date;
      if (detailData.distribution_date) {
        const dd = new Date(detailData.distribution_date);
        distribution_date =
          dd.toLocaleDateString() +
          ' ' +
          dd.getHours() +
          ':' +
          dd.getMinutes().toString().padStart(2, '0');
      }

      const date = new Date(detailData.delivery_date);
      const today = new Date();
      return (
        <>
          <div className={classes.container}>
            <table>
              <tbody>
                <tr>
                  <td className={classes.row}>{rows['title']}</td>
                  <td className={classes.data}>
                    {detailData.title +
                      (detailData.serial_number
                        ? ' #' + detailData.serial_number
                        : '')}
                  </td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['is_secret']}</td>
                  <td className={classes.data}>
                    {detailData.is_secret ? '秘伝' : '-'}
                  </td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['seminar_type']}</td>
                  <td className={classes.data}>{detailData.seminar_type}</td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['tag']}</td>
                  <td className={classes.data}>
                    {detailData.tag.map((obj) => {
                      return ` #${obj.name}`;
                    })}
                  </td>
                </tr>
                {/* <tr>
                  <td className={classes.row}>{rows['organizer']}</td>
                  <td className={classes.data}>{detailData.organizer}</td>
                </tr> */}
                <tr>
                  <td className={classes.row}>{rows['instructor']}</td>
                  <td className={classes.data}>{detailData.instructor.name}</td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['place']}</td>
                  <td className={classes.data}>
                    {detailData.place.facility_name +
                      ' ' +
                      detailData.hall.name}
                  </td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['subject']}</td>
                  <td className={classes.data}>{detailData.subject}</td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['capacity']}</td>
                  <td className={classes.data}>{detailData.capacity + '名'}</td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['entry_fee']}</td>
                  <td className={classes.data}>
                    {detailData.entry_fee + '円/ 日'}
                  </td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['membership_fee']}</td>
                  <td className={classes.data}>
                    {detailData.membership_fee + '円/ 日'}
                  </td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['payment_method']}</td>
                  <td className={classes.data}>
                    {detailData?.payment_method?.is_mixed
                      ? '全支払方法OK'
                      : detailData?.payment_method?.is_only_cash
                      ? '現金決済のみ'
                      : detailData?.payment_method?.is_only_credit_card
                      ? 'クレジットカード決済のみ'
                      : '未設定'}
                  </td>
                </tr>
                <tr>
                  <td className={classes.row}>開催日</td>
                  <td className={classes.data}>
                    {event_date_start_time.toLocaleDateString()}
                  </td>
                </tr>
                {part_two_start_time.getHours() === 23 &&
                part_two_start_time.getMinutes() === 59 ? (
                  <>
                    {/* 1コマの場合 */}
                    <tr>
                      <td className={classes.row}>第一部</td>
                      <td className={classes.data}>
                        {part_one_start_time.getHours() +
                          ':' +
                          part_one_start_time
                            .getMinutes()
                            .toString()
                            .padStart(2, '0') +
                          ' - ' +
                          part_one_end_time.getHours() +
                          ':' +
                          part_one_end_time
                            .getMinutes()
                            .toString()
                            .padStart(2, '0')}
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    {/* 2コマ以上の場合 */}
                    <tr>
                      <td className={classes.row}>第一部</td>
                      <td className={classes.data}>
                        {part_one_start_time.getHours() +
                          ':' +
                          part_one_start_time
                            .getMinutes()
                            .toString()
                            .padStart(2, '0') +
                          ' - ' +
                          part_one_end_time.getHours() +
                          ':' +
                          part_one_end_time
                            .getMinutes()
                            .toString()
                            .padStart(2, '0')}
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.row}>第二部</td>
                      <td className={classes.data}>
                        {part_two_start_time.getHours() +
                          ':' +
                          part_two_start_time
                            .getMinutes()
                            .toString()
                            .padStart(2, '0') +
                          ' - ' +
                          part_two_end_time.getHours() +
                          ':' +
                          part_two_end_time
                            .getMinutes()
                            .toString()
                            .padStart(2, '0')}
                      </td>
                    </tr>
                    {part_three_start_time ? (
                      <>
                        <tr>
                          <td className={classes.row}>第三部</td>
                          <td className={classes.data}>
                            {part_three_start_time.getHours() +
                              ':' +
                              part_three_start_time
                                .getMinutes()
                                .toString()
                                .padStart(2, '0') +
                              ' - ' +
                              part_three_end_time.getHours() +
                              ':' +
                              part_three_end_time
                                .getMinutes()
                                .toString()
                                .padStart(2, '0')}
                          </td>
                        </tr>
                      </>
                    ) : (
                      ''
                    )}
                    {part_four_start_time ? (
                      <>
                        <tr>
                          <td className={classes.row}>懇親会</td>
                          <td className={classes.data}>
                            {part_four_start_time.getHours() +
                              ':' +
                              part_four_start_time
                                .getMinutes()
                                .toString()
                                .padStart(2, '0') +
                              ' - ' +
                              part_four_end_time.getHours() +
                              ':' +
                              part_four_end_time
                                .getMinutes()
                                .toString()
                                .padStart(2, '0')}
                          </td>
                        </tr>
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
                <tr>
                  <td className={classes.row}>{rows['guidance']}</td>
                  <td className={classes.data}>{detailData.guidance}</td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['notes']}</td>
                  <td className={classes.data}>{detailData.notes}</td>
                </tr>
                <tr>
                  <td className={classes.row}>
                    {rows['information_to_management']}
                  </td>
                  <td className={classes.data}>
                    {detailData.information_to_management}
                  </td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['image']}</td>
                  <td className={classes.data} id="image">
                    <img src={detailData.image} alt="semina_image" />
                  </td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['documents']}</td>
                  <td className={classes.data}>
                    {detailData.documents.document ? (
                      <a href={detailData.documents.document}>
                        {detailData.documents.title}
                      </a>
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>

                <tr>
                  <td className={classes.row}>{rows['delivery_date']}</td>
                  <td className={classes.data}>{delivery_date}</td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['distribution_is']}</td>
                  <td className={classes.data}>
                    {detailData.distribution_is ? '有り' : '無し'}
                  </td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['distribution_date']}</td>
                  <td className={classes.data}>
                    {distribution_date ? distribution_date : '-'}
                  </td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['show_the_instructor']}</td>
                  <td className={classes.data}>
                    {detailData.show_the_instructor ? '有り' : '無し'}
                  </td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['release_is']}</td>
                  <td className={classes.data}>
                    {detailData.release_is
                      ? date < today
                        ? '公開済み'
                        : '公開予定'
                      : '公開未定'}
                  </td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['products']}</td>
                  <td className={classes.data}>
                    {detailData.products.map((product, index) => {
                      return (
                        <p
                          key={`product${index}`}
                          style={{ lineHeight: '1.2' }}
                        >
                          {product.name}
                        </p>
                      );
                    })}
                  </td>
                </tr>
                <tr>
                  <td className={classes.row}>{rows['uuid']}</td>
                  <td className={classes.data}>{detailData.uuid}</td>
                </tr>
              </tbody>
            </table>
            {permissions.includes('admin') ||
            permissions.includes('instructor') ||
            permissions.includes('organizer') ? (
              <div className={classes.updateBtn}>
                <button onClick={() => handleClick(detailData.uuid)}>
                  更新
                </button>
              </div>
            ) : undefined}
          </div>
          <Footer />
        </>
      );
    }
  };

  return (
    <>
      <div style={{ margin: '80px' }}>
        <OnOffByScroll place="General" />
      </div>
      <div className={classes.pageTitle}>セミナー情報詳細</div>
      <BreadCrumbs crumbs={CRUMBS} />
      <hr />
      {isAuth ? (
        contentTable()
      ) : (
        <div>
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleAuth}
          >
            ログイン
          </span>
          してください。
        </div>
      )}
    </>
  );
};
export default SeminarDetailForAdmin;
